import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  TreeSelect,
  Checkbox,
  Button,
  message,
  Modal,
  Avatar,
} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import "./../../CSS/MaterialDetail.css";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { FormInstance } from "antd/es/form";
import { useHistory } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import i18n from "i18next";
import { TreeNode } from "antd/es/tree-select";
import { User } from "../../../../../../components/UserSelect/schema";
import { Scrollbars } from "react-custom-scrollbars";
import { BaseURL } from "../../../../../../constants/url";
import {usePermission} from "../../../../../../util/permession";
export interface Params {
  form: FormInstance;
  skuId: string;
  type: string;
  property: string;
  onSave: ()=>void;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Param {
  current: any;
}

function loop(condition: any, data: any) {
  let newarr: any = [];
  if (data != undefined) {
    data.forEach((element: any) => {
      if (element.title.indexOf(condition) > -1) {
        newarr.push(element);
      } else {
        if (element.children && element.children.length > 0) {
          const ab = loop(condition, element.children);
          const obj = {
            ...element,
            children: ab,
          };
          if (ab && ab.length > 0) {
            newarr.push(obj);
          }
        }
      }
    });
  }
  return newarr;
}

export default ({ form, skuId, type, property,onSave }: Params) => {
  const [loading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState("undefined");
  const [condition, setCondition] = useState("");
  const [dataTree, setDataTree] = useState<any>();
  const history = useHistory();
  const [isChange, setIsChange] = useState(false);
  const thisRef: Param = useRef();
  thisRef.current = {
    condition,
    dataTree,
  };
  const callBack = useCallback(
    function confirm(flag: boolean) {
      if (flag) {
        Modal.confirm({
          title: i18n.t("返回操作",),
          icon: <ExclamationCircleOutlined />,
          content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
          okText: i18n.t("okText"),
          cancelText: i18n.t("cancelText"),
          onOk: function () {
            history.push("/sku/skuManage/" + property);
          },
        });
      } else {
        history.push("/sku/skuManage/" + property);
      }
    },
    [history, property]
  );
  const getTreeSelect = useCallback(function getTreeSelect() {
    axios
      .post<Result<any[]>>(`/clothes/treeSelect/getTreeSelect`, {
        type: "clothes_sku_category",
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          setDataTree(data);
        }
      });
  }, []);
  useEffect(function () {
    getTreeSelect();
  }, []);

  const onChange = (value: any) => {
    console.log(value);
    setSelectValue(value);
  };
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    let categoryId = values.categoryId;
    values.categoryId = categoryId.join(",");
    if (values.isBigPackage === undefined) {
      values.isBigPackage = "0";
    } else {
      values.isBigPackage = values.isBigPackage[0];
    }
    if (values.isProcessingMaterial === undefined || values.isProcessingMaterial.length == 0) {
      values.isProcessingMaterial = "0";
    } else {
      values.isProcessingMaterial = values.isProcessingMaterial[0];
    }
    if (values.isMulticolor === undefined || values.isMulticolor.length == 0) {
      values.isMulticolor = "0";
    } else {
      values.isMulticolor = values.isMulticolor[0];
    }
    axios
      .post<Result<any[]>>(`/clothes/sku/saveSku`, {
        data: JSON.stringify(values),
        skuId: skuId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info("保存基本信息成功");
          setIsChange(false);
          if(onSave){
            onSave()
            setLoading(false);
          }else{
            setLoading(false);
          }
          axios
              .post<Result<any[]>>(`/clothes/sku/getSkuById`, {
                uuid: skuId,
              })
              .then((resp:any) => {
                if (resp.data.Status === "1") {
                  form.setFieldsValue(resp.data.Data);
                  form.setFieldsValue({
                    categoryId:resp.data.Data.categoryId.split(",")
                  })
                }
              });
        }
      });
  };
  const onFinishFailed = (values: any) => {
    setLoading(false);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };

  function getTreeNote(dataTree: any) {
    if (dataTree != undefined) {
      return (
        <>
          {dataTree.map((item: any) => {
            return (
              <TreeNode value={item.value} title={item.title}>
                {item.children == undefined ? (
                  <></>
                ) : (
                  getTreeNote(item.children)
                )}
              </TreeNode>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  }

  const confirm = useCallback(
    function confirm() {
      Modal.confirm({
        title: i18n.t("复制商品",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("确定复制该商品吗",),
        okText: i18n.t("确定",),
        cancelText: i18n.t("cancel",),
        onOk: function () {
          const url = `/clothes/sku/copySkuBySkuId?skuId=` + skuId;
          axios.post(url).then(async (resp) => {
            if (resp.data.Status === "1") {
              await message.info(i18n.t("复制成功"));
              let data: any = resp.data.Data;
              await history.push(
                "/sku/skuManageDetail/" + data.uuid + "/edit/" + data.property
              );
              await window.location.reload();
            } else {
              await message.info(i18n.t("复制失败"));
            }
          });
        },
      });
    },
    [history, skuId]
  );
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError={true}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-wrap">
                <div className="u-form-l">
                  <Form.Item label={i18n.t("售货条形码")} name="vendingBarcode">
                    <Input/>
                  </Form.Item>
                  <Form.Item label={i18n.t("包装规格")} name="spec">
                    <Input/>
                  </Form.Item>
                  <Form.Item label={i18n.t("包装率(%)")} name="packingRate">
                    <Input type={"number"}/>
                  </Form.Item>
                  <Form.Item label={i18n.t("按大包装卖")} name="isBigPackage">
                    <Checkbox.Group>
                      <Checkbox value="1"/>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item label={i18n.t("净重(KG)")} name="unitNetWeight">
                    <Input/>
                  </Form.Item>
                </div>
                <div className="u-form-r">
                  <Form.Item
                      label={i18n.t("商品标签")}
                      name="categoryId"
                      rules={[
                        {
                          required: true,
                          validator: async (rule, value) => {
                            if (value == undefined || value.length == 0) {
                              message.error(i18n.t("商品标签不能为空"));
                              throw new Error(i18n.t("商品标签不能为空"));
                            }
                          },
                        },
                      ]}
                  >
                    <TreeSelect
                        showSearch
                        style={{width: "100%"}}
                        dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                        placeholder="Please select"
                        treeNodeFilterProp={"title"}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                    >
                      {getTreeNote(dataTree)}
                    </TreeSelect>
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改商品") &&<Button
                        type="primary"
                        className="u-form-buttonB"
                        hidden={type == "add"}
                        onClick={() => {
                          confirm();
                        }}
                    >
                      {i18n.t("复制")}
                    </Button>}
                    {usePermission("新增修改商品") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.validateFields().then(()=>{
                            form.submit();
                            setLoading(true);
                          })
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
