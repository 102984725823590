import React, {useEffect, useState} from "react";

export interface Param {
    colors:any,
    nameColorId:string,
    colorName:string
}
export default ({colors,colorName,nameColorId}:Param)=>{
    const [colorNameColor, setColorNameColor] = useState("black");
    //1:暗色
    useEffect(()=>{
        if(nameColorId == "1"){
            setColorNameColor("white");
        }
    },colors)

    return (
        <div style={{ width: "100%"}}>
            {colors &&
                colors.map((color:any,index:any)=>{
                    return <div
                        style={{
                            backgroundColor: color,
                            float: "left",
                            width: 100/colors.length+"%",
                        }}
                    >
                        <span>&nbsp;</span>
                    </div>
                })
            }
            <div style={{position:"absolute",width:"100%",color:colorNameColor,textAlign:"center"}}>{colorName}</div>
        </div>
    )
}