import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Form, Input, InputNumber, message, Modal, Table } from "antd";
import { useWindowSize } from "react-use";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { isEmpty } from "../../util/Common";
import i18n from "i18next";
import { FormInstance } from "antd/es/form";
interface Parames {
  current: any;
}
interface ModalFormProps {
  isComponent: boolean;
  visible: boolean;
  onCancel: () => void;
  addForm: FormInstance;
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
export const MasterMaterialComponent: React.FC<ModalFormProps> = ({
                                                      isComponent,
                                                      visible,
                                                      onCancel,
                                                      addForm,
                                                    }) => {
  const [form] = Form.useForm();
  const [totalForm] = Form.useForm();
  const [detailForm] = Form.useForm();
  const { width, height } = useWindowSize();
  const [component, setComponent] = useState("");
  const thisRef: Parames = useRef();
  const [componentNum, setComponentNum] = useState<any>([]);
  thisRef.current = {
    component,
    componentNum,
  };
  const [data, setData] = useState([
    {
      key: 0,
      uuid: "",
      code: "",
      italiano: "",
      english: "",
      contentNum: 0,
    },
  ]);
  const layoutModal = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  const onClose = useCallback(function () {
    onCancel();
    setData([]);
    setComponentNum([]);
    setComponent("");
    totalForm.setFieldsValue({
      totalNum: 0,
    });
  }, []);

  const setTotalNum = useCallback(function setTotalNum(arr: any) {
    let total = 0;
    arr.map((item: any) => {
      if (Number(item) != NaN) {
        total += Number(item);
      }
    });
    totalForm.setFieldsValue({
      totalNum: total,
    });
  }, []);

  const getComponentDictory = useCallback(function () {
    clearData();

    let list = [{name:"001"},{name:"001"},{name:"001"}];
    if (list.length > 0) {
      let arr: any;
      arr = [];
      let arrs: any;
      arrs = [];
      list.map((item: any, index: any) => {
        arrs.push(0);
        arr.push({
          name: item.name,
          contentNum: 0,
        });
      });
      setData(arr);
      setComponentNum(arrs);
    }

  }, []);

  const columnsData = [
    {
      title: i18n.t("主原料",),
      dataIndex: "name",
      align: "center",
    },
    {
      title: "%",
      dataIndex: "contentNum",
      align: "center",
      render: (text: any, record: any, index: any) => {
        return (
            <>
              <Input
                  className="white-input"
                  defaultValue={record.contentNum}
                  onChange={(e) => {
                    let num = e.target.value;
                    let arr = [...thisRef.current.componentNum];
                    arr[index] = num;
                    setComponentNum(arr);
                    setTotalNum(arr);
                  }}
                  onBlur={(e) => {
                    let num = e.target.value;
                    let content = "";
                    let old = thisRef.current.component;
                    let nameIndex = old.indexOf(record.name);
                    if (!isEmpty(num) && Number(num) != 0) {
                      //判断原来的变量是否存在该字段
                      if (nameIndex > -1) {
                        //存在只替换数字
                        let qian = old.substring(0, nameIndex);
                        let hou = old.substring(nameIndex);
                        let lastbIndex = qian.lastIndexOf("%");
                        let oldNum = "";
                        if (qian.indexOf(",") > -1) {
                          let lastdIndex = qian.lastIndexOf(",");
                          oldNum = qian.substring(lastdIndex + 1, lastbIndex);
                          let qianhou = qian.substring(
                              qian.lastIndexOf(oldNum) + oldNum.length
                          );
                          let qianqian = qian.substring(
                              0,
                              qian.lastIndexOf(oldNum)
                          );
                          qian = qianqian + num + qianhou;
                        } else {
                          oldNum = qian.substring(0, lastbIndex);
                          let qianqian = num;
                          let qianhou = qian.substring(
                              qian.lastIndexOf(oldNum) + oldNum.length
                          );
                          qian = qianqian + qianhou;
                        }
                        content = qian + hou;
                      } else {
                        //不存在直接拼接
                        content = old + num + "% " + record.name + ",";
                      }
                      console.log(content)
                      setComponent(content);
                    } else {
                      //判断原来的变量是否存在该字段
                      if (nameIndex > -1) {
                        //存在删除
                        let qian = old.substring(0, nameIndex);
                        let hou = old.substring(nameIndex);
                        if (qian.indexOf(",") > -1) {
                          let lastdIndex = qian.lastIndexOf(",");
                          qian = qian.substring(0, lastdIndex);
                          hou = hou.substring(record.name.length);
                        } else {
                          qian = "";
                          hou = hou.substring(record.name.length);
                        }
                        content = qian + hou;
                        console.log(content)
                        setComponent(content);
                      }
                    }
                  }}
              />
            </>
        );
      },
    },
  ];

  const clearData = useCallback(function () {
    setData([]);
    setComponentNum([]);
    setComponent("");
    totalForm.setFieldsValue({
      totalNum: 0,
    });
  }, []);

  useEffect(
      function () {
        clearData();
        let component = "";
        if (isComponent) {
          if (addForm.getFieldValue("component") != undefined) {
            component = addForm.getFieldValue("component") + ",";
          }
          setComponent(component);
        } else {
          if (addForm.getFieldValue("lining") != undefined) {
            component = addForm.getFieldValue("lining") + ",";
          }
          setComponent(component);
        }
        let isExsiest = false;
        if (component != "") {
          isExsiest = true;
        }

        let list = [{name:"001"},{name:"001"},{name:"001"}];
        if (list.length > 0) {
          let arr: any;
          arr = [];
          let arrs: any;
          arrs = [];
          list.map((item: any, index: any) => {
            arrs.push(0);
            arr.push({
              name: item.name,
              contentNum: 0,
            });
          });
          setData(arr);
          setComponentNum(arrs);
        }
      },
      [visible]
  );

  const onOk = () => {
    let count = 0;

    if (count == 0) {
      componentNum.map((item: any) => {
        count = count + Number(item);
      });
    }
    console.log(count)
    if (count == 100) {
      let values = "";
      if (component.indexOf(",") == 0) {
        values = component.substring(1, component.length - 1);
      } else {
        values = component.substring(0, component.length - 1);
      }
      if (isComponent) {
        form.setFieldsValue({
          component: values,
        });
      } else {
        form.setFieldsValue({
          lining: values,
        });
      }
      form.submit();
    } else {
      message.error(i18n.t("您的成份总和≠100%，请从新输入"));
    }
  };

  const element: ReactNode = (
      <>
        <Form
            {...layout}
            form={totalForm}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
        >
          <Form.Item name={"totalNum"} colon={false}>
            <InputNumber
                className={"component-inputnum"}
                defaultValue={0}
                readOnly={true}
                formatter={(value) => `${value}%`}
                size={"middle"}
                parser={(value: any) => value.replace("%", "")}
            />
          </Form.Item>
        </Form>
        <button type="button" className="ant-btn ant-btn-primary" onClick={onOk}>
          <span>{i18n.t("确定")}</span>
        </button>
        <button type="button" className="ant-btn" onClick={onClose}>
          <span>{i18n.t("取消")}</span>
        </button>
      </>
  );
  const detailOnOk = () => {
    detailForm.submit();
  };
  const detailElement: ReactNode = (
      <>
        <button
            style={{width: 100}}
            className="ant-btn ant-btn-primary"
            onClick={detailOnOk}
        >
          <span>{i18n.t("保存")}</span>
        </button>
      </>
  );
  const [detailVisible, setDetailVisible] = useState(false);

  const [columns] = useState<any[]>(columnsData);
  return (
      <Modal
          closable={false}
          visible={visible}
          onCancel={onCancel}
          footer={element}
          destroyOnClose={true}
          maskClosable={false}
          bodyStyle={{height: (height * 1) / 2}}
      >
        <Form form={form} {...layoutModal} layout="horizontal" name="addForm">
          <Form.Item noStyle shouldUpdate>
            <Form.Item name="component" hidden label=" ">
              <Input/>
            </Form.Item>
            <Form.Item name="lining" hidden label=" ">
              <Input/>
            </Form.Item>
          </Form.Item>
          <div className="sku-model1">{i18n.t("成份")}</div>
          <Table
              className="u-td-input"
              dataSource={data}
              columns={columns}
              scroll={{y: (height * 1) / 3}}
              pagination={false}
          />
        </Form>

      </Modal>
  );
};
