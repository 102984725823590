import React, { useRef, useState } from "react";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import EnterpriseSubAccount from "./../Children/index";
import i18n from "i18next";

export default () => {
  const thisGridRef = useRef<any>();
  const { height } = useWindowSize();
  const [enterpriseId, setEnterpriseId] = useState("");
  const [visiable, setVisiable] = useState(false);
  const [thisCorpId, setThisCorpId] = useState(0);
  const hideModal = () => {
    setVisiable(false);
    thisGridRef.current.refresh();
  };
  const columnsData = [
    {
      fieldShowName: i18n.t("app_enterprise_main_account"),
      fieldName: "parentId",
      width: 100,
      dictionary: "enterprise",
    },
    {
      fieldShowName: i18n.t("app_enterprise_merge_sub"),
      fieldName: "name",
      width: 130,
    },
    {
      fieldShowName: i18n.t("app_enterprise_sub_role"),
      fieldName: "postId",
      width: 130,
      dictionary: "base_position",
    },
    {
      fieldShowName: i18n.t("app_enterprise_operation_type"),
      fieldName: "operationType",
      width: 130,
      dictionary: "dict_operation",
    },
    {
      fieldShowName: i18n.t("app_enterprise_operation_time"),
      fieldName: "operationTime",
      width: 130,
      align: "center",
    },
    {
      fieldShowName: "IP",
      fieldName: "ip",
      width: 100,
    },
  ];
  const [columns] = useState<any[]>(columnsData);

  return (
    <div className="u-box u-basic-page">
      <div className="u-box-head">
        <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          {i18n.t("app_enterprise_operation_records")}
        </span>
      </div>
      <EnterpriseSubAccount
        enterpriseId={enterpriseId}
        thisCorpId={thisCorpId}
        visiable={visiable}
        hideModal={hideModal}
      />
      <div className="u-box-con">
        <DataGrid
          ref={thisGridRef}
          pagination={true}
          sorter={{ field: "operationTime", order: "descend" }}
          pageSize={Math.floor((height - 280) / 42)}
          rowSelectionType="checkbox"
          descriptions={columns}
          url={`/oa/appEnterpriseManagement/getMergeRecords`}
        />
      </div>
    </div>
  );
};
