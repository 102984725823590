import { BaseURL } from "../constants/url";

function createNewTab(dataUrl: string, menuName: string | undefined) {
  if (window.top && window.top.location !== window.self.location) {
    // @ts-ignore
    window.parent.postMessage(
      JSON.stringify({
        type: "tab",
        url: dataUrl,
        title: menuName,
      }),
      "*"
    );
  } else {
    window.open(BaseURL + dataUrl);
  }
}
function closeCurrentTab() {
  if (window.top && window.top.location !== window.self.location) {
    // @ts-ignore
    window.parent.postMessage(
      JSON.stringify({
        type: "closeTab"
      }),
      "*"
    );
  }
}

export { createNewTab,closeCurrentTab };
