import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const type = "DragableBodyRow";
const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  onClick,
  ...restProps
}: any) => {
  const ref = useRef<any>();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const innerStyle = { ...style };
  if (moveRow) {
    drop(drag(ref));
    innerStyle.cursor = "move";
  } else if (onClick) {
    innerStyle.cursor = "pointer";
  }
  return (
    <tr
      onClick={onClick}
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={innerStyle}
      {...restProps}
    />
  );
};

export default DragableBodyRow;
