import React, {useEffect, useState} from "react";

export interface Param {
    values: any,
    form:any
}
const OnFinishFaileds = ({values,form}:Param) => {
    const [messages, setMessages] = useState<any>();
    const [hideMessage, setHideMessage] = useState(true);

    useEffect(()=>{
        let arr: any = [];
        if(values.errorFields == undefined){
            setHideMessage(false);
        }else{
            values.errorFields.map((item: any) => {
                arr.push(<li>{item.errors}</li>);
                setHideMessage(false);
            });
            if(values.errorFields.length > 0){
                var rootNode:any = document.getElementById('root');
                rootNode.scrollIntoView(true);
            }
        }
        setMessages(arr);
    },[values])

    return (
        <div hidden={hideMessage} className="form-message">
            <ul>{messages}</ul>
        </div>
    )
};

export default OnFinishFaileds;