import React, {useCallback, useEffect, useRef, useState} from "react"
import {
    Tag,
    Typography,
    Row,
    Col,
    Form,
    Input,
    Select,
    Button,
    Checkbox,
    DatePicker,
    Table,
    Pagination,
    message, Modal, Steps, Tabs
} from 'antd'
import {Link, useHistory} from 'react-router-dom'
import {useUpdateEffect, useWindowSize} from "react-use";
import '../clothes.css'
import '../antBasicReset.css'
import {DataGrid} from "../../../../../../components/DataGrid";
import {inputType} from "../../../../../../constants/inputType";
import moment from "moment";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";

import {updateMomentType} from "../../util/DateFormat";
import {instance as axios} from "../../../../../../util/axios";
import DateTimePickerWithFormat from "../../../../../../components/DateTimePicker";
import i18n from "i18next";
import {usePermission} from "../../../../../../util/permession";


const {Title} = Typography
const {Option} = Select
const {RangePicker} = DatePicker

type DataSourceType = {
    id: React.Key;
    title?: string;
    decs?: string;
    state?: string;
    created_at?: string;
    children?: DataSourceType[];
};

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
}




export default () => {
    const [form] = Form.useForm()
    const [rowId, setRowId] = useState<any>()
    const [searchParam, setSearchParam] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [materialSearchData, setMaterialSearchData] = useState<any>({});
    const thisGridRef = useRef<any>();
    const history = useHistory();
    const [total, setTotal] = useState<any>(0);
    const columns  = [
        {
            fieldShowName: i18n.t('交货日期',),
            fieldName: "deliveryTime",
            inputType: inputType.DATE,
            width: 100
        },
        {
            fieldShowName: i18n.t('订单号码',),
            fieldName: "serialNumber",
            width: 100
        },
        {
            fieldShowName: i18n.t('订单日期',),
            fieldName: "docDate",
            inputType: inputType.DATE,
            width: 100
        },
        {
            fieldShowName: i18n.t('客户公司',),
            fieldName: "customerId",
            inputType: inputType.DICTIONARY,
            dictionary: "clothes_customer",
            width: 100
        },
        {
            fieldShowName: i18n.t('商品',),
            fieldName: "skuId",
            inputType: inputType.DICTIONARY,
            dictionary: "clothes_sku",
            width: 100
        },
        {
            fieldShowName: i18n.t('数量',),
            fieldName: 'subTotalQuantity',
        },
        {
            fieldShowName: i18n.t('尺码',),
            fieldName: 'brandId',
            inputType: inputType.DICTIONARY,
            dictionary: "clothes_customer_brand",
            width: 100
        }
        //
        // {
        //     fieldShowName: '品牌',
        //     fieldName: "docDate",
        //     inputType: inputType.DATE,
        //     width: "100"
        // },
        // {
        //     fieldShowName: '公司名称',
        //     fieldName: "customerId",
        //     inputType: inputType.DICTIONARY,
        //     dictionary: "clothes_customer",
        //     width: "100"
        // },
        //
        // {
        //     fieldShowName: '总数量',
        //     fieldName: 'total',
        // },
        // {
        //     fieldShowName: '总金额',
        //     fieldName: 'amount',
        //     width: "100"
        // },
        // {
        //     fieldShowName: '状态',
        //     fieldName: 'status',
        //
        //     inputType: inputType.DICTIONARY,
        //     dictionary: "dict_order_status",
        //     width: "100"
        // },
        // {
        //     fieldShowName: '销售预交货期',
        //     fieldName: 'preDeliveryDate',
        //     width: "100"
        // },
        // {
        //     fieldShowName: '业务负责人',
        //     fieldName: 'principalId',
        //     inputType: inputType.DICTIONARY,
        //     dictionary: "base_staff",
        //     width: "100"
        // }
    ];

    const materialColumns: any = [

        {
            fieldShowName: i18n.t('物料类型',),
            fieldName: "type",
            inputType: inputType.DICTIONARY,
            dictionary: "dict_material_type",
            width: 100
        },
        {
            fieldShowName: i18n.t('名称',),
            fieldName: "skuName",
            // inputType: inputType.DICTIONARY,
            // dictionary: "skuName",
            width: 100
        },
        {
            fieldShowName: i18n.t('描述',),
            fieldName: "skuCode",
            // inputType:inputType.DICTIONARY,
            // dictionary:"clothes_sku.code",
            width: 100
        },
        {
            fieldShowName: i18n.t('颜色',),
            fieldName: "color",
            width: 100,
            render:(text:any,row:any)=>{
                return (
                    <div style={{width:"100%",background:text}}>
                        {row.colorName}
                    </div>)
            }
        },
        {
            fieldShowName: i18n.t('单位',),
            fieldName: "unitId",
            inputType: inputType.DICTIONARY,
            width: 100,
            dictionary: "dict_sku_unit",
        },
        {
            fieldShowName: i18n.t('需求量',),
            width: 50,
            fieldName: 'needQuantity',
        },
        {
            fieldShowName: i18n.t('供应商',),
            width: 250,
            fieldName: 'supplierName',
            showTitle: true
        },
    ];

    const getTotal = useCallback((record:any)=>{
        console.log(record.quantity)
        console.log(total)
        return (!record.quantity?0:(total*record.quantity)?total*record.quantity:0)
    },[total]);

    useEffect(()=>{
        form.setFieldsValue({plannedProductionDate:moment()})
    },[])

    useUpdateEffect(() => {
        var skuId = "";
        var joinId = "";
        var rowData = thisGridRef.current.getSelectedRows();
        console.log(rowData)
        if (rowData) {
            rowData.map((item: any) => {
                    if (skuId == "") {
                        skuId += item.skuId;
                    } else {
                        skuId += "," + item.skuId;
                    }
                    if (joinId == "") {
                        joinId += item.joinId;
                    } else {
                        joinId += "," + item.joinId;
                    }
                }
            )
        }

        setMaterialSearchData({skuId: skuId,joinIds:joinId})
    }, [selectedRows]);

    useUpdateEffect(() => {
        setSelectedRows([])
    }, [searchParam]);

    /**
     * 设置时间查询条件
     */
    const setDocDateParam = ()=>{
        let tempSearchParam = {...searchParam};
        setSearchParam(null);
        var date = form.getFieldValue("docDate1");
        var searchFilter = form.getFieldValue("searchFilter");
        delete tempSearchParam.searchField_dateTime_docDate;
        delete tempSearchParam.searchField_dateTime_deliveryTime;
        if(searchFilter && searchFilter != "1" && date){
            tempSearchParam["searchField_dateTime_"+searchFilter] = date[0].format("YYYY-MM-DD 00:00:00") + "|" + date[1].format("YYYY-MM-DD 23:59:59");
        }
        setSearchParam(tempSearchParam);
    }

    /**
     * 新建
     */
    const add = ()=>{
        var selectRows = thisGridRef.current.getSelectedRows();
        if(!selectRows || selectRows.length ==0){
            message.error(i18n.t("请勾选一条数据"));
            return;
        }
        if(!selectRows || selectRows.length !=1){
            message.error(i18n.t("只能勾选一条数据"));
            return;
        }
        var selectRow = selectRows[0];
        var param:any = {};
        var orderData = form.getFieldsValue();
        updateMomentType(orderData);
        param.skuId = selectRow.skuId;
        param.sizeId = selectRow.skuSizeId;
        param.saleOrderId = selectRow.saleOrderId;
        param.joinId = selectRow.joinId;
        param.orderData = JSON.stringify(orderData);
        axios.post("/clothes/produceOrder/saveProduceOrder",param)
            .then(function (resp) {
                if (resp.data.Status == 1) {
                    message.success(i18n.t("新增成功!"));
                    // history.replace("/clothes/produceOrder");
                    thisGridRef.current.refresh();
                }
            })
    }
    return (
        <div className="u-container">
            <Title level={4}>{i18n.t("生产订单")}</Title>
            <div className="u-divider-horizontal"></div>
            <div>
                <Form
                    form={form}
                    {...formItemLayout}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="serialNumber"
                                label={i18n.t("生产计划单")}
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="plannedProductionDate"
                                label={i18n.t("计划生产日期")}
                            >
                                <DateTimePickerWithFormat showTime={false}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="searchFilter"
                                label={i18n.t("客户订单查询")}
                            >
                                <Select onChange={(v: any) => {
                                    setDocDateParam();
                                }}>
                                    <Option value={"1"}>{i18n.t("全部订单")}</Option>
                                    <Option value={"deliveryTime"}>{i18n.t("订单交货日期")}</Option>
                                    <Option value={"docDate"}>{i18n.t("订单日期")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="产品"*/}
                        {/*    >*/}
                        {/*        <DictionarySelect entityName={"clothes_sku"} onChange={(v: any) => {*/}
                        {/*            let tempSearchParam = {...searchParam};*/}
                        {/*            setSearchParam(null);*/}
                        {/*            tempSearchParam.searchField_dictionary_skuId = v;*/}
                        {/*            setSearchParam(tempSearchParam);*/}
                        {/*        }} allowClear={true}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="docDate1"
                                label={i18n.t("时间查询")}
                            >
                                <DateTimePickerWithFormat
                                    rangePicker={true}
                                    onChange={(v: any) => {
                                        setDocDateParam();
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        name="customerId"*/}
                        {/*        label="客户"*/}
                        {/*    >*/}
                        {/*        <DictionarySelect entityName={"clothes_customer"} onChange={(v) => {*/}
                        {/*            let tempSearchParam = {...searchParam};*/}
                        {/*            setSearchParam(null);*/}
                        {/*            tempSearchParam.searchField_dictionary_customerId = v;*/}
                        {/*            setSearchParam(tempSearchParam);*/}
                        {/*        }} allowClear={true}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                    </Row>
                    <Row gutter={24}>


                        <Col span={8}>
                            <Form.Item
                                name="客户订单号"
                                label={i18n.t("客户订单号")}
                            >
                                <Input onChange={(v: any) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_string_serialNumber = "1|" + v.target.value;
                                    setSearchParam(tempSearchParam);
                                }} allowClear={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>


            <DataGrid
                ref={thisGridRef}
                sorter={{field: "createTime", order: "descend"}}
                pageSize={5}
                pagination={false}
                rowSelectionType="checkbox"
                descriptions={columns}
                url={`/clothes/produceOrder/getSaleOrderDetailList`}
                searchData={searchParam}
                onClick={(record: any) => {
                    // setRowDocId(record.uuid);
                    setRowId(record.key);

                }}
                expandedRow={(record: any) => {
                    var tempData = [];
                    tempData.push(record);
                    return (
                        <DataGrid descriptions={record.childrenColumn} dataSource={tempData}/>
                    )
                }}
                onSelected={(recordIds: any) => {
                    let tempTotal = 0;
                    recordIds.map((recordId: any) => {
                        let record = thisGridRef.current.getDataItem(recordId);
                        tempTotal = tempTotal + (record.subTotalQuantity ? record.subTotalQuantity : 0)
                    })
                    console.log(tempTotal)
                    setTotal(tempTotal);
                    setSelectedRows(recordIds);
                }}
                isShowSearch={true}
            />

            <div className="u-btn-toolbar" style={{padding: '0'}}>
                {usePermission("新增修改生产订单") && <Button htmlType="button" onClick={add}>
                    {i18n.t("确认")}
                </Button>}
                {usePermission("新增修改生产订单") &&<Button type="primary" htmlType="button">
                    {i18n.t("应用")}
                </Button>}
                <Link to={"/clothes/produceOrder"}>
                    <Button type="primary" htmlType="button">
                        {i18n.t("取消")}
                    </Button>
                </Link>


            </div>

            {
                selectedRows.length > 0 &&
                <DataGrid descriptions={materialColumns} url={"/clothes/sku/getMaterialCacheBySkuIdOrderId"}
                          searchData={materialSearchData} isShowSearch={false} pagination={false} loadAllDictionary={true}
                />
            }


        </div>
    );
}