import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader
} from "antd";
import {DataGrid} from "../../../../../components/DataGrid";
import i18n from "i18next";


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState({type:"0"});


    return (
        <div>
            <PageHeader
                title={i18n.t("(订单) 原材料预计用量统计(按颜色)")}
            />
            <Row gutter={[24, 8]}>
                <Space>

                    <Form form={form} style={{marginLeft: 10}}>
                        <Row>
                            <Space>

                                <Form.Item name={"range"}>
                                    <DatePicker picker="month"/>
                                </Form.Item>

                                <Form.Item name={"type"}>
                                    <Input placeholder={i18n.t("颜色查询")}/>
                                    {/*<DictionarySelect searchUrl={"/clothes/ddd"} entityName={""}/>*/}
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => {
                                        var multicolor = form.getFieldValue("type")
                                        var ranges: any = form.getFieldValue("range")
                                        const temp: any = {};
                                        temp.type = "0";//原材料
                                        temp.colorName = multicolor;//原材料
                                        if (ranges) {
                                            temp.startDate = ranges.format("YYYY-MM");
                                            setSearchData(temp)
                                        } else {
                                            setSearchData(temp)
                                        }
                                    }}>{i18n.t("搜索")}</Button>
                                    <Button onClick={() => {
                                        form.setFieldsValue({type: undefined, range: undefined})

                                    }}>{i18n.t("重置")}</Button>
                                </Form.Item>
                            </Space>
                        </Row>
                    </Form>

                </Space>
            </Row>


            <DataGrid isShowSearch={true} url={"/clothes/statistic/getYclColorPreAmount"} searchData={searchData}
                      descriptions={[
                          {
                              fieldName: "docDate",
                              fieldShowName: i18n.t("时间",)
                          },
                          {
                              fieldName: "colorName",
                              fieldShowName: i18n.t("颜色",)
                          },

                          {
                              fieldName: "amount",
                              fieldShowName: i18n.t("预计用量",)
                          },

                      ]}/>

        </div>
    );
}