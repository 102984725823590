import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, PageHeader
} from "antd";
import * as echarts from 'echarts';
import {instance as axios} from "../../../../../util/axios";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import {isEmpty, isNullOrUndefined} from "../util/Common";
import {Option} from "antd/es/mentions";
import i18n from "i18next";


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState({type:"0"});
    const [tableData,setTableData] = useState([]);
    const [gridSearchData, setGridSearchData] = useState();

    useEffect(()=>{
        let element = document.getElementById('main1');
        let myChart = echarts.init(element as HTMLDivElement);


        var option2 = {
            tooltip: {
                trigger: 'item',
                formatter: '{b}:{d} ',
            },
            legend: {
                bottom: '5%',
                left: 'center'
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        show: true
                    },
                    label: {
                        formatter: '{b}:{d} %',
                    },
                }
            ]
        };
        myChart.showLoading()
        myChart.setOption(option2);
        let data: any =  Object.assign(searchData==undefined?{}:searchData,gridSearchData==undefined?{}:gridSearchData);

        axios
            .post("/clothes/statistic/getYclPreAmount",data).then(async (resp:any)=>{
                myChart.hideLoading()
            if(resp.data.Status == 1){
                var data = resp.data.Data.data
                var tempData:any = [];
                var count = 0;
                console.log(tempData)

                if(data){
                    setTableData(data)
                    await Promise.all(data.map(async (item:any)=>{
                        var itemData:any = {};
                        itemData.name = item.multicolor;
                        itemData.value = item.value;
                        count+=item.value;
                        tempData.push(itemData)
                    }))

                    myChart.setOption({
                        title:{
                            text:i18n.t("预计量")+": \n"+ count,
                            left: 'center',
                            top: 'center'
                        },
                        series: [
                            {
                                data: tempData
                            }
                        ]
                    })
                }
            }
        })

    },[searchData,gridSearchData])

    return (
        <div>
            <PageHeader
                title={i18n.t("(订单) 原材料预计用量统计")}
            />
            <Row gutter={[24, 8]}>
                <Space>

                    <Form form={form} style={{marginLeft: 10}}>
                        <Row>
                            <Space>

                                <Form.Item name={"range"}>
                                    <DatePicker picker="month"/>
                                </Form.Item>

                                <Form.Item name={"type"}>
                                    <Select placeholder={i18n.t("用料选择")}>
                                        <Option value={"1"}>{i18n.t("有色")}</Option>
                                        <Option value={"0"}>{i18n.t("白胚料")}</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => {
                                        var multicolor = form.getFieldValue("type")
                                        var ranges: any = form.getFieldValue("range")
                                        const temp: any = {};
                                        temp.type = "0";//原材料
                                        temp.multicolor = multicolor;//原材料
                                        if (ranges) {
                                            temp.startDate = ranges.format("YYYY-MM");
                                            setSearchData(temp)
                                        } else {
                                            setSearchData(temp)
                                        }
                                    }}>{i18n.t("搜索")}</Button>
                                    <Button onClick={() => {
                                        form.setFieldsValue({type: undefined, range: undefined})

                                    }}>{i18n.t("重置")}</Button>
                                </Form.Item>
                            </Space>
                        </Row>
                    </Form>
                    <Space>

                    </Space>
                </Space>
            </Row>

            <Row>
                <Col span={24}>
                    <div id={"main1"} style={{height: "400px"}}/>
                </Col>
            </Row>

            <DataGrid isShowSearch={true}  url={"/clothes/statistic/getYclPreAmount"} searchData={searchData}
                      onSearch={(data)=>{
                          setGridSearchData(data)

                      }}
                      descriptions={[
                {
                    fieldName: "docDate",
                    fieldShowName: i18n.t("时间",)
                },
                {
                    fieldName: "multicolor",
                    fieldShowName: i18n.t("用料类型",)
                },

                {
                    fieldName: "value",
                    fieldShowName: i18n.t("预计用量",)
                },

            ]}/>

        </div>
    );
}