import { instance as axios } from "./axios";
import { BaseConfig, Result } from "../schemas/common";
import { ConfigId, ConfigName } from "../enum";

async function getConfigByName(name: ConfigName): Promise<BaseConfig> {
  const configResp = await axios.get<Result<BaseConfig>>(
    `/oa/crmConfig/getCrmConfigByName?name=${name}`
  );
  return configResp.data.Data;
}

async function getConfigById(id: ConfigId): Promise<BaseConfig> {
  const configResp = await axios.get<Result<BaseConfig>>(
    `/oa/crmConfig/getCrmConfig?id=${id}`
  );
  return configResp.data.Data;
}
//获取时间偏移量
async function getTimeOffset(): Promise<number> {
  const configResp = await axios.get<Result<number>>(
    `/clothes/timeZone/getTimeZone`
  );
  return configResp.data.Data;
}
//获取日期格式
async function getDateTimeFormat(): Promise<any> {
  const configResp = await axios.get<Result<any>>(
    `/clothes/timeZone/getDateFormat`
  );
  return configResp.data.Data;
}

export { getConfigByName, getConfigById ,getTimeOffset,getDateTimeFormat};
