export enum ConfigId {
  /**
   * 项目新建方式：直接新建、从客户新建、从商机转化
   */
  ProjectLaunchMode = "ab77c83744464c30a32d15df68c8ef23",
}

export enum ConfigName {
  HideProductInContactRecord = "隐藏联系记录意向产品",
  DisplayCustomerTransferButton = "显示转交按钮",
  GonghaiContactsVisible = "公海客户联系人是否可见",
  GonghaiMobileVisible = "公海客户电话是否可见",
}

export enum DeliveryOrderStatus {
  /**
   * 草稿
   */
  DRAFT = "1",
  /**
   * 审核中
   */
  APPROVAL = "2",
  /**
   * 未清
   */
  OUTSTANDING = "3",
  /**
   * 已完成
   */
  COMPLETED = "4",
  /**
   * 已取消
   */
  CANCELLED = "5",
}

export enum InventoryType {
  //入库
  STORAGE = "0",
  //出库
  DELIVERY = "1",
  //退货
  RETURN = "2",
  //发票
  INVOICE = "3",
  //转储
  TRANSFER = "4",
  //不限制
  UNLIMITED = "5",
}
