import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import { Form, Input, Modal, message, Space, Button } from "antd";
import "../../CSS/customer.css";
import { useUpdateEffect, useWindowSize } from "react-use";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import i18n from "i18next";
import { FormInstance } from "antd/es/form";
import { DataGrid } from "../../../../../../components/DataGrid";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../../components/EditForm";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import GetRender from "../../Sku/Detail/GetRender";
import { Description } from "../../../../../../schemas/description";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Params {
  customerId: string;
  brandId: string;
  sizeVisiable: boolean;
  sizeHideModal: () => void;
  brandForm: FormInstance;
  templateId: string;
}
export default ({
  customerId,
  brandId,
  sizeVisiable,
  sizeHideModal,
  brandForm,
  templateId,
}: Params) => {
  const { width, height } = useWindowSize();
  const [detailForm] = Form.useForm();
  const detailOnOk = () => {
    detailForm.submit();
  };
  const [isHiddenAdd, setIsHiddenAdd] = useState(false);
  const hideSizeModal = useCallback(function hideSizeModal() {
    setIsHiddenAdd(false);
    sizeHideModal();
  },[]);
  const detailElement: ReactNode = (
      <>
        <>
          <button
              type="button"
              className="ant-btn ant-btn-primary"
              onClick={detailOnOk}
          >
            <span>{i18n.t("确定")}</span>
          </button>
          <button type="button" className="ant-btn" onClick={hideSizeModal}>
            <span>{i18n.t("取消")}</span>
          </button>
        </>
      </>
  );

  const onDetailFinish = async (values: any) => {
    await saveDataGrid(dataGridRef);
    try {
      let sizeList = await dataGridRef.current.getData();
      values.sizeList = sizeList;
      values.uuid = templateId;
      values.isCurrency = 0;
      values.customerId = customerId;
      values.brandId = brandId;
      await axios
        .post<Result<any[]>>(`/clothes/size/saveTemplateAndSizeList`, {
          data: JSON.stringify(values),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            let data = resp.data.Data;
            brandForm.setFieldsValue({
              skuSizeId: data,
            });
            hideSizeModal();
          }
        });
    } catch (e) {
      message.error(i18n.t("saveError"));
    }
  };

  const dataGridRef = useRef<any>(null);
  const [tableForm] = Form.useForm();
  const [urlData, setUrlData] = useState(
    "clothes/size/getSizeManageListByTemplateId?templateId=" + templateId
  );
  const [namespace, setNamespace] = useState("clothes_size.all");
  const [entityName, setEntityName] = useState("clothes_size");
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const onDetailSave = async () => {};
  function format() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(currentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(dataGridRef, onDetailSave, entityName, false),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  useEffect(
    function () {
      console.log("templateId:", templateId);
    },
    [sizeVisiable]
  );

  return (
      <Modal
          title={i18n.t("尺码表")}
          className="add-component"
          visible={sizeVisiable}
          destroyOnClose={true}
          onCancel={hideSizeModal}
          footer={detailElement}
          width={(width * 1) / 1.8}
          bodyStyle={{height: (height * 1) / 2}}
          maskClosable={false}
      >
        <Form
            {...layout}
            form={detailForm}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
            preserve={false}
            onFinish={onDetailFinish}
        >
          <Form.Item name="uuid" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="isCurrency" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="customerId" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="brandId" hidden>
            <Input/>
          </Form.Item>
          <Form.Item label={i18n.t("尺寸表名称")} className="size-code" name="name">
            <Input/>
          </Form.Item>
          <Form.Item
              label={i18n.t("尺寸表简介")}
              className="size-code"
              name={"briefIntroduction"}
          >
            <Input/>
          </Form.Item>
          <Form.Item className="u-form-brand-size">
            <DataGrid
                ref={dataGridRef}
                url={urlData}
                descriptions={format()}
                pagination={false}
                onClick={async (record: any) => {
                  await editOtherLine(dataGridRef, record);
                }}
                loadAllDictionary={true}
                isShowSearch={false}
                form={tableForm}
            />
          </Form.Item>
          <div hidden={isHiddenAdd}>
            <Space>
              <Button
                  style={{width: 100}}
                  onClick={async () => {
                    await saveDataGrid(dataGridRef);
                    let list = dataGridRef.current.getData();
                    if (list.length < 3) {
                      dataGridRef.current.add();
                    } else {
                      dataGridRef.current.add();
                      setIsHiddenAdd(true);
                    }
                  }}
              >
                {i18n.t("add")}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
  );
};
