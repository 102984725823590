import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { TableGrid } from "../../../../../../../components/TableGrid";
import { ProjectFeeds } from "../Feeds";
import { File } from "../file";
import { SessionContext } from "../../../../../index";
import Session from "../../../../../../../schemas/session";
import { DynamicForm } from "../../../../../../../schemas/dynamicForm";
import { instance as axios } from "../../../../../../../util/axios";
import { Result } from "../../../../../../../schemas/common";
import { DynamicTab } from "../../../components/DynamicTab";
const { TabPane } = Tabs;

export interface ProjectSProps {
  id: string;
  advisorId: string;
  permission: string[];
}

export const Archive = ({ id, advisorId, permission }: ProjectSProps) => {
  const session: Session = useContext(SessionContext);

  /**
   * 没有分组的单据
   */
  const [dynamicTabConfigs, setDynamicTabConfigs] = useState<DynamicForm[]>([]);
  /**
   * 独立的单据
   */
  const [
    standaloneDynamicTabConfigs,
    setStandaloneDynamicTabConfigs,
  ] = useState<DynamicForm[]>([]);
  useEffect(
    function () {
      axios
        .post<Result<DynamicForm[]>>(
          "/crm/dynamicTab/getSelectedList?host=project&hostPrimaryKey=" + id
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            const data = resp.data.Data;
            if (data.length === 0) {
              return;
            }
            setDynamicTabConfigs(data.filter((item) => !item.group));
            setStandaloneDynamicTabConfigs(data.filter((item) => item.group));
          }
        });
    },
    [id, advisorId, permission, session.id]
  );
  return (
    <>
      <Tabs>
        <TabPane key={"info"} tab={"基本信息"}>
          <TableGrid id={id} entityName="crm_project" columnCount={3} />
        </TabPane>
        <TabPane key={"file"} tab={"文件库"}>
          <File
            categories={""}
            projectId={id}
            editable={advisorId === session.id}
          />
        </TabPane>
        <TabPane key={"forms"} tab={"单据"}>
          {dynamicTabConfigs.map((tab) => (
            <>
              <DynamicTab
                editable={
                  advisorId === session.id ||
                  permission.some(
                    (item: string) => item === tab.workflowTemplateId
                  )
                }
                key={tab.tableName}
                id={id}
                host="project"
                advisorId={advisorId}
                data={{
                  ...tab,
                  entityName: tab.tableName,
                  uuid: tab.uuid,
                }}
              />
            </>
          ))}
        </TabPane>
        {standaloneDynamicTabConfigs.map((item) => (
          <TabPane tab={item.formName} key={item.tableName}>
            <DynamicTab
              key={item.tableName}
              id={id}
              host="project"
              advisorId={advisorId}
              data={{
                ...item,
                entityName: item.tableName,
                uuid: item.uuid,
              }}
            />
          </TabPane>
        ))}
        <TabPane key={"feed"} tab={"动态"}>
          <ProjectFeeds projectId={id} />
        </TabPane>
      </Tabs>
    </>
  );
};
