import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {Button, Modal, message, DatePicker, Tag} from "antd";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import {usePermission} from "../../../../../util/permession";
import storage from "../util/Stroage";
import {createNewTab} from "../../../../../util/tyy";
import { Tabs } from 'antd';
import DetailList from "./DetailList";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

export default () => {
  const thisGridRef = useRef<any>();
  const { height } = useWindowSize();
  const [urlData, setUrlData] = useState("/clothes/orderPur/getOrderPurList");
  const [totalQty, setTotalQty] = useState("");
  const [totalAmo, setTotalAmo] = useState("");
  const generateUUID = useCallback(function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }, []);
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("删除提示"),
      okText: i18n.t("okText"),
      cancelText: i18n.t("cancelText"),
      onOk: function () {
        const url = `/clothes/orderPur/deleteOrderPurByUuid?orderId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("deleteSuccess"));
            thisGridRef.current.refresh();
          } else {
            message.info(i18n.t("deleteError"));
          }
        });
      },
    });
  }, []);
  const history = useHistory();
  const columnsData = [
    {
      fieldShowName: i18n.t("时间",),
      fieldName: "purchaseTime",
      inputType:4,
      width: 200,
    },
    {
      fieldShowName: i18n.t("单号",),
      fieldName: "serialNumber",
      width: 200,
    },
    {
      fieldShowName: i18n.t("采购员",),
      fieldName: "purchaseStaff",
      inputType:6,
      dictionary: "base_staff",
      width: 180,
    },
    {
      fieldShowName: i18n.t("供应商",),
      fieldName: "supplier",
      inputType:6,
      dictionary: "clothes_customer",
      width: 180,
    },
    {
      fieldShowName: i18n.t("总数量",),
      fieldName: "quantity",
      width: 180,
    },
    {
      fieldShowName: i18n.t("总金额",),
      fieldName: "amount",
      width: 180,
    },
    {
      fieldShowName: i18n.t("付款状态",),
      fieldName: "paymentStatus",
      width: 180,
      render:(text:any,record:any)=>{
        setTotalAmo(record.totalAmo)
        setTotalQty(record.totalQty)

        if (text == '2') {
          return (
              <Tag color="#87d068">{i18n.t("已付款")}</Tag>
          );
        } else if (text == '1') {
          return (
              <Tag color="#2db7f5">{i18n.t("部分付款")}</Tag>
          );
        } else {
          return (
              <Tag color="#f50">{i18n.t("未付款")}</Tag>
          );
        }
      }
    },
    /*{
      fieldShowName: i18n.t("操作",),
      fieldName: "uuid",
      isHiddenSearch: true,
      align: "center",
      width: 50,
      fixed: "right" as "right",
      render: function useAa(text: any, record: any){
        return (
            usePermission("删除采购订单") && <a
                href="#"
                className="deleteA"
                onClick={(e: any) => {
                  confirm(record.uuid);
                  e.stopPropagation();
                }}
            >
              {i18n.t("删除")}
            </a>
        );
      },
    },*/
  ];
  const [columns] = useState<any[]>(columnsData);

  return (
      <>
        <Tabs defaultActiveKey="1" >
          {/*//采购订单列表*/}
          <TabPane tab={i18n.t("入库订单")} key="1">
            <div className="u-box u-basic-page">
              <div className="u-box-head">
          <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          {i18n.t("时间区间")}：
          <DateTimePickerWithFormat
              rangePicker={true}
              onChange={(dateString: any) => {
                if (dateString) {
                  if (dateString.length == 2) {
                    console.log(dateString)
                    let startTime = dateString[0].format("YYYY-MM-DD") + " 00:00:00";
                    let endTime = dateString[1].format("YYYY-MM-DD") + " 23:59:59";
                    setUrlData(
                        "/clothes/orderPur/getOrderPurList?startTime=" +
                        startTime +
                        "&endTime=" +
                        endTime
                    );
                  }
                } else {
                  setUrlData(
                      "/clothes/orderPur/getOrderPurList"
                  );
                }
                //  else if (dateString.length == 1) {
                //   let startTime = dateString[0] + " 00:00:00";
                //   setUrlData(
                //     "/clothes/orderPur/getOrderPurList?startTime=" + startTime
                //   );
                // }
              }}
          />
        </span>
                <span className="u-box-title">
                  入库总数量:{totalQty}
                </span>

                <span className="u-box-title" style={{paddingLeft:50}}>
                  入库总金额:{totalAmo}
                </span>

                <div className="u-box-toolbar">
                  <div className="u-icon-list u-btn-list">
                    <Button
                        type="primary"
                        onClick={() => {
                          console.log(111
                          )
                          // 订单付款
                          var ids = thisGridRef.current.getSelectedIds();
                          var selectedRows: any[] = thisGridRef.current.getSelectedRows();
                          if (ids.length <= 0) {
                            message.error(i18n.t("必须选择一条数据"));
                            return false;
                          }
                          selectedRows.map((item) => {
                            if (selectedRows.length > 0 && item.paymentStatus > 0) {
                              message.info(i18n.t("只能批量结算未结算的状态"));
                              throw new DOMException();
                            }
                          })

                          var checkedIds = ids.join(",");
                          const param = {
                            orderIds: checkedIds,
                            paymentType: 1//订单付款
                          }
                          axios.post("/clothes/orderPur/payment",param).then((resp)=>{
                            if(resp.data.Status == 1){
                              message.success(i18n.t("付款成功"));
                              thisGridRef.current.refresh();
                            }else{
                              message.success(i18n.t("付款失败"));
                            }
                          })

                        }}
                        className="u-btn-add"
                    >
                      {i18n.t("付款")}
                    </Button>
                    {usePermission("新增修改采购订单") && <Button
                        type="primary"
                        onClick={() => {
                          createNewTab(storage.get("reactUrl")+"/clothes/orderPurDetail/" + generateUUID() + "/add",i18n.t('采购订单明细',))
                        }}
                        className="u-btn-add"
                    >
                      {i18n.t("新增")}
                    </Button>}
                  </div>
                </div>
              </div>

              <div className="u-box-con">
                <DataGrid
                    ref={thisGridRef}
                    pagination={true}
                    sorter={{field: "createTime", order: "descend"}}
                    pageSize={Math.floor((height - 280) / 42)}
                    rowSelectionType="checkbox"
                    descriptions={columns}
                    // isShowSearch={false}
                    onClick={(record: any) => {
                      createNewTab(storage.get("reactUrl")+"/clothes/orderPurDetail/" + record.uuid + "/edit",i18n.t('入库订单明细',))
                    }}
                    url={urlData}
                    isShowExport={true}

                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={i18n.t("入库物料详情")} key="2">
            <DetailList/>
          </TabPane>
        </Tabs>
      </>

  );
};
