import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { Tabs, Input, message } from "antd";
import { DataGrid } from "../../../../../components/DataGrid";
import { instance as axios } from "../../../../../util/axios";

const { TabPane } = Tabs;
const { Search } = Input;

const BarcodeScanning = function () {
  const history = useHistory();
  const { height } = useWindowSize();
  const rukuGridRef = useRef<any>(null);
  const barcodeInputRef = useRef<any>(null);
  const allRukuGridRef = useRef<any>(null);
  const [position, setPosition] = useState("");
  const [barcode, setBarcode] = useState("");
  const [barcodeIds, setBarcodeIds] = useState("0");

  function getBarcodeList(
    tab: string,
    key: string,
    ids: string,
    gridRef: React.MutableRefObject<any>
  ) {
    return (
      <TabPane tab={tab} key={key}>
        <DataGrid
          style={{ marginBottom: 8 }}
          pagination={true}
          pageSize={Math.floor((height - 280) / 42)}
          ref={gridRef}
          sorter={{ field: "inputTime", order: "descend" }}
          descriptions={[
            {
              fieldName: "radCategory",
              fieldShowName: "系列",
              width: 100,
              dictionary: "rad_sku_category",
            },
            {
              fieldName: "radId",
              fieldShowName: "型号",
              width: 100,
              dictionary: "rad_sku",
            },
            {
              fieldName: "pieces",
              fieldShowName: "片数",
              width: 100,
            },
            {
              fieldName: "centerCategory",
              fieldShowName: "高度分类",
              width: 100,
              dictionary: "dict_rad_center_category",
            },
            {
              fieldName: "centerSpacing",
              fieldShowName: "高度",
              width: 100,
            },
            {
              fieldName: "caliber",
              fieldShowName: "口径",
              width: 100,
              dictionary: "dict_rad_caliber",
            },
            {
              fieldName: "color",
              fieldShowName: "颜色",
              width: 100,
              dictionary: "dict_rad_color",
            },
            {
              fieldName: "barcode",
              fieldShowName: "条形码",
              width: 100,
            },
            {
              fieldName: "inputTime",
              fieldShowName: "入库时间",
              width: 100,
            },
            {
              fieldName: "position",
              fieldShowName: "货架",
              dictionary: "rad_position",
              width: 100,
            },
          ]}
          url={`/erp/barcode/getStorageList?barcodeIds=${ids}`}
        />
      </TabPane>
    );
  }
  return (
    <Tabs>
      <TabPane tab="入库" key="1">
        <>
          <Input
            placeholder="请输入货架条码"
            autoFocus
            value={position}
            onChange={(event) => {
              setPosition(event.target.value);
            }}
            onPressEnter={(event) => {
              barcodeInputRef.current.focus();
            }}
            style={{ width: 252, marginBottom: 15, marginRight: 30 }}
          />
          <Search
            placeholder="请输入条码"
            enterButton="确定"
            ref={barcodeInputRef}
            style={{ width: 252, marginBottom: 15 }}
            value={barcode}
            onChange={(event) => {
              setBarcode(event.target.value);
            }}
            onSearch={(value) => {
              axios
                .post("/erp/barcode/storage", {
                  barcode: value,
                  position: position,
                })
                .then((resp) => {
                  if (resp.data.Status == "1") {
                    message.info("入库成功！");
                    setBarcodeIds(barcodeIds + "," + resp.data.Data);
                    setBarcode("");
                    barcodeInputRef.current.focus();
                    rukuGridRef.current.refresh();
                  } else {
                    message.info(resp.data.Message);
                  }
                });
            }}
          />
          <h2
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#1890ff",
            }}
          >
            扫码记录
          </h2>
          <Tabs>
            {getBarcodeList("本次扫码记录", "3", barcodeIds, rukuGridRef)}
            {getBarcodeList("全部扫码记录", "4", "", allRukuGridRef)}
          </Tabs>
        </>
      </TabPane>
      <TabPane tab="出库" key="2">
        {height && (
          <>
            <DataGrid
              style={{ marginBottom: 8 }}
              pagination={true}
              pageSize={Math.floor((height - 130) / 42)}
              sorter={{ field: "createTime", order: "descend" }}
              descriptions={[
                {
                  fieldName: "serialNumber",
                  fieldShowName: "出库单单号",
                  width: 100,
                },
                {
                  fieldName: "orderNumber",
                  fieldShowName: "订单单号",
                  width: 200,
                },
                {
                  fieldName: "customerId",
                  fieldShowName: "客户",
                  width: 100,
                },
                {
                  fieldName: "outQuantities",
                  fieldShowName: "数量",
                  width: 100,
                },
                {
                  fieldName: "createTime",
                  fieldShowName: "制单时间",
                  width: 100,
                },
                {
                  fieldShowName: "操作",
                  width: 50,
                  render: (_: any, record: any) => (
                    <a
                      onClick={() =>
                        history.push(
                          `/rad/barcode/scanning/chuku/${record.uuid}/${record.orderId}`
                        )
                      }
                    >
                      扫描
                    </a>
                  ),
                },
              ]}
              url={`/erp/radOut/getList?listFilter= ifNull(st.outQuantities,0)>0 and st.status=5 `}
            />
          </>
        )}
      </TabPane>
    </Tabs>
  );
};
export default BarcodeScanning;
