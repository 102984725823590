import React, { useEffect, useRef, useState } from "react";
import { Button, message, Popconfirm, Tabs } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { DataGrid } from "../../../../../../components/DataGrid";
import { get } from "../../../../../../util/description";
import { PlusOutlined } from "@ant-design/icons";
import { Result } from "../../../../../../schemas/common";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import i18n from "i18next";
const { TabPane } = Tabs;

export interface Description extends FieldDescription {
  editable?: boolean;
}

export default function List() {
  const gridRef = useRef<any>(null);
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  useEffect(function () {
    get("crm_project_process_designer").then((descriptions: Description[]) => {
      descriptions.forEach((description) => {
        description.editable = true;
      });
      const formattedDescriptions = JSON.parse(JSON.stringify(descriptions));
      formattedDescriptions.push({
        fieldShowName: "操作",
        render: getRender("", gridRef),
        align: "center",
        width: "100px",
      });
      setDescriptions(formattedDescriptions);
    });
  }, []);
  function getRender(entityName: string, gridRef: React.MutableRefObject<any>) {
    return function (_: any, record: any) {
      return (
        <>
          {gridRef.current.isEditing(record) ? (
            <>
              <Button
                type={"link"}
                style={{ marginRight: 16 }}
                onClick={async () => {
                  await gridRef.current.save(record.key);
                  const data = gridRef.current.getDataItem(record.key);
                  const url = `/crm/project/process/saveProcess`;
                  axios
                    .post<Result<void>>(url, { data: JSON.stringify([data]) })
                    .then(() => {
                      message.info("保存成功");
                      gridRef.current.refresh();
                    });
                }}
              >
                {i18n.t("确认")}
              </Button>
              {!record.key ? (
                <Button
                  type={"link"}
                  onClick={() => gridRef.current.remove(record.key)}
                >
                  撤销
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {gridRef.current.isOnEdit() ? (
                <>
                  <Button
                    type={"link"}
                    style={{ marginRight: 16, color: "gray" }}
                  >
                    编辑
                  </Button>
                  <Button type={"link"} style={{ color: "gray" }}>
                    删除
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type={"link"}
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      gridRef.current.edit(record);
                    }}
                  >
                    编辑
                  </Button>
                  <Popconfirm
                    title="确定要删除吗?"
                    onConfirm={() => {
                      const url = `/crm/project/process/delProcess?uuid=${record.key}`;
                      axios.delete<Result<void>>(url).then(() => {
                        message.info("删除成功");
                        gridRef.current.refresh();
                      });
                    }}
                  >
                    <Button type={"link"}>删除</Button>
                  </Popconfirm>
                </>
              )}
            </>
          )}
        </>
      );
    };
  }
  return (
    <>
      <Tabs>
        <TabPane tab="项目阶段设置" key="1">
          <DataGrid
            style={{ marginBottom: 8 }}
            pagination={false}
            dragable={true}
            ref={gridRef}
            descriptions={descriptions}
            url={`/crm/project/process/list`}
          />
          <Button
            icon={<PlusOutlined />}
            type={"primary"}
            onClick={() => gridRef.current.add()}
          >
            新建
          </Button>
        </TabPane>
      </Tabs>
    </>
  );
}
