import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, Button, Modal, message, Space } from "antd";
import {
  CaretDownOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import UploadWrapper from "../../../../../components/UploadWrapper";

export default () => {
  const thisGridRef = useRef<any>();
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState(
    false
  );

  const { height } = useWindowSize();

  const columnsData = [
    {
      fieldShowName: i18n.t("单号",),
      fieldName: "serialNumber",
      width: 200,
      render:(text: any, record: any) => {
        return <span onClick={(e:any) => {
          var top:any = window.parent;
          top.postMessage(JSON.stringify({title:"销售订单",type:"tab",url:"/wf/form/vsheet/form?workflowTemplateId=c09223b3908045a3847775ebd7cb9ce5&id="+record.uuid+"&version=默认"}),'*')
        }}>{text}</span>;
      }
    },
    {
      fieldShowName: "单据类型",
      fieldName: "docType",
      width: 180,
      dictionary: "dict_order_type",
      showTitle: true,
    },
    {
      fieldShowName: "订单日期",
      fieldName: "orderDate",
      dataType:4,
      width: 180,
      showTitle: true,
    },
    {
      fieldShowName: "总数量",
      fieldName: "quantity",
      width: 180,
      showTitle: true,
    },
    {
      fieldShowName: "订单状态",
      fieldName: "orderStatus",
      width: 180,
      dictionary: "dict_order_status",
      showTitle: true,
    },
    {
      fieldShowName: "生产状态",
      fieldName: "producingStatus",
      width: 180,
      dictionary: "dict_producing_status",
      showTitle: true,
    },
    {
      fieldShowName: "操作",
      fixed: "right",
      title: "option",
      width: 50,
      align: "center",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        const menu1 = (
          <Menu>
            <Menu.Item
              key="1"
              onClick={(e:any) => {
                var top:any = window.parent;
                top.postMessage(JSON.stringify({title:"销售订单",type:"tab",url:"/wf/form/vsheet/form?workflowTemplateId=c09223b3908045a3847775ebd7cb9ce5&id="+record.uuid+"&version=默认"}),'*')
              }}
            >
              查看详情
            </Menu.Item>
            {/*<Menu.Item*/}
            {/*  key="2"*/}
            {/*  onClick={(e) => {*/}

            {/*  }}*/}
            {/*>*/}
            {/*  生产计划*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item*/}
            {/*    key="3"*/}
            {/*    onClick={(e) => {*/}

            {/*    }}*/}
            {/*>*/}
            {/*  订单作废*/}
            {/*</Menu.Item>*/}
          </Menu>
        );
        return (
          <div className="u-ellips-box">
            <Dropdown overlay={menu1}>
              <EllipsisOutlined />
            </Dropdown>
          </div>
        );
      },
    },
  ];
  const operationMenu = <Menu></Menu>;
  const [columns] = useState<any[]>(columnsData);

  return (
    <div className="u-box u-basic-page">
      <div className="u-box-head">
        <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          订单管理 <CaretDownOutlined />
        </span>
        <div className="u-box-toolbar">
          <div className="u-icon-list u-btn-list">
            <Space>
            <Button
              type="primary"
              onClick={() => {
                var top:any = window.parent;
                top.postMessage(JSON.stringify({title:"销售订单",type:"tab",url:"/wf/form/vsheet/form?workflowTemplateId=c09223b3908045a3847775ebd7cb9ce5&id=0&version=默认"}),'*')
              }}
              className="u-btn-add"
            >
              新建
            </Button>
            <Button
                type="primary"
                onClick={() => {
                }}
                className="u-btn-add"
            >
              打印
            </Button>
            <Button
                type="primary"
                onClick={() => {
                }}
                className="u-btn-add"
            >
              导出PDF
            </Button>
            <Dropdown overlay={operationMenu}>
              <span title="UnorderedListOutlined">
                <UnorderedListOutlined />
              </span>
            </Dropdown>
            </Space>
          </div>
        </div>
      </div>

      <div className="u-box-con">
        <DataGrid
          ref={thisGridRef}
          pagination={true}
          sorter={{ field: "createTime", order: "descend" }}
          pageSize={Math.floor((height - 280) / 42)}
          rowSelectionType="checkbox"
          descriptions={columns}
          url={`/clothes/orderManage/getOrderManageList`}
        />
        <UploadWrapper
          multiple={false}
          visible={uploadWrapperDialogVisible}
          onOk={(file) => {
            setUploadWrapperDialogVisible(false);
            axios
              .post("/clothes/utils/excelUtil/importExcel", {
                tableName: "crm_customer",
                treeField: "",
                treeParentValue: "",
                defaultValueFields: "type,status,taxIncentives",
                defaultValues: "a,0,1",
                fileId: file[0].uuid,
                startIndex: 1,
                endIndex: 0,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.info(resp.data.Message);
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              });
          }}
          onCancel={() => setUploadWrapperDialogVisible(false)}
        />
      </div>
    </div>
  );
};
