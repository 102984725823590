import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Form, Button, Space, message, Table, Modal, Input} from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import "../../CSS/customer.css";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../../components/EditForm";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import i18n from "i18next";
import GetRender from "./GetRender";
import { Description } from "../../../../../../schemas/description";
import {generateUUID, isNullOrUndefined} from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import { useHistory } from "react-router";
import { FormInstance } from "antd/es/form";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import HuiZongTable from "./HuiZongTable";
import {DataGrid} from "../../../../../../components/DataGrid";
import {usePermission} from "../../../../../../util/permession";
import {createNewTab} from "../../../../../../util/tyy";
import storage from "../../util/Stroage";
import {log} from "util";
import useForceUpdate from "../../../../../../hooks/useForceUpdate";
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 15 },
};
interface Params {
  form: FormInstance;
  skuId: string;
  property: string;
  huiZongForm:any
}
export default forwardRef(({ form, skuId,property,huiZongForm }: Params,ref:any) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isCailiaoEdit, setIsCailiaoEdit] = useState(false);
  const childRef:any = useRef();
  useImperativeHandle(ref, () => ({
    submitForm() {
      form.validateFields().then(()=>{
        form.submit();
        setLoading(true);
      })
    },
  }));
  console.log(11111)
  const callBack = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/sku/skuManage/"+property);
        },
      });
    }else{
      history.push("/sku/skuManage/"+property);
    }

  }, []);
  const onValuesChange = (values: any) => {
    // setIsChange(true);
  };
  const [realEntityName, setRealEntityName] = useState("clothes_raw_materials");
  useEffect(function () {
    form.setFieldsValue({
      skuId: skuId,
    });
    axios
      .post<Result<any[]>>(`/clothes/sku/getCostBySkuId`, {
        skuId: skuId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          if (data != undefined) {
            huiZongForm.setFieldsValue({
              data:[
                {
                  rawMaterialCost: data.rawMaterialCost,
                  liningCost: data.liningCost,
                  accessoriesCost: data.accessoriesCost,
                  craftCost: data.craftCost,
                  totalCost: data.totalCost,
                },
              ]
            })
            let dataes = huiZongForm.getFieldValue("data");
            if(childRef.current!=undefined){
              childRef.current.changeVal(dataes);
            }
          }else{
            huiZongForm.setFieldsValue({
              data:[
                {
                  rawMaterialCost: 0,
                  liningCost: 0,
                  accessoriesCost: 0,
                  craftCost: 0,
                  totalCost: 0,
                },
              ]
            })
            let dataes = huiZongForm.getFieldValue("data");
            if(childRef.current!=undefined){
              childRef.current.changeVal(dataes);
            }
          }
        }
      });
  }, []);
  const onFinish = async (values: any) => {
    let categoryId = values.categoryId;
    values.categoryId = categoryId.join(",");
    if (values.isBigPackage == undefined) {
      values.isBigPackage = "0";
    } else {
      values.isBigPackage = values.isBigPackage[0];
    }
    if (values.isProcessingMaterial === undefined) {
      values.isProcessingMaterial = "0";
    } else {
      values.isProcessingMaterial = values.isProcessingMaterial[0];
    }
    if (values.isMulticolor === undefined) {
      values.isMulticolor = "0";
    } else {
      values.isMulticolor = values.isMulticolor[0];
    }
    await saveDataGrid(liBuDataGridRef);
    await saveDataGrid(caiLiaoDataGridRef);
    await saveDataGrid(fuLiaoDataGridRef);
    let liningList = await liBuDataGridRef.current.getData();
    let materialList = await caiLiaoDataGridRef.current.getData();
    let fuLiaoList = await fuLiaoDataGridRef.current.getData();
    let originData = await huiZongForm.getFieldValue("data");
    values.rawMaterialCost = originData[0].rawMaterialCost;
    values.liningCost = originData[0].liningCost;
    values.accessoriesCost = originData[0].accessoriesCost;
    values.craftCost = originData[0].craftCost;
    values.totalCost = originData[0].totalCost;
    console.log("Finish:", values);
    await axios
      .post<Result<any[]>>(`/clothes/sku/saveMaterialList`, {
        data: JSON.stringify(values),
        skuId: skuId,
        liningList: JSON.stringify(liningList),
        materialList: JSON.stringify(materialList),
        fuLiaoList: JSON.stringify(fuLiaoList),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          setLoading(false);
          setIsChange(false);

          axios
              .post<Result<any[]>>(`/clothes/sku/getSkuById`, {
                uuid: skuId,
              })
              .then((resp:any) => {
                if (resp.data.Status === "1") {
                  form.setFieldsValue(resp.data.Data);
                  form.setFieldsValue({
                    categoryId:resp.data.Data.categoryId.split(",")
                  })
                }
              });
          // window.location.reload();
        }
      });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const [caiLiaoForm] = Form.useForm();
  const caiLiaoDataGridRef = useRef<any>(null);
  const [caiLiaoNamespace, setcaiLiaoNamespace] = useState(
    "clothes_raw_materials_main.all"
  );
  const [caiLiaoEntityName, setcaiLiaoEntityName] = useState(
    "clothes_raw_materials_main"
  );
  const [caiLiaoCurrentDescriptions, setcaiLiaoCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

/*  useEffect(function () {
    get(caiLiaoEntityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(caiLiaoNamespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setcaiLiaoCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);*/
  const [caiLiaoTableForm] = Form.useForm();

  function caiLiaoFormat() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(caiLiaoCurrentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
        caiLiaoDataGridRef,
        onDetailSave,
        caiLiaoEntityName,
        false,
        realEntityName,
        onDetailSave
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      onDetailChange(description, caiLiaoTableForm);
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const [liBuForm] = Form.useForm();
  const liBuDataGridRef = useRef<any>(null);
  const [liBuNamespace, setliBuNamespace] = useState(
    "clothes_raw_materials_lining.all"
  );
  const [liBuEntityName, setliBuEntityName] = useState(
    "clothes_raw_materials_lining"
  );
  const [liBuCurrentDescriptions, setliBuCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

/*  useEffect(function () {
    get(liBuEntityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(liBuNamespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setliBuCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);*/
  const [liBuTableForm] = Form.useForm();

  function liBuFormat() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(liBuCurrentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
        liBuDataGridRef,
        onDetailSave,
        liBuEntityName,
        false,
        realEntityName,
        onDetailSave
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      onDetailChange(description, liBuTableForm);
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const [fuLiaoForm] = Form.useForm();
  const fuLiaoDataGridRef = useRef<any>(null);
  const [fuLiaoNamespace, setfuLiaoNamespace] = useState(
    "clothes_raw_materials_fuliao.all"
  );
  const [fuLiaoEntityName, setfuLiaoEntityName] = useState(
    "clothes_raw_materials_fuliao"
  );
  const [fuLiaoCurrentDescriptions, setfuLiaoCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

/*  useEffect(function () {
    get(fuLiaoEntityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(fuLiaoNamespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setfuLiaoCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);*/
  const [fuLiaoTableForm] = Form.useForm();

  function fuLiaoFormat() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(fuLiaoCurrentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
        fuLiaoDataGridRef,
        onDetailSave,
        fuLiaoEntityName,
        false,
        realEntityName,
        onDetailSave
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      onDetailChange(description, fuLiaoTableForm);
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const onDetailChange = async (
    description: Description,
    dataForm: FormInstance
  ) => {
    description.editable = true;
    if (description.fieldName == "name" || description.fieldName == "code") {
      description.onChange = (value: any) => {
        axios
          .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
            skuId: value,
          })
          .then(async (resp) => {
            let data: any = resp.data.Data;
            if (resp.data.Status == "1") {
              let item = {
                name: data.uuid,
                code: data.uuid,
                unitId: data.unitId,
                quantity: 0,
                price: data.standardCost,
                cost: 0,
                isMulticolor: data.isMulticolor,
              };
              await dataForm.setFieldsValue(item);
              // addLine(caiLiaoDataGridRef);
            }
          });
      };
    }
    if (description.fieldName == "quantity") {
      description.onChange = async (e: any) => {
        let quantity = e.target.value;
        let price = dataForm.getFieldValue("price");
        let cost = 0;
        if (!isNaN(quantity) && !isNaN(price)) {
          cost = quantity * price;
        }
        await dataForm.setFieldsValue({
          cost: cost,
        });
      };
    }
  };
  const onDetailSave = async () => {
    let property = await form.getFieldValue("property");
    //3为自产类   自产类 = 物料清单+工艺清单=基本价格
    // if (property == "3") {
    //   await saveDataGrid(caiLiaoDataGridRef);
    //   await saveDataGrid(liBuDataGridRef);
    //   await saveDataGrid(fuLiaoDataGridRef);
    // }

      let caiLiao = await caiLiaoDataGridRef.current.getData();
      let liBu = await liBuDataGridRef.current.getData();
      let fuLiao = await fuLiaoDataGridRef.current.getData();
      let caiLiaoCost = 0.0;
      let liBuCost = 0.0;
      let fuLiaoCost = 0.0;
      let originData = await huiZongForm.getFieldValue("data")
      let gongyiCost = originData[0].craftCost;
      if (isNaN(gongyiCost)) {
        gongyiCost = 0.0;
      }
      if (caiLiao != undefined && caiLiao.length > 0) {
        caiLiao.map((item: any) => {
          if (!isNaN(item.cost)) {
            caiLiaoCost += item.cost;
          }
        });
      }
      if (liBu != undefined && liBu.length > 0) {
        liBu.map((item: any) => {
          if (!isNaN(item.cost)) {
            liBuCost += item.cost;
          }
        });
      }
      if (fuLiao != undefined && fuLiao.length > 0) {
        fuLiao.map((item: any) => {
          if (!isNaN(item.cost)) {
            fuLiaoCost += item.cost;
          }
        });
      }
      await huiZongForm.setFieldsValue({
        data:[
          {
            rawMaterialCost: caiLiaoCost,
            liningCost: liBuCost,
            accessoriesCost: fuLiaoCost,
            craftCost: gongyiCost,
            totalCost: (caiLiaoCost + liBuCost + fuLiaoCost + gongyiCost).toFixed(2),
          },
        ]
      })
      let dataes = await huiZongForm.getFieldValue("data");
      await childRef.current.changeVal(dataes);
      await form.setFieldsValue({
        standardCost: (caiLiaoCost + liBuCost + fuLiaoCost + gongyiCost).toFixed(2),
      });


  };



  const forceUpdate = useForceUpdate();

  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                scrollToFirstError={true}
            >
              <Form.Item className="remark" label={i18n.t("主原材料添加")} name=" ">
                {caiLiaoDataGridRef && <DataGrid
                    ref={caiLiaoDataGridRef}
                    url={"/clothes/sku/getMaterialBySkuIdAndType"}
                    descriptions={
                      [
                        {
                          "fieldName":"name",
                          "fieldShowName": i18n.t("原材料/辅料编码",),
                          width:70,
                          "inputType":6,
                          "readOnly":false,
                          "dictionary":"clothes_sku",
                          "displayToList":true,
                          "filter":"property='0' and name is not null and name!=''",
                          "showTitle":false,
                          "editable":true,
                          onChange :(value: any) => {
                            axios
                                .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                  skuId: value,
                                })
                                .then(async (resp) => {
                                  let data: any = resp.data.Data;
                                  if (resp.data.Status == "1") {
                                    let item = {
                                      name: data.uuid,
                                      code: data.uuid,
                                      unitId: data.unitId,
                                      quantity: 0,
                                      price: data.standardCost,
                                      cost: 0,
                                      isMulticolor: data.isMulticolor,
                                    };
                                    await caiLiaoTableForm.setFieldsValue(item);
                                    // addLine(caiLiaoDataGridRef);
                                  }
                                });
                          }
                        },
                        {
                          "fieldName":"code",
                          "fieldShowName": i18n.t("简介",),
                          "inputType":6,
                          "readOnly":false,
                          "dictionary":"dict_sku_code",
                          "displayToList":true,
                          "filter":"property='0' and name is not null and name!=''",
                          "showTitle":false,
                          "editable":true,
                          onChange :(value: any) => {
                            axios
                                .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                  skuId: value,
                                })
                                .then(async (resp) => {
                                  let data: any = resp.data.Data;
                                  if (resp.data.Status == "1") {
                                    let item = {
                                      name: data.uuid,
                                      code: data.uuid,
                                      unitId: data.unitId,
                                      quantity: 0,
                                      price: data.standardCost,
                                      cost: 0,
                                      isMulticolor: data.isMulticolor,
                                    };
                                    await caiLiaoTableForm.setFieldsValue(item);
                                    // addLine(caiLiaoDataGridRef);
                                  }
                                });
                          }
                        },
                        {
                          "fieldName":"unitId",
                          "fieldShowName": i18n.t("单位",),
                          width:30,
                          "inputType":6,
                          "readOnly":false,
                          "dictionary":"dict_sku_unit",
                          "displayToList":true,
                          "showTitle":false,
                          "editable":true
                        },
                        {
                          "fieldName":"quantity",
                          "fieldShowName": i18n.t("数量",),
                          width:20,
                          "inputType":0,
                          "readOnly":false,
                          "displayToList":true,
                          "showTitle":false,
                          "editable":true,
                          onChange : async (e: any) => {
                            let quantity = e.target.value;
                            let price = caiLiaoTableForm.getFieldValue("price");
                            let cost = 0;
                            if (!isNaN(quantity) && !isNaN(price)) {
                              cost = quantity * price;
                            }
                            await caiLiaoTableForm.setFieldsValue({
                              cost: cost,
                            });
                          }
                        },
                        {
                          "fieldName":"price",
                          "fieldShowName": i18n.t("单位成本",),
                          width:30,
                          "inputType":0,
                          "readOnly":true,
                          "editable":true
                        },
                        {
                          "fieldName":"cost",
                          // "fieldShowName": i18n.t("成本=数量*单位成本",),
                          "fieldShowName": i18n.t("成本",),
                          width:35,
                          "inputType": 0,
                          "readOnly":true,
                          "editable":true
                        },
                        {
                          "fieldName":"colorIndex",
                          "fieldShowName": i18n.t("色序",),
                          width:35,
                          editRender:()=>{
                            return(
                                <Input type={"number"}/>
                            )
                          },
                          "displayToList":true,
                          "editable":true
                        },
                        {
                          "fieldName":"isMulticolor",
                          "fieldShowName": i18n.t("有色布",),
                          width:40,
                          "inputType":6,
                          "readOnly":true,
                          "dictionary":"dict_yes_no",
                          "displayToList":true,
                          "editable":true
                        },
                        {
                          fieldShowName: i18n.t("options"),
                          fixed: "right",
                          render: (text:any,record:any)=>{
                            return (
                                <>
                                  <a
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        console.log(record);
                                        Modal.confirm({
                                          title: i18n.t("删除提示"),
                                          icon: <ExclamationCircleOutlined/>,
                                          content: i18n.t("删除操作"),
                                          okText: i18n.t("确定"),
                                          okType: "danger",
                                          cancelText: i18n.t("cancelText"),
                                          onOk() {
                                            caiLiaoDataGridRef.current.remove(record.key);
                                          },
                                          onCancel() {
                                            console.log("Cancel");
                                          },
                                        });
                                      }}
                                  >
                                    {i18n.t("delete")}
                                  </a>
                                </>
                            )
                          },
                          width:30,
                        },
                      ]
                    }
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(caiLiaoDataGridRef, record);
                      await onDetailSave();
                      // forceUpdate()
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    searchData={{skuId: skuId, type: "0"}}
                    form={caiLiaoTableForm}
                    // onValuesChange={onValuesChange}
                />}
                <div style={{paddingBottom: 25}}>
                  <Space>
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          await addLine(caiLiaoDataGridRef);
                          await onDetailSave();
                        }}
                    >
                      {i18n.t("添加")}
                    </Button>}
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          createNewTab(storage.get("reactUrl")+`/sku/skuManageDetail/` +
                              generateUUID() +
                              `/add/0`,i18n.t('产品明细',)+"(原材料)")
                        }}
                    >
                      {i18n.t("新增")}
                    </Button>}
                  </Space>
                </div>
              </Form.Item>
              <Form.Item className="remark" label={i18n.t("里布/内衬添加")} name=" ">

                <DataGrid
                    ref={liBuDataGridRef}
                    url={"/clothes/sku/getMaterialBySkuIdAndType"}
                    descriptions={
                        [
                            {
                                "fieldName":"name",
                                "fieldShowName": i18n.t("原材料/辅料编码",),
                                width:70,
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"clothes_sku",
                                "displayToList":true,
                                "filter":"property='0' and name is not null and name!=''",
                                "showTitle":false,
                                "editable":true,
                                onChange :(value: any) => {
                                    axios
                                        .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                            skuId: value,
                                        })
                                        .then(async (resp) => {
                                            let data: any = resp.data.Data;
                                            if (resp.data.Status == "1") {
                                                let item = {
                                                    name: data.uuid,
                                                    code: data.uuid,
                                                    unitId: data.unitId,
                                                    quantity: 0,
                                                    price: data.standardCost,
                                                    cost: 0,
                                                    isMulticolor: data.isMulticolor,
                                                };
                                                await liBuTableForm.setFieldsValue(item);
                                                // addLine(caiLiaoDataGridRef);
                                            }
                                        });
                                }
                            },
                            {
                                "fieldName":"code",
                                "fieldShowName": i18n.t("简介",),
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"dict_sku_code",
                                "displayToList":true,
                                "filter":"property='0' and name is not null and name!=''",
                                "showTitle":false,
                                "editable":true,
                                onChange :(value: any) => {
                                    axios
                                        .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                            skuId: value,
                                        })
                                        .then(async (resp) => {
                                            let data: any = resp.data.Data;
                                            if (resp.data.Status == "1") {
                                                let item = {
                                                    name: data.uuid,
                                                    code: data.uuid,
                                                    unitId: data.unitId,
                                                    quantity: 0,
                                                    price: data.standardCost,
                                                    cost: 0,
                                                    isMulticolor: data.isMulticolor,
                                                };
                                                await liBuTableForm.setFieldsValue(item);
                                                // addLine(caiLiaoDataGridRef);
                                            }
                                        });
                                }
                            },
                            {
                                "fieldName":"unitId",
                                "fieldShowName": i18n.t("单位",),
                                width:30,
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"dict_sku_unit",
                                "displayToList":true,
                                "showTitle":false,
                                "editable":true
                            },
                            {
                                "fieldName":"quantity",
                                "fieldShowName": i18n.t("数量",),
                                width:20,
                                "inputType":0,
                                "readOnly":false,
                                "displayToList":true,
                                "showTitle":false,
                                "editable":true,
                                onChange : async (e: any) => {
                                    let quantity = e.target.value;
                                    let price = liBuTableForm.getFieldValue("price");
                                    let cost = 0;
                                    if (!isNaN(quantity) && !isNaN(price)) {
                                        cost = quantity * price;
                                    }
                                    await liBuTableForm.setFieldsValue({
                                        cost: cost,
                                    });
                                }
                            },
                            {
                                "fieldName":"price",
                                "fieldShowName": i18n.t("单位成本",),
                                width:30,
                                "inputType":0,
                                "readOnly":true,
                                "editable":true
                            },
                            {
                                "fieldName":"cost",
                                "fieldShowName": i18n.t("成本",),
                                width:70,
                                "inputType":0,
                                "readOnly":true,
                                "editable":true
                            },
                          {
                            "fieldName":"colorIndex",
                            "fieldShowName": i18n.t("色序",),
                            width:35,
                            editRender:()=>{
                              return(
                                  <Input type={"number"}/>
                              )
                            },
                            "displayToList":true,
                            "editable":true
                          },
                            {
                                "fieldName":"isMulticolor",
                                "fieldShowName": i18n.t("有色布",),
                                width:40,
                                "inputType":6,
                                "readOnly":true,
                                "dictionary":"dict_yes_no",
                                "displayToList":true,
                                "editable":true
                            },
                            {
                                fieldShowName: i18n.t("options"),
                                fixed: "right",
                              render: (text:any,record:any)=>{
                                return (
                                    <>
                                      <a
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            console.log(record);
                                            Modal.confirm({
                                              title: i18n.t("删除提示"),
                                              icon: <ExclamationCircleOutlined/>,
                                              content: i18n.t("删除操作"),
                                              okText: i18n.t("确定"),
                                              okType: "danger",
                                              cancelText: i18n.t("cancelText"),
                                              onOk() {
                                                liBuDataGridRef.current.remove(record.key);
                                              },
                                              onCancel() {
                                                console.log("Cancel");
                                              },
                                            });
                                          }}
                                      >
                                        {i18n.t("delete")}
                                      </a>
                                    </>
                                )
                              },
                                width:30,
                            }
                        ]
                    }
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(liBuDataGridRef, record);
                      await onDetailSave();
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    searchData={{skuId: skuId, type: "1"}}
                    form={liBuTableForm}
                    // onValuesChange={onValuesChange}
                />
                <div style={{paddingBottom: 25}}>
                  <Space>
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          await addLine(liBuDataGridRef);
                          await onDetailSave();
                        }}
                    >
                      {i18n.t("添加")}
                    </Button>}
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          createNewTab(storage.get("reactUrl")+`/sku/skuManageDetail/` +
                              generateUUID() +
                              `/add/0`,i18n.t('产品明细',)+"(原材料)")
                        }}
                    >
                      {i18n.t("新增")}
                    </Button>}
                  </Space>
                </div>
              </Form.Item>
              <Form.Item className="remark" label={i18n.t("辅料添加")} name=" ">
                <DataGrid
                    ref={fuLiaoDataGridRef}
                    url={"/clothes/sku/getMaterialBySkuIdAndType"}
                    descriptions={
                        [
                            {
                                "fieldName":"name",
                                "fieldShowName": i18n.t("原材料/辅料编码",),
                                width:70,
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"clothes_sku",
                                "displayToList":true,
                                "filter":"property='2' and name is not null and name!=''",
                                "showTitle":false,
                                "editable":true,
                                onChange :(value: any) => {
                                    axios
                                        .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                            skuId: value,
                                        })
                                        .then(async (resp) => {
                                            let data: any = resp.data.Data;
                                            if (resp.data.Status == "1") {
                                                let item = {
                                                    name: data.uuid,
                                                    code: data.uuid,
                                                    unitId: data.unitId,
                                                    quantity: 0,
                                                    price: data.standardCost,
                                                    cost: 0,
                                                    isMulticolor: data.isMulticolor,
                                                };
                                                await fuLiaoTableForm.setFieldsValue(item);
                                                // addLine(caiLiaoDataGridRef);
                                            }
                                        });
                                }
                            },
                            {
                                "fieldName":"code",
                                "fieldShowName": i18n.t("简介",),
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"dict_sku_code",
                                "displayToList":true,
                                "filter":"property='2' and name is not null and name!=''",
                                "showTitle":false,
                                "editable":true,
                                onChange :(value: any) => {
                                    axios
                                        .post<Result<any[]>>(`/clothes/sku/getSkuBySkuId`, {
                                            skuId: value,
                                        })
                                        .then(async (resp) => {
                                            let data: any = resp.data.Data;
                                            if (resp.data.Status == "1") {
                                                let item = {
                                                    name: data.uuid,
                                                    code: data.uuid,
                                                    unitId: data.unitId,
                                                    quantity: 0,
                                                    price: data.standardCost,
                                                    cost: 0,
                                                    isMulticolor: data.isMulticolor,
                                                };
                                                await fuLiaoTableForm.setFieldsValue(item);
                                                // addLine(caiLiaoDataGridRef);
                                            }
                                        });
                                }
                            },
                            {
                                "fieldName":"unitId",
                                "fieldShowName": i18n.t("单位",),
                                width:30,
                                "inputType":6,
                                "readOnly":false,
                                "dictionary":"dict_sku_unit",
                                "displayToList":true,
                                "showTitle":false,
                                "editable":true
                            },
                            {
                                "fieldName":"quantity",
                                "fieldShowName": i18n.t("数量",),
                                width:20,
                                "inputType":0,
                                "readOnly":false,
                                "displayToList":true,
                                "showTitle":false,
                                "editable":true,
                                onChange : async (e: any) => {
                                    let quantity = e.target.value;
                                    let price = fuLiaoTableForm.getFieldValue("price");
                                    let cost = 0;
                                    if (!isNaN(quantity) && !isNaN(price)) {
                                        cost = quantity * price;
                                    }
                                    await fuLiaoTableForm.setFieldsValue({
                                        cost: cost,
                                    });
                                }
                            },
                            {
                                "fieldName":"price",
                                "fieldShowName": i18n.t("单位成本",),
                                width:30,
                                "inputType":0,
                                "readOnly":true,
                                "editable":true
                            },
                            {
                                "fieldName":"cost",
                                "fieldShowName": i18n.t("成本",),
                                width:70,
                                "inputType":0,
                                "readOnly":true,
                                "editable":true
                            },
                          {
                            "fieldName":"colorIndex",
                            "fieldShowName": i18n.t("色序",),
                            width:35,
                            editRender:()=>{
                              return(
                                  <Input type={"number"}/>
                              )
                            },
                            "displayToList":true,
                            "editable":true
                          },
                            {
                                "fieldName":"isMulticolor",
                                "fieldShowName": i18n.t("有色布",),
                                width:40,
                                "inputType":6,
                                "readOnly":true,
                                "dictionary":"dict_yes_no",
                                "displayToList":true,
                                "editable":true
                            },
                            {
                                fieldShowName: i18n.t("options"),
                                fixed: "right",
                              render: (text:any,record:any)=>{
                                return (
                                    <>
                                      <a
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            console.log(record);
                                            Modal.confirm({
                                              title: i18n.t("删除提示"),
                                              icon: <ExclamationCircleOutlined/>,
                                              content: i18n.t("删除操作"),
                                              okText: i18n.t("确定"),
                                              okType: "danger",
                                              cancelText: i18n.t("cancelText"),
                                              onOk() {
                                                fuLiaoDataGridRef.current.remove(record.key);
                                              },
                                              onCancel() {
                                                console.log("Cancel");
                                              },
                                            });
                                          }}
                                      >
                                        {i18n.t("delete")}
                                      </a>
                                    </>
                                )
                              },
                                width:30,
                            },
                        ]

                    }
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(fuLiaoDataGridRef, record);
                      await onDetailSave();
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    searchData={{skuId: skuId, type: "2"}}
                    form={fuLiaoTableForm}
                    // onValuesChange={onValuesChange}
                />
                <div style={{paddingBottom: 25}}>
                  <Space>
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          await addLine(fuLiaoDataGridRef);
                          await onDetailSave();
                        }}
                    >
                      {i18n.t("添加")}
                    </Button>}
                    {usePermission("新增修改商品") &&<Button
                        style={{width: 100}}
                        onClick={async () => {
                          createNewTab(storage.get("reactUrl")+`/sku/skuManageDetail/` +
                              generateUUID() +
                              `/add/2`,i18n.t('产品明细',)+"(辅料)")
                        }}
                    >
                      {i18n.t("新增")}
                    </Button>}
                  </Space>
                </div>
              </Form.Item>
              <Form.Item className="remark" label={i18n.t("汇总")} name=" ">
                <HuiZongTable huiZongForm={huiZongForm} ref={childRef}/>
              </Form.Item>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改商品") &&<Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.validateFields().then(()=>{
                            form.submit();
                            setLoading(true);
                          })
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
} )
