import React, {useRef, useState} from "react";
import i18n from "i18next";
import {instance as axios} from "../../../../../../util/axios";
import {Result} from "../../../../../../schemas/common";
import {Form, Input, message} from "antd";
import GetRender from "./GetRender";
import {editOtherLine} from "../../components/EditForm";
import {DataGrid} from "../../../../../../components/DataGrid";
import useForceUpdate from "../../../../../../hooks/useForceUpdate";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";

const Test = ()=>{
    return(
        <>
            <DictionarySelect entityName={"dict_country"} style={{width:300}}/>
        </>
    )
}


export default Test;