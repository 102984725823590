import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import storage from "./screens/Clothes/util/Stroage";
import React from "react";
import {updateLanguage} from "../../util/changeLanguage";

var lang = storage.get("ClientLanguage");
var packages = storage.get("languagePackage");
if(lang == undefined || packages == undefined){
    updateLanguage();
}

var resources = getLanguagePackage();


function getLanguagePackage() {
  let currentLanguagePackage = storage.get("languagePackage");
  let lanPackage = lang;
  var currentResources = {};
  currentResources[lanPackage] = { translation: currentLanguagePackage };
  return currentResources;
}

// the translations
// (tip move them in a JSON file and import them)
initI18n();
function initI18n() {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: lang,

            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });
}

export default i18n;
