import {instance as axios} from "./axios"
import storage from "../containers/App/screens/Clothes/util/Stroage";
// import cookie from "js-cookie"
import cookie from "js-cookie"


 function updateLanguage(isRefreshIframe?:boolean){
    axios.get("/clothes/config/getLanguage").then((resp)=>{
        if(resp.data.Status == 1){
            storage.set("ClientLanguage", resp.data.Data);
            cookie.set('ClientLanguage', resp.data.Data, { path: '/' });
            //更新一次后台接口语言包
            axios.get("/oa/language/reload").then(()=>{});
            axios.get("/clothes/language/getLanguagePack").then((res)=>{
                if (res.data.Status == 1) {
                    var data = res.data.Data;
                    let languagePackages:any = {};
                    data.map((item:any) => {
                        var key = item.key;
                        switch (resp.data.Data) {
                            case "en_us":
                                languagePackages[key] = item.english;
                                break;
                            case "zh_cn":
                                languagePackages[key] = item.chinese;
                                break;
                            case "it_it":
                                languagePackages[key] = item.italian;
                                break;
                        }
                    });
                    storage.set("languagePackage", languagePackages);
                    if(isRefreshIframe){
                        var top: any = window.top;
                        top.postMessage(JSON.stringify({type:"refresh"}), '*');
                    }else{
                        // window.location.reload();
                    }
                }
            })
        }

    })

}

export {updateLanguage}