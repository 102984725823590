import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  Form,
  Input,
  Table,
  Select,
  Dropdown,
  Menu,
  message,
  Space,
  Button,
  Popconfirm,
} from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import i18n from "i18next";

import { instance as axios } from "../../../../../util/axios";
import { useWindowSize } from "react-use";
import {DataGrid} from "../../../../../components/DataGrid";
import {editOtherLine} from "../components/EditForm";


export default ({}) => {
  const thisGridRef = useRef<any>();
  const [tableForm] = Form.useForm();
  const { width, height } = useWindowSize();
  const getRender = useCallback(function getRender(
      gridRef: React.MutableRefObject<any>
      ) {
        return function (_: any, record: any) {
            return (
                <>
                    {gridRef.current.isEditing(record) ? (
                        <>
                            <a
                                style={{marginRight: 16}}
                                onClick={async () => {
                                    await gridRef.current.save(record.key);
                                    const data = gridRef.current.getDataItem(record.key);
                                    const url = `/oa/language/saveLanguage`;
                                    axios
                                        .post(url, {
                                            data: JSON.stringify(
                                                {
                                                    uuid: data.uuid,
                                                    keyWord: data.keyWord,
                                                    category: data.category,
                                                    remark: data.remark,
                                                    navigationMenuId: data.navigationMenuId,
                                                    urlPath: data.urlPath,
                                                    chinese: data.chinese,
                                                    english: data.english,

                                                    italian: data.italian,
                                                }
                                            )
                                        })
                                        .then(() => {
                                            thisGridRef.current.refresh();
                                            message.info(i18n.t("保存成功"));
                                        });
                                }
                                }
                            >
                                {i18n.t("确认")}
                            </a>
                        </>
                    ) : (
                        <>
                            <MinusCircleOutlined
                                value={"删除"}
                                style={{margin: "0 10px"}}
                                onClick={() => {
                                    const url = `/oa/language/deleteLanguageById`;

                                    axios.post(url, {uuid: record.uuid}).then(() => {
                                        thisGridRef.current.save(record.key);
                                        thisGridRef.current.refresh();
                                        message.info(i18n.t("删除成功"));
                                    });
                                }
                                }
                            />
                        </>
                    )}
                </>
            );
        };
      },
      []);
  const returnData = useCallback(()=>{
      return (
          <div>
              <DataGrid
                  ref={thisGridRef}
                  url={`/oa/language/getLanguageList`}
                  descriptions={[
                      {
                          fieldName: "keyWord",
                          fieldShowName: i18n.t("客户标识",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "category",
                          fieldShowName: i18n.t("分类",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "remark",
                          fieldShowName: i18n.t("备注",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "navigationMenuId",
                          fieldShowName: i18n.t("导航",),
                          width: 120,
                          dictionary: "navigation_menu",
                          inputType: 6,
                          editable: true,
                      },
                      {
                          fieldName: "urlPath",
                          fieldShowName: i18n.t("页面地址",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "chinese",
                          fieldShowName: i18n.t("中文",),
                          width: 120,
                          editable: true,
                      },

                      {
                          fieldName: "english",
                          fieldShowName: i18n.t("英语",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "italian",
                          fieldShowName: i18n.t("意大利语",),
                          width: 120,
                          editable: true,
                      },
                      {
                          fieldName: "uuid",
                          fieldShowName: i18n.t("操作",),
                          width: 80,
                          render: getRender(thisGridRef),
                      },
                  ]}
                  // pagination={false}
                  pageSize={Math.floor((height - 280) / 42)}
                  // isShowSearch={false}
                  loadAllDictionary={true}
                  onClick={async (record: any) => {
                      await editOtherLine(thisGridRef, record);
                  }}
                  form={tableForm}
              />
              <Button
                  style={{
                      marginLeft: "80%",
                      backgroundColor: "#BF3232",
                      width: "150px",
                      height: "40px",
                      borderRadius: "20px",
                      marginTop: "50px",
                      color: "white"
                  }}
                  onClick={() => {

                  }}
              >
                  {i18n.t("生成语言包")}
              </Button>
          </div>
      );
  },[])
    return (
        <div className="u-TabDevice" style={{height: "450px"}}>
            <Button
                className="u-btnA"
                onClick={() => {
                    thisGridRef.current.add();
                }}
            >
                {i18n.t("新增")}
            </Button>
            {
                returnData()
            }
        </div>
    );
};