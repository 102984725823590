import React from "react";
import {Card, Skeleton, Image, Spin, Table} from "antd";

import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../util/axios";
import Empty from "antd/es/empty";
import i18n from "i18next";
import {BaseURL} from "../../../../../constants/url";

const { Meta } = Card;

export default class USwiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "10000px",
      transition: "500ms ease-out",
      transform: "translatex(0px)",
      countnum: 10, //图片数量
      curPageIndex: 0, //点击右箭头+1，左箭头减1
      viewcount: 3, //初始展示图片数量（和div宽度相关）
      Larrowstate: true,
      Rarrowstate: true,
      createTime: this.props.createTime,
      data: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        "/inventory/imageRecord/getImageRecordList?createTimeStr=" +
          this.state.createTime
      )
      .then((resp) => {
        var res = resp.data;
        if (res.Status == 1) {
          this.setState({
            data: res.Data,
            countnum: res.Data.length,
          });
        }
      });
  }

  handleclickPrev() {
    let { countnum, viewcount, curPageIndex, Rarrowstate } = this.state;
    if (curPageIndex > 0) {
      if (curPageIndex === countnum - viewcount) {
        this.setState({
          Rarrowstate: !Rarrowstate,
        });
      }
      this.getPage(curPageIndex - 1);
    } else {
      return;
    }
  }

  handleclickNext() {
    let { curPageIndex, countnum, viewcount } = this.state;
    if (Number(curPageIndex) < Number(countnum) - Number(viewcount)) {
      if (curPageIndex < 0) {
        curPageIndex = 0;
      }
      if (Number(curPageIndex)) {
        this.setState({
          Larrowstate: true,
        });
      }
      this.getPage(curPageIndex + 1);
    } else {
      return;
    }
  }

  getPage(value) {
    let { countnum, viewcount, Larrowstate, Rarrowstate } = this.state;
    let n = countnum - viewcount;
    let itemwidth = 260;
    let transform = "translatex(-" + value * itemwidth + "px)";
    value > n || 0 > value || this.setState({ transform: transform });
    this.setState({
      curPageIndex: value,
    });
    if (Number(value)) {
      this.setState({
        Larrowstate: !Larrowstate,
      });
    }
    if (Number(value) === Number(n)) {
      this.setState({
        Rarrowstate: !Rarrowstate,
      });
    }
  }

  getContent() {
    let { width, transition, transform, Larrowstate, Rarrowstate } = this.state;
    let LArrowClaName = Larrowstate ? "arrow_l" : "arrow_l disabled";
    let RArrowClaName = Rarrowstate ? "arrow_r" : "arrow_r disabled";
    let content;
    if (this.state.data.length > 0) {
      content = (
        <div className="u-swiper-wrap">
          <div className="u-swiper">
            <div
              style={{
                width: width,
                transition: transition,
                transform: transform,
              }}
            >
              {this.state.data.map(function (item, index) {
                return (
                  <Card
                    style={{ width: 240 }}
                    cover={
                      <Image
                        alt="example"
                        src={BaseURL+"/oa/common/attachment/showImageAttachment?id="+item.imgId}
                        placeholder={
                          <Spin spinning={true}>
                          </Spin>
                        }
                      />
                    }
                  >
                    <Meta title={item.imgId} description={item.enterTime} />
                    <Meta
                      description={i18n.t("public_enterStaffAccount")+"：" + item.enterStaffAccount}
                    />
                  </Card>
                );
              })}
            </div>
          </div>

          <button
            className={LArrowClaName}
            onClick={() => this.handleclickPrev()}
          >
            <DoubleLeftOutlined />
          </button>
          <button
            className={RArrowClaName}
            onClick={() => this.handleclickNext()}
          >
            <DoubleRightOutlined />
          </button>
        </div>
      );
    } else {
      content = <Table
          showHeader={false}
          columns={undefined}
          dataSource={undefined}
          pagination={false}
      />;
    }
    return content;
  }
  render() {
    return this.getContent();
  }
}
