import React from 'react'

import Index from './index'

export default () =>  {
	return(
		<div className="u-box u-edit">
			<Index />
		</div>
	)
}