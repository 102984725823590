import React, {
  Dispatch,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Upload } from "antd";
import { CellData } from "../schemas/CellData";
import { FormGroup } from "./FormGroup";
import { instance as axios } from "../../../../util/axios";
import { UploadOutlined } from "@ant-design/icons";
import { BaseURL } from "../../../../constants/url";
import { UploadFile } from "antd/lib/upload/interface";
import { ReducerAction } from "../../type";

interface FileCellProps {
  data: CellData;
  layout?: "vertical" | "horizontal";
  dispatch: Dispatch<ReducerAction>;
}

export const FileCell = forwardRef(
  ({ data, layout, dispatch }: FileCellProps, ref: any) => {
    const setValue = useCallback(
      (id: string, value: any) => {
        dispatch({ type: "SET_VALUE", id, value });
      },
      [dispatch]
    );
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    useEffect(() => {
      data.onChange?.(data.value, setValue);
    }, [data.onChange, data.value, setValue]);
    useEffect(() => {
      axios
        .get(`/oa/common/attachment/index?attachIds=${data.value}`)
        .then((resp) => {
          if (resp.data.Status === "1") {
            let patternArr = ["BMP", "JPG", "JPEG", "PNG", "GIF"];
            setFileList(
              resp.data.Data.map(
                (item: { filename: string; uuid: string; suffix: string }) => {
                  let url: string;
                  if (
                    item.suffix &&
                    patternArr.indexOf(item.suffix.toUpperCase()) > 0
                  ) {
                    url =
                      BaseURL +
                      "/html/base/public/attachment/singleImage?imageId=" +
                      item.uuid;
                  } else if (item.suffix.toUpperCase() === "PDF") {
                    url =
                      BaseURL +
                      "/html/base/public/attachment/showPDF?fileId=" +
                      item.uuid;
                  } else {
                    url =
                      BaseURL +
                      "/oa/common/attachment/downloadFile?id=" +
                      item.uuid;
                  }
                  return {
                    name: item.filename,
                    uid: item.uuid,
                    status: "done",
                    url: url,
                    response: { Data: { uuid: item.uuid } },
                  };
                }
              )
            );
          }
        });
    }, [data.value]);
    return (
      <>
        <FormGroup
          ref={ref}
          required={data.required!}
          warning={data.warning}
          layout={layout}
          warnable={data.warnable!}
          label={
            data.labeled ? (
              <label title={data.label}>{data.label}</label>
            ) : (
              <></>
            )
          }
          element={
            <div style={{ paddingTop: 10, verticalAlign: "top" }}>
              <Upload
                withCredentials={true}
                data={(file) => {
                  return {
                    Filedata: file,
                    category: "id",
                    fileName: encodeURI(file.name),
                  };
                }}
                fileList={fileList}
                multiple={true}
                action={BaseURL + "/oa/common/attachment/uploadFile"}
                onChange={(param) => {
                  setFileList(param.fileList);
                  const value = param.fileList
                    .filter((item) => item.status === "done")
                    .map((item) => item.response.Data.uuid)
                    .join(",");
                  dispatch?.({
                    type: "SET_VALUE",
                    id: data.id,
                    value: value,
                  });
                }}
              >
                <Button size="small">
                  <UploadOutlined /> 上传
                </Button>
              </Upload>
            </div>
          }
        />
      </>
    );
  }
);
