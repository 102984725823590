import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Form, Table} from "antd";
import "../../CSS/customer.css";
import { FormInstance } from "antd/es/form";
import i18n from "i18next";
import {isNullOrUndefined} from "../../util/Common";
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 15 },
};
interface Params {
    huiZongForm: FormInstance;
}
export default forwardRef(({ huiZongForm}: Params,ref: any) => {
    const [originData, setOriginData] = useState([
        {
            rawMaterialCost: 0,
            liningCost: 0,
            accessoriesCost: 0,
            craftCost: 0,
            totalCost: 0,
        },
    ]);
    // useEffect(function () {
    //     initform();
    // },[huiZongForm])
    // const initform = useCallback(function initform() {
    //     let data = huiZongForm.getFieldValue("data");
    //     if(data!=undefined){
    //         setOriginData([
    //             {
    //                 rawMaterialCost: data.rawMaterialCost,
    //                 liningCost: data.liningCost,
    //                 accessoriesCost: data.accessoriesCost,
    //                 craftCost: data.craftCost,
    //                 totalCost: data.totalCost,
    //             },
    //         ]);
    //     }
    // },[huiZongForm])
    useImperativeHandle(ref, () => ({
        changeVal: (data:any) => {
            if(data!=undefined){
                setOriginData([
                    {
                        rawMaterialCost: isNullOrUndefined(data[0].rawMaterialCost)?0:data[0].rawMaterialCost.toFixed(2),
                        liningCost: isNullOrUndefined(data[0].liningCost)?0:data[0].liningCost.toFixed(2),
                        accessoriesCost: isNullOrUndefined(data[0].accessoriesCost)?0:data[0].accessoriesCost.toFixed(2),
                        craftCost:  isNullOrUndefined(data[0].craftCost)?0:data[0].craftCost.toFixed(2),
                        totalCost:  isNullOrUndefined(data[0].totalCost)?0:data[0].totalCost,
                    },
                ]);
            }
        }
    }));
    return (
        <Table
            bordered
            pagination={false}
            dataSource={originData}
            columns={[
                {
                    title: i18n.t("主原料添加",),
                    dataIndex: "rawMaterialCost",
                    width: "20%",
                },
                {
                    title: i18n.t("里布/内衬",),
                    dataIndex: "liningCost",
                    width: "20%",
                },
                {
                    title: i18n.t("辅料",),
                    dataIndex: "accessoriesCost",
                    width: "20%",
                },
                {
                    title: i18n.t("工艺",),
                    dataIndex: "craftCost",
                    width: "20%",
                },
                {
                    title: i18n.t("总成本",),
                    dataIndex: "totalCost",
                    width: "20%",
                },
            ]}
            rowClassName="editable-row"
        />
    );
});
