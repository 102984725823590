import React, {useEffect, useRef, useState} from 'react'
import i18n from "i18next";
import {addLine, editOtherLine, saveDataGrid} from "../../components/EditForm";
import {DataGrid} from "../../../../../../components/DataGrid";
import {Button, Form, Input} from "antd";
interface param {
    orderId:any
    templateId:any
    dataGridRef:any
}

export default ({orderId,templateId,dataGridRef}:param)=>{
    const [tableForm] = Form.useForm();
    return(
        <div>
            <Button
                style={{width: 100}}
                onClick={async () => {
                    await addLine(dataGridRef);
                }}
            >
                {i18n.t("add")}
            </Button>
            <DataGrid
                ref={dataGridRef}
                url={"clothes/size/getSizeManageListByTemplateId?orderId="+orderId+"&templateId=null"}
                descriptions={[
                    {
                        fieldName: "name",
                        fieldShowName: i18n.t("尺码名称",),
                        editable: true,
                    },
                    {
                        fieldName: "column1",
                        fieldShowName: i18n.t("尺码1",),
                        editable: true,

                    },
                    {
                        fieldName: "column2",
                        fieldShowName: i18n.t("尺码2",),
                        editable: true,
                    },
                    {
                        fieldName: "column3",
                        fieldShowName: i18n.t("尺码3",),
                        editable: true,
                    },
                    {
                        fieldName: "column4",
                        fieldShowName: i18n.t("尺码4",),
                        editable: true,
                    },
                    {
                        fieldName: "column5",
                        fieldShowName: i18n.t("尺码5",),
                        editable: true,
                    },
                    {
                        fieldName: "column6",
                        fieldShowName: i18n.t("尺码6",),
                        editable: true,
                    },
                    {
                        fieldName: "column7",
                        fieldShowName: i18n.t("尺码7",),
                        editable: true,
                    },

                ]}
                pagination={false}
                onClick={async (record) => {
                    await editOtherLine(dataGridRef, record);
                }}
                loadAllDictionary={true}
                isShowSearch={false}
                form={tableForm}

            />
        </div>
    )
}