import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader
} from "antd";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import i18n from "i18next";
import {Option} from "antd/es/mentions";
import TreeSelectByType from "../../../../../components/TreeSelectByType";


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState({"property":"-1"});
    const gridRef = useRef<any>(null);

    useEffect(()=>{
        form.setFieldValue("property","-1")
    },[])

    return (
        <div>
            <PageHeader
                title={i18n.t("库存余额表")}
                extra={[
                    <Button key="1" type="primary" onClick={() => {
                        gridRef.current.export();
                    }}>
                        {i18n.t("导出")}
                    </Button>,
                ]}
                footer={
                    <Form form={form} layout={"inline"}>

                        <Form.Item name={"name"} label={i18n.t("商品编码")}>
                            <Input/>
                        </Form.Item>

                        <Form.Item name={"code"} label={i18n.t("商品简介")}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"property"} label={i18n.t("物料类型")}>
                            <DictionarySelect entityName={"dict_sku_property"} style={{width: 150}}/>
                        </Form.Item>
                        <Form.Item name={"label"} label={i18n.t("标签")} style={{width:250}}>
                            <TreeSelectByType entityName={"clothes_sku_category"} multiple={true} />
                        </Form.Item>
                        <Button onClick={() => {
                            var name = form.getFieldValue("name")
                            var code: any = form.getFieldValue("code")
                            var property: any = form.getFieldValue("property")
                            var label: any = form.getFieldValue("label")
                            const temp: any = {};

                            if (name) {
                                temp.name = name;
                            }
                            if (code) {
                                temp.code = code;
                            }
                            if (property) {
                                temp.property = property;
                            }
                            if (label) {
                                var labelStr = "";
                                if(label.length > 0){
                                    labelStr = label.join(",");
                                }
                                temp.label = labelStr;
                            }

                            setSearchData(temp)

                        }}>{i18n.t("搜索")}</Button>

                        <Button onClick={() => {
                            form.setFieldsValue({name: undefined, code: undefined})
                            setSearchData({} as any)

                        }}>{i18n.t("重置")}</Button>
                    </Form>
                }
            />


            <DataGrid ref={gridRef}  url={"/clothes/inventory/getInventoryList"}
                      searchData={searchData}
                      descriptions={[
                          {
                              fieldName: "property",
                              fieldShowName: i18n.t("商品类别",),
                              isHiddenSearch:true
                          },
                          {
                              fieldName: "name",
                              fieldShowName: i18n.t("商品编码",),
                              isHiddenSearch:true
                          },

                          {
                              fieldName: "code",
                              fieldShowName: i18n.t("商品简介",),
                              isHiddenSearch:true
                          },
                          {
                              fieldName: "category",
                              fieldShowName: i18n.t("标签",),
                              isHiddenSearch:true
                          },
                          {
                              fieldName: "unit",
                              fieldShowName: i18n.t("单位",),
                          },
                          {
                              fieldName: "quantity",
                              fieldShowName: i18n.t("库存数量",),
                              isHiddenSearch:false
                          },

                      ]}/>

        </div>
    );
}