import React, {
  useState,
  useContext, useRef,
} from "react";
import { Tabs, Select } from "antd";

import TabA from "./TabA";
import TabB from "./TabB";
import TabC from "./TabC";
import TabD from "./TabD";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useHistory, useParams } from "react-router";
import { formatDateTime, parseDate } from "../../util/DateFormat";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import i18n from "i18next";
import Session from "../../../../../../schemas/session";
import { SessionContext } from "../../../../index";
import ImageUpload from "../../../../../../components/ImageUpload/ImageUpload";
import FileUpload from "../../../../../../components/FileUpload/FileUpload";

const { TabPane } = Tabs;
const { Option } = Select;

export interface Params {
  uuid: string;
  customerType: string;
}
export default () => {
  const params = useParams<Params>();
  const uuid = params.uuid;
  const customerType = params.customerType;
  const [isFactory,setIsFactory] = useState(false);
  const session: Session = useContext(SessionContext);
  const [keys,setKeys] = useState("1");
  const callbackTabs = (key: any) => {
    console.log(key);
  };

  return (
      <div className="u-box u-basic-page u-MaterialArchivesForm">
        <div className="u-box-head u-box-head-border">
          <span className="u-box-title">{i18n.t("新增客户")}</span>
        </div>
        <div className="u-box-con u-fixed-padding">
          <div className="u-tabsA">
            <div className="u-form-lr">
              <Tabs activeKey={keys} onChange={callbackTabs}>
                <TabPane tab={i18n.t("添加客户")} key="1">
                  <TabA customerId={uuid} onOk={(key: string) => {
                    setKeys(key);
                  }} setFactory={() => {
                    setIsFactory(true);
                  }} customerType={customerType}/>
                </TabPane>
                <TabPane tab={i18n.t("基本信息完善")} key="2">
                  <TabB customerId={uuid} onOk={(key: string) => {
                    setKeys(key);
                  }} isFactory={isFactory} customerType={customerType}/>
                </TabPane>
                <TabPane tab={i18n.t("联系信息完善")} key="3">
                  <TabC customerId={uuid} onOk={(key: string) => {
                    setKeys(key);
                  }} isFactory={isFactory} customerType={customerType}/>
                </TabPane>
                {
                  isFactory ?
                      <TabPane tab={i18n.t("工序")} key="4">
                        <TabD customerId={uuid} onOk={(key: string) => {
                          setKeys(key);
                        }} customerType={customerType}/>
                      </TabPane> : <></>
                }
              </Tabs>
            </div>
          </div>
        </div>
      </div>
  );
};
