import React from "react";
import { Empty, Card, Badge } from "antd";

import { Comment } from "../Comment";
import { AvatarWrapper } from "../../../../../../../../../components/AvatarWrapper";
import { Like } from "../Like";

export interface PoolItemProps {
  data: any;
  onComment: () => void;
  onLike: () => void;
}
export const PoolItem = ({ data, onComment, onLike }: PoolItemProps) => {
  return (
    <>
      <div
        className="pool"
        style={{ overflowX: "auto", display: "flex", marginTop: 20 }}
      >
        {data.map((record: any, index: any) => (
          <Card
            key={index}
            title={record.stageName}
            extra={
              <Badge
                count={record.contactNum}
                overflowCount={999}
                style={{ backgroundColor: "#1890ff" }}
              />
            }
            bordered={true}
            style={{ width: 340, flex: "0 0 340px", marginRight: 20 }}
          >
            {record.contactList && record.contactList.length > 0 ? (
              record.contactList.map((item: any) => (
                <div
                  key={item.uuid}
                  className="card-content-item"
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "10px 15px",
                    marginBottom: 10,
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                  >
                    {item.customerName}
                  </span>
                  <div style={{ color: "#00000073" }}>{item.content}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      <AvatarWrapper
                        userId={item.advisorId}
                        userName={item.advisorName}
                      />
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      {item.contactTime.split(" ")[0]}
                    </span>
                    <Comment
                      count={item.commentNumber}
                      uuid={item.uuid}
                      onComment={() => {
                        if (onComment) {
                          onComment();
                        }
                      }}
                    />
                    <Like
                      count={item.likeNumber}
                      uuid={item.uuid}
                      liked={item.isLike}
                      onLike={() => {
                        if (onLike) {
                          onLike();
                        }
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <Empty
                style={{
                  textAlign: "center",
                  backgroundColor: "#ffffff",
                }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </Card>
        ))}
      </div>
    </>
  );
};
