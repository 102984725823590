import React from "react";
import { Avatar } from "antd";
import { BaseURL } from "../../constants/url";

export interface AvatarWrapperProps {
  userId: string;
  userName: string;
  size?: "small" | "default" | "large";
  direction?: "default" | "vertical";
}
export const AvatarWrapper = ({
  direction,
  size = "small",
  userId,
  userName,
}: AvatarWrapperProps) => {
  const avatar = (
    <Avatar
      size={size}
      src={`${BaseURL}/oa/common/attachment/showAvatar?userId=${userId}`}
    />
  );
  return (
    <>
      {direction === "vertical" ? (
        <table
          style={{
            display: "inline-block",
            paddingTop: 10,
            paddingRight: 10,
            paddingBottom: 10,
            paddingLeft: 20,
          }}
        >
          <tbody>
            <tr>
              <td>{avatar}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <span>{userName}</span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <>
          {avatar}
          <span
            style={{
              marginLeft: "4px",
              verticalAlign: "middle",
            }}
          >
            {userName}
          </span>
        </>
      )}
    </>
  );
};
