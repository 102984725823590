import React from "react";
import {Form, Select} from "antd";

interface Param {
    onChange:(record:any)=>void
}
const DiscountRateType = function ({onChange}:Param) {
    return (
        <Form.Item name={"discountType"} noStyle>
            <Select defaultValue="$" onChange={onChange}>
                <Select.Option value="1">$</Select.Option>
                <Select.Option value="2">%</Select.Option>
            </Select>
        </Form.Item>
    )
}

export default DiscountRateType;