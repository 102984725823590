import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  get,
  getByCustomerCategoryId,
} from "../../../../../../util/description";
import { getConfigByName } from "../../../../../../util/config";
import {
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  Radio,
  Space,
  Spin,
  Tabs,
  Tag,
} from "antd";
import {
  ExclamationCircleOutlined,
  FileAddOutlined,
  UserSwitchOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
  DeleteOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import { UserSelect } from "../../../../../../components/UserSelect";
import { TableGrid } from "../../../../../../components/TableGrid";
import {
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
} from "@ant-design/icons/lib";
import { useLocalStorage, useWindowSize } from "react-use";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import { CustomerTransferDialog } from "../../components/CustomerTransferDialog";
import { CustomerTagDialog } from "../../components/CustomerTagDialog";
import { CustomerMemberAddDialog } from "../../components/CustomerMemberAddDialog";
import { Focus } from "../../components/Focus";
import moment from "moment";
import { TreeWrapper } from "../../../../../../components/TreeWrapper";
import { Detail } from "../Detail";
import { Description } from "../../../../../../schemas/description";
import { CustomerContactDetailDialog } from "../../components/CustomerContactDetailDialog";
import { ButtonCustomerContactList } from "../../components/ButtonCustomerContactList";
import {
  advisorIdRender,
  buildFilterURL,
  mobileRender,
  nameRender,
} from "./util";
import {
  CRMFilterDialog,
  CustomerBelongMapper,
  CustomerFilters,
  DateRangeMapper,
} from "../../../../../../components/CRMFilterDialog";
import { CustomerTag } from "../../../../../../schemas/customerTag";
import { Result } from "../../../../../../schemas/common";
import update from "immutability-helper";
import { ConfigName } from "../../../../../../enum";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const { TabPane } = Tabs;
const { Search } = Input;
const { Sider, Content } = Layout;
const { confirm } = Modal;
const { CheckableTag } = Tag;

const defaultUrl = "/crm/customer/getCustomerList";
const defaultCustomerFilters: CustomerFilters = {
  belong: "",
  dateRangeFilters: {},
  customFilters: {},
  fieldFilters: [],
};

async function fetchTags(): Promise<any[]> {
  const resp = await axios.post<Result<any[]>>(
    "/crm/customer/getAdvisorCreatedLabel"
  );
  return resp.data.Data;
}

/**
 * 客户列表
 * @constructor
 */
export default function List() {
  const allGridRef = useRef<any>();
  const followingUpGridRef = useRef<any>();
  const successGridRef = useRef<any>();
  const failGridRef = useRef<any>();
  const tableGridRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [recentCustomerFilters, setRecentFilterCondition] = useLocalStorage<
    CustomerFilters
  >("customer-list.filter", defaultCustomerFilters);
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const [allUrl, setAllUrl] = useState(
    `${defaultUrl}?customerStatus=${buildFilterURL(
      recentCustomerFilters,
      descriptions
    )}`
  );
  const [followingUpUrl, setFollowingUpUrl] = useState(
    `${defaultUrl}?customerStatus=0${buildFilterURL(
      recentCustomerFilters,
      descriptions
    )}`
  );
  const [successUrl, setSuccessUrl] = useState(
    `${defaultUrl}?customerStatus=2${buildFilterURL(
      recentCustomerFilters,
      descriptions
    )}`
  );
  const [failUrl, setFailUrl] = useState(
    `${defaultUrl}?customerStatus=1${buildFilterURL(
      recentCustomerFilters,
      descriptions
    )}`
  );
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);
  const { height } = useWindowSize();
  const session: Session = useContext(SessionContext);
  const [gonghaiForm, setGonghaiForm] = useState({
    id: "",
    visible: false,
  });
  const [categoryTransferForm, setCategoryTransferForm] = useState({
    customerIds: [],
    visible: false,
  });
  const [customerTagForm, setCustomerTagForm] = useState({
    customerId: "",
    visible: false,
  });
  const [customerRoleForm, setCustomerRoleForm] = useState({
    customerIds: [],
    visible: false,
  });
  const [currentTabKey, setCurrentTabKey] = useState<"-1" | "0" | "1" | "2">(
    "-1"
  );
  const [addCustomerForm, setAddCustomerForm] = useState({
    visible: false,
    mvp: true,
  });
  const [addCustomerContactForm, setAddCustomerContactForm] = useState({
    customerId: "",
    visible: false,
  });
  const [
    customerFilterDialogVisible,
    setCustomerFilterDialogVisible,
  ] = useState(false);
  const [treeCategory, setTreeCategory] = useState();
  const [tags, setTags] = useState<CustomerTag[]>([]);
  useEffect(function () {
    fetchTags().then((result) => setTags(result));
  }, []);
  useEffect(() => {
    customerFilterDialogVisible &&
      fetchTags().then((result) => setTags(result));
  }, [customerFilterDialogVisible]);
  useEffect(function () {
    get("crm_customer").then((descriptions: FieldDescription[]) => {
      setDescriptions(descriptions);
      setCurrentDescriptions(descriptions);
      setLoading(false);
    });
  }, []);
  const { t } = useTranslation();
  const free = useCallback(
    function (gridRef: React.MutableRefObject<any>) {
      const selectedRows: any[] = gridRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        message.error("请勾选要放到公海的客户");
        return;
      }
      const hasPermission = selectedRows.every(function (row: any) {
        return row.advisorId === session.id;
      });
      if (!hasPermission) {
        message.error("本次操作包含无权限操作的客户，已终止");
        return;
      }
      getConfigByName(ConfigName.DisplayCustomerTransferButton).then(
        (config) => {
          if (config && config.value === "true") {
            setGonghaiForm((prevState) => ({
              ...prevState,
              visible: true,
            }));
          } else {
            axios
              .post("/crm/customer/gongHai/inGonghai", {
                ids: gridRef.current.getSelectedIds().join(","),
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.success("操作成功");
                  gridRef.current.refresh();
                }
              });
          }
        }
      );
    },
    [session.id]
  );

  function handleTabBarExtraMenuClick({ key }: any) {
    switch (key) {
      case "export":
        const index = parseInt(currentTabKey);
        const gridRef =
          [followingUpGridRef, failGridRef, successGridRef][index] ||
          allGridRef;
        gridRef.current.export();
    }
  }

  function handleTabChange(key: string): void {
    setCurrentTabKey(key as "-1" | "0" | "1" | "2");
  }

  function del(gridRef: React.MutableRefObject<any>) {
    const ids = gridRef.current.getSelectedIds().join(",");
    if (ids === "") {
      message.error("请选择您要删除的客户");
      return;
    }
    const customerNames = gridRef.current
      .getSelectedRows()
      .map((item: any) => item.name)
      .join(",")
      .replace(/,/g, "、");
    confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      content: `您确定要删除客户"${customerNames}"吗? 不可恢复!`,
      onOk() {
        axios
          .post("/crm/customer/deleteCustomer", {
            ids: ids,
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              message.success("删除成功");
              gridRef.current.refresh();
            } else {
              message.error(resp.data.Data);
            }
          });
      },
    });
  }

  const transfer = useCallback(
    function transfer(gridRef: React.MutableRefObject<any>) {
      const selectedRows: any[] = gridRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        message.error("请选择您要转移分组的客户");
        return;
      }
      const hasPermission = selectedRows.every(function (row: any) {
        return row.advisorId === session.id;
      });
      if (!hasPermission) {
        message.error("本次操作包含无权限操作的客户，已终止");
        return;
      }
      setCategoryTransferForm({
        ...categoryTransferForm,
        visible: true,
        customerIds: gridRef.current.getSelectedIds(),
      });
    },
    [categoryTransferForm, session.id]
  );

  const addRoleStaff = useCallback(
    function (gridRef: React.MutableRefObject<any>) {
      const selectedRows: any[] = gridRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        message.error(i18n.t("请选择客户"));
        return;
      }
      const hasPermission = selectedRows.every(function (row: any) {
        return row.advisorId === session.id;
      });
      if (!hasPermission) {
        message.error(i18n.t("无权为他人客户设置成员"));
        return;
      }
      setCustomerRoleForm({
        ...customerRoleForm,
        visible: true,
        customerIds: gridRef.current.getSelectedIds().join(","),
      });
    },
    [customerRoleForm, session.id]
  );

  const setTag = useCallback(
    function (gridRef: React.MutableRefObject<any>) {
      const selectedRows: any[] = gridRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        message.error(i18n.t("请选择客户"));
        return;
      }
      if (selectedRows.length > 1) {
        message.error(i18n.t("请选择一个客户"));
        return;
      }
      const hasPermission = selectedRows.every(function (row: any) {
        return row.advisorId === session.id;
      });
      if (!hasPermission) {
        message.error("无权为他人客户设置标签");
        return;
      }
      setCustomerTagForm({
        ...customerTagForm,
        visible: true,
        customerId: selectedRows[0].uuid,
      });
    },
    [customerTagForm, session.id]
  );

  const formatDescriptions = useCallback(
    function (gridRef) {
      const formattedCurrentDescriptions = JSON.parse(
        JSON.stringify(currentDescriptions)
      );
      formattedCurrentDescriptions.forEach((description: Description) => {
        if (description.fieldName === "name") {
          description.fixed = true;
          description.render = nameRender;
        } else if (description.fieldName === "advisorId") {
          description.render = advisorIdRender;
        } else if (description.fieldName === "mobile") {
          description.width = 150;
          description.render = mobileRender;
        }
      });
      formattedCurrentDescriptions.unshift({
        fieldShowName: "操作",
        render: (text: any, record: any) => {
          return (
            <>
              <Space>
                <Focus
                  isFocus={record.isFocus}
                  uuid={record.uuid}
                  onFocus={() => {
                    if (gridRef.current) {
                      gridRef.current.refresh();
                    }
                  }}
                />
                <ButtonCustomerContactList customerId={record.uuid} />
                {record.ref.advisorId === session.id && (
                  <>
                    <FileAddOutlined
                      style={{ verticalAlign: "middle" }}
                      onClick={(e) => {
                        setAddCustomerContactForm((prevState) => ({
                          ...prevState,
                          visible: true,
                          customerId: record.uuid,
                        }));
                        e.stopPropagation();
                      }}
                    />
                  </>
                )}
              </Space>
            </>
          );
        },
        align: "center",
        fixed: true,
      });
      return formattedCurrentDescriptions;
    },
    [currentDescriptions, session.id]
  );

  const getTabPane = useCallback(
    function (
      tabName: string,
      key: "-1" | "0" | "1" | "2",
      gridRef: React.MutableRefObject<any>,
      url: string,
      setUrl: React.Dispatch<React.SetStateAction<string>>
    ) {
      return (
        <TabPane tab={tabName} key={key}>
          {currentTabKey === key && (
            <>
              <Layout>
                <Sider width={260}>
                  <TreeWrapper
                    editable={session.permissions.some(
                      (item: string) => item === "全部客户管理"
                    )}
                    entityName="crm_customer_category"
                    onSelected={(keys) => {
                      const category: any = keys[0];
                      setTreeCategory(category);
                      getByCustomerCategoryId(category).then(
                        (fieldNames: string) => {
                          let copy = JSON.parse(JSON.stringify(descriptions));
                          if (fieldNames) {
                            copy = copy.filter(
                              (item: FieldDescription) =>
                                fieldNames
                                  .split(",")
                                  .indexOf(item.fieldName || "") > -1
                            );
                          }
                          setCurrentDescriptions(copy);
                          let status = key === "-1" ? "" : key;
                          setUrl(
                            `${defaultUrl}?customerStatus=${status}&category=${category}${buildFilterURL(
                              recentCustomerFilters,
                              descriptions
                            )}`
                          );
                        }
                      );
                    }}
                  />
                </Sider>
                <Content>
                  <Layout style={{ paddingLeft: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                      <Space>
                        <Button
                          icon={<UserSwitchOutlined />}
                          onClick={() => {
                            if (gridRef.current.getSelectedIds().length < 1) {
                              message.error("请选择要分配的客户");
                              return;
                            }
                            setUserSelectVisible(true);
                          }}
                        >
                          移交
                        </Button>
                        <Button
                          icon={<UserDeleteOutlined />}
                          onClick={() => {
                            free(gridRef);
                          }}
                        >
                          放入公海
                        </Button>
                        <Button
                          icon={<UserAddOutlined />}
                          onClick={() => {
                            addRoleStaff(gridRef);
                          }}
                        >
                          添加成员
                        </Button>
                        {session.permissions.some(
                          (item: any) => item === "删除客户"
                        ) && (
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => del(gridRef)}
                          >
                            删除
                          </Button>
                        )}
                        <Button
                          icon={<SwapOutlined />}
                          onClick={() => transfer(gridRef)}
                        >
                          转移类别
                        </Button>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item>发送短信</Menu.Item>
                              <Menu.Item onClick={() => setTag(gridRef)}>
                                设置标签
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button icon={<DownOutlined />}>更多</Button>
                        </Dropdown>
                      </Space>
                      <Space style={{ float: "right" }}>
                        <Button
                          icon={<FilterOutlined />}
                          onClick={() => {
                            setCustomerFilterDialogVisible(true);
                          }}
                        >
                          筛选
                        </Button>
                      </Space>
                    </div>
                    <Content>
                      {(recentCustomerFilters.belong ||
                        (recentCustomerFilters.dateRangeFilters
                          .contactTime instanceof Array &&
                          recentCustomerFilters.dateRangeFilters.contactTime
                            .length === 2) ||
                        (recentCustomerFilters.customFilters.tags &&
                          recentCustomerFilters.customFilters.tags.length >
                            0) ||
                        (typeof recentCustomerFilters.dateRangeFilters
                          .contactTime === "string" &&
                          recentCustomerFilters.dateRangeFilters
                            .contactTime)) && (
                        <div style={{ marginBottom: 10 }}>
                          {recentCustomerFilters.belong &&
                            CustomerBelongMapper.filter(
                              (item) =>
                                item.value === recentCustomerFilters.belong
                            ).map((item) => (
                              <Tag
                                closable={true}
                                visible={true}
                                color={"processing"}
                                key={`belong-${item.value}`}
                                onClose={() => {
                                  const filter = JSON.parse(
                                    JSON.stringify(recentCustomerFilters)
                                  );
                                  filter.belong = "";
                                  setRecentFilterCondition(filter);
                                  const filterURL = buildFilterURL(
                                    filter,
                                    descriptions
                                  );
                                  let status = key === "-1" ? "" : key;
                                  setUrl(
                                    `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${filterURL}`
                                  );
                                }}
                              >
                                {`客户归属 等于 "${item.text}"`}
                              </Tag>
                            ))}
                          {recentCustomerFilters.dateRangeFilters.contactTime &&
                            (recentCustomerFilters.dateRangeFilters
                              .contactTime instanceof Array ? (
                              recentCustomerFilters.dateRangeFilters.contactTime
                                .length === 2 ? (
                                <Tag
                                  color={"processing"}
                                  closable={true}
                                  onClose={() => {
                                    const filter = JSON.parse(
                                      JSON.stringify(recentCustomerFilters)
                                    );
                                    filter.dateRangeFilters = {};

                                    const filterURL = buildFilterURL(
                                      filter,
                                      descriptions
                                    );
                                    setRecentFilterCondition(filter);
                                    let status = key === "-1" ? "" : key;
                                    setUrl(
                                      `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${filterURL}`
                                    );
                                  }}
                                >
                                  {"最近联系时间 " +
                                    (recentCustomerFilters.dateRangeFilters
                                      .contactTime[0]
                                      ? `从 '${recentCustomerFilters.dateRangeFilters.contactTime[0]}'`
                                      : "") +
                                    (recentCustomerFilters.dateRangeFilters
                                      .contactTime[1]
                                      ? ` 至 '${recentCustomerFilters.dateRangeFilters.contactTime[1]}'`
                                      : "")}
                                </Tag>
                              ) : (
                                <></>
                              )
                            ) : (
                              DateRangeMapper.filter((item) => {
                                return (
                                  item.value ===
                                  recentCustomerFilters.dateRangeFilters
                                    .contactTime
                                );
                              }).map((item) => (
                                <Tag
                                  color={"processing"}
                                  closable={true}
                                  onClose={() => {
                                    const filter = JSON.parse(
                                      JSON.stringify(recentCustomerFilters)
                                    );
                                    filter.dateRangeFilters = {};
                                    const filterURL = buildFilterURL(
                                      filter,
                                      descriptions
                                    );
                                    setRecentFilterCondition(filter);
                                    let status = key === "-1" ? "" : key;
                                    setUrl(
                                      `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${filterURL}`
                                    );
                                  }}
                                >
                                  {'最近联系时间 为 "' + item.text + '"'}
                                </Tag>
                              ))
                            ))}
                          {recentCustomerFilters.customFilters.tags.map(
                            (text: string) => (
                              <Tag
                                closable={true}
                                color={"processing"}
                                onClose={() => {
                                  const filter = JSON.parse(
                                    JSON.stringify(recentCustomerFilters)
                                  );
                                  filter.customFilters.tags = filter.customFilters.tags.filter(
                                    (item: string) => item !== text
                                  );
                                  const filterURL = buildFilterURL(
                                    filter,
                                    descriptions
                                  );
                                  setRecentFilterCondition(filter);
                                  let status = key === "-1" ? "" : key;
                                  setUrl(
                                    `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${filterURL}`
                                  );
                                }}
                              >
                                {text}
                              </Tag>
                            )
                          )}
                          <Button
                            type={"link"}
                            onClick={() => {
                              setRecentFilterCondition(defaultCustomerFilters);
                              let status = key === "-1" ? "" : key;
                              setUrl(
                                `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${buildFilterURL(
                                  defaultCustomerFilters,
                                  descriptions
                                )}`
                              );
                            }}
                          >
                            清空筛选
                          </Button>
                        </div>
                      )}

                      {currentDescriptions.length > 0 && height && session.id && (
                        <>
                          <DataGrid
                            onDraw={(record) => (
                              <Detail
                                key={record.key}
                                customerId={record.key}
                                customerType="normal"
                              />
                            )}
                            drawerWidth={1000}
                            pageSize={Math.floor((height - 220) / 42)}
                            ref={gridRef}
                            url={url}
                            rowSelectionType="checkbox"
                            descriptions={formatDescriptions(gridRef)}
                          />
                        </>
                      )}
                    </Content>
                  </Layout>
                </Content>
              </Layout>
              <Modal
                title="选择放入的公海"
                width={250}
                visible={gonghaiForm.visible}
                onCancel={() => {
                  setGonghaiForm({
                    ...gonghaiForm,
                    visible: false,
                    id: "",
                  });
                }}
                onOk={() => {
                  axios
                    .post("/crm/customer/gongHai/inGonghai", {
                      ids: gridRef.current.getSelectedIds().join(","),
                      libraryId: gonghaiForm.id,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        message.success("操作成功");
                        gridRef.current.refresh();
                        setGonghaiForm({
                          ...gonghaiForm,
                          visible: false,
                          id: "",
                        });
                      }
                    });
                }}
              >
                <DictionarySelect
                  style={{ width: 188, marginLeft: 8 }}
                  entityName="crm_customer_public_library"
                  value={gonghaiForm.id}
                  onChange={(value: any) => {
                    setGonghaiForm({
                      ...gonghaiForm,
                      id: value,
                    });
                  }}
                />
              </Modal>
              <UserSelect
                visible={userSelectVisible}
                multiple={false}
                defaultSelectedUsers={[]}
                onCancel={() => {
                  setUserSelectVisible(false);
                }}
                onOk={(selectedUsers: { id: string }[]) => {
                  axios
                    .post<
                      Result<{
                        isCreatable: boolean;
                        isCreatableNumber: number;
                      }>
                    >("/crm/customer/distributable", {
                      userId: selectedUsers[0].id,
                      customerNumberToAdd: gridRef.current.getSelectedIds()
                        .length,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        if (!resp.data.Data.isCreatable) {
                          message.error(
                            `您选择的业务员的客户数超额，您最多为其分配${resp.data.Data.isCreatableNumber}个客户`
                          );
                          return;
                        }
                        axios
                          .post<Result<void>>("/crm/customer/assign", {
                            customerIds: gridRef.current
                              .getSelectedIds()
                              .join(","),
                            toAdvisorId: selectedUsers[0].id,
                          })
                          .then((res) => {
                            if (res.data.Status === "1") {
                              message.success("分配成功");
                              gridRef.current.refresh();
                            } else {
                              message.error(`分配失败：${res.data.Message}`);
                            }
                          });
                      }
                    });
                  setUserSelectVisible(false);
                }}
              />
              <CustomerTransferDialog
                visible={categoryTransferForm.visible}
                customerIds={categoryTransferForm.customerIds}
                onCancel={() => {
                  setCategoryTransferForm((prevState) => ({
                    ...prevState,
                    visible: false,
                    customerIds: [],
                  }));
                }}
                onOk={() => {
                  setCategoryTransferForm((prevState) => ({
                    ...prevState,
                    visible: false,
                    customerIds: [],
                  }));
                  gridRef.current.refresh();
                }}
              />
              <CustomerTagDialog
                visible={customerTagForm.visible}
                customerId={customerTagForm.customerId}
                onCancel={() => {
                  setCustomerTagForm({
                    ...customerTagForm,
                    visible: false,
                    customerId: "",
                  });
                }}
              />
              <CRMFilterDialog
                descriptions={currentDescriptions!}
                visible={customerFilterDialogVisible}
                dateRangeFilters={[
                  {
                    dataIndex: "contactTime",
                    title: "最近联系时间：",
                  },
                ]}
                customFilters={[
                  (customerFilters, setCustomerFilter) => (
                    <>
                      <span>标签：</span>
                      <CheckableTag
                        key={"all"}
                        checked={
                          !customerFilters.customFilters.tags ||
                          customerFilters.customFilters.tags.length === 0
                        }
                        onChange={(checked) => {
                          if (checked) {
                            setCustomerFilter((prevState) => ({
                              ...prevState,
                              customFilters: { tags: [] },
                            }));
                          }
                        }}
                      >
                        全部
                      </CheckableTag>
                      {tags.map((tag) => (
                        <CheckableTag
                          key={tag.name}
                          checked={
                            customerFilters.customFilters.tags &&
                            customerFilters.customFilters.tags.indexOf(
                              tag.name
                            ) > -1
                          }
                          onChange={(checked) => {
                            const customFilters = customerFilters.customFilters;
                            if (checked) {
                              setCustomerFilter({
                                ...customerFilters,
                                customFilters: {
                                  ...customFilters,
                                  tags: [
                                    ...(customFilters.tags || []),
                                    tag.name,
                                  ],
                                },
                              });
                            } else {
                              setCustomerFilter({
                                ...customerFilters,
                                customFilters: {
                                  ...customFilters,
                                  tags: update(customFilters.tags, {
                                    $splice: [
                                      [customFilters.tags.indexOf(tag.name), 1],
                                    ],
                                  }),
                                },
                              });
                            }
                          }}
                        >
                          {tag.name}
                        </CheckableTag>
                      ))}{" "}
                    </>
                  ),
                ]}
                onOk={(filter) => {
                  const filterURL = buildFilterURL(filter, descriptions);
                  setRecentFilterCondition(filter);
                  let status = key === "-1" ? "" : key;
                  setUrl(
                    `${defaultUrl}?customerStatus=${status}&category=${treeCategory}${filterURL}`
                  );
                  setCustomerFilterDialogVisible(false);
                }}
                onCancel={() => {
                  setCustomerFilterDialogVisible(false);
                }}
              />
              <CustomerMemberAddDialog
                visible={customerRoleForm.visible}
                customerIds={customerRoleForm.customerIds}
                onCancel={() => {
                  setCustomerRoleForm({
                    ...customerRoleForm,
                    visible: false,
                    customerIds: [],
                  });
                }}
                onOk={() => {
                  setCustomerRoleForm({
                    ...customerRoleForm,
                    visible: false,
                    customerIds: [],
                  });
                }}
              />{" "}
            </>
          )}
        </TabPane>
      );
    },
    [
      currentTabKey,
      session.permissions,
      recentCustomerFilters,
      currentDescriptions,
      height,
      formatDescriptions,
      gonghaiForm,
      userSelectVisible,
      categoryTransferForm.visible,
      categoryTransferForm.customerIds,
      customerTagForm,
      customerFilterDialogVisible,
      customerRoleForm,
      descriptions,
      free,
      addRoleStaff,
      transfer,
      setTag,
      setRecentFilterCondition,
      treeCategory,
      tags,
    ]
  );

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey={currentTabKey}
          onChange={handleTabChange}
          tabBarExtraContent={
            <Space>
              <Button
                type={"primary"}
                icon={<PlusOutlined />}
                onClick={() => {
                  setAddCustomerForm({
                    ...addCustomerForm,
                    visible: true,
                  });
                }}
              >
                {t("新建客户")}
              </Button>
              <Dropdown
                overlay={
                  <Menu onClick={handleTabBarExtraMenuClick}>
                    <Menu.Item>导入</Menu.Item>
                    <Menu.Item key={"export"}>导出</Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  更多
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          }
        >
          {getTabPane("全部", "-1", allGridRef, allUrl, setAllUrl)}
          {getTabPane(
            "跟进中的",
            "0",
            followingUpGridRef,
            followingUpUrl,
            setFollowingUpUrl
          )}
          {/* {getTabPane("待跟进的", notGridRef)} */}
          {getTabPane("成交的", "2", successGridRef, successUrl, setSuccessUrl)}
          {getTabPane("失败的", "1", failGridRef, failUrl, setFailUrl)}
        </Tabs>
      </Spin>{" "}
      <Modal
        title="新建客户"
        width={800}
        bodyStyle={{ height: 400, overflowY: "auto" }}
        visible={addCustomerForm.visible}
        onCancel={() => {
          setAddCustomerForm({
            ...addCustomerForm,
            visible: false,
          });
        }}
        onOk={() => {
          tableGridRef.current.save();
        }}
      >
        <>
          <div
            style={{ textAlign: "right", marginBottom: 20, paddingRight: 25 }}
          >
            <Radio.Group
              onChange={(e) => {
                setAddCustomerForm({
                  ...addCustomerForm,
                  mvp: e.target.value === "default",
                });
              }}
              defaultValue="default"
            >
              <Radio.Button value="default">全部</Radio.Button>
              <Radio.Button value="mvp">精简</Radio.Button>
            </Radio.Group>
          </div>
          <div>
            <TableGrid
              onCell={(cell) => {
                if (cell.id === "address") {
                  cell.type = "address";
                } else if (cell.id === "mobile") {
                  // cell.onChange = (value, setValue) => {
                  //   // eval("setValue('name', value)");
                  // };
                }
              }}
              // onChange={(data, setValue) => {
              //   console.log(data);
              // }}
              ref={tableGridRef}
              entityName="crm_customer"
              columnCount={2}
              mvp={addCustomerForm.mvp}
              defaultValues={{
                creatorId: session.id,
                advisorId: session.id,
                createTime: moment().format("YYYY-MM-DD"),
                category: treeCategory,
              }}
              onSaved={() => {
                setAddCustomerForm({
                  ...addCustomerForm,
                  visible: false,
                });
                const index = parseInt(currentTabKey);
                const gridRef =
                  [followingUpGridRef, failGridRef, successGridRef][index] ||
                  allGridRef;
                gridRef.current.refresh();
              }}
            />
          </div>
        </>
      </Modal>
      <CustomerContactDetailDialog
        visible={addCustomerContactForm.visible}
        customerId={addCustomerContactForm.customerId}
        onCancel={() => {
          setAddCustomerContactForm({
            ...addCustomerContactForm,
            visible: false,
          });
        }}
        onOk={() => {
          setAddCustomerContactForm({
            ...addCustomerContactForm,
            visible: false,
          });
        }}
      />
    </>
  );
}
