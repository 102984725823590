import React, {useCallback, useEffect, useRef, useState} from "react";
import { Form, Input, Button, message, Modal, Switch } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import i18n from "i18next";
import { useHistory } from "react-router";
import { Result } from "../../../../../../schemas/common";
import { instance as axios } from "../../../../../../util/axios";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import TabFChild from "./TabFChild";
import {usePermission} from "../../../../../../util/permession";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
export interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const childRef:any = useRef();
  const [form] = Form.useForm();
  const [childForm] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if (flag) {
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    } else {
      history.push("/clothes/CRM/"+customerType);
    }
  }, []);
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/getWorkingProcedureById`, {
          customerId:customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            form.setFieldsValue({
              workingProcedureList:resp.data.Data
            });
          }
        });
  })
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    if(childForm.getFieldValue("index")!=-1){
      let flag = values.workingProcedureList[childForm.getFieldValue("index")].status;
      values.workingProcedureList[childForm.getFieldValue("index")].status = !flag;
    }
    axios
        .post<Result<any[]>>(`/clothes/customerManage/saveWorkingProcedureList`, {
          data: JSON.stringify(values.workingProcedureList),
          customerId: customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setLoading(false);
            setIsChange(false);
          }
        });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="uuid" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <Form.List name="workingProcedureList">
                {(fields, {add, remove}) => {
                  return (
                      <>
                        {fields.map((field) => (
                            <div
                                className="u-add-list-item"
                                style={{
                                  overflow: "hidden",
                                }}
                                key={field.key}
                            >
                              <div className="u-form-list">
                                <Form.Item name={[field.name, "uuid"]} hidden>
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                    name={[field.name, "customerId"]}
                                    hidden
                                    initialValue={customerId}
                                >
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="工序"
                                    name={[field.name, "stepId"]}
                                    rules={[
                                      {
                                        required: true,
                                        validator: async (rule, value) => {
                                          if (value == undefined || value.trim() == "") {
                                            message.error(i18n.t('工序不能为空'));
                                            throw new Error(i18n.t('工序不能为空'));
                                          }
                                        }
                                      },
                                    ]}
                                >
                                  <DictionarySelect
                                      entityName={"clothes_working_procedure"}
                                  />
                                </Form.Item>
                                <Form.Item label={i18n.t("启用")} name={[field.name, "status"]}>
                                  <Switch
                                      size="default"
                                      defaultChecked={form.getFieldValue("workingProcedureList")[field.name] == undefined ? false : form.getFieldValue("workingProcedureList")[field.name].status == undefined ? false : form.getFieldValue("workingProcedureList")[field.name].status}
                                      checkedChildren={i18n.t("color_manage_activated")}
                                      unCheckedChildren={i18n.t(
                                          "color_manage_not_activated"
                                      )}
                                      onClick={() => {
                                        if (childForm.getFieldValue("count") == 0) {
                                          childRef.current.setChildNums(1, field.name);
                                        } else {
                                          if (field.name == childForm.getFieldValue("index")) {
                                            childRef.current.setChildNums(1, -1);
                                          }
                                        }
                                      }}
                                      style={{width: 65}}
                                      // checked={form.getFieldValue("workingProcedureList")[field.name]==undefined?false:form.getFieldValue("workingProcedureList")[field.name].email==undefined?false:form.getFieldValue("workingProcedureList")[field.name].email}
                                      // onChange={(value)=>{
                                      //   let oldArr:any = form.getFieldValue("workingProcedureList");
                                      //   let index = field.name;
                                      //   let arr:any=[];
                                      //   if(value){
                                      //     for(let i=0;i<oldArr.length;i++){
                                      //       if(i!=index){
                                      //         oldArr[i].email=false;
                                      //       }
                                      //       arr.push(oldArr[i]);
                                      //     }
                                      //     form.setFieldsValue({
                                      //       workingProcedureList:arr,
                                      //     })
                                      //   }
                                      // }}
                                  />
                                </Form.Item>
                                <Form.Item label=" " colon={false}>
                                  {fields.length > 1 ? (
                                      <span
                                          className="u-del-form-list"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                      >
                                <DeleteOutlined/> {i18n.t("删除")}
                              </span>
                                  ) : null}
                                </Form.Item>
                              </div>
                            </div>
                        ))}
                        <div className="u-add-list">
                          <div className="u-form-list">
                            <Form.Item
                                label=" "
                                className="u-contacts-head"
                                colon={false}
                            >
                          <span
                              className="u-add-cof"
                              onClick={() => {
                                add();
                              }}
                          >
                            <PlusOutlined/> {i18n.t("添加工序")}
                          </span>
                            </Form.Item>
                          </div>
                        </div>
                      </>
                  );
                }}
              </Form.List>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
        <TabFChild childForm={childForm} ref={childRef}/>
      </div>
  );
};
