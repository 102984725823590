import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { instance as axios } from "../../../../../util/axios";
import { DynamicTab } from "../components/DynamicTab";

const { TabPane } = Tabs;

export default function FormList() {
  const [dynamicTabConfigs, setDynamicTabConfigs] = useState<any[]>([]);

  useEffect(function () {
    axios.post("/crm/dynamicTab/getSelectedList?host=customer").then((resp) => {
      if (resp.data.Status === "1") {
        if (resp.data.Data.length === 0) {
          return;
        }
        setDynamicTabConfigs(resp.data.Data);
      }
    });
  }, []);

  return (
    <>
      <Tabs>
        {dynamicTabConfigs.map((item: any) => (
          <TabPane tab={item.formName} key={item.tableName}>
            <DynamicTab
              key={item.tableName}
              data={{
                ...item,
                entityName: item.tableName,
                uuid: item.uuid,
              }}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}
