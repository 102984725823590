import React, {useEffect, useState} from "react";
import {Form, Input, Checkbox, Button, message} from "antd";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons";

import {FormInstance} from "antd/es/form";
import i18n from "i18next";
import {useHistory} from "react-router";
import {Result} from "../../../../../../schemas/common";
import {instance as axios} from "../../../../../../util/axios";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import TextArea from "antd/es/input/TextArea";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 15},
};

export interface Params {
    customerId: string;
    form:FormInstance;
}

export default ({customerId,form}: Params) => {
    useEffect(function () {
        // axios
        //     .post<Result<any[]>>(`/clothes/customerManage/getContactListByCustomerId`, {
        //         customerId: customerId,
        //     })
        //     .then((resp) => {
        //         if (resp.data.Status === "1") {
        //             form.setFieldsValue({
        //                 customerContactList: resp.data.Data
        //             });
        //         }
        //     });
    })

    return (
        <div className="u-box-con u-fixed-padding">
            <div className="u-tabsA">
                <Form.Provider>
                    <Form
                        {...layout}
                        form={form}
                        layout="horizontal"
                        name="basicForm"
                        autoComplete="off"
                    >
                    </Form>
                </Form.Provider>
            </div>
        </div>
    );
};
