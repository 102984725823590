import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, message, Modal} from "antd";

import i18n from "i18next";
import {useHistory} from "react-router";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import TextArea from "antd/es/input/TextArea";
import {instance as axios} from "../../../../../../util/axios";
import {Result} from "../../../../../../schemas/common";
import "../../CSS/customer.css";
import Brand from "../Brand/Brand";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
};

interface copyProps {
  customerId: string;
    onOk: (key:string) => void;
    isFactory: boolean;
    customerType: string;
}

export default ({ customerId,onOk,isFactory,customerType }: copyProps) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [registeredFilter,setRegisteredFilter] = useState("");
    const [save,setSave] = useState(false);
    const [officeFilter,setOfficeFilter] = useState("");
    const [isShowBranch,setIsShowBranch] = useState(false);
    const [isChange,setIsChange] = useState(false);
    const [nextStepLoading, setNextStepLoading] = useState(false);
    const [lastStepLoading, setLastStepLoading] = useState(false);
    const confirm = useCallback(function confirm(flag: boolean) {
        if(flag){
            Modal.confirm({
                title: i18n.t("返回操作",),
                icon: <ExclamationCircleOutlined />,
                content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
                okText: i18n.t("okText"),
                cancelText: i18n.t("cancelText"),
                onOk: function () {
                    history.push("/clothes/CRM/"+customerType);
                },
            });
        }else{
            history.push("/clothes/CRM/"+customerType);
        }

    }, []);
    const onFinish = (values: any) => {
        console.log("Finish:", values);
        values.uuid = customerId;
        axios
            .post<Result<any[]>>(`/clothes/customerManage/saveCustomer`, {
                data: JSON.stringify(values),
                type:"add"
            })
            .then((resp) => {
                if (resp.data.Status === "1") {
                    message.info(i18n.t("saveSuccess"));
                    if(save){
                        history.push("/clothes/CRM/"+customerType);
                    }else{
                        onOk("3");
                        setNextStepLoading(false);
                    }
                }else{
                    message.error(i18n.t("error"));
                    setNextStepLoading(false);
                }
            });
    };
    useEffect(function () {
        axios
            .post<Result<any[]>>(`/clothes/customerManage/getCustomerInformationById`, {
                customerId:customerId
            })
            .then((resp) => {
                if (resp.data.Status === "1") {
                    let data:any = resp.data.Data;
                    //4：客户
                    if(data.customerType=="4"){
                        setIsShowBranch(true);
                    }
                    form.setFieldsValue({
                        registeredCountry:data.country,
                        officeCountry:data.country,
                    })
                }
            });
    },[])
    const onFinishFailed = (values: any) => {
        let arr: any = [];
        values.errorFields.map((item: any) => {
            arr.push(<li>{item.errors}</li>);
        });
        setLoading(false);
        window.scrollTo(0, 0);
    };
    const onValuesChange = (values: any) => {
        setIsChange(true);
    };
    useEffect(function () {
        // form.setFieldsValue({
        //     registeredCountry:"ITA",
        //     officeCountry:"ITA",
        // })
    },[])
    return (
        <div className="u-box-con u-fixed-padding">
            <div className="u-tabsA">
                <Form.Provider>
                    <Form
                        {...layout}
                        form={form}
                        layout="horizontal"
                        name="basicForm"
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChange}
                    >
                        <div className="u-form-lr">
                            <div className="u-form-list">
                                <Form.Item name="uuid" hidden>
                                    <Input defaultValue={customerId}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="u-ThreCon">
                            <Form.Item label="p.iva" name="p_iva">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("公司注册地址")} name="registeredAddress">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("公司办公地址")} name="officeAddress">
                                <Input/>
                            </Form.Item>
                            <Form.Item label="COD. FIS" name="COD_FIS">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("国家")} name="registeredCountry">
                                <DictionarySelect entityName={"dict_country"} onChange={
                                    (value) => {
                                        setRegisteredFilter(" countryId = '" + value + "'");
                                    }
                                }/>
                            </Form.Item>
                            <Form.Item label={i18n.t("国家")} name="officeCountry">
                                <DictionarySelect isTrans={true} entityName={"dict_country"} onChange={
                                    (value) => {
                                        setOfficeFilter(" countryId = '" + value + "'");
                                    }
                                }/>
                            </Form.Item>
                            <Form.Item label="COD.SDI" name="COD_SDI">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("城市")} name="registeredCity">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("城市")} name="officeCity">
                                <Input/>
                            </Form.Item>
                            <Form.Item hidden={!isShowBranch} label={i18n.t("客户品牌")} name="brand">
                                <Brand form={form} isClose={true}/>
                            </Form.Item>
                            <Form.Item label={i18n.t("邮编")} name="registeredPostcode">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("邮编")} name="officePostcode">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("客户税率")} name="taxId">
                                <DictionarySelect entityName={"dict_tax"}/>
                            </Form.Item>
                            <Form.Item label={i18n.t("街道")} name="registeredStreet">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("街道")} name="officeStreet">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("中介")} name="introducerCode">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("省会编码")} name="registeredProvincialCapitalCode">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("省会编码")} name="officeProvincialCapitalCode">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={i18n.t("中介收费")} name="introducerCharges">
                                <Input/>
                            </Form.Item>
                            {isFactory && <Form.Item label={i18n.t("加工厂编码")} name="customerNumber">
                                <Input/>
                            </Form.Item>}
                            <Form.Item className="remark" label={i18n.t("客户备注")} name="remark" {...{
                                labelCol: {span: 3},
                                wrapperCol: {span: 20}
                            }}>
                                <TextArea rows={4}/>
                            </Form.Item>
                        </div>

                        <Form.Item
                            className="u-form-custom"
                            {...{wrapperCol: {span: 24}}}
                        >
                            <div className="u-button-box">
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        className="u-form-buttonB"
                                        onClick={() => {
                                            form.submit();
                                            setLoading(true);
                                            setSave(true);
                                        }}
                                        loading={loading}
                                    >
                                        {i18n.t("保存")}
                                    </Button>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            setLastStepLoading(true);
                                            onOk("1");
                                            setLastStepLoading(false);
                                        }}
                                        loading={lastStepLoading}
                                        className="u-form-buttonA"
                                    >
                                        {i18n.t("上一步")}
                                    </Button>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            form.submit();
                                            setNextStepLoading(true);
                                        }}
                                        loading={nextStepLoading}
                                        className="u-form-buttonA"
                                    >
                                        {i18n.t("下一步")}
                                    </Button>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            confirm(isChange);
                                        }}
                                        className="u-form-buttonA"
                                    >
                                        {i18n.t("返回")}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </Form>
                </Form.Provider>
            </div>
        </div>
    );

};
