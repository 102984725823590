import React from "react";
import { Tag, Tooltip, Input,message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import i18n from "i18next";
import { FormInstance } from "antd/es/form";

export default class EditableTagGroup extends React.Component {
  state = {
    tags: [],
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
  };

  componentWillReceiveProps=(nextProps)=>{
    this.setState({
      tags:
          nextProps.form.getFieldValue("brand") == undefined
              ? []
              : nextProps.form.getFieldValue("brand").split("!@#")
    })
  }

  setBrand = (tags) => {
    if (tags.length > 0) {
      this.props.form.setFieldsValue({
        brand: tags.join("!@#"),
        tags:tags
      });
    } else {
      this.props.form.setFieldsValue({
        brand: "",
        tags:tags
      });
    }
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
    this.setBrand(tags);
    return tags;
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    if(inputValue.trim()!=""){
      let { tags } = this.state;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      this.setState({
        tags,
        inputVisible: false,
        inputValue: "",
      });
      this.setBrand(tags);
      return tags;
    }else{
      let { tags } = this.state;
      this.setState({
        tags,
        inputVisible: false,
        inputValue: "",
      });
    }
  };

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value });
    console.log(e.target.value);
  };

  handleEditInputConfirm = () => {
    this.setState(({ tags, editInputIndex, editInputValue }) => {
      if(editInputValue.trim()!=""){
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        this.setBrand(newTags);
        return {
          tags: newTags,
          editInputIndex: -1,
          editInputValue: "",
        };
      }else{
        message.error(i18n.t("品牌框不能为空"));
      }
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  saveEditInputRef = (input) => {
    this.editInput = input;
  };

  render() {
    const {
      tags,
      inputVisible,
      inputValue,
      editInputIndex,
      editInputValue,
    } = this.state;
    return (
      <>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={this.saveEditInputRef}
                key={tag}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={this.handleEditInputChange}
                onBlur={this.handleEditInputConfirm}
                onPressEnter={this.handleEditInputConfirm}
              />
            );
          }

          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag
              className="edit-tag"
              key={tag}
              closable={this.props.isClose}
              onClose={() => this.handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  this.setState(
                    { editInputIndex: index, editInputValue: tag },
                    () => {
                      this.editInput.focus();
                    }
                  );
                  e.preventDefault();
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={this.showInput}>
            <PlusOutlined /> {i18n.t('add')}
          </Tag>
        )}
      </>
    );
  }
}
