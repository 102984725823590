import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Drawer,
  Row,
  Col,
  Tooltip,
  Upload,
  message,
  Button,
  Popover,
  List,
} from "antd";
import { instance as axios } from "../../../../../util/axios";
import { forEach } from "../../../../../components/DynamicForm/Designer/util";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";

export interface Params {
  id: string;
  entityName: string;
    isActive:boolean
}
interface Interface {
  fieldName: string;
  fieldShowName: string;
  inputType: number;
  dictionary: string;
  readOnly: boolean;
}

const CustomForms = function ({ id, entityName,isActive }: Params) {
  const [data, setData] = useState<Interface[]>([]);
  useEffect(
    function () {
      axios
        .post("/oa/field/getDetailField", {
          tableName: entityName,
          isHidden:isActive
        })
        .then(function (resp) {
          setData(resp.data.Data);
        });
    },
    [id]
  );

  function formatForm() {
    const element: ReactNode = data.map(function (item, key) {
      switch (item.inputType) {
        case 0: //输入框
          return (
            <Form.Item name={item.fieldName} label={item.fieldShowName} hidden={isActive}>
              <Input readOnly={item.readOnly} disabled={item.readOnly}/>
            </Form.Item>
          );
          break;
        case 4://日期选择
          return (
            <Form.Item name={item.fieldName} label={item.fieldShowName}  hidden={isActive}>
              <DateTimePickerWithFormat showTime={false} disabled={item.readOnly} style={{ width: '100%' }} />
            </Form.Item>
          );
          break;
        case 5://时间选择
          return (
            <Form.Item name={item.fieldName} label={item.fieldShowName}  hidden={isActive}>
              <DateTimePickerWithFormat showTime={true} disabled={item.readOnly} style={{ width: '100%' }} />
            </Form.Item>
          );
          break;
        case 6://字典
          return (
            <Form.Item name={item.fieldName} label={item.fieldShowName}  hidden={isActive}>
              <DictionarySelect entityName={item.dictionary} disabled={item.readOnly}/>
            </Form.Item>
          );
          break;
        default:
          return (
            <Form.Item name={item.fieldName} label={item.fieldShowName}  hidden={isActive}>
              <Input readOnly={item.readOnly} />
            </Form.Item>
          );
          break;
      }
    });
    return <div>{element}</div>;
  }

  return <>{formatForm()}</>;
};

export default CustomForms;
