import React from "react";
import { AvatarWrapper } from "../../../../../../../components/AvatarWrapper";
import { Space } from "antd";
import { ProjectFeed } from "../../../../../../../schemas/projecrFeed";

export interface FeedListItemProps {
  data: ProjectFeed;
}
export const FeedsItem = ({ data }: FeedListItemProps) => {
  return (
    <>
      <Space>
        <AvatarWrapper userId={data.creatorId} userName={""} size={"large"} />
        <div
          style={{
            flex: 1,
            paddingTop: 5,
          }}
        >
          <Space>
            <span>{data.name}</span>
            <span>{data.createTime}</span>
          </Space>
          <div>{data.content}</div>
        </div>
      </Space>
    </>
  );
};
