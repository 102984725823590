import React, { useCallback, useEffect, useRef, useState } from "react";
import {Form, Input, Button, Space, message, Table, Modal} from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import "../../CSS/customer.css";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { useHistory, useParams } from "react-router";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import moment from "moment";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {DataGrid} from "../../../../../../components/DataGrid";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Param {
  current: any;
}
interface Params {
  enterpriseId: string;
  type: string;
  id: string;
  onclose: ()=>void
}
export default ({enterpriseId,type,id,onclose}:Params) => {
  const [form] = Form.useForm();
  // const params = useParams<Params>();
  const { height } = useWindowSize();
  // const enterpriseId = params.enterpriseId;
  // const type = params.type;
  // const id = params.id;
  const history = useHistory();
  const { width } = useWindowSize();
  const gridRef = useRef<any>();
  const [descriptions] = useState({
    //加工厂字段
    processingPlant: [
      {
        fieldName: "factory",
        fieldShowName: "名称",
        width: 100,
      },
      {
        fieldName: "procedures",
        fieldShowName: "工序",
        width: 150,
      },
    ],
    //服装厂字段
    garmentFactory: [
      {
        fieldName: "factory",
        fieldShowName: "名称",
        width: 100,
      },
      {
        fieldName: "procedures",
        fieldShowName: "产品类型",
        width: 150,
      },
    ],
  });
  const [visiable, setVisiable] = useState(false);
  const hideModal = () => {
    setVisiable(false);
  };
  useEffect(function () {
    axios
      .post<Result<any[]>>(
        `/oa/appEnterpriseManagement/getEnterpriseManagementById`,
        {
          enterpriseId: enterpriseId,
        }
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          form.setFieldsValue(resp.data.Data);
        }
      });
  }, []);
  const [originData, setOriginData] = useState<any>([]);
  const onFinish = async (values: any) => {
    console.log("Finish:", values);
  };
  const onFinishFailed = (values: any) => {

  };
  const thisRef: Param = useRef();
  thisRef.current = {
    originData,
  };
  const getSubAccountList = useCallback(function getSubAccountList() {
    axios
      .post<Result<any[]>>(`/oa/appEnterpriseManagement/getSubAccountList`, {
        enterpriseId: enterpriseId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data = resp.data.Data;
          if (data != undefined) {
            data.map((item: any) => {
              item.key = item.uuid;
              item.isUpdate = 0;
            });
            setOriginData(data);
          } else {
            setOriginData([]);
          }
        }
      });
  }, []);
  useEffect(function () {
    getSubAccountList();
  }, []);
  const setSubAccountData = useCallback(function (arr: any) {
    if (arr.length > 0) {
      console.log(thisRef.current.originData);
      let newArr: any = [...thisRef.current.originData];
      arr.map((item: any) => {
        let flag = true;
        if (thisRef.current.originData.length > 0) {
          thisRef.current.originData.map((old: any) => {
            if (old.originEnterpriseId == item.id) {
              flag = false;
            }
          });
        }
        if (flag) {
          newArr.push({
            uuid: "",
            name: item.name,
            code: item.code,
            postId: "4c49f4de2e3e44659293975dd55ddfcd",
            contact: item.contact,
            contactWay: item.contactWay,
            type: item.type,
            ip: item.ip,
            parentId: enterpriseId,
            originEnterpriseId: item.id,
            mergeTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            registeredTime: item.registeredTime,
            finalLandingTime: item.finalLandingTime,
            isUpdate:0,
            key: item.uuid,
          });
        }
      });
      setOriginData(newArr);
    }
  }, []);

  return (
    <div className="u-box-con u-fixed-padding">
      <div className="u-tabsA">
        <Form.Provider>
          <Form
            {...layout}
            form={form}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="id" hidden>
              <Input readOnly={true} />
            </Form.Item>
            <div className="u-ThreCon">
              <Form.Item label={i18n.t("app_enterprise_main_account")} name="name">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("app_enterprise_account_type")} name="type">
                <DictionarySelect
                  disabled={true}
                  entityName="dict_enterprise_type"
                />
              </Form.Item>
              <Form.Item label={i18n.t("appEnterprise_registeredTime")} name="registeredTime">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("public_contract")} name="contact">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("enterprise_contact_information")} name="contactWay">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item
                className="remark"
                label="子账号"
                name="supplierList"
                {...{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }}
              >
                <div className="u-box-con">
                  <Table
                    bordered
                    pagination={false}
                    dataSource={thisRef.current.originData}
                    columns={[
                      {
                        title: i18n.t("appEnterprise_name"),
                        dataIndex: "name",
                        align: "center",
                        width: "18%",
                        // render: (text: any, record: any) => {
                        //   return (
                        //     <Input
                        //       defaultValue={record.name}
                        //       onChange={(e) => {
                        //         record.name = e.target.value;
                        //         if(record.uuid!=""){
                        //           record.isUpdate=1;
                        //         }
                        //       }}
                        //     />
                        //   );
                        // },
                      },
                      {
                        title: i18n.t("appEnterprise_account_role"),
                        dataIndex: "postId",
                        align: "center",
                        width: "18%",
                        render: (text: any, record: any) => {
                          return (
                            <DictionarySelects
                              entityName="base_position"
                              defaultValue={record.postId}
                              disabled={true}
                              filter={"1=1"}
                              onChange={(value) => {
                                record.postId = value;
                                if(record.uuid!=""){
                                  record.isUpdate=1;
                                }
                              }}
                            />
                          );
                        },
                      },
                      {
                        title: i18n.t("public_contract"),
                        dataIndex: "contact",
                        align: "center",
                        width: "18%",
                      },
                      {
                        title: i18n.t("enterprise_contact_information"),
                        dataIndex: "contactWay",
                        align: "center",
                        width: "18%",
                      },
                      {
                        title: i18n.t("appEnterprise_registeredTime"),
                        dataIndex: "registeredTime",
                        align: "center",
                        width: "18%",
                      },
                    ]}
                    rowClassName="editable-row"
                  />
                </div>
              </Form.Item>
              <Form.Item
                  className="remark"
                  label="关联工厂"
                  name="supplierList"
                  {...{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }}
              >
                <div className="u-box-con">
                  <DataGrid
                      drawerWidth={width}
                      style={{ marginBottom: 8 }}
                      pagination={true}
                      ref={gridRef}
                      pageSize={Math.floor((height - 280) / 42)}
                      // sorter={{ field: "createTime", order: "descend" }}
                      descriptions={
                        type == "1" ? descriptions.processingPlant : descriptions.garmentFactory
                      }
                      url={"/clothes/appFactory/getFactoryListByCorpId?corpId="+id+"&type="+type}
                  />
                </div>
              </Form.Item>
            </div>
            <Form.Item
              className="u-form-custom"
              {...{ wrapperCol: { span: 24 } }}
            >
              <div className="u-button-box">
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      onclose()
                    }}
                    className="u-form-buttonA"
                  >
                    返回
                  </Button>
                </Form.Item>
              </div>
            </Form.Item>
          </Form>
        </Form.Provider>
      </div>
    </div>
  );
};
