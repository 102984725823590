import React, { useEffect, useState } from "react";
import { message, Modal, Input, Tag, Button } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { UserCustomerTagManageDialog } from "../UserCustomerTagManageDialog";
import { CustomerTag } from "../../../../../../schemas/customerTag";
const { CheckableTag } = Tag;
const { Search } = Input;
export interface CustomerTagDialogProps {
  visible: boolean;
  customerId: string;
  onCancel: () => void;
}
export const CustomerTagDialog = (props: CustomerTagDialogProps) => {
  const [value, setValue] = useState("");
  const [tags, setTags] = useState<CustomerTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<CustomerTag[]>([]);
  const [tagManageVisible, setTagManageVisible] = useState(false);
  useEffect(
    function () {
      if (props.visible) {
        axios.get("/crm/customer/label/getList").then((resp) => {
          if (resp.data.Status === "1") {
            setTags(resp.data.Data);
          }
        });
      }
    },
    [props.visible]
  );
  useEffect(
    function () {
      if (props.visible) {
        axios
          .get(
            "/crm/customer/label/getLabelByCustomerId?customerId=" +
              props.customerId
          )
          .then((resp) => {
            if (resp.data.Status === "1") {
              setSelectedTags(resp.data.Data);
            }
          });
      }
    },
    [props.customerId, props.visible]
  );
  return (
    <>
      <Modal
        title="添加标签"
        width={400}
        bodyStyle={{ paddingBottom: 50 }}
        visible={props.visible}
        onCancel={() => {
          props.onCancel();
        }}
        footer={null}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <Search
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            enterButton="保存标签"
            onSearch={(value) => {
              if (value === "") {
                message.error("请输入内容");
                return;
              }
              const name = tags.some(function (item) {
                return item.name === value;
              });
              if (name) {
                message.error("该标签已存在，不可重复添加");
                return;
              }
              axios
                .post("/crm/customer/addCustomerPill", {
                  customerId: props.customerId,
                  name: value,
                })
                .then((resp) => {
                  if (resp.data.Status === "1") {
                    const newTag = {
                      name: resp.data.Data.name,
                      uuid: resp.data.Data.labelId,
                    };
                    setTags([...tags, newTag]);
                    setSelectedTags([...selectedTags, newTag]);
                    setValue("");
                  }
                });
            }}
          />
          <Button
            type={"link"}
            onClick={() => {
              setTagManageVisible(true);
            }}
          >
            编辑标签
          </Button>
        </div>
        <div>
          {tags.map((tag) => (
            <CheckableTag
              key={tag.name}
              style={{
                border: "1px solid #1890ff",
                borderRadius: 30,
                marginBottom: 5,
              }}
              checked={
                selectedTags.filter(
                  (item: CustomerTag) => item.name === tag.name
                ).length === 1
              }
              onChange={(checked) => {
                if (checked) {
                  axios
                    .post("/crm/customer/addCustomerPill", {
                      customerId: props.customerId,
                      name: tag.name,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        setSelectedTags((prev) => [
                          ...prev,
                          ...tags.filter(
                            (tag) => tag.uuid === resp.data.Data.labelId
                          ),
                        ]);
                      }
                    });
                } else {
                  axios
                    .post("/crm/customer/delCustomerPillByName", {
                      customerId: props.customerId,
                      name: tag.name,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        if (resp.data.Data) {
                          setTags(tags.filter((item) => item.uuid != tag.uuid));
                        }
                        setSelectedTags(
                          selectedTags.filter((item) => item.uuid !== tag.uuid)
                        );
                      }
                    });
                }
              }}
            >
              {tag.name}
            </CheckableTag>
          ))}
        </div>
      </Modal>
      <UserCustomerTagManageDialog
        visible={tagManageVisible}
        onCancel={() => {
          setTagManageVisible(false);
        }}
        onOk={(newTags) => {
          setTags(newTags);
          setSelectedTags(
            newTags.filter((tag) => {
              return selectedTags.some((item) => item.uuid === tag.uuid);
            })
          );
          setTagManageVisible(false);
        }}
      />
    </>
  );
};
