import React, { useContext } from "react";
import { Button, message } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../../../../util/axios";
import { SessionContext } from "../../../../../../../index";
import Session from "../../../../../../../../../schemas/session";

export interface LikeProps {
  onLike: () => void;
  count: number;
  liked: boolean;
  uuid: string;
}
export const Like = (props: LikeProps) => {
  const session: Session = useContext(SessionContext);
  function handleLike(props: any) {
    if (!props.liked) {
      axios
        .post("/oa/like/like/add", {
          dataType: "联系记录",
          dataId: props.uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info("点赞成功");
            if (props.onLike) {
              props.onLike();
            }
          }
        });
    } else {
      axios
        .post("/oa/like/like/delete", {
          fromId: session.id,
          dataId: props.uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info("取消点赞成功");
            if (props.onLike) {
              props.onLike();
            }
          }
        });
    }
  }
  return (
    <>
      <Button
        type="link"
        icon={<LikeOutlined style={{ marginRight: 4 }} />}
        style={{ color: props.count > 0 ? "#1890ff" : "#000000a6" }}
        onClick={() => {
          handleLike(props);
        }}
      >
        {props.count}
      </Button>
    </>
  );
};
