import React, { useRef, useState } from "react";

import { useWindowSize } from "react-use";
import { DataGrid } from "../../../../../components/DataGrid";
import "../CSS/price.css";
import i18n from "i18next";
export default () => {
  const { width, height } = useWindowSize();
  const thisGridRef = useRef<any>();
  const columnsData = [
    {
      fieldShowName: i18n.t("价格表名称",),
      fieldName: "name",
    },
    {
      fieldShowName: i18n.t("客户名称",),
      fieldName: "customerId",
      dictionary: "clothes_customer",
      inputType:6,
    },
    {
      fieldShowName: i18n.t("品牌",),
      fieldName: "brandId",
      dictionary: "clothes_customer_brand",
      inputType:6,
    },
  ];
  const [columns] = useState<any[]>(columnsData);
  return (
    <div className="price-tabA-table">
      <DataGrid
        ref={thisGridRef}
        pagination={true}
        sorter={{ field: "createTime", order: "descend" }}
        pageSize={Math.floor((height - 280) / 38)}
        descriptions={columns}
        url={`/clothes/price/getAllPriceList`}
      />
    </div>
  );
};
