export const advisorIdDescription = {
  fieldShowName: "业务员",
  fieldName: "advisorId",
  dictionary: "base_staff",
};
export const creatorIdDescription = {
  fieldShowName: "创建人",
  fieldName: "creatorId",
  dictionary: "base_staff",
};
export const createTimeDescription = {
  fieldShowName: "创建时间",
  fieldName: "createTime",
};
