export function openWindowWithPostRequest(
  url: string,
  params: { [key: string]: any }
) {
  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", "_blank");
  for (let i in params) {
    if (params.hasOwnProperty(i)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = i;
      input.value = params[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
