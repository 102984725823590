import React, { ReactNode, useCallback, useRef, useState } from "react";
import "../CSS/tax.css";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
import { Result } from "../../../../../schemas/common";
import i18n from "i18next";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
export default () => {
  const { width, height } = useWindowSize();
  const [detailForm] = Form.useForm();
  const thisGridRef = useRef<any>();
  const [visiable, setVisiable] = useState(false);
  const hideModal = () => {
    setVisiable(false);
  };
  const detailOnOk = () => {
    detailForm.submit();
  };
    const detailElement: ReactNode = (
        <>
            <button
                type="button"
                className="ant-btn ant-btn-primary"
                onClick={detailOnOk}
            >
                <span>{i18n.t("确定")}</span>
            </button>
            <button type="button" className="ant-btn" onClick={hideModal}>
                <span>{i18n.t("cancelText")}</span>
            </button>
        </>
    );
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
        title: i18n.t("删除操作",),
      icon: <ExclamationCircleOutlined />,
        content: i18n.t("删除提示",),
        okText: i18n.t("确定",),
        cancelText: i18n.t("取消",),
      onOk: function () {
        const url = `/clothes/pc/workingProcedure/deletePcWorkingProcedureById?workingProcedureId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
              message.info(i18n.t("删除成功"));
            thisGridRef.current.refresh();
          } else {
              message.info(i18n.t("删除失败"));
          }
        });
      },
    });
  }, []);
  const getWorkingProcedureById = useCallback(function getWorkingProcedureById(
    uuid: string
  ) {
    axios
      .post<Result<any[]>>(
        `/clothes/pc/workingProcedure/getPcWorkingProcedureById`,
        {
          workingProcedureId: uuid,
        }
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          detailForm.setFieldsValue(resp.data.Data);
        }
      });
  },
  []);
  const onDetailFinish = (values: any) => {
    axios
      .post<Result<any[]>>(
        `/clothes/pc/workingProcedure/savePcWorkingProcedure`,
        {
          data: JSON.stringify(values),
        }
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          setVisiable(false);
          thisGridRef.current.refresh();
        }
      });
  };
    return (
        <div className="u-box u-basic-page">
            <div className="u-box-head">
        <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          {i18n.t("工艺管理")}
        </span>
                <div className="u-box-toolbar">
                    <div className="u-icon-list u-btn-list">
                        <Button
                            type="primary"
                            onClick={() => {
                                setVisiable(true);
                            }}
                            className="u-btn-add"
                        >
                            {i18n.t("新增")}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="u-box-con">
                <DataGrid
                    pageSize={Math.floor((height - 320) / 42)}
                    ref={thisGridRef}
                    url={"/clothes/pc/workingProcedure/getPcWorkingProcedureList"}
                    descriptions={[
                        {
                            fieldShowName: i18n.t("名称",),
                            fieldName: "name",
                        },
                        {
                            fieldShowName: i18n.t("操作",),
                            fieldName: "uuid",
                            isHiddenSearch: true,
                            render: (text: any, record: any) => {
                                return (
                                    <>
                                        <a
                                            href="#"
                                            className="deleteA"
                                            onClick={(e: any) => {
                                                getWorkingProcedureById(record.uuid);
                                                setVisiable(true);
                                            }}
                                        >
                                            {i18n.t("编辑")}
                                        </a>
                                        <a
                                            href="#"
                                            className="deleteA"
                                            style={{marginLeft: "10%"}}
                                            onClick={(e: any) => {
                                                confirm(record.uuid);
                                            }}
                                        >
                                            {i18n.t("删除")}
                                        </a>
                                    </>
                                );
                            },
                        },
                    ]}
                />
            </div>

            <Modal
                title={i18n.t("工艺")}
                className="add-component"
                visible={visiable}
                destroyOnClose={true}
                onCancel={hideModal}
                footer={detailElement}
                width={(width * 1) / 3}
                bodyStyle={{height: (height * 1) / 5}}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={detailForm}
                    layout="horizontal"
                    name="basicForm"
                    autoComplete="off"
                    preserve={false}
                    onFinish={onDetailFinish}
                >
                    <Form.Item name="uuid" hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("工艺名称")}
                        name="name"
                        rules={[
                            {
                                required: true,
                                validator: async (rule, value) => {
                                    if (value == undefined || value.trim() == "") {
                                        message.error(i18n.t("工艺名称不能为空"));
                                        throw new Error(i18n.t("工艺名称不能为空"));
                                    }
                                },
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
