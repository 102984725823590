import React, { useCallback, useEffect, useRef, useState } from "react";
import {Form, Input, Button, Space, message, Modal, Select} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import TextArea from "antd/es/input/TextArea";
import "../../CSS/customer.css";
import { DictionarySelects } from "../../../../../../components/DictionarySelectFilter";
import FileUpload from "../../../../../../components/FileUpload/FileUpload";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { useUpdateEffect } from "react-use";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../../components/EditForm";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import i18n from "i18next";
import GetRender from "../../util/Document";
import { Description } from "../../../../../../schemas/description";
import { isNullOrUndefined } from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useHistory } from "react-router";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons/lib";
import Price from "./Price";
import Size from "./Size";
import {usePermission} from "../../../../../../util/permession";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [brandId, setBrandId] = useState<any>("");
  const [isChange,setIsChange] = useState(false);
  const [isHidden,setIsHidden] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      setIsHidden(true);
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onCancel: function () {
          setIsHidden(false);
        },
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);
  useEffect(function () {
    axios
      .post<Result<any[]>>(`/clothes/customerManage/getBrandByCustomerId`, {
        customerId: customerId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          form.setFieldsValue(resp.data.Data);
          setBrandId(form.getFieldValue("uuid"));
        }
      });
  }, []);
  const getBrandByBrandId = useCallback(function getBrandByBrandId(
    brandId: string
  ) {
    axios
      .post<Result<any[]>>(`/clothes/customerManage/getBrandById`, {
        brandId: brandId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          form.resetFields();
          form.setFieldsValue(resp.data.Data);
          setBrandId(form.getFieldValue("uuid"));
        }
      });
  },
  []);
  const onFinish = async (values: any) => {
    await saveDataGrid(wuLiuDataGridRef);
    await saveDataGrid(fuLiaoDataGridRef);
    values.logisticsList = await wuLiuDataGridRef.current.getData();
    values.supplierList = await fuLiaoDataGridRef.current.getData();
    console.log("Finish:", values);
    axios
      .post<Result<any[]>>(`/clothes/customerManage/saveBrand`, {
        data: JSON.stringify(values),
        customerId: customerId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          setLoading(false);
          setIsChange(false);
        }
      });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  const [fuLiaoForm] = Form.useForm();
  const fuLiaoDataGridRef = useRef<any>(null);
  const [fuLiaoNamespace, setFuLiaoNamespace] = useState(
    "clothes_accessories_supplier.all"
  );
  const [fuLiaoEntityName, setFuLiaoEntityName] = useState(
    "clothes_accessories_supplier"
  );
  const [fuLiaoCurrentDescriptions, setFuLiaoCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  useEffect(function () {
    get(fuLiaoEntityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(fuLiaoNamespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setFuLiaoCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const [fuLiaoTableForm] = Form.useForm();

  function fuLiaoFormat() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(fuLiaoCurrentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
        fuLiaoDataGridRef,
        onDetailSave,
        fuLiaoEntityName
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const [wuLiuForm] = Form.useForm();
  const wuLiuDataGridRef = useRef<any>(null);
  const [wuLiuNamespace, setwuLiuNamespace] = useState(
    "clothes_logistics_information.all"
  );
  const [wuLiuEntityName, setwuLiuEntityName] = useState(
    "clothes_logistics_information"
  );
  const [wuLiuCurrentDescriptions, setwuLiuCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  useEffect(function () {
    get(wuLiuEntityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(wuLiuNamespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setwuLiuCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const [wuLiuTableForm] = Form.useForm();

  function wuLiuFormat() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(wuLiuCurrentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
        wuLiuDataGridRef,
        onDetailSave,
        wuLiuEntityName
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const onDetailSave = async () => {};

  const [messageInfo, setMessageInfo] = useState<any>();

  //复制相关
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    form.submit();
    var times = setInterval(() => {
      try {
        setIsModalVisible(true);
        clearInterval(times);
      } catch (e) {
        clearInterval(times);
      }
    }, 1000);
  };

  const handleOk = () => {
    //获取后台信息将隐藏字段全部替换成对应的字段
    axios
      .post<Result<any[]>>(`/clothes/customerManage/getBrandById`, {
        brandId: form.getFieldValue("uuid"),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let brand: any = resp.data.Data;
          if (brand != undefined) {
            form.setFieldsValue({
              sort: brand.sort,
              uuid: brand.uuid,
              name: brand.name,
            });
            fuLiaoDataGridRef.current.clearUuid();
            wuLiuDataGridRef.current.clearUuid();
          }
        }
      });
    //清空两个列表的uuid
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [priceVisiable, setPriceVisiable] = useState(false);
  const [sizeVisiable, setSizeVisiable] = useState(false);
  const priceHideModal = () => {
    setPriceVisiable(false);
  };
  const sizeHideModal = () => {
    setSizeVisiable(false);
  };
  const generateUUID = useCallback(function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
        c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }, [sizeVisiable]);

  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Price
              customerId={customerId}
              brandId={brandId}
              priceVisiable={priceVisiable}
              priceHideModal={priceHideModal}
              brandForm={form}
          />
          <Size
              customerId={customerId}
              brandId={brandId}
              sizeVisiable={sizeVisiable}
              sizeHideModal={sizeHideModal}
              brandForm={form}
              templateId={generateUUID()}
          />
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="customerId" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="sort" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="name" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <div className="u-ThreCon">
                <Form.Item label={i18n.t("品牌选择")} name="uuid">
                  <DictionarySelects
                      entityName={"clothes_customer_brand"}
                      filter={"customerId='" + customerId + "'"}
                      onChange={(value) => {
                        getBrandByBrandId(value);
                      }}
                  />
                </Form.Item>
                <Form.Item
                    className="crm-brand-price"
                    label={i18n.t("价格公式")}
                    name="formula"
                    {...{labelCol: {span: 8}, wrapperCol: {span: 12}}}
                >
                  <Select>
                    <Select.Option value="A">{i18n.t("公式A")}</Select.Option>
                    <Select.Option value="B">{i18n.t("公式B")}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="u-ThreCon">
                <Form.Item label={i18n.t("业务负责人")} name="staffId">
                  <DictionarySelect entityName={"base_staff"}/>
                </Form.Item>
                <Form.Item
                    className="crm-brand-price"
                    label={i18n.t("品牌价格表")}
                    name="pricelistId"
                    {...{labelCol: {span: 8}, wrapperCol: {span: 12}}}
                >
                  <DictionarySelects
                      entityName={"clothes_price"}
                      filter={" p.isCurrency = 1 OR p.customerId = '" + customerId + "' "}
                      onChange={(value:any)=>{
                        if(isNullOrUndefined(form.getFieldValue("formula")) || form.getFieldValue("formula") == ''){
                          message.info("请选择价格公式")
                          form.setFieldsValue({pricelistId:''})
                        }
                      }}
                  />
                </Form.Item>
                <div
                    className="crm-price-add"
                    style={{visibility: (priceVisiable || sizeVisiable || isModalVisible || isHidden) ? "collapse" : "unset"}}
                >
                  <PlusOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        setPriceVisiable(true);
                      }}
                  />
                </div>
                <Form.Item
                    className="crm-brand-price"
                    label={i18n.t("品牌尺码")}
                    name="skuSizeId"
                    {...{labelCol: {span: 8}, wrapperCol: {span: 12}}}
                >
                  <DictionarySelects
                      entityName={"clothes_size_template"}
                      filter={"(customerId IS NULL OR customerId = '" + customerId + "') AND isDelete != 1"}
                  />
                </Form.Item>
                <div
                    className="crm-price-add"
                    style={{visibility: sizeVisiable ? "collapse" : "unset"}}
                >
                  <PlusOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        setSizeVisiable(true);
                      }}
                  />
                </div>
                <Form.Item
                    className="remark"
                    label={i18n.t("辅料供应商")}
                    name="supplierList"
                    {...{labelCol: {span: 3}, wrapperCol: {span: 20}}}
                >
                  {isNullOrUndefined(messageInfo) ? (
                      ""
                  ) : (
                      <OnFinishFaileds values={messageInfo} form={fuLiaoForm}/>
                  )}
                  <DataGrid
                      ref={fuLiaoDataGridRef}
                      url={
                        "/clothes/customerManage/getSupplierByBrandId?brandId=" +
                        brandId
                      }
                      descriptions={fuLiaoFormat()}
                      pagination={true}
                      onClick={async (record: any) => {
                        await editOtherLine(fuLiaoDataGridRef, record);
                      }}
                      loadAllDictionary={true}
                      isShowSearch={false}
                      pageSize={4}
                      form={fuLiaoTableForm}
                  />
                  <div style={{paddingBottom: 25}}>
                    <Space>
                      <Button
                          style={{width: 100}}
                          onClick={async () => {
                            await addLine(fuLiaoDataGridRef);
                          }}
                      >
                        {i18n.t("add")}
                      </Button>
                    </Space>
                  </div>
                </Form.Item>
                <Form.Item
                    className="remark"
                    label={i18n.t("物流信息")}
                    name="logisticsList"
                    {...{labelCol: {span: 3}, wrapperCol: {span: 20}}}
                >
                  {isNullOrUndefined(messageInfo) ? (
                      ""
                  ) : (
                      <OnFinishFaileds values={messageInfo} form={wuLiuForm}/>
                  )}
                  <DataGrid
                      ref={wuLiuDataGridRef}
                      url={
                        "/clothes/customerManage/getLogisticsByBrandId?brandId=" +
                        brandId
                      }
                      descriptions={wuLiuFormat()}
                      pagination={false}
                      onClick={async (record: any) => {
                        await editOtherLine(wuLiuDataGridRef, record);
                      }}
                      loadAllDictionary={true}
                      isShowSearch={false}
                      form={wuLiuTableForm}
                  />
                  <div style={{paddingBottom: 25}}>
                    <Space>
                      <Button
                          style={{width: 100}}
                          onClick={async () => {
                            await addLine(wuLiuDataGridRef);
                          }}
                      >
                        {i18n.t("add")}
                      </Button>
                    </Space>
                  </div>
                </Form.Item>
              </div>
              <div className="u-ThreCon">
                <Form.Item
                    className="customer-attachmentId"
                    label={i18n.t("客户手册")}
                    name="attachmentId"
                >
                  <FileUpload/>
                </Form.Item>
              </div>
              <div className="u-ThreCon">
                <Form.Item
                    className="remark"
                    label={i18n.t("发货须知")}
                    name="shippingInstructions"
                    {...{labelCol: {span: 3}, wrapperCol: {span: 20}}}
                >
                  <TextArea rows={4}/>
                </Form.Item>
                <Form.Item
                    className="remark"
                    label={i18n.t("其他注意事项")}
                    name="precautions"
                    {...{labelCol: {span: 3}, wrapperCol: {span: 20}}}
                >
                  <TextArea rows={4}/>
                </Form.Item>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          showModal();
                        }}
                    >
                      {i18n.t("复制")}
                    </Button>}
                    {usePermission("新增修改客户") && <Button
                        htmlType="button"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                        className="u-form-buttonB"
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        loading={loading}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>

          <Modal
              title={i18n.t("复制")}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              maskClosable={false}
          >
            <Form.Provider>
              <Form
                  {...layout}
                  form={form}
                  layout="horizontal"
                  name="basicForm"
                  autoComplete="off"
              >
                <Form.Item label={i18n.t("品牌选择")} name="uuid">
                  <DictionarySelects
                      entityName={"clothes_customer_brand"}
                      filter={"customerId='" + customerId + "'"}
                  />
                </Form.Item>
              </Form>
            </Form.Provider>
          </Modal>
        </div>
      </div>
  );
};
