import React, {useEffect, useState} from 'react'
import { Table } from 'antd'
import {instance as axios} from "../../../../../util/axios";

const columns = [
		{ title: 'serial number', dataIndex: 'createTime', key: 'date' },
		{ title: 'Storage time', dataIndex: 'content', key: 'name' },
	]

interface Param {
	docType:string,
	docId:string
}
export default ({docId,docType}:Param) =>  {
	const [data, setData] = useState();
	// const data:any = []
	useEffect(()=>{
		axios
			.get("/inventory/changeRecord/getChangeRecord?docType="+docType+"&docId="+docId).then((resp)=>{
				if(resp.data.Status == 1){
					setData(resp.data.Data);
				}else{

				}
		})
	},[])
    return (
		<div className="invoice">
			<Table
				showHeader={false}
				columns={columns} 
				dataSource={data} 
				pagination={false} 
			/>
		</div>
    )
}