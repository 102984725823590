import React, { useContext } from "react";
import { message } from "antd";
import { PhoneTwoTone } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";

export interface DialingProps {
  mobile: string;
  uuid: string;
  advisorId: string;
  style?: React.CSSProperties;
}
export const Dialing = (props: DialingProps) => {
  const session: Session = useContext(SessionContext);
  return (
    <>
      <PhoneTwoTone
        style={props.style}
        onClick={(e) => {
          if (props.advisorId !== session.id) {
            message.error("只能给自己的客户拨打电话");
            return;
          }
          axios
            .post("/crm/customer/callOut", {
              customerId: props.uuid,
              mobile: props.mobile,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                if (resp.data.Data.indexOf("407") == -1) {
                  message.success("呼叫成功");
                } else {
                  message.error("当前坐席未登陆");
                }
              } else {
                message.error("您还未配置坐席，无法呼叫");
              }
            });
          e.stopPropagation();
        }}
      />
    </>
  );
};
