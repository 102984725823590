import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Modal,
  message,
  Tree,
  Drawer,
  Space,
  Switch,
  Select,
} from "antd";
import {
  CaretDownOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../../util/axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { CorrespondenceTreeWrapper } from "../../../../../../components/CorrespondenceTree";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import {createNewTab} from "../../../../../../util/tyy";
import storage from "../../util/Stroage";

interface Param {
  current: any;
}

const ColorList = function () {
  document.addEventListener("paste", function (e) {
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
  });
  const [parent, setParent] = useState("parent");
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  const descriptionsCopy = JSON.parse(
    JSON.stringify(currentDescriptions || [])
  );
  const generateUUID = useCallback(function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }, []);
  const [urldata, setUrldata] = useState("-1");
  const [edit, setEdit] = useState(" ");
  const history = useHistory();
  const thisGridRef = useRef<any>(null);
  const { height } = useWindowSize();
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title:  i18n.t("删除操作"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("删除提示"),
      okText: i18n.t("color_manage_determine"),
      cancelText: i18n.t("cancel"),
      onOk: function () {
        //删除操作
        const url = `/clothes/colorManager/deleteColorById?colorId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("deleteSuccess"));
            thisGridRef.current.refresh();
          } else {
            message.info(i18n.t("deleteError"));
          }
        });
      },
    });
  }, []);
  const thisRef: Param = useRef();
  thisRef.current = {
    urldata,
    edit,
  };
  useEffect(
    function () {
      thisGridRef.current.refresh();
    },
    [urldata]
  );
  const operationMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => {
          thisGridRef.current.export();
        }}
      >
        <UploadOutlined style={{ fontSize: "20px" }} />
        <span style={{ marginLeft: "5px" }}>{i18n.t("export")}</span>
      </Menu.Item>
    </Menu>
  );
  const handlerSwitch = (checked: boolean, record: any,e:Event) => {
    let sta = checked === true ? 1 : 0;
    axios // 保存操作
      .post("/clothes/colorManager/updateStatusById", {
        uuid: record.uuid,
        status: sta,
      })
      .then((resp) => {
        message.success("SUCCESS");
      })
      .catch((error) => {
        message.success("FAILD");
      });
    e.stopPropagation();
  };
  const columnsData = [

    {
      fieldName: "name",
      fieldShowName: i18n.t("颜色名称"),
      width: 200,
      align: "center",
    },
    {
      fieldName: "typeId",
      fieldShowName: i18n.t("颜色种类"),
      width: 100,
      inputType: 6,
      dictionary: "dict_sku_color_type",
      align: "center",
    },
    {
      fieldName: "name",
      fieldShowName: i18n.t("颜色"),
      width: 200,
      align: "center",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        let colorName = "black";
        //1:暗色
        if(record.nameColorId == "1"){
          colorName = "white";
        }
        if (record.ref.typeId == "2") {
          return (
                <div style={{ width: "100%"}}>
                    {
                        record.colors.map((color:any,index:any)=>{
                            return <div
                                style={{
                                    backgroundColor: color,
                                    float: "left",
                                    width: 100/record.colors.length+"%",
                                }}
                            >
                                <span style={{color:colorName}}>&nbsp;</span>
                            </div>
                        })
                    }
                    <div style={{position:"absolute",width:"100%",color:colorName}}>{record.name}</div>
                </div>
            )
        } else {
          return (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: record.rgb,
                  float: "left",
                  width: "100%",
                }}
              >
                <span style={{color:colorName}}>{record.name}</span>
              </div>
            </div>
          );
        }
      },
    },
    {
      fieldName: "status",
      fieldShowName: i18n.t("status"),
      width: 200,
      align: "center",
      isHiddenSearch: true,
      render: (fieldName: any, record: any) => {
        return (
          <Switch
            size="default"
            checkedChildren={i18n.t("color_manage_activated")}
            unCheckedChildren={i18n.t("color_manage_not_activated")}
            style={{ width: 65 }}
            defaultChecked={record.status == 0 ? false : true}
            onChange={(checked,e:any) => handlerSwitch(checked, record,e)}
          />
        );
      },
    },
    {
      fieldName: "categoryId",
      fieldShowName: i18n.t("所属分类",),
      width: 200,
      align: "center",
      inputType:6,
      dictionary: "color_sku_category",
    },
    {
      fieldShowName: i18n.t("material_operating"),
      fixed: "right",
      title: "option",
      width: 50,
      align: "center",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        return (
            <a
                href="#"
                className="deleteA"
                onClick={(e:any)=>{
                  confirm(record.uuid);
                  e.stopPropagation();
                }}
            >
                {i18n.t("delete")}
            </a>
        );
      },
    },
  ];

  const [columns] = useState<any[]>(columnsData);
  const getSpicralPriceList = useCallback(function getSpicralPriceList(
    //tab标签名
    tab: string,
    //标签的键,标识符
    key: string,
    //表示页面列表
    gridRef: React.MutableRefObject<any>
  ) {
    return (
      <div className="u-box u-basic-page">
        <div className="u-box u-basic-page">
          <div className="u-box-head">
            <span className="u-box-title" onClick={(e) => e.preventDefault()}>
              {i18n.t("color_manage_management")}
            </span>
            <div className="u-box-toolbar">
              <div className="u-icon-list u-btn-list">
                <Button
                    type="primary"
                    className="u-btnA"
                    onClick={() => {
                      createNewTab(
                          storage.get("reactUrl")+"/clothes/colorManager/list",
                          "颜色表管理"
                      );
                      // history.push("/clothes/colorManager/list");
                    }}
                >
                  {i18n.t("color_manage_card_home")}
                </Button>
              </div>
            </div>
            <div className="u-box-toolbar">
              <div className="u-icon-list u-btn-list">
                <Button
                  type="primary"
                  className="u-btnA"
                  onClick={() => {
                    createNewTab(
                        storage.get("reactUrl")+"/clothes/colorManager/detail/"+generateUUID()+"/add/false/undefined",
                        "颜色详情"
                    );
                    // history.push("/clothes/colorManager/detail/"+generateUUID()+"/add/false/undefined");
                  }}
                >
                  {i18n.t("新增")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="u-box-con">
          <DataGrid
            ref={thisGridRef}
            url={"/clothes/colorManager/getList"}
            rowSelectionType="checkbox"
            pagination={true}
            sorter={{ field: "createTime", order: "descend" }}
            pageSize={Math.floor((height - 280) / 42)}
            descriptions={columns}
            onClick={(record: any) => {
              // 跳转编辑页面
              createNewTab(
                  storage.get("reactUrl")+"/clothes/colorManager/detail/" +
                  record.uuid +
                  "/edit/false/"+record.categoryId,
                  "颜色详情"
              );
              /*history.push(
                  "/clothes/colorManager/detail/" +
                  record.uuid +
                  "/edit/false/"+record.categoryId
              );*/
            }}
            searchData={{ categoryId: thisRef.current.urldata }}
          />
        </div>
      </div>
    );
  }, []);
  return (
    <>
      {getSpicralPriceList(
        i18n.t("material_groupManagement"),
        "material_groupManagement",
        thisGridRef
      )}
    </>
  );
};
export default ColorList;
