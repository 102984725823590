import React, { CSSProperties, useEffect } from "react";
import "./index.css";

interface AutocompleteProps {
  style?: CSSProperties;
  onConfirm: (e: any) => void;
}

export default function ({ style, onConfirm }: AutocompleteProps) {
  useEffect(() => {
    // @ts-ignore
    const ac = new BMap.Autocomplete({
      input: "suggestId",
    });

    ac.addEventListener("onconfirm", (e: any) => {
      onConfirm(e);
    });
  }, [onConfirm]);

  return <input style={style} id={"suggestId"} />;
}
