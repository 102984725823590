import React, { useEffect, useRef, useState } from "react";
import { Button, message, Modal, Space } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { Description } from "../../../../../../schemas/description";
import { get } from "../../../../../../util/description";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { UserSelect } from "../../../../../../components/UserSelect";
import { Result } from "../../../../../../schemas/common";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Detail } from "../Detail";
import { getConfigByName } from "../../../../../../util/config";
import { ConfigName } from "../../../../../../enum";

const { confirm } = Modal;
export interface DynamicTabProps {
  uuid: string;
  claimable: boolean;
  assignable: boolean;
}
export const TabContent = ({
  uuid,
  claimable,
  assignable,
}: DynamicTabProps) => {
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const { height } = useWindowSize();
  const gridRef = useRef<any>();
  const [descriptions, setDescriptions] = useState<Description[]>([]);

  useEffect(
    function () {
      get("crm_customer").then((columns: Description[]) => {
        let copy = JSON.parse(JSON.stringify(columns));
        getConfigByName(ConfigName.GonghaiMobileVisible).then((config) => {
          if (config && config.value === "否") {
            copy = copy.filter(
              (item: Description) => item.fieldName !== "mobile"
            );
          }
          getConfigByName(ConfigName.GonghaiContactsVisible).then(
            (contactResp) => {
              if (contactResp && contactResp.value === "否") {
                copy = copy.filter(
                  (item: Description) => item.fieldName !== "contact"
                );
              }
              copy.push({
                fieldShowName: "前业务员",
                fieldName: "previousAdvisorId",
                dictionary: "base_staff",
              });
              copy.push({
                fieldShowName: "进入公海时间",
                fieldName: "giveupTime",
              });
              setDescriptions(copy);
            }
          );
        });
      });
    },
    [uuid]
  );
  return (
    <>
      {descriptions.length > 0 && (
        <div key={uuid}>
          <Space style={{ marginBottom: 10 }}>
            {assignable && (
              <Button
                onClick={() => {
                  const selectedRows: any[] = gridRef.current.getSelectedRows();
                  if (selectedRows.length === 0) {
                    message.error("请选择要分配的客户");
                    return;
                  }
                  setUserSelectVisible(true);
                }}
              >
                重新分配
              </Button>
            )}
            {claimable && (
              <Button
                onClick={() => {
                  const selectedRows: any[] = gridRef.current.getSelectedRows();
                  const selectedIds = gridRef.current.getSelectedIds();
                  if (selectedRows.length === 0) {
                    message.error("请选择要提取的客户");
                    return;
                  }
                  confirm({
                    title: "提示",
                    icon: <ExclamationCircleOutlined />,
                    okText: "确定",
                    cancelText: "取消",
                    content: `确定要提取客户吗`,
                    onOk() {
                      axios
                        .post<
                          Result<{
                            isCreatable: boolean;
                            isCreatableNumber: number;
                          }>
                        >("/crm/customer/distributable", {
                          customerNumberToAdd: selectedIds.length,
                        })
                        .then((resp) => {
                          if (resp.data.Status === "1") {
                            if (!resp.data.Data.isCreatable) {
                              message.error(
                                `您的客户数超额，您最多能提取${resp.data.Data.isCreatableNumber}个客户！`
                              );
                              return;
                            }
                            axios
                              .post<Result<void>>(
                                "/crm/customer/gongHai/extractGonghai",
                                { ids: selectedIds.join(",") }
                              )
                              .then((resp) => {
                                if (resp.data.Status === "1") {
                                  message.success("操作成功");
                                  gridRef.current.refresh();
                                } else {
                                  message.error("删除失败");
                                }
                              });
                          }
                        });
                    },
                  });
                }}
              >
                提取
              </Button>
            )}
            <Button
              onClick={() => {
                const ids = gridRef.current.getSelectedIds().join(",");
                if (ids === "") {
                  message.error("请选择您要删除的客户");
                  return;
                }
                confirm({
                  title: "提示",
                  icon: <ExclamationCircleOutlined />,
                  okText: "确定",
                  cancelText: "取消",
                  content: `您确定要删除吗?`,
                  onOk() {
                    axios
                      .post<Result<string>>("/crm/customer/deleteCustomer", {
                        ids: ids,
                        deleteSource: "gonghai",
                      })
                      .then((resp) => {
                        if (resp.data.Status === "1") {
                          message.success(resp.data.Data);
                          gridRef.current.refresh();
                        } else {
                          message.error(resp.data.Data);
                        }
                      });
                  },
                });
              }}
            >
              删除
            </Button>
          </Space>
          <DataGrid
            onDraw={(record) => (
              <Detail
                key={record.key}
                customerId={record.key}
                customerType="gonghai"
              />
            )}
            ref={gridRef}
            drawerWidth={1000}
            style={{ marginBottom: 8 }}
            pageSize={Math.floor((height - 280) / 42)}
            descriptions={descriptions}
            rowSelectionType="checkbox"
            sorter={{ field: "lastUpdateDateTime", order: "descend" }}
            url={`/crm/customer/gongHai/getGongHaiCustomerByLibraryId?libraryId=${uuid}`}
          />
        </div>
      )}
      <UserSelect
        visible={userSelectVisible}
        multiple={false}
        defaultSelectedUsers={[]}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
        onOk={(selectedUsers: any[]) => {
          const selectedIds = gridRef.current.getSelectedIds();
          axios
            .post<Result<{ isCreatable: boolean; isCreatableNumber: number }>>(
              "/crm/customer/distributable",
              {
                customerNumberToAdd: selectedIds.length,
                userId: selectedUsers[0].id,
              }
            )
            .then((resp) => {
              if (resp.data.Status === "1") {
                if (!resp.data.Data.isCreatable) {
                  message.error(
                    `您选择的业务员的客户数超额，您最多为其分配${resp.data.Data.isCreatableNumber}个客户`
                  );
                  return;
                }
                axios
                  .post<Result<void>>("/crm/customer/assign", {
                    customerIds: selectedIds.join(","),
                    toAdvisorId: selectedUsers[0].id,
                  })
                  .then((res) => {
                    if (res.data.Status === "1") {
                      message.success("分配成功");
                      gridRef.current.refresh();
                    } else {
                      message.error(`分配失败:${res.data.Message}`);
                    }
                  });
              }
            });
          setUserSelectVisible(false);
        }}
      />
    </>
  );
};
