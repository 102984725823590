import React, { useCallback, useEffect, useState } from "react";
import { List, message, Spin } from "antd";
import {
  ClockCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib";
import EditCustomerAddress from "./EditCustomerAddress";
import { instance as axios } from "../../../../../util/axios";
import i18n from "i18next";
import {isEmpty} from "../util/Common";

interface Param {
  customerId: string;
  onSelected: (address: any) => void;
  addressType: 0 | 1;
}

const CustomerAddress = function ({
  customerId,
  onSelected,
  addressType,
}: Param) {
  const [list, setList] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerAddress, setCustomerAddress] = useState({});
  const [mark, setMark] = useState<string>("");

  const formatListItems = useCallback(
    (list: any) => {
      list.map((item: any) => {
          item.name =
              isEmpty(item.receivingCompany)?"":item.receivingCompany +
              "\n" +
              (item.street == undefined ? "" : item.street) +
              " " +
              (item.addressee == undefined ? "" : item.addressee) +
              "\n" +
              (item.postcode == undefined ? "" : item.postcode) +
              " " +
              (item.city == undefined ? "" : item.city) +
              "\n" +
              (item.country == undefined ? "" : item.country);
      });
      return list;
    },
    [list]
  );

  async function getData() {
    setLoading(true);
    axios
      .post(
        "/clothes/customerManage/getShippingAddressByCustomerId?customerId=" +
          customerId
      )
      .then((resp) => {
        if (resp.data.Status == 1) {
          setList(resp.data.Data.data);
        }
      })
      .catch(() => {
        message.error(i18n.t("public_getDataError"));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(
    function () {
      getData();
    },
    [customerId, addressType]
  );
  return (
    <Spin spinning={loading}>
      <div className="u-editlist">
        <List
          dataSource={formatListItems(list)}
          renderItem={(item: any) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <div
                    onClick={() => {
                      onSelected(item.name);
                    }}
                  >
                      <>
                          {item.receivingCompany}
                          <br/>
                          {item.street}
                          {" "}
                          {item.addressee}
                          <br/>
                          {item.postcode}
                          {" "}
                          {item.city}
                          <br/>
                          {item.country}
                          <br/>
                      </>
                  </div>
                }
              />
              {/*<EditOutlined*/}
              {/*  onClick={() => {*/}
              {/*    setVisible(true);*/}
              {/*    setCustomerAddress(item);*/}
              {/*  }}*/}
              {/*/>*/}
            </List.Item>
          )}
        />
        {/*<div className="u-bar-actions">
          <span
            className="u-add-actions"
            onClick={() => {
              if (customerId == "" || customerId == undefined) {
                  message.info(i18n.t("public_pleaceSelectCustomer"));
              } else {
                setVisible(true);
                var emptyItem = {};
                setCustomerAddress(emptyItem);
                setCustomerAddress({
                  uuid: "",
                  addressType: addressType,
                  customerId: customerId,
                });
              }
            }}
          >
            <PlusCircleOutlined />{i18n.t("public_addNewAddress")}
          </span>
          <span
            className="u-add-actions"
            style={{float:"right"}}
            onClick={() => {
              getData();
            }}
          >
            <SyncOutlined /> {i18n.t("public_refresh")}
          </span>
        </div>

        <EditCustomerAddress
          visible={visible}
          onCreate={(item: any) => {
            setVisible(false);
            setList([]);
            getData();
          }}
          onCancel={() => {
            setVisible(false);
          }}
          data={customerAddress}
        />*/}
      </div>
    </Spin>
  );
};

export default CustomerAddress;
