import React, {useEffect, useRef, useState} from "react";
import {
    CaretDownOutlined,
    CaretUpOutlined,
} from "@ant-design/icons/lib";
import {Card, Col, Row, Statistic, Radio, Table, Progress, DatePicker, message, Spin, Form} from "antd";
import * as echarts from 'echarts';
import {instance as axios} from "../../../../../util/axios";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import i18n from "i18next";

const { RangePicker } = DatePicker;


export default ()=>{

    const [customerCount, setCustomerCount] = useState({today:"0",rate:"0",grow:"up"});
    const [skuCount, setSkuCount] = useState({today:"0",rate:"0",grow:"up"});
    const [purCount, setPurCount] = useState({today:"0",rate:"0",grow:"up"});
    const [proCount, setProCount] = useState({today:"0",rate:"0",grow:"up"});
    const [orderCount, setOrderCount] = useState({today:"0",rate:"0",grow:"up"});
    const [loading, setLoading] = useState(true);
    const [saleBoardLoading, setSaleBoardLoading] = useState(false);
    const [dataSource, setDataSource] = useState();
    const [addType, setAddType] = useState("clothes_customer");
    const [moreUrl, setMoreUrl] = useState("/clothes/CRM/4");
    const [saleBoardType, setSaleBoardType] = useState("1");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    // option && myChart.setOption(option);
    useEffect(()=>{
        axios
            .get("/clothes/statistic/getNewDataToday")
            .then((resp) => {
                if (resp.data.Status === "1") {
                    var data = resp.data.Data;
                    data.map((item:any)=>{
                        if(item.tableName == "clothes_customer"){
                            setCustomerCount(item);
                        }
                        if(item.tableName == "clothes_sku"){
                            setSkuCount(item);
                        }
                        if(item.tableName == "clothes_order_pur"){
                            setPurCount(item);
                        }
                        if(item.tableName == "clothes_produce_order"){
                            setProCount(item);
                        }
                        if(item.tableName == "clothes_order"){
                            setOrderCount(item);
                        }
                    })
                    setLoading(false)
                }
            });
    },[])

    useEffect(()=>{
        let element = document.getElementById('main2');
        let myChart = echarts.init(element as HTMLDivElement);


        const option2 = {
            title:{
                text: i18n.t("近一周销售额概览",)
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
        };
        myChart.setOption(option2);

        axios
            .post("/clothes/statistic/getSkuAmount",{startDate:startDate,endDate:endDate}).then((resp:any)=>{
            if(resp.data.Status == 1){
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: resp.data.Data.date
                    },
                    series: [
                        {
                            data: resp.data.Data.data,
                            type: 'line',
                            smooth: true
                        }
                    ]
                })
            }
        })

    },[startDate,endDate])

    useEffect(()=>{
        let element = document.getElementById('chart1');
        let myChart = echarts.init(element as HTMLDivElement);


        const option2 = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
        };
        myChart.setOption(option2);
        myChart.resize({height:300})
        myChart.showLoading()

        axios
            .get("/clothes/statistic/getAddCount?tableName="+addType).then((resp)=>{
            myChart.hideLoading()
            if(resp.data.Status != 1){
                return;
            }
            myChart.setOption({
                series: [
                    {
                        name: i18n.t('基础',),
                        type: 'bar',
                        stack: 'Ad',
                        emphasis: {
                            focus: 'series'
                        },
                        color:"#4ECA70",
                        data: resp.data.Data.base
                    },
                    {
                        name: i18n.t('新增',),
                        type: 'bar',
                        stack: 'Ad',
                        emphasis: {
                            focus: 'series'
                        },
                        color:"#38A0FF",
                        data: resp.data.Data.add
                    }
                ]
            })

        })
    },[addType])
    useEffect(()=>{
        setSaleBoardLoading(true)
        axios
            .get("/clothes/statistic/getSkuBoard?type="+saleBoardType).then((resp:any)=>{
            setSaleBoardLoading(false)
                if(resp.data.Status == 1){
                    setDataSource(resp.data.Data);
                }
        })
    },[saleBoardType])

    return (
        <div className="site-statistic-demo-card" id="main">
            <Spin spinning={loading}>
                <Row gutter={[24, 8]}>
                    <Col span={5}>
                        <Card>
                            <Row>
                                <Col span={8}>
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="4073" width="80" height="80">
                                        <path
                                            d="M512 928C282.8 928 97.1 742.2 97.1 513.1S282.8 98.2 512 98.2 926.9 284 926.9 513.1 741.2 928 512 928z m217.3-177.9c10.9 0 19.8-7.6 19.8-17V614.9C749.1 547 591.1 513 512 513s-237.1 34-237.1 101.9v118.3c0 9.3 8.8 17 19.7 17l434.7-0.1zM512 453.8c65.5 1.4 119.6-50.6 121-116.1 1.4-65.5-50.6-119.6-116.1-121h-5c-65.5 1.4-117.4 55.6-116.1 121 1.5 63.6 52.7 114.8 116.2 116.1z"
                                            p-id="4074" data-spm-anchor-id="a313x.7781069.0.i3" className="selected"
                                            fill="#1296db"></path>
                                    </svg>
                                </Col>
                                <Col span={16}>
                                    <Row>
                                        <Statistic title={i18n.t("今日新增客户")} value={customerCount.today}/>
                                        <Col span={24}>
                                        <span style={{color: customerCount.grow == "up" ? "green" : "red"}}>
                                            {customerCount.grow == "up" ? <CaretUpOutlined/> :
                                                <CaretDownOutlined/>} {customerCount.rate}% {i18n.t("同比昨日")}
                                        </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card>
                            <Row>
                                <Col span={8}>
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="18106" width="80px" height="80px">
                                        <path d="M623.655498 572.98291" p-id="18107" fill="#98D87D"></path>
                                        <path d="M661.586388 750.167355" p-id="18108" fill="#98D87D"></path>
                                        <path
                                            d="M511.997442 65.85487C265.572006 65.85487 65.853335 265.633916 65.853335 512S265.572006 958.14513 511.997442 958.14513c246.307755 0 446.147176-199.779046 446.147176-446.144106S758.305197 65.85487 511.997442 65.85487zM378.175597 706.844663c0 11.504008-9.04091 20.839631-20.207227 20.839631l-55.686277 0c-11.168364 0-20.207227-9.335622-20.207227-20.839631L282.074865 486.055095c0-11.507078 9.038864-20.843724 20.207227-20.843724l55.686277 0c11.168364 0 20.207227 9.337669 20.207227 20.843724L378.175597 706.844663zM400.743592 727.625966c-0.030699 0-0.079818-0.002047-0.148379-0.008186-0.061398 0.00614-0.107447 0.008186-0.1361 0.008186-0.037862 0-0.042979-0.00614 0-0.021489-0.058328-0.013303-0.098237-0.023536-0.102331-0.027629l0.48607 0c-0.004093 0.004093-0.048095 0.01535-0.100284 0.027629C400.788617 727.619826 400.785547 727.625966 400.743592 727.625966zM741.778802 506.241856c-1.01512 10.603499-40.764426 189.30653-41.913599 191.045127-4.134157 8.654101-10.478655 16.309454-18.871813 21.908986-8.800433 5.832845-14.262842 8.366551-23.924899 8.366551l-0.163729 0-256.06191 0.010233c0.007163-0.007163-0.053212-0.010233-0.245593-0.010233-0.186242 0-0.248663 0.00307-0.237407 0.010233l-5.166673 0 0.405229-263.345804c52.891627-6.211469 97.351212-51.493791 98.859565-107.820658-0.1361-1.626034 0-3.248997 0-4.947685 0-30.121018 19.238157-55.144948 48.095391-55.144948 24.792663 0 45.511543 18.050098 50.857295 42.253336l0.255827-0.027629c2.947122 13.809517 4.552689 28.149107 4.552689 42.889833 0 22.716374-1.873674 46.89403-8.665357 67.179029 0 0 102.555747 0.431835 103.440907 0.569982 17.096377 0.724501 31.231305 7.322779 40.957831 23.660886C740.118999 483.234863 742.571864 494.930229 741.778802 506.241856z"
                                            p-id="18109" fill="#98D87D"></path>
                                    </svg>
                                </Col>
                                <Col span={16}>
                                    <Row>
                                        <Statistic title={i18n.t("今日新增产品数")} value={skuCount.today}/>
                                        <Col span={24}>
                                        <span style={{color: skuCount.grow == "up" ? "green" : "red"}}>
                                            {skuCount.grow == "up" ? <CaretUpOutlined/> :
                                                <CaretDownOutlined/>} {skuCount.rate}% {i18n.t("同比昨日")}
                                        </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card>
                            <Row>
                                <Col span={8}>
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="53759" width="80" height="80">
                                        <path
                                            d="M575.977 477.761c10.947-7.662 26.15-13.622 42.206-19.825 45.003-17.758 79.545-34.057 79.545-70.302V270.626H326.515v117.008c0 36.367 34.421 52.787 79.546 70.302 16.055 6.081 31.258 12.041 42.205 19.704 24.813 17.271 24.69 42.813 24.448 45.732 0 1.46 0.365 27.002-24.448 44.152-11.068 7.663-26.15 13.38-42.205 19.704-45.125 17.515-79.79 33.935-79.79 70.302v116.035h371.58V657.773c0-36.367-34.544-52.787-79.79-70.302-15.933-6.325-31.137-12.041-42.084-19.704-24.812-17.393-24.69-42.813-24.447-45.733 0-1.58-0.365-27.001 24.447-44.273z m-103.02-45.124c-16.907-10.947-35.394-17.88-55.585-25.178-8.392-2.919-22.38-6.324-33.083-10.703-9.122-3.649-15.082-12.893-15.082-22.866v-30.286c0-7.906 6.325-14.23 14.23-14.353h259.801c7.906 0 14.231 6.447 14.231 14.231v30.164c0 10.096-5.96 19.34-15.447 22.988-11.068 4.5-25.542 7.785-33.935 10.947-20.068 7.42-39.164 14.23-56.07 25.177-18.853 12.042-60.086 12.042-79.06-0.121z"
                                            fill="#FFD86E" p-id="53760"></path>
                                        <path
                                            d="M512 25.542c-274.275 0-496.492 222.218-496.492 496.37 0 274.154 222.217 496.493 496.492 496.493 274.153 0 496.492-222.217 496.492-496.492C1008.371 247.76 786.153 25.543 512 25.543z m88.303 511.818c8.27 5.473 22.866 10.703 38.07 16.177 45.976 16.663 109.224 39.772 109.224 103.871V773.2h13.379c16.055 0 29.07 13.014 29.07 29.07s-13.015 29.069-29.07 29.069H263.267c-16.176 0-29.312-12.893-29.312-29.07s13.257-29.313 29.312-29.313h13.38V657.165c0-64.342 63.004-87.208 109.223-103.872 15.204-5.595 29.678-10.703 38.07-16.176 9.487-6.082 9.974-13.866 9.974-14.11 0-1.702-0.487-9.487-9.974-15.568-8.392-5.473-22.866-10.582-38.192-16.177-46.219-16.906-109.223-39.773-109.223-103.872V270.383h-13.38c-16.176 0-29.312-13.015-29.312-29.313 0-16.055 13.258-29.07 29.313-29.07h498.073c16.055 0 29.07 13.015 29.07 29.07 0 16.177-13.015 29.313-29.07 29.313h-13.38V387.39c0 64.343-63.247 87.209-109.223 103.872-15.447 5.595-29.799 10.704-38.191 16.177-9.487 6.081-9.974 13.866-9.974 14.717 0 1.46 0.487 9 9.852 15.204z"
                                            fill="#FFD86E" p-id="53761"></path>
                                    </svg>
                                </Col>
                                <Col span={16}>
                                    <Row>
                                        <Statistic title={i18n.t("今日新增采购单")} value={purCount.today}/>
                                        <Col span={24}>
                                        <span style={{color: purCount.grow == "up" ? "green" : "red"}}>
                                            {purCount.grow == "up" ? <CaretUpOutlined/> :
                                                <CaretDownOutlined/>} {purCount.rate}% {i18n.t("同比昨日")}
                                        </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card>
                            <Row>
                                <Col span={10}>
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="18548"
                                         data-spm-anchor-id="a313x.7781069.0.i19" width="80" height="80">
                                        <path
                                            d="M512 11.377778c276.48 0 500.622222 224.142222 500.622222 500.622222S788.48 1012.622222 512 1012.622222 11.377778 788.48 11.377778 512 235.52 11.377778 512 11.377778zM377.4464 616.709689H331.207111V350.776889h366.580622v265.944178H458.103467c-6.030222 0-11.696356 2.844444-15.303111 7.657244l-79.780978 106.564267 30.606222 22.914844 74.046578-98.907022h249.230222a19.114667 19.114667 0 0 0 19.114667-19.114667V331.662222a19.114667 19.114667 0 0 0-19.114667-19.114666h-404.821333a19.114667 19.114667 0 0 0-19.114667 19.114666v304.173511c0 10.558578 8.567467 19.114667 19.114667 19.114667h38.877866z m65.911467-194.525867v37.239467h139.423289v-37.239467z m0 88.348445v37.239466h139.423289v-37.239466z"
                                            p-id="18549" data-spm-anchor-id="a313x.7781069.0.i15" className=""
                                            fill="#00BFFF"></path>
                                    </svg>
                                </Col>
                                <Col span={14}>
                                    <Row>
                                        <Statistic title={i18n.t("今日新增销售订单")} value={orderCount.today}/>
                                        <Col span={24}>
                                        <span style={{color: orderCount.grow == "up" ? "green" : "red"}}>
                                            {orderCount.grow == "up" ? <CaretUpOutlined/> :
                                                <CaretDownOutlined/>} {orderCount.rate}% {i18n.t("同比昨日")}
                                        </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card>
                            <Row>
                                <Col span={8}>
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="4378" width="80" height="80">
                                        <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                                              fill="#F3857C"
                                              p-id="4379" data-spm-anchor-id="a313x.7781069.0.i2" className=""></path>
                                        <path
                                            d="M204.8 339.968l296.96 137.216L798.72 339.968 501.76 225.28 204.8 339.968zM526.336 512v296.96l274.432-126.976V385.024L526.336 512zM204.8 385.024v296.96l274.432 126.976V512L204.8 385.024z m251.904 210.944l-229.376-108.544v-45.056l229.376 108.544v45.056z"
                                            fill="#ffffff" p-id="4380" data-spm-anchor-id="a313x.7781069.0.i3"
                                            className=""></path>
                                    </svg>
                                </Col>
                                <Col span={16}>
                                    <Row>
                                        <Statistic title={i18n.t("今日新增生产订单")} value={proCount.today}/>
                                        <Col span={24}>
                                       <span style={{color: proCount.grow == "up" ? "green" : "red"}}>
                                            {proCount.grow == "up" ? <CaretUpOutlined/> :
                                                <CaretDownOutlined/>} {proCount.rate}% {i18n.t("同比昨日")}
                                        </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>


                </Row>
            </Spin>
            <Row>
                <Col span={14}>
                    <Card title={i18n.t("新增数据统计")} extra={<a href={moreUrl}>{i18n.t("更多")}</a>}
                          bodyStyle={{height: "400px"}}>
                        <Radio.Group defaultValue={"clothes_customer@/clothes/CRM/4"} onChange={(e: any) => {
                            let value = e.target.value;
                            console.log(value)
                            setAddType(value.split("@")[0])
                            setMoreUrl(value.split("@")[1])
                        }}>
                            <Radio.Button value="clothes_customer@/clothes/CRM/4">{i18n.t("客户")}</Radio.Button>
                            <Radio.Button value="clothes_sku@/sku/skuManage/0">{i18n.t("产品")}</Radio.Button>
                            <Radio.Button value="clothes_order_pur@/clothes/orderPur">{i18n.t("采购单")}</Radio.Button>
                            <Radio.Button
                                value="clothes_produce_order@/clothes/produceOrder">{i18n.t("生产订单")}</Radio.Button>
                            <Radio.Button value="clothes_order@/clothes/order">{i18n.t("订单")}</Radio.Button>
                        </Radio.Group>
                        <div id={"chart1"} style={{height: "400px", width: "100%"}}/>
                    </Card>
                </Col>
                <Col span={10}>
                    <Card title={i18n.t("产品销量排行榜")} extra={<a href="/clothes/order">{i18n.t("更多")}</a>}
                          bodyStyle={{height: "400px"}}>
                        <Radio.Group defaultValue={"1"} onChange={(e: any) => {
                            setSaleBoardType(e.target.value)
                        }}>
                            <Radio.Button value="1">{i18n.t("本周")}</Radio.Button>
                            <Radio.Button value="2">{i18n.t("本月")}</Radio.Button>
                            <Radio.Button value="3">{i18n.t("本年")}</Radio.Button>
                        </Radio.Group>
                        <Table loading={saleBoardLoading} pagination={false} columns={[
                            {
                                title: i18n.t('排名',),
                                dataIndex: 'index',
                                key: 'index',
                                render: text => <a style={{fontSize: 25}}>{text}</a>,
                            },
                            {
                                title: i18n.t('商品编码',),
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: i18n.t('占比',),
                                dataIndex: 'rate',
                                key: 'rate',
                                render: (value) => (
                                    <>
                                        <Progress percent={value}/>
                                    </>
                                ),
                            },
                            {
                                title: i18n.t('环比',),
                                key: 'addRate',
                                dataIndex: 'addRate',
                                render: (value, record) => (
                                    <div style={{color: record.grow == "up" ? "green" : "red"}}>
                                        {value}% {record.grow == "up" ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </div>
                                ),
                            }
                        ]} dataSource={dataSource}/>
                    </Card>
                </Col>
            </Row>
            <Card title={i18n.t("销售额概览")}>
                <Form.Item name={"range"}>
                    <DateTimePickerWithFormat onChange={(ranges) => {
                        var startDateStr = ranges[0].format("YYYY-MM-DD");
                        var endDateStr = ranges[1].format("YYYY-MM-DD");
                        setStartDate(startDateStr)
                        setEndDate(endDateStr)

                    }} rangePicker={true}/>
                </Form.Item>
                {/*<ReactECharts option={option2} style={{height:"500px"}} />*/}
                <div id={"main2"} style={{width: "100%", height: "500px"}}/>
            </Card>
        </div>
    );

}