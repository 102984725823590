import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    Col,
    Row,
    Statistic,
    Radio,
    Table,
    Progress,
    DatePicker,
    message,
    Spin,
    Form,
    Divider,
    Button
} from "antd";
import * as echarts from 'echarts';
import {instance as axios} from "../../../../../util/axios";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import {isEmpty, isNullOrUndefined} from "../util/Common";
import {DictionarySelects} from "../../../../../components/DictionarySelectFilter";
import i18n from "i18next";
const { RangePicker } = DatePicker;


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState();
    const [gridSearchData, setGridSearchData] = useState();

    useEffect(()=>{
        let element = document.getElementById('main1');
        let myChart = echarts.init(element as HTMLDivElement);


        var option2 = {
            title:{
                left: 'center',
                top: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}:{d} %',
            },
            legend: {
                bottom: '5%',
                left: 'center'
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        show: true
                    },
                    label: {
                        formatter: '{b}:{d} %',
                    }
                }
            ]
        };
        myChart.showLoading()
        myChart.setOption(option2);
        let data: any =  Object.assign(searchData==undefined?{}:searchData,gridSearchData==undefined?{}:gridSearchData);

        axios
            .post("/clothes/statistic/getOrderAmount",data).then((resp:any)=>{
                myChart.hideLoading()
            if(resp.data.Status == 1){
                var data = resp.data.Data
                var count = 0
                if(data && data.length > 0){
                    count = data[0].counts;
                }
                myChart.setOption({
                    title:{
                        text: i18n.t("产品数量合计")+": \n"+ count
                    },
                    series: [
                        {
                            data: resp.data.Data
                        }
                    ]
                })
            }
        })

    },[searchData,gridSearchData])
    useEffect(()=>{
        let element = document.getElementById('main2');
        let myChart = echarts.init(element as HTMLDivElement);


        var option2 = {
            title:{
                left: 'center',
                top: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}:{d} %',
            },
            legend: {
                bottom: '5%',
                left: 'center'
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        show: true
                    },
                    label: {
                        formatter: '{b}:{d} %',
                    }
                }
            ]
        };
        myChart.showLoading()
        myChart.setOption(option2);

        let data: any =  Object.assign(searchData==undefined?{}:searchData,gridSearchData==undefined?{}:gridSearchData);
        axios
            .post("/clothes/statistic/getOrderPriceAmount",data).then((resp:any)=>{
                myChart.hideLoading()
            if(resp.data.Status == 1){
                var data = resp.data.Data
                var count = 0
                if(data && data.length > 0){
                    count = data[0].counts;
                }
                myChart.setOption({
                    title:{
                        text:i18n.t("产品价格合计")+": \n"+ count
                    },
                    series: [
                        {
                            data: resp.data.Data
                        }
                    ]
                })
            }
        })

    },[searchData,gridSearchData])


    return (
        <div>
            <Row gutter={[24, 8]}>
                <Form form={form}>
                    <Row>
                        <Form.Item name={"type"}>
                            <DictionarySelects filter={" uuid != '-1' "} entityName={"dict_sku_property"}
                                               style={{width: 400}}/>
                        </Form.Item>
                        <Form.Item name={"range"}>

                            <DateTimePickerWithFormat rangePicker={true}/>
                        </Form.Item>
                        <Form.Item name={"status"}>

                            <DictionarySelect entityName={"dict_order_status"} style={{width: 100}}/>
                        </Form.Item>
                    </Row>
                </Form>
                <Button onClick={() => {
                    var type = form.getFieldValue("type")
                    var ranges: any = form.getFieldValue("range")
                    var status: any = form.getFieldValue("status")
                    const temp: any = {};
                    temp.type = isEmpty(type) ? "" : type;
                    temp.status = isEmpty(status) ? "" : status;
                    if (ranges) {
                        temp.startDate = ranges[0].format("YYYY-MM-DD");
                        temp.endDate = ranges[1].format("YYYY-MM-DD");
                        setSearchData(temp)
                    } else {
                        setSearchData(temp)
                    }
                }}>{i18n.t("搜索")}</Button>
                <Button onClick={() => {
                    form.setFieldsValue({type: undefined, range: undefined})

                }}>{i18n.t("重置")}</Button>
            </Row>

            <Row>
                <Col span={12}>
                    <div id={"main1"} style={{height: "400px"}}/>
                </Col>
                <Col span={12}>
                    <div id={"main2"} style={{height: "400px"}}/>
                </Col>
            </Row>
            <Divider/>

            <DataGrid isShowSearch={true} url={"/clothes/statistic/getOrderList"} searchData={searchData}

                      onSearch={(data:any)=>{
                          setGridSearchData(data)
                      }}

                      descriptions={[
                          {
                              fieldName: "docDate",
                              fieldShowName: i18n.t("时间",)
                          }, {
                              fieldName: "docType",
                              fieldShowName: i18n.t("订单种类",),
                              inputType: 6,
                              dictionary: "dict_doc_type"
                          },
                          {
                              fieldName: "serialNumber",
                              fieldShowName: i18n.t("文件号码",)
                          },
                          {
                              fieldName: "customerId",
                              fieldShowName: i18n.t("客户",),
                              inputType: 6,
                              dictionary: "clothes_customer"
                          },
                          {
                              fieldName: "skuId",
                              fieldShowName: i18n.t("商品编码",),
                              inputType: 6,
                              dictionary: "clothes_sku"
                          },
                          {
                              fieldName: "type",
                              fieldShowName: i18n.t("产品类型",)
                          },
                          {
                              fieldName: "category",
                              fieldShowName: i18n.t("产品标签",)
                          },
                          {
                              fieldName: "subtotal",
                              fieldShowName: i18n.t("金额合计",)
                          },
                          {
                              fieldName: "subTotalQuantity",
                              fieldShowName: i18n.t("数量",)
                          },
                          {
                              fieldName: "principalId",
                              fieldShowName: i18n.t("业务负责人",),
                              inputType: 6,
                              dictionary: "base_staff"
                          },
                          {
                              fieldName: "status",
                              fieldShowName: i18n.t("状态",),
                              inputType: 6,
                              dictionary: "dict_order_status"
                          },

                      ]}/>

        </div>
    );
}