import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import { Menu, Dropdown, Button, Modal, message, Space, Drawer } from "antd";
import {
  CaretDownOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import {
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  ImportOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import OrderPlanDetail from "./Detail/index";
import { Result } from "../../../../../schemas/common";

export default () => {
  const thisGridRef = useRef<any>();
  const [rowData, setRowData] = useState<any>({});
  const [visible, setVisible] = useState(false);

  const { height } = useWindowSize();
  const showRowDetail = function (record: any) {
    setRowData(record.ref);
    setVisible(true);
  };
  const startDispatch = function (uuid: string) {
    axios
      .post<Result<any[]>>(`/clothes/productionPlanManage/startDispatch`, {
        planId: uuid,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("派单成功"));
          thisGridRef.current.refresh();
        } else if (resp.data.Status === "2") {
          message.info(i18n.t("派单失败，该生产计划暂无生成计划明细"));
        }
      });
  };
  const columnsData = [
    {
      fieldShowName: i18n.t("操作",),
      fixed: "left",
      title: "option",
      width: 200,
      align: "center",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        if (record.status == "1") {
          return (
              <>
                <a
                    style={{marginRight: 16, color: "#3592FB"}}
                    onClick={() => {
                      showRowDetail(record);
                    }}
                >
                  {i18n.t("计划详情")}
                </a>
                <a
                    style={{color: "#3592FB"}}
                    onClick={() => {
                      startDispatch(record.uuid);
                    }}
                >
                  {i18n.t("开始派单")}
                </a>
              </>
          );
        } else {
          return (
              <>
                <div
                    className="u-ellips-box"
                    onClick={() => {
                      showRowDetail(record);
                    }}
                >
                  {i18n.t("计划详情")}
                </div>
              </>
          );
        }
      },
    },
    {
      fieldShowName: i18n.t("订单号",),
      fieldName: "serialNumber",
      width: 180,
    },
    {
      fieldShowName: i18n.t("客户",),
      fieldName: "customerId",
      width: 180,
      dictionary: "crm_customer",
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("商品",),
      fieldName: "skuId",
      width: 180,
      dictionary: "clothes_sku",
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("订单日期",),
      fieldName: "orderDate",
      width: 180,
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("派单数量",),
      fieldName: "assignQuantity",
      width: 180,
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("当前进度",),
      fieldName: "planStatus",
      width: 180,
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("预计交货日期",),
      fieldName: "preDeliveryDate",
      width: 200,
      showTitle: true,
    },
  ];
  const operationMenu = <Menu></Menu>;
  const [columns] = useState<any[]>(columnsData);

  return (
      <div className="u-box u-basic-page">
        <div className="u-box-head">
        <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          {i18n.t("生产计划管理")} <CaretDownOutlined/>
        </span>
          <div className="u-box-toolbar">
            <div className="u-icon-list u-btn-list">
              <Space>
                <Button type="primary" onClick={() => {
                }} className="u-btn-add">
                  {i18n.t("打印")}
                </Button>
                <Button type="primary" onClick={() => {
                }} className="u-btn-add">
                  {i18n.t("导出PDF")}
                </Button>
                <Dropdown overlay={operationMenu}>
                <span title="UnorderedListOutlined">
                  <UnorderedListOutlined/>
                </span>
                </Dropdown>
              </Space>
            </div>
          </div>
        </div>

        <div className="u-box-con">
          <DataGrid
              ref={thisGridRef}
              pagination={true}
              sorter={{field: "createTime", order: "descend"}}
              pageSize={Math.floor((height - 280) / 42)}
              rowSelectionType="checkbox"
              descriptions={columns}
              url={`/clothes/productionPlanManage/getOrderProductionPlanList`}
          />
          <Drawer
              className="u-drawer u-detail-drawer"
              placement="right"
              mask={false}
              width="78%"
              height="min-height:500px"
              closable={true}
              onClose={() => {
                setVisible(false);
              }}
              visible={visible}
              getContainer={false}
          >
            <div className="u-box detail-box u-box-fixed">
              <div className="u-box-head">
                <div className="u-box-toolbar u-fr">
                  <button
                      className="u-drawer-close"
                      onClick={() => {
                        setVisible(false);
                      }}
                  >
                    <CloseOutlined/>
                  </button>
                </div>
              </div>
              <OrderPlanDetail
                  orderId={rowData.orderId}
                  serialNumber={rowData.serialNumber}
                  customerId={rowData.customerId}
                  orderPlanId={rowData.uuid}
              />
            </div>
          </Drawer>
        </div>
      </div>
  );
};
