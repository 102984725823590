import React, { ReactNode } from "react";
import { Form, Input, Modal, InputNumber, message } from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import "../../CSS/customer.css";
import { useWindowSize } from "react-use";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import i18n from "i18next";
import { FormInstance } from "antd/es/form";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Params {
  customerId: string;
  brandId: string;
  priceVisiable: boolean;
  priceHideModal: () => void;
  brandForm: FormInstance;
}
export default ({ customerId, brandId, priceVisiable, priceHideModal, brandForm }: Params) => {
  const { width, height } = useWindowSize();
  const [detailForm] = Form.useForm();
  const detailOnOk = () => {
    detailForm.submit();
  };
  const detailElement: ReactNode = (
    <>
      <>
        <button
          type="button"
          className="ant-btn ant-btn-primary"
          onClick={detailOnOk}
        >
          <span>{i18n.t("确定")}</span>
        </button>
        <button type="button" className="ant-btn" onClick={priceHideModal}>
          <span>{i18n.t("取消")}</span>
        </button>
      </>
    </>
  );
  const onDetailFinish = (values: any) => {
    values.isCurrency = 0;
    values.customerId = customerId;
    values.brandId = brandId;
    axios
      .post<Result<any[]>>(`/clothes/price/savePrice`, {
        data: JSON.stringify(values),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          let pricelistId = resp.data.Data;
          brandForm.setFieldsValue({
            pricelistId: pricelistId,
          });
          priceHideModal();
        }
      });
  };
  return (
      <Modal
          title={i18n.t("价格表")}
          className="add-component"
          visible={priceVisiable}
          destroyOnClose={true}
          onCancel={priceHideModal}
          footer={detailElement}
          width={(width * 1) / 3}
          bodyStyle={{height: (height * 1) / 2.3}}
          maskClosable={false}
      >
        <Form
            {...layout}
            form={detailForm}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
            preserve={false}
            onFinish={onDetailFinish}
        >
          <Form.Item name="uuid" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="isCurrency" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="customerId" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="brandId" hidden>
            <Input/>
          </Form.Item>
          <Form.Item label={i18n.t("价格表名称")} name="name">
            <Input/>
          </Form.Item>
          <Form.Item label={i18n.t("参考基价")} name="referenceBasePrice">
            <Input readOnly={true}/>
          </Form.Item>
          <Form.Item label={i18n.t("前期成本固定加价")} name="earlyPriceIncrease">
            <Input type="number"/>
          </Form.Item>
          <Form.Item label={i18n.t("利润%")} name="profit">
            <InputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                size={"middle"}
                parser={(value: any) => value.replace("%", "")}
            />
          </Form.Item>
          <Form.Item label={i18n.t("后期固定加价")} name="latePriceIncrease">
            <Input type="number"/>
          </Form.Item>
          <Form.Item label={i18n.t("修正精确")} name="accuracyId">
            <DictionarySelect entityName={"dict_price_accuracy"} isTrans = {true}/>
          </Form.Item>
        </Form>
      </Modal>
  );
};
