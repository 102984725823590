import React, { ReactNode, useCallback, useRef, useState } from "react";
import {message, Modal, Switch} from "antd";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { DataGrid } from "../../../../../../components/DataGrid";
import {instance as axios} from "../../../../../../util/axios";

interface ModalFormProps {
  visible: boolean;
  onCancel: () => void;
  categoryId: string;
}

export const ModalForm: React.FC<ModalFormProps> = ({ visible, onCancel,categoryId }) => {
  const { width, height } = useWindowSize();
  const thisGridRef = useRef<any>(null);
  const onClose = useCallback(function () {
    onCancel();
  }, []);

  const columnsData = [
    {
      fieldName: "categoryId",
        fieldShowName: i18n.t("所属分类",),
      width: 200,
      align: "center",
      inputType: 6,
      dictionary: "color_sku_category",
    },
    {
      fieldName: "name",
      fieldShowName: i18n.t("颜色名称"),
      width: 200,
      align: "center",
    },
      {
          fieldName: "name",
          fieldShowName: i18n.t("颜色"),
          width: 200,
          align: "center",
          isHiddenSearch: true,
          render: (text: any, record: any) => {
              let colorName = "black";
              //1:暗色
              if(record.nameColorId == "1"){
                  colorName = "white";
              }
              if (record.typeId == "2") {
                  return (
                      <div style={{ width: "100%"}}>
                          {
                              record.colorArr.map((color:any,index:any)=>{
                                  return <div
                                      style={{
                                          backgroundColor: color,
                                          float: "left",
                                          width: 100/record.colorArr.length+"%",
                                      }}
                                  >
                                      <span style={{color:colorName}}>&nbsp;</span>
                                  </div>
                              })
                          }
                      </div>
                  )
              } else {
                  return (
                      <div style={{ width: "100%" }}>
                          <div
                              style={{
                                  backgroundColor: record.rgb,
                                  float: "left",
                                  width: "100%",
                              }}
                          >
                              <span style={{color:colorName}}>&nbsp;</span>
                          </div>
                      </div>
                  );
              }
          },
      },
  ];

  const [columns] = useState<any[]>(columnsData);

  const onOk = () => {
      var selected = thisGridRef.current.getSelectedRows();
      if(selected.length>0){
          let arr:any=[];
          selected.map((item:any)=>{
              arr.push({
                  uuid:item.uuid,
                  categoryId:item.categoryId+","+categoryId,
              })
          })
          axios.post("/clothes/colorManager/updateCategoryIdByColorIdList",{
              data:JSON.stringify(arr)
          }).then(function (resp) {
                  if (resp.data.Status == 1) {
                      message.success(i18n.t("新增成功!"));
                      onCancel();
                  }
              })
      }else{
          message.info(i18n.t("请选择中颜色后点击确定"));
      }
      console.log("selected:",selected);
      console.log("categoryId:",categoryId);
  };

    const element: ReactNode = (
        <>
            <button type="button" className="ant-btn ant-btn-primary" onClick={onOk}>
                <span>{i18n.t("确定")}</span>
            </button>
            <button type="button" className="ant-btn" onClick={onClose}>
                <span>{i18n.t("cancelText")}</span>
            </button>
        </>
    );

    return (
        <Modal
            closable={false}
            visible={visible}
            onCancel={onCancel}
            footer={element}
            destroyOnClose={true}
            maskClosable={false}
            bodyStyle={{height: "500px",overflow:"scroll"}}
            width={1000}
        >
            <div className="sku-model1">{i18n.t("颜色库")}</div>
            <div className="sku-model1">
                <DataGrid
                    ref={thisGridRef}
                    url={"/clothes/colorManager/getEnableListByCategoryId"}
                    rowSelectionType="checkbox"
                    pagination={false}
                    sorter={{field: "createTime", order: "descend"}}
                    pageSize={Math.floor((height - 280) / 42)}
                    descriptions={columns}
                    searchData={{categoryId: categoryId}}
                />
            </div>
        </Modal>
    );
};
