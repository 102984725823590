import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import {Form, message, Button, Space, Modal, Input} from "antd";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../util/description";
import { FieldDescription } from "../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../schemas/description";
import {useUpdateEffect, useWindowSize} from "react-use";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../components/EditForm";
import { DictionarySelects } from "../../../../../components/DictionarySelectFilter";
import GetRender from "../Sku/Detail/GetRender";
import { validateMessages } from "../util/DocumentEvent";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons/lib";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export default () => {
  const [form] = Form.useForm();
  const [detailForm] = Form.useForm();
  const { width, height } = useWindowSize();
  const [data, setData] = useState<any>([]);
  const dataGridRef = useRef<any>(null);
  const history = useHistory();
  const [namespace, setNamespace] = useState(
      "clothes_sku_working_procedure.all"
  );
  const [originData, setOriginData] = useState([
    {
      rawMaterialCost: 0,
      liningCost: 0,
      accessoriesCost: 0,
      craftCost: 0,
      totalCost: 0,
    },
  ]);
  const [realEntityName, setRealEntityName] = useState(
      "clothes_sku_working_procedure"
  );
  const [entityName, setEntityName] = useState("clothes_sku_working_procedure");
  const [craftTemplateId, setCraftTemplateId] = useState("1");
  const [skuCraftTemplateId, setSkuCraftTemplateId] = useState("");
  const [isExists, setIsExists] = useState(false);
  const [urlData, setUrlData] = useState("1");
  const [currentDescriptions, setCurrentDescriptions] = useState<
      FieldDescription[]
      >([]);
  const [loading, setLoading] = useState(false);

  useEffect(
      function () {
        setTemplateId(craftTemplateId);
        form.setFieldsValue({
          craftTemplateId:craftTemplateId
        })
        getTemplateById(craftTemplateId);
      },
      [craftTemplateId]
  );
  const setTemplateId = useCallback(function setTemplateId(
      craftTemplateId: string
      ) {
        form.setFieldsValue({
          craftTemplateId: craftTemplateId,
        });
        setUrlData(
            "/clothes/sku/getWorkingProcedureByTemplateId?craftTemplateId=" +
            craftTemplateId
        );
      },
      []);

  const onFinish = async (values: any) => {
    await saveDataGrid(dataGridRef);
    try {
      let procedureList = await dataGridRef.current.getData();
      await axios
          .post<Result<any[]>>(`/clothes/sku/saveWorkingProcedureByTemplateId`, {
            craftTemplateId: values.craftTemplateId,
            data: JSON.stringify(procedureList),
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              message.info(i18n.t("saveSuccess"));
              setTimeout(()=>{
                  window.location.reload()
              }, 2000);
            }
          });
    } catch (e) {
      message.error(i18n.t("saveError"));
      setLoading(false);
    }
  };

  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
          (nameSpaceDescriptions: any) => {
            if (nameSpaceDescriptions == null) {
              setCurrentDescriptions(descriptions);
            }
          }
      );
    });
  }, []);
  const [tableForm] = Form.useForm();

  function format() {
    const formattedCurrentDescriptions = JSON.parse(
        JSON.stringify(currentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(
          dataGridRef,
          onDetailSave,
          entityName,
          false,
          realEntityName,
          onDetailSave
      ),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
        if(description.fieldName != 'sort'){
            description.editable = true;
        }
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }
  const onDetailSave = async () => {
  };
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("删除提示"),
      okText: i18n.t("determine"),
      cancelText: i18n.t("cancel"),
      onOk: function () {
        const url = `/clothes/sku/deleteTemplateById?craftTemplateId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("deleteSuccess"));
            setTimeout(()=>{
                window.location.reload()
            }, 2000);
          } else {
            message.info(i18n.t("deleteError"));
          }
        });
      },
    });
  }, []);
  const [detailVisible, setDetailVisible] = useState(false);
  const hideModal = () => {
    setDetailVisible(false);
  };
  const detailOnOk = () => {
    detailForm.submit();
  };
  const getOtherTemplate = useCallback(function (uuid: string) {
    axios
        .post<Result<any[]>>(`/clothes/sku/getOtherTemplate`, {
          craftTemplateId: uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            setData(resp.data.Data);
          }
        });
  }, []);
  const showSizeName = (type:string) => {
    if(type=="add"){
      setDetailVisible(true);
      detailForm.setFieldsValue({
        uuid:"",
        skuId:"",
        name: "",
      })
      getOtherTemplate("");
    }else{
      setDetailVisible(true);
      getOtherTemplate(craftTemplateId);
    }
  };
  const onDetailFinish = (values:any) => {
    axios
        .post<Result<any[]>>(`/clothes/sku/saveTemplate`, {
          data: JSON.stringify(values),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setDetailVisible(false);
            setData([]);
            setTimeout(()=>{
                window.location.reload()
            },3000);
          }
        });
  };
  const detailElement: ReactNode = (
      <>
        <button
            style={{ width: 100 }}
            className="ant-btn ant-btn-primary"
            onClick={detailOnOk}
        >
          <span>{i18n.t("保存")}</span>
        </button>
      </>
  );
  const getTemplateById = useCallback(function getTemplateById(uuid: string) {
    axios
        .post<Result<any[]>>(`/clothes/sku/getTemplateById`, {
          craftTemplateId: uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data: any = resp.data.Data;
            if (data != undefined) {
              setTemplateId(data.uuid);
              detailForm.setFieldsValue({
                uuid:data.uuid,
                skuId:data.skuId,
                name: data.name,
              })
            }
          }
        });
  }, []);
  const [messageInfo, setMessageInfo] = useState<any>();
  return (
      <>
        <div className="u-form">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                onFinish={onFinish}
                validateMessages={validateMessages}
                onFinishFailed={(err: any) => {
                  setMessageInfo(err);
                  setLoading(false);
                }}
                scrollToFirstError={true}
            >
              <div className="u-ThreCon">
                <Form.Item
                    label={i18n.t("模板名称")}
                    className="size-code"
                    name={"craftTemplateId"}
                    {...{ labelCol: { span: 7 }, wrapperCol: { span: 9 } }}
                >
                  <DictionarySelects
                      entityName={"clothes_craft_template"}
                      filter={"1 = 1 AND isDelete != 1 and skuId is null or skuId = '' "}
                      onChange={(value) => {
                        if(dataGridRef.current.isOnEdit()){
                          dataGridRef.current.cancelEdit();
                          dataGridRef.current.refresh();
                        }
                        setCraftTemplateId(value);
                      }}
                  />
                </Form.Item>
                <div className="size-add" >
                  <PlusOutlined
                      style={{ fontSize: "160%", marginTop: "23%" }}
                      onClick={()=>{
                        showSizeName("add");
                      }}
                  />
                </div>
                <div className="size-edit">
                  <EditOutlined
                      style={{ fontSize: "160%", marginTop: "23%" }}
                      onClick={()=>{
                        showSizeName("edit");
                      }}
                  />
                </div>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{ wrapperCol: { span: 24 } }}
              >
                    <DataGrid
                        ref={dataGridRef}
                        url={urlData}
                        descriptions={format()}
                        pagination={false}
                        onClick={async (record: any) => {
                          await editOtherLine(dataGridRef, record);
                        }}
                        loadAllDictionary={true}
                        isShowSearch={false}
                        form={tableForm}
                    />
              </Form.Item>
              <div style={{ paddingBottom: 25 }}>
                <Space>
                  <Button
                      style={{ width: 100 }}
                      onClick={async () => {
                        // await addLine(dataGridRef);
                        var tempData = dataGridRef.current.getData()
                        await saveDataGrid(dataGridRef);
                        var maxSort = 0;
                        tempData.map((item:any)=>{
                          if(item.sort > maxSort){
                            maxSort = item.sort;
                          }
                        })
                        dataGridRef.current.addData({sort:maxSort+1});
                      }}
                  >
                    {i18n.t("add")}
                  </Button>
                </Space>
              </div>
              <Form.Item
                  className="u-form-custom100"
                  {...{ wrapperCol: { span: 24 } }}
              >
                <div className="u-button-box">
                  <Form.Item>
                    <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          // setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                          if (craftTemplateId == "1" || craftTemplateId == "2") {
                            message.error(i18n.t("基础模板不能删除"));
                          } else {
                            confirm(craftTemplateId);
                          }
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("删除")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>

          <Modal
              style={{ marginTop: "5%" }}
              className="add-component"
              visible={detailVisible}
              onCancel={hideModal}
              footer={detailElement}
              width={(width * 1) / 3}
              destroyOnClose={true}
              bodyStyle={{ height: (height * 1) / 3.5 }}
              maskClosable={false}
          >
            <Form
                {...layout}
                form={detailForm}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onDetailFinish}
            >
              <Form.Item
                  name="uuid"
                  hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                  name="skuId"
                  hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                  className="size-name"
                  label={i18n.t("模板名称")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      validator: async (rule, value) => {
                        if (value == undefined || value.trim() == "") {
                          message.error(i18n.t("模板名称不能为空"));
                          throw new Error(i18n.t("模板名称不能为空"));
                        }
                        if (data != undefined && data.length > 0) {
                          data.map((item: any) => {
                            if (item.name == value) {
                              message.error(i18n.t("模板名称重复"));
                              throw new Error(i18n.t("模板名称重复"));
                            }
                          });
                        }
                      },
                    },
                  ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </>
  );
};
