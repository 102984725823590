import {isNull} from "util";
import {isNullOrUndefined} from "./Common";

function parser(moneyStr:string) {
    moneyStr = moneyStr + "";
    return Number(moneyStr.replace(/\$\s?|(,*)/g, ""));
}

function formatMoney(
    number: any,
    places: number,
    symbol: any,
    thousand: any,
    decimal: any
) {
    places = !isNaN((places = Math.abs(places))) ? places : 0;
    symbol = symbol !== undefined ? symbol : ""; //"$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var negative = number < 0 ? "-" : "",
        i: any =
            parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "",
        j = i.length;
    j = j > 3 ? j % 3 : 0;
    return (
        symbol +
        negative +
        (j ? i.substr(0, j) + thousand : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
        (places
            ? decimal +
            Math.abs(number - i)
                .toFixed(places)
                .slice(2)
            : "")
    );
}

function formatNumber(number:any) {
    if(isNullOrUndefined(number)){
        return 0;
    }else{
        return Number(number);
    }
}

export {formatMoney,parser,formatNumber}