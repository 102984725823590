import React from "react";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";

interface Param {
    title:string,
    content:any,
    onOk?:()=>void,
    onCancel?:()=>void
}

const Tip = ({title,content,onOk,onCancel}:Param)=>{
    return (
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            content: content,
            onOk() {
                if(onOk){
                    onOk();
                }
            },
            onCancel() {
                if(onCancel){
                    onCancel();
                }
            },
        })
    )
}

export default Tip;