import { Description } from "../../../../../../schemas/description";
import moment from "moment";
import { CustomerFilters } from "../../../../../../components/CRMFilterDialog";
import { CRMBelong } from "../../../../../../type";

export function buildFilterURL(
  filter: CustomerFilters,
  descriptions: Description[]
): string {
  let filterUrl = "";
  if (filter.belong) {
    filterUrl += `&relationship=${filter.belong}`;
  }
  if (filter.dateRangeFilters.createTime) {
    const format = "YYYY-MM-DD";
    let createTimeRange: [string | null, string | null] | null;
    if (filter.dateRangeFilters.createTime === "recent7Days") {
      createTimeRange = [
        moment().add(-6, "days").format(format),
        moment().startOf("day").format(format),
      ];
    } else if (filter.dateRangeFilters.createTime === "yesterday") {
      createTimeRange = [moment().add(-1, "days").format(format), null];
    } else if (filter.dateRangeFilters.createTime === "today") {
      createTimeRange = [moment().startOf("day").format(format), null];
    } else if (
      filter.dateRangeFilters.createTime instanceof Array &&
      filter.dateRangeFilters.createTime.length === 2
    ) {
      createTimeRange = [
        filter.dateRangeFilters.createTime[0],
        filter.dateRangeFilters.createTime[1],
      ];
    } else {
      createTimeRange = [null, null];
    }
    filterUrl += `&startCreateTime=${
      createTimeRange[0] || ""
    }&finishCreateTime=${createTimeRange[1] || ""}`;
  }
  if (filter.fieldFilters.length > 0) {
    filterUrl += `&selectCondition=${encodeURI(
      JSON.stringify(
        filter.fieldFilters.map((item) => {
          const inputType = descriptions.find(
            (description) => item.fieldName === description.fieldName
          )!.inputType!;
          let fieldValue =
            [4, 6, 8].indexOf(inputType) > -1
              ? item.fieldValue.join(",")
              : item.fieldValue;
          return {
            ...item,
            fieldValue,
            queryMethod: item.operator,
            fieldType: [
              "string",
              null,
              "number",
              "bool",
              "datetime",
              null,
              "dictionary",
              null,
              "dictionary",
            ][inputType],
          };
        })
      )
    )}`;
  }
  return filterUrl;
}
