import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import zhCn from "antd/es/locale/zh_CN";
import "./containers/App/i18n";
import storage from "./containers/App/screens/Clothes/util/Stroage";
import moment from "moment";
import "moment/locale/zh-cn";


function getLanguage(){
    var lang = storage.get("ClientLanguage");
    if(lang == "zh_cn"){
        moment.locale('zh-cn')
        return zhCn;
    }else{
        moment.locale("en");
        return enUS;
    }
}

ReactDOM.render(
  <ConfigProvider locale={getLanguage()}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
