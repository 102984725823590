import React, { useEffect, useState } from "react";
import {Button, Modal,Upload} from "antd";
import { instance as axios } from "../../util/axios";
import { BaseURL } from "../../constants/url";
import { UploadFile } from "antd/lib/upload/interface";
import i18n from "i18next";
import {PlusOutlined} from "@ant-design/icons/lib";

export interface Param {
  value?: string | string[];
  onChange?: (value: any) => {};
}

const FileUpload = ({ value, onChange }: Param) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    axios.get(`/oa/common/attachment/index?attachIds=${value}`).then((resp) => {
      if (resp.data.Status === "1") {
        let patternArr = ["BMP", "JPG", "JPEG", "PNG", "GIF"];
        setFileList(
          resp.data.Data.map(
            (item: { filename: string; uuid: string; suffix: string }) => {
              let url: string;
              if (
                item.suffix &&
                patternArr.indexOf(item.suffix.toUpperCase()) > 0
              ) {
                url =
                  BaseURL +
                  "/oa/common/attachment/showImageAttachment?id=" +
                  item.uuid;
              } else if (item.suffix.toUpperCase() === "PDF") {
                url =
                  BaseURL +
                  "/html/base/public/attachment/showPDF?fileId=" +
                  item.uuid;
              } else {
                url =
                  BaseURL +
                  "/oa/common/attachment/downloadFile?id=" +
                  item.uuid;
              }
              return {
                name: item.filename,
                uid: item.uuid,
                status: "done",
                url: url,
                response: { Data: { uuid: item.uuid } },
              };
            }
          )
        );
      }
    });
  }, [value]);
  return (
      <div className="clearfix">
          <Upload
              // listType="picture-card"
              withCredentials={true}
              data={(file: { name: string; }) => {
                  return {
                      fileData: file,
                      category: "id",
                      fileName: encodeURI(file.name),
                  };
              }}
              fileList={fileList}
              multiple={true}
              action={BaseURL + "/oa/common/attachment/uploadFile"}
              onChange={(param:any) => {
                  setFileList(param.fileList);
                  const ids = param.fileList
                      .filter((item:any) => item.status === "done")
                      .map((item:any) => item.response.Data.uuid)
                      .join(",");
                  if(onChange){
                      onChange(ids)
                  }
              }}
          >
              <Button className={"ant-upload ant-upload-select ant-upload-select-picture-card"}>
                  <PlusOutlined />
                  <div className="ant-upload-text">
                      {i18n.t("上传附件")}
                  </div>
              </Button>
          </Upload>
      </div>
  );
};
export default FileUpload;
