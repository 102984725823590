import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { get } from "../../../../../../util/description";
import { Button, message, Modal, Space, Spin } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import { UserSelect } from "../../../../../../components/UserSelect";
import { TableGrid } from "../../../../../../components/TableGrid";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons/lib";
import { useWindowSize } from "react-use";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { Description } from "../../../../../../schemas/description";
import { advisorIdRender } from "../../Customer/List/util";
import { Result } from "../../../../../../schemas/common";
import { Detail } from "../Detail";
import {
  advisorIdDescription,
  createTimeDescription,
} from "../../../../../../constants/descriptions";
import moment from "moment";
import { buildFilterURL } from "./util";
import { CRMFilterDialog } from "../../../../../../components/CRMFilterDialog";
import { createNewTab } from "../../../../../../util/tyy";

const { confirm } = Modal;
async function del(gridRef: React.MutableRefObject<any>, userId: string) {
  const selectedRows: any[] = gridRef.current.getSelectedRows();
  const ids = gridRef.current.getSelectedIds().join(",");
  if (ids === "") {
    message.error("请选择您要删除的商机");
    return;
  }
  if (!selectedRows.every((item) => item.advisorId === userId)) {
    message.error("您没有权限删除他人录入的商机");
    return;
  }
  let hasPermission = true;
  for (let i = 0; i < selectedRows.length; i++) {
    const resp = await axios.get<Result<any>>(
      `/crm/salesChance/getChanceById?t=${new Date().getTime()}&uuid=${
        selectedRows[i].uuid
      }`
    );
    if (resp.data.Status === "1") {
      const data: { [key: string]: string | number } = resp.data.Data;
      if (
        data.stage &&
        data.status !== "35fd82f361cb13eab4427cd30aeb1fda" &&
        data.status !== "35fd82f361cb12eab4427cd30aeb1fda"
      ) {
        message.error("无法删除进行中的商机！");
        hasPermission = false;
        return;
      }
    }
  }
  if (hasPermission) {
    confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      content: `您确定要删除吗?`,
      onOk() {
        axios
          .post<Result<string>>("/crm/salesChance/deleteChance", {
            ids: ids,
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              message.success("删除成功");
              gridRef.current.refresh();
            } else {
              message.error(resp.data.Data);
            }
          });
      },
    });
  }
}
function changeToProject(gridRef: React.MutableRefObject<any>, userId: string) {
  const ids = gridRef.current.getSelectedIds().join(",");
  const selectedRows: any[] = gridRef.current.getSelectedRows();
  if (ids === "") {
    message.error("请选择您要转化为项目的商机");
    return;
  }
  if (selectedRows.length > 1) {
    message.error("一次只能转化一个商机");
    return;
  }
  if (selectedRows[0].advisorId !== userId) {
    message.error("您无权把他人的商机转化为项目");
    return;
  }
  axios
    .get<Result<{ code: number; uuid: string }>>(
      `/crm/salesChance/getTransformProject?chanceCode=${selectedRows[0].code}`
    )
    .then((resp) => {
      if (resp.data.Status === "1") {
        let workflowTemplateId = "f3f6afbe8fae4ab0bddc483cf9531e28";
        if (
          resp.data.Data.code &&
          resp.data.Data.code === selectedRows[0].code
        ) {
          createNewTab(
            `/wf/form/vsheet/form?workflowTemplateId=${workflowTemplateId}&id=${resp.data.Data.uuid}`,
            "项目报备申请"
          );
        } else {
          const arg = `chanceId=${selectedRows[0].uuid}&code=${selectedRows[0].code}&advisorId=${selectedRows[0].advisorId}&region=${selectedRows[0].region}&regionProvince=${selectedRows[0].province}&source=${selectedRows[0].source}&projectName=${selectedRows[0].name}`;
          createNewTab(
            `/wf/form/vsheet/form?workflowTemplateId=${workflowTemplateId}&id=0&${arg}`,
            "项目报备申请"
          );
        }
      } else {
        message.error(resp.data.Message);
      }
    });
}
export default function List() {
  const gridRef = useRef<any>();
  const tableGridRef = useRef<any>(null);
  const [url, setUrl] = useState("/crm/salesChance/getData");
  const [loading, setLoading] = useState(false);
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const session: Session = useContext(SessionContext);
  const { height } = useWindowSize();
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const [shareChanceVisible, setShareChanceVisible] = useState(false);
  const [
    salesChanceFilterDialogVisible,
    setSalesChanceFilterDialogVisible,
  ] = useState(false);
  const [addSalesChanceVisible, setAddSalesChanceVisible] = useState(false);
  const defaultUrl = "/crm/salesChance/getData";

  useEffect(function () {
    get("crm_sale_chance").then((columns: FieldDescription[]) => {
      setDescriptions(columns);
      setLoading(false);
    });
  }, []);
  const formatDescriptions = useCallback(
    function () {
      const formattedCurrentDescriptions: FieldDescription[] = JSON.parse(
        JSON.stringify(descriptions)
      );
      if (
        !formattedCurrentDescriptions.some(
          (item) => item.fieldName === "createTime"
        )
      ) {
        formattedCurrentDescriptions.push(createTimeDescription);
      }

      if (
        !formattedCurrentDescriptions.some(
          (item) => item.fieldName === "advisorId"
        )
      ) {
        formattedCurrentDescriptions.push(advisorIdDescription);
      }
      if (
        !formattedCurrentDescriptions.some(
          (item) => item.fieldName === "clueProviderId"
        )
      ) {
        formattedCurrentDescriptions.push({
          fieldShowName: "线索提供人",
          fieldName: "clueProviderId",
          dictionary: "base_staff",
        });
      }
      formattedCurrentDescriptions.push({
        fieldShowName: "转化状态",
        fieldName: "currentState",
      });

      formattedCurrentDescriptions.forEach((columns: Description) => {
        if (columns.fieldName === "advisorId") {
          columns.render = advisorIdRender;
        }
      });
      return formattedCurrentDescriptions;
    },
    [descriptions]
  );

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ paddingLeft: 10 }}>
          <div style={{ marginBottom: 10 }}>
            <Space>
              <Button
                type={"primary"}
                icon={<PlusOutlined />}
                onClick={() => {
                  setAddSalesChanceVisible(true);
                }}
              >
                新建商机
              </Button>
              <Button
                onClick={() => {
                  changeToProject(gridRef, session.id);
                }}
              >
                转化为项目
              </Button>
              {session.permissions.some(
                (item: any) => item === "批量分配客户"
              ) && (
                <Button
                  onClick={() => {
                    const ids = gridRef.current.getSelectedIds().join(",");
                    if (ids === "") {
                      message.error("请选择要分配的商机");
                      return;
                    }
                    setUserSelectVisible(true);
                  }}
                >
                  分配
                </Button>
              )}
              <Button onClick={() => del(gridRef, session.id)}>删除</Button>
              <Button
                onClick={() => {
                  gridRef.current.export();
                }}
              >
                导出
              </Button>
              <Button
                onClick={() => {
                  const ids = gridRef.current.getSelectedIds().join(",");
                  if (ids === "") {
                    message.error("请选择要共享的商机");
                    return;
                  }
                  const selectedRows: any[] = gridRef.current.getSelectedRows();
                  if (
                    !selectedRows.every((item) => item.advisorId === session.id)
                  ) {
                    message.error("只能共享自己的商机");
                    return;
                  }
                  setShareChanceVisible(true);
                }}
              >
                共享
              </Button>
            </Space>
            <Space style={{ float: "right" }}>
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setSalesChanceFilterDialogVisible(true);
                }}
              >
                筛选
              </Button>
            </Space>
          </div>
          <div>
            {descriptions.length > 0 && height && (
              <>
                <DataGrid
                  onDraw={(record) => (
                    <Detail key={record.key} chanceId={record.key} />
                  )}
                  drawerWidth={1000}
                  pageSize={Math.floor((height - 180) / 42)}
                  ref={gridRef}
                  url={url}
                  sorter={{ field: "code", order: "descend" }}
                  rowSelectionType="checkbox"
                  descriptions={formatDescriptions()}
                />
              </>
            )}
          </div>
        </div>
      </Spin>{" "}
      <Modal
        title="新建商机"
        width={800}
        // bodyStyle={{ height: 400, overflowY: "auto" }}
        visible={addSalesChanceVisible}
        onCancel={() => {
          setAddSalesChanceVisible(false);
        }}
        onOk={() => {
          tableGridRef.current.save();
        }}
      >
        <TableGrid
          ref={tableGridRef}
          entityName="crm_sale_chance"
          columnCount={2}
          defaultValues={{
            advisorId: session.id,
            createTime: moment().format("YYYY-MM-DD"),
          }}
          onSaved={() => {
            setAddSalesChanceVisible(false);
          }}
        />
      </Modal>
      <UserSelect
        visible={userSelectVisible}
        multiple={false}
        defaultSelectedUsers={[]}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
        onOk={(selectedUsers: any[]) => {
          axios
            .post<Result<string>>("/crm/salesChance/assign", {
              staffId: selectedUsers[0].id,
              ids: gridRef.current.getSelectedIds().join(","),
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("分配成功");
                gridRef.current.refresh();
              } else {
                message.error(resp.data.Message);
              }
            });
          setUserSelectVisible(false);
        }}
      />
      <UserSelect
        visible={shareChanceVisible}
        multiple={false}
        defaultSelectedUsers={[]}
        onCancel={() => {
          setShareChanceVisible(false);
        }}
        onOk={(selectedUsers: any[]) => {
          axios
            .post<Result<string>>("/crm/salesChance/share", {
              staffId: selectedUsers[0].id,
              ids: gridRef.current.getSelectedIds().join(","),
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("共享成功");
                gridRef.current.refresh();
              } else {
                message.error(resp.data.Message);
              }
            });
          setShareChanceVisible(false);
        }}
      />
      <CRMFilterDialog
        descriptions={formatDescriptions()}
        dateRangeFilters={[
          {
            dataIndex: "createTime",
            title: "创建时间：",
          },
        ]}
        visible={salesChanceFilterDialogVisible}
        onOk={(filter) => {
          console.log(filter);
          const filterURL = buildFilterURL(filter, formatDescriptions());
          setUrl(`${defaultUrl}?1=1${filterURL}`);
          setSalesChanceFilterDialogVisible(false);
        }}
        onCancel={() => {
          setSalesChanceFilterDialogVisible(false);
        }}
      />
    </>
  );
}
