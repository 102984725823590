import {instance as axios} from "../../../../../util/axios";
import React from "react";
import i18next from "i18next";
import {isNullOrUndefined} from "./Common";
import {message} from "antd";
import {addLine} from "../components/EditForm";

const SkuEvent = (e:any,tableForm:any,dataGridref:any,saveSuccess?:Function)=>{
    axios
        .post("/inventory/sku/getSkuById", {
            uuid: e,
        })
        .then(async (resp) => {
            var data = resp.data.Data;
            if (resp.data.Status == 1) {
                console.log(data);
                var item = {
                    skuCode:data.skuCode,
                    price:data.price,
                    quantity:1,
                    vendorId:data.vendorId,
                    taxAmount:0,
                    beforeTaxTotal:0,
                    afterTaxAmount:0
                };
                await tableForm.setFieldsValue(item);
                addLine(dataGridref);
            }
        }).then(()=>{
            if(saveSuccess){
                saveSuccess()
            }
    });
}

const validateMessages = {
    required: '请填写 ${label} ',
};

const validateDetailData = (data:any)=>{
    if(isNullOrUndefined(data) || data.length ==0){
        message.error(i18next.t("public_pleaceFillDetail"));
        throw new DOMException("", "");
    }
}

export {SkuEvent,validateMessages,validateDetailData}