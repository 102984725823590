import { instance as axios } from "./axios";
import {message} from "antd";

function downloadFile(fileUrl:any,fileName:any) {
  axios.get(fileUrl,{responseType:"blob"}).then((resp)=>{
    if(resp){
      const pdf = resp.data;
      if(pdf){
        var a = document.createElement('a');
        a.download = fileName;
        a.style.display = 'none';
        var blob = new Blob([pdf]);
        a.href = URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }else{
      message.error(" ")
    }

  }).catch(()=>{
    message.error(" ")
  })
}

export { downloadFile };
