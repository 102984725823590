import React, { useContext } from "react";
import { message } from "antd";
import { TabletTwoTone } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";

export interface APPDialingProps {
  mobile: string;

  style?: React.CSSProperties;
}
export const APPDialing = (props: APPDialingProps) => {
  const session: Session = useContext(SessionContext);
  return (
    <>
      <TabletTwoTone
        style={props.style}
        onClick={(e) => {
          axios
            .post("/oa/mobile/im/im/pushPhoneMessage", {
              recipientId: session.id,
              telephoneNumber: props.mobile,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("呼叫成功");
              } else {
                message.success("您还未配置工作手机，无法呼叫");
              }
            });
          e.stopPropagation();
        }}
      />
    </>
  );
};
