import React, { ReactNode, useCallback, useRef, useState } from "react";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../../util/axios";
export interface Params {
  enterpriseId: string;
  thisCorpId: number;
  visiable: boolean;
  hideModal: () => void;
}
export default ({ enterpriseId, thisCorpId, visiable, hideModal }: Params) => {
  const thisGridRef = useRef<any>();
  const { width, height } = useWindowSize();
  const confirm = useCallback(
    function confirm(
      enterpriseId: string,
      thisCorpId: number,
      originCorpId: number
    ) {
      Modal.confirm({
        title: i18n.t("appEnterprise_split_operation"),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("appEnterprise_split_q"),
        okText: i18n.t("determine"),
        cancelText: i18n.t("cancel"),
        onOk: function () {
          axios
            .post("oa/appEnterpriseManagement/splitSubAccountByCorpId", {
              enterpriseId: enterpriseId,
              thisCorpId: thisCorpId,
              originCorpId: originCorpId,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.info(i18n.t("appEnterprise_split_success"));
                thisGridRef.current.refresh();
              } else {
                message.info(i18n.t("appEnterprise_split_failed"));
              }
            });
        },
      });
    },
    [visiable]
  );
  const detailElement: ReactNode = <></>;
  return (
    <Modal
      title={i18n.t("appEnterprise_sub_account_list")}
      className="add-component"
      visible={visiable}
      destroyOnClose={true}
      onCancel={hideModal}
      footer={detailElement}
      width={(width * 1) / 1.3}
      bodyStyle={{ height: (height * 1) / 2 }}
      maskClosable={false}
    >
      <div className="u-box u-basic-page">
        <div className="u-box-con">
          <DataGrid
            ref={thisGridRef}
            pagination={true}
            sorter={{ field: "registeredTime", order: "descend" }}
            pageSize={Math.floor((height - 280) / 2)}
            rowSelectionType="checkbox"
            descriptions={[
              {
                fieldShowName: i18n.t("appEnterprise_originEnterpriseId"),
                fieldName: "originEnterpriseId",
                width: 100,
              },
              {
                fieldShowName: i18n.t("appEnterprise_name"),
                fieldName: "name",
                width: 100,
              },
              {
                fieldShowName: i18n.t("appEnterprise_account_role"),
                fieldName: "postId",
                dictionary: "base_position",
                width: 130,
              },
              {
                fieldShowName: i18n.t("public_contract"),
                fieldName: "contact",
                width: 100,
              },
              {
                fieldShowName: i18n.t("enterprise_contact_information"),
                fieldName: "contactWay",
                width: 130,
              },
              {
                fieldShowName: i18n.t("appEnterprise_mergeTime"),
                fieldName: "mergeTime",
                width: 150,
              },
              {
                fieldShowName: i18n.t("appEnterprise_finalLandingTime"),
                fieldName: "finalLandingTime",
                width: 150,
              },
              {
                fieldShowName: i18n.t("appEnterprise_registeredTime"),
                fieldName: "registeredTime",
                width: 150,
              },
              {
                fieldShowName: i18n.t("options"),
                width: 130,
                align: "center",
                isHiddenSearch: true,
                  fixed:"right",
                render: (text: any, record: any) => {
                  return (
                    <>
                      <a
                        href="#"
                        className="deleteA"
                        onClick={(e: any) => {
                          confirm(
                            enterpriseId,
                            thisCorpId,
                            record.originEnterpriseId
                          );
                        }}
                      >
                        {i18n.t("appEnterprise_split")}
                      </a>
                    </>
                  );
                },
              },
            ]}
            url={
              `/oa/appEnterpriseManagement/getSubAccountListByEnterpriseId?enterpriseId=` +
              enterpriseId
            }
          />
        </div>
      </div>
    </Modal>
  );
};
