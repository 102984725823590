import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { get } from "../../../../../../util/description";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Space,
  Spin,
  Tabs,
} from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import { UserSelect } from "../../../../../../components/UserSelect";
import { TableGrid } from "../../../../../../components/TableGrid";
import {
  DownOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons/lib";
import { useWindowSize } from "react-use";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { Description } from "../../../../../../schemas/description";
import { advisorIdRender } from "../../Customer/List/util";
import { Dialing } from "../../components/Dialing";
import { APPDialing } from "../../components/APPDialing";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import { Result } from "../../../../../../schemas/common";
import { Detail } from "../Detail";
import {
  advisorIdDescription,
  creatorIdDescription,
  createTimeDescription,
} from "../../../../../../constants/descriptions";

const { TabPane } = Tabs;
const { confirm } = Modal;

function del(gridRef: React.MutableRefObject<any>) {
  const ids = gridRef.current.getSelectedIds().join(",");
  if (ids === "") {
    message.error("请选择您要删除的线索");
    return;
  }
  confirm({
    title: "提示",
    icon: <ExclamationCircleOutlined />,
    okText: "确定",
    cancelText: "取消",
    content: `您确定要删除吗?`,
    onOk() {
      axios
        .post<Result<string>>("/crm/clue/deleteClue", {
          ids: ids,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.success("删除成功");
            gridRef.current.refresh();
          } else {
            message.error(resp.data.Data);
          }
        });
    },
  });
}
export default function List() {
  const unassignedRef = useRef<any>();
  const assignedRef = useRef<any>();
  const tableGridRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const session: Session = useContext(SessionContext);
  const [currentTabKey, setCurrentTabKey] = useState<"-1" | "0">("-1");
  const { height } = useWindowSize();
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const [closeForm, setCloseForm] = useState({
    visible: false,
    id: "",
  });
  const [addLeadVisible, setAddLeadVisible] = useState(false);

  useEffect(function () {
    get("crm_clue").then((columns: FieldDescription[]) => {
      setDescriptions(columns);
      setLoading(false);
    });
  }, []);
  const getCurrentRef = () => {
    if (currentTabKey === "-1") {
      return unassignedRef;
    } else {
      return assignedRef;
    }
  };
  const formatDescriptions = useCallback(
    function (gridRef) {
      const formattedCurrentDescriptions: FieldDescription[] = JSON.parse(
        JSON.stringify(descriptions)
      );
      if (
        !formattedCurrentDescriptions.some(
          (item) => item.fieldName === "createTime"
        )
      ) {
        formattedCurrentDescriptions.push(createTimeDescription);
      }
      if (
        !formattedCurrentDescriptions.some(
          (item) => item.fieldName === "creatorId"
        )
      ) {
        formattedCurrentDescriptions.push(creatorIdDescription);
      }
      if (currentTabKey === "0") {
        if (
          !formattedCurrentDescriptions.some(
            (item) => item.fieldName === "status"
          )
        ) {
          formattedCurrentDescriptions.push({
            fieldShowName: "状态",
            fieldName: "status",
            dictionary: "dict_clue_status",
          });
        }
        if (
          !formattedCurrentDescriptions.some(
            (item) => item.fieldName === "advisorId"
          )
        ) {
          formattedCurrentDescriptions.push(advisorIdDescription);
        }
        if (
          !formattedCurrentDescriptions.some(
            (item) => item.fieldName === "disAdvisorTime"
          )
        ) {
          formattedCurrentDescriptions.push({
            fieldShowName: "分配给业务员的时间",
            fieldName: "disAdvisorTime",
          });
        }
      }
      formattedCurrentDescriptions.forEach((columns: Description) => {
        if (columns.fieldName === "advisorId") {
          columns.render = advisorIdRender;
        } else if (columns.fieldName === "contactPho") {
          columns.width = 150;
          columns.render = function (text: any, record: any) {
            const advisorId = record.ref.advisorId;
            const textStyle = { marginLeft: "4px", verticalAlign: "middle" };
            return (
              <>
                <span style={{ verticalAlign: "middle" }}>{text}</span>
                {record.contactPho && (
                  <>
                    <Dialing
                      advisorId={advisorId}
                      style={textStyle}
                      uuid={record.uuid}
                      mobile={record.contactPho}
                    />
                    <APPDialing style={textStyle} mobile={record.contactPho} />
                  </>
                )}
              </>
            );
          };
        }
      });
      return formattedCurrentDescriptions;
    },
    [descriptions, currentTabKey]
  );
  function handleTabChange(key: string): void {
    setCurrentTabKey(key as "-1" | "0");
  }

  const transfer = useCallback(
    function (gridRef: React.MutableRefObject<any>) {
      const selectedRows: any[] = gridRef.current.getSelectedRows();
      if (selectedRows.length === 0) {
        message.error("请选择您要转化为商机的线索");
        return;
      }
      const hasPermission = selectedRows.every(function (row: any) {
        return row.advisorId === session.id;
      });
      if (!hasPermission) {
        message.error("操作失败，只能转化分配给自己的线索");
        return;
      }
      if (selectedRows.some((item) => item.status === "1")) {
        message.error("选择中包含未分配的线索");
        return;
      }
      if (selectedRows.some((item) => item.status === "4")) {
        message.error("选择中包含已成交的线索");
        return;
      }
      if (selectedRows.some((item) => item.status === "6")) {
        message.error("选择中包含已转化为商机的线索");
        return;
      }
      const ids = gridRef.current.getSelectedIds().join(",");
      confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        okText: "确定",
        cancelText: "取消",
        content: `确定要转化为商机吗？`,
        onOk() {
          axios
            .post<Result<string>>("/crm/clue/translateClueToSaleChance", {
              ids: ids,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success(resp.data.Data);
                gridRef.current.refresh();
              } else {
                message.error(resp.data.Data);
              }
            });
        },
      });
    },
    [session.id]
  );
  function handleTabBarExtraMenuClick({ key }: any) {
    switch (key) {
      case "export":
        const index = parseInt(currentTabKey) + 1;
        const gridRef = [unassignedRef, assignedRef][index];
        gridRef.current.export();
    }
  }
  const getTabPane = useCallback(
    function (
      tabName: string,
      key: "-1" | "0",
      gridRef: React.MutableRefObject<any>,
      status: string
    ) {
      return (
        <TabPane tab={tabName} key={key}>
          {currentTabKey === key && (
            <>
              <div style={{ paddingLeft: 10 }}>
                <div style={{ marginBottom: 10 }}>
                  <Space>
                    {key === "0" && (
                      <Button onClick={() => transfer(gridRef)}>
                        转化为商机
                      </Button>
                    )}
                    {session.permissions.some(
                      (item: any) => item === "分配线索"
                    ) && (
                      <Button
                        onClick={() => {
                          const selectedRows: any[] = gridRef.current.getSelectedRows();
                          if (selectedRows.length === 0) {
                            message.error("请选择您要分配的线索");
                            return;
                          }
                          setUserSelectVisible(true);
                        }}
                      >
                        分配
                      </Button>
                    )}
                    <Button onClick={() => del(gridRef)}>删除</Button>
                    <Button
                      onClick={() => {
                        const ids = gridRef.current.getSelectedIds().join(",");
                        if (ids === "") {
                          message.error("请选择您要关闭的线索");
                          return;
                        }
                        setCloseForm((prevState) => ({
                          ...prevState,
                          visible: true,
                        }));
                      }}
                    >
                      关闭
                    </Button>
                  </Space>
                </div>
                <div>
                  {descriptions.length > 0 && height && (
                    <>
                      <DataGrid
                        onDraw={(record) => (
                          <Detail key={record.key} leadId={record.key} />
                        )}
                        drawerWidth={1000}
                        sorter={{
                          field:
                            "IFNULL(disAdvisorTime,IFNULL(lastUpdateTime,createTime))",
                          order: "descend",
                        }}
                        pageSize={Math.floor((height - 220) / 42)}
                        ref={gridRef}
                        url={"/crm/clue/getClueList?status=" + status}
                        rowSelectionType="checkbox"
                        descriptions={formatDescriptions(gridRef)}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </TabPane>
      );
    },
    [currentTabKey, session.permissions, height, descriptions]
  );

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey={currentTabKey}
          onChange={handleTabChange}
          tabBarExtraContent={
            <Space>
              {currentTabKey === "-1" && (
                <Button
                  type={"primary"}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setAddLeadVisible(true);
                  }}
                >
                  新建线索
                </Button>
              )}
              <Dropdown
                overlay={
                  <Menu onClick={handleTabBarExtraMenuClick}>
                    <Menu.Item>导入</Menu.Item>
                    {session.permissions.some(
                      (item: any) => item === "导出线索"
                    ) && <Menu.Item key={"export"}>导出</Menu.Item>}
                  </Menu>
                }
              >
                <Button>
                  更多
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          }
        >
          {getTabPane("未分配", "-1", unassignedRef, "unassigned")}
          {getTabPane("已分配", "0", assignedRef, "assigned")}
        </Tabs>
      </Spin>{" "}
      <Modal
        title="新建线索"
        width={800}
        bodyStyle={{ height: 400, overflowY: "auto" }}
        visible={addLeadVisible}
        onCancel={() => {
          setAddLeadVisible(false);
          tableGridRef.current.refresh();
        }}
        onOk={() => {
          tableGridRef.current.save();
        }}
      >
        <TableGrid
          onChange={(data, setValue) => {
            console.log(data);
          }}
          ref={tableGridRef}
          entityName="crm_clue"
          columnCount={2}
          onSaved={() => {
            setAddLeadVisible(false);
            unassignedRef.current?.refresh();
          }}
        />
      </Modal>
      <Modal
        title="确定要关闭选中的线索吗？"
        width={250}
        visible={closeForm.visible}
        onCancel={() => {
          setCloseForm((prevState) => ({
            ...prevState,
            visible: false,
            id: "",
          }));
        }}
        onOk={() => {
          const currentRef = getCurrentRef();
          axios
            .post<Result<string>>("/crm/clue/close", {
              ids: currentRef.current.getSelectedIds().join(","),
              reason: closeForm.id,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success(resp.data.Data);
                currentRef.current.refresh();
                setCloseForm((prevState) => ({
                  ...prevState,
                  visible: false,
                  id: "",
                }));
              } else {
                message.error(resp.data.Data);
              }
            });
        }}
      >
        <DictionarySelect
          style={{ width: 188, marginLeft: 8 }}
          entityName="dict_crm_clue_closereason"
          value={closeForm.id}
          onChange={(value: any) => {
            setCloseForm((prevState) => ({
              ...prevState,
              id: value,
            }));
          }}
        />
      </Modal>
      <UserSelect
        visible={userSelectVisible}
        multiple={false}
        defaultSelectedUsers={[]}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
        onOk={(selectedUsers: any[]) => {
          const currentRef = getCurrentRef();
          axios
            .post<Result<string>>("/crm/clue/distribution", {
              newAdvisorId: selectedUsers[0].id,
              ids: currentRef.current.getSelectedIds().join(","),
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("分配成功");
                currentRef.current.refresh();
              } else {
                message.error(resp.data.Data);
              }
            });
          setUserSelectVisible(false);
        }}
      />
    </>
  );
}
