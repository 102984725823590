import { message } from "antd";
import { CustomerContact } from "./schema";

export function validateTableGridSave(data: CustomerContact) {
  if (!data.content) {
    message.error("联系内容不能为空");
    return false;
  } else if (!data.contactTime) {
    message.error("联系时间不能为空");
    return false;
  } else if (!data.stage) {
    message.error("联系阶段不能为空");
    return false;
  } else if (!data.contactWay) {
    message.error("联系方式不能为空");
    return false;
  }
  return true;
}
