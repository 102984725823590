import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader
} from "antd";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import i18n from "i18next";


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState();


    return (
        <div>
            <PageHeader
                title={i18n.t("地域成交量标签统计")}
            />
            <Row gutter={[24, 8]}>
                <Space>

                    <Form form={form} style={{marginLeft: 10}}>
                        <Row>
                            <Space>

                                <Form.Item name={"range"}>
                                    <DatePicker picker="month"/>
                                </Form.Item>

                                <Form.Item name={"city"}>
                                    <DictionarySelect isTrans={true} entityName={"dict_country"} style={{width:100}}/>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => {
                                        var city = form.getFieldValue("city")
                                        var ranges: any = form.getFieldValue("range")
                                        const temp: any = {};
                                        if (ranges) {
                                            temp.startDate = ranges.format("YYYY-MM");
                                        }
                                        if (city) {
                                            temp.city = city;
                                        }

                                        setSearchData(temp)

                                    }}>{i18n.t("搜索")}</Button>
                                    <Button onClick={() => {
                                        form.setFieldsValue({city: undefined, range: undefined})

                                    }}>{i18n.t("重置")}</Button>
                                </Form.Item>
                            </Space>
                        </Row>
                    </Form>

                </Space>
            </Row>


            <DataGrid isShowSearch={true} url={"/clothes/statistic/getSaleAmountAreaLabel"} searchData={searchData}
                      descriptions={[
                          {
                              fieldName: "docDate",
                              fieldShowName: i18n.t("时间",)
                          },
                          {
                              fieldName: "name",
                              fieldShowName: i18n.t("地域",)
                          },
                          {
                              fieldName: "caName",
                              fieldShowName: i18n.t("标签",)
                          },

                          {
                              fieldName: "count",
                              fieldShowName: i18n.t("成交量",)
                          },

                      ]}/>

        </div>
    );
}