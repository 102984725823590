import React, { useState, useEffect, useCallback } from "react";
import { Button, Input, Spin, List } from "antd";
import { instance as axios } from "../../../../../../../util/axios";
import { FeedsItem } from "./FeedsItem";
import { FlexDiv } from "../../../../../../../components/style";
import { ProjectFeed } from "../../../../../../../schemas/projecrFeed";
import { Result } from "../../../../../../../schemas/common";

const { TextArea } = Input;
export interface ProjectFeedsProps {
  projectId: string;
}

export const ProjectFeeds = ({ projectId }: ProjectFeedsProps) => {
  const [addForm, setAddForm] = useState({
    content: "",
  });
  const [list, setList] = useState<{
    data: ProjectFeed[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const getListData = useCallback(
    async (pageSize, pageIndex) => {
      let data = {
        projectId: projectId,
        pageSize: pageSize,
        pageIndex: pageIndex,
      };
      setLoading(true);
      const resp = await axios.post<
        Result<{
          pageInfo: {
            pageNumbers: number;
            pageSize: string;
            pageIndex: string;
          };
          feedList: ProjectFeed[];
        }>
      >("/crm/crm/project/getProjectFeed", data);
      let pageNumbers = resp.data.Data.pageInfo.pageNumbers;
      let total: number =
        pageNumbers * parseInt(resp.data.Data.pageInfo.pageSize);
      setList((prevState) => ({
        ...prevState,
        data: resp.data.Data.feedList,
        total: total,
      }));
      setLoading(false);
    },
    [projectId]
  );

  useEffect(() => {
    getListData(pageSize, pageIndex).then();
  }, [getListData, pageIndex, pageSize]);

  return (
    <>
      <div style={{ background: "#e6f7ff", padding: 10 }}>
        <TextArea
          rows={4}
          placeholder="动态"
          value={addForm.content}
          onChange={(e) => {
            setAddForm({
              ...addForm,
              content: e.target.value,
            });
          }}
        />
        <FlexDiv style={{ paddingTop: 10 }}>
          <Button
            loading={submitting}
            onClick={() => {
              if (addForm.content) {
                setSubmitting(true);
                axios
                  .post<Result<void>>("/crm/crm/project/addProjectFeed", {
                    content: addForm.content,
                    projectId: projectId,
                  })
                  .then((resp) => {
                    if (resp.data.Status === "1") {
                      setAddForm({
                        content: "",
                      });
                      setSubmitting(false);
                      getListData(pageSize, pageIndex).then();
                    }
                  });
              }
            }}
          >
            确定
          </Button>
        </FlexDiv>
      </div>
      <Spin spinning={loading}>
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            total: list.total,
            current: pageIndex,
            onChange: (page, pageSize) => {
              setPageIndex(page);
              getListData(pageSize, page).then();
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
              getListData(size, current).then();
            },
            pageSize: pageSize,
          }}
          dataSource={list.data}
          renderItem={(item: any) => (
            <List.Item key={item.uuid}>
              <FeedsItem data={item} />
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};
