import React, { CSSProperties, useEffect, useState } from "react";
import {Divider, Input, message, Select} from "antd";
import { Dictionary } from "../../schemas/dictionary";
import { cacheableInstance as axios } from "../../util/axios";
import { Pager } from "../DataGrid/schemas";
import { BaseURL } from "../../constants/url";
import { Result } from "../../schemas/common";
import { useUpdateEffect } from "react-use";
import {isEmpty} from "../../containers/App/screens/Clothes/util/Common";
import i18n from "i18next";
import {PlusOutlined} from "@ant-design/icons/lib";

function mapper(item: any) {
    return {
        text: item.name,
        value: item.uuid,
    };
}
export interface DictionarySelectPropses {
    onChange?: (value: any, option: any) => void;
    entityName: string;
    filter: string;
    style?: CSSProperties;
    value?: string | string[];
    defaultValue?: string | string[];
    size?: "small" | "middle" | "large";
    placeholder?: string;
    multiple?: boolean;
    disabled?: boolean;
    textIndex?: string | null;
    addAble?:boolean;
    onClick?:()=>void;
    refresh?:number;
}

async function getData(
    entityName: string,
    filter: string,
    keyword: string | null,
    selectId: string | string[] | null,
    pagination: Pager | null,
    textIndex: string | null
): Promise<Dictionary[]> {
    const url = `${BaseURL}/oa/common/CommonApi/GetZLSelectDict`;
    const queryString = `pageIndex=${
        pagination ? pagination.pageIndex : ""
    }&pageSize=${
        pagination ? pagination.pageSize : ""
    }&dictionaryName=${entityName}&key=${keyword || ""}&selectId=${
        typeof selectId === "string"
            ? selectId
            : selectId instanceof Array
            ? selectId.join(",")
            : ""
    }&filter=${selectId ? "setValue" : filter}&colName=${textIndex || ""}`;
    return (
        await axios.get<Result<{ uuid: string; name: string }[]>>(
            `${url}?${queryString}`
        )
    ).data.Data.map(mapper);
}
const initialPagination = { pageIndex: 0, pageSize: 10 };
export const DictionarySelects = ({
                                     entityName,
                                     filter,
                                     onChange,
                                     placeholder,
                                     size,
                                     style,
                                     value,
                                     defaultValue,
                                     multiple,
                                     disabled,
                                     textIndex,
                                     addAble=false,
                                      onClick,
    refresh
                                 }: DictionarySelectPropses) => {
    const { Option } = Select;
    const [dataSource, setDataSource] = useState<Dictionary[]>([]);
    const [keyword, setKeyword] = useState<string>("");
    const [pagination, setPagination] = useState<Pager>(initialPagination);
    const [addItem, setAddItem] = useState("");
    const [loadSuccess, setLoadSuccess] = useState(false);

    // init
    useEffect(() => {
        console.log(refresh)
        getData(entityName,filter, null, null, initialPagination, textIndex || null).then(
            (resp) => {
                if (value) {
                    getData(entityName,filter, null, value, null, textIndex || null).then(
                        (valueResp) => {
                            const result = [...resp];
                            valueResp.forEach((i) => {
                                if (!resp.some((j) => j.value === i.value)) {
                                    result.push(i);
                                }
                            });
                            setDataSource(result);
                            setLoadSuccess(true);
                        }
                    );
                } else {
                    setDataSource(resp);
                }
            }
        );
    }, [entityName,filter, textIndex, value,refresh]);
    const [timeout, setTimeoutt] = useState(0);

    // on scroll
    useUpdateEffect(() => {
        if (timeout > 0) {
            console.log("set time out")
            clearTimeout(timeout);
            setTimeoutt(0);
        }
        let time:any = setTimeout(getCurrentData, 300);
        setTimeoutt(time);
    }, [pagination,refresh]);

    async function getCurrentData(){
        const resp = await getData(entityName,filter, keyword, null, pagination, null);
        setDataSource((prev: Dictionary[]) => {
            const result = [...prev];
            resp.forEach((i) => {
                if (!prev.some((j) => j.value === i.value)) {
                    result.push(i);
                }
            });
            return result;
        });
    }

    // on search
    useUpdateEffect(() => {
        setPagination((prevState) => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [keyword,refresh]);

    return (
        <>
            <Select
                onClick={()=>{
                    if(onClick){
                        onClick();
                    }
                }}
                dropdownMatchSelectWidth={false}
                style={style}
                disabled={disabled || false}
                placeholder={placeholder || i18n.t("请选择")}
                showSearch
                size={size || "middle"}
                mode={multiple ? "multiple" : undefined}
                onPopupScroll={(event) => {
                    const scrollDistance =
                        event.currentTarget.scrollHeight - event.currentTarget.scrollTop;
                    console.log(scrollDistance)
                    if (scrollDistance >= event.currentTarget.clientHeight) {
                        let pageSize = 10;
                        if(pagination.pageSize!=undefined){
                            pageSize = pagination.pageSize;
                        }
                        if(dataSource.length>=((pagination.pageIndex+1)*pageSize)){
                            setPagination((prev) => {
                                let current = Object.assign({}, prev);
                                current.pageIndex++;
                                return current;
                            });
                        }else if(pagination.pageIndex>0){
                            setPagination((prev) => {
                                let current = Object.assign({}, prev);
                                current.pageIndex--;
                                return current;
                            });
                        }
                    }
                }}
                onChange={onChange}
                filterOption={false}
                defaultValue={defaultValue}
                value={loadSuccess?value:""}
                onSearch={(val) => {
                    setKeyword(val);
                    setDataSource([]);
                    setPagination({ pageIndex: 0, pageSize: 10 });
                }}
                dropdownRender={addAble?menu =>
                    (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Input style={{ flex: 'auto' }} value={addItem} onChange={value=>{
                                    setAddItem(value.target.value);
                                }}/>
                                <a
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={()=>{
                                        if(isEmpty(addItem)){
                                            message.error(i18n.t("public_emptyContent"));
                                            return;
                                        }
                                        var newItemList = [];
                                        var newItem:any = {};
                                        newItem.isNew = true;
                                        newItem.hasChanges = true;
                                        newItem.name = addItem;
                                        newItemList.push(newItem);
                                        axios.post("oa/dictionary/dictionaryName/saveDictionaryList?tableName="+entityName,{data:JSON.stringify(newItemList)}).then((resp)=>{
                                            if(resp.data.Status == 1){
                                                message.success(i18n.t("public_addSuccess"))
                                            }
                                        })
                                        setDataSource([]);
                                        setKeyword("");
                                        setPagination(initialPagination);
                                        setAddItem("");
                                        getData(entityName,filter+" AND 1!="+parseInt((new Date().getTime() / 1000).toString()), null, null, initialPagination, textIndex || null).then(
                                            (resp) => {
                                                if (value) {
                                                    getData(entityName,filter, null, value, null, textIndex || null).then(
                                                        (valueResp) => {
                                                            const result = [...resp];
                                                            valueResp.forEach((i) => {
                                                                if (!resp.some((j) => j.value === i.value)) {
                                                                    result.push(i);
                                                                }
                                                            });
                                                            setDataSource(result);
                                                        }
                                                    );
                                                } else {
                                                    setDataSource(resp);
                                                }
                                            }
                                        );
                                    }}
                                >
                                    <PlusOutlined /> Add item
                                </a>
                            </div>
                        </div>
                    ) :undefined}
            >
                {dataSource.map((item: Dictionary) => {
                    return (
                        <Option value={item.value} key={item.value}>
                            {item.text}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};
