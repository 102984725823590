import React from "react";
import { Form, Input, DatePicker, InputNumber } from "antd";
import { DictionarySelect } from "../../../DictionarySelect";
import MaterialSelect from "../../../MaterialSelect";
import {DictionarySelects} from "../../../DictionarySelectFilter";
import {formatMoney} from "../../../../containers/App/screens/Clothes/util/FormatMoney";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: number;
  record: any;
  index: number;
  dictionary: string;
  filter: string;
  children: React.ReactNode;
  onChange:any;
  keepRight:boolean;
  readOnly:boolean;
  editRender:Function;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  dictionary,
  filter,
  children,
    onChange,
    keepRight,
    readOnly, editRender,
  ...restProps
}) => {
  let inputNode;
  if(editRender){
    inputNode = editRender();
  }else{
    switch (inputType) {
      case 6:
        switch (dictionary) {
          case "inventory_sku":
            inputNode = (
                <MaterialSelect onChange={onChange} />
            );
            break;
          case "clothes_working_procedure":
            inputNode = (
                <DictionarySelect disabled={readOnly} value={typeof record.ref[dataIndex]=="string"?record.ref[dataIndex].split(","):record.ref[dataIndex]} multiple={true} entityName={dictionary} onChange={onChange} />
            );
            break;
          default:
            if(filter!=undefined&&filter!=""){
              inputNode = (
                  <DictionarySelects disabled={readOnly} value={record[dataIndex]} entityName={dictionary} onChange={onChange} filter={filter}/>
              );
            }else{
              inputNode = (
                  <DictionarySelect disabled={readOnly} value={record[dataIndex]} entityName={dictionary} onChange={onChange} />
              );
            }

        }
        break;
      case 4:
        inputNode = (
            <DatePicker
                onChange={onChange}
                disabled={readOnly}
                value={record[dataIndex]}
                format="YYYY-MM-DD"
            />
        );
        break;
      case 5:
        inputNode = (
            <DatePicker
                onChange={onChange}
                disabled={readOnly}
                value={record[dataIndex]}
                format="YYYY-MM-DD HH:mm:ss"
                showTime
            />
        );
        break;
      case 2:
        inputNode = <InputNumber disabled={readOnly} onChange={onChange}/>;
        children = formatMoney(record.ref[dataIndex]+"",2,"","","");
        break;
      default:
        inputNode = <Input readOnly={readOnly} onChange={onChange}/>;
        break;
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0,width:"100%" }}
          // rules={[{ required: false, message: `请填写 ${title} !` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
          <div style={keepRight?{textAlign:"right"}:undefined}>
            {children}
          </div>
      )}
    </td>
  );
};

export default EditableCell;
