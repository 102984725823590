import React, {useRef, useState} from 'react'
import {Modal, Table, Input, message, Row, Col, Form, Tree} from 'antd'
import {DataGrid} from "../../../../../../components/DataGrid";
import {inputType} from "../../../../../../constants/inputType";
import {isEmpty, isNullOrUndefined} from "../../util/Common";
import {useWindowSize} from "react-use";
import i18n from "i18next";

interface FormProps {
	visible: boolean,
	onCancel: () => void,
	onOk: (v:any) => void,
}


export default ({visible, onCancel, onOk}: FormProps) => {
	const formRef = useRef<any>()
	const [rowsData, setRowsData] = useState()
	const [searchData, setSearchData] = useState<any>({})
	const [showData, setShowData] = useState<any>(false)
	const { height } = useWindowSize();

	const columns = [
		{
			fieldShowName: i18n.t('客户名称',),
			fieldName: 'name',
		},
		{
			fieldShowName: i18n.t('公司名称',),
			fieldName: 'shortName',
		},
		{
			fieldShowName: i18n.t('品牌',),
			fieldName: 'brand',
		},
	]
	const rowSelection = {
		onChange: (selectedRowKeys:any, selectedRows:any) => {
			setRowsData(selectedRows)
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		}
	}
	
	const getCustomerData = (v:any) => {
		console.log('获取客户', v.target.value)
	}

	return (
		<Modal
			getContainer={false}
			style={{top: '20px'}}
			width={'1000px'}
			visible={visible}
			title={i18n.t("客户选择")}
			okText={i18n.t("确定")}
			cancelText={i18n.t("取消")}
			onCancel={onCancel}
			onOk={() => {
				var data = formRef.current.getSelectedRows();
				if (data.length < 1) {
					message.warning(i18n.t('请选择客户'));
					return false;
				}
				onOk(data)
			}}
			bodyStyle={{height: 550}}
			maskClosable={false}
		>
			<Form>
				<Row gutter={24}>
					<Col span={8}>
						<Form.Item
							name="name"
							label={i18n.t("客户名称")}
						>
							<Input onChange={(v) => {
								let tempSearchParam = {...searchData};
								setSearchData(null);
								if (isEmpty(v.target.value)) {
									delete tempSearchParam.searchField_string_name;
								} else {
									tempSearchParam.searchField_string_name = "1|" + v.target.value;
								}
								setSearchData(tempSearchParam);
							}}
								   allowClear={true}

							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="shortName"
							label={i18n.t("公司名称")}
						>
							<Input onChange={(v) => {
								let tempSearchParam = {...searchData};
								setSearchData(null);
								console.log(11)
								if (isEmpty(v.target.value)) {
									delete tempSearchParam.searchField_string_shortName;
								} else {
									tempSearchParam.searchField_string_shortName = "1|" + v.target.value;
								}
								setSearchData(tempSearchParam);
							}}
								   allowClear={true}

							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="brand"
							label={i18n.t("品牌")}
						>
							<Input onChange={(v) => {
								let tempSearchParam = {...searchData};
								setSearchData(null);
								if (isEmpty(v.target.value)) {
									delete tempSearchParam.searchField_string_brand;
								} else {
									tempSearchParam.searchField_string_brand = "1|" + v.target.value;
								}
								setSearchData(tempSearchParam);
							}}
								   allowClear={true}

							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<div className={"u-goods-table-photo"}>
				<Tree
					defaultCheckedKeys={["3"]}
					className={"u-form-photo-l"}
					showLine
					defaultExpandedKeys={['0-0-0']}
					onSelect={(v) => {
						var key: any = v[0];

						let tempSearchParam = {...searchData};
						setSearchData(null);
						if (isEmpty(key)) {
							delete tempSearchParam.searchField_string_customerType;
						} else {
							tempSearchParam.searchField_string_customerType = "1|" + key;
						}
						setShowData(true)
						setSearchData(tempSearchParam);

					}}
					treeData={[
						{
							title: i18n.t('物流运输',),
							key: '1',
						},
						{
							title: i18n.t('中介',),
							key: '2',
						},
						{
							title: i18n.t('供应商',),
							key: '3',
						},
						{
							title: i18n.t('客户',),
							key: '4',
						},
						{
							title: i18n.t('加工厂',),
							key: '5',
						},
						{
							title: i18n.t('其他',),
							key: '6',
						},
					]}
				/>

				{showData && <DataGrid ref={formRef} descriptions={columns} url={"/clothes/customerManage/getCustomerList"}
						  rowSelectionType={"radio"} searchData={searchData} isShowSearch={false} clickToSelected={true}
						  style={{position: "absolute", marginLeft: 200, width: 840}}
				/>}

			</div>
		</Modal>
	);
}