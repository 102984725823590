import React, { useState } from "react";
import {
  Progress,
  Space,
  Button,
  Popconfirm,
  message,
  Mentions,
  Comment,
  Avatar,
} from "antd";
import { AvatarWrapper } from "../../../../../components/AvatarWrapper";
import { Like } from "../Customer/Contact/List/components/Like";
import {
  EditOutlined,
  DeleteOutlined,
  ContactsOutlined,
  MessageOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { instance as axios } from "../../../../../util/axios";
import { CustomerContactDetailDialog } from "./CustomerContactDetailDialog";
import { BaseURL } from "../../../../../constants/url";
import { Result } from "../../../../../schemas/common";
import { FlexDiv } from "../../../../../components/style";

export interface ListItemProps {
  data: any;
  editable?: boolean;
  entityName?: string;
  onChange: () => void;
}
export const ContactListItem = ({
  data,
  onChange,
  entityName,
  editable = true,
}: ListItemProps) => {
  const [detailForm, setDetailForm] = useState({
    visible: false,
    id: "",
  });
  const [commentExpanded, setCommentExpanded] = useState<boolean>(false);
  const [commentContent, setCommentContent] = useState("");
  return (
    <>
      <FlexDiv>
        <div style={{ float: "left", marginRight: 30 }}>
          <Progress type="circle" percent={data.stage} width={70} />
        </div>
        <div style={{ flex: 1, paddingTop: 5 }}>
          <FlexDiv>
            <span style={{ marginRight: 30 }}>{data.contactTime}</span>
            <span style={{ flex: 1 }}>
              <AvatarWrapper
                userId={data.creatorId}
                userName={data.creatorId}
              />
            </span>
            {editable && (
              <Space>
                <Button
                  type={"link"}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setDetailForm({
                      ...detailForm,
                      visible: true,
                      id: data.uuid,
                    });
                  }}
                />
                <Popconfirm
                  title="确定要删除吗?"
                  onConfirm={() => {
                    const url = `/crm/crm/contact/deleteContact?ids=${data.uuid}`;
                    axios.delete(url).then((resp) => {
                      if (resp.data.Status === "1") {
                        message.info("删除成功");
                        onChange?.();
                      } else {
                        message.error("删除失败");
                      }
                    });
                  }}
                >
                  <Button type={"link"} icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            )}
          </FlexDiv>
          <div>{data.content}</div>
          <FlexDiv style={{ justifyContent: "space-between" }}>
            <span>
              <ContactsOutlined /> {data.customerName}
            </span>
            <div>
              <Button
                type="link"
                icon={<MessageOutlined style={{ marginRight: 4 }} />}
                style={{
                  paddingRight: 0,
                  color: data.commentNumber > 0 ? "#1890ff" : "#000000a6",
                }}
                onClick={() => {
                  setCommentExpanded(!commentExpanded);
                }}
              >
                {data.commentNumber}
              </Button>
              <Like
                count={data.likeNumber}
                uuid={data.uuid}
                liked={data.isLike}
                onLike={() => {
                  onChange?.();
                }}
              />
            </div>
          </FlexDiv>
          {commentExpanded && (
            <div style={{ background: "#e6f7ff", padding: 10 }}>
              <FlexDiv>
                <Mentions
                  style={{ width: "100%" }}
                  value={commentContent}
                  onChange={(value) => setCommentContent(value)}
                  onSelect={() => {}}
                  defaultValue=""
                />
                <Button
                  type="link"
                  style={{ marginLeft: 5 }}
                  icon={<SendOutlined rotate={-40} style={{ fontSize: 24 }} />}
                  onClick={() => {
                    axios
                      .post<Result<void>>("/oa/comment/comment/add", {
                        dataType: "联系记录",
                        dataId: data.uuid,
                        content: commentContent,
                      })
                      .then((resp) => {
                        if (resp.data.Status === "1") {
                          message.info("保存成功");
                          setCommentContent("");
                          onChange?.();
                        }
                      });
                  }}
                />
              </FlexDiv>
              {data.commentList?.map((item: any) => (
                <Comment
                  key={item.time}
                  author={item.name}
                  avatar={
                    <Avatar
                      src={`${BaseURL}/oa/common/attachment/showAvatar?userId=${item.advisorId}`}
                    />
                  }
                  content={<p>{item.content}</p>}
                  datetime={<span>{item.time}</span>}
                />
              ))}
            </div>
          )}
        </div>
      </FlexDiv>
      <CustomerContactDetailDialog
        visible={detailForm.visible}
        entityName={entityName}
        uuid={detailForm.id}
        onCancel={() => {
          setDetailForm({
            ...detailForm,
            visible: false,
          });
        }}
        onOk={() => {
          onChange?.();
          setDetailForm({
            ...detailForm,
            visible: false,
          });
        }}
      />
    </>
  );
};
