import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, List, message, Popconfirm, Radio, Space, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../../util/axios";
import { DataGrid } from "../../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { ContactListItem } from "../../../components/ContactListItem";
import { CustomerContactDetailDialog } from "../../../components/CustomerContactDetailDialog";
import { FieldDescription } from "../../../../../../../schemas/fieldDescriptions";
import { get } from "../../../../../../../util/description";
import { getConfigByName } from "../../../../../../../util/config";
import { AddContact } from "../../../components/AddContact";
import { Result } from "../../../../../../../schemas/common";
import { Like } from "../../../Customer/Contact/List/components/Like";
import { Comment } from "../../../Customer/Contact/List/components/Comment";
import { ConfigName } from "../../../../../../../enum";

export interface ContactListProps {
  chanceId: string;
}

export const ContactList = ({ chanceId }: ContactListProps) => {
  const [list, setList] = useState<{
    data: {
      uuid: string;
      isLike: boolean;
      likeNumber: number;
      commentNumber: number;
      content: string;
      advisorId: string;
      contactTime: string;
      stage: string;
      commentList: {
        name: string;
        content: string;
        time: string;
      }[];
    }[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const gridRef = useRef<any>(null);
  const { height } = useWindowSize();
  const [editForm, setEditForm] = useState({
    visible: false,
    id: "",
  });
  /**
   * list为平铺，table为列表
   */
  const [displayType, setDisplayType] = useState<"list" | "table">("list");
  const getListData = useCallback(
    async (pageSize, pageIndex) => {
      let data = {
        chanceId: chanceId,
        pageSize: pageSize,
        pageIndex: pageIndex,
      };
      setLoading(true);
      const resp = await axios.post(
        "/crm/crm/contact/getChanceContactTile",
        data
      );
      let pageNumbers = resp.data.Data.pageInfo.pageNumbers;
      let total: number = pageNumbers * resp.data.Data.pageInfo.pageSize;
      setList((prevState) => ({
        ...prevState,
        data: resp.data.Data.ChanceContactTileList,
        total: total,
      }));
      setLoading(false);
    },
    [chanceId]
  );

  useEffect(function () {
    get("crm_sale_chance_contact").then((columns: FieldDescription[]) => {
      const descriptionsCopy = JSON.parse(JSON.stringify(columns));

      descriptionsCopy.unshift({
        fieldShowName: "操作",
        render: (text: any, record: any) => {
          return (
            <>
              <Space>
                <Button
                  type={"link"}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setEditForm((prevState) => ({
                      ...prevState,
                      visible: true,
                      id: record.key,
                    }));
                  }}
                />
                <Popconfirm
                  title="确定要删除吗?"
                  onConfirm={() => {
                    const url = `/crm/crm/contact/deleteContact?ids=${record.key}`;
                    axios.delete<Result<void>>(url).then((resp) => {
                      if (resp.data.Status === "1") {
                        message.info("删除成功");
                        gridRef.current.refresh();
                      } else {
                        message.error(resp.data.Message);
                      }
                    });
                  }}
                >
                  <Button type={"link"} icon={<DeleteOutlined />} />
                </Popconfirm>
                <Comment
                  count={record.commentNumber}
                  uuid={record.uuid}
                  onComment={() => {
                    gridRef.current.refresh();
                  }}
                />
                <Like
                  count={record.likeNumber}
                  uuid={record.uuid}
                  liked={record.isLike}
                  onLike={() => {
                    gridRef.current.refresh();
                  }}
                />
              </Space>
            </>
          );
        },
        align: "center",
        fixed: true,
        width: 200,
      });
      getConfigByName(ConfigName.HideProductInContactRecord).then((config) => {
        if (!config || config.value !== "true") {
          descriptionsCopy.push({
            fieldShowName: "意向产品",
            fieldName: "intentionCategory",
          });
        }
        setDescriptions(descriptionsCopy);
      });
    });
  }, []);
  useEffect(() => {
    getListData(pageSize, pageIndex).then();
  }, [getListData, pageIndex, pageSize]);

  return (
    <>
      <AddContact
        host="chance"
        id={chanceId}
        onOk={() => {
          if (displayType === "list") {
            getListData(pageSize, pageIndex).then();
          } else {
            gridRef.current?.refresh();
          }
        }}
      />
      <Space style={{ margin: "30px 0 20px" }}>
        <Radio.Group
          onChange={(e) => {
            setDisplayType(e.target.value);
            if (e.target.value === "list") {
              getListData(pageSize, pageIndex).then();
            } else {
              gridRef.current?.refresh();
            }
          }}
          defaultValue="list"
        >
          <Radio.Button value="list">平铺</Radio.Button>
          <Radio.Button value="table">列表</Radio.Button>
        </Radio.Group>
      </Space>
      {displayType === "list" && (
        <Spin spinning={loading}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              total: list.total,
              current: pageIndex,
              onChange: (page, pageSize) => {
                setPageIndex(page);
                getListData(pageSize, page).then();
              },
              onShowSizeChange: (current, size) => {
                setPageSize(size);
                getListData(size, current).then();
              },
              pageSize: pageSize,
            }}
            dataSource={list.data}
            renderItem={(item: any) => (
              <List.Item key={item.uuid} actions={[]}>
                <ContactListItem
                  data={item}
                  entityName="crm_sale_chance_contact"
                  onChange={() => getListData(pageSize, pageIndex).then()}
                />
              </List.Item>
            )}
          />
        </Spin>
      )}
      {displayType === "table" && (
        <DataGrid
          style={{ marginBottom: 8 }}
          pageSize={Math.floor((height - 280) / 42)}
          pagination={true}
          ref={gridRef}
          sorter={{ field: "createTime", order: "descend" }}
          descriptions={descriptions}
          url={`/crm/crm/contact/getContact?chanceId=${chanceId}`}
        />
      )}
      <CustomerContactDetailDialog
        visible={editForm.visible}
        uuid={editForm.id}
        entityName="crm_sale_chance_contact"
        onCancel={() => {
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
        onOk={() => {
          if (displayType === "list") {
            getListData(pageSize, 1).then();
          } else {
            gridRef.current?.refresh();
          }
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
      />
    </>
  );
};
