import React, { useEffect, useState, useCallback } from "react";
import { Button, Space, message } from "antd";
import { FileSearchOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../../util/axios";
import { DataGrid } from "../../../../../../components/DataGrid";
import { get } from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { OperationButtonGroup } from "./OperationButtonGroup";
import { Description } from "../../../../../../schemas/description";
import { createTimeDescription } from "../../../../../../constants/descriptions";
import { DynamicTabBuiltIn, EntityName } from "../../../../../../type";
import { createNewTab } from "../../../../../../util/tyy";

export interface DynamicTabProps {
  id?: string;
  advisorId?: string;
  host?: string;
  editable?: boolean;
  data: {
    uuid: string;
    formName: string;
    entityName: EntityName;
    builtin?: DynamicTabBuiltIn;
    maxFormNumber?: number;
    createFrom?: string;
    workflowTemplateId?: string;
    isCreatable?: boolean;
  };
}
const defaultColumns = [
  {
    fieldName: "serialNumber",
    fieldShowName: "单号",
    width: 100,
  },
  createTimeDescription,
  {
    fieldName: "currentState",
    fieldShowName: "当前状态",
    width: 100,
  },
  {
    fieldName: "formName",
    fieldShowName: "表单名称",
    width: 100,
  },
];
export const DynamicTab = ({
  id,
  advisorId,
  data,
  host = "customer",
  editable = true,
}: DynamicTabProps) => {
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  const create = useCallback(
    function (
      workflowTemplateId?: string,
      formName?: string,
      dynamicTabId?: string,
      createFrom?: string,
      createFormTab?: string
    ) {
      let url = `/wf/form/vsheet/form?workflowTemplateId=${workflowTemplateId}&id=0&${host}Id=${id}&advisorId=${advisorId}`;
      axios
        .get(
          `/crm/dynamicTab/getFormParameter?dynamicTabId=${dynamicTabId}&createFrom=${
            createFrom || ""
          }&hostMajorKey=${id}&createFormTab=${createFormTab || ""}`
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            if (workflowTemplateId) {
              url += resp.data.Data;
              createNewTab(url, formName);
            } else {
              message.error(resp.data.Data);
            }
          } else {
            message.error(resp.data.Data);
          }
        });
    },
    [advisorId, host, id]
  );

  const open = useCallback(
    (workflowTemplateId: string, id: string, formName?: string) => {
      let url = `/wf/form/vsheet/form?workflowTemplateId=${workflowTemplateId}&id=${id}&${host}Id=${id}&advisorId=${advisorId}`;
      if (workflowTemplateId) {
        createNewTab(url, formName);
      } else {
        //tablegrid
      }
    },
    [advisorId, host]
  );

  useEffect(
    function () {
      get(data.entityName).then((columns: FieldDescription[]) => {
        let finalColumns: any;
        if (columns.length > 0) {
          finalColumns = columns;
        } else {
          finalColumns = JSON.parse(JSON.stringify(defaultColumns));
        }
        const amountColumn = {
          fieldName: "amount",
          fieldShowName: "金额(元)",
          width: 100,
        };
        if (data.builtin === "order") {
          finalColumns.push(amountColumn);
          finalColumns.push({
            fieldName: "receivedAmount",
            fieldShowName: "已收款金额(元)",
            width: 100,
          });
        } else if (data.builtin === "contract" || data.builtin === "receipt") {
          finalColumns.push(amountColumn);
        } else if (data.builtin === "workorder") {
          finalColumns.push({
            fieldName: "balance",
            fieldShowName: "金额(元)",
            width: 100,
          });
        }
        finalColumns.unshift({
          fieldShowName: "操作",
          render: (text: any, record: any) => {
            return (
              <>
                <Space>
                  <Button
                    type={"link"}
                    onClick={() => {
                      open(
                        record.workflowTemplateId,
                        record.uuid,
                        record.formName
                      );
                    }}
                  >
                    <FileSearchOutlined />
                  </Button>
                  {id && advisorId && (
                    <OperationButtonGroup
                      dynamicTabId={data.uuid}
                      id={id}
                      dataId={record.uuid}
                      advisorId={advisorId}
                      onOk={(form) => {
                        create(
                          form.workflowTemplateId,
                          form.formName,
                          form.uuid,
                          record.uuid,
                          data.uuid
                        );
                      }}
                    />
                  )}
                </Space>
              </>
            );
          },
          align: "center",
          fixed: true,
          width: 100,
        });
        setDescriptions(finalColumns);
      });
    },
    [advisorId, create, data.builtin, data.uuid, data.entityName, id, open]
  );
  return (
    <>
      {descriptions.length > 0 && (
        <div key={data.entityName}>
          {id && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 32,
              }}
            >
              <span>{data.formName}</span>
              {data.maxFormNumber !== 1 &&
                data.createFrom &&
                data.createFrom.indexOf("host") !== -1 &&
                data.isCreatable &&
                editable && (
                  <Button
                    type={"link"}
                    style={{ color: "#1890ff", paddingRight: 0 }}
                    onClick={() => {
                      create(data.workflowTemplateId, data.formName, data.uuid);
                    }}
                  >
                    +{data.formName}
                  </Button>
                )}
            </div>
          )}
          <DataGrid
            style={{ marginBottom: 8 }}
            descriptions={descriptions}
            url={`/crm/dynamicTab/getFormDataList?${host}Id=${
              id || ""
            }&formTableName=${data.entityName}`}
          />
        </div>
      )}
    </>
  );
};
