import {message} from "antd";
import i18n from "i18next";
import {isNull} from "util";
import {isNullOrUndefined} from "../../util/Common";

const isOnEdit =async (dataGridRef : any) =>{
    await saveDataGrid(dataGridRef);
    // var isEdit = dataGridRef.current.isOnEdit();
    // if(isEdit){
    //     message.info(i18n.t("public_saveFirst"));
    // }
    // return false;
    var detailList = dataGridRef.current.getData();
    detailList.map((item:any) => {
        if(isNullOrUndefined(item.skuId)){
            dataGridRef.current.remove(item.key);
        }
    });
}

const saveDataGrid = async (dataGridRef : any)=>{
    if(dataGridRef.current.isOnEdit()){
        await dataGridRef.current.save(dataGridRef.current.getEditingKey());
    }
}

const addLine = async (dataGridRef : any)=>{
    await saveDataGrid(dataGridRef);
    dataGridRef.current.add();
}

const editOtherLine = async (dataGridRef : any,record:any)=>{
    if(dataGridRef.current.isEditing(record)){
        return;
    }
    await saveDataGrid(dataGridRef);
    await dataGridRef.current.edit(record);
}

const removeLine = async (dataGridRef : any,record:any)=>{
    await saveDataGrid(dataGridRef);
    dataGridRef.current.remove(record.key);
}

export {isOnEdit,saveDataGrid,addLine,editOtherLine,removeLine}