import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, message, Button, Space, Input, Modal } from "antd";
import { get, getNameSpaceDescriptions } from "../../../../../util/description";
import { FieldDescription } from "../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../schemas/description";
import { useUpdateEffect, useWindowSize } from "react-use";
import { addLine, editOtherLine, saveDataGrid } from "../components/EditForm";
import { DictionarySelects } from "../../../../../components/DictionarySelectFilter";
import { validateMessages } from "../util/DocumentEvent";
import GetRender from "../Sku/Detail/GetRender";
import "../CSS/sizeIndex.css";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons/lib";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export default () => {
  const [form] = Form.useForm();
  const [detailForm] = Form.useForm();
  const { width, height } = useWindowSize();
  const dataGridRef = useRef<any>(null);
  const [data, setData] = useState<any>([]);
  const [namespace, setNamespace] = useState("clothes_size.all");
  const [entityName, setEntityName] = useState("clothes_size");
  const [templateId, settemplateId] = useState("1");
  const [urlData, setUrlData] = useState("1");
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);
  const [loading, setLoading] = useState(false);
  useEffect(function () {
    let name = form.getFieldValue("name");
  }, []);
  useEffect(
    function () {
      setTemplateId(templateId);
      getOtherTemplate("1");
      getTemplateById("1");
    },
    []
  );
  const getTemplateById = useCallback(function getTemplateById(uuid: string) {
      axios
        .post<Result<any[]>>(`/clothes/size/getTemplateById`, {
          templateId: uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data: any = resp.data.Data;
            if (data != undefined) {
              form.setFieldsValue({
                templateId: data.uuid,
                briefIntroduction: data.briefIntroduction,
                name: data.name,
              });
              setTemplateId(data.uuid);
              detailForm.setFieldsValue({
                uuid:data.uuid,
                name:data.name,
                briefIntroduction: data.briefIntroduction,
              })
            }
          }
        });
  }, []);
  const setTemplateId = useCallback(function setTemplateId(templateId: string) {
    form.setFieldsValue({
      templateId: templateId,
    });
    settemplateId(templateId);
    setUrlData(
      "/clothes/size/getSizeManageListByTemplateId?templateId=" + templateId
    );
  }, []);

  const onFinish = async (values: any) => {
    console.log("values:", values);
    await saveDataGrid(dataGridRef);
    try {
      let sizeList = await dataGridRef.current.getData();
      await axios
        .post<Result<any[]>>(`/clothes/size/saveSizeList`, {
          templateId: templateId,
          sizeList: JSON.stringify(sizeList),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("保存成功"));
            setTimeout(()=>{
              window.location.reload()
            }, 2000);
          }
        });
    } catch (e) {
      message.error(i18n.t("保存失败"));
      setLoading(false);
    }
  };

  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const [tableForm] = Form.useForm();

  function format() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(currentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("操作"),
      fixed: "right",
      render: GetRender.GetRender(dataGridRef, onDetailSave, entityName, false),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const getOtherTemplate = useCallback(function (uuid: string) {
    axios
      .post<Result<any[]>>(`/clothes/size/getOtherTemplate`, {
        templateId: uuid,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          setData(resp.data.Data);
        }
      });
  }, []);

  const onDetailSave = async () => {};
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作",),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("确定删除吗",),
      okText: i18n.t("确定",),
      cancelText: i18n.t("取消",),
      onOk: function () {
        const url = `/clothes/size/deleteTemplateById?templateId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("删除成功"));
            setTimeout(()=>{
              window.location.reload()
            }, 2000);
          } else {
            message.info(i18n.t("删除失败"));
          }
        });
      },
    });
  }, []);
  const [detailVisible, setDetailVisible] = useState(false);
  const hideModal = () => {
    setDetailVisible(false);
  };
  const detailOnOk = () => {
    detailForm.submit();
  };
  const detailElement: ReactNode = (
      <>
        <button
            style={{width: 100}}
            className="ant-btn ant-btn-primary"
            onClick={detailOnOk}
        >
          <span>{i18n.t("保存")}</span>
        </button>
      </>
  );
  const showSizeName = (type:string) => {
    if(type=="add"){
      setDetailVisible(true);
    }else{
      setDetailVisible(true);
      getOtherTemplate(templateId);
    }
  };

  const onDetailFinish = (values:any) => {
    axios
        .post<Result<any[]>>(`/clothes/size/saveTemplate`, {
          data: JSON.stringify(values),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setDetailVisible(false);
            setData([]);
            setTimeout(()=>{
              window.location.reload()
            },3000);
          }
        });
  };

  return (
      <>
        <div className="u-form">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                onFinish={onFinish}
                validateMessages={validateMessages}
                onFinishFailed={(err: any) => {
                  setLoading(false);
                }}
                scrollToFirstError={true}
            >
              <div className="u-ThreCon">
                <Form.Item
                    label={i18n.t("尺寸表名称")}
                    className="size-code"
                    name={"templateId"}
                    {...{labelCol: {span: 7}, wrapperCol: {span: 9}}}
                >
                  <DictionarySelects
                      entityName={"clothes_size_template"}
                      filter={"1 = 1 AND isDelete != 1"}
                      onChange={(value) => {
                        if (dataGridRef.current.isOnEdit()) {
                          dataGridRef.current.cancelEdit();
                          dataGridRef.current.refresh();
                        }
                        setTemplateId(value);
                        getTemplateById(value);
                        getOtherTemplate(value);
                      }}
                  />
                </Form.Item>
                <div className="size-add">
                  <PlusOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        detailForm.resetFields();
                        showSizeName("add");
                      }}
                  />
                </div>
                <div className="size-edit">
                  <EditOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        showSizeName("edit");
                      }}
                  />
                </div>
              </div>
              <div className="u-ThreCon">
                <Form.Item
                    label={i18n.t("尺寸表简介")}
                    className="size-code"
                    name={"briefIntroduction"}
                    {...{labelCol: {span: 7}, wrapperCol: {span: 9}}}
                >
                  <Input readOnly={true}/>
                </Form.Item>
              </div>

              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <DataGrid
                    ref={dataGridRef}
                    url={urlData}
                    descriptions={format()}
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(dataGridRef, record);
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    form={tableForm}
                />
              </Form.Item>
              <div style={{paddingBottom: 25}}>
                <Space>
                  <Button
                      style={{width: 100}}
                      onClick={async () => {
                        await addLine(dataGridRef);
                      }}
                  >
                    {i18n.t("添加")}
                  </Button>
                </Space>
              </div>
              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          // setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                          if (templateId == "1") {
                            message.error(i18n.t("基础模板不能删除"));
                          } else {
                            confirm(templateId);
                          }
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("删除")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
            <Modal
                style={{marginTop: "5%"}}
                className="add-component"
                visible={detailVisible}
                onCancel={hideModal}
                footer={detailElement}
                width={(width * 1) / 3}
                destroyOnClose={true}
                bodyStyle={{height: (height * 1) / 3.5}}
                maskClosable={false}
            >
              <Form
                  {...layout}
                  form={detailForm}
                  layout="horizontal"
                  name="basicForm"
                  autoComplete="off"
                  onFinish={onDetailFinish}
              >
                <Form.Item
                    label={i18n.t("尺寸表简介")}
                    name="uuid"
                    hidden
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    className="size-name"
                    label={i18n.t("尺寸表名称")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        validator: async (rule, value) => {
                          if (value == undefined || value.trim() == "") {
                            message.error(i18n.t("尺寸表名称不能为空"));
                            throw new Error(i18n.t("尺寸表名称不能为空"));
                          }
                          if (data != undefined && data.length > 0) {
                            data.map((item: any) => {
                              if (item.name == value) {
                                message.error(i18n.t("尺寸表名称重复"));
                                throw new Error(i18n.t("尺寸表名称重复"));
                              }
                            });
                          }
                        },
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    label={i18n.t("尺寸表简介")}
                    name="briefIntroduction"
                >
                  <Input/>
                </Form.Item>
              </Form>
            </Modal>
          </Form.Provider>
        </div>
      </>
  );
};
