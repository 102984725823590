import React, {useState} from "react"
import { Upload } from 'antd';
import { 
	PlusOutlined,
	DeleteOutlined,
	SettingOutlined,
	LoadingOutlined
 } from '@ant-design/icons'
import i18n from "i18next";

function getBase64(file:any) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})
}

export default ()=> {
	const [viewImage, setViewImage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [fileList, setFileList] = useState<any[]>([])
	const [isCls, setIsCls] = useState('')

	const handlePreview = (file:any, idx:any) => {
		if (!file.url && !file.preview) {
		  file.preview = getBase64(file.originFileObj)
		}

		setViewImage(file.url || file.thumbUrl)
		setIsCls(idx)
	}

	const handleChange = (v:any) => {
		setIsLoading(true)
		if (!v.file.url && !v.file.preview) {
		  v.file.preview = getBase64(v.file.originFileObj)
		}
		if (v.fileList.length !== 0) {
			setViewImage(v.fileList[0].url || v.fileList[0].thumbUrl)
		}
		// if (v.file.status === 'done') { // 上传成功返回数据
		// 	setFileList(v.fileList)
		//  setIsLoading(false)
		// }
		setFileList(v.fileList)
		setIsLoading(false)
	}
	
	const RemoveDevice = (record:any, idx:any) => {
		const newData = [...fileList]
		for (let i = 0; i < newData.length; i++) { 
			if (record === fileList[i]) {
				newData.splice(idx, 1)
				setFileList(newData)
			}  
		 }
	}

	return (
		<div className="u-imgUpload">
			<div className="u-PreviewImg">
				{fileList.length ? <img alt="" src={viewImage}/> : null}
			</div>
			{fileList.length ?
				<div className={`u-uploadImg ${fileList.length > 3 ? 'u-mr10' : ''}`}>
					{fileList.map((item: any, idx) => (
						<div className={`u-uploadImg-item ${Number(isCls) === idx ? 'u-tip' : null}`} key={idx}>
							{item.url || item.thumbUrl ?
								<>
									<img
										title={item.name}
										alt={item.name}
										src={item.url || item.thumbUrl}
									/>
									<div className="u-uploadImg-btn-shade"></div>
									<div className="u-uploadImg-btn">
										<SettingOutlined
											title={i18n.t("设为默认")}
											className="u-uploadImg-set"
											onClick={() => {
												handlePreview(item, idx)
											}}
										/>

										<DeleteOutlined
											title={i18n.t("删除")}
											className="u-uploadImg-del"
											onClick={() => {
												RemoveDevice(item, idx)
											}}
										/>
									</div>
								</> :
								<LoadingOutlined/>
							}

						</div>
					))}
				</div> :
				<></>
			}
			<Upload
				action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
				listType="picture-card"
				fileList={fileList}
				onChange={handleChange}
			>
				<div>
					{isLoading ? <LoadingOutlined/> : <PlusOutlined/>}
				</div>
			</Upload>
		</div>
	);
}