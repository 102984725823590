import React, {ReactNode} from "react";
import { Description } from "../../schemas/description";
import {SizeType} from "antd/es/config-provider/SizeContext";
import {FormInstance} from "antd/lib/form";

export interface Pager {
  pageIndex: number;
  pageSize: number | undefined;
}

export interface Sorter {
  field: string;
  order: string | undefined;
}

export interface GridProps {
  scrolly?:number;
  pagination?: Boolean;
  pageSize?: number;
  sorter?: Sorter;
  dragable?: boolean;
  scroll?: { x?: true,y?:number };
  descriptions?: Description[];
  entityName?: string;
  url?: string;
  dataSource?: any[];
  rowSelectionType?: "checkbox" | "radio";
  style?: React.CSSProperties;
  onDrop?: Function;
  onDraw?: (row: any) => JSX.Element;
  drawerWidth?: number;
  expressions?: Expression[];
  onSelected?:(row:any)=>void;
  expandedRow?: (row: any) => any;
  searchData?: { [key: string]: string };
  size?: SizeType;
  expandedRowMutuallyExclusive?:boolean;
  expandedRowClassName?:string;
  rowStyle?:string;
  isShowSearch?:boolean;
  loadAllDictionary?:boolean;
  form?:any;
  onClick?:(record:any)=>void
  onValuesChange?:(values: any)=>void
  defaultExpandAllRows?:boolean
  tableSummary?:(row: any) => JSX.Element,
  rowClassName?:(record:any)=>string,
  clickToSelected?:boolean//点击选中当前行,
  isShowExport?:boolean,
  checkboxProps?:(row:any)=>{},
  onSearch?:(row:any)=>void,
  editRender?:()=>ReactNode,
}

export class Expression {
  field: string;
  expression: string;
  format?: (value: string) => string;

  constructor(field: string, expression: string) {
    this.field = field;
    this.expression = expression;
  }
}

export interface GridDataOptions {
  url: string;
  dictionaryMapper: { [key: string]: string };
  pagination?: Boolean;
  pager?: Pager;
  sorter?: Sorter;
  searchForm: { [key: string]: string };
  searchData: { [key: string]: string } | undefined;
  loadAllDictionary?:boolean;
}

export interface CountOptions {
  url: string;
  searchForm: { [key: string]: string };
  expressions?: Expression[];
  searchData?:{ [key: string]: string } | undefined;
}
