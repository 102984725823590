import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Row, Col, message, Spin } from "antd";
import { instance as axios } from "../../../../../util/axios";
import i18n from "i18next";
import { Simulate } from "react-dom/test-utils";

const { Option } = Select;

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface FormProps {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
  data: any;
}

export default ({ visible, onCancel, data, onCreate }: FormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(data);
    form.resetFields();
    form.setFieldsValue(data);
  }, [data]);

  return (
    <Modal
      getContainer={false}
      style={{ top: "20px" }}
      width={"460px"}
      visible={visible}
      title= {i18n.t("additional_address_title")} //"Additional Address"
      okText= {i18n.t("保存")}//"Save"
      cancelText= {i18n.t("cancel")} //"Cancel"
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log(values);
            setLoading(true);
            axios
              .post("/inventory/customerAddress/saveCustomerAddress", values)
              .then((resp) => {
                if (resp.data.Status == 1) {
                  onCreate(values);
                } else {
                  message.error(i18n.t("saveError"));
                }
              })
              .catch(() => {
                message.error(i18n.t("saveError"));
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <div className="u-modalForm">
          <Form
            size="small"
            form={form}
            layout="vertical"
            name="customerAddress"
          >
            <Form.Item name="address" label={i18n.t("company_config_address")}>
              <Input.TextArea
                placeholder={i18n.t("company_config_address")}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </Form.Item>
            <Form.Item name="city" label={i18n.t("company_config_city")}>
              <Input />
            </Form.Item>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="state" label={i18n.t("company_config_state")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="postalCode" label={i18n.t("company_config_postalCode")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="telephone" label={i18n.t("company_config_telephone")}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="fax" label={i18n.t("company_config_fax")}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="uuid" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="customerId" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="addressType" hidden={true}>
              <Input />
            </Form.Item>
          </Form>
          <div style={{ padding: "5px 0 0 0" }}>
            <b>{i18n.t("note")}:</b> {i18n.t("additional_address_note")} {/*// Changes made here will be updated to this contact.*/}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
