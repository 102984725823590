import React, { useCallback, useEffect, useState } from "react";
import {Form, Input, Button, message} from "antd";
import { useHistory } from "react-router";
import Tabs from "../Tabs/Tabs";
import "../../CSS/customer.css";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { CorrespondenceTreeWrapper } from "../../../../../../components/CorrespondenceTree";
import i18n from "i18next";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

export default () => {
  const [form] = Form.useForm();
  const [parent, setParent] = useState("parent");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fristTypeName, setFristTypeName] = useState("全部类目");
  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  useEffect(function () {
    getTypesOfGoodsList(fristTypeName, 1);
  }, []);
  const generateUUID = useCallback(function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
        c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }, []);
  const getTypesOfGoodsList = useCallback(function getTypesOfGoodsList(
    typeName: string,
    type: number
  ) {
    if (type < 4) {
      axios
        .post<Result<any[]>>(`/clothes/sku/getTypesOfGoodsList`, {
          typeName: typeName,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data: any = resp.data.Data;
            if (data != undefined) {
              let arr: any = [];
              data.map((item: any) => {
                arr.push(item.name);
              });
              switch (type) {
                case 1:
                  form.setFieldsValue({
                    fristList: arr,
                  });
                  break;
                case 2:
                  form.setFieldsValue({
                    secondList: arr,
                  });
                  break;
                case 3:
                  form.setFieldsValue({
                    thirdList: arr,
                  });
                  break;
                default:
                  form.setFieldsValue({
                    fristList: arr,
                  });
                  break;
              }
            }
          }
        });
    }
  },
  []);
  return (
      <div className="u-box u-basic-page">
        <div className="u-box-head">
          <span className="u-box-title" onClick={(e) => e.preventDefault()}>
            {i18n.t("商品类别管理")}
          </span>
        </div>
        <div className="u-box-con">
          <div className="sku-Tree-l">
            <CorrespondenceTreeWrapper
                editable={true}
                getTreeDataUrl={`/clothes/treeSelect/getTreeData?type=clothes_sku_category&parent=${parent}`}
                updateUrl={"/clothes/treeSelect/updateCategoryById?tableName=clothes_sku_category"}
                deleteUrl={"/clothes/treeSelect/deleteCategoryById?tableName=clothes_sku_category&uuid="}
                addUrl={"/clothes/treeSelect/addCategory?tableName=clothes_sku_category&uuid="}
                onSelected={(keys) => {
                }}
                showSeacher={true}
            />
          </div>
          {/*<div className="u-Table-r">*/}
          {/*  <Form.Provider>*/}
          {/*    <Form*/}
          {/*      {...layout}*/}
          {/*      form={form}*/}
          {/*      layout="horizontal"*/}
          {/*      name="basicForm"*/}
          {/*      autoComplete="off"*/}
          {/*      style={{marginTop:"10%"}}*/}
          {/*      onFinish={onFinish}*/}
          {/*      onFinishFailed={onFinishFailed}*/}
          {/*    >*/}
          {/*      <Form.Item className="type-goods-form" label="商品品种" name="fristList">*/}
          {/*        <Tabs*/}
          {/*          form={form}*/}
          {/*          isClose={false}*/}
          {/*          type={1}*/}
          {/*          getTypesOfGoodsList={getTypesOfGoodsList}*/}
          {/*        />*/}
          {/*      </Form.Item>*/}
          {/*      <Form.Item className="type-goods-form" label="品种子类" name="secondList">*/}
          {/*        <Tabs*/}
          {/*          form={form}*/}
          {/*          isClose={false}*/}
          {/*          type={2}*/}
          {/*          getTypesOfGoodsList={getTypesOfGoodsList}*/}
          {/*        />*/}
          {/*      </Form.Item>*/}
          {/*      <Form.Item className="type-goods-form" label="子类再分类" name="thirdList">*/}
          {/*        <Tabs*/}
          {/*          form={form}*/}
          {/*          isClose={false}*/}
          {/*          type={3}*/}
          {/*          getTypesOfGoodsList={getTypesOfGoodsList}*/}
          {/*        />*/}
          {/*      </Form.Item>*/}
          {/*    </Form>*/}
          {/*  </Form.Provider>*/}
          {/*</div>*/}
        </div>
      </div>
  );
};
