import React, {ReactDOM, useEffect, useRef, useState} from "react";
import {DataGrid} from "../../../../../components/DataGrid";
import {inputType} from "../../../../../constants/inputType";
import {editOtherLine} from "../components/EditForm";
import i18n from "i18next";
import {Button, Form, message} from "antd";
import {instance as axios} from "../../../../../util/axios";
import {formatNumber} from "../util/FormatMoney";
import {usePermission} from "../../../../../util/permession";

export interface Param {
    docId: string,
    photoUrl: string,
    sizeColumn: any,
}

const ClothesCut = ({docId, photoUrl, sizeColumn}: Param) => {

    console.log("photoUrl",photoUrl)

    const masterMaterialTableRef = useRef<any>();
    const libuMaterialTableRef = useRef<any>();
    const [tableForm] = Form.useForm();
    const [libuForm] = Form.useForm();
    const getIsCanEdit = ()=>{
        return true;
    }

    const getRender = (record: any, gridRef: any, onSave: any) => {
        return (
            <>
                <a
                    style={{marginRight: 16}}
                    onClick={async (e) => {
                        console.log(record)
                        if(gridRef.current.getEditingKey() == record.uuid|| gridRef.current.getEditingKey() == record.key){
                            await gridRef.current.save(gridRef.current.getEditingKey());
                            await onSave();
                        }else{
                            await gridRef.current.edit(record);
                        }
                    }}
                >
                    {i18n.t("编辑/保存")}
                </a>

            </>
        )

    }
    const middleColumn: any = [
        {
            fieldShowName: i18n.t('商品简介',),
            fieldName: 'materialDescription',
            inputType: inputType.DICTIONARY,
            dictionary: "dict_sku_code",
            width: 130,
            editable: getIsCanEdit(),
            showTitle : true,
            onChange:(val:any)=>{
                tableForm.setFieldsValue({materialId: val});
            }
        },
        {
            fieldShowName: i18n.t('长',),
            fieldName: 'length',
            editable: true,
            width: 50,
        },
        {
            fieldShowName: i18n.t('宽',),
            fieldName: 'width',
            editable: true,
            width: 50,
        },
        {
            fieldShowName: i18n.t('颜色',),
            fieldName: 'color',
            editable: true,
            width: 80,

        },
    ];


    const useAppendColumnMaster: any = (sizeColumn: any) => {
        const endColumn: any = [
            {
                fieldShowName: i18n.t('层数',),
                fieldName: 'layers',
                editable: true,
                onChange:()=>{
                    countActualAmount(tableForm);
                }
            },
            {
                fieldShowName: i18n.t('实裁件数',),
                fieldName: 'actualAmount',
                editable: true,
            }
        ];
        const appendColumns = [
            {
                fieldShowName: i18n.t('主原料',),
                fieldName: 'materialId',
                inputType: inputType.DICTIONARY,
                dictionary: "clothes_sku",
                editable: getIsCanEdit(),
                onChange:(val:any)=>{
                    tableForm.setFieldsValue({materialDescription: val});
                }

            }
        ];

        var operate: any =
            {
                fieldShowName: i18n.t('操作',),
                render: (record: any) => {
                   return getRender(record,masterMaterialTableRef, ()=>{
                       save(masterMaterialTableRef, record,0);
                   })
                },
            }
            if(usePermission("新增修改生产订单")){
                return appendColumns.concat(middleColumn).concat(editColumn(tableForm)).concat(endColumn).concat(operate);

            }else{
                return appendColumns.concat(middleColumn).concat(editColumn(tableForm)).concat(endColumn);

            }

    }

    const save = (gridRef:any,record:any,type:any)=>{
        var data = gridRef.current.getDataItem(record.uuid);
        // data.docId = docId;
        data.materialType = type;
        axios.post("/clothes/produceOrder/updateProduceOrderMaterial",data)
            .then(function (resp) {
                var res = resp.data;
                if (res.Status == 1) {
                    message.success(i18n.t("保存成功"));
                    gridRef.current.refresh();
                }else{
                    message.error(i18n.t("保存失败"));
                }
            }).catch(()=>{
            message.error(i18n.t("保存失败"));
        })
    }

    const editColumn = (tableForm:any)=>{
        var tempColumn = JSON.parse(JSON.stringify(sizeColumn));
        tempColumn.map((item:any)=>{
            item.editable=true;
            item.onChange=()=>{
                countActualAmount(tableForm);
            }
        })
        return tempColumn;
    }


    //计算实际裁剪数
    const countActualAmount = (tableForm:any)=>{
        var formData = tableForm.getFieldsValue();
        var exp = /^size+[0-9]$/;
        var sizeAmount = 0;
        for(var index in formData){
            if(exp.test(index)){
                sizeAmount += formatNumber(formData[index]);
            }
        }
        //层数
        var layers = formatNumber(formData.layers);
        var actualAmount = layers * sizeAmount;
        tableForm.setFieldsValue({actualAmount: actualAmount});
    }
    const useAppendColumn: any = (sizeColumn: any) => {
        const endColumn: any = [
            {
                fieldShowName: i18n.t('层数',),
                fieldName: 'layers',
                editable: true,
                onChange:()=>{
                    countActualAmount(libuForm);
                }
            },
            {
                fieldShowName: i18n.t('实裁件数',),
                fieldName: 'actualAmount',
                editable: true,
            }
        ];

        const appendColumns = [
            {
                fieldShowName: i18n.t('里布裁剪',),
                fieldName: 'materialId',
                inputType: inputType.DICTIONARY,
                dictionary: "clothes_sku",
                editable: getIsCanEdit()
            }
        ];
        var operate: any =
            {
                fieldShowName: i18n.t('操作',),
                render: (record: any) => {
                    return getRender(record, libuMaterialTableRef,  () => {
                        save(libuMaterialTableRef, record,1);
                    })
                },
            }
            if(usePermission("新增修改生产订单")){
                return appendColumns.concat(middleColumn).concat(editColumn(libuForm)).concat(endColumn).concat(operate);

            }else{
                return appendColumns.concat(middleColumn).concat(editColumn(libuForm)).concat(endColumn);

            }
    }

    return <div>

        <div className="u-table-photo">
            <div className="u-table-left">

                <DataGrid
                    ref={masterMaterialTableRef}
                    pagination={false}
                    pageSize={5}
                    scrolly={125}
                    rowSelectionType="checkbox"
                    descriptions={useAppendColumnMaster(sizeColumn)}
                    url={`/clothes/produceOrder/getProduceOrderMaterialList`}
                    searchData={{docId: docId, materialType: "0"}}
                    onClick={async (record: any) => {
                        // await editOtherLine(masterMaterialTableRef,record)
                    }}
                    form={tableForm}
                    isShowSearch={false}
                />

                {usePermission("新增修改生产订单") && <Button onClick={() => {
                    masterMaterialTableRef.current.addDataEdit({uuid:"",docId:docId});

                }}>
                    {i18n.t("add")}
                </Button>}

                <DataGrid
                    ref={libuMaterialTableRef}
                    pagination={false}
                    pageSize={5}
                    scrolly={125}
                    rowSelectionType="checkbox"
                    descriptions={useAppendColumn(sizeColumn)}
                    url={`/clothes/produceOrder/getProduceOrderMaterialList`}
                    searchData={{docId: docId, materialType: "1"}}
                    onClick={async (record: any) => {
                        // await editOtherLine(libuMaterialTableRef,record)
                    }}
                    form={libuForm}
                    isShowSearch={false}
                />
                {usePermission("新增修改生产订单") && <Button onClick={()=>{
                    libuMaterialTableRef.current.addDataEdit({uuid:"",docId:docId});

                }}>
                    {i18n.t("add")}
                </Button>}


            </div>
            <div className="u-photo-left">
                {photoUrl && <img alt='' src={photoUrl}/>}
            </div>
        </div>

    </div>
}

export default ClothesCut;