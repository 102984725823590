import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import {Modal, Radio} from "antd";
import i18n from "i18next";
export interface Params {
  enterpriseId: string;
  visiable: boolean;
  hideModal: () => void;
  setSubAccountData: (arr: any) => void;
}
export default ({
  enterpriseId,
  visiable,
  hideModal,
  setSubAccountData,
}: Params) => {
  const thisGridRef = useRef<any>();
  const { width, height } = useWindowSize();
  const [modalHeight, setModalHeight] = useState(0);
  const [searchData,setSearchData] = useState<any>({searchField_string_type:"1|1"});
  useEffect(function () {
    setModalHeight((height * 1) / 2);
  }, []);
  const columnsData = [
    {
      fieldShowName: i18n.t("enterprise_number"),
      fieldName: "id",
      width: 150,
    },
    {
      fieldShowName: i18n.t("appEnterprise_name"),
      fieldName: "name",
      width: 150,
    },
    {
      fieldShowName: i18n.t("enterpriseManage_type"),
      fieldName: "type",
      width: 150,
      dictionary: "dict_enterprise_type",
    },
    {
      fieldShowName: i18n.t("public_contract"),
      fieldName: "contact",
      width: 100,
    },
    {
      fieldShowName: i18n.t("enterprise_contact_information"),
      fieldName: "contactWay",
      width: 130,
    },
    {
      fieldShowName: i18n.t("enterprise_last_login_time"),
      fieldName: "finalLandingTime",
      width: 130,
    },
    {
      fieldShowName: i18n.t("appEnterprise_registeredTime"),
      fieldName: "registeredTime",
      width: 130,
    },
  ];
  const [columns] = useState<any[]>(columnsData);
  const detailOnOk = () => {
    setSubAccountData(thisGridRef.current.getSelectedRows());
    updateEnterprise(thisGridRef.current.getSelectedRows());
  };
  const updateEnterprise = useCallback(function updateEnterprise(arr: any) {
    let uuids = "";
    if (arr != undefined && arr.length > 0) {
      arr.map((item: any) => {
        uuids += item.uuid + ",";
      });
    }
    hideModal();
  }, []);
  const detailElement: ReactNode = (
    <>
      <button
        type="button"
        className="ant-btn ant-btn-primary"
        onClick={detailOnOk}
      >
        <span>{i18n.t("determine")}</span>
      </button>
    </>
  );
  return (
    <Modal
      title={i18n.t("app_enterprise_add_sub_account")}
      className="add-component"
      closable={false}
      visible={visiable}
      destroyOnClose={true}
      onCancel={hideModal}
      footer={detailElement}
      width={(width * 1) / 1.3}
      bodyStyle={{ height: modalHeight }}
      maskClosable={false}
    >
      <div className="u-box u-basic-page">
        <Radio.Group
            onChange={(e) => {
              let tempSearchParam = {...searchData};
              setSearchData(null);
              tempSearchParam.searchField_string_type = "1|" + e.target.value;
              setSearchData(tempSearchParam);
            }}
            defaultValue="1"
        >
          <Radio.Button value="1">{i18n.t("app_enterprise_garment_factory")}</Radio.Button>
          <Radio.Button value="2">{i18n.t("app_enterprise_processing_plant")}</Radio.Button>
        </Radio.Group>
        <div className="u-box-con">
          <DataGrid
            ref={thisGridRef}
            pagination={true}
            sorter={{ field: "registeredTime", order: "descend" }}
            pageSize={Math.floor((modalHeight - 280) / 6)}
            scroll={{ y: modalHeight*0.67 }}
            rowSelectionType="checkbox"
            descriptions={columns}
            url={
              `/oa/appEnterpriseManagement/getEnterpriseListById?enterpriseId=` +
              enterpriseId
            }
            searchData={searchData}
          />
        </div>
      </div>
    </Modal>
  );
};
