import React, { useEffect, useRef, useState } from "react";
import { instance as axios } from "../../../../../../util/axios";
import { Button, Descriptions, Space, Steps } from "antd";
import { Project } from "../../../../../../schemas/project";
import { Result } from "../../../../../../schemas/common";
import { Stage } from "./Stage";
import { Archive } from "./Archive";
import { EnvironmentTwoTone } from "@ant-design/icons/lib";
import { ProjectStage } from "../../../../../../schemas/projectStage";
const { Step } = Steps;

export interface DetailProps {
  projectId: string;
}

async function changeStage(
  projectId: string,
  nextStageId: string
): Promise<Result<void>> {
  const resp = await axios.post<Result<void>>(
    `/crm/crm/project/updateProjectStage`,
    {
      projectId: projectId,
      stageId: nextStageId,
    }
  );
  return resp.data;
}

export const Detail = ({ projectId }: DetailProps) => {
  const [project, setProject] = useState<Project | null>(null);
  const [mode, setMode] = useState<"stage" | "archive">("archive");
  const [current, setCurrent] = useState<number>(0);
  const [stages, setStages] = useState<ProjectStage[]>([]);
  const [userPermission, setUserPermission] = useState<string[]>([]);
  const stageRef = useRef<any>(null);
  useEffect(
    function () {
      axios
        .post<Result<Project>>("/crm/crm/project/getProjectById", {
          uuid: projectId,
        })
        .then((res) => {
          if (res.data.Status === "1") {
            const project = res.data.Data;
            setProject(project);
            axios
              .post<Result<{ data: ProjectStage[] }>>(
                "crm/crm/project/getProjectStageList",
                {
                  projectId: projectId,
                }
              )
              .then((resp) => {
                if (resp.data.Status === "1") {
                  setStages(resp.data.Data.data);
                  setCurrent(
                    resp.data.Data.data.findIndex(
                      (item) => item.name === project.stage
                    )
                  );
                }
              });
          }
        });
    },
    [projectId]
  );
  useEffect(
    function () {
      axios
        .post<Result<string>>("/crm/crm/projectRole/getUserProjectPermission", {
          projectId,
        })
        .then((res) => {
          if (res.data.Status === "1") {
            setUserPermission(res.data.Data.split(","));
          }
        });
    },
    [projectId]
  );

  return (
    <>
      {project && (
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <Descriptions
            title={
              <>
                <span>{project.name}</span>
                {mode === "archive" ? (
                  <Button type={"link"} onClick={() => setMode("stage")}>
                    阶段视图
                  </Button>
                ) : (
                  <Button type={"link"} onClick={() => setMode("archive")}>
                    档案视图
                  </Button>
                )}
              </>
            }
          >
            <Descriptions.Item span={2} label="地址">
              {project.address ? (
                <Space>
                  <span>{project.address}</span>
                  <EnvironmentTwoTone />
                </Space>
              ) : (
                "无"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="备注">
              {project.remark || "无"}
            </Descriptions.Item>
          </Descriptions>
          <Steps progressDot current={current}>
            {stages.map((stage) => (
              <Step
                title={stage.percent + "%"}
                description={stage.name}
                key={stage.uuid}
              />
            ))}
          </Steps>
          {mode === "stage" && (
            <Space style={{ paddingBottom: 10 }}>
              {current > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    const nextStageId = stages[current - 1].uuid;
                    changeStage(projectId, nextStageId).then(function (result) {
                      if (result.Status === "1") {
                        setCurrent(current - 1);
                        stageRef.current.refresh();
                      }
                    });
                  }}
                >
                  返回上一阶段
                </Button>
              )}
              {current < stages.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    const nextStageId = stages[current + 1].uuid;
                    changeStage(projectId, nextStageId).then(function (result) {
                      if (result.Status === "1") {
                        setCurrent(current + 1);
                        stageRef.current.refresh();
                      }
                    });
                  }}
                >
                  进入下一阶段
                </Button>
              )}
            </Space>
          )}
          {mode === "archive" ? (
            <Archive
              id={projectId}
              advisorId={project.advisorId}
              permission={userPermission}
            />
          ) : (
            <Stage
              ref={stageRef}
              id={projectId}
              advisorId={project.advisorId}
              permission={userPermission}
            />
          )}
        </Space>
      )}
    </>
  );
};
