import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import { Form, message, Input, Modal, InputNumber } from "antd";
import { useWindowSize } from "react-use";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
import { validateMessages } from "../util/DocumentEvent";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import "../CSS/tax.css";
import { DictionarySelects } from "../../../../../components/DictionarySelectFilter";
import i18n from "i18next";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
interface Param {
  current: any;
}
export default () => {
  const [form] = Form.useForm();
  const [detailForm] = Form.useForm();
  const { width, height } = useWindowSize();
  const [taxId, setTaxId] = useState("");
  const [isHiddenEdit, setIsHiddenEdit] = useState(false);
  const [isHiddenDelete, setIsHiddenDelete] = useState(false);
  const [count, setCount] = useState(2);
  const thisRef: Param = useRef();
  thisRef.current = {
    count,
  };
  const getTaxById = useCallback(function getTaxById(taxId: string) {
    axios
      .post<Result<any[]>>(`/clothes/tax/getTaxById`, {
        taxId: taxId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          if (data != undefined) {
            form.setFieldsValue(data);
            setTaxId(data.uuid);
          }
        }
      });
  }, []);

  const getTaxByIdModel = useCallback(function getTaxByIdModel(taxId: string) {
    axios
      .post<Result<any[]>>(`/clothes/tax/getTaxById`, {
        taxId: taxId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          if (data != undefined) {
            detailForm.setFieldsValue(data);
            setTaxId(data.uuid);
          }
        }
      });
  }, []);
  const [detailVisible, setDetailVisible] = useState(false);
  const hideModal = () => {
    setDetailVisible(false);
  };
  const detailOnOk = () => {
    detailForm.submit();
  };
  // useEffect(
  //   function () {
  //     if (taxId.length < 2) {
  //       setIsHiddenDelete(true);
  //       setIsHiddenEdit(true);
  //     } else {
  //       setIsHiddenDelete(true);
  //       setIsHiddenEdit(true);
  //     }
  //   },
  //   [taxId]
  // );
  const detailElement: ReactNode = (
      <>
        <button
            style={{width: 100}}
            className="ant-btn ant-btn-primary"
            onClick={detailOnOk}
        >
          <span>{i18n.t("保存")}</span>
        </button>
        <button
            style={{width: 100}}
            className="ant-btn"
            onClick={() => {
              detailForm.resetFields();
            }}
        >
          <span>{i18n.t("重置")}</span>
        </button>
      </>
  );
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作",),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("您确定删除此税率吗？",),
      okText: i18n.t("确定",),
      cancelText: i18n.t("取消",),
      onOk: function () {
        const url = `/clothes/tax/deleteTaxById?taxId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("删除成功"));
            form.resetFields();
            getNewSelect();
            setTaxId("");
          } else {
            message.info(i18n.t("删除失败"));
          }
        });
      },
    });
  }, []);
  const getNewSelect = useCallback(
    function getNewSelect() {
      let num = thisRef.current.count + 1;
      setCount(num);
    },
    [taxId]
  );
  const onDetailFinish = (values: any) => {
    axios
      .post<Result<any[]>>(`/clothes/tax/saveTax`, {
        uuid: values.uuid,
        name: values.name,
        taxRate: values.taxRate,
        remark: values.remark,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("保存成功"));
          let data: any = resp.data.Data;
          getTaxById(data);
          setDetailVisible(false);
          getNewSelect();
        }
      });
  };

  return (
      <>
        <div className="u-form">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
              <div className="u-ThreCon">
                <Form.Item label={i18n.t("税号名称")} name={"uuid"}>
                  <DictionarySelects
                      entityName={"dict_tax"}
                      onChange={(value) => {
                        getTaxById(value);
                      }}
                      filter={"1!=" + count}
                  />
                </Form.Item>
                <div className="tax-add">
                  <PlusOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        detailForm.resetFields();
                        setDetailVisible(true);
                      }}
                  />
                </div>
                <div className="tax-edit" hidden={isHiddenEdit}>
                  <EditOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        if (taxId == "") {
                          message.error(i18n.t("请选择税号后编辑"));
                        } else {
                          getTaxByIdModel(taxId);
                          setDetailVisible(true);
                        }
                      }}
                  />
                </div>
                <div className="tax-delete" hidden={isHiddenDelete}>
                  <DeleteOutlined
                      style={{fontSize: "160%", marginTop: "23%"}}
                      onClick={() => {
                        confirm(taxId);
                      }}
                  />
                </div>
              </div>
              <div className="u-ThreCon">
                <Form.Item label={i18n.t("税率%")} name="taxRate">
                  <Input readOnly={true} style={{width: "25%"}}/>
                </Form.Item>
              </div>
            </Form>
            <Modal
                style={{marginTop: "5%"}}
                className="add-component"
                visible={detailVisible}
                onCancel={hideModal}
                footer={detailElement}
                width={(width * 1) / 3}
                destroyOnClose={true}
                bodyStyle={{height: (height * 1) / 3.5}}
                maskClosable={false}
            >
              <Form
                  {...layout}
                  form={detailForm}
                  layout="horizontal"
                  name="basicForm"
                  autoComplete="off"
                  onFinish={onDetailFinish}
              >
                <Form.Item name="uuid" hidden>
                  <Input/>
                </Form.Item>
                <Form.Item name="remark" hidden>
                  <Input/>
                </Form.Item>
                <Form.Item
                    className="size-name"
                    label={i18n.t("税号名称")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        validator: async (rule, value) => {
                          if (value == undefined || value.trim() == "") {
                            message.error(i18n.t("税号名称不能为空"));
                            throw new Error(i18n.t("税号名称不能为空"));
                          }
                        },
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("税率%")} name="taxRate">
                  <InputNumber
                      defaultValue={0}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      size={"middle"}
                      parser={(value: any) => value.replace("%", "")}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Form.Provider>
        </div>
      </>
  );
};
