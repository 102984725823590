import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader
} from "antd";
import update from "immutability-helper";
import {SearchOutlined} from "@ant-design/icons/lib";
import GoodsModal from "../../Order/Detail/GoodsModal";
import i18n from "i18next";
interface param {
    form:any
}

export default ({form}:param)=>{
    const [showModal, setShowModal] = useState(false);
    const formItemLayout = {
        labelCol: {
            xs: { span: 2 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 2 },
            sm: { span: 12 },
        },
    };

    useEffect(()=>{

    },[])


    return (
        <div>
            <Form
                {...formItemLayout}
                form={form}
                name="add"
            >
                <Form.Item style={{display: "inline"}} label={i18n.t("商品编码")}>
                    <Row gutter={8}>
                        <Col span={20}>
                            <Form.Item name={"name"} noStyle rules={[{required: true}]}>
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button shape="circle" icon={<SearchOutlined/>} onClick={() => {
                                setShowModal(true)
                            }}/>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label={i18n.t("商品简介")} name={"code"} rules={[{required: true}]}>
                    <Input disabled={true}/>

                </Form.Item>
                <Form.Item label={i18n.t("生产模特编码")} name={"modelCode"}>
                    <Input/>

                </Form.Item>
                <Form.Item label={i18n.t("数量")} name={"quantity"} rules={[{required: true}]}>
                    <Input type={"Number"}/>
                </Form.Item>
                <Form.Item hidden name={"skuId"}>
                    <Input/>
                </Form.Item>
            </Form>
            <GoodsModal visible={showModal} onOk={(data) => {
                const obj = JSON.parse(JSON.stringify(data))[0];
                form.setFieldsValue({
                    name: obj.name,
                    code: obj.code,
                    skuId: obj.uuid
                })
                setShowModal(false);
            }} onCancel={() => {
                setShowModal(false);
            }}/>
        </div>
    );
}