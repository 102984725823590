import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { TableGrid } from "../../../../../../components/TableGrid";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import {
  Button,
  Tabs,
  message,
  Descriptions,
  Space,
  Layout,
  Popconfirm,
  Steps,
} from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { useWindowSize } from "react-use";
import { DynamicTab } from "../../components/DynamicTab";
import { ContactList } from "./ContactList";
import { AvatarWrapper } from "../../../../../../components/AvatarWrapper";
import { Result, ContactStage } from "../../../../../../schemas/common";
import { CorrespondenceTreeWrapper } from "../../../../../../components/CorrespondenceTree";
import UploadWrapper from "../../../../../../components/UploadWrapper";
import { createTimeDescription } from "../../../../../../constants/descriptions";

const { TabPane } = Tabs;
const { Step } = Steps;
const { Sider, Content } = Layout;
export interface DetailProps {
  chanceId: string;
}

export interface ChanceViewModel {
  customerName: string;
  advisorId: string;
  advisorName: string;
  stage: string;
  stageName: string;
  index: number;
  percent: number;
  uuid: string;
  createTime: string;
  amount: string;
  type: string;
}

export const Detail = ({ chanceId }: DetailProps) => {
  document.addEventListener("paste", function (e) {
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
  });
  const tableGridRef = useRef<any>();
  const shareGridRef = useRef<any>();
  const fileGridRef = useRef<any>();
  const session: Session = useContext(SessionContext);
  const { height } = useWindowSize();
  const [correspondenceUrl, setCorrespondenceUrl] = useState(
    "/crm/saleChanceCorrespondence/getCorrespondence?categoryId=12345678901234567890123456789010&chanceId=" +
      chanceId
  );
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState<
    boolean
  >(false);
  const [treeCategory, setTreeCategory] = useState<string>();
  /**
   * 没有分组的单据
   */
  const [dynamicTabConfigs, setDynamicTabConfigs] = useState<any[]>([]);
  /**
   * 独立的单据
   */
  const [
    standaloneDynamicTabConfigs,
    setStandaloneDynamicTabConfigs,
  ] = useState<any[]>([]);
  const [chance, setChance] = useState<ChanceViewModel>({
    advisorId: "",
    advisorName: "",
    customerName: "",
    stageName: "",
    stage: "",
    uuid: "",
    percent: 0,
    index: 0,
    createTime: "",
    amount: "",
    type: "",
  });
  const [stageList, setStageList] = useState<ContactStage[]>([]);
  const getChanceInfo = useCallback(
    function () {
      axios
        .post<Result<ChanceViewModel>>(
          `/crm/salesChance/getChanceById?uuid=${chanceId}`
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data = resp.data.Data;
            axios
              .get<
                Result<{
                  data: ContactStage[];
                }>
              >(`/crm/salesChance/getChanceStageList?type=${data.type}`)
              .then((res) => {
                if (res.data.Status === "1") {
                  let stages = res.data.Data.data;
                  setStageList(stages);
                  data.index = data.stage
                    ? stages.findIndex((item) => item.uuid === data.stage)
                    : -1;
                  data.stageName = data.stage ? stages[data.index].name : "";
                  data.percent = data.stage ? stages[data.index].percent : 0;
                }
                setChance(data);
              });
          }
        });
    },
    [chanceId]
  );
  useEffect(
    function () {
      axios
        .post<Result<any[]>>(
          `/crm/dynamicTab/getSelectedList?host=chance&hostPrimaryKey=${chanceId}`
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            const data: any[] = resp.data.Data;
            if (data.length === 0) {
              return;
            }
            setDynamicTabConfigs(data.filter((item) => !item.group));
            setStandaloneDynamicTabConfigs(data.filter((item) => item.group));
          }
        });
      getChanceInfo();
    },
    [chanceId, getChanceInfo]
  );

  return (
    <>
      <Descriptions title="商机信息">
        <Descriptions.Item label="客户名称">
          <Space>
            <span>{chance.customerName || "无"}</span>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="联系阶段">
          {chance.stageName || "无"}
        </Descriptions.Item>
        <Descriptions.Item label="业务员">
          {chance.advisorId ? (
            <Space>
              <AvatarWrapper
                userId={chance.advisorId}
                userName={chance.advisorName}
              />
            </Space>
          ) : (
            "无"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
          {chance.createTime || "无"}
        </Descriptions.Item>
        <Descriptions.Item label="预计金额(万元)">
          {chance.amount || "无"}
        </Descriptions.Item>
      </Descriptions>
      <div>
        <div>
          <span>当前联系阶段：</span>
          {chance.advisorId === session.id &&
            chance.percent < stageList[stageList.length - 1].percent && (
              <Button
                type={"link"}
                onClick={() => {
                  axios
                    .post<Result<void>>(
                      `/crm/salesChance/moveToNextStage?chanceId=${chanceId}`
                    )
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        getChanceInfo();
                        tableGridRef.current?.refresh();
                        message.success(resp.data.Message);
                      }
                    });
                }}
              >
                进入下一阶段
              </Button>
            )}
        </div>
        <Steps
          progressDot
          size="small"
          current={chance.index}
          style={{ margin: "10px 0" }}
        >
          {stageList.map((item: ContactStage) => (
            <Step title={item.name} key={item.uuid} />
          ))}
        </Steps>
      </div>
      <Tabs>
        <TabPane key={"info"} tab={"基本信息"}>
          <div style={{ textAlign: "right" }}>
            <Button
              type={"link"}
              style={{ paddingRight: 0 }}
              onClick={() => {
                tableGridRef.current.save();
              }}
            >
              保存
            </Button>
          </div>
          <TableGrid
            ref={tableGridRef}
            id={chanceId}
            entityName="crm_sale_chance"
            columnCount={3}
          />
        </TabPane>
        <TabPane key={"contactList"} tab={"跟进记录"}>
          <ContactList chanceId={chanceId} />
        </TabPane>
        <TabPane key={"forms"} tab={"单据"}>
          {dynamicTabConfigs.map((item: any) => (
            <DynamicTab
              key={item.tableName}
              id={chanceId}
              host="chance"
              advisorId={chance.advisorId}
              data={{
                ...item,
                entityName: item.tableName,
                uuid: item.uuid,
              }}
            />
          ))}
        </TabPane>
        {standaloneDynamicTabConfigs.map((item: any) => (
          <TabPane tab={item.formName} key={item.tableName}>
            <DynamicTab
              key={item.tableName}
              id={chanceId}
              host="chance"
              advisorId={chance.advisorId}
              data={{
                ...item,
                entityName: item.tableName,
                uuid: item.uuid,
              }}
            />
          </TabPane>
        ))}
        <TabPane key={"correspondence"} tab={"资料管理"}>
          <Layout>
            <Sider width={260}>
              <CorrespondenceTreeWrapper
                getTreeDataUrl={`/crm/saleChanceCorrespondenceCategory/getTreeData?type=crm_sale_chance_correspondence_category&chanceId=${chanceId}`}
                deleteUrl={
                  "/crm/saleChanceCorrespondenceCategory/deleteChild?uuid="
                }
                addUrl={`/crm/saleChanceCorrespondenceCategory/addChild?uuid=0&chanceId=${chanceId}`}
                updateUrl={`/crm/saleChanceCorrespondenceCategory/addChild?chanceId=${chanceId}`}
                editable={true}
                onSelected={(keys) => {
                  const category: string = keys[0] || "";
                  setTreeCategory(category);
                  setCorrespondenceUrl(
                    `/crm/saleChanceCorrespondence/getCorrespondence?chanceId=${chanceId}&categoryId=${category}`
                  );
                }}
              />
            </Sider>
            <Content>
              {session.id === chance.advisorId && (
                <div style={{ textAlign: "right" }}>
                  <Button
                    type={"link"}
                    style={{ paddingRight: 0 }}
                    onClick={() => setUploadWrapperDialogVisible(true)}
                  >
                    上传文件
                  </Button>
                </div>
              )}
              <DataGrid
                pageSize={Math.floor((height - 300) / 42)}
                url={correspondenceUrl}
                ref={fileGridRef}
                descriptions={[
                  {
                    fieldShowName: "文件名称",
                    fieldName: "name",
                  },
                  {
                    fieldShowName: "大小",
                    render(file: { fileSize: number }) {
                      return (
                        <>
                          {file.fileSize >= 1
                            ? file.fileSize.toFixed(2) + " M"
                            : (file.fileSize * 1024).toFixed(2) + " KB"}
                        </>
                      );
                    },
                  },
                  {
                    fieldShowName: "上传人员",
                    fieldName: "creatorId",
                    dictionary: "base_staff",
                  },
                  {
                    fieldShowName: "上传时间",
                    fieldName: "createTime",
                  },
                  {
                    fieldShowName: "操作",
                    render(text: any, record: any) {
                      return (
                        <>
                          <Button
                            type={"link"}
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              window.open(
                                `/oa/common/attachment/downloadFiles?ids=${record.attachments}`
                              );
                            }}
                          />
                          <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                              const url = `/crm/saleChanceCorrespondence/deleteCorrespondence?uuid=${record.key}`;
                              axios.delete<Result<void>>(url).then((resp) => {
                                if (resp.data.Status === "1") {
                                  message.info("删除成功");
                                  fileGridRef.current.refresh();
                                }
                              });
                            }}
                          >
                            <Button type={"link"} icon={<DeleteOutlined />} />
                          </Popconfirm>
                        </>
                      );
                    },
                  },
                ]}
              />
            </Content>
          </Layout>
        </TabPane>
        <TabPane key={"shareList"} tab={"共享记录"}>
          <DataGrid
            pageSize={Math.floor((height - 300) / 42)}
            url={`/crm/salesChance/getShareList?chanceId=${chanceId}`}
            ref={shareGridRef}
            descriptions={[
              {
                fieldShowName: "共享业务员",
                fieldName: "sharedStaffId",
                dictionary: "base_staff",
              },
              createTimeDescription,
              {
                fieldShowName: "操作",
                render(text: any, record: any) {
                  return (
                    <>
                      <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                          const url = `/crm/salesChance/deleteShare?ids=${record.key}`;
                          axios.delete<Result<any>>(url).then((resp) => {
                            if (resp.data.Status === "1") {
                              message.info("删除成功");
                              shareGridRef.current.refresh();
                            }
                          });
                        }}
                      >
                        <Button type={"link"} icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  );
                },
              },
            ]}
          />
        </TabPane>
      </Tabs>
      <UploadWrapper
        multiple={false}
        visible={uploadWrapperDialogVisible}
        onOk={(files) => {
          setUploadWrapperDialogVisible(false);
          axios
            .post<Result<void>>(
              `/crm/saleChanceCorrespondence/correspondence`,
              {
                attachments: files.map((file) => file.uuid).join(","),
                chanceId: chanceId,
                categoryId: treeCategory,
              }
            )
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.info("保存成功");
                fileGridRef.current?.refresh();
              }
            });
        }}
        onCancel={() => setUploadWrapperDialogVisible(false)}
      />
    </>
  );
};
