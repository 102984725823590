import React, {useEffect, useState} from "react";
import {Button, Col, Form, Image, Input, message, Radio, Row} from "antd";
import TextArea from "antd/es/input/TextArea";
import {instance as axios} from "../../../../../util/axios";
import i18n from "i18next";
import {usePermission} from "../../../../../util/permession";

export interface Param {
    photoUrl: string,
    docData: any
}
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
}
const CustomersNeedToKnow = ({photoUrl, docData}: Param) => {
    const [form] = Form.useForm()


    useEffect(() => {
        console.log(docData)
        form.setFieldsValue(docData)
    }, [docData])


    return <div>

        <div className="u-table-photo">
            <div className="u-table-left">
                <Form
                    form={form}
                    {...formItemLayout}
                >
                    <Form.Item name={"shippingInstructions"} label={i18n.t("发货须知")} style={{width: "800px"}}>
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name={"precautions"} label={i18n.t("其他注意事项")} style={{width: "800px"}}>
                        <TextArea rows={4}/>
                    </Form.Item>

                    {usePermission("新增修改生产订单") &&
                    <Button htmlType="button" style={{marginLeft: "270px"}} onClick={() => {
                        var data = form.getFieldsValue();
                        data.uuid = docData.uuid;
                        axios.post("/clothes/produceOrder/updateProduceOrder", data).then((resp) => {
                            if (resp.data.Status == 1) {
                                message.success(i18n.t("修改成功"));
                            }
                        })
                    }}>
                        {i18n.t("保存")}
                    </Button>}

                </Form>
            </div>
            <div className="u-photo-left">
                {photoUrl && <img alt='' src={photoUrl}/>}
            </div>
        </div>

    </div>;
}

export default CustomersNeedToKnow;