import React, { useEffect, useState } from "react";
import { Button, Modal, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../util/axios";
import { CustomerTag } from "../../../../../schemas/customerTag";
export interface UserCustomerTagManageDialogProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (tags: CustomerTag[]) => void;
}

/**
 * 用户的客户标签管理
 * @param onCancel
 * @param onOk
 * @param visible
 * @constructor
 */
export const UserCustomerTagManageDialog = ({
  onCancel,
  onOk,
  visible,
}: UserCustomerTagManageDialogProps) => {
  const [tags, setTags] = useState<CustomerTag[]>([]);
  const [delTag, setDelTag] = useState<String[]>([]);
  useEffect(
    function () {
      if (visible) {
        axios.get("/crm/customer/label/getList").then((resp) => {
          if (resp.data.Status === "1") {
            setTags(resp.data.Data);
          }
        });
      }
    },
    [visible]
  );
  return (
    <>
      <Modal
        title="编辑标签"
        width={400}
        visible={visible}
        onCancel={() => {
          onCancel();
        }}
        onOk={() => {
          delTag.forEach((item: String) => {
            axios.delete("/crm/customer/label/del?labelId=" + item);
          });
          tags.forEach((item: CustomerTag) => {
            axios.post("/crm/customer/label/update", {
              labelId: item.uuid,
              name: item.name,
            });
          });
          onOk(tags);
        }}
      >
        {tags.map((tag: CustomerTag, i: number) => (
          <div
            key={tag.uuid}
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <Input
              value={tag.name}
              style={{ marginRight: 10, width: 300 }}
              onChange={(e: any) => {
                setTags(
                  tags.map((item) => ({
                    ...item,
                    name: item.uuid === tag.uuid ? e.target.value : item.name,
                  }))
                );
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => {
                setDelTag([...delTag, tag.uuid]);
                tags.splice(i, 1);
              }}
            />
          </div>
        ))}
      </Modal>
    </>
  );
};
