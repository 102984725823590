import React, {useCallback, useEffect, useState} from "react";
import {
  Form,
  Input,
  message,
  Button, Modal,
} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import i18n from "i18next";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useHistory } from "react-router";
import TextArea from "antd/es/input/TextArea";
import BankList from "./BankList";
import "../../CSS/customer.css";
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/getCustomerBankInformationById`, {
          customerId: customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            form.setFieldsValue(resp.data.Data);
          }
        });
  }, []);
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    values.uuid = customerId;
    axios
        .post<Result<any[]>>(`/clothes/customerManage/saveBankInformation`, {
          data: JSON.stringify(values),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setLoading(false);
            setIsChange(false);
          }
        });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="uuid" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <div className="u-ThreCon">
                <Form.Item label={i18n.t("货币")} name="currencyId">
                  <DictionarySelect entityName={"dict_currency"}/>
                </Form.Item>
                <Form.Item label={i18n.t("付款方式")} name="paymentMethodId">
                  <DictionarySelect entityName={"dict_payment_method"} addAble={true} isTrans={true}/>
                </Form.Item>
                <Form.Item label={i18n.t("付款周期")} name="paymentCycleId">
                  <DictionarySelect entityName={"dict_payment_cycle"}/>
                </Form.Item>
              </div>
              <Form.Item className={"customer-payment-from"} label={i18n.t("客户银行信息")} {...{
                labelCol: {span: 3},
                wrapperCol: {span: 10}
              }}>
              </Form.Item>
              <Form.List name="bankList">
                {(fields, {add, remove}) => {
                  return (
                      <>
                        {fields.map((field) => (
                            <div
                                className="u-add-list-item"
                                style={{
                                  overflow: "hidden",
                                }}
                                key={field.key}
                            >
                              <Form.Item name={[field.name, "uuid"]} hidden>
                                <Input/>
                              </Form.Item>
                              <Form.Item name={[field.name, "customerId"]} hidden>
                                <Input/>
                              </Form.Item>
                              <Form.Item
                                  name={[field.name, "customerId"]}
                                  hidden
                                  initialValue={customerId}
                              >
                                <Input/>
                              </Form.Item>
                              <Form.Item className={"payment-from"} label={i18n.t("银行名称")}
                                         name={[field.name, "bankName"]} {...{
                                labelCol: {span: 3},
                                wrapperCol: {span: 10}
                              }}>
                                <Input/>
                              </Form.Item>
                              <Form.Item className={"payment-from"} label={i18n.t("开户行")}
                                         name={[field.name, "accountBank"]} {...{
                                labelCol: {span: 3},
                                wrapperCol: {span: 10}
                              }}>
                                <Input/>
                              </Form.Item>
                              <Form.Item className={"payment-from"} label="IBAN"
                                         name={[field.name, "IBAN"]} {...{
                                labelCol: {span: 3},
                                wrapperCol: {span: 10}
                              }}>
                                <Input/>
                              </Form.Item>
                              <Form.Item className={"payment-from"} label="SWIFT"
                                         name={[field.name, "SWIFTCode"]} {...{
                                labelCol: {span: 3},
                                wrapperCol: {span: 10}
                              }}>
                                <Input/>
                              </Form.Item>
                              <div className="u-ThreCon">
                                <Form.Item className="remark" label={i18n.t("备注")}
                                           name={[field.name, "paymentNote"]} {...{
                                  labelCol: {span: 3},
                                  wrapperCol: {span: 20}
                                }}>
                                  <TextArea rows={4}/>
                                </Form.Item>
                              </div>
                              <Form.Item label=" " colon={false}>
                                {fields.length > 1 ? (
                                    <span
                                        className="u-del-form-list"
                                        onClick={() => {
                                          Modal.confirm({
                                            title: i18n.t("删除操作",),
                                            icon: <ExclamationCircleOutlined/>,
                                            content: i18n.t("确定删除吗？",),
                                            okText: i18n.t("okText"),
                                            cancelText: i18n.t("cancelText"),
                                            onOk: function () {
                                              remove(field.name);
                                            },
                                          });
                                        }}
                                    >
                                <DeleteOutlined/> {i18n.t("删除")}
                              </span>
                                ) : null}
                              </Form.Item>
                            </div>
                        ))}
                        <div className="u-add-list">
                          <div className="u-form-list">
                            <Form.Item label=" " className="u-contacts-head" colon={false}>
                                                        <span
                                                            className="u-add-cof"
                                                            onClick={() => {
                                                              add();
                                                            }}
                                                        >
                            <PlusOutlined/> {i18n.t("添加银行信息")}
                          </span>
                            </Form.Item>
                          </div>
                        </div>
                      </>
                  );
                }}
              </Form.List>

              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") &&  <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          // setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
