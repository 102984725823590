import React, {useRef, useState} from 'react'
import {Modal, Table, Input, message, Tree, Row, Col, Form} from 'antd'
import {DataGrid} from "../../../../../../components/DataGrid";
import {BaseURL} from "../../../../../../constants/url";
import {useWindowSize} from "react-use";
import {CorrespondenceTreeWrapper} from "../../../../../../components/CorrespondenceTree";
import "../clothes.css";
import {isEmpty} from "../../util/Common";
import i18n from "i18next";
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
};
interface FormProps {
    visible: boolean,
    onCancel: () => void,
    onOk: (v: any) => void,
}


export default ({visible, onCancel, onOk}: FormProps) => {
    const { height } = useWindowSize();
    const columns = [
        {
            fieldShowName: i18n.t("商品编码",),
            fieldName: 'name',
            width: 100
        },
        {
            fieldShowName: i18n.t('商品简介',),
            fieldName: 'code',
            width: 100
        }
    ]
    const tableRef = useRef<any>();
    const [rowsData, setRowsData] = useState()
    const [photoUrl, setPhotoUrl] = useState('');
    const [category, setCategory] = useState("");
    const [searchData, setSearchData] = useState<any>({})

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setRowsData(selectedRows)
            setPhotoUrl(selectedRows[0].url)
        }
    }

    const getCustomerData = (v: any) => {
        console.log('获取商品', v.target.value)
    }

    return (
        <Modal
            getContainer={false}
            style={{top: '20px'}}
            width={'1080px'}
            visible={visible}
            title={i18n.t("商品列表")}
            okText={i18n.t("确定")}
            cancelText={i18n.t("取消")}
            onCancel={onCancel}
            onOk={() => {
                var data = tableRef.current.getSelectedRows();
                if (data == undefined || data.length < 1) {
                    message.warning(i18n.t('请选择商品'));
                    return false;
                }
                onOk(data)
            }}
            bodyStyle={{height: 550}}
            maskClosable={false}
        >
            <div className="u-goods-table-photo">
                <Tree
                    defaultCheckedKeys={["3"]}
                    className={"u-form-photo-l"}
                    showLine
                    defaultExpandedKeys={['0-0-0']}
                    onSelect={(v) => {
                        var key: any = v[0];
                        if (key) {
                            setCategory(key);
                        } else {
                            setCategory("");
                        }
                    }}
                    treeData={[
                        {
                            title: i18n.t('自产类',),
                            key: '3',
                        },
                        {
                            title: i18n.t('成品采购',),
                            key: '5',
                        },
                        {
                            title: i18n.t('辅料',),
                            key: '2',
                        },
                        {
                            title: i18n.t('原材料',),
                            key: '0',
                        },
                        {
                            title: i18n.t('服务',),
                            key: '4',
                        },
                    ]}
                />

                <Form {...layout}>

                    <div className="goodsmodal_form_left">
                        <Form.Item
                            name="name"
                            label={i18n.t("商品编码")}
                        >
                            <Input onChange={(v) => {
                                let tempSearchParam = {...searchData};
                                setSearchData(null);
                                if (isEmpty(v.target.value)) {
                                    delete tempSearchParam.searchField_string_name;
                                } else {
                                    tempSearchParam.searchField_string_name = "1|" + v.target.value;
                                }
                                setSearchData(tempSearchParam);
                            }}
                                   allowClear={true}
                            />
                        </Form.Item>
                    </div>

                    <div className="goodsmodal_form_right">
                        <Form.Item
                            name="code"
                            label={i18n.t("商品简介")}
                        >
                            <Input onChange={(v) => {
                                let tempSearchParam = {...searchData};
                                setSearchData(null);
                                if (isEmpty(v.target.value)) {
                                    delete tempSearchParam.searchField_string_code;
                                } else {
                                    tempSearchParam.searchField_string_code = "1|" + v.target.value;
                                }
                                setSearchData(tempSearchParam);
                            }}
                                   allowClear={true}
                            />
                        </Form.Item>
                    </div>

                </Form>

                {category != "" && <DataGrid ref={tableRef} url={"/clothes/sku/getSkuManageList?property=" + category}
                          rowSelectionType={"radio"}
                          descriptions={columns} onClick={(record: any) => {
                    setPhotoUrl(BaseURL + "/oa/common/attachment/showImageAttachment?id=" + record.imageId)
                }} clickToSelected={true} style={{position: "absolute", marginLeft: 200, width: 840}}
                          searchData={searchData} scrolly={height * 0.6}/>}

                {photoUrl && <div className="u-form-photo-r">
                    <img alt='' src={photoUrl}/>
                </div>}
            </div>
        </Modal>
    );
}