import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    Col,
    Row,
    Statistic,
    Radio,
    Table,
    Progress,
    DatePicker,
    message,
    Spin,
    Form,
    Divider,
    Button
} from "antd";
import * as echarts from 'echarts';
import {instance as axios} from "../../../../../util/axios";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import {isEmpty, isNullOrUndefined} from "../util/Common";
import i18n from "i18next";
const { RangePicker } = DatePicker;


export default ()=>{
    const [form] = Form.useForm();
    const [type, setType] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchData, setSearchData] = useState();
    const [gridSearchData, setGridSearchData] = useState();

    useEffect(()=>{
        let element = document.getElementById('main1');
        let myChart = echarts.init(element as HTMLDivElement);


        var option2 = {
            title:{
                left: 'center',
                top: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}:{d} %',
            },
            legend: {
                bottom: '5%',
                left: 'center'
            },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        show: true
                    },
                    label: {
                        formatter: '{b}:{d} %',
                    }
                }
            ]
        };
        myChart.showLoading()
        myChart.setOption(option2);
        let data: any =  Object.assign(searchData==undefined?{}:searchData,gridSearchData==undefined?{}:gridSearchData);

        axios
            .post("/clothes/statistic/getProductOrderAmount",data).then((resp:any)=>{
                myChart.hideLoading()
            if(resp.data.Status == 1){
                var data = resp.data.Data
                var count = 0
                if(data && data.length > 0){
                    count = data[0].counts;
                }
                myChart.setOption({
                    title:{
                        text:i18n.t("产品数量合计")+": \n"+ count
                    },
                    series: [
                        {
                            data: resp.data.Data
                        }
                    ]
                })
            }
        })

    },[searchData,gridSearchData])
    // useEffect(()=>{
    //     let element = document.getElementById('main2');
    //     let myChart = echarts.init(element as HTMLDivElement);
    //
    //
    //     var option2 = {
    //         title:{
    //             left: 'center',
    //             top: 'center'
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: '{b}:{d} %',
    //         },
    //         legend: {
    //             bottom: '5%',
    //             left: 'center'
    //         },
    //         series: [
    //             {
    //                 name: '',
    //                 type: 'pie',
    //                 radius: ['40%', '70%'],
    //                 avoidLabelOverlap: false,
    //                 labelLine: {
    //                     show: true
    //                 },
    //                 label: {
    //                     formatter: '{b}:{d} %',
    //                 }
    //             }
    //         ]
    //     };
    //     myChart.showLoading()
    //     myChart.setOption(option2);
    //
    //     axios
    //         .post("/clothes/statistic/getProductOrderPriceAmount",searchData).then((resp:any)=>{
    //             myChart.hideLoading()
    //         if(resp.data.Status == 1){
    //             var data = resp.data.Data
    //             var count = 0
    //             if(data && data.length > 0){
    //                 count = data[0].counts;
    //             }
    //             myChart.setOption({
    //                 /*title:{
    //                     text:"产品价格合计：\n"+count
    //                 },*/
    //                 series: [
    //                     {
    //                         data: resp.data.Data
    //                     }
    //                 ]
    //             })
    //         }
    //     })
    //
    // },[searchData])


    return (
        <div>
            <Row gutter={[24, 8]}>
                <Form form={form}>
                    <Row>
                        <Form.Item name={"type"}>
                            <DictionarySelect entityName={"dict_sku_property"} style={{width: 400}}/>
                        </Form.Item>
                        <Form.Item name={"range"}>

                            <DateTimePickerWithFormat rangePicker={true}/>
                        </Form.Item>
                    </Row>
                </Form>
                <Button onClick={() => {
                    var type = form.getFieldValue("type")
                    var ranges: any = form.getFieldValue("range")
                    const temp: any = {};
                    temp.type = isEmpty(type) ? "" : type;
                    if (ranges) {
                        temp.startDate = ranges[0].format("YYYY-MM-DD");
                        temp.endDate = ranges[1].format("YYYY-MM-DD");
                        setSearchData(temp)
                    } else {
                        setSearchData(temp)
                    }
                }}>{i18n.t("搜索")}</Button>
                <Button onClick={() => {
                    form.setFieldsValue({type: undefined, range: undefined})

                }}>{i18n.t("重置")}</Button>
            </Row>

            <Row>
                <Col span={24}>
                    <div id={"main1"} style={{height: "400px"}}/>
                </Col>
                {/*<Col span={12}>*/}
                {/*    <div id={"main2"} style={{height:"400px"}}/>*/}
                {/*</Col>*/}
            </Row>
            <Divider/>

            <DataGrid isShowSearch={true} url={"/clothes/statistic/getProductOrderList"} searchData={searchData} onSearch={(data:any)=>{
                setGridSearchData(data)

            }}
                      descriptions={[
                          {
                              fieldName: "createTime",
                              fieldShowName: i18n.t("时间",)
                          },
                          {
                              fieldName: "serialNumber",
                              fieldShowName: i18n.t("文件号码",)
                          },
                          {
                              fieldName: "customerId",
                              fieldShowName: i18n.t("客户",),
                              inputType: 6,
                              dictionary: "clothes_customer"
                          },
                          {
                              fieldName: "skuName",
                              fieldShowName: i18n.t("商品编码",)
                          },
                          {
                              fieldName: "type",
                              fieldShowName: i18n.t("产品类型",)
                          },
                          {
                              fieldName: "skuCode",
                              fieldShowName: i18n.t("产品编号",)
                          },
                          {
                              fieldName: "category",
                              fieldShowName: i18n.t("产品标签",)
                          },
                          {
                              fieldName: "factoryId",
                              fieldShowName: i18n.t("加工厂",),
                              inputType: 6,
                              dictionary: "clothes_customer"
                          },
                          {
                              fieldName: "schedule",
                              fieldShowName: i18n.t("工艺",)
                          },

                      ]}/>

        </div>
    );
}