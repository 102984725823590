import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Form, Input, Table} from "antd";
import "../../CSS/customer.css";
import { FormInstance } from "antd/es/form";
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 15 },
};
interface Params {
  childForm: FormInstance;
}
export default forwardRef(({ childForm}: Params,ref: any) => {
  const [nums,setNums] = useState({
    count:0,
    index:-1
  })

  useEffect(function () {
    childForm.setFieldsValue({
      count:0,
      index:-1
    })
  },[])

  useImperativeHandle(ref, () => ({
    // setChildNums: (count:any,index:any,isEdit:any) => {
    //   if(isEdit){
    //     setNums({
    //       count:count,
    //       index:index
    //     })
    //   }
    //   return nums;
    // }
    setChildNums: (count:any,index:any) => {
      childForm.setFieldsValue({
        count:count,
        index:index
      })
    }
  }));
  return (
      <>
        <Form
            {...layout}
            form={childForm}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
        >
          <Form.Item name="count" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="index" hidden>
            <Input />
          </Form.Item>
        </Form>
      </>
  );
});
