import React from "react";
import { Tabs } from "antd";

import TabA from "./TabA";
import TabB from "./TabB";
import TabC from "./TabC";
import i18n from "i18next";

const { TabPane } = Tabs;

export default () => {
    const callbackTabs = (key: any) => {
        console.log(key);
    };

    return (
        <div className="u-box u-basic-page u-MaterialArchivesForm">
            <div className="u-box-con u-fixed-padding">
                <div className="u-tabsA">
                    <div className="u-form-lr">
                        {i18n.t("formulaA")}
                        <br/>
                        {i18n.t("formulaB")}
                        <Tabs defaultActiveKey={"1"} onChange={callbackTabs}>
                            <TabPane tab={i18n.t("价格表")} key="1">
                                <TabA/>
                            </TabPane>
                            <TabPane tab={i18n.t("通用价格表")} key="2">
                                <TabB/>
                            </TabPane>
                            <TabPane tab={i18n.t("客户定制价格表")} key="3">
                                <TabC/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};
