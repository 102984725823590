import React, {useState, useEffect, useRef, useCallback} from "react";
import {Button, Divider, Form, Input, InputNumber, message, Modal, Row, Space} from "antd";

import { jsonInstance as axiosj } from "../../../../../../util/axios";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useHistory, useParams } from "react-router";
import "../../CSS/BasicData.css";
import "../../CSS/orderPur.css";
import { DictionarySelects } from "../../../../../../components/DictionarySelectFilter";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { useUpdateEffect, useWindowSize } from "react-use";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../../components/EditForm";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import i18n from "i18next";
import GetRender from "../../Sku/Detail/GetRender";
import { Description } from "../../../../../../schemas/description";
import { DataGrid } from "../../../../../../components/DataGrid";
import DateTimePickerWithFormat from "../../../../../../components/DateTimePicker";
import moment from "moment";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import TextArea from "antd/es/input/TextArea";
import {inputType} from "../../../../../../constants/inputType";
import {BaseURL} from "../../../../../../constants/url";
import TreeSelectByType from "../../../../../../components/TreeSelectByType";
import np from "number-precision";
import {formatDateTime} from "../../util/DateFormat";
import {closeCurrentTab, createNewTab} from "../../../../../../util/tyy";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
const remarkLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 20 },
};

export interface Params {
  uuid: string;
  type: string;
}
type DataSourceType = {
  id: React.Key;
  associate?: string;
  questionsNum?: number;
  type?: string;
  fraction?: number;
  scoringMethod?: string;
};

export default () => {
  const history = useHistory();
  const { width, height } = useWindowSize();
  const totalDataGridRef = useRef<any>(null);
  const [totalNamespace, setTotalNamespace] = useState(
    "clothes_order_pur_total.all"
  );
  const [totalEntityName, setTotalEntityName] = useState(
    "clothes_order_pur_total"
  );
  const [totalCurrentDescriptions, setTotalCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);
  const [totalLoading, setTotalLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          //关闭当前页签
          // closeCurrentTab()
          history.push("/clothes/orderPur");
        },
      });
    }else{
      history.push("/clothes/orderPur");
    }

  }, []);


  const detailDataGridRef = useRef<any>(null);

  const [detailLoading, setdetailLoading] = useState(false);

  const [detailTableForm] = Form.useForm();
  const [open, setOpen] = useState(false);



  const [pagTitle, setPagTitle] = useState("");
  const [form] = Form.useForm();
  const param = useParams<Params>();
  const uuid = param.uuid;
  const type = param.type;

  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/orderPur/getOrderPurById`, {
          orderId: uuid,
        })
        .then((resp: any) => {
          if (resp.data.Status === "1" && resp.data.Data) {
            let data: any = resp.data.Data;
            let purchaseTime = data.purchaseTime;
            data.purchaseTime = null;
            form.setFieldsValue(data);
            form.setFieldsValue({
              purchaseTime: moment(purchaseTime, "YYYY-MM-DD HH:mm:ss"),
            });
          }else{
            form.setFieldsValue({
              uuid: uuid,
              purchaseTime: moment(),
              purchaseStaff: localStorage.getItem("userId")
            });
          }
        });
  }, [uuid,type]);

  useEffect(() => {
    setPagTitle(type == "add" ? i18n.t("新增采购订单") : i18n.t("编辑入库订单"));
  }, [type]);

  const onFinish = async (values: any) => {
    await saveDataGrid(detailDataGridRef);
    var detailList = await detailDataGridRef.current.getData();
    if(detailList && detailList.length > 0){
      try {
        console.log(detailList)
        await detailList.map((item: any) => {
          //商品编码和商品简介必填
          if (!item.skuId || !item.skuCode) {
            message.error(i18n.t("商品编码和商品简介必须填写"));
            throw new DOMException("");
          }
          if (!item.property) {
            message.error(i18n.t("货品类型必须填写"));
            throw new DOMException("");
          }

          if (item && item.labelArr && item.labelArr.length > 0) {
            item.label = item.labelArr.join(",");
          }
        })
      }catch (e) {
        return false
      }
    }
    values.detailList = detailList;
    values.purchaseTime = formatDateTime(values.purchaseTime);
    axiosj
      .post<Result<any[]>>(`/clothes/orderPur/saveOrderPur?orderId=`+uuid, values)
      .then((resp) => {
        setIsChange(false);
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          refresh().then(()=>{
            window.location.reload();
          })
        }
      });
  };

  function refresh():Promise<string> {
    history.push("/clothes/orderPurDetail/"+uuid+"/edit");
    return new Promise((resolve, reject) => {
        resolve("")
    });
  }

  const onValuesChange = (values: any) => {
    setIsChange(true);
  };

  /**
   * 计算总数量
   * 计算总金额
   */
  function addQuantityAndAmount() {
    console.log(detailDataGridRef.current.getData())

  }

  function getSupplyPrice(skuId:any) {
    const supplyId = form.getFieldValue("supplier")
    if(supplyId){
      axios.get("/clothes/sku/getPriceBySupplyId?skuId="+skuId+"&supplyId="+supplyId).then((res)=>{
        if(res.data.Status == 1){
          const price = res.data.Data;
          if(price){
            detailTableForm.setFieldValue("price", price);
          }
        }
      })
    }
  }

  /**
   * 获取商品标签 单位
   * @param skuId
   */
  function getSkuLabel(skuId:any) {
      axios.post("/clothes/sku/getSkuById?uuid="+skuId).then((res)=>{
        if(res.data.Status == 1){
          const data = res.data.Data;
          console.log(res.data)
          if(data && data.categoryId && data.categoryId.split(",").length > 0){
            detailTableForm.setFieldValue("labelArr", data.categoryId.split(","));
          }else{
            detailTableForm.setFieldValue("labelArr", []);
          }
          if(data && data.unitId){
            detailTableForm.setFieldValue("skuUnit", data.unitId);
          }else{
            detailTableForm.setFieldValue("skuUnit", "");
          }
          if(data && data.property){
            detailTableForm.setFieldValue("property", data.property);
          }else{
            detailTableForm.setFieldValue("property", "");
          }

        }
      })
  }


  const column:any = [
    {
      fieldShowName: i18n.t("商品编码",),
      fieldName: 'skuId',
      dictionary: "clothes_sku",
      inputType: inputType.DICTIONARY,
      width: 100,
      editable: true,
      editRender:()=>{
        return (
            <DictionarySelect entityName={"clothes_sku"} addAble={true} localAdd={true} onAdd={(item)=>{
              detailTableForm.setFieldsValue({skuId:item.value,});
              // 把新增的数据加入到datagrid临时数据里面
              detailDataGridRef.current.addDictionary({"skuId.clothes_sku":item});
              setOpen(false)
            }} closeOnAdd={true}
            onChange={(item)=>{
              detailTableForm.setFieldsValue({skuCode:item});
              //获取供应商专属价格
              getSupplyPrice(item);
              getSkuLabel(item);
            }}
            />
        )
      },
      onChange:()=>{

      }
    },
    {
      fieldShowName: i18n.t('商品简介',),
      fieldName: 'skuCode',
      inputType: inputType.DICTIONARY,
      dictionary: "dict_sku_code",
      width: 100,
      editable: true,
      editRender:()=>{
        return (
            <DictionarySelect entityName={"dict_sku_code"} addAble={true} localAdd={true} onAdd={(item)=>{
              detailTableForm.setFieldsValue({skuCode:item.value,});
              // 把新增的数据加入到datagrid临时数据里面
              detailDataGridRef.current.addDictionary({"skuCode.dict_sku_code":item});
              setOpen(false)
            }} closeOnAdd={true}
                              onChange={(item)=>{
                                detailTableForm.setFieldsValue({skuId:item})
                                //获取供应商专属价格
                                getSupplyPrice(item);
                                getSkuLabel(item);
                              }}
            />
        )
      },
    },
    {
      fieldShowName: i18n.t('商品类别',),
      fieldName: 'property',
      dictionary: "dict_sku_property",
      width: 100,
      inputType: 6,
      editable: true,
      editRender:()=>{
        return <DictionarySelects entityName={"dict_sku_property"} filter={" uuid != -1 "}/>
      }
    },
    {
      fieldShowName: i18n.t('标签',),
      fieldName: 'labelArr',
      dictionary: "clothes_sku_category",
      width: 100,
      editable: true,
      editRender:()=>{
        return <TreeSelectByType entityName={"clothes_sku_category"} multiple={true}/>
      }
    },
    {
      fieldShowName: i18n.t('采购单位',),
      fieldName: 'skuUnit',
      dictionary: "dict_sku_unit",
      inputType: inputType.DICTIONARY,
      width: 100,
      editable: true
    },
    {
      fieldShowName: i18n.t('采购数量',),
      fieldName: 'quantity',
      inputType: inputType.DOUBLE,
      width: 100,
      editable: true,
      onChange:(quantity:any)=>{
        //采购单价:
        var price = detailTableForm.getFieldValue("price");
        if(!price){
          price = 0;
        }
        detailTableForm.setFieldsValue({amount: np.times(quantity,price)});
        addQuantityAndAmount();
      }
    },
    {
      fieldShowName: i18n.t('采购单价',),
      fieldName: 'price',
      inputType: inputType.DOUBLE,
      width: 100,
      editable: true,
      onChange:(price:any)=>{
        var quantity = detailTableForm.getFieldValue("quantity");
        if(!quantity){
          quantity = 0;
        }
        detailTableForm.setFieldsValue({amount: np.times(quantity,price)});
        addQuantityAndAmount();
      }
    },
    {
      fieldShowName: i18n.t('金额小计',),
      fieldName: 'amount',
      inputType: inputType.DOUBLE,
      width: 100,
      readOnly: true
    }
  ];


  const [skuType, setSkuType] = useState();
  return (
      <div className="u-box u-basic-page u-MaterialArchivesForm">
        <div className="u-box-head u-box-head-border">
          <span className="u-box-title">{pagTitle}</span>
        </div>
        <div className="u-box-con u-fixed-padding u-Table-border">
          <Form.Provider onFormFinish={(name, {values, forms}) => {
          }}>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="editForm"
                autoComplete="off"
                onFinish={onFinish}
                scrollToFirstError={true}
                onValuesChange={onValuesChange}
            >
              <Form.Item name="uuid" hidden></Form.Item>
              <Form.Item name="createTime" hidden></Form.Item>
              <Form.Item name="creatorId" hidden></Form.Item>
              <div className="order-FourCon">
                <div className="u-form-wrap">
                  <Form.Item
                      label={i18n.t("时间")}
                      name="purchaseTime"
                      rules={[
                        {
                          required: true,
                          validator: async (rule, value) => {
                            if (value == undefined) {
                              message.error(i18n.t("时间不能为空"));
                              throw new Error(i18n.t("时间不能为空"));
                            }
                            let purchaseTime = value.format("YYYY-MM-DD HH:mm:ss");
                            if (
                                purchaseTime == undefined ||
                                purchaseTime.trim() == ""
                            ) {
                              message.error(i18n.t("时间不能为空"));
                              throw new Error(i18n.t("时间不能为空"));
                            }
                          },
                        },
                      ]}
                  >
                    <DateTimePickerWithFormat
                        showTime={false}
                        style={{width: "100%"}}
                    />
                  </Form.Item>
                  <Form.Item
                      label={i18n.t("采买人员")}
                      name="purchaseStaff"
                  >
                    <DictionarySelect entityName={"base_staff"} disabled={true}/>
                  </Form.Item>
                  <Form.Item
                      label={i18n.t("供应商")}
                      name="supplier"
                      rules={[
                        {
                          required: true,
                          validator: async (rule, value) => {
                            if (value == undefined || value.trim() == "") {
                              message.error(i18n.t("供应商不能为空"));
                              throw new Error(i18n.t("供应商不能为空"));
                            }
                          },
                        },
                      ]}
                  >
                    <DictionarySelects entityName={"clothes_customer"} filter={" customerType = 3"}/>
                  </Form.Item>

                  <Form.Item
                      label={i18n.t("税前总金额")}
                      name="totalAmount"
                  >
                    <Input disabled={true}/>
                  </Form.Item>
                  <Form.Item
                      label={i18n.t("总数量")}
                      name="quantity"
                  >
                    <Input disabled={true}/>

                  </Form.Item>
                  <Form.Item
                      label={i18n.t("税率类别")}
                      name="taxRateId"
                  >
                     <DictionarySelect entityName={"dict_tax"} onChange={(value)=>{
                       axios
                           .post<Result<any[]>>(`/clothes/tax/getTaxById`, {
                             taxId: value,
                           }).then((data:any)=>{
                             if(data.data.Status == 1){
                               form.setFieldValue("taxRate",data.data.Data.taxRate)
                             }
                       })
                     }}/>
                  </Form.Item>
                  <Form.Item
                      label={i18n.t("税率")}
                      name="taxRate"
                  >
                     <Input disabled={true}/>
                  </Form.Item>

                  <Form.Item
                      label={i18n.t("税后总金额")}
                      name="amount"
                  >
                    <Input disabled={true}/>
                  </Form.Item>

                  <Form.Item
                      label={i18n.t("备注")}
                      name="remark"
                  >
                    <TextArea rows={3}/>
                  </Form.Item>
                </div>
              </div>
              <Row>
                <DataGrid
                    ref={detailDataGridRef}
                    url={
                      "/clothes/orderPur/getOrderPurDetailList?orderId=" + uuid
                    }
                    descriptions={column}
                    pagination={false}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    form={detailTableForm}
                    onValuesChange={onValuesChange}
                    onClick={(record)=>{
                      editOtherLine(detailDataGridRef,record);
                    }}
                />
              </Row>
              <div className="u-form">

                <div style={{paddingBottom: 25}}>
                  <Space>
                    <Button
                        style={{width: 100}}
                        onClick={async () => {
                          await addLine(detailDataGridRef);
                        }}
                    >
                      {i18n.t("add")}
                    </Button>
                  </Space>
                </div>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改采购订单") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                        }}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
