import React, { useEffect, useState } from "react";
import { message, Modal, Input, Button, Form, Row, Col } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { UserSelect } from "../../../../../../components/UserSelect";
import { User } from "../../../../../../components/UserSelect/schema";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
export interface CustomerMemberAddDialogProps {
  visible: boolean;
  customerIds: string[];
  onCancel: () => void;
  onOk: () => void;
}

export const CustomerMemberAddDialog = (
  props: CustomerMemberAddDialogProps
) => {
  const [userIds, setUserIds] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userNames, setUserNames] = useState("");
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  useEffect(
    function () {
      setUserNames("");
      setUserIds("");
      setRoleId("");
    },
    [props.visible]
  );
  const formItemLayout = {
    labelCol: {
      xs: { span: 32 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <>
      <Modal
        title="添加成员"
        width={450}
        bodyStyle={{ paddingBottom: 50 }}
        visible={props.visible}
        onCancel={() => {
          props.onCancel();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              props.onCancel();
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (!userIds) {
                message.error("请选择用户");
                return;
              }
              if (!roleId) {
                message.error("请选择指定客户角色");
                return;
              }
              axios
                .post("/crm/customer/role/saveCustomerRoleStaff", {
                  customerIds: props.customerIds.join(","),
                  roleId: roleId,
                  staffIds: userIds,
                })
                .then((resp) => {
                  if (resp.data.Status === "1") {
                    message.success("操作成功");
                    props.onOk();
                  }
                });
            }}
          >
            确定
          </Button>,
        ]}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <Form {...formItemLayout}>
            <Form.Item label="成员" style={{ margin: 0 }}>
              <Row gutter={8}>
                <Col span={20}>
                  <Form.Item>
                    <Input value={userNames} style={{ width: 200 }} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button
                    type={"link"}
                    onClick={() => {
                      setUserSelectVisible(true);
                    }}
                  >
                    更多人员
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="指定客户角色"
              labelAlign={"right"}
              style={{ margin: 0 }}
            >
              <Row gutter={8}>
                <Col span={20}>
                  <Form.Item>
                    <DictionarySelect
                      value={roleId}
                      style={{ width: 200 }}
                      entityName={"crm_customer_role"}
                      multiple={false}
                      onChange={(value) => {
                        setRoleId(value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <UserSelect
        visible={userSelectVisible}
        defaultSelectedUsers={[]}
        multiple={true}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
        onOk={(users: User[]) => {
          setUserSelectVisible(false);
          setUserIds(users.map((user) => user.id).join(","));
          setUserNames(users.map((user) => user.name).join(","));
        }}
      />
    </>
  );
};
