import React from "react"
import {editOtherLine} from "../../components/EditForm";
import {DataGrid} from "../../../../../../components/DataGrid";
import i18n from "i18next";

export interface Params {
    changeType: string;
    customerId: string;
}

export default ({customerId,changeType}:Params)=>{

    return (
        <>
            <DataGrid
                url={
                    "/clothes/changeHistory/getChangeHistory"
                }
                searchData={{changeType:changeType,formId:customerId}}
                descriptions={[
                    {
                        fieldShowName: i18n.t("修改时间",),
                        fieldName: "createTime",
                        width: 180,
                        showTitle: false,
                    },
                    {
                        fieldShowName: i18n.t("修改人",),
                        fieldName: "creatorId",
                        width: 180,
                        dictionary: "base_staff",
                        showTitle: false,
                    },

                    {
                        fieldShowName: i18n.t("修改内容",),
                        fieldName: "content",
                        width: 500,
                        showTitle: true,
                    },
                ]}
                loadAllDictionary={true}
                isShowSearch={false}
            />
        </>
    )
}