import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import {Form, message, Button, Space, Modal} from "antd";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../../schemas/description";
import { useUpdateEffect } from "react-use";
import {
  addLine,
  editOtherLine,
  saveDataGrid,
} from "../../components/EditForm";
import { DictionarySelects } from "../../../../../../components/DictionarySelectFilter";
import GetRender from "./GetRender";
import { validateMessages } from "../../util/DocumentEvent";
import { isNullOrUndefined } from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import { instance as axios,instanceNoError as axiosE } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { FormInstance } from "antd/es/form";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Params {
  form: FormInstance;
  skuId: string;
  property: string;
  huiZongForm:any;
  ref:any;
}
export default forwardRef(({ form, skuId,property,huiZongForm }: Params,ref:any) => {
  const dataGridRef = useRef<any>(null);
  const history = useHistory();
  const [namespace, setNamespace] = useState(
    "clothes_sku_working_procedure.all"
  );
  const [originData, setOriginData] = useState([
    {
      rawMaterialCost: 0,
      liningCost: 0,
      accessoriesCost: 0,
      craftCost: 0,
      totalCost: 0,
    },
  ]);
  const [realEntityName, setRealEntityName] = useState(
    "clothes_sku_working_procedure"
  );
  const [entityName, setEntityName] = useState("clothes_sku_working_procedure");
  const [craftTemplateId, setCraftTemplateId] = useState("");
  const [skuCraftTemplateId, setSkuCraftTemplateId] = useState("");
  const [isExists, setIsExists] = useState(false);
  const [urlData, setUrlData] = useState("1");
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const callBack = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/sku/skuManage/"+property);
        },
      });
    }else{
      history.push("/sku/skuManage/"+property);
    }

  }, []);
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };

  useImperativeHandle(ref, () => ({
    submits() {
      form.submit();
    },
  }));

  useEffect(function () {
    axios
      .post<Result<any[]>>(`/clothes/sku/getCostBySkuId`, {
        skuId: skuId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          if (data != undefined) {
            setOriginData([
              {
                rawMaterialCost: data.rawMaterialCost,
                liningCost: data.liningCost,
                accessoriesCost: data.accessoriesCost,
                craftCost: data.craftCost,
                totalCost: data.totalCost,
              },
            ]);
          }
        }
      });
  }, []);
  useEffect(function () {
    let name = form.getFieldValue("name");
    axios
      .post<Result<any[]>>(`/clothes/sku/isExistsTemplate`, {
        skuId: skuId,
        name: name,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data: any = resp.data.Data;
          console.log("data:", data);
          if (data.isExists) {
            setIsExists(data.isExists);
            setTemplateId(data.craftTemplateId);
            setSkuCraftTemplateId(data.craftTemplateId);
            setCraftTemplateId(data.craftTemplateId);
          } else {
            setSkuCraftTemplateId(data.craftTemplateId);
          }
        }
      });
  }, []);
  useEffect(
    function () {
      setTemplateId(craftTemplateId);
    },
    [craftTemplateId]
  );
  const setTemplateId = useCallback(function setTemplateId(
    craftTemplateId: string
  ) {
    form.setFieldsValue({
      craftTemplateId: craftTemplateId,
    });
    setUrlData(
      "/clothes/sku/getWorkingProcedureByTemplateId?craftTemplateId=" +
        craftTemplateId
    );
  },
  [craftTemplateId]);

  const onFinish = async (values: any) => {
    let categoryId = values.categoryId;
    values.categoryId = categoryId.join(",");
    if (values.isBigPackage == undefined) {
      values.isBigPackage = "0";
    } else {
      values.isBigPackage = values.isBigPackage[0];
    }
    if (values.isProcessingMaterial === undefined) {
      values.isProcessingMaterial = "0";
    } else {
      values.isProcessingMaterial = values.isProcessingMaterial[0];
    }
    if (values.isMulticolor === undefined) {
      values.isMulticolor = "0";
    } else {
      values.isMulticolor = values.isMulticolor[0];
    }
    await saveDataGrid(dataGridRef);
    values.craftTemplateId = skuCraftTemplateId;
    values.rawMaterialCost = originData[0].rawMaterialCost;
    values.liningCost = originData[0].liningCost;
    values.accessoriesCost = originData[0].accessoriesCost;
    values.craftCost = originData[0].craftCost;
    values.totalCost = originData[0].totalCost;
    try {
      let procedureList = await dataGridRef.current.getData();
      await axiosE
        .post<Result<any[]>>(`/clothes/sku/saveProcedure`, {
          data: JSON.stringify(values),
          skuId: skuId,
          craftTemplateId: skuCraftTemplateId,
          procedureList: JSON.stringify(procedureList),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setIsChange(false);
            axios
                .post<Result<any[]>>(`/clothes/sku/getSkuById`, {
                  uuid: skuId,
                })
                .then((resp:any) => {
                  if (resp.data.Status === "1") {
                    form.setFieldsValue(resp.data.Data);
                    form.setFieldsValue({
                      categoryId:resp.data.Data.categoryId.split(",")
                    })
                  }
                });
            // window.location.reload();
          }else{
            if(resp.data.Message == 'noShip'){
              message.error(i18n.t("工序必须包含发货环节"))
            }
          }
        });
    } catch (e) {
      message.error(i18n.t("saveError"));
      setLoading(false);
    }
  };


  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const [tableForm] = Form.useForm();

  function format() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(currentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: (text:any,record:any)=>{
        return (
            <>
              <a
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(record);
                    Modal.confirm({
                      title: i18n.t("删除提示"),
                      icon: <ExclamationCircleOutlined/>,
                      content: i18n.t("删除操作"),
                      okText: i18n.t("确定"),
                      okType: "danger",
                      cancelText: i18n.t("cancelText"),
                      onOk() {
                        dataGridRef.current.remove(record.key);
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
              >
                {i18n.t("delete")}
              </a>
            </>
        )
      },
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
        if(description.fieldName != 'sort'){
            description.editable = true;
        }
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }
  const mountCost = async ()=>{
    let gongxu = await dataGridRef.current.getData();
    let craftCost = 0.0;
    let rawMaterialCost = originData[0].rawMaterialCost;
    let liningCost = originData[0].liningCost;
    let accessoriesCost = originData[0].accessoriesCost;
    let buyingPrice = form.getFieldValue("buyingPrice");
    if (isNaN(rawMaterialCost)) {
      rawMaterialCost = 0.0;
    }
    if (isNaN(liningCost)) {
      liningCost = 0.0;
    }
    if (isNaN(accessoriesCost)) {
      accessoriesCost = 0.0;
    }
    if (isNaN(buyingPrice)) {
      buyingPrice = Number(0.0);
    }
    if (gongxu != undefined && gongxu.length > 0) {
      gongxu.map((item: any) => {
        if (!isNaN(item.price)) {
          craftCost += Number(item.price);
        }
      });
    }
    console.log(craftCost)
    // 3:自产类 = 物料清单+工艺清单=基本价格
    if (property == "3") {
      await setOriginData([
        {
          rawMaterialCost: rawMaterialCost,
          liningCost: liningCost,
          accessoriesCost: accessoriesCost,
          craftCost: craftCost,
          totalCost: rawMaterialCost + liningCost + accessoriesCost + craftCost,
        },
      ]);
      await form.setFieldsValue({
        standardCost:
            rawMaterialCost + liningCost + accessoriesCost + craftCost,
        rawMaterialCost: rawMaterialCost,
        liningCost: liningCost,
        accessoriesCost: accessoriesCost,
        craftCost: craftCost,
        totalCost: rawMaterialCost + liningCost + accessoriesCost + craftCost
      });
      await huiZongForm.setFieldsValue(
          {data:[{
        standardCost:
            rawMaterialCost + liningCost + accessoriesCost + craftCost,
        rawMaterialCost: rawMaterialCost,
        liningCost: liningCost,
        accessoriesCost: accessoriesCost,
        craftCost: craftCost,
        totalCost: rawMaterialCost + liningCost + accessoriesCost + craftCost
      }]});
    } else {
      // 采购价格+工艺清单 =基本价格
      await setOriginData([
        {
          rawMaterialCost: rawMaterialCost,
          liningCost: liningCost,
          accessoriesCost: accessoriesCost,
          craftCost: craftCost,
          totalCost: Number(buyingPrice) + Number(craftCost),
        },
      ]);
      await form.setFieldsValue({
        standardCost: Number(buyingPrice) + Number(craftCost),
        rawMaterialCost: rawMaterialCost,
        liningCost: liningCost,
        accessoriesCost: accessoriesCost,
        craftCost: craftCost,
        totalCost: rawMaterialCost + liningCost + accessoriesCost + craftCost
      });
      await huiZongForm.setFieldsValue(
          {data:[{
              standardCost:
                  rawMaterialCost + liningCost + accessoriesCost + craftCost,
              rawMaterialCost: rawMaterialCost,
              liningCost: liningCost,
              accessoriesCost: accessoriesCost,
              craftCost: craftCost,
              totalCost: rawMaterialCost + liningCost + accessoriesCost + craftCost
            }]});
    }
  }
  const onDetailSave = async () => {
    let property = await form.getFieldValue("property");
    await saveDataGrid(dataGridRef);
    await mountCost();
  };


  return (
      <>
        <div className="u-form">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                onFinish={onFinish}
                validateMessages={validateMessages}
                onFinishFailed={(err: any) => {
                  setLoading(false);
                }}
                onValuesChange={onValuesChange}
                scrollToFirstError={true}
            >
              <Form.Item
                  label={i18n.t("工艺模板")}
                  name={"craftTemplateId"}
                  style={{left: "-9.5%",zIndex:"1"}}
              >
                <DictionarySelects
                    entityName={"clothes_craft_template"}
                    filter={"skuId IS NULL or skuId = '' OR skuId = '" + skuId + "'"}
                    onChange={(value) => {
                      setCraftTemplateId(value);
                    }}
                    onClick={async () => {
                      await saveDataGrid(dataGridRef);
                      await mountCost();
                    }}
                />
              </Form.Item>
              {/*<Space>*/}
              {/*  <Button*/}
              {/*    style={{ width: 100 }}*/}
              {/*    disabled={isExists}*/}
              {/*    onClick={() => {*/}
              {/*      setCraftTemplateId(skuCraftTemplateId);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    新建模板*/}
              {/*  </Button>*/}
              {/*</Space>*/}

              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <DataGrid
                    ref={dataGridRef}
                    url={urlData}
                    descriptions={format()}
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(dataGridRef, record);
                      await mountCost();
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    form={tableForm}
                    onValuesChange={onValuesChange}
                />
              </Form.Item>
              <div style={{paddingBottom: 25}}>
                <Space>
                  {usePermission("新增修改商品") &&<Button
                      style={{width: 100}}
                      onClick={async () => {
                        // await addLine(dataGridRef);
                        var tempData = dataGridRef.current.getData()
                        await saveDataGrid(dataGridRef);
                        var maxSort = 0;
                        tempData.map((item: any) => {
                          if (item.sort > maxSort) {
                            maxSort = item.sort;
                          }
                        })
                        dataGridRef.current.addData({sort: maxSort + 1});
                      }}
                  >
                    {i18n.t("add")}
                  </Button>}
                </Space>
              </div>
              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改商品") &&<Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          // setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </>
  );
} );
