import React, {useEffect, useState} from "react";
import {DataGrid} from "../../../../../components/DataGrid";
import {Button, Form, Input, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useWindowSize } from "react-use";
import {inputType} from "../../../../../constants/inputType";
import {instance as axios} from "../../../../../util/axios";
import i18n from "i18next";
import {usePermission} from "../../../../../util/permession";

export interface Param {
    docId:string,
    photoUrl:string,
    skuId:any,
    row:any,
}
const TechnicalSpecifications = ({docId,photoUrl,skuId,row}:Param)=>{
    const {width} = useWindowSize();
    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 14},
    }
    const [form] = Form.useForm();

    useEffect(()=>{
        axios.get("/clothes/produceOrder/getProductOrder?id="+docId).then((resp)=>{
            if(resp.data.Status == 1){
                var data = resp.data.Data;
                form.setFieldsValue({technicalInfo:data.technicalInfo?data.technicalInfo:"",barCode:data.barCode})
            }
        })
    },[docId,row])

    const [colorColumn, setColorColumn] = useState<any>(
        [
            {
                fieldShowName: i18n.t('物料类型',),
                fieldName: "type",
                inputType: inputType.DICTIONARY,
                dictionary: "dict_material_type",
                width: "100"
            },
            {
                fieldShowName: i18n.t('名称',),
                fieldName: "name",
                inputType: inputType.DICTIONARY,
                dictionary: "clothes_sku",
                width: "100"
            },
            {
                fieldShowName: i18n.t('描述',),
                fieldName: "code",
                inputType:inputType.DICTIONARY,
                dictionary:"clothes_sku.code",
                width: "100"
            },
            {
                fieldShowName: i18n.t('单位',),
                fieldName: "unitId",
                inputType: inputType.DICTIONARY,
                dictionary: "dict_sku_unit",
                width: "100"
            },
            {
                fieldShowName: i18n.t('需求量',),
                fieldName: 'needQuantity',
            }
    ]);

    return <div>

        <div className="u-table-photo">
            <div className="u-table-left">


                <DataGrid
                    pagination={false}
                    pageSize={5}
                    rowSelectionType="checkbox"
                    descriptions={colorColumn}
                    url={`/clothes/sku/getMaterialBySkuId?skuId=` + skuId+"&count="+row.packageNumber}
                    onClick={(record: any) => {
                    }}
                    isShowSearch={false}
                    style={{width: width - 550}}
                    isShowExport={true}
                />


            </div>
            <div className="u-right">
                <Form {...formItemLayout} form={form}>
                    <Form.Item name={"technicalInfo"} label={i18n.t("技术资料")} style={{width: "400px"}}>
                        <TextArea rows={8}/>
                    </Form.Item>
                    <Form.Item name={"barCode"} label={i18n.t("条形码")}>
                        <Input/>
                    </Form.Item>


                </Form>
                {usePermission("新增修改生产订单") && <Button htmlType="button" style={{marginLeft: "135px"}} onClick={() => {
                    var data = form.getFieldsValue();
                    data.uuid = docId;
                    axios.post("/clothes/produceOrder/updateProduceOrder", data).then((resp) => {
                        if (resp.data.Status == 1) {
                            message.success(i18n.t("修改成功"));
                        }
                    })
                }}>
                    {i18n.t("保存")}
                </Button>}
            </div>
            <div className="u-photo-left">
                {photoUrl && <img alt='' src={photoUrl}/>}
            </div>
        </div>

    </div>;
}

export default TechnicalSpecifications;