import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {useHistory} from "react-router-dom";
import {
  Form,
  message,
  Button,
  Space, Modal,
} from "antd";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../../schemas/description";
import {useUpdateEffect} from "react-use";
import {addLine, editOtherLine, saveDataGrid} from "../../components/EditForm";
import GetRender from "../../util/Document";
import {validateMessages} from "../../util/DocumentEvent";
import {isNullOrUndefined} from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import {instance as axios} from "../../../../../../util/axios";
import {Result} from "../../../../../../schemas/common";
import moment from "moment";
import {FormInstance} from "antd/es/form";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {inputType} from "../../../../../../constants/inputType";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Params {
  form: FormInstance;
  skuId: string;
  property: string;
}
export default ({ form,skuId,property }: Params) => {
  const dataGridRef = useRef<any>(null);
  const history = useHistory();
  const [namespace, setNamespace] = useState(
      "clothes_sku_supplier.all"
  );
  const [entityName, setEntityName] = useState(
      "clothes_sku_supplier"
  );

  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const callBack = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/sku/skuManage/"+property);
        },
      });
    }else{
      history.push("/sku/skuManage/"+property);
    }

  }, []);
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };

  const onFinish =  async (values: any) => {
    let categoryId = values.categoryId;
    values.categoryId = categoryId.join(",");
    if (values.isBigPackage == undefined) {
      values.isBigPackage = "0";
    } else {
      values.isBigPackage = values.isBigPackage[0];
    }
    if (values.isProcessingMaterial === undefined) {
      values.isProcessingMaterial = "0";
    } else {
      values.isProcessingMaterial = values.isProcessingMaterial[0];
    }
    if (values.isMulticolor === undefined) {
      values.isMulticolor = "0";
    } else {
      values.isMulticolor = values.isMulticolor[0];
    }
    await saveDataGrid(dataGridRef);
    try {
      let supplierList = await dataGridRef.current.getData();
      if(supplierList!=undefined){
        await supplierList.map((item:any)=>{
          item.createTime=moment().format("YYYY-MM-DD").toString();
        })
      }
      await axios
          .post<Result<any[]>>(`/clothes/sku/saveSupplier`, {
            data: JSON.stringify(values),
            skuId: skuId,
            supplierList: JSON.stringify(supplierList),
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              message.info(i18n.t("saveSuccess"));
              setLoading(false);
              setIsChange(false);
            }
          });
    } catch (e) {
      message.error(i18n.t("saveError"));
      setLoading(false);
    }
  };

/*  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
          (nameSpaceDescriptions: any) => {
            if (nameSpaceDescriptions == null) {
              setCurrentDescriptions(descriptions);
            }
          }
      );
    });
  }, []);*/

  const currentDescriptions = [
    {
      fieldName: "supplierNumber",
      fieldShowName: i18n.t("供应商编号",),
      width: 200,
      // align: "center",
    },
    {
      fieldName: "name",
      fieldShowName: i18n.t("供应商",),
      width: 200,
      // align: "center",
      inputType:6,
      dictionary:"clothes_customer",
      editRender:()=>{
        return(
            <DictionarySelects entityName={"clothes_customer"} filter={" customerType = '3' "}/>
        )
      }
    },
    {
      fieldName: "productNumber",
      fieldShowName: i18n.t("供应商产品编号",),
      width: 200,
      // align: "center",
    },
    {
      fieldName: "price",
      fieldShowName: i18n.t("价格",),
      width: 200,
      inputType: "2"

      // align: "center",
    },
    {
      fieldName: "createTime",
      fieldShowName: i18n.t("创建日期",),
      width: 200,
      inputType: "4"
      // align: "center",
    },
  ];

  const [tableForm] = Form.useForm();

  function format() {
    const formattedCurrentDescriptions:any = currentDescriptions;
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(dataGridRef,onDetailSave,entityName,false),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const onDetailSave = async ()=>{
  }

  const [messageInfo, setMessageInfo] = useState<any>();

  return (
      <>
        <div className="u-form1">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                onFinish={onFinish}
                validateMessages={validateMessages}
                onFinishFailed={(err: any) => {
                  setMessageInfo(err);
                  setLoading(false);
                }}
                onValuesChange={onValuesChange}
                scrollToFirstError={true}
            >
              {isNullOrUndefined(messageInfo) ? "" : <OnFinishFaileds values={messageInfo} form={form}/>}

              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <DataGrid
                    ref={dataGridRef}
                    url={
                      "/clothes/sku/getSupplierBySkuId?skuId=" + skuId
                    }
                    descriptions={format()}
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(dataGridRef, record);
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    form={tableForm}
                    onValuesChange={onValuesChange}
                />
              </Form.Item>
              <div style={{paddingBottom: 25}}>
                <Space>
                  <Button style={{width: 100}} onClick={async () => {
                    let item = {
                      uuid: "",
                      skuId: skuId,
                      name: "",
                      supplierNumber: "",
                      productNumber: "",
                      price: 0.00,
                      createTime: moment().format("YYYY-MM-DD"),
                    };
                    await dataGridRef.current.addData(item);
                    // await addLine(dataGridRef);
                  }}>
                    {i18n.t("add")}
                  </Button>
                </Space>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改商品") &&<Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </>
  );
};
