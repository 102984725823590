import React, {useEffect} from "react";
import {useParams} from "react-router";
import {instance as axios} from "../../../../../util/axios";
import {deleteRedisKey} from "../../../../../util/redis";
import {updateLanguage} from "../../../../../util/changeLanguage";

export interface Params {
    language:string;
}

const LanguageChange = ()=>{
    const param = useParams<Params>();
    const language = param.language;
    useEffect(()=>{
        axios.post("/clothes/config/changeLanguage",{language:language}).then((result)=>{
            if(result.data.Status == 1){
                deleteRedisKey("ClientLanguage").then(() => {
                    updateLanguage(true);
                });
            }
        })
    })
    return (
        <></>
    )
}


export default LanguageChange;