import React, {useEffect, useState} from 'react'
import {Modal, Input, Form, Select, Button, message} from 'antd'

import RecordsModal from './RecordsModal'
import EditTable from './EditTable'
import {instance as axios} from "../../../../../../util/axios";
import moment from "moment";
import {Dictionary} from "../../../../../../schemas/dictionary";
import i18n from "i18next";

const tableRef = React.createRef<any>()//这里创建一个给子组件的ref类型暂时不知道写什么就写any先，有知道的大佬麻烦告诉下类型

interface FormProps {
	visible: boolean,
	onCancel: () => void,
	onOk: (v:any, v1:any) => void,
	onApply: (v:any, v1:any) => void,
	initData:any
}

const layout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 17 },
}

export default ({visible, onCancel, onOk,initData,onApply}: FormProps) => {
	const [visibleR, setVisibleR] = useState(false)
	const [form] = Form.useForm()
	const [priceList, setPriceList] = useState([]);

	useEffect(()=>{
		// console.log(initData)
		// form.setFieldsValue(initData.baseData);
		setTimeout(()=>{
			form.setFieldsValue(initData.baseData);
		}, 500);
	},[initData]);

	useEffect(()=>{
		//获取物料对应的价格表信息
		axios.get("/clothes/sku/getSkuPriceList?skuId="+initData.baseData.skuId).then((data)=>{
			if(data.data.Status == 1){
				setPriceList(data.data.Data);
			}
		})
	},[initData.baseData.skuId])

	const onFinish = (v:any) => {
		// let temp = v
		// temp.type = tableRef.current.columnType;
		// onOk(tableRef.current, temp)
		var colorData = tableRef.current.getDatas();
		var baseData = form.getFieldsValue();
		//这里的colorColumns 需要去除render 格式

		//colorData去除没有填过的数
		var tempColorData = colorData.colorData;
		console.log(tempColorData)
		if(tempColorData){
			var resultData:any = [];
			tempColorData.map((item:any)=>{
				var isAdd = false;
				var subTotalQuantity = 0;
				for(let index in item){
					var pat = /^size+[0-9]+$/;
					if (index.match(pat)) {
							if(Number(item[index]) > 0){
								isAdd = true;
								subTotalQuantity += Number(item[index]);
							}
							if(Number(item[index]) == NaN){
								message.error(i18n.t("请输入正确的数字!"));
							}
						}
				}
				if(isAdd){
					item.subTotalQuantity = subTotalQuantity;
					resultData.push(item);
				}
			})
			colorData.colorData = resultData;
		}

		onOk(colorData, baseData);

	}

	const onReset = () => {
	    form.resetFields()
		tableRef.current.onReset();
		onApply({}, {});
	}
	const onApplyed = () => {
		var colorData = tableRef.current.getDatas();
	    var data = form.getFieldsValue();
		console.log(colorData,data)
		onApply(data, colorData);
	}

	return (
		<Modal
			className="u-m"
			getContainer={false}
			style={{top: '20px'}}
			width={'90%'}
			visible={visible}
			title={i18n.t("订单明细")}
			footer={null}
			onCancel={onCancel}
			destroyOnClose={true}
			maskClosable={false}
		>

			<div style={{position: 'relative', height: '500px'}}>
				<div className="u-goods-tableForm">

					<EditTable ref={tableRef} initData={initData}/>

					<Form form={form} preserve={false} {...layout} onFinish={onFinish} layout="horizontal"
						  name="colourSize" autoComplete="off">
						<div className="u-goods-tableForm-r">

							<Form.Item label={i18n.t("商品编码")} name="skuCode">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("名称")} name="skuName">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("客户要求")} name="processReq">
								<Input.TextArea autoSize={{minRows: 2, maxRows: 5}}/>
							</Form.Item>
							<Form.Item label={i18n.t("客户条形码")} name="barCode">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("包装规格")} name="packageType">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("衣架")} name="hanger">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("定牌")} name="oem">
								<Input/>
							</Form.Item>
							<Form.Item label={i18n.t("单价")}>
								<Form.Item
									name="price"
									noStyle>
									<Input style={{width: '50px'}}/>
								</Form.Item>
								<Form.Item
									name="dj1"
									noStyle>
									<Select allowClear style={{width: '120px', margin: '0 0 0 10px'}}
											dropdownMatchSelectWidth={250}>
										{priceList && priceList.map((item: any) => (
											<Select.Option value={item.name} key={item.name}><span
												style={{float: "left"}}>{item.name}</span><span style={{
												float: "right",
												paddingRight: "50px"
											}}>{item.price}</span></Select.Option>
										))}
									</Select>
								</Form.Item>
							</Form.Item>
							<Form.Item label={i18n.t("历史订单")} name="ia">
								<Select allowClear onClick={() => {
									setVisibleR(true)
								}}>
								</Select>
							</Form.Item>


						</div>
						<div className="u-btn-toolbar u-btn-bottom" style={{padding: '0', width: '600px', right: '0'}}>
							<div style={{float: 'right'}}>
								<Button type="primary" htmlType="submit">
									{i18n.t("确定")}
								</Button>
								<Button type="primary" htmlType="button" onClick={onReset}>
									{i18n.t("全清")}
								</Button>
								<Button type="primary" htmlType="button" onClick={onApplyed}>
									{i18n.t("应用")}
								</Button>
								<Button type="primary"
										onClick={onCancel}
								>
									{i18n.t("取消")}
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
			<RecordsModal
				visible={visibleR}
				onCancel={() => {
					setVisibleR(false)
				}}
				onOk={(v: any) => {
					console.log(v)
					setVisibleR(false)
				}}
				skuId={initData.baseData.skuId}
			/>
		</Modal>
	);
}