import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react"
import {Form, Input, Select, Button, message} from 'antd'
import {useUpdateEffect} from "react-use";
import i18n from "i18next";
export interface remarkParam {
	formData:any,
	headerRemark:any,
	endRemark:any
}
export const TabWJBZ = forwardRef(({formData,headerRemark,endRemark}:remarkParam,ref:any)=>{
	const [optionData1, setOptionData1] = useState<any>([])
	const [optionData2, setOptionData2] = useState<any>([])
	const [fieldValue1, setFieldValue1] = useState('')
	const [fieldValue2, setFieldValue2] = useState('')

	const onChangeTextArea1 = (v:any) => {
		setFieldValue1(v.target.value)
	}

	const onChangeTextArea2 = (v:any) => {
		setFieldValue2(v.target.value)
	}

	const onChangeSelect1 = (v:any) => {
		formData.setFieldsValue({ wjbz11: v })
	}

	const onChangeSelect2 = (v:any) => {
		formData.setFieldsValue({ wjbz22: v })
	}

	useEffect(()=>{
		console.log(headerRemark,endRemark)
		if(headerRemark){
			setOptionData1(JSON.parse(headerRemark))
		}
		if(endRemark){
			setOptionData2(JSON.parse(endRemark))
		}
	},[]);


	useImperativeHandle(ref, () => ({
		getHeaderRemark() {
			return JSON.stringify(optionData1);
		},
		getEndRemark() {
			return JSON.stringify(optionData2);
		}
	}))

	useUpdateEffect(()=>{
		formData.setFieldsValue({headerRemark:JSON.stringify(optionData1),endRemark:JSON.stringify(optionData2)});
	},[optionData1,optionData2])

	return (
		<>
		<div className={"u-tab-con u-tab-wjbz"}>
			<Form.Item label={i18n.t("发货须知")} name="packingRequirements">
				<Input.TextArea autoSize={{minRows: 3, maxRows: 5}}/>
			</Form.Item>
		</div>
		<div className="u-tab-con u-tab-wjbz">
			<Form.Item label="文件头备注">
				<Form.Item
					name="wjbz1"
					noStyle>
					<Select allowClear onChange={onChangeSelect1}>
						{optionData1 && optionData1.map((item: any, idx: any) => (
							item.value === '' ? null :
								<Select.Option value={item.value} key={item.value}>{item.value}</Select.Option>
						))}
					</Select>
				</Form.Item>
				<div className="u-btn-toolbar">
					<Button
						size="small"
						onClick={() => {
							setOptionData1([...optionData1, {value: fieldValue1}])
							formData.setFieldsValue({wjbz1: fieldValue1})
							message.success(i18n.t("增加备注成功"));
						}}>
						保存</Button>
					<Button size="small"
							onClick={() => {
								var currentReamrk = formData.getFieldValue("wjbz1");
								let arr: any = optionData1
								for (let i = 0; i < arr.length; i++) {
									if (currentReamrk === arr[i].value) {
										arr.splice(i, 1)
									}
								}
								setOptionData1([...arr])
								formData.setFieldsValue({wjbz1: ''})
								formData.setFieldsValue({wjbz11: ''})
								message.success(i18n.t("删除备注成功"));
							}}>{i18n.t("删除")}</Button>
				</div>
				<Form.Item
					name="wjbz11"
					noStyle>
					<Input.TextArea
						onChange={onChangeTextArea1}
						autoSize={{minRows: 5, maxRows: 8}}/>
				</Form.Item>
			</Form.Item>
			<Form.Item label={i18n.t("文件尾备注")}>
				<Form.Item
					name="wjbz2"
					noStyle>
					<Select allowClear onChange={onChangeSelect2}>
						{optionData2 && optionData2.map((item: any, idx: any) => (
							item.value === '' ? null :
								<Select.Option value={item.value} key={item.value}>{item.value}</Select.Option>
						))}
					</Select>
				</Form.Item>
				<div className="u-btn-toolbar">
					<Button
						size="small"
						onClick={() => {
							setOptionData2([...optionData2, {value: fieldValue2}]);
							formData.setFieldsValue({wjbz2: fieldValue2})
							message.success(i18n.t("增加备注成功"));
						}}>
						{i18n.t("保存")}</Button>
					<Button size="small"
							onClick={() => {
								var currentReamrk = formData.getFieldValue("wjbz2");
								let arr: any = optionData2
								for (let i = 0; i < arr.length; i++) {
									if (currentReamrk === arr[i].value) {
										arr.splice(i, 1)
									}
								}
								setOptionData2([...arr])
								formData.setFieldsValue({wjbz2: ''})
								formData.setFieldsValue({wjbz22: ''})
								message.success(i18n.t("删除备注成功"));
							}}>删除</Button>
				</div>
				<Form.Item
					name="wjbz22"
					noStyle>
					<Input.TextArea onChange={onChangeTextArea2}
									autoSize={{minRows: 5, maxRows: 8}}/>
				</Form.Item>
			</Form.Item>

		</div>
			</>
	);

})
