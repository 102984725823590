import React, { useCallback, useEffect, useRef, useState } from "react";
import {Form, Input, Button, Space, message, Table, Modal} from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import "../../CSS/customer.css";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { useHistory, useParams } from "react-router";
import EnterpriseList from "./enterpriseList";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import { stringify } from "querystring";
import moment from "moment";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {isEmpty} from "../../util/Common";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Param {
  current: any;
}
interface Params {
  enterpriseId: string;
}
interface Params2 {
  enterpriseIdd: string;
  onclose:()=>void;
}
export default ({enterpriseIdd,onclose}:Params2) => {
  const [form] = Form.useForm();
  const params = useParams<Params>();
  const { height } = useWindowSize();
  const enterpriseId = enterpriseIdd;
  const history = useHistory();
  const [visiable, setVisiable] = useState(false);
  const hideModal = () => {
    setVisiable(false);
  };
  useEffect(function () {
    axios
      .post<Result<any[]>>(
        `/oa/appEnterpriseManagement/getEnterpriseManagementById`,
          isEmpty(enterpriseIdd)?{
          enterpriseId: enterpriseId,
        }:{
            enterpriseId: enterpriseIdd,
          }
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          form.setFieldsValue(resp.data.Data);
        }
      });
  }, []);
  const [originData, setOriginData] = useState<any>([]);
  function getRender() {
    if (originData.length > 0) {
      return function (_: any, record: any) {
        return (
          <a
            href="#"
            className="deleteA"
            onClick={(e: any) => {
              Modal.confirm({
                title: i18n.t("app_enterprise_remove_operation"),
                icon: <ExclamationCircleOutlined />,
                content: i18n.t("app_enterprise_remove_sub"),
                okText: i18n.t("determine"),
                cancelText: i18n.t("cancel"),
                onOk: function () {
                  axios
                      .post<Result<any[]>>(
                          `/oa/appEnterpriseManagement/deleteSubAccountEnterprise`,
                          {
                            originEnterpriseId: record.originEnterpriseId,
                          }
                      )
                      .then((resp) => {
                        if (resp.data.Status === "1") {
                          let arr = originData.filter(
                              (item: any) => item.key != record.key
                          );
                          setOriginData(arr);
                        }
                      });
                },
              });

            }}
          >
            {i18n.t("app_enterprise_remove")}
          </a>
        );
      };
    }
  }
  const onFinish = async (values: any) => {
    console.log("Finish:", values);
    if (originData.length > 0) {
      axios
        .post("/oa/appEnterpriseManagement/saveSubAccount", {
          subAccount: JSON.stringify(thisRef.current.originData),
          corpId: form.getFieldValue("id"),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.success(i18n.t("app_enterprise_merged_success"));
            setTimeout(()=>{
              onclose()
            }, 2000);
          }
        });
    } else {
      axios
        .post("/oa/appEnterpriseManagement/deleteSubAccountByParentId", {
          enterpriseId: enterpriseIdd,
          corpId: form.getFieldValue("id"),
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.success(i18n.t("app_enterprise_merged_success"));
            setTimeout(()=>{
              onclose()
            }, 2000);
          }
        });
    }
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    window.scrollTo(0, 0);
  };
  const thisRef: Param = useRef();
  thisRef.current = {
    originData,
  };
  const getSubAccountList = useCallback(function getSubAccountList() {
    axios
      .post<Result<any[]>>(`/oa/appEnterpriseManagement/getSubAccountList`, {
        enterpriseId: enterpriseIdd,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          let data = resp.data.Data;
          if (data != undefined) {
            data.map((item: any) => {
              item.key = item.uuid;
              item.isUpdate = 0;
            });
            setOriginData(data);
          } else {
            setOriginData([]);
          }
        }
      });
  }, []);
  useEffect(function () {
    getSubAccountList();
  }, []);
  const setSubAccountData = useCallback(function (arr: any) {
    if (arr.length > 0) {
      console.log(thisRef.current.originData);
      let newArr: any = [...thisRef.current.originData];
      arr.map((item: any) => {
        let flag = true;
        if (thisRef.current.originData.length > 0) {
          thisRef.current.originData.map((old: any) => {
            if (old.originEnterpriseId == item.id) {
              flag = false;
            }
          });
        }
        if (flag) {
          newArr.push({
            uuid: "",
            name: item.name,
            code: item.code,
            postId: "4c49f4de2e3e44659293975dd55ddfcd",
            contact: item.contact,
            contactWay: item.contactWay,
            type: item.type,
            ip: item.ip,
            parentId: enterpriseIdd,
            originEnterpriseId: item.id,
            mergeTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            registeredTime: item.registeredTime,
            finalLandingTime: item.finalLandingTime,
            isUpdate:0,
            key: item.uuid,
          });
        }
      });
      setOriginData(newArr);
    }
  }, []);

  return (
    <div className="u-box-con u-fixed-padding">
      <div className="u-tabsA">
        <EnterpriseList
          enterpriseId={enterpriseIdd}
          visiable={visiable}
          hideModal={hideModal}
          setSubAccountData={setSubAccountData}
        />

        <Form.Provider>
          <Form
            {...layout}
            form={form}
            layout="horizontal"
            name="basicForm"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="id" hidden>
              <Input readOnly={true} />
            </Form.Item>
            <div className="u-ThreCon">
              <Form.Item label={i18n.t("app_enterprise_main_account")} name="name">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("app_enterprise_account_type")} name="type">
                <DictionarySelect
                  disabled={true}
                  entityName="dict_enterprise_type"
                />
              </Form.Item>
              <Form.Item label={i18n.t("appEnterprise_registeredTime")} name="registeredTime">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("public_contract")} name="contact">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item label={i18n.t("enterprise_contact_information")} name="contactWay">
                <Input readOnly={true} />
              </Form.Item>
              <Form.Item
                className="remark"
                label={i18n.t("app_enterprise_to_be_merged")}
                name="supplierList"
                {...{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }}
              >
                <div className="u-box-con">
                  <Table
                    bordered
                    pagination={false}
                    dataSource={thisRef.current.originData}
                    columns={[
                      {
                        title: i18n.t("appEnterprise_name"),
                        dataIndex: "name",
                        align: "center",
                        width: "18%",
                        render: (text: any, record: any) => {
                          return (
                            <Input
                              defaultValue={record.name}
                              onChange={(e) => {
                                record.name = e.target.value;
                                if(record.uuid!=""){
                                  record.isUpdate=1;
                                }
                              }}
                            />
                          );
                        },
                      },
                      {
                        title: i18n.t("appEnterprise_account_role"),
                        dataIndex: "postId",
                        align: "center",
                        width: "18%",
                        render: (text: any, record: any) => {
                          return (
                            <DictionarySelects
                              entityName="base_position"
                              defaultValue={record.postId}
                              filter={"1=1"}
                              onChange={(value) => {
                                record.postId = value;
                                if(record.uuid!=""){
                                  record.isUpdate=1;
                                }
                              }}
                            />
                          );
                        },
                      },
                      {
                        title: i18n.t("public_contract"),
                        dataIndex: "contact",
                        align: "center",
                        width: "18%",
                      },
                      {
                        title: i18n.t("enterprise_contact_information"),
                        dataIndex: "contactWay",
                        align: "center",
                        width: "18%",
                      },
                      {
                        title: i18n.t("appEnterprise_registeredTime"),
                        dataIndex: "registeredTime",
                        align: "center",
                        width: "18%",
                      },
                      {
                        title: i18n.t("options"),
                        fixed: "right",
                        width: "10%",
                        align: "center",
                        render: getRender(),
                      },
                    ]}
                    rowClassName="editable-row"
                  />
                </div>
                <div style={{ paddingBottom: 25 }}>
                  <Space>
                    <Button
                      style={{ width: 100 }}
                      onClick={async () => {
                        setVisiable(true);
                      }}
                    >
                      {i18n.t("tablegrid_add_to")}
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </div>
            <Form.Item
              className="u-form-custom"
              {...{ wrapperCol: { span: 24 } }}
            >
              <div className="u-button-box">
                <Form.Item>
                  <Button
                    type="primary"
                    className="u-form-buttonB"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {i18n.t("app_enterprise_merge")}
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      if(onclose){
                        onclose();
                      }
                    }}
                    className="u-form-buttonA"
                  >
                    {i18n.t("cancel")}
                  </Button>
                </Form.Item>
              </div>
            </Form.Item>
          </Form>
        </Form.Provider>
      </div>
    </div>
  );
};
