import React, {useCallback, useRef, useState} from 'react'
import {Modal, Table, Input, Button} from 'antd'
import {DoubleRightOutlined, DoubleLeftOutlined} from '@ant-design/icons';
import {DataGrid} from "../../../../../../components/DataGrid";
import {inputType} from "../../../../../../constants/inputType";
import i18n from "i18next";

interface FormProps {
    visible: boolean,
    onCancel: () => void,
    onOk: (v: any) => void,
    skuId: string
}


export default ({visible, onCancel, onOk, skuId}: FormProps) => {
    const [rowsData, setRowsData] = useState()
    const [flag, setFlag] = useState(false)
    const dataGridRef = useRef<any>();
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setRowsData(selectedRows)
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    }

    const getCustomerData = (v: any) => {
        console.log('商品历史订单', v.target.value)
    }
    const initColumn = [{
        fieldShowName: i18n.t('国家，城市',),
        fieldName: 'destination',
    },
        {
            fieldShowName: i18n.t('公司',),
            inputType:inputType.DICTIONARY,
            dictionary:"clothes_customer",
            fieldName: 'customerId',
        },
        {
            fieldShowName: i18n.t('日期',),
            fieldName: 'createTime',
            width: 100
        },
        {
            fieldShowName: i18n.t('数量',),
            fieldName: 'subTotalQuantity',
        },];
    const withPriceColumn = [{
        fieldShowName: i18n.t('国家，城市',),
        fieldName: 'destination',
    },
        {
            fieldShowName: i18n.t('公司',),
            inputType:inputType.DICTIONARY,
            dictionary:"clothes_customer",
            fieldName: 'customerId',
        },
        {
            fieldShowName: i18n.t('日期',),
            fieldName: 'createTime',
            width: 100
        },
        {
            fieldShowName: i18n.t('数量',),
            fieldName: 'subTotalQuantity',
        },
        {
            fieldShowName: i18n.t('价格',),
            fieldName: 'price',
            width: 100
        }];
    const [columns, setColumns] = useState<any>(initColumn)


    return (
        <Modal
            getContainer={false}
            style={{top: '20px'}}
            width={'880px'}
            visible={visible}
            title={i18n.t("商品历史订单")}
            okText={i18n.t("确定")}
            cancelText={i18n.t("取消")}
            onCancel={onCancel}
            onOk={() => {
                onOk(rowsData)
            }}
            destroyOnClose={true}
            maskClosable={false}
        >
            <div>
                <Button style={{float: "right", zIndex: 99}} icon={<DoubleRightOutlined style={{color: '#1890ff'}}/>}
                        onClick={() => {
                            setFlag(!flag)
                            // onShow();
                        }}>
                </Button>


                {!flag && <DataGrid url={"/clothes/order/getHistoryOrderList?skuId=" + skuId}
                                    descriptions={initColumn} sorter={{field: "createTime", order: "descend"}}/>}

                {flag && <DataGrid url={"/clothes/order/getHistoryOrderList?skuId=" + skuId}
                                   descriptions={withPriceColumn} sorter={{field: "createTime", order: "descend"}}/>}
            </div>
        </Modal>
    );
}