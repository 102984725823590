import React from "react"
import {Form, InputNumber} from 'antd'
import i18n from "i18next";

export interface Param {
    formData: any,
    countAmount: () => void;
}

export default ({formData, countAmount}: Param) => {

    const countCosts = () => {
        var transportationCosts = formData.getFieldValue("transportationCosts") || 0;
        var transportationCostsRate = formData.getFieldValue("transportationCostsRate") || 0;
        var packageCosts = formData.getFieldValue("packageCosts") || 0;
        var packageCostsRate = formData.getFieldValue("packageCostsRate") || 0;
        var bankCosts = formData.getFieldValue("bankCosts") || 0;
        var bankCostsRate = formData.getFieldValue("bankCostsRate") || 0;
        var othersCosts = formData.getFieldValue("othersCosts") || 0;
        var othersCostsRate = formData.getFieldValue("othersCostsRate") || 0;
        var cashOnDeliveryCosts = formData.getFieldValue("cashOnDeliveryCosts") || 0;
        var cashOnDeliveryCostsRate = formData.getFieldValue("cashOnDeliveryCostsRate") || 0;
        var bolliCosts = formData.getFieldValue("bolliCosts") || 0;
        var bolliCostsRate = formData.getFieldValue("bolliCostsRate") || 0;

        var serviceCosts = Number(transportationCosts * (1 + transportationCostsRate / 100) +
            packageCosts * (1 + packageCostsRate / 100) +
            bankCosts * (1 + bankCostsRate / 100) +
            othersCosts * (1 + othersCostsRate / 100) +
            cashOnDeliveryCosts * (1 + cashOnDeliveryCostsRate / 100) +
            bolliCosts * (1 + bolliCostsRate / 100)).toFixed(2);
        formData.setFieldsValue({serviceAmount: serviceCosts});
        countAmount();


    }
    return (
        <div className="u-tab-con u-tab-fwfy">
            <Form.Item label={i18n.t("运输费用")}>
                <Form.Item
                    name="transportationCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>

                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="transportationCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>
            </Form.Item>
            <Form.Item label={i18n.t("包装费用")}>
                <Form.Item
                    name="packageCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>
                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="packageCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>

            </Form.Item>
            <Form.Item label={i18n.t("银行费用")}>
                <Form.Item
                    name="bankCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>
                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="bankCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>
            </Form.Item>
            <Form.Item label={i18n.t("其他费用")}>
                <Form.Item
                    name="othersCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>
                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="othersCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>
            </Form.Item>
            <Form.Item label={i18n.t("货到付款费用")}>
                <Form.Item
                    name="cashOnDeliveryCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>
                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="cashOnDeliveryCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>
            </Form.Item>
            <Form.Item label={i18n.t("BOLLI费用")}>
                <Form.Item
                    name="bolliCosts"
                    noStyle>
                    <InputNumber style={{width: '160px'}} onChange={() => countCosts()}/>
                </Form.Item>
                <Form.Item
                    noStyle>
                    <span style={{margin: '0 0 0 20px'}}>{i18n.t("税率")}</span>
                </Form.Item>
                <Form.Item
                    name="bolliCostsRate"
                    noStyle>
                    <InputNumber onChange={() => countCosts()} style={{width: '65px', margin: '0 0 0 10px'}}/>
                </Form.Item>
            </Form.Item>
        </div>
    );

}