import React, { useCallback, useEffect, useRef, useState } from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {
  Menu,
  Dropdown,
  Pagination,
  Button,
  Table,
  Modal,
  message,
} from "antd";
import {
  CaretDownOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import UploadWrapper from "../../../../../components/UploadWrapper";
import {hasPermission, usePermission} from "../../../../../util/permession";
import {createNewTab} from "../../../../../util/tyy";
import storage from "../util/Stroage";
export interface Params {
    customerType: string;
}
export default () => {
  const params = useParams<Params>();
  const customerType = params.customerType;
  const thisGridRef = useRef<any>();
  const thisGridRef2 = useRef<any>();
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState(
    false
  );
  const [hasDelete, setHasDelete] = useState(
      hasPermission("删除客户")
  );
    const history = useHistory();

    const { height } = useWindowSize();
  const generateUUID = useCallback(function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }, []);
  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("删除提示"),
      okText: i18n.t("okText"),
      cancelText: i18n.t("cancelText"),
      onOk: function () {
        const url =
          `/clothes/customerManage/deleteCustomerByUuid?uuid=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("deleteSuccess"));
            thisGridRef.current.refresh();
          } else {
            message.info(i18n.t("deleteError"));
          }
        });
      },
    });
  }, []);

    const getColumn = useCallback(()=>{
        if(customerType == '4'){
            return [
                {
                    fieldShowName: i18n.t("客户种类"),
                    fieldName: "customerType",
                    width: 80,
                    dictionary: "dict_customer_type",
                },
                {
                    fieldShowName: i18n.t("客户名称"),
                    fieldName: "name",
                    width: 80,
                },
                {
                    fieldShowName: i18n.t("公司名称"),
                    fieldName: "shortName",
                    width: 80,
                },
                {
                    fieldShowName: i18n.t("客户品牌"),
                    fieldName: "brand",
                    width: 80,
                },
                {
                    fieldShowName: i18n.t("操作",),
                    isHiddenSearch: true,
                    align: "center",
                    width: 50,
                    fixed: "right" as "right",
                    shouldCellUpdate:()=>{
                      return true;
                    },
                    render: (text:any,record:any)=>{
                        return (
                            hasDelete ? <a
                                href="#"
                                className="deleteA"
                                onClick={(e:any)=>{
                                    confirm(record.uuid);
                                    e.stopPropagation();
                                }}
                            >
                                {i18n.t("delete")}
                            </a>:""
                        );
                    }
                }
            ]
        }else{
            return [
                {
                    fieldShowName: i18n.t("客户种类"),
                    fieldName: "customerType",
                    width: 80,
                    dictionary: "dict_customer_type",
                },
                {
                    fieldShowName: i18n.t("客户名称"),
                    fieldName: "name",
                    width: 80,
                },
                {
                    fieldShowName: i18n.t("公司名称"),
                    fieldName: "shortName",
                    width: 80,
                },
                {
                    fieldShowName: i18n.t("操作",),
                    isHiddenSearch: true,
                    align: "center",
                    width: 50,
                    fixed: "right" as "right",
                    shouldCellUpdate:()=>{
                        return true;
                    },
                    render: function useAa(text: any, record: any) {
                        return (
                            hasPermission("删除客户") && <a
                                href="#"
                                className="deleteA"
                                onClick={(e:any)=>{
                                    confirm(record.uuid);
                                    e.stopPropagation();
                                }}
                            >
                                {i18n.t("delete")}
                            </a>
                        );
                    },
                },
            ]
        }

    },[])
  const columnsDataExport = useCallback(()=>{
      if(customerType == '4') {
      return [
          {
              fieldShowName: i18n.t("客户种类"),
              fieldName: "customerType",
              width: 80,
              dictionary: "dict_customer_type",
          },
          {
              fieldShowName: i18n.t("客户名称"),
              fieldName: "name",
              width: 80,
          },
          {
              fieldShowName: i18n.t("公司名称"),
              fieldName: "shortName",
              width: 80,
          },
          {
              fieldShowName: i18n.t("品牌"),
              fieldName: "brand",
              width: 80,
          },
          {
              fieldShowName: i18n.t("P.IVA"),
              fieldName: "p_iva",
              width: 80,
          },
          {
              fieldShowName: i18n.t("COD.FIS"),
              fieldName: "COD_FIS",
              width: 80,
          },
          {
              fieldShowName: i18n.t("COD.SDI"),
              fieldName: "COD_SDI",
              width: 80,
          },
          {
              fieldShowName: i18n.t("客户税率"),
              inputType: 6,
              dictionary: "dict_tax",
              fieldName: "taxId",
              width: 80,
          },
          {
              fieldShowName: i18n.t("公司注册地址"),
              fieldName: "registeredAddress",
              width: 80,
          },
          {
              fieldShowName: i18n.t("注册地国家"),
              fieldName: "registeredCountry",
              width: 80,
              dictionary: "dict_country",
          },
          {
              fieldShowName: i18n.t("注册地城市"),
              fieldName: "registeredCity",
              width: 80,
              dictionary: "dict_city",
          },
          {
              fieldShowName: i18n.t("注册地邮编"),
              fieldName: "registeredPostcode",
              width: 80,
          },
          {
              fieldShowName: i18n.t("注册省会"),
              fieldName: "registeredProvincialCapitalCode",
              width: 80,
          },
          {
              fieldShowName: i18n.t("公司办公地址"),
              fieldName: "officeAddress",
              width: 80,
          },
          {
              fieldShowName: i18n.t("办公地国家"),
              fieldName: "officeCountry",
              width: 80,
              dictionary: "dict_country",
          },
          {
              fieldShowName: i18n.t("办公地城市"),
              fieldName: "officeCity",
              width: 80,
              dictionary: "dict_city",
          },
          {
              fieldShowName: i18n.t("办公地邮编"),
              fieldName: "officePostcode",
              width: 80,
          },
          {
              fieldShowName: i18n.t("办公省会"),
              fieldName: "officeProvincialCapitalCode",
              width: 80,
          },
          {
              fieldShowName: i18n.t("中介公司"),
              fieldName: "introducerCode",
              width: 80,
          },
          {
              fieldShowName: i18n.t("客户备注"),
              fieldName: "remark",
              title:true,
              width: 80,
          },
          {
              fieldShowName: i18n.t("操作",),
              isHiddenSearch: true,
              align: "center",
              width: 50,
              fixed: "right" as "right",
              render: function useAa(text: any, record: any) {
                  return (
                      usePermission("删除客户") && <a
                          href="#"
                          className="deleteA"
                          onClick={(e:any)=>{
                              confirm(record.uuid);
                              e.stopPropagation();
                          }}
                      >
                          {i18n.t("delete")}
                      </a>
                  );
              },
          },
      ]
      }else{
          return [
              {
                  fieldShowName: i18n.t("客户种类"),
                  fieldName: "customerType",
                  width: 80,
                  dictionary: "dict_customer_type",
              },
              {
                  fieldShowName: i18n.t("客户名称"),
                  fieldName: "name",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("公司名称"),
                  fieldName: "shortName",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("P.IVA"),
                  fieldName: "p_iva",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("COD.FIS"),
                  fieldName: "COD_FIS",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("COD.SDI"),
                  fieldName: "COD_SDI",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("客户税率"),
                  inputType: 6,
                  dictionary: "dict_tax",
                  fieldName: "taxId",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("公司注册地址"),
                  fieldName: "registeredAddress",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("注册地国家"),
                  fieldName: "registeredCountry",
                  width: 80,
                  dictionary: "dict_country",
              },
              {
                  fieldShowName: i18n.t("注册地城市"),
                  fieldName: "registeredCity",
                  width: 80,
                  dictionary: "dict_city",
              },
              {
                  fieldShowName: i18n.t("注册地邮编"),
                  fieldName: "registeredPostcode",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("注册省会"),
                  fieldName: "registeredProvincialCapitalCode",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("公司办公地址"),
                  fieldName: "officeAddress",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("办公地国家"),
                  fieldName: "officeCountry",
                  width: 80,
                  dictionary: "dict_country",
              },
              {
                  fieldShowName: i18n.t("办公地城市"),
                  fieldName: "officeCity",
                  width: 80,
                  dictionary: "dict_city",
              },
              {
                  fieldShowName: i18n.t("办公地邮编"),
                  fieldName: "officePostcode",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("办公省会"),
                  fieldName: "officeProvincialCapitalCode",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("中介公司"),
                  fieldName: "introducerCode",
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("客户备注"),
                  fieldName: "remark",
                  title:true,
                  width: 80,
              },
              {
                  fieldShowName: i18n.t("操作",),
                  isHiddenSearch: true,
                  align: "center",
                  width: 50,
                  fixed: "right" as "right",
                  render: function useAa(text: any, record: any) {
                      return (
                          usePermission("删除客户") && <a
                              href="#"
                              className="deleteA"
                              onClick={(e:any)=>{
                                  confirm(record.uuid);
                                  e.stopPropagation();
                              }}
                          >
                              {i18n.t("delete")}
                          </a>
                      );
                  },
              },
          ]
      }
  },[])
  const operationMenu = (
    <Menu>
      <Menu.Item onClick={() => setUploadWrapperDialogVisible(true)}>
        <ImportOutlined style={{ fontSize: "20px" }} />
        <span style={{ marginLeft: "5px" }}>{i18n.t("import")}</span>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e) => {
          thisGridRef2.current.export();
        }}
      >
        <UploadOutlined style={{ fontSize: "20px" }} />
        <span style={{ marginLeft: "5px" }}>{i18n.t("export")}</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          axios
            .post(
              "/clothes/utils/excelUtil/downloadExcelTemplate",
              {
                tableName: "clothes_customer",
              },
              {
                responseType: "blob",
              }
            )
            .then((resp) => {
              var blob = new Blob([resp.data]);
              var downloadElement = document.createElement("a");
              var href = window.URL.createObjectURL(blob);
              downloadElement.href = href;
              downloadElement.download = i18n.t("customer_template")+".xlsx";
              document.body.appendChild(downloadElement);
              downloadElement.click();
              document.body.removeChild(downloadElement);
              window.URL.revokeObjectURL(href);
            });
        }}
      >
        <DownloadOutlined style={{ fontSize: "20px" }} />
        <span style={{ marginLeft: "5px" }}>{i18n.t("downTemplate")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="u-box u-basic-page">
      <div className="u-box-head">
          <span className="u-box-title" onClick={(e) => e.preventDefault()}>
            {i18n.t('全部')} {i18n.t('客户管理')}
          </span>
        <div className="u-box-toolbar">
          <div className="u-icon-list u-btn-list">
            {usePermission("新增修改客户") && <Button
              type="primary"
              onClick={() => {
                  createNewTab(storage.get("reactUrl")+"/clothes/CRMForm/" + generateUUID()+"/"+customerType,i18n.t('客户明细',))
                // history.push("/clothes/CRMForm/" + generateUUID()+"/"+customerType);
              }}
              className="u-btn-add"
            >
                {i18n.t('add')}
            </Button>}
            <Dropdown overlay={operationMenu}>
              <span title="UnorderedListOutlined">
                <UnorderedListOutlined />
              </span>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="u-box-con">
        <DataGrid
          ref={thisGridRef}
          pagination={true}
          pageSize={Math.floor((height - 280) / 42)}
          rowSelectionType="checkbox"
          descriptions={getColumn()}
          onClick={
            (record:any)=>{
                createNewTab(storage.get("reactUrl")+"/clothes/CRMDetail/" + record.uuid +"/" +record.ref.customerType,i18n.t('客户明细',))
            }
          }
          url={`/clothes/customerManage/getCustomerList?customerType=`+customerType}
        />
        <UploadWrapper
          multiple={false}
          visible={uploadWrapperDialogVisible}
          onOk={(file) => {
            if(file.length==1){
              if(file[0].name.indexOf("xls")>-1){
                setUploadWrapperDialogVisible(false);
                axios
                    .post("/clothes/utils/excelUtil/importExcel", {
                      tableName: "clothes_customer",
                      treeField: "",
                      treeParentValue: "",
                      // defaultValueFields: "type,status,taxIncentives",
                      // defaultValues: "a,0,1",
                      defaultValueFields: "",
                      defaultValues: "",
                      fileId: file[0].uuid,
                      startIndex: 1,
                      endIndex: 0,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        message.info(resp.data.Message);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }else{
                          message.error(i18n.t("导入模板不符合规范"));
                      }
                    });
              }else{
                  message.error(i18n.t("传入文件类型不符"));
              }
            }else{
                message.error(i18n.t("只能导入一个文件"));
            }
          }}
          onCancel={() => setUploadWrapperDialogVisible(false)}
        />
      </div>

        <div className="u-box-con" style={{display: "none"}}>
            <DataGrid
                ref={thisGridRef2}
                pagination={true}
                pageSize={Math.floor((height - 280) / 42)}
                rowSelectionType="checkbox"
                descriptions={columnsDataExport()}
                onClick={
                    (record:any)=>{
                        history.push(
                            "/clothes/CRMDetail/" + record.uuid +"/" +record.ref.customerType);
                    }
                }
                url={`/clothes/customerManage/getCustomerList?customerType=`+customerType}
            />
        </div>
    </div>
  );
};
