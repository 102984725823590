import React, {useEffect, useState} from "react";
import {DataGrid} from "../../../../../components/DataGrid";
import {Button, Col, Form, Image, Input, message, Radio, Row} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useWindowSize} from "react-use";
import {inputType} from "../../../../../constants/inputType";
import {instance as axios} from "../../../../../util/axios";
import path from "path";
import i18n from "i18next";

export interface Param {
    photoUrl: string,
    skuId: any
}

const WashingSign = ({photoUrl, skuId}: Param) => {
    const [washingSign, setWashingSign] = useState<any>();
    const [sku, setSku] = useState<any>();
    const baseDir = "../";
    useEffect(() => {
        axios.post("/clothes/sku/getSkuById", {uuid: skuId}).then((resp) => {
            if (resp.data.Status == 1) {

                setSku(resp.data.Data)
            }
        })
        axios.get("/clothes/produceOrder/getWashingSign?skuId=" + skuId).then((data) => {
            if (data.data.Status == 1) {

                setWashingSign(data.data.Data);

            }
        })
    }, [skuId])

    const getWashingSign = () => {


    }

    return <div>

        <div className="u-table-photo">
            <div className="u-table-left">
                <Row gutter={18}>

                    {
                        washingSign &&
                        Object.keys(washingSign).map((key: any) => {
                            return (
                                <Col span={2}>
                                    <div>{i18n.t(key)}:</div>
                                    {washingSign[key].map((item: any) => {
                                        var imgPath = item.path;
                                        var imagePath = require(`../${imgPath}`);
                                        return (
                                            <Image
                                                src={imagePath}
                                                width={"30px"}
                                                preview={false}
                                            />
                                        )
                                    })}
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row gutter={18}>
                    <Col span={15}>
                        {sku && <Form.Item label={i18n.t("加工技术资料")}>
                            <TextArea value={sku.processRemark} rows={4} disabled={true}/>
                        </Form.Item>}
                    </Col>
                </Row>
            </div>
            <div className="u-photo-left">
                {photoUrl && <img alt='' src={photoUrl}/>}
            </div>
        </div>

    </div>;
}

export default WashingSign;