import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useWindowSize } from "react-use";
import { Tabs, Input, message } from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import { instance as axios } from "../../../../../../util/axios";

const { TabPane } = Tabs;
const { Search } = Input;
interface Params {
  uuid: string;
  orderId: string;
}

const Chuku = function () {
  const params = useParams<Params>();
  const { height } = useWindowSize();
  const todoGridRef = useRef<any>(null);
  const doneGridRef = useRef<any>(null);
  const detailGridRef = useRef<any>(null);
  const [barcode, setBarcode] = useState("");
  function getTabPane(
    tabName: string,
    url: string,
    gridRef: React.MutableRefObject<any>
  ) {
    return (
      <TabPane tab={tabName} key={tabName}>
        <>
          {height && (
            <>
              <DataGrid
                style={{ marginBottom: 8 }}
                pagination={true}
                pageSize={Math.floor((height - 180) / 42)}
                ref={gridRef}
                sorter={{ field: "inputTime", order: "descend" }}
                descriptions={[
                  {
                    fieldName: "boundOrderNo",
                    fieldShowName: "合同号",
                  },
                  {
                    fieldName: "boundCustomerId",
                    fieldShowName: "客户",
                  },
                  {
                    fieldName: "radCategory",
                    fieldShowName: "系列",
                    dictionary: "rad_sku_category",
                  },
                  {
                    fieldName: "radId",
                    fieldShowName: "型号",
                    dictionary: "rad_sku",
                  },
                  {
                    fieldName: "pieces",
                    fieldShowName: "片数",
                  },
                  {
                    fieldName: "centerCategory",
                    fieldShowName: "高度分类",
                    dictionary: "dict_rad_center_category",
                  },
                  {
                    fieldName: "centerSpacing",
                    fieldShowName: "高度",
                  },
                  {
                    fieldName: "caliber",
                    fieldShowName: "口径",
                    dictionary: "dict_rad_caliber",
                  },
                  {
                    fieldName: "color",
                    fieldShowName: "颜色",
                    dictionary: "dict_rad_color",
                  },
                  {
                    fieldName: "barcode",
                    fieldShowName: "条形码",
                  },
                  {
                    fieldName: "inputTime",
                    fieldShowName: "入库时间",
                  },
                  {
                    fieldName: "position",
                    fieldShowName: "货架",
                    dictionary: "rad_position",
                  },
                ]}
                url={url}
              />
            </>
          )}
        </>
      </TabPane>
    );
  }
  return (
    <>
      <Search
        placeholder="请输入条码"
        enterButton="确定"
        style={{ width: 252, marginBottom: 15 }}
        value={barcode}
        onChange={(event) => {
          setBarcode(event.target.value);
        }}
        onSearch={(value) => {
          axios
            .post("/erp/barcode/outOfStock", {
              barcode: value,
              outputId: params.uuid,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                setBarcode("");
                message.info(resp.data.Data);
                if (todoGridRef.current) {
                  todoGridRef.current.refresh();
                } else if (doneGridRef.current) {
                  doneGridRef.current.refresh();
                } else if (detailGridRef.current) {
                  detailGridRef.current.refresh();
                }
              } else {
                message.info(resp.data.Message);
              }
            });
        }}
      />
      <Tabs
        onChange={(activeKey: any) => {
          if (activeKey === "待扫码") {
            if (todoGridRef.current) {
              todoGridRef.current.refresh();
            }
          } else if (activeKey === "已扫码") {
            if (doneGridRef.current) {
              doneGridRef.current.refresh();
            }
          } else if (activeKey === "明细") {
            if (detailGridRef.current) {
              detailGridRef.current.refresh();
            }
          }
        }}
      >
        {getTabPane(
          "待扫码",
          "erp/barcode/getReadyOutBarcode?outputId=" + params.uuid,
          todoGridRef
        )}
        {getTabPane(
          "已扫码",
          "erp/barcode/getAlreadyOutBarcode?outputId=" + params.uuid,
          doneGridRef
        )}
        <TabPane tab="明细" key="明细">
          <>
            {height && (
              <>
                <DataGrid
                  style={{ marginBottom: 8 }}
                  pagination={true}
                  pageSize={Math.floor((height - 180) / 42)}
                  ref={detailGridRef}
                  sorter={{ field: "createTime", order: "descend" }}
                  descriptions={[
                    {
                      fieldName: "radCategory",
                      fieldShowName: "系列",
                      dictionary: "rad_sku_category",
                    },
                    {
                      fieldName: "radId",
                      fieldShowName: "型号",
                      dictionary: "rad_sku",
                    },
                    {
                      fieldName: "pieces",
                      fieldShowName: "片数",
                    },
                    {
                      fieldName: "centerCategory",
                      fieldShowName: "高度分类",
                      dictionary: "dict_rad_center_category",
                    },
                    {
                      fieldName: "centerSpacing",
                      fieldShowName: "高度",
                    },
                    {
                      fieldName: "caliber",
                      fieldShowName: "口径",
                      dictionary: "dict_rad_caliber",
                    },
                    {
                      fieldName: "color",
                      fieldShowName: "颜色",
                      dictionary: "dict_rad_color",
                    },
                    {
                      fieldName: "outputCount",
                      fieldShowName: "已扫条数",
                    },
                    {
                      fieldName: "actualOut",
                      fieldShowName: "应扫条数",
                    },
                  ]}
                  url={`erp/radOut/getOutOfStockDetail?outputId=${params.uuid}`}
                />
              </>
            )}
          </>
        </TabPane>
      </Tabs>
    </>
  );
};
export default Chuku;
