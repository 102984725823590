import moment from "moment";
import { isNullOrUndefined } from "./Common";
import { formatNumber } from "./FormatMoney";
import { getDateTimeFormat, getTimeOffset } from "../../../../../util/config";
import {useEffect, useState} from "react";
// var offset: any;
// getTimeOffset().then((res) => {
//   offset = res;
// });

const formatDateTime = (date: any) => {
  return date ? date.format("YYYY-MM-DD HH:mm:ss") : undefined;
};
const parseDate = (date: any) => {
  return date ? moment(date) : undefined;
};
const parseFieldDate = (data: any,fieldsName:any) => {
  fieldsName.map((item:any)=>{
    if(data[item]){
      data[item] = moment(data[item]);
    }
  })
  return data;
};

/**
 * 是否是时间格式
 * @param object
 */
function isMoment(object: any) {
  if (isNullOrUndefined(object)) {
    return false;
  } else {
    if (object._isAMomentObject) {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * 更新时间格式的数据
 * @param object
 */
function updateMomentType(object: any) {
  for (var key in object) {
    if (isMoment(object[key])) {
      object[key] = formatDateTime(object[key]);
    }
  }
}

//得到标准时区的时间的函数
function getLocalTime(dateTime: string, withHourFormat: boolean) {
  if (isNullOrUndefined(dateTime)) return "";
  var formatStr = localStorage.getItem("format");
    var format = JSON.parse(formatStr!);
  // var utfOffset = formatNumber(offset) * 60;
  var dateFormat = withHourFormat
    ? format.dateFormat + " " + format.hourFormat
    : format.dateFormat;
  return moment(dateTime).format(dateFormat);
}

export { formatDateTime, parseDate, updateMomentType, getLocalTime,parseFieldDate };
