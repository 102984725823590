import React, {useEffect, useRef, useState} from "react";
import {Button, Col, PageHeader, Row, Space, Tag} from "antd";
import {useHistory} from "react-router";
import i18n from "i18next";


export default ()=>{
    const history = useHistory();


    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={i18n.t("统计图")}
            />
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/sale");
            }}>
                {i18n.t("销售数据")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/product");
            }}>
                {i18n.t("生产数据")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount1");
            }}>
                {i18n.t("(订单) 辅料预计用量统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount2");
            }}>
                {i18n.t("(订单) 原材料预计用量统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount4");
            }}>
                {i18n.t("(订单) 原材料预计用量统计(按颜色)")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount5");
            }}>
                {i18n.t("生产单辅料预计用量统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount6");
            }}>
                {i18n.t("生产单原材料预计用量统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount7");
            }}>
                {i18n.t("销售单成交地域统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount8");
            }}>
                {i18n.t("地域成交量统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount9");
            }}>
                {i18n.t("地域成交量标签统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount10");
            }}>
                {i18n.t("地域成交量颜色统计")}
            </Tag>
            <Tag style={{fontSize:16}} onClick={() => {
                history.push("/clothes/statistic/orderAmount11");
            }}>
                {i18n.t("加工厂核算")}
            </Tag>
        </div>
    );

}