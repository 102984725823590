import axios from "axios";
import { stringify } from "qs";
import { BaseURL } from "../constants/url";
import { setupCache } from "axios-cache-adapter";
import {message} from "antd";

function createInstance(cacheable,json,hiddenError) {
    // json格式请求头
    const headerJSON = {
        "Content-Type": "application/json;charset=UTF-8"
    };
    // FormData格式请求头
    const headerFormData = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    };
  const instance = axios.create({
    baseURL: BaseURL, // 为所有 HTTP 请求 URL 添加前缀
    withCredentials: true,
    crossDomain: true,
    transformRequest: json?undefined:(data) => stringify(data),
    adapter: cacheable
      ? setupCache({
          maxAge: 15 * 60 * 1000,
          exclude: {
            query: false,
          },
        }).adapter
      : null,
      headers:json?headerJSON:headerFormData
  });
  instance.interceptors.response.use((response) => {
    if (response.data.Status && response.data.Status !== "1") {
      console.log(response.data);
        if(!hiddenError){
            message.error(response.data.Message)
        }
    }
    return response;
  });
  return instance;
}

const instance = createInstance(false);
const instanceNoError = createInstance(false,false,true);
const cacheableInstance = createInstance(true);
const jsonInstance = createInstance(true,true);

export { instance, cacheableInstance,jsonInstance ,instanceNoError};
