import React, { useRef, useState, useEffect, useContext } from "react";
import { useWindowSize } from "react-use";
import { Tabs, List, Spin, Space, Popconfirm, Button, message } from "antd";
import { DataGrid } from "../../../../../../../components/DataGrid";
import { instance as axios } from "../../../../../../../util/axios";
import { Comment } from "./components/Comment";
import { PoolItem } from "./components/PoolItem";
import { Like } from "./components/Like";
import { SearchBar } from "./components/SearchBar";
import { ContactListItem } from "../../../components/ContactListItem";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Result } from "../../../../../../../schemas/common";
import { CustomerContactDetailDialog } from "../../../components/CustomerContactDetailDialog";
import { SessionContext } from "../../../../../index";
import Session from "../../../../../../../schemas/session";

const { TabPane } = Tabs;
const ContactList = () => {
  const session: Session = useContext(SessionContext);
  const gridRef = useRef<any>(null);
  const { height } = useWindowSize();
  const [listData, setListData] = useState<any[]>([]);
  const [poolData, setPoolData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listSearchBar, setListSearchBar] = useState({
    startTime: "",
    endTime: "",
    advisorId: "",
    departmentId: "",
    productIds: [""],
    projectId: "",
    customerId: "",
  });
  const [poolSearchBar, setPoolSearchBar] = useState({
    startTime: "",
    endTime: "",
    advisorId: "",
    departmentId: "",
    productIds: [""],
    projectId: "",
    customerId: "",
  });
  const [editForm, setEditForm] = useState({
    visible: false,
    id: "",
  });

  async function getListData(
    pageSize: any,
    pageIndex: any,
    listSearchBar: any
  ) {
    let productIdsStr;
    if (listSearchBar.productIds && listSearchBar.productIds.length > 0) {
      productIdsStr = listSearchBar.productIds.join(",");
    }
    let data = {
      customerId: listSearchBar.customerId,
      projectId: listSearchBar.projectId,
      departmentId: listSearchBar.departmentId,
      advisorId: listSearchBar.advisorId,
      startTime: listSearchBar.startTime,
      endTime: listSearchBar.endTime,
      productIds: productIdsStr,
      pageSize: pageSize,
      pageIndex: pageIndex,
    };
    setLoading(true);
    await axios.post("/crm/crm/contact/getContactTile", data).then((resp) => {
      let data = resp.data.Data.contactTileList;
      setListData(data);
      let pageNumbers = resp.data.Data.pageInfo.pageNumbers;
      let pageSize = resp.data.Data.pageInfo.pageSize;
      let total: number = pageNumbers * pageSize;
      setTotal(total);
      setLoading(false);
    });
  }
  async function getPoolData(poolSearchBar: any) {
    let productIdsStr;
    if (poolSearchBar.productIds && poolSearchBar.productIds.length > 0) {
      productIdsStr = poolSearchBar.productIds.join(",");
    }
    let data = {
      customerId: poolSearchBar.customerId,
      projectId: poolSearchBar.projectId,
      departmentId: poolSearchBar.departmentId,
      advisorId: poolSearchBar.advisorId,
      startTime: poolSearchBar.startTime,
      endTime: poolSearchBar.endTime,
      productIds: productIdsStr,
    };
    setLoading(true);
    await axios
      .post("/crm/crm/contact/getContactYongDaoData", data)
      .then((resp) => {
        let data = resp.data.Data;
        setPoolData(data);
        setLoading(false);
      });
  }
  useEffect(() => {
    getListData(currPageSize, pageIndex, listSearchBar).then();
  }, [currPageSize, listSearchBar, pageIndex]);
  return (
    <>
      <Tabs
        onChange={(activeKey: any) => {
          if (activeKey === "1") {
            getListData(currPageSize, pageIndex, listSearchBar).then();
          } else if (activeKey === "2") {
            if (gridRef.current) {
              gridRef.current.refresh();
            }
          } else if (activeKey === "3") {
            getPoolData(poolSearchBar).then();
          }
        }}
      >
        <TabPane tab="平铺" key="1">
          <SearchBar
            onSearch={(form) => {
              setListSearchBar({
                ...listSearchBar,
                ...form,
              });
              getListData(currPageSize, 1, form).then();
              setPageIndex(1);
            }}
          />
          <Spin spinning={loading}>
            <List
              itemLayout="vertical"
              size="large"
              pagination={{
                total: total,
                current: pageIndex,
                onChange: (page, pageSize) => {
                  setPageIndex(page);
                  getListData(pageSize, page, listSearchBar).then();
                },
                onShowSizeChange: (current, size) => {
                  setCurrPageSize(size);
                  getListData(size, current, listSearchBar).then();
                },
                pageSize: currPageSize,
              }}
              dataSource={listData}
              renderItem={(item: any) => (
                <List.Item key={item.uuid} actions={[]}>
                  <ContactListItem
                    editable={item.advisorId === session.id}
                    data={item}
                    entityName="crm_contact"
                    onChange={() =>
                      getListData(currPageSize, pageIndex, listSearchBar).then()
                    }
                  />
                </List.Item>
              )}
            />
          </Spin>
        </TabPane>
        <TabPane tab="列表" key="2">
          <DataGrid
            style={{ marginBottom: 8 }}
            pageSize={Math.floor((height - 280) / 42)}
            pagination={true}
            ref={gridRef}
            sorter={{ field: "createTime", order: "descend" }}
            descriptions={[
              {
                fieldName: "customerId",
                fieldShowName: "客户",
                width: 150,
                dictionary: "crm_customer",
              },
              {
                fieldName: "content",
                fieldShowName: "内容",
                width: 200,
              },
              {
                fieldName: "stage",
                fieldShowName: "把握度",
                width: 100,
                dictionary: "dict_contact_stage",
              },
              {
                fieldName: "advisorId",
                fieldShowName: "业务员",
                width: 100,
                dictionary: "base_staff",
              },
              {
                fieldName: "contactTime",
                fieldShowName: "联系时间",
                width: 100,
              },
              {
                fieldName: "intentionCategory",
                fieldShowName: "意向产品",
                width: 100,
              },
              {
                fieldShowName: "操作",
                render: (_: any, record: any) => (
                  <Space>
                    {record.ref.advisorId === session.id && (
                      <Button
                        type={"link"}
                        icon={<EditOutlined />}
                        onClick={() => {
                          setEditForm({
                            ...editForm,
                            visible: true,
                            id: record.key,
                          });
                        }}
                      />
                    )}
                    {record.ref.advisorId === session.id && (
                      <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                          const url = `/crm/crm/contact/deleteContact?ids=${record.key}`;
                          axios.delete<Result<void>>(url).then((resp) => {
                            if (resp.data.Status === "1") {
                              message.info("删除成功");
                              gridRef.current.refresh();
                            } else {
                              message.error(resp.data.Message);
                            }
                          });
                        }}
                      >
                        <Button type={"link"} icon={<DeleteOutlined />} />
                      </Popconfirm>
                    )}
                    <Comment
                      count={record.commentNumber}
                      uuid={record.uuid}
                      onComment={() => {
                        gridRef.current.refresh();
                      }}
                    />
                    <Like
                      count={record.likeNumber}
                      uuid={record.uuid}
                      liked={record.isLike}
                      onLike={() => {
                        gridRef.current.refresh();
                      }}
                    />
                  </Space>
                ),
              },
            ]}
            url={`/crm/crm/contact/getContact?customerId=&projectId=&chanceId=`}
          />
        </TabPane>
        <TabPane tab="泳道图" key="3">
          <SearchBar
            onSearch={(form) => {
              setPoolSearchBar({
                ...poolSearchBar,
                ...form,
              });
              getPoolData(form);
            }}
          />
          <Spin spinning={loading}>
            <PoolItem
              data={poolData}
              onComment={() => {
                getPoolData(poolSearchBar).then();
              }}
              onLike={() => {
                getPoolData(poolSearchBar).then();
              }}
            />
          </Spin>
        </TabPane>
      </Tabs>
      <CustomerContactDetailDialog
        visible={editForm.visible}
        uuid={editForm.id}
        onCancel={() => {
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
        onOk={() => {
          gridRef.current?.refresh();
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
      />
    </>
  );
};
export default ContactList;
