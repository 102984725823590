import React, { useState } from "react";
import { Button, message, Modal, Input } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../../../../util/axios";

const { TextArea } = Input;
export interface CommentProps {
  onComment: () => void;
  uuid: string;
  count: number;
}
export const Comment = (props: CommentProps) => {
  const [commentForm, setCommentForm] = useState({
    content: "",
    contactId: "",
    visible: false,
  });
  return (
    <>
      <Button
        type="link"
        icon={<MessageOutlined style={{ marginRight: 4 }} />}
        style={{
          paddingRight: 0,
          color: props.count > 0 ? "#1890ff" : "#000000a6",
        }}
        onClick={() => {
          setCommentForm({
            ...commentForm,
            visible: true,
            contactId: props.uuid,
          });
        }}
      >
        {props.count}
      </Button>
      <Modal
        title="添加联系记录评论"
        visible={commentForm.visible}
        onCancel={() => {
          setCommentForm({
            ...commentForm,
            visible: false,
            content: "",
          });
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setCommentForm({
                ...commentForm,
                visible: false,
                content: "",
              });
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              axios
                .post("/oa/comment/comment/add", {
                  dataType: "联系记录",
                  dataId: commentForm.contactId,
                  content: commentForm.content,
                })
                .then((resp) => {
                  if (resp.data.Status === "1") {
                    message.info("保存成功");
                    setCommentForm({
                      ...commentForm,
                      visible: false,
                      content: "",
                    });
                    if (props.onComment) {
                      props.onComment();
                    }
                  }
                });
            }}
          >
            确定
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          value={commentForm.content}
          onChange={(event) => {
            setCommentForm({
              ...commentForm,
              content: event.target.value,
            });
          }}
        />
      </Modal>
    </>
  );
};
