import React, { useCallback, useEffect, useRef, useState } from "react";
import { get } from "../../../../../../util/description";
import { instance as axios } from "../../../../../../util/axios";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { TreeWrapper } from "../../../../../../components/TreeWrapper";
import {
  Button,
  Checkbox,
  DatePicker,
  Layout,
  Space,
  Modal,
  message,
  Tabs,
} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import moment, { Moment } from "moment";
import { Result } from "../../../../../../schemas/common";
// import MonacoEditor from "react-monaco-editor";
import { EditOutlined } from "@ant-design/icons/lib";
import { createNewTab } from "../../../../../../util/tyy";
import { Expression } from "../../../../../../components/DataGrid/schemas";

const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const defaultUrl = "/crm/bonus/getBonus";
const summaryUrl = "/crm/bonus/getBonusSummary";
export default function List() {
  const gridRef = useRef<any>();
  const [currentTabKey, setCurrentTabKey] = useState<"1" | "2">("1");
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const [summaryDescriptions, setSummaryDescriptions] = useState<
    FieldDescription[]
  >([]);
  const [expression] = useState<Expression>(
    new Expression("总业绩", "sum(orderAmount)")
  );
  expression.format = function (value) {
    return `总业绩：${value}`;
  };
  const [boundMonth, setBoundMonth] = useState<Moment>(moment(new Date()));
  const [boundDepartment, setBoundDepartment] = useState<string>("");
  const [cover, setCover] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(defaultUrl);
  const [scriptForm, setScriptForm] = useState({
    visible: false,
    script: "console.log('Hello, world!');",
  });
  const { height } = useWindowSize();

  const provide = useCallback(function (gridRef: React.MutableRefObject<any>) {
    const selectedRows: any[] = gridRef.current.getSelectedRows();
    const createAble = selectedRows.some(function (row: any) {
      return row.status === "2";
    });
    if (createAble) {
      message.error("请选择未发放的数据");
      return;
    }
    const bonusIds = gridRef.current.getSelectedIds();
    if (bonusIds.length === 0) {
      message.error("请选择要发放的数据");
      return;
    }
    const params: String[] = [];
    bonusIds.forEach((bonusId: string) => params.push("bonusId_" + bonusId));
    createNewTab(
      `/wf/form/vsheet/form?workflowTemplateId=e92d62efc57611eab5f57cd30a518102&id=0&applicationDate=${boundMonth.format(
        "YYYY-MM"
      )}&detaildata=${encodeURI(params.join("^"))}`,
      "发放申请"
    );
  }, []);

  const getTabPane = useCallback(
    function (
      tabName: string,
      key: "1" | "2",
      gridRef: React.MutableRefObject<any>,
      url: string
    ) {
      return (
        <>
          <TabPane tab={tabName} key={key}>
            <Layout style={{ paddingLeft: 10 }}>
              <div style={{ marginBottom: 10 }}>
                <Space>
                  <DatePicker
                    value={boundMonth}
                    picker="month"
                    onChange={(value) => {
                      setBoundMonth(moment(value));
                      setUrl(
                        `${
                          currentTabKey === "1" ? defaultUrl : summaryUrl
                        }?boundMonth=${moment(value).format(
                          "YYYY-MM"
                        )}&boundDepartment=${boundDepartment}`
                      );
                    }}
                  />
                  <DictionarySelect
                    value={boundDepartment}
                    style={{ width: 188 }}
                    entityName={"base_department"}
                    onChange={(value) => {
                      setBoundDepartment(value);
                      setUrl(
                        `${
                          currentTabKey === "1" ? defaultUrl : summaryUrl
                        }?boundMonth=${
                          boundMonth ? boundMonth.format("YYYY-MM") : ""
                        }&boundDepartment=${value}`
                      );
                    }}
                  />
                  {key === "1" && (
                    <>
                      <Checkbox
                        onChange={(e) => {
                          setCover(e.target.checked);
                        }}
                      >
                        是否覆盖原结果
                      </Checkbox>
                      <Button
                        type={"primary"}
                        onClick={() => {
                          axios
                            .post<Result<string>>("/crm/bonus/compute", {
                              boundMonth: boundMonth!.format("YYYY-MM"),
                              cover: cover,
                              boundDepartment: boundDepartment,
                            })
                            .then((resp) => {
                              if (resp.data.Status === "1") {
                                setUrl(
                                  `${
                                    currentTabKey === "1"
                                      ? defaultUrl
                                      : summaryUrl
                                  }?boundMonth=${
                                    boundMonth
                                      ? boundMonth.format("YYYY-MM")
                                      : ""
                                  }&boundDepartment=${boundDepartment}&t=${new Date().getTime()}`
                                );
                              }
                            });
                        }}
                      >
                        计算提成
                      </Button>
                      <Button
                        onClick={() => {
                          axios
                            .get<Result<string>>("/crm/bonus/getBonusScript")
                            .then(function (resp) {
                              if (resp.data.Status === "1") {
                                setScriptForm(() => ({
                                  ...scriptForm,
                                  visible: true,
                                  script: resp.data.Data,
                                }));
                              }
                            });
                        }}
                      >
                        编辑脚本
                      </Button>
                    </>
                  )}
                  <Button
                    onClick={() => {
                      gridRef.current.export();
                    }}
                  >
                    导出
                  </Button>
                </Space>
              </div>
              <Content>
                <DataGrid
                  pageSize={Math.floor((height - 220) / 42)}
                  ref={gridRef}
                  url={url}
                  rowSelectionType="checkbox"
                  expressions={[expression]}
                  descriptions={
                    key === "1" ? descriptions : summaryDescriptions
                  }
                />
              </Content>
              {key === "1" && (
                <Space style={{ textAlign: "right", display: "inline-block" }}>
                  <Button
                    onClick={() => {
                      provide(gridRef);
                    }}
                  >
                    发放
                  </Button>
                </Space>
              )}
            </Layout>
          </TabPane>
        </>
      );
    },
    [
      currentTabKey,
      descriptions,
      summaryDescriptions,
      boundMonth,
      boundDepartment,
      url,
      cover,
    ]
  );
  function handleTabChange(key: string): void {
    setCurrentTabKey(key as "1" | "2");
    setUrl(
      `${key === "1" ? defaultUrl : summaryUrl}?boundMonth=${
        boundMonth ? boundMonth.format("YYYY-MM") : ""
      }&boundDepartment=${boundDepartment}`
    );
  }

  useEffect(function () {
    get("crm_bonus").then((descriptions: FieldDescription[]) => {
      const formattedDescriptions = JSON.parse(JSON.stringify(descriptions));
      formattedDescriptions.push({
        fieldShowName: "操作",
        render: (text: any, record: any) => {
          return (
            <>
              {record.ref.status !== "2" && (
                <Button
                  type={"link"}
                  onClick={function () {
                    createNewTab(
                      `/wf/form/vsheet/form?workflowTemplateId=e92d62efc57611eab5f57cd30a518103&id=0&bonusId=${record.uuid}`,
                      "修改申请"
                    );
                  }}
                >
                  <EditOutlined />
                  修改
                </Button>
              )}
            </>
          );
        },
        align: "center",
        width: "100px",
        fixed: true,
      });
      setDescriptions(formattedDescriptions);
    });
    get("crm_bonus_summary").then((descriptions: FieldDescription[]) => {
      const formattedDescriptions = JSON.parse(JSON.stringify(descriptions));
      formattedDescriptions.push({
        fieldShowName: "发放",
        render: (text: any, record: any) => {
          return (
            <>
              {record.provideAmount}/{record.amount}
            </>
          );
        },
        align: "center",
        width: "100px",
        fixed: true,
      });
      setSummaryDescriptions(formattedDescriptions);
    });
  }, []);

  return (
    <>
      <Layout>
        <Sider width={260}>
          <TreeWrapper
            entityName="base_department"
            onSelected={(keys) => {
              setBoundDepartment(keys[0]);
              setUrl(
                `${
                  currentTabKey === "1" ? defaultUrl : summaryUrl
                }?boundMonth=${
                  boundMonth ? boundMonth.format("YYYY-MM") : ""
                }&boundDepartment=${keys[0]}`
              );
            }}
          />
        </Sider>
        <Content>
          <Tabs defaultActiveKey={currentTabKey} onChange={handleTabChange}>
            {getTabPane("员工汇总", "1", gridRef, url)}
            {getTabPane("部门汇总", "2", gridRef, url)}
          </Tabs>
        </Content>
      </Layout>
      <Modal
        visible={scriptForm.visible}
        title={"编辑脚本"}
        width={720}
        onCancel={function () {
          setScriptForm({
            ...scriptForm,
            visible: false,
          });
        }}
        onOk={function () {
          axios
            .post<Result<void>>("/crm/bonus/saveBonusScript", {
              script: scriptForm.script,
            })
            .then(function (resp) {
              if (resp.data.Status === "1") {
                setScriptForm(() => ({
                  ...scriptForm,
                  visible: false,
                }));
              }
            });
        }}
      >
        {/*<MonacoEditor
          language="javascript"
          theme="vs-dark"
          height={400}
          value={scriptForm.script}
          options={{
            selectOnLineNumbers: true,
          }}
          onChange={function (value) {
            setScriptForm(() => ({
              ...scriptForm,
              visible: true,
              script: value,
            }));
          }}
          editorDidMount={function () {}}
        />*/}
      </Modal>
    </>
  );
}
