import React, { useContext, useState, useEffect, useRef } from "react";
import { TableGrid } from "../../../../../../components/TableGrid";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import {
  Button,
  Tabs,
  message,
  Descriptions,
  Space,
  Popconfirm,
  Skeleton,
} from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import {
  DownloadOutlined,
  DeleteOutlined,
  IdcardTwoTone,
  EnvironmentTwoTone,
} from "@ant-design/icons";
import UploadWrapper from "../../../../../../components/UploadWrapper";
import { instance as axios } from "../../../../../../util/axios";
import { CustomerMemberManager } from "../../components/CustomerMemberManager";
import { Contacts } from "./Contacts";
import { useTimeout, useWindowSize } from "react-use";
import { DynamicTab } from "../../components/DynamicTab";
import { ContactList } from "./components/ContactList";
import { Dialing } from "../../components/Dialing";
import { APPDialing } from "../../components/APPDialing";
import { Projects } from "./Projects";
import { Result } from "../../../../../../schemas/common";
import { BaseURL } from "../../../../../../constants/url";
import { DynamicForm } from "../../../../../../schemas/dynamicForm";

const { TabPane } = Tabs;
export interface DetailProps {
  customerId: string;
  customerType?: string | undefined;
}

export const Detail = ({ customerId, customerType }: DetailProps) => {
  document.addEventListener("paste", function (e) {
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
  });
  const session: Session = useContext(SessionContext);
  const { height } = useWindowSize();
  const tableGridRef = useRef<any>();
  const [permission, setPermission] = useState<string[]>([]);
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState(
    false
  );
  /**
   * 没有分组的单据
   */
  const [dynamicTabConfigs, setDynamicTabConfigs] = useState<DynamicForm[]>([]);
  /**
   * 独立的单据
   */
  const [
    standaloneDynamicTabConfigs,
    setStandaloneDynamicTabConfigs,
  ] = useState<DynamicForm[]>([]);
  const [customer, setCustomer] = useState<{
    remark: string;
    advisorId: string;
    name: string;
    mobile: string;
    address: string;
    uuid: string;
  }>({
    advisorId: "",
    name: "",
    mobile: "",
    address: "",
    remark: "",
    uuid: "",
  });
  const fileGridRef = useRef<any>();
  const [isReady] = useTimeout(800);
  useEffect(
    function () {
      axios
        .post("/crm/customer/getCustomerById?id=" + customerId)
        .then((resp) => {
          if (resp.data.Status === "1") {
            setCustomer(resp.data.Data);
          }
        });
      axios
        .post<Result<DynamicForm[]>>(
          "/crm/dynamicTab/getSelectedList?host=customer&hostPrimaryKey=" +
            customerId
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            const data = resp.data.Data;
            if (data.length === 0) {
              return;
            }
            setDynamicTabConfigs(data.filter((item) => !item.group));
            setStandaloneDynamicTabConfigs(data.filter((item) => item.group));
          }
        });
    },
    [customerId]
  );
  useEffect(
    function () {
      axios
        .post<Result<string>>(
          "/crm/customer/role/getUserCustomerRolePermission",
          {
            customerId,
          }
        )
        .then((resp) => {
          if (resp.data.Status === "1") {
            setPermission(resp.data.Data.split(","));
          }
        });
    },
    [customerId]
  );

  return (
    <>
      <Descriptions title="客户信息">
        <Descriptions.Item label="名称">
          <Space>
            <span>{customer.name}</span>
            <IdcardTwoTone />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="电话">
          {customer.mobile ? (
            <Space>
              <span>{customer.mobile}</span>
              <Dialing
                advisorId={customer.advisorId}
                uuid={customer.uuid}
                mobile={customer.mobile}
              />
              <APPDialing mobile={customer.mobile} />
            </Space>
          ) : (
            "无"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="地址">
          {customer.address ? (
            <Space>
              <span>{customer.address}</span>
              <EnvironmentTwoTone />
            </Space>
          ) : (
            "无"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="备注">
          {customer.remark || "无"}
        </Descriptions.Item>
      </Descriptions>
      <Tabs>
        <TabPane key={"info"} tab={"基本信息"}>
          {session.permissions.some(
            (item: any) => item === "修改全部客户信息"
          ) &&
            customerType !== "gonghai" &&
            customer.advisorId === session.id && (
              <div style={{ textAlign: "right" }}>
                <Button
                  type={"link"}
                  style={{ paddingRight: 0 }}
                  onClick={() => {
                    tableGridRef.current.save();
                  }}
                >
                  保存
                </Button>
              </div>
            )}
          {isReady() ? (
            <TableGrid
              id={customerId}
              ref={tableGridRef}
              entityName="crm_customer"
              columnCount={3}
            />
          ) : (
            <Skeleton active />
          )}
        </TabPane>
        <TabPane key={"role"} tab={"成员"}>
          <CustomerMemberManager customerId={customerId} />
        </TabPane>
        {session.permissions.some(
          (item: string) => item === "查看项目列表"
        ) && (
          <TabPane key={"project"} tab={"项目"}>
            <Projects
              customerId={customerId}
              editable={
                customerType !== "gonghai" && customer.advisorId === session.id
              }
            />
          </TabPane>
        )}
        <TabPane key={"contacts"} tab={"联系人"}>
          <Contacts
            customerId={customerId}
            editable={
              customerType !== "gonghai" && customer.advisorId === session.id
            }
          />
        </TabPane>
        <TabPane key={"contactList"} tab={"跟进记录"}>
          <ContactList
            customerId={customerId}
            editable={
              customerType !== "gonghai" && customer.advisorId === session.id
            }
          />
        </TabPane>
        <TabPane key={"forms"} tab={"单据"}>
          {dynamicTabConfigs.map((tab) => (
            <DynamicTab
              editable={
                (customer.advisorId === session.id ||
                  permission.some(
                    (item: string) => item === tab.workflowTemplateId
                  )) &&
                customerType !== "gonghai"
              }
              key={tab.tableName}
              id={customerId}
              host="customer"
              advisorId={customer.advisorId}
              data={{
                ...tab,
                entityName: tab.tableName,
                uuid: tab.uuid,
              }}
            />
          ))}
        </TabPane>
        {standaloneDynamicTabConfigs.map((tab) => (
          <TabPane tab={tab.formName} key={tab.tableName}>
            <DynamicTab
              editable={
                (customer.advisorId === session.id ||
                  permission.some(
                    (item: string) => item === tab.workflowTemplateId
                  )) &&
                customerType !== "gonghai"
              }
              key={tab.tableName}
              id={customerId}
              host="customer"
              advisorId={customer.advisorId}
              data={{
                ...tab,
                entityName: tab.tableName,
                uuid: tab.uuid,
              }}
            />
          </TabPane>
        ))}
        <TabPane key={"file"} tab={"文件库"}>
          <div style={{ textAlign: "right" }}>
            <Button
              type={"link"}
              onClick={() => setUploadWrapperDialogVisible(true)}
            >
              上传文件
            </Button>
          </div>
          <DataGrid
            ref={fileGridRef}
            pageSize={Math.floor((height - 300) / 42)}
            url={"/crm/correspondence/getList?customerId=" + customerId}
            descriptions={[
              {
                fieldShowName: "文件名称",
                fieldName: "name",
              },
              {
                fieldShowName: "大小",
              },
              {
                fieldShowName: "上传人员",
                fieldName: "creatorId",
                dictionary: "base_staff",
              },
              {
                fieldShowName: "上传时间",
                fieldName: "createTime",
              },
              {
                fieldShowName: "操作",
                render(text: any, record: any) {
                  return (
                    <>
                      <Button
                        type={"link"}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          window.open(
                            `${BaseURL}/oa/common/attachment/downloadFiles?ids=${record.attachments}`
                          );
                        }}
                      />
                      {record.key && (
                        <Popconfirm
                          title="确定要删除吗?"
                          onConfirm={() => {
                            const url = `/crm/correspondence/delete?uuid=${record.key}`;
                            axios.post<Result<void>>(url).then((resp) => {
                              if (resp.data.Status === "1") {
                                message.info("删除成功");
                                fileGridRef.current.refresh();
                              }
                            });
                          }}
                        >
                          <Button type={"link"} icon={<DeleteOutlined />} />
                        </Popconfirm>
                      )}
                    </>
                  );
                },
              },
            ]}
          />
          <UploadWrapper
            multiple={false}
            visible={uploadWrapperDialogVisible}
            onOk={(file) => {
              setUploadWrapperDialogVisible(false);
              if (file.length > 0) {
                axios
                  .post(`/crm/correspondence/save`, {
                    attachments: file[0].uuid,
                    customerId: customer.uuid,
                  })
                  .then((resp) => {
                    if (resp.data.Status === "1") {
                      message.info("保存成功");
                      fileGridRef.current.refresh();
                    }
                  });
              }
            }}
            onCancel={() => setUploadWrapperDialogVisible(false)}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
