import React, {useCallback, useEffect, useState} from "react";
import {Form, Input, Checkbox, Button, message, Modal} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/es/form";
import i18n from "i18next";
import { useHistory } from "react-router";
import {Result} from "../../../../../../schemas/common";
import {instance as axios} from "../../../../../../util/axios";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
export interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/getContactListByCustomerId`, {
          customerId:customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            form.setFieldsValue({
              customerContactList:resp.data.Data
            });
          }
        });
  })
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    axios
        .post<Result<any[]>>(`/clothes/customerManage/saveCustomerContactList`, {
          data: JSON.stringify(values.customerContactList),
          customerId:customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            setLoading(false);
            setIsChange(false);
          }
        });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="customerId" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <Form.List name="customerContactList">
                {(fields, {add, remove}) => {
                  return (
                      <>
                        {fields.map((field) => (
                            <div
                                className="u-add-list-item"
                                style={{
                                  overflow: "hidden",
                                }}
                                key={field.key}
                            >
                              <div className="u-form-list">
                                <Form.Item name={[field.name, "uuid"]} hidden>
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                    name={[field.name, "customerId"]}
                                    hidden
                                    initialValue={customerId}
                                >
                                  <Input/>
                                </Form.Item>
                                <Form.Item label={i18n.t("名称")} name={[field.name, "name"]}>
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={i18n.t("职位")}
                                    name={[field.name, "position"]}
                                >
                                  <DictionarySelects entityName={"dict_customer_post"} addAble={true} filter={" 1=1 "}/>
                                </Form.Item>
                                <Form.Item label={i18n.t("手机")} name={[field.name, "phone"]}>
                                  <Input/>
                                </Form.Item>
                                <Form.Item label={i18n.t("微信")} name={[field.name, "wechat"]}>
                                  <Input/>
                                </Form.Item>
                                <Form.Item label={i18n.t("邮箱")} name={[field.name, "email"]}>
                                  <Input/>
                                </Form.Item>
                                <Form.Item label=" " colon={false}>
                                  {fields.length > 1 ? (
                                      <span
                                          className="u-del-form-list"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                      >
                                <DeleteOutlined/> {i18n.t("删除")}
                              </span>
                                  ) : null}
                                </Form.Item>
                              </div>
                            </div>
                        ))}
                        <div className="u-add-list">
                          <div className="u-form-list">
                            <Form.Item label=" " className="u-contacts-head" colon={false}>
                              {fields.length < 3 ? (
                                  <span
                                      className="u-add-cof"
                                      onClick={() => {
                                        add();
                                      }}
                                  >
                            <PlusOutlined/> {i18n.t("添加联系信息")}
                          </span>
                              ) : null}
                            </Form.Item>
                          </div>
                        </div>
                      </>
                  );
                }}
              </Form.List>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
