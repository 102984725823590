import { instance as axios } from "../../util/axios";
import { Result } from "../../schemas/common";
import { CellData } from "../DynamicForm/Designer/schemas/CellData";

export async function isDuplicate(
  entityName: string,
  fieldName: string,
  value: any,
  id: string | null
) {
  let duplicate = false;
  let resp = await axios.post<Result<"1" | "0">>(
    "/crm/common/tableGrid/validateMultiUnique",
    {
      tableName: entityName,
      fieldName: fieldName,
      value: value,
      id: id || "0",
    }
  );
  if (resp.data.Status === "1" && resp.data.Data !== "1") {
    duplicate = true;
  }
  return duplicate;
}

export function convertBDZD2CellData(bdzd: any): CellData {
  return {
    type: bdzd.DataType,
    id: bdzd.Name,
    label: bdzd.DisplayName,
    active: false,
    entityName: bdzd.DicTableName ? bdzd.DicTableName : "",
    value: bdzd.Value,
    disabled: bdzd.ReadOnly,
    required: bdzd.Required,
    multiple: bdzd.multiple ? bdzd.multiple : false,
  };
}
