import React, { useState } from "react";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal, Select, Radio, DatePicker, Input, Button } from "antd";
import { Description } from "../schemas/description";
import { DictionarySelect } from "./DictionarySelect";
import { Dictionary } from "../schemas/dictionary";
import { CRMBelong } from "../type";
const { Option } = Select;
const { RangePicker } = DatePicker;
export const CustomerBelongMapper: Dictionary[] = [
  {
    value: "",
    text: "全部",
  },
  {
    value: "other",
    text: "下属的",
  },
  {
    value: "self",
    text: "我的",
  },
  {
    value: "share",
    text: "共享给我的",
  },
];
export const DateRangeMapper: Dictionary[] = [
  {
    text: "最近7天",
    value: "recent7Days",
  },
  {
    text: "昨天",
    value: "yesterday",
  },
  {
    text: "今天",
    value: "today",
  },
  {
    text: "全部",
    value: "",
  },
];
export interface CustomerFilters {
  belong: CRMBelong;
  fieldFilters: FieldFilter[];
  dateRangeFilters: DateRangeFilters;
  customFilters: CustomFilters;
}
interface DateRangeFilters {
  [key: string]: string[] | string;
}
interface CustomFilters {
  [key: string]: any;
}
export interface FieldFilter {
  fieldName: string;
  fieldValue?: any;
  operator: string;
}
export interface CRMFilterDialogProps {
  visible: boolean;
  descriptions: Description[];
  customFilters?: ((
    filter: CustomerFilters,
    setCustomerFilter: React.Dispatch<React.SetStateAction<CustomerFilters>>
  ) => JSX.Element)[];
  dateRangeFilters?: [
    {
      title: string;
      dataIndex: string;
    }
  ];
  onOk: (form: CustomerFilters) => void;
  onCancel: () => void;
}
const CommonFilterForm:any = styled.div`
  margin-bottom: 15px;
  > span:first-of-type {
    width: 135px;
    text-align: right;
    display: inline-block;
  }
`;
const FieldFilterForm = styled.div`
  padding-left: 35px;
  > div {
    position: relative;
    margin-top: 10px;
  }
  .fieldFiltersItem {
    margin-bottom: 15px;
  }
`;

export const CRMFilterDialog = function ({
  descriptions,
  customFilters,
  dateRangeFilters,
  onCancel,
  onOk,
  visible,
}: CRMFilterDialogProps) {
  const [customerFilter, setCustomerFilter] = useState<CustomerFilters>({
    belong: "",
    customFilters: {},
    fieldFilters: [],
    dateRangeFilters: {},
  });
  return (
    <>
      <Modal
        title="筛选"
        width={860}
        visible={visible}
        bodyStyle={{ paddingBottom: 0 }}
        maskClosable={false}
        footer={
          <div>
            <Button key={1}>保存为常用条件</Button>
            <Button
              key={2}
              onClick={() => {
                setCustomerFilter({
                  belong: "",
                  dateRangeFilters: {},
                  customFilters: {},
                  fieldFilters: [],
                });
              }}
            >
              重置
            </Button>
            <Button
              key={3}
              type="primary"
              className="save"
              onClick={() => onOk && onOk(customerFilter)}
            >
              筛选
            </Button>
          </div>
        }
        onCancel={() => onCancel && onCancel()}
      >
        <FieldFilterForm>
          <div>
            {customerFilter.fieldFilters.length > 0 &&
              customerFilter.fieldFilters.map(
                (filter: FieldFilter, index: number) => {
                  const description = descriptions.find(
                    (item) => item.fieldName === filter.fieldName
                  )!;
                  return (
                    <div key={index} className="fieldFiltersItem">
                      <Select
                        value={filter.fieldName}
                        style={{ width: 126 }}
                        onChange={(value) => {
                          const copy: FieldFilter[] = [
                            ...customerFilter.fieldFilters,
                          ];
                          const find: Description = descriptions.find(
                            (item) => item.fieldName === value
                          )!;
                          if (find) {
                            copy[index].fieldName = value!;
                            if (find.inputType === 8 || find.inputType === 6) {
                              copy[index].operator = "等于";
                            } else if (find.inputType === 4) {
                              copy[index].operator = "介于";
                            }
                            setCustomerFilter({
                              ...customerFilter,
                              fieldFilters: copy,
                            });
                          }
                        }}
                      >
                        {descriptions.map((item: Description) => (
                          <Option
                            key={item.fieldName}
                            value={item.fieldName!}
                            type={item.inputType}
                          >
                            {item.fieldShowName}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        value={filter.operator}
                        style={{ width: 80, margin: "0 10px" }}
                        onChange={(value) => {
                          const copy: FieldFilter[] = JSON.parse(
                            JSON.stringify(customerFilter.fieldFilters)
                          );
                          copy[index].operator = value;
                          setCustomerFilter({
                            ...customerFilter,
                            fieldFilters: copy,
                          });
                        }}
                      >
                        {description && description.inputType === 4 ? (
                          <Option value="介于">介于</Option>
                        ) : description &&
                          (description.inputType === 6 ||
                            description.inputType === 8) ? (
                          <Option value="等于">等于</Option>
                        ) : (
                          <>
                            <Option value="等于">等于</Option>
                            <Option value="包含">包含</Option>
                          </>
                        )}
                      </Select>
                      {description &&
                      (description.inputType === 6 ||
                        description.inputType === 8) ? (
                        <DictionarySelect
                          value={filter.fieldValue}
                          style={{ width: 259 }}
                          entityName={description.dictionary!}
                          multiple={true}
                          onChange={(value) => {
                            const copy: FieldFilter[] = JSON.parse(
                              JSON.stringify(customerFilter.fieldFilters)
                            );
                            copy[index].fieldValue = value;
                            setCustomerFilter({
                              ...customerFilter,
                              fieldFilters: copy,
                            });
                          }}
                        />
                      ) : description && description.inputType === 4 ? (
                        <RangePicker
                          value={filter.fieldValue}
                          onChange={(value, dateString) => {
                            const copy: FieldFilter[] = JSON.parse(
                              JSON.stringify(customerFilter.fieldFilters)
                            );
                            copy[index].fieldValue = dateString;
                            setCustomerFilter({
                              ...customerFilter,
                              fieldFilters: copy,
                            });
                          }}
                        />
                      ) : (
                        <Input
                          value={filter.fieldValue}
                          style={{ width: 259 }}
                          onChange={(e) => {
                            const copy: FieldFilter[] = JSON.parse(
                              JSON.stringify(customerFilter.fieldFilters)
                            );
                            copy[index].fieldValue = e.target.value;
                            setCustomerFilter({
                              ...customerFilter,
                              fieldFilters: copy,
                            });
                          }}
                        />
                      )}
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          const copy: FieldFilter[] = JSON.parse(
                            JSON.stringify(customerFilter.fieldFilters)
                          );
                          copy.splice(index, 1);
                          setCustomerFilter({
                            ...customerFilter,
                            fieldFilters: copy,
                          });
                        }}
                      />
                    </div>
                  );
                }
              )}
            <Button
              type={"link"}
              onClick={() => {
                const copy: FieldFilter[] = JSON.parse(
                  JSON.stringify(customerFilter.fieldFilters)
                );
                copy.splice(customerFilter.fieldFilters.length, 0, {
                  fieldName: "",
                  operator: "",
                });
                setCustomerFilter({
                  ...customerFilter,
                  fieldFilters: copy,
                });
              }}
            >
              + 添加条件
            </Button>
          </div>
          <CommonFilterForm>
            <span>客户归属：</span>
            <Select
              value={customerFilter.belong}
              style={{ width: 350 }}
              onChange={(value) =>
                setCustomerFilter({
                  ...customerFilter,
                  belong: value,
                })
              }
            >
              {CustomerBelongMapper.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </CommonFilterForm>
          {dateRangeFilters?.map((filter) => (
            <CommonFilterForm key={filter.dataIndex}>
              <span>{filter.title}</span>
              <Radio.Group
                value={customerFilter.dateRangeFilters[filter.dataIndex]}
                onChange={(e) => {
                  const value = e.target.value;
                  setCustomerFilter({
                    ...customerFilter,
                    dateRangeFilters: {
                      ...customerFilter.dateRangeFilters,
                      [filter.dataIndex]: value,
                    },
                  });
                }}
              >
                {DateRangeMapper.map((range) => (
                  <Radio.Button value={range.value}>{range.text}</Radio.Button>
                ))}
              </Radio.Group>
              <RangePicker
                onChange={(value, dateString) => {
                  setCustomerFilter({
                    ...customerFilter,
                    dateRangeFilters: {
                      ...customerFilter.dateRangeFilters,
                      [filter.dataIndex]: dateString,
                    },
                  });
                }}
              />
            </CommonFilterForm>
          ))}
          {customFilters?.map((filter) => (
            <CommonFilterForm>
              {filter(customerFilter, setCustomerFilter)}
            </CommonFilterForm>
          ))}
        </FieldFilterForm>
      </Modal>
    </>
  );
};
