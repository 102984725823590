import React, {useEffect, useState} from "react";
import { Table, Avatar } from "antd";
import { instance as axios } from "../../../../../util/axios";

const columns = [
  {
    title: "userName",
    dataIndex: "userName",
    key: "1",
    width: "30%",
    render: (text: any, record: any) => (
      <>
        <div className="u-con-l">
          <Avatar src={"http://www.boeryun.cn/images/index/default_avatar.png"} style={{ padding: "0 0 0 0" }} />
        </div>
        <div className="u-con-r">
          <span>{text} </span>
          <p style={{ color: "#d0d0d0" }}>{record.departmentName}</p>
        </div>
      </>
    ),
  },
  { title: "opinion", dataIndex: "opinion", key: "2",width: "50%", },
  {
    title: "status",
    dataIndex: "status",
    key: "3",
      width: "20%",
      render: (status: any, record: any) => (
      <>
        <span style={{ color: "#51a851" }}> {record.result} </span>
        <p>{record.processTime}</p>
      </>
    ),
  },
];

export interface Params {
  formDataId: string;
  workflowId: string;
  workflowTemplateId: string;
}



export default ({ formDataId, workflowId, workflowTemplateId }: Params) => {
    const [data,setData] = useState<any>();
    useEffect(function () {
        axios.post("/wf/form/vsheet/getLcgcList", {
            workflowId: workflowId,
            formDataId: formDataId,
            workflowTemplateId: workflowTemplateId,
        }).then(function (resp) {
            let datas = resp.data;
            setData(datas);

        })
    },[])


    async function getLcList(
        formDataId: string,
        workflowId: string,
        workflowTemplateId: string
    ) {
        const resp = await axios.post("/wf/form/vsheet/getLcgcList", {
            workflowId: workflowId,
            formDataId: formDataId,
            workflowTemplateId: workflowTemplateId,
        });
        return resp;
    }
  return (
    <div className="u-collapseA invoice">
      <Table
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};
