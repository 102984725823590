import React, {useCallback, useEffect, useState} from "react";
import {
  Form,
  Input,
  message,
  Button, Modal,
} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import i18n from "i18next";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useHistory } from "react-router";
import Brand from "../Brand/Brand";
import TextArea from "antd/es/input/TextArea";
import "../../CSS/customer.css";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const [data,setData] = useState<any>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [registeredFilter,setRegisteredFilter] = useState("");
  const [officeFilter,setOfficeFilter] = useState("");
  const [isChange,setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作"),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/getCustomerInformationById`, {
          customerId: customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            form.setFieldsValue(resp.data.Data);
          }
        });
  }, []);
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    values.uuid = customerId;
    axios
      .post<Result<any[]>>(`/clothes/customerManage/saveCustomerInformation`, {
        data: JSON.stringify(values),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          setLoading(false);
          setIsChange(false);
          //刷新一下
          window.location.reload()
        }else{
          message.info(i18n.t("saveError"));
          setLoading(false);
        }
      });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  const nowDate = parseInt((new Date().getTime() / 1000).toString());
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/selectAllCustomer`, {
          customerId: customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data = resp.data.Data;
            if(data!=undefined&&data.length>0){
              setData(data);
            }
          }
        });
  },[])
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="uuid" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <div className="u-ThreCon">
                <Form.Item
                    label={i18n.t("客户名称")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        validator: async (rule, value) => {
                          if (value == undefined || value.trim() == "") {
                            message.error(i18n.t('客户名称不能为空'));
                            throw new Error(i18n.t('客户名称不能为空'));
                          }
                          if (data != undefined && data.length > 0) {
                            data.map((item: any) => {
                              if (item.name == value) {
                                message.error(i18n.t('客户名称重复'));
                                throw new Error(i18n.t('客户名称重复'));
                              }
                            })
                          }
                        }
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("公司注册地址")} name="registeredAddress">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("公司办公地址")} name="officeAddress">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("公司名称")} name="shortName">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("国家")} name="registeredCountry">
                  <DictionarySelect entityName={"dict_country"} onChange={
                    (value) => {
                      setRegisteredFilter(" countryId = '" + value + "'");
                    }
                  } isTrans={true}/>
                </Form.Item>
                <Form.Item label={i18n.t("国家")} name="officeCountry">
                  <DictionarySelect entityName={"dict_country"} onChange={
                    (value) => {
                      setOfficeFilter(" countryId = '" + value + "'");
                    }
                  } isTrans={true}/>
                </Form.Item>
                <Form.Item hidden={customerType != "4"} label={i18n.t("品牌")} name="brand">
                  <Brand form={form} isClose={false}/>
                </Form.Item>
                <Form.Item label={i18n.t("城市")} name="registeredCity">
                  <Input/>
                  {/*<DictionarySelects entityName={"dict_city"} filter={registeredFilter}/>*/}
                </Form.Item>
                <Form.Item label={i18n.t("城市")} name="officeCity">
                  <Input/>
                  {/*<DictionarySelects entityName={"dict_city"} filter={officeFilter}/>*/}
                </Form.Item>
                <Form.Item label="p.iva" name="p_iva">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("邮编")} name="registeredPostcode">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("邮编")} name="officePostcode">
                  <Input/>
                </Form.Item>
                <Form.Item label="COD. FIS" name="COD_FIS">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("街道")} name="registeredStreet">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("街道")} name="officeStreet">
                  <Input/>
                </Form.Item>
                <Form.Item label="COD.SDI" name="COD_SDI">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("省会编码")} name="registeredProvincialCapitalCode">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("省会编码")} name="officeProvincialCapitalCode">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("客户种类")} name="customerType">
                  <DictionarySelect entityName={"dict_customer_type"}/>
                </Form.Item>
                <Form.Item label={i18n.t("中介")} name="introducerCode">
                  <DictionarySelects entityName={"clothes_customer"}
                                     filter={"uuid!='" + customerId + "' AND customerType='2' AND 1!=" + nowDate}/>
                </Form.Item>
                <Form.Item label={i18n.t("中介收费")} name="introducerCharges">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("联系人")} name="contact">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("联系方式")} name="contactInformation">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("客户税率")} name="taxId">
                  <DictionarySelect entityName={"dict_tax"}/>
                </Form.Item>
                {customerType == "5" && <Form.Item label={i18n.t("加工厂编码")} name="customerNumber">
                  <Input/>
                </Form.Item>}
                <Form.Item className="remark" label={i18n.t("客户备注")} name="remark" {...{
                  labelCol: {span: 3},
                  wrapperCol: {span: 20}
                }}>
                  <TextArea rows={4}/>
                </Form.Item>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
