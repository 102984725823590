import React, { useEffect, useState, useContext } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined, FormOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { DynamicForm } from "../../../../../../schemas/dynamicForm";
import { Result } from "../../../../../../schemas/common";
import { EntityName } from "../../../../../../type";

export interface OperationButtonGroupProps {
  dynamicTabId: string;
  id: string;
  dataId: string;
  advisorId: string;
  onOk: (form: {
    uuid: string;
    formName: string;
    entityName: EntityName;
    workflowTemplateId?: string;
  }) => void;
}

/**
 * 动态选项卡列表行内"新建"按钮
 * @param dynamicTabId 动态选项卡 ID
 * @param customerId 客户 ID
 * @param dataId 行数据 uuid
 * @param advisorId 业务员 ID
 * @param onOk 新建按钮点击回调
 * @constructor
 */
export const OperationButtonGroup = ({
  dynamicTabId,
  id,
  dataId,
  advisorId,
  onOk,
}: OperationButtonGroupProps) => {
  const session: Session = useContext(SessionContext);
  const [data, setData] = useState<
    {
      uuid: string;
      formName: string;
      entityName: EntityName;
      workflowTemplateId?: string;
    }[]
  >([]);
  useEffect(
    function () {
      axios
        .get<Result<DynamicForm[]>>(
          `/crm/dynamicTab/getRelevanceDynamicForm?dynamicTabId=${dynamicTabId}&hostPrimaryKey=${id}&prevPrimaryKey=${dataId}`
        )
        .then((resp) => {
          if (resp.data.Status === "1" && resp.data.Data.length > 0) {
            setData(
              resp.data.Data.filter(
                (item) => advisorId === session.id && item.isCreatable
              ).map((item) => ({
                ...item,
                entityName: item.tableName,
              }))
            );
          }
        });
    },
    [advisorId, id, dataId, dynamicTabId, session.id]
  );
  const menu = (
    <Menu>
      {data.map((item) => (
        <Menu.Item key={`opt-button-${item.uuid}`}>
          <span onClick={() => onOk?.(item)}>{item.formName}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {data.length > 0 && (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type={"link"} onClick={(e) => e.preventDefault()}>
            <FormOutlined /> <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </>
  );
};
