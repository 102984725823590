import React, { useState, useRef, useEffect, useCallback } from "react";
import { Popconfirm, message, Button, Space, Radio, Spin, List } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../../../util/axios";
import { DataGrid } from "../../../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { ContactListItem } from "../../../../components/ContactListItem";
import { CustomerContactDetailDialog } from "../../../../components/CustomerContactDetailDialog";
import { Result } from "../../../../../../../../schemas/common";
import { AddContact } from "../../../../components/AddContact";
import { Like } from "../../../Contact/List/components/Like";
import { Comment } from "../../../Contact/List/components/Comment";

export interface ContactListProps {
  customerId: string;
  editable?: boolean;
}

export const ContactList = ({
  customerId,
  editable = true,
}: ContactListProps) => {
  const [list, setList] = useState<{
    data: {
      uuid: string;
      isLike: boolean;
      likeNumber: number;
      commentNumber: number;
      customerName: string;
      content: string;
      advisorId: string;
      contactTime: string;
      stage: string;
      commentList: {
        name: string;
        content: string;
        time: string;
      }[];
    }[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const gridRef = useRef<any>(null);
  const { height } = useWindowSize();
  const [editForm, setEditForm] = useState({
    visible: false,
    id: "",
  });
  /**
   * list为平铺，table为列表
   */
  const [displayType, setDisplayType] = useState<"list" | "table">("list");
  const getListData = useCallback(
    async (pageSize, pageIndex) => {
      let data = {
        customerId: customerId,
        pageSize: pageSize,
        pageIndex: pageIndex,
      };
      setLoading(true);
      const resp = await axios.post("/crm/crm/contact/getContactTile", data);
      let pageNumbers = resp.data.Data.pageInfo.pageNumbers;
      let total: number = pageNumbers * resp.data.Data.pageInfo.pageSize;
      setList((prevState) => ({
        ...prevState,
        data: resp.data.Data.contactTileList,
        total: total,
      }));
      setLoading(false);
    },
    [customerId]
  );
  useEffect(() => {
    getListData(pageSize, pageIndex).then();
  }, [getListData, pageIndex, pageSize]);

  return (
    <>
      {editable && (
        <AddContact
          host="customer"
          id={customerId}
          entityName="crm_contact"
          onOk={() => {
            if (displayType === "list") {
              getListData(pageSize, pageIndex).then();
            } else {
              gridRef.current?.refresh();
            }
          }}
        />
      )}
      <div style={{ margin: "30px 0 20px" }}>
        <Radio.Group
          onChange={(e) => {
            setDisplayType(e.target.value);
            if (e.target.value === "list") {
              getListData(pageSize, pageIndex).then();
            } else {
              gridRef.current?.refresh();
            }
          }}
          defaultValue="list"
        >
          <Radio.Button value="list">平铺</Radio.Button>
          <Radio.Button value="table">列表</Radio.Button>
        </Radio.Group>
      </div>
      {displayType === "list" && (
        <Spin spinning={loading}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              total: list.total,
              current: pageIndex,
              onChange: (page, pageSize) => {
                setPageIndex(page);
                getListData(pageSize, page).then();
              },
              onShowSizeChange: (current, size) => {
                setPageSize(size);
                getListData(size, current).then();
              },
              pageSize: pageSize,
            }}
            dataSource={list.data}
            renderItem={(item: any) => (
              <List.Item key={item.uuid} actions={[]}>
                <ContactListItem
                  editable={editable}
                  data={item}
                  entityName="crm_contact"
                  onChange={() => getListData(pageSize, pageIndex).then()}
                />
              </List.Item>
            )}
          />
        </Spin>
      )}
      {displayType === "table" && (
        <DataGrid
          style={{ marginBottom: 8 }}
          pageSize={Math.floor((height - 280) / 42)}
          pagination={true}
          ref={gridRef}
          sorter={{ field: "createTime", order: "descend" }}
          descriptions={[
            {
              fieldShowName: "操作",
              render: (text: any, record: any) => {
                return (
                  <>
                    <Space>
                      {editable && (
                        <Button
                          type={"link"}
                          icon={<EditOutlined />}
                          onClick={() => {
                            setEditForm({
                              ...editForm,
                              visible: true,
                              id: record.key,
                            });
                          }}
                        />
                      )}
                      {editable && (
                        <Popconfirm
                          title="确定要删除吗?"
                          onConfirm={() => {
                            const url = `/crm/crm/contact/deleteContact?ids=${record.key}`;
                            axios.delete<Result<void>>(url).then((resp) => {
                              if (resp.data.Status === "1") {
                                message.info("删除成功");
                                gridRef.current.refresh();
                              } else {
                                message.error(resp.data.Message);
                              }
                            });
                          }}
                        >
                          <Button type={"link"} icon={<DeleteOutlined />} />
                        </Popconfirm>
                      )}
                      <Comment
                        count={record.commentNumber}
                        uuid={record.uuid}
                        onComment={() => {
                          gridRef.current.refresh();
                        }}
                      />
                      <Like
                        count={record.likeNumber}
                        uuid={record.uuid}
                        liked={record.isLike}
                        onLike={() => {
                          gridRef.current.refresh();
                        }}
                      />
                    </Space>
                  </>
                );
              },
              align: "center",
              fixed: true,
              width: 200,
            },
            {
              fieldName: "customerId",
              fieldShowName: "客户",
              width: 150,
              dictionary: "crm_customer",
            },
            {
              fieldName: "content",
              fieldShowName: "内容",
              width: 200,
            },
            {
              fieldName: "stage",
              fieldShowName: "把握度",
              width: 100,
              dictionary: "dict_contact_stage",
            },
            {
              fieldName: "advisorId",
              fieldShowName: "业务员",
              width: 100,
              dictionary: "base_staff",
            },
            {
              fieldName: "contactTime",
              fieldShowName: "联系时间",
              width: 100,
            },
            {
              fieldName: "intentionCategory",
              fieldShowName: "意向产品",
              width: 100,
            },
          ]}
          url={`/crm/crm/contact/getContact?customerId=${customerId}&projectId=&chanceId=`}
        />
      )}
      <CustomerContactDetailDialog
        visible={editForm.visible}
        uuid={editForm.id}
        onCancel={() => {
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
        onOk={() => {
          if (displayType === "list") {
            getListData(pageSize, 1).then();
          } else {
            gridRef.current?.refresh();
          }
          setEditForm({
            ...editForm,
            visible: false,
          });
        }}
      />
    </>
  );
};
