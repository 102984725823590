import React, { useState } from "react";
import i18n from "i18next";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import {saveDataGrid} from "../components/EditForm";

const GetRender = function (
  gridRef: any,
  onSave: () => void,
  entityName: string,
  setDefault?: boolean
) {
  var deleteUrl = "/clothes/common/CommonApi/deleteById";
  return function (_: any, record: any) {
    return (
      <>
        {gridRef.current.isEditing(record) ? (
          <>
            <a
              style={{ marginRight: 16 }}
              onClick={async (e) => {
                e.stopPropagation();
                await gridRef.current.save(record.key);
                onSave();
              }}
            >
              {i18n.t("确认")}
            </a>
            {!record.uuid ? (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  gridRef.current.remove(record.key);
                }}
              >
                {i18n.t("撤销")}
              </a>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {gridRef.current.isOnEdit() ? (
              <>
                <a
                  style={{ color: "gray" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {i18n.t("delete")}
                </a>
              </>
            ) : setDefault ? (
              <>
                <a
                  style={{ marginRight: 16 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(record);
                    Modal.confirm({
                      title: i18n.t("删除提示"),
                      icon: <ExclamationCircleOutlined />,
                      content: i18n.t("删除操作"),
                      okText: i18n.t("确定"),
                      okType: "danger",
                      cancelText: i18n.t("cancelText"),
                      onOk() {
                        if (record.key) {
                          axios
                            .post(deleteUrl, {
                              uuid: record.key,
                              tableName: entityName,
                            })
                            .then((resp) => {
                              if (resp.data.Status == 1) {
                                message.success(i18n.t("deleteSuccess"));
                              } else {
                                message.error(i18n.t("deleteError"));
                              }
                              // dataGridRef.current.refresh();
                            })
                            .catch(() => {
                              message.error(i18n.t("deleteError"));
                            });
                        }
                        gridRef.current.remove(record.key);
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
                >
                  {i18n.t("delete")}
                </a>
                <a
                    onClick={async (e) => {
                      e.stopPropagation();
                      gridRef.current.setDefault(record);
                      await saveDataGrid(gridRef);
                    }}
                >
                  {i18n.t("设为默认")}
                </a>
              </>
            ) : (
              <>
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(record);
                    Modal.confirm({
                      title: i18n.t("删除提示"),
                      icon: <ExclamationCircleOutlined />,
                      content: i18n.t("删除操作"),
                      okText: i18n.t("确定"),
                      okType: "danger",
                      cancelText: i18n.t("cancelText"),
                      onOk() {
                        if (record.key) {
                          axios
                            .post(deleteUrl, {
                              uuid: record.key,
                              tableName: entityName,
                            })
                            .then((resp) => {
                              if (resp.data.Status == 1) {
                                message.success(i18n.t("deleteSuccess"));
                              } else {
                                message.error(i18n.t("deleteError"));
                              }
                              // dataGridRef.current.refresh();
                            })
                            .catch(() => {
                              message.error(i18n.t("deleteError"));
                            });
                        }
                        gridRef.current.remove(record.key);
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
                >
                  {i18n.t("delete")}
                </a>
              </>
            )}
          </>
        )}
      </>
    );
  };
};

export default { GetRender };
