import React from "react"
import { Form, Input, Select, DatePicker} from 'antd'
import DateTimePickerWithFormat from "../../../../../../components/DateTimePicker";
import i18n from "i18next";

export default ()=> {

	return (
		<div className="u-tab-con u-tab-csfs">
			<Form.Item label={i18n.t("包装方式")} name="packageId">
				<Select allowClear>
					<Select.Option value="1">Option1</Select.Option>
					<Select.Option value="2">Option2</Select.Option>
					<Select.Option value="3">Option3</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label={i18n.t("件数")} name="packageNumber">
				<Input/>
			</Form.Item>
			<Form.Item label={i18n.t("重量")} name="weight">
				<Input addonAfter="KG"/>
			</Form.Item>
			<Form.Item label={i18n.t("港口")} name="portId">
				<Select allowClear>
					<Select.Option value="1">Option1</Select.Option>
					<Select.Option value="2">Option2</Select.Option>
					<Select.Option value="3">Option3</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label={i18n.t("送货时间")} name="deliveryTime">
				<DateTimePickerWithFormat />
			</Form.Item>
			<Form.Item label={i18n.t("运输")} name="transportWay">
				<Select allowClear>
					<Select.Option value="1">Option1</Select.Option>
					<Select.Option value="2">Option2</Select.Option>
					<Select.Option value="3">Option3</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label={i18n.t("运输公司")} name="transportCompany">
				<Input/>
			</Form.Item>
		</div>
	);
	
}