import { Modal, Upload } from "antd";
import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { BaseURL } from "../../constants/url";
import i18n from "i18next";

const { Dragger } = Upload;

interface UploadWrapperProps {
  multiple?: boolean;
  visible: boolean;
  onOk: (file: File[]) => void;
  onCancel: () => void;
}

interface File {
  uuid: string;
  name: string;
}

export default function ({
  multiple = false,
  visible,
  onOk,
  onCancel,
}: UploadWrapperProps) {
  const [file, setFile] = useState<File[]>([]);
  return (
    <Modal
      title={i18n.t("fileUpload")}
      onCancel={onCancel}
      destroyOnClose={true}
      onOk={() => onOk(file)}
      visible={visible}
      maskClosable={false}
    >
      <Dragger
        withCredentials={true}
        data={(file) => {
          return {
            fileData: file,
            category: "id",
            fileName: encodeURI(file.name),
          };
        }}
        multiple={multiple}
        action={BaseURL + "/oa/common/attachment/uploadFile"}
        method={"post"}
        onChange={(param) => {
          setFile(
            param.fileList
              .filter((item) => item.status === "done")
              .map((item) => ({
                name: item.response.Data.filename,
                uuid: item.response.Data.uuid,
              }))
          );
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{i18n.t("fileUploadMessage")}</p>
      </Dragger>
    </Modal>
  );
}
