import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {
  Form,
  Input,
  message,
  Button, Modal,
} from "antd";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import i18n from "i18next";
import { formatDateTime } from "../../util/DateFormat";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useHistory } from "react-router";
import Brand from "../Brand/Brand";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
interface copyProps {
  customerId: string;
  onOk: (key:string) => void;
  setFactory: () => void;
  customerType: string;
}
export default ({ customerId,onOk,setFactory,customerType }: copyProps) => {
  const [data,setData] = useState<any>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nextStepLoading, setNextStepLoading] = useState(false);
  const [save,setSave] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const confirm = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);
  const onFinish = (values: any) => {
    console.log("Finish:", values);
    values.uuid = customerId;
    let customerType = values.customerType;
    axios
      .post<Result<any[]>>(`/clothes/customerManage/saveCustomer`, {
        data: JSON.stringify(values),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          if(save){
            history.push("/clothes/CRM/"+customerType);
          }else{
            if(customerType==="5"){
              setFactory();
            }
            onOk("2");
            setNextStepLoading(false);
          }
        }
      });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/customerManage/selectAllCustomer`, {
          customerId: customerId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data = resp.data.Data;
            if(data!=undefined&&data.length>0){
              setData(data);
            }
          }
        });
  },[])
  useEffect(function () {
    form.setFieldsValue({
      customerType:customerType,
      country:"ITA",
      currencyId:"1"
    })
  },[])
  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
            >
              <div className="u-form-lr">
                <div className="u-form-list">
                  <Form.Item name="uuid" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="currencyId" hidden>
                    <Input/>
                  </Form.Item>
                </div>
              </div>
              <div className="u-TabCon">
                <Form.Item
                    label={i18n.t("客户名称")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        validator: async (rule, value) => {
                          if (value == undefined || value.trim() == "") {
                            message.error(i18n.t('客户名称不能为空'));
                            throw new Error(i18n.t('客户名称不能为空'));
                          }
                          if (data != undefined && data.length > 0) {
                            data.map((item: any) => {
                              if (item.name == value) {
                                message.error(i18n.t('客户名称重复'));
                                throw new Error(i18n.t('客户名称重复'));
                              }
                            })
                          }
                        }
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("公司名称")} name="shortName">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("客户类型")} name="customerType">
                  <DictionarySelect entityName={"dict_customer_type"}/>
                </Form.Item>
                <Form.Item label={i18n.t("联系人方式")} name="contactInformation">
                  <Input/>
                </Form.Item>
                <Form.Item label={i18n.t("客户联系人")} name="contact">
                  <Input/>
                </Form.Item>
                {/*<Form.Item label={i18n.t("客户所在国家")} name="country">
                  <DictionarySelect entityName={"dict_country"}/>
                </Form.Item>*/}
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                          setSave(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                          form.submit();
                          setNextStepLoading(true);
                        }}
                        loading={nextStepLoading}
                        className="u-form-buttonA"
                    >
                      {i18n.t("下一步")}
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                          confirm(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
