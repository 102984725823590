import React, { useState, useRef, useEffect } from "react";
import { Popconfirm, message, Button, Space, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { DataGrid } from "../../../../../../components/DataGrid";
import { get } from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { TableGrid } from "../../../../../../components/TableGrid";
import { useWindowSize } from "react-use";
export interface ContactsProps {
  customerId: string;
  editable?: boolean;
}

/**
 * 客户联系人列表
 * @param customerId 客户 ID
 * @constructor
 */
export const Contacts = ({ customerId, editable = true }: ContactsProps) => {
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const { height } = useWindowSize();
  const contactsGridRef = useRef<any>();
  const contactsTableGridRef = useRef<any>();
  const [contactsForm, setContactsForm] = useState({
    visible: false,
    uuid: "",
  });
  useEffect(function () {
    get("crm_customer_contact").then((descriptions: FieldDescription[]) => {
      const descriptionsCopy = JSON.parse(JSON.stringify(descriptions));
      descriptionsCopy.unshift({
        fieldShowName: "操作",
        render: (text: any, record: any) => {
          return (
            <>
              {editable && (
                <Space>
                  <Button
                    type={"link"}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setContactsForm({ visible: true, uuid: record.key });
                    }}
                  />
                  <Popconfirm
                    title="确定要删除吗?"
                    onConfirm={() => {
                      const url = `/crm/customer/deleteOtherContact?ids=${record.key}`;
                      axios.delete(url).then(() => {
                        message.info("删除成功");
                        contactsGridRef.current.refresh();
                      });
                    }}
                  >
                    <Button type={"link"} icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              )}
            </>
          );
        },
        align: "center",
        fixed: true,
      });
      setDescriptions(descriptionsCopy);
    });
  }, []);

  return (
    <>
      {editable && (
        <div style={{ textAlign: "right" }}>
          <Button
            type={"link"}
            style={{ color: "#1890ff", paddingRight: 0 }}
            onClick={() => setContactsForm({ visible: true, uuid: "" })}
          >
            +联系人
          </Button>
        </div>
      )}
      <DataGrid
        pageSize={Math.floor((height - 320) / 42)}
        ref={contactsGridRef}
        url={"/crm/customer/getOtherContactList?customerId=" + customerId}
        descriptions={descriptions}
      />
      <Modal
        title="联系人详情"
        width={800}
        visible={contactsForm.visible}
        onCancel={() => {
          setContactsForm({ visible: false, uuid: "" });
        }}
        onOk={() => {
          contactsTableGridRef.current.save();
        }}
      >
        <TableGrid
          ref={contactsTableGridRef}
          id={contactsForm.uuid}
          key={new Date().toString()}
          entityName="crm_customer_contact"
          columnCount={2}
          defaultValues={{ customerId }}
          hiddenFields={["customerId"]}
          onSaved={() => {
            contactsGridRef.current.refresh();
            setContactsForm({ visible: false, uuid: "" });
          }}
        />
      </Modal>
    </>
  );
};
