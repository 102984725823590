import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button, Form, Input, message, Modal, Table } from "antd";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
import { useWindowSize } from "react-use";
import { instance as axios } from "../../../../../util/axios";
import i18n from "i18next";
import { ScanOutlined } from "@ant-design/icons/lib";

interface Params {
  warehouseId: string;
  storageType:0|1|2|3|4
}

const ScanSerialNumber = forwardRef(({ warehouseId,storageType }: Params, ref: any) => {
  const { width, height } = useWindowSize();
  const [tableData, setTableData] = useState<any>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: i18n.t("public_serialNumber"),
      dataIndex: "serialNumber",
      width: 150,
    },
    {
      title: i18n.t("skuCode"),
      dataIndex: "skuCode",
      width: 150,
    },
    {
      title: i18n.t("skuName"),
      dataIndex: "skuName",
      width: 150,
    },
    {
      title: i18n.t("warehouse"),
      dataIndex: "warehouse",
      width: 150,
    },
    {
      title: i18n.t("location"),
      dataIndex: "location",
      width: 150,
    },
    {
      title: i18n.t("quantity"),
      dataIndex: "quantity",
      width: 150,
    },
  ];

  useImperativeHandle(ref, () => ({
    getTableData() {
      return tableData;
    },
  }));

  /**
   *序列号是否重复
   * @param serialNumber
   */
  const isRepeat = (serialNumber: string) => {
      const data = [...tableData];
      let flag:boolean = false;
      data.map((item)=>{
          if(item.serialNumber === serialNumber){
              flag = true;
              //终止迭代
              return false;
          }
      })
      return flag;
  };

  useEffect(() => {
    form.setFieldsValue({ warehouseId: warehouseId });
  }, []);

  function handleKeyUp(key: any) {
    if (key.keyCode == 13) {
      var serialNumber = form.getFieldValue("serialNumber");
      //判断是否重复选择
        if(isRepeat(serialNumber)){
            form.setFieldsValue({ serialNumber: null });
            message.info(i18n.t("public_serialNumberRepeat"));
            return;
        }
      setLoading(true);
      axios
        .get(
          "/inventory/skuSerialNumber/getSkuSerialBySerialNumber?serialNumber=" +
            serialNumber+"&storageType="+storageType
        )
        .then(function (resp) {
          switch (resp.data.Status) {
            case "1":
              if (resp.data.Data == null) {
                message.info(i18n.t("public_dataNotFound"));
              } else {
                var data = resp.data.Data;
                data.quantity = 1;
                data.key = data.uuid;
                delete data.uuid;
                setTableData([...tableData, data]);
              }
              break;
            case "2":
              message.info(i18n.t("sale_storage_serialNumberStored"));
              break;
            case "4":
              message.info(i18n.t("outOfStock_serialNumberReleased"));
              break;
            case "5":
              message.info(i18n.t("transfer_order_serialNumberNotStored"));
              break;
            case "6":
              message.info(i18n.t("outOfStock_serialNumberReleased"));
              break;
            default:
              message.error(i18n.t("public_getDataError"));
              break;
          }

        })
        .catch(() => {
          message.error(i18n.t("public_getDataError"));
        })
        .finally(() => {
          setLoading(false);
          form.setFieldsValue({ serialNumber: null });
        });
    }
  }

  return (
    <div>
      <div className={"u-form"}>
        <Form form={form}>
          <Form.Item
            name={"serialNumber"}
            label={i18n.t("sale_storage_placeScanSerial")}
          >
            <Input
                autoFocus={true}
              onKeyUp={handleKeyUp}
              suffix={
                <ScanOutlined
                  onClick={() => {
                  }}
                  style={{ fontSize: "20px" }}
                />
              }
            />
          </Form.Item>
          <Form.Item name={"warehouseId"} label={i18n.t("warehouse")}>
            <DictionarySelect
              entityName={"inventory_warehouse"}
              value={warehouseId}
            />
          </Form.Item>
        </Form>
      </div>
      <br />
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: (height * 2) / 3 - 40 }}
      />
    </div>
  );
});
export default ScanSerialNumber;
