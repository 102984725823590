import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { get } from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { SessionContext } from "../../../../index";
import { Detail } from "../Detail";
import { Button, Layout, Modal, Space, Tabs } from "antd";
import Session from "../../../../../../schemas/session";
import { Description } from "../../../../../../schemas/description";
import { PlusOutlined } from "@ant-design/icons/lib";
import { TableGrid } from "../../../../../../components/TableGrid";
import moment from "moment";
import { getConfigById } from "../../../../../../util/config";
import { ConfigId } from "../../../../../../enum";

const { Content } = Layout;
const { TabPane } = Tabs;
const defaultUrl = "/crm/crm/project/getProjectList";
export default function List() {
  useEffect(function () {
    get("crm_project").then((descriptions: FieldDescription[]) => {
      setDescriptions(descriptions);
    });
  }, []);
  useEffect(function () {
    getConfigById(ConfigId.ProjectLaunchMode).then((config) => {
      if (config && config.value === "直接新建") {
        setProjectCreatable(true);
      }
    });
  }, []);
  const gridRef = useRef<any>();
  const tableGridRef = useRef<any>(null);
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const [projectCreatable, setProjectCreatable] = useState<boolean>(false);
  const { height } = useWindowSize();
  const session: Session = useContext(SessionContext);
  const [currentTabKey, setCurrentTabKey] = useState<"-1" | "0" | "1">("-1");
  const [addProjectForm, setAddProjectForm] = useState({
    visible: false,
    mvp: true,
  });

  const formatDescriptions = useCallback(
    function () {
      const formattedCurrentDescriptions = JSON.parse(
        JSON.stringify(descriptions)
      );
      formattedCurrentDescriptions.forEach((description: Description) => {
        if (description.fieldName === "name") {
          description.fixed = true;
          description.render = function (text: string) {
            const textStyle = { marginLeft: `4px` };
            return (
              <>
                <span style={textStyle}>{text}</span>
              </>
            );
          };
        }
      });
      return formattedCurrentDescriptions;
    },
    [descriptions]
  );
  function handleTabChange(key: string): void {
    setCurrentTabKey(key as "-1" | "0" | "1");
  }
  const getTabPane = useCallback(
    function (
      tabName: string,
      key: "-1" | "0" | "1",
      gridRef: React.MutableRefObject<any>,
      url: string
    ) {
      return (
        <TabPane tab={tabName} key={key}>
          {currentTabKey === key && (
            <>
              <Layout>
                <Content>
                  <Layout style={{ paddingLeft: 10 }}>
                    <Content>
                      {descriptions.length > 0 && height && (
                        <>
                          <DataGrid
                            onDraw={(record) => (
                              <Detail key={record.key} projectId={record.key} />
                            )}
                            drawerWidth={1000}
                            pageSize={Math.floor((height - 220) / 42)}
                            ref={gridRef}
                            url={url}
                            rowSelectionType="checkbox"
                            descriptions={formatDescriptions()}
                          />
                        </>
                      )}
                    </Content>
                  </Layout>
                </Content>
              </Layout>{" "}
            </>
          )}
        </TabPane>
      );
    },
    [currentTabKey, height, formatDescriptions, descriptions]
  );
  return (
    <>
      <Tabs
        defaultActiveKey={currentTabKey}
        onChange={handleTabChange}
        tabBarExtraContent={
          <Space>
            {projectCreatable && (
              <Button
                type={"primary"}
                icon={<PlusOutlined />}
                onClick={() => {
                  setAddProjectForm({
                    ...addProjectForm,
                    visible: true,
                  });
                }}
              >
                新建项目
              </Button>
            )}
          </Space>
        }
      >
        {getTabPane("全部", "-1", gridRef, defaultUrl)}
        {getTabPane("进行中", "0", gridRef, defaultUrl + "?status=unFinished")}
        {getTabPane("完成", "1", gridRef, defaultUrl + "?status=finished")}
      </Tabs>
      <Modal
        title="新建项目"
        width={900}
        bodyStyle={{ height: 400, overflowY: "auto" }}
        visible={addProjectForm.visible}
        onCancel={() => {
          setAddProjectForm({
            ...addProjectForm,
            visible: false,
          });
        }}
        onOk={() => {
          tableGridRef.current.save();
        }}
      >
        <>
          <div>
            <TableGrid
              ref={tableGridRef}
              entityName="crm_project"
              columnCount={2}
              mvp={addProjectForm.mvp}
              defaultValues={{
                creatorId: session.id,
                advisorId: session.id,
                createTime: moment().format("YYYY-MM-DD"),
              }}
              onSaved={() => {
                setAddProjectForm({
                  ...addProjectForm,
                  visible: false,
                });
              }}
            />
          </div>
        </>
      </Modal>
    </>
  );
}
