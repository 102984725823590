import {instance as axios} from "../../../../../util/axios";

const getDocNumber = async (docType:string)=>{
    const resp = await axios
        .get(
            "/inventory/serialNumberSequence/getSerialNumber?sequenceName=" +
            docType
        );
    return resp.data.Data;
};
export {getDocNumber}