import React from "react";
import { Badge } from "antd";
import { AvatarWrapper } from "../../../../../../components/AvatarWrapper";
import { Dialing } from "../../components/Dialing";
import { APPDialing } from "../../components/APPDialing";
import { CustomerFilters } from "../../../../../../components/CRMFilterDialog";
import moment from "moment";
import { Description } from "../../../../../../schemas/description";
import { CRMBelong } from "../../../../../../type";

function map(belong: CRMBelong): string {
  switch (belong) {
    case "":
      return "";
    case "self":
      return "myCustomer";
    case "share":
      return "shareCustomer";
    case "other":
      return "subordinateCustomer";
  }
}

export function isNew({ lastAssignTime, lastContactTime }: any) {
  return (
    lastAssignTime && (!lastContactTime || lastAssignTime > lastContactTime)
  );
}

export function nameRender(text: any, record: any) {
  const textStyle = { marginLeft: "4px", verticalAlign: "middle" };
  return (
    <>
      {isNew(record) ? (
        <Badge status={"warning"} />
      ) : (
        <Badge status={"default"} style={{ opacity: 0 }} />
      )}
      <span style={textStyle}>{text}</span>
    </>
  );
}

export function advisorIdRender(text: any, record: any) {
  return <AvatarWrapper userId={record.ref.advisorId} userName={text} />;
}

export function mobileRender(text: any, record: any) {
  const advisorId = record.ref.advisorId;
  const textStyle = { marginLeft: "4px", verticalAlign: "middle" };
  return (
    <>
      <span style={{ verticalAlign: "middle" }}>{text}</span>
      {record.mobile && (
        <>
          <Dialing
            advisorId={advisorId}
            style={textStyle}
            uuid={record.uuid}
            mobile={record.mobile}
          />
          <APPDialing style={textStyle} mobile={record.mobile} />
        </>
      )}
    </>
  );
}

export function buildFilterURL(
  filter: CustomerFilters,
  descriptions: Description[]
): string {
  let filterUrl = "";
  if (filter.belong) {
    filterUrl += `&customerBelong=${map(filter.belong)}`;
  }
  if (filter.customFilters.tags && filter.customFilters.tags.length > 0) {
    filterUrl += `&labels=${filter.customFilters.tags.join(",")}`;
  }
  if (filter.dateRangeFilters.contactTime) {
    const format = "YYYY-MM-DD";
    let contactTimeRange: [string | null, string | null] | null;
    if (filter.dateRangeFilters.contactTime === "recent7Days") {
      contactTimeRange = [
        moment().add(-6, "days").format(format),
        moment().startOf("day").format(format),
      ];
    } else if (filter.dateRangeFilters.contactTime === "yesterday") {
      contactTimeRange = [moment().add(-1, "days").format(format), null];
    } else if (filter.dateRangeFilters.contactTime === "today") {
      contactTimeRange = [moment().startOf("day").format(format), null];
    } else if (
      filter.dateRangeFilters.contactTime instanceof Array &&
      filter.dateRangeFilters.contactTime.length === 2
    ) {
      contactTimeRange = [
        filter.dateRangeFilters.contactTime[0],
        filter.dateRangeFilters.contactTime[1],
      ];
    } else {
      contactTimeRange = [null, null];
    }
    filterUrl += `&startContactTime=${
      contactTimeRange[0] || ""
    }&endContactTime=${contactTimeRange[1] || ""}`;
  }
  if (filter.fieldFilters.length > 0) {
    filterUrl += `&selectCondition=${encodeURI(
      JSON.stringify(
        filter.fieldFilters!.map((item) => {
          const inputType = descriptions.find(
            (description) => item.fieldName === description.fieldName
          )!.inputType!;
          const operatorMapper = [
            "string",
            null,
            "number",
            "bool",
            "datetime",
            null,
            "dictionary",
            null,
            "dictionary",
          ];
          let fieldValue =
            [4, 6, 8].indexOf(inputType) > -1
              ? item.fieldValue.join(",")
              : item.fieldValue;
          return {
            ...item,
            fieldValue,
            queryMethod: item.operator,
            fieldType: operatorMapper[inputType],
          };
        })
      )
    )}`;
  }
  return filterUrl;
}
