import React, {
	useCallback,
	useEffect,
	useState,
} from "react";
import {Form, Input, Button, Modal, message, Select} from "antd";
import "../../CSS/customer.css";
import {useHistory} from "react-router";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import i18n from "i18next";
import {instance as axios} from "../../../../../../util/axios";
import {usePermission} from "../../../../../../util/permession";
import {Result} from "../../../../../../schemas/common";

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 15 },
};
interface Params {
	skuId: string;
	property: string;
}
export default ({ skuId,property }: Params) => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [isChange,setIsChange] = useState(false);
	const [priceListA, setPriceListA] = useState([]);
	const [priceListB, setPriceListB] = useState([]);

	const callBack = useCallback(function confirm(flag: boolean) {
		if(flag){
			Modal.confirm({
				title: i18n.t("返回操作",),
				icon: <ExclamationCircleOutlined />,
				content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
				okText: i18n.t("okText"),
				cancelText: i18n.t("cancelText"),
				onOk: function () {
					history.push("/sku/skuManage/"+property);
				},
			});
		}else{
			history.push("/sku/skuManage/"+property);
		}

	}, []);
	useEffect(function () {
		//获取物料对应的价格表信息
		axios.get("/clothes/sku/getSkuPriceList?skuId="+skuId+'&formula=A').then((data)=>{
			if(data.data.Status == 1){
				setPriceListA(data.data.Data);
			}
		})
		axios.get("/clothes/sku/getSkuPriceList?skuId="+skuId+'&formula=B').then((data)=>{
			if(data.data.Status == 1){
				setPriceListB(data.data.Data);
			}
		})
	}, []);


	const onFinish = async (values: any) => {
		console.log("values:",values);
		message.info(i18n.t("保存成功"));
		setLoading(false);
		setIsChange(false);
		axios
			.post<Result<any[]>>(`/clothes/sku/getSkuById`, {
				uuid: skuId,
			})
			.then((resp:any) => {
				if (resp.data.Status === "1") {
					form.setFieldsValue(resp.data.Data);
					form.setFieldsValue({
						categoryId:resp.data.Data.categoryId.split(",")
					})
				}
			});
	};
	const onFinishFailed = (values: any) => {
		let arr: any = [];
		values.errorFields.map((item: any) => {
			arr.push(<li>{item.errors}</li>);
		});
		setLoading(false);
		window.scrollTo(0, 0);
	};
	const onValuesChange = (values: any) => {
		setIsChange(true);
	};

	return (
		<div className="u-box-con u-fixed-padding">
			<div className="u-tabsA">
				<Form.Provider>
					<Form
						{...layout}
						form={form}
						layout="horizontal"
						name="basicForm"
						autoComplete="off"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						scrollToFirstError={true}
						onValuesChange={onValuesChange}
					>
						<div className="u-form-lr">
							<div className="u-form-list">
								<Form.Item name="skuId" hidden>
									<Input/>
								</Form.Item>
							</div>
						</div>
						<div className="u-TabCon">
							<Form.Item
								name="dj1"
								label={i18n.t("参考销售价格")+" A"}>
								<Select allowClear>
									{priceListA && priceListA.map((item: any) => (
										<Select.Option value={item.name} key={item.name}><span
											style={{float: "left"}}>{item.name}</span><span style={{
											float: "right",
											paddingRight: "50px"
										}}>{item.price}</span></Select.Option>
									))}
								</Select>
							</Form.Item>

						</div>
						<div className="u-TabCon">
							<Form.Item
								name="dj1"
								label={i18n.t("参考销售价格")+" B"}>
								<Select allowClear>
									{priceListB && priceListB.map((item: any) => (
										<Select.Option value={item.name} key={item.name}><span
											style={{float: "left"}}>{item.name}</span><span style={{
											float: "right",
											paddingRight: "50px"
										}}>{item.price}</span></Select.Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<Form.Item
							className="u-form-custom"
							{...{wrapperCol: {span: 24}}}
						>
							<div className="u-button-box">
								<Form.Item>
									{usePermission("新增修改商品") &&<Button
										type="primary"
										className="u-form-buttonB"
										onClick={() => {
											form.submit();
											setLoading(true);
										}}
										loading={loading}
									>
										{i18n.t("保存")}
									</Button>}
									<Button
										htmlType="button"
										onClick={() => {
											callBack(isChange);
										}}
										className="u-form-buttonA"
									>
										{i18n.t("返回")}
									</Button>
								</Form.Item>
							</div>
						</Form.Item>
					</Form>
				</Form.Provider>
			</div>
		</div>
	);
};
