import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, PageHeader
} from "antd";
import * as echarts from 'echarts';
import {instance as axios} from "../../../../../util/axios";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import {isEmpty, isNullOrUndefined} from "../util/Common";
import {Option} from "antd/es/mentions";
import i18n from "i18next";


export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState({type:"2"});
    const [gridSearchData, setGridSearchData] = useState();

    useEffect(()=>{
        let element = document.getElementById('main1');
        let myChart = echarts.init(element as HTMLDivElement);


        var option2  = {
            xAxis: {
                type: 'category',
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    type: 'bar'
                }
            ]
        };
        myChart.showLoading()
        myChart.setOption(option2);
        let data: any =  Object.assign(searchData==undefined?{}:searchData,gridSearchData==undefined?{}:gridSearchData);

        axios
            .post("/clothes/statistic/getPFlPreAmount",searchData).then(async (resp:any)=>{
            myChart.hideLoading()
            if(resp.data.Status == 1){
                var data = resp.data.Data.data
                var tempData:any = [];
                var tempTitle:any = [];
                console.log(tempData)

                if(data){
                    await Promise.all(data.map(async (item:any)=>{
                        tempData.push(item.amount)
                        tempTitle.push(item.docDate)
                    }))
                    myChart.setOption({
                        xAxis: {
                            type: 'category',
                            data: tempTitle
                        },
                        series: [
                            {
                                data: tempData
                            }
                        ]
                    })
                }

            }
        })

    },[searchData,gridSearchData])

    return (
        <div>
            <PageHeader
                title={i18n.t("生产单辅料预计用量统计")}
            />
            <Form form={form}>
                <Row>
                    <Space>

                        <Form.Item name={"range"}>
                            <DateTimePickerWithFormat rangePicker={true} onChange={(ranges) => {
                                const temp: any = {};
                                temp.type = '2';//辅料
                                if (ranges) {
                                    temp.startDate = ranges[0].format("YYYY-MM-DD");
                                    temp.endDate = ranges[1].format("YYYY-MM-DD");
                                    setSearchData(temp)
                                } else {
                                    setSearchData(temp)
                                }
                            }}/>
                        </Form.Item>

                    </Space>
                </Row>
            </Form>


            <Row>
                <Col span={24}>
                    <div id={"main1"} style={{height: "400px"}}/>
                </Col>
            </Row>

            <DataGrid isShowSearch={true} url={"clothes/statistic/getPFlPreAmount"} searchData={searchData}
                      descriptions={[
                          {
                              fieldName: "docDate",
                              fieldShowName: i18n.t("时间",)
                          },
                          {
                              fieldName: "property",
                              fieldShowName: i18n.t("产品类型",),
                              inputType: 6,
                              dictionary: "dict_sku_property"
                          },

                          {
                              fieldName: "amount",
                              fieldShowName: i18n.t("预计用量",)
                          },

                      ]}/>

        </div>
    );
}