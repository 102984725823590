import React, { useEffect, useState } from "react";
import { DatePicker, Form, TimePicker } from "antd";
import { getDateTimeFormat } from "../../util/config";
import moment from "moment";

export interface Param {
  value?: any;
  onChange?: (value: any) => void;
  style?: any;
  format?: any;
  disabled?: boolean;
  showTime?: boolean;
  picker?: any;
  rangePicker?: boolean;
  ranges?: any;
}

const DateTimePickerWithFormat = ({
  value,
  onChange,
  style,
  format,
  disabled = false,
  showTime = false,
  picker,
  rangePicker = false,
                                    ranges
}: Param) => {
  const [dateFormat, setDateFormat] = useState<any>();
  const [timeFormat, setTimeFormat] = useState<any>();
  const { RangePicker } = DatePicker;
  useEffect(() => {
    var format:any = localStorage.getItem("format")
    setDateFormat(format.dateFormat);
    setTimeFormat(format.hourFormat);
  },[]);
  return (
    <>
      {rangePicker ? (
        <>
          {" "}
          <RangePicker
              value={value}

              format={
                format
                    ? format
                    : showTime
                    ? dateFormat + " " + timeFormat
                    : dateFormat
              }
            ranges={ranges?ranges:{
              今日: [moment(), moment()],
              本周: [moment().startOf("week"), moment().endOf("week")],
              本月: [moment().startOf("month"), moment().endOf("month")],
              本季度: [moment().startOf("quarter"), moment().endOf("quarter")],
              本年: [moment().startOf("year"), moment().endOf("year")],
              上周: [moment().subtract(1, 'week').startOf("week"), moment().subtract(1, 'week').endOf("week")],
              上个月: [moment().subtract(1, 'month').startOf("month"), moment().subtract(1, 'month').endOf("month")],
              上季度: [moment().subtract(1, 'quarter').startOf("quarter"), moment().subtract(1, 'quarter').endOf("quarter")],
            }}
            onChange={(v: any) => {
              if (onChange) {
                onChange(v);
              }
            }}
          />
        </>
      ) : (
        <DatePicker
          picker={picker ? picker : undefined}
          value={value}
          disabled={disabled}
          style={style ? style : undefined}
          format={
            format
              ? format
              : showTime
              ? dateFormat + " " + timeFormat
              : dateFormat
          }
          showTime={showTime}
          onChange={(value) => {
            if (onChange) {
              onChange(value);
            }
          }}
        />
      )}
    </>
  );
};

export default DateTimePickerWithFormat;
