import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";

import { DictionarySelect } from "../../../../../components/DictionarySelect";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import "../../../i18n";
import i18n from "i18next";
import { updateLanguage } from "../../../../../util/changeLanguage";
import { deleteRedisKey } from "../../../../../util/redis";
const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 13 },
};

export default () => {
  const initialValues = {
    uuid: "0",
    language: "",
    createTime: "",
    creatorId: "",
  };
  const [form] = Form.useForm();
  const [isChangeLanguage, setIsChangeLanguage] = useState(false);
  useEffect(function () {
    axios
      .post<Result<any[]>>(`/clothes/personal/config/getPersonalConfig`)
      .then((resp) => {
        if (resp.data.Status === "1") {
          if (resp.data.Data != undefined) {
            form.setFieldsValue(resp.data.Data);
          } else {
            form.setFieldsValue({
              language: "zh_cn",
            });
          }
        }else {
            form.setFieldsValue({
                language: "zh_cn",
            });
        }
      });
  }, []);
  const onFinish = (values: any) => {
    axios
      .post<Result<any[]>>(`/clothes/personal/config/savePersonalConfig`, {
        uuid: values.uuid,
        language: values.language,
        createTime: values.createTime,
        creatorId: values.creatorId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.success(i18n.t("saveSuccess"));
          if (isChangeLanguage) {
            deleteRedisKey("ClientLanguage").then(() => {
              updateLanguage(true);
            });
          }
        }
      });
  };

  return (
    <div className="u-form u-form1 u-config">
      <Form
        className="u-form-box"
        {...layout}
        layout="horizontal"
        form={form}
        name="editForm"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item name="uuid" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="createTime" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="creatorId" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="language" label={i18n.t("personal_config_language")}>
          <DictionarySelect
            entityName={"dict_language_type"}
            multiple={false}
            onChange={() => {
              setIsChangeLanguage(true);
            }}
          />
        </Form.Item>
        <Form.Item className="u-form-custom" {...{ wrapperCol: { span: 24 } }}>
          <div className="u-button-box">
            <Form.Item>
              <Button
                type="primary"
                className="u-form-buttonB"
                htmlType="submit"
              >
                {i18n.t("保存")}
              </Button>
              <Button htmlType="button" className="u-form-buttonA">
                {i18n.t("company_config_reset")}
              </Button>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
