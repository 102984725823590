import React, {useCallback, useRef, useState} from "react"
import {
    Typography,
    Row,
    Col,
    Form,
    Input,
    Button,
    Table,
    message, Modal, Steps, Tabs
} from 'antd'
import {Link} from 'react-router-dom'
import {DataGrid} from "../../../../../components/DataGrid";
import {useUpdateEffect, useWindowSize} from "react-use";
import './clothes.css'
import './antBasicReset.css'
import {inputType} from "../../../../../constants/inputType";
import {instance as axios} from "../../../../../util/axios";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import {isNullOrUndefined} from "../util/Common";
import {BaseURL} from "../../../../../constants/url";
import ClothesCut from "./ClothesCut";
import ProduceList from "./ProduceList";
import TechnicalSpecifications from "./TechnicalSpecifications";
import {formatNumber} from "../util/FormatMoney";
import WashingSign from "./WashingSign";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import CustomersNeedToKnow from "./CustomersNeedToKnow";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {Result} from "../../../../../schemas/common";
import {editOtherLine, saveDataGrid} from "../components/EditForm";
import GetRender from "../Sku/Detail/GetRender";
import {usePermission} from "../../../../../util/permession";
import i18n from "i18next";
import {DictionarySelects} from "../../../../../components/DictionarySelectFilter";
import ColorDiv from "../../../../../components/ColorDiv";
import AddSize from "../Order/Detail/AddSize";

const {Title} = Typography
const {Step} = Steps
const {TabPane} = Tabs
const {Text} = Typography

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14},
}

export default () => {
    const hejiDataGridRef = useRef<any>();
    const [form] = Form.useForm()
    const [photoUrl, setPhotoUrl] = useState("")
    const [rowSelectionData, setRowSelectionData] = useState([])
    // const [rowId, setRowId] = useState<any>()
    const [row, setRow] = useState<any>()
    const [rowDocId, setRowDocId] = useState('')
    const [rowDetailData, setRowDetailData] = useState<any>(null);
    const [searchParam, setSearchParam] = useState<any>([]);
    const [sizeColumn, setSizeColumn] = useState<any>([]);
    const [currentProcess, setCurrentProcess] = useState<any>();
    const [currentStep, setCurrentStep] = useState<any>("");
    const [showMaterials, setShowMaterials] = useState<any>(false);
    const [rowId, setRowId] = useState<any>("");
    const thisGridRef = useRef<any>();
    const processRef = useRef<any>();
    const {height} = useWindowSize();
    const materialColumns: any = [

        {
            fieldShowName: i18n.t('物料类型',),
            fieldName: "property",
            inputType: inputType.DICTIONARY,
            dictionary: "dict_material_type",
            width: "100"
        },
        {
            fieldShowName: i18n.t('名称',),
            fieldName: "skuName",
            // inputType: inputType.DICTIONARY,
            // dictionary: "clothes_sku",
            width: "100"
        },
        {
            fieldShowName: i18n.t('描述',),
            fieldName: "skuCode",
            // inputType:inputType.DICTIONARY,
            // dictionary:"clothes_sku.code",
            width: "100"
        },
        {
            fieldShowName: i18n.t('颜色',),
            fieldName: "color",
            width: "100",
            render:(text:any,row:any)=>{
                return (
                    <div style={{width:"100%",background:text}}>
                        {row.colorName}
                    </div>)
            }
        },
        {
            fieldShowName: i18n.t('单位',),
            fieldName: "unitId",
            inputType: inputType.DICTIONARY,
            dictionary: "dict_sku_unit",
        },
        {
            fieldShowName: i18n.t('需求量',),
            fieldName: 'needQuantity',
        },
        {
            fieldShowName: i18n.t('供应商',),
            fieldName: 'supplier',
            showTitle:true

        },
    ];

    const getFontColor = (a: any) => {
        if (!isNullOrUndefined(a)) {
            if (a.substr(0, 1) == "#") a = a.substring(1);
            a = a.toLowerCase();
            var b = new Array();
            for (var x = 0; x < 3; x++) {
                b[0] = a.length == 6 ? a.substr(x * 2, 2) : a.substr(x * 1, 1) + a.substr(x * 1, 1);
                b[3] = "0123456789abcdef";
                b[1] = b[0].substr(0, 1);
                b[2] = b[0].substr(1, 1);
                b[20 + x] = b[3].indexOf(b[1]) * 16 + b[3].indexOf(b[2])
            }
            var color = 0.213 * b[20] + 0.715 * b[21] + 0.072 * b[22] > 255 / 2;
            return color ? '#000000' : '#ffffff'
        } else {
            return "#000000";
        }

    }


    function getRender() {
        return function (_: any, record: any) {
            return (
                <>
                    <>
                        <a
                            style={{marginRight: 16}}
                            onClick={async (e) => {
                                if(hejiDataGridRef.current.getEditingKey() == record.uuid || hejiDataGridRef.current.getEditingKey() == record.key){
                                    await saveDataGrid(hejiDataGridRef);
                                    let heji = await hejiDataGridRef.current.getData();
                                    heji.map((item: any) => {
                                        for (var index in item) {
                                            if (index.indexOf("size") > -1) {
                                                if (item[index] == undefined || item[index] == "") {
                                                    item[index] = 0;
                                                } else {
                                                    item[index] = formatNumber(item[index]);
                                                }
                                            }
                                        }
                                    })
                                    axios
                                        .post("/clothes/produceOrder/updateProduceOrderSizeList", {
                                            data: JSON.stringify(heji),
                                        })
                                        .then((resp) => {
                                            if (resp.data.Status === "1") {
                                                message.success(i18n.t("保存成功"));
                                                hejiDataGridRef.current.refresh();
                                            }
                                        });
                                }else{
                                    await editOtherLine(hejiDataGridRef, record);
                                }
                            }}
                        >
                            {i18n.t("编辑/保存")}
                        </a>
                    </>

                </>
            );
        };
    }


    const useAppendColumn = (sizeColumn: any) => {
        if (sizeColumn) {
            sizeColumn.map((item: any) => {
                item["editable"] = true;
                for (var index in item) {
                    if (index == "fieldShowName") {
                        item.title = item[index];
                    }
                    if (index == "fieldName") {
                        item.dataIndex = item[index];
                    }
                }
            });
        }

        const options = [{
            fieldShowName: i18n.t("options"),
            fieldName: '',
            render: getRender(),
            width: 130
        }]

        const appendColumns = [
            {
                fieldShowName: i18n.t('颜色',),
                fieldName: 'colorName',
                render: (text: any, record: any) => {
                    return (
                        <ColorDiv colors={record.color.split(",")} colorName={record.colorName} nameColorId={record.nameColorId}/>
                    )
                },
                width:200,
            },
            {
                fieldShowName: i18n.t('计划生产量',),
                fieldName: 'plannedProduction',
                // editable:true
            },
        ];
        // sizeColumn.concat(options);
        if(usePermission("新增修改生产订单")){
            return appendColumns.concat(sizeColumn.concat(options));

        }else{
            return appendColumns.concat(sizeColumn);

        }
    }

    useUpdateEffect(()=>{
        console.log(photoUrl)
    },[photoUrl])

    return (
        <div className="u-container">
            <Title level={4}>{i18n.t("生产订单")}</Title>
            <div className="u-divider-horizontal"></div>
            <div>
                <Form
                    form={form}
                    {...formItemLayout}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="docDate1"
                                label={i18n.t("时间查询")}
                            >
                                <DateTimePickerWithFormat
                                    rangePicker={true}
                                    onChange={(v: any) => {
                                        let tempSearchParam = {...searchParam};
                                        setSearchParam(null);
                                        if (!v) {
                                            delete tempSearchParam.searchField_dateTime_createTime;
                                        } else {
                                            tempSearchParam.searchField_dateTime_createTime = v[0].format("YYYY-MM-DD") + "|" + v[1].format("YYYY-MM-DD");
                                        }
                                        setSearchParam(tempSearchParam);
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="customerId"
                                label={i18n.t("客户")}
                            >
                                <DictionarySelect entityName={"clothes_customer"} onChange={(v) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_dictionary_customerId = v;
                                    setSearchParam(tempSearchParam);
                                }} allowClear={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="produce"
                                label={i18n.t("工艺查询")}
                            >
                                <DictionarySelect entityName={"dict_working_procedure"} onChange={(v: any) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    // ["1","2"]
                                    var value = '';
                                    if(v && v.length > 0){
                                        v.map((tempV:string)=>{
                                            if(value.length > 0){
                                                value += ",";
                                            }
                                            value += tempV;
                                        })
                                        tempSearchParam.searchField_dictionary_scheduleId = value;
                                        console.log(v)
                                        console.log(tempSearchParam)
                                    }else{
                                        delete tempSearchParam.searchField_dictionary_scheduleId;
                                    }
                                    setSearchParam(tempSearchParam);
                                }} allowClear={true} multiple={true}/>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>


                        <Col span={8}>
                            <Form.Item
                                name="serialNumber"
                                label={i18n.t("订单号")}
                            >
                                <Input onChange={(v) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_string_saleOrder = "1|" + v.target.value;
                                    setSearchParam(tempSearchParam);
                                }}
                                       allowClear={true}

                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="kuanhao"
                                label={i18n.t("商品编号")}
                            >
                                <DictionarySelect entityName={"clothes_sku"} onChange={(v: any) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_dictionary_skuId = v;
                                    setSearchParam(tempSearchParam);
                                }} allowClear={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={10}>

                            {currentProcess && <Form.Item noStyle
                                                          name={"currentStep"}
                            >
                                <Steps size="small" current={currentProcess.currentStep} onChange={(v) => {
                                    var tempProcess = JSON.parse(JSON.stringify(currentProcess));
                                    tempProcess.currentStep = v;
                                    setCurrentProcess(tempProcess);
                                    var currentProcessId = "";
                                    var i = 0;
                                    tempProcess.processList.map((item: any) => {
                                        if (i == v) {
                                            currentProcessId = item.processId;
                                        }
                                        i++;
                                    })
                                    //更新数据库
                                    //设置工序步骤
                                    axios.post("/clothes/produceOrder/updateProduceOrder", {
                                        uuid: row.uuid,
                                        schedule: currentProcessId
                                    }).then((resp) => {
                                        if (resp.data.Status == 1) {
                                            message.success(i18n.t("修改进度成功"));
                                            thisGridRef.current.refresh();
                                            setCurrentStep(currentProcessId);
                                            if (processRef.current) {
                                                processRef.current.refresh()
                                            }
                                        }
                                    })
                                }}>

                                    {currentProcess.processList.map((item: any) => {
                                        return (
                                            <Step title={item.name}/>
                                        )
                                    })}
                                </Steps>

                            </Form.Item>}

                        </Col>
                    </Row>
                    <Row gutter={24}>


                        <Col span={8}>
                            <Form.Item
                                name="docNumber"
                                label={i18n.t("制单号")}
                            >
                                <Input onChange={(v) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_string_serialNumber = "1|" + v.target.value;
                                    setSearchParam(tempSearchParam);
                                }}
                                       allowClear={true}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="factoryId"
                                label={i18n.t("加工厂")}
                            >
                                <DictionarySelects entityName={"clothes_customer"} filter={" customerType='5' "} onChange={(value)=>{
                                    axios.post("/clothes/customerManage/getCustomerInformationById?customerId="+value).then((res)=>{
                                        if(res.data.Status == 1){
                                            form.setFieldsValue({factoryCode:res.data.Data.customerNumber})
                                            let tempSearchParam = {...searchParam};
                                            setSearchParam(null);
                                            tempSearchParam.searchField_dictionary_factory = value;
                                            setSearchParam(tempSearchParam);
                                        }
                                    })
                                }}/>

                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                name="factoryCode"
                                label={i18n.t("加工厂编号")}
                            >
                                <Input onChange={(v) => {
                                    axios.post("/clothes/customerManage/getCustomerIdByCode?customerCode="+v.target.value+"&customerType=5").then((res)=>{
                                        if(res.data.Status == 1){
                                            form.setFieldsValue({factoryId:res.data.Data})
                                            let tempSearchParam = {...searchParam};
                                            setSearchParam(null);
                                            tempSearchParam.searchField_dictionary_factory = res.data.Data;
                                            setSearchParam(tempSearchParam);
                                        }
                                    })

                                }}
                                       allowClear={true}

                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>

                        <Col span={8}>
                            <Form.Item
                                name="craftName"
                                label={i18n.t("查找进度")}
                            >
                                <Input onChange={(v) => {
                                    let tempSearchParam = {...searchParam};
                                    setSearchParam(null);
                                    tempSearchParam.searchField_string_craftName = "1|" + v.target.value;
                                    setSearchParam(tempSearchParam);
                                }}
                                       allowClear={true}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>


            <DataGrid
                ref={thisGridRef}
                pagination={true}
                sorter={{field: "createTime", order: "descend"}}
                pageSize={5}
                rowSelectionType="checkbox"
                descriptions={[

                    {
                        fieldShowName: i18n.t('生产制单'),
                        fieldName: "serialNumber",
                        width: 100
                    },
                    {
                        fieldShowName: i18n.t('销售订单',),
                        fieldName: 'saleOrderId',

                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_order.serialNumber",
                        width: 150
                    },
                    {
                        fieldShowName: i18n.t('商品编号'),
                        fieldName: "skuId",
                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_sku",
                        width: 100
                    },
                    {
                        fieldShowName: i18n.t('商品简介'),
                        fieldName: "skuCode",
                        width: 100
                    },
                    {
                        fieldShowName: i18n.t('客户',),
                        fieldName: "customerId",
                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_customer",
                        width: 100
                    },
                    {
                        fieldShowName: i18n.t('品牌',),
                        fieldName: 'brandId',
                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_customer_brand",
                        width: 100
                    },
                    {
                        fieldShowName: i18n.t('件数',),
                        fieldName: 'packageNumber',
                        keepRight:true,
                    },
                    {
                        fieldShowName: i18n.t('进度',),
                        fieldName: 'craftName',
                        // inputType: inputType.DICTIONARY,
                        // dictionary: "clothes_sku_working_procedure",
                        render:(text: any, record: any) => {
                            if(!text){
                                return (
                                    <></>
                                )
                            }
                            if(text.indexOf("发货") >= 0){
                                return (
                                    <div style={{color: 'green'}}>
                                        {record.craftName}
                                    </div>
                                )
                            }else if(text.indexOf("裁剪") >= 0){
                                return (
                                    <div style={{color: 'deepskyblue'}}>
                                        {record.craftName}
                                    </div>
                                )
                            }else if(text.indexOf("印染") >= 0 || text.indexOf("染洗") >= 0){
                                return (
                                    <div style={{color: 'orange'}}>
                                        {record.craftName}
                                    </div>
                                )
                            }else if(text.indexOf("缝纫") >= 0){
                                return (
                                    <div style={{color: '#CCC000'}}>
                                        {record.craftName}
                                    </div>
                                )
                            } else if(text.indexOf("熨烫") >= 0){
                                return (
                                    <div style={{color: 'red'}}>
                                        {record.craftName}
                                    </div>
                                )
                            }else{
                                return (
                                    <div style={{color: 'black'}}>
                                        {record.craftName}
                                    </div>
                                )
                            }

                            },
                        },
                    {
                        fieldShowName: i18n.t('工厂名称',),
                        fieldName: 'factory',
                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_customer",
                    },

                    {
                        fieldShowName: i18n.t('预交货期',),
                        fieldName: 'salePreDeliveryDate',
                        inputType: inputType.DATE,
                    },
                    {
                        fieldShowName: i18n.t('客户要求',),
                        fieldName: 'processRequirements',
                    },
                    {
                        fieldShowName: i18n.t('生产备注',),
                        fieldName: 'premark',
                    },
                    //
                    // {
                    //     fieldShowName: '品牌',
                    //     fieldName: "docDate",
                    //     inputType: inputType.DATE,
                    //     width: "100"
                    // },
                    // {
                    //     fieldShowName: '公司名称',
                    //     fieldName: "customerId",
                    //     inputType: inputType.DICTIONARY,
                    //     dictionary: "clothes_customer",
                    //     width: "100"
                    // },
                    //
                    // {
                    //     fieldShowName: '总数量',
                    //     fieldName: 'total',
                    // },
                    // {
                    //     fieldShowName: '总金额',
                    //     fieldName: 'amount',
                    //     width: "100"
                    // },
                    // {
                    //     fieldShowName: '状态',
                    //     fieldName: 'status',
                    //
                    //     inputType: inputType.DICTIONARY,
                    //     dictionary: "dict_order_status",
                    //     width: "100"
                    // },
                    // {
                    //     fieldShowName: '销售预交货期',
                    //     fieldName: 'preDeliveryDate',
                    //     width: "100"
                    // },
                    // {
                    //     fieldShowName: '业务负责人',
                    //     fieldName: 'principalId',
                    //     inputType: inputType.DICTIONARY,
                    //     dictionary: "base_staff",
                    //     width: "100"
                    // }
                ]}
                url={`/clothes/produceOrder/getList`}
                searchData={searchParam}
                onClick={(record: any) => {
                    // setRowDocId(record.uuid);
                    axios.get("/clothes/size/getSizeColumnById?sizeId=" + record.sizeId)
                        .then(function (resp) {
                            var res = resp.data;
                            if (res.Status == 1) {
                                setSizeColumn(res.Data);
                                // setRowId(record.uuid);
                                setRow(record)
                                setPhotoUrl(BaseURL + "/oa/common/attachment/showImageAttachment?id=" + record.imageId);
                                //设置工序步骤
                                axios.get("/clothes/produceOrder/getCurrentStep?docId=" + record.uuid).then((processData) => {
                                    if (processData.data.Status == 1) {
                                        setCurrentProcess(processData.data.Data);
                                        setCurrentStep(processData.data.Data.currentStepId);
                                    }
                                })
                            }
                        })
                }}
                isShowSearch={false}
                clickToSelected={true}
                isShowExport={true}
            />

            <div className="u-btn-toolbar" style={{padding: '0'}}>
                {usePermission("新增修改生产订单") && <Link to={"/clothes/produceOrderDetail"}> <Button htmlType="button">
                    {i18n.t("新建")}
                </Button></Link>}

                {usePermission("新增修改生产订单") &&<Button type="primary" htmlType="button" onClick={()=>{
                    var produceIds = "";
                    if (processRef.current) {
                        produceIds = processRef.current.getProduceIds()
                    }
                    console.log("produceIds",produceIds)
                    var ids = thisGridRef.current.getSelectedIds();
                    console.log(ids)
                    if (ids.length <= 0) {
                        message.error(i18n.t("必须选择一条数据"));
                        return false;
                    }
                    if (ids.length > 1) {
                        message.error(i18n.t("只能选择一条数据"));
                        return false;
                    }
                    var checkedIds = "";
                    ids.map((item: string) => {
                        if (checkedIds != "") {
                            checkedIds += "," + item;
                        } else {
                            checkedIds += item;
                        }
                    })

                    window.open(BaseURL+"/clothes/produceOrder/getCaiJianPrintPdf?orderId=" + checkedIds+"&produceIds="+produceIds);
                    // window.open(BaseURL+"/clothes/produceOrder/getCaiJianPrintPdf?orderId=" + checkedIds);
                }}>
                    {i18n.t("打印加工单")}
                </Button>}
                {usePermission("新增修改生产订单") && <Button type="primary" htmlType="button" onClick={()=>{
                    var produceIds = "";
                    if (processRef.current) {
                        produceIds = processRef.current.getProduceIds()
                    }
                    console.log("produceIds",produceIds)
                    var ids = thisGridRef.current.getSelectedIds();
                    console.log(ids)
                    if (ids.length <= 0) {
                        message.error(i18n.t("必须选择一条数据"));
                        return false;
                    }
                    if (ids.length > 1) {
                        message.error(i18n.t("只能选择一条数据"));
                        return false;
                    }
                    var checkedIds = "";
                    ids.map((item: string) => {
                        if (checkedIds != "") {
                            checkedIds += "," + item;
                        } else {
                            checkedIds += item;
                        }
                    })
                    window.open(BaseURL+"/clothes/produceOrder/getLihuoPrintPdf?orderId=" + checkedIds+"&produceIds="+produceIds);
                }}>
                    {i18n.t("打印理货单")}
                </Button>}
                {usePermission("新增修改生产订单") && <Button type="primary" htmlType="button" onClick={()=>{
                    var produceIds = "";
                    if (processRef.current) {
                        produceIds = processRef.current.getProduceIds()
                    }
                    console.log("produceIds",produceIds)
                    var ids = thisGridRef.current.getSelectedIds();
                    console.log(ids)
                    if (ids.length <= 0) {
                        message.error(i18n.t("必须选择一条数据"));
                        return false;
                    }
                    if (ids.length > 1) {
                        message.error(i18n.t("只能选择一条数据"));
                        return false;
                    }
                    var checkedIds = "";
                    ids.map((item: string) => {
                        if (checkedIds != "") {
                            checkedIds += "," + item;
                        } else {
                            checkedIds += item;
                        }
                    })
                    //紧急停止接口
                    axios.post("/clothes/produceOrder/stopProduce?produceId=" + checkedIds).then((resp)=>{
                        if(resp.data.Status == 1){
                            message.success("紧急停止成功");
                            thisGridRef.current.refresh();
                        }
                    });

                }}>
                    {i18n.t("紧急停止")}
                </Button>}
                <Button htmlType="button" onClick={()=>{
                    var produceIds = "";
                    if (processRef.current) {
                        produceIds = processRef.current.getProduceIds()
                    }
                    console.log("produceIds",produceIds)
                    var ids = thisGridRef.current.getSelectedIds();
                    console.log(ids)
                    if (ids.length <= 0) {
                        message.error(i18n.t("必须选择一条数据"));
                        return false;
                    }
                    if (ids.length > 1) {
                        message.error(i18n.t("只能选择一条数据"));
                        return false;
                    }
                    var checkedIds = "";
                    ids.map((item: string) => {
                        if (checkedIds != "") {
                            checkedIds += "," + item;
                        } else {
                            checkedIds += item;
                        }
                    })
                    setRowId(checkedIds)

                    setShowMaterials(true);
                }}>
                    {i18n.t("预计用料查询")}
                </Button>
            </div>


            {row &&
            <div>
                <Tabs defaultActiveKey="1" className="u-tabs">
                    {
                        row &&
                        (
                            <>
                                <TabPane tab={i18n.t("布料裁剪")} key="1">
                                    {/*<TabBZMX/>*/}
                                    <ClothesCut docId={row.uuid} photoUrl={photoUrl} sizeColumn={sizeColumn}/>
                                </TabPane>
                                <TabPane tab={i18n.t("工艺清单")} key="2">
                                    {/*<TabCSFS/>*/}
                                    <ProduceList docId={row.uuid} photoUrl={photoUrl} currentStep={currentStep}
                                                 ref={processRef}/>
                                </TabPane>

                                <TabPane tab={i18n.t("技术规格")} key="3">
                                    {/*<TabFWFY formData={form} countAmount={countAmount}/>*/}
                                    <TechnicalSpecifications docId={row.uuid} photoUrl={photoUrl} skuId={row.skuId}
                                                             row={row}/>
                                </TabPane>
                                <TabPane tab={i18n.t("洗涤标志")} key="4">
                                    {/*<TabFWFY formData={form} countAmount={countAmount}/>*/}
                                    <WashingSign photoUrl={photoUrl} skuId={row.ref.skuId}/>
                                </TabPane>
                                <TabPane tab={i18n.t("客户需知")} key="5">
                                    <CustomersNeedToKnow photoUrl={photoUrl} docData={row}/>
                                </TabPane>
                            </>
                        )
                    }

                </Tabs>

                <div id={"sizeDiv"}>
                    {
                        row &&
                        <DataGrid descriptions={useAppendColumn(sizeColumn)}
                                  ref={hejiDataGridRef}
                                  url={"/clothes/produceOrder/getProduceOrderSizeList?docId=" + row.uuid}
                                  pagination={false}
                                  tableSummary={(pageData) => {
                                      //定义一个数组,存储合计数量
                                      var count: any = [];
                                      var sizeItem: any = {};
                                      pageData.forEach((item: any) => {
                                          var exp = /^size+[0-9]$/;
                                          sizeColumn.forEach((item1:any)=> {
                                              //以size* 开头的,为列信息
                                              var sizeName = item1.fieldName;
                                              var count = item[sizeName];
                                              // console.log("sizeName:"+sizeName+" count:"+count)
                                              sizeItem[sizeName] = formatNumber(sizeItem[sizeName]);
                                              sizeItem[sizeName] += formatNumber(item[sizeName]);
                                              // console.log(sizeItem)
                                          })
                                      })
                                      for (var item in sizeItem) {
                                          count.push(sizeItem[item]);
                                      }
                                      return (
                                          <>
                                              <Table.Summary.Row>
                                                  <Table.Summary.Cell colSpan={2} index={1}>
                                                      <span style={{float: 'right'}}>{i18n.t("合计")}：</span>
                                                  </Table.Summary.Cell>
                                                  {count.map((item: any, index: any) => {
                                                      return (
                                                          <Table.Summary.Cell index={index}>
                                                              <Text type="danger" style={{paddingLeft:8}}>{Number(item)}</Text>
                                                          </Table.Summary.Cell>
                                                      )
                                                  })}
                                              </Table.Summary.Row>
                                          </>
                                      );
                                  }}
                                  isShowSearch={false}
                                  onClick={async (record: any) => {

                                  }}
                        />
                    }
                </div>
            </div>
            }
            <Modal
                getContainer={false}
                style={{top: '20px'}}
                width={'800px'}
                visible={showMaterials}
                title={i18n.t("预计用料查询")}
                okText={i18n.t("确定")}
                cancelText={i18n.t("取消")}
                onCancel={()=>{
                    setShowMaterials(false);
                }}
                onOk={async () => {
                    setShowMaterials(false);
                }}
                bodyStyle={{height: 300,overflow:"scroll"}}
                maskClosable={false}
                destroyOnClose={true}
            >
                <DataGrid descriptions={materialColumns} url={"/clothes/produceOrder/getProduceRawOrderMaterialList?docId="+rowId}
                          isShowSearch={false} pagination={false} loadAllDictionary={true}
                />
            </Modal>
        </div>
    );
}