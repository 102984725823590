import React from "react";
import { message } from "antd";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";

export interface FocusProps {
  onFocus: () => void;
  isFocus: boolean;
  uuid: string;
}
export const Focus = (props: FocusProps) => {
  return (
    <>
      {props.isFocus ? (
        <StarFilled
          style={{ color: "#ffec3d", verticalAlign: "middle" }}
          onClick={() => {
            axios
              .post("/crm/customer/focusOrCancel", {
                customerId: props.uuid,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.success("操作成功");
                  if (props.onFocus) {
                    props.onFocus();
                  }
                }
              });
          }}
        />
      ) : (
        <StarOutlined
          style={{ verticalAlign: "middle" }}
          onClick={() => {
            axios
              .post("/crm/customer/focusOrCancel", {
                customerId: props.uuid,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.success("操作成功");
                  if (props.onFocus) {
                    props.onFocus();
                  }
                }
              });
          }}
        />
      )}
    </>
  );
};
