import React from "react"
import {Form, Select, Input, InputNumber} from 'antd'
import i18n from "i18next";

export default ()=> {

	return (
		<div className="u-tab-con u-tab-fkfs">
			<Form.Item label={i18n.t("付款方式")} name="payWay">
				<Input/>
			</Form.Item>
			<Form.Item label={i18n.t("付款周期")} name="stagePayCycle">
				<Input/>
			</Form.Item>
			<Form.Item label={i18n.t("付款金额")} name="payTotal">
				{/* eslint-disable-next-line react/jsx-no-undef */}
				<InputNumber/>
			</Form.Item>
		</div>
	);
	
}