import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  CSSProperties,
  useCallback,
} from "react";
import { message } from "antd";
import { instance as axios } from "../../util/axios";
import Form from "../DynamicForm/Instance";
import { CellData } from "../DynamicForm/Designer/schemas/CellData";
import moment from "moment";
import { AddressCell } from "../DynamicForm/AddressCell";
import AddressCellConfig from "../DynamicForm/AddressCellConfig";
import { convertBDZD2CellData, isDuplicate } from "./util";

interface TableGridProps {
  id?: string;
  entityName: string;
  columnCount?: number;
  mvp?: boolean;
  hiddenFields?: string[];
  defaultValues?: { [key: string]: any };
  onSaved?: (uuid: string) => void;
  style?: CSSProperties;
  onCell?: (cell: CellData) => void;
  onChange?: (root: any, setValue: (field: string, value: any) => void) => void;
}
export const TableGrid = forwardRef(
  (
    {
      id,
      entityName,
      columnCount = 2,
      mvp = true,
      hiddenFields,
      defaultValues,
      onSaved,
      style,
      onCell,
      onChange,
    }: TableGridProps,
    ref: any
  ) => {
    const [data, setData] = useState<CellData>();
    const [originalData, setOriginalData] = useState<any[]>([]);
    const formRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      save: function () {
        return save();
      },
      refresh: function () {
        loadData();
      },
    }));
    const loadData = useCallback(
      function () {
        axios
          .post("/crm/common/tableGrid/tableGrid", {
            type: entityName,
            id: id || "0",
            defaultValuesJSONString: JSON.stringify(defaultValues),
          })
          .then((result: any) => {
            let root: CellData = {
              type: "grid",
              id: "1",
              active: false,
              lanes: [{ span: 100, cellDataList: [] }],
            };
            let currentCell: CellData | null = null;
            let copy = JSON.parse(JSON.stringify(result.data.表单字段s));
            setOriginalData(copy);
            // noinspection JSNonASCIINames
            let resultCopy = copy
              .filter((item: any) => !item.Identify && item.TypeName)
              .filter(
                (item: any) =>
                  !hiddenFields ||
                  hiddenFields.every((ele) => ele !== item.Name)
              );
            resultCopy.forEach((item: any) => {
              for (const key in defaultValues) {
                if (
                  defaultValues.hasOwnProperty(key) &&
                  !item.Value &&
                  item.Name === key
                ) {
                  item.Value = defaultValues[key];
                }
              }
            });
            if (!mvp) {
              resultCopy = resultCopy.filter(
                (item: any) => item.TypeName && item.Required
              );
            }
            // noinspection JSNonASCIINames
            resultCopy.forEach((item: any, i: number) => {
              switch (item.DataType) {
                case "string":
                case "int32":
                  item.DataType = "input";
                  break;
                case "multiselect":
                case "freeMultiselect":
                  item.DataType = "select";
                  item.multiple = true;
                  item.Value = item.Value ? item.Value.split(",") : [];
                  break;
                case "combobox":
                  item.DataType = "select";
                  item.Value = item.Value !== "0" ? item.Value : "";
                  break;
                case "datetime":
                  item.Value = item.Value
                    ? moment(item.Value).format("YYYY-MM-DD")
                    : null;
                  break;
                default:
              }
              if (i % columnCount === 0) {
                currentCell = {
                  type: "grid",
                  id: i.toString(),
                  active: false,
                  lanes: [],
                };
                const colspan =
                  columnCount - item.Colspan + 1 > 0
                    ? columnCount - item.Colspan + 1
                    : 1;
                for (let j = 0; j < colspan; j++) {
                  currentCell.lanes?.push({
                    span: 24 / columnCount,
                    cellDataList: [],
                  });
                }
                root.lanes![0].cellDataList.push(currentCell);
              }
              if (currentCell!.lanes! && currentCell!.lanes![i % columnCount]) {
                const cellData = convertBDZD2CellData(item);
                onCell && onCell(cellData);
                currentCell!.lanes![i % columnCount].cellDataList!.push(
                  cellData
                );
              }
            });
            root.id = (+new Date()).toString();
            setData(root);
          });
      },
      [columnCount, defaultValues, entityName, hiddenFields, id, mvp, onCell]
    );
    useEffect(() => {
      loadData();
    }, [loadData, mvp]);
    async function save() {
      if (!formRef.current.validate().hasError) {
        let duplicate: boolean = false;
        let uniqueFields: any[];
        uniqueFields = originalData.filter((item: any) => item.UniqueField);
        const currentData = formRef.current.getData();
        for (let i = 0; i < uniqueFields.length; i++) {
          const field = uniqueFields[i];
          const value = currentData[field.Name];
          const formattedId = id || null;
          if (await isDuplicate(entityName, field.Name, value, formattedId)) {
            message.error(`${field.DisplayName}重复`);
            duplicate = true;
          }
        }
        if (duplicate) {
          return;
        }
        const gridData: { [key: string]: any } = Object.assign(
          defaultValues ? defaultValues : {},
          currentData
        );
        originalData.forEach((item) => {
          for (const key in gridData) {
            if (gridData[key] instanceof Array) {
              gridData[key] = gridData[key].join(",");
            }
            if (item.Name === key) {
              item.Value = gridData[key];
            }
          }
        });
        for (const key in gridData) {
          if (!originalData.some((item) => item.Name === key)) {
            originalData.push({ Name: key, Value: gridData[key] });
          }
        }

        axios
          .post("/crm/common/tableGrid/saveDataAndReturnId", {
            type: entityName,
            jsonData: JSON.stringify(originalData),
          })
          .then((resp: any) => {
            if (resp.data.Status === "1") {
              message.success("保存成功");
              loadData();
              onSaved?.(resp.data.Data);
            } else {
              message.error("保存失败");
            }
          });
      }
    }
    return (
      <>
        {data && (
          <Form
            customCells={[
              {
                type: "address",
                cell: AddressCell,
                icon: <></>,
                name: "地址",
                config: AddressCellConfig,
              },
            ]}
            onChange={(data, setValue) => onChange?.(data, setValue)}
            key={data.id}
            ref={formRef}
            data={data}
          />
        )}
      </>
    );
  }
);
