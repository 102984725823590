import React, {ReactNode, useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader, message, Modal, InputNumber, Table, Typography, Steps, Tabs, Tag
} from "antd";
import {DataGrid} from "../../../../../components/DataGrid";
import {DictionarySelect} from "../../../../../components/DictionarySelect";
import {DictionarySelects} from "../../../../../components/DictionarySelectFilter";
import i18n from "i18next";
import {inputType} from "../../../../../constants/inputType";
import {instance as axios} from "../../../../../util/axios";
import DateTimePickerWithFormat from "../../../../../components/DateTimePicker";
import "../CSS/newClass.css"
import {Result} from "../../../../../schemas/common";
import {useWindowSize} from "react-use";
import {isNullOrUndefined} from "../util/Common";
import {Option} from "antd/es/mentions";
import Tip from "../../../../../components/Tip";


export default ()=>{
    const [form] = Form.useForm();
    const [detailForm] = Form.useForm();
    const [searchData, setSearchData] = useState();
    const [visiable, setVisiable] = useState(false);
    const thisGridRef = useRef<any>();
    const hideModal = () => {
        setVisiable(false);
    };
    const { width, height } = useWindowSize();
    const [checkId, setCheckId] = useState("");
    const [checkNum, setCheckNum] = useState(0);
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    const {Title} = Typography
    const {Step} = Steps
    const {TabPane} = Tabs
    const {Text} = Typography

    const onDetailFinish = (values: any) => {
        axios
            .post<Result<any[]>>(`/clothes/price/savePrice`, {
                data: JSON.stringify(values),
            })
            .then((resp) => {
                if (resp.data.Status === "1") {
                    message.info(i18n.t("saveSuccess"));
                    setVisiable(false);
                    thisGridRef.current.refresh();
                }
            });
    };
    const param = {
        title: i18n.t("结算",),
        content: i18n.t("是否确认此操作，该操作不可逆",)
    }

    return (
        <div>
            <PageHeader
                title={i18n.t("加工厂核算")}
            />
            <Row gutter={[24, 8]}>
                <Space>

                    <Form form={form} style={{marginLeft: 10}} >
                        <Row>
                            <Space>

                                <Form.Item
                                    name="docDate"
                                    label={i18n.t("时间查询")}
                                >
                                    <DateTimePickerWithFormat
                                        rangePicker={true}
                                    />
                                </Form.Item>


                                <Form.Item
                                    name="status"
                                    label={i18n.t("状态")}
                                >
                                    <Select style={{width:150}} allowClear>
                                        <Option value={"未结算"}>{i18n.t("未结算")}</Option>
                                        <Option value={"部分结算"}>{i18n.t("部分结算")}</Option>
                                        <Option value={"已结算"}>{i18n.t("已结算")}</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="factoryId"
                                    label={i18n.t("加工厂")}
                                >
                                    <DictionarySelects entityName={"clothes_customer"} filter={" customerType='5' "} style={{width:200}}/>

                                </Form.Item>

                                <Form.Item
                                    name="produce"
                                    label={i18n.t("工艺查询")}
                                >
                                    <DictionarySelect entityName={"dict_working_procedure"} style={{width: 300}}/>

                                </Form.Item>

                                <Form.Item>
                                    <Button onClick={() => {
                                        var produce = form.getFieldValue("produce")
                                        var factoryId = form.getFieldValue("factoryId")
                                        var docDate: any = form.getFieldValue("docDate")
                                        var status: any = form.getFieldValue("status")
                                        const temp: any = {};
                                        if (docDate) {
                                            temp.searchField_dateTime_createTime = docDate[0].format("YYYY-MM-DD") + "|" + docDate[1].format("YYYY-MM-DD");
                                        }
                                        if (factoryId) {
                                            temp.searchField_dictionary_factoryId = factoryId;
                                        }
                                        if (produce) {
                                            temp.searchField_dictionary_processId = produce;
                                        }
                                        if (status) {
                                            temp.searchField_string_billingStatus = "1|"+status;
                                        }
                                        setSearchData(temp)

                                    }}>{i18n.t("搜索")}</Button>
                                    <Button onClick={() => {
                                        var ids = thisGridRef.current.getSelectedIds();
                                        var selectedRows: any[] = thisGridRef.current.getSelectedRows();
                                        if (ids.length <= 0) {
                                            message.error(i18n.t("必须选择一条数据"));
                                            return false;
                                        }
                                        selectedRows.map((item) => {
                                            if (selectedRows.length > 1 && item.settlementAmount > 0) {
                                                message.info(i18n.t("只能批量结算未结算的状态"));
                                                throw new DOMException();
                                            }
                                        })
                                        var checkedIds = "";
                                        ids.map((item: string) => {
                                            if (checkedIds != "") {
                                                checkedIds += "," + item;
                                            } else {
                                                checkedIds += item;
                                            }
                                        })


                                        if (ids.length > 1) {
                                            const tipParam = {
                                                ...param,
                                                onOk:()=>{
                                                    axios.post("/clothes/statistic/updateProduceOrderStatus", {
                                                        uuid: checkedIds,
                                                        isBath: true
                                                    }).then((resp: any) => {
                                                        if (resp.data.Status == 1) {
                                                            message.success(i18n.t("结算成功"));
                                                            hideModal()
                                                            thisGridRef.current.refresh()
                                                        } else {
                                                            message.error(resp.data.Data);
                                                        }
                                                    })
                                                }
                                            }
                                            Tip(tipParam)
                                        } else {
                                            var checkedId = ids[0];
                                            var checkRow = selectedRows[0];
                                            setCheckId(checkedId);
                                            var checkedNum = checkRow.ableSettlementAmount;
                                            setCheckNum(checkedNum);
                                            setVisiable(true);
                                            detailForm.setFieldsValue({
                                                uuid: checkedId,
                                                checkNum: checkedNum,
                                                settlementAmount: checkedNum,
                                                settlementedAmount: checkRow.settlementAmount
                                            })
                                        }


                                        /*axios.post("/clothes/statistic/updateProduceOrderStatus",{uuid:checkedIds,status:1}).then((resp:any)=>{
                                            if(resp.data.Status == 1){
                                                message.success("结算成功");
                                                thisGridRef.current.refresh()
                                            }
                                        })*/


                                    }}>{i18n.t("结算")}</Button>
                                    <Button onClick={() => {
                                        form.setFieldsValue({
                                            factoryId: undefined,
                                            docDate: undefined,
                                            produce: undefined
                                        })

                                    }}>{i18n.t("重置")}</Button>
                                </Form.Item>
                            </Space>
                        </Row>
                    </Form>

                </Space>
            </Row>


            <div className={"jgc"}>
                <DataGrid isShowSearch={false} isShowExport={true}
                          ref={thisGridRef} rowSelectionType="checkbox"
                          url={"/clothes/statistic/getProduceOrderStatusList"} searchData={searchData} descriptions={[
                    {
                        fieldName: "billingStatus",
                        fieldShowName: i18n.t("状态",),
                        render: (text: any, record: any) => {
                            if (text == '未结算') {
                                return (
                                    <Tag color="#f50">{i18n.t(text)}</Tag>
                                );
                            } else if (text == '部分结算') {
                                return (
                                    <Tag color="#2db7f5">{i18n.t(text)}</Tag>
                                );
                            } else {
                                return (
                                    <Tag color="#87d068">{i18n.t(text)}</Tag>
                                );
                            }
                        }
                    },
                    {
                        fieldName: "serialNumber",
                        fieldShowName: i18n.t("制单号",)
                    },
                    {
                        fieldName: "skuId",
                        fieldShowName: i18n.t("商品编号",),
                        inputType: 6,
                        dictionary: "clothes_sku"
                    },
                    {
                        fieldName: "brandId",
                        fieldShowName: i18n.t("品牌",),
                        inputType: 6,
                        dictionary: "clothes_customer_brand"
                    },
                    {
                        fieldShowName: i18n.t('工序',),
                        fieldName: 'craftName',
                        // inputType: inputType.DICTIONARY,
                        // dictionary: "clothes_sku_working_procedure",
                        render: (text: any, record: any) => {
                            return (
                                <div style={{color: record.schedule == 'stop' ? 'red' : ''}}>
                                    {record.craftName}
                                </div>
                            )
                        },
                    },
                    {
                        fieldName: "actualProcessingNumber",
                        fieldShowName: i18n.t('实际加工数',),
                    },
                    {
                        fieldName: "billByPiece",
                        fieldShowName: i18n.t('单位成本',),
                    },
                    {
                        fieldName: "amount",
                        fieldShowName: i18n.t('合计',),
                    },
                    {
                        fieldShowName: i18n.t('加工厂',),
                        fieldName: 'factoryId',
                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_customer",
                    },
                    {
                        fieldShowName: i18n.t('销售订单',),
                        fieldName: 'saleOrderId',

                        inputType: inputType.DICTIONARY,
                        dictionary: "clothes_order.serialNumber",
                    },
                    {
                        fieldShowName: i18n.t('销售预交货期',),
                        fieldName: 'preFinishDate',
                        inputType: inputType.DATE,
                    },
                    {
                        fieldShowName: i18n.t('备注',),
                        fieldName: 'remark',
                    },

                ]}

                          tableSummary={pageData => {
                              let totalBorrow = 0;
                              let totalRepayment = 0;

                              pageData.forEach(({amount, actualProcessingNumber}: any) => {
                                  totalBorrow += actualProcessingNumber;
                                  totalRepayment += amount;
                              });

                              return (
                                  <>
                                      <Table.Summary.Row>
                                          <Table.Summary.Cell colSpan={2} index={1}>
                                              <span style={{float: 'right'}}>{i18n.t("合计")}：</span>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{totalBorrow}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{totalRepayment}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell index={0}>
                                              <Text type="danger" style={{paddingLeft: 8}}>{""}</Text>
                                          </Table.Summary.Cell>
                                      </Table.Summary.Row>
                                  </>
                              );
                          }}

                    /*checkboxProps={(record)=>{
                        var disable = false;
                        if(record.settlementAmount > 0 && record.settlementAmount <= record.amount){
                            disable = true;
                        }
                        return(
                            {
                                disabled: disable, // Column configuration not to be checked
                                name: record.name,
                            }
                        )
          }}*/
                />


            </div>

            <Modal
                title={i18n.t("价格表")}
                className="add-component"
                visible={visiable}
                destroyOnClose={false}
                onCancel={hideModal}
                footer={
                    <>
                        <>
                            <button
                                type="button"
                                className="ant-btn ant-btn-primary"
                                onClick={() => {
                                    var data = detailForm.getFieldsValue();
                                    console.log(data)
                                    if (isNullOrUndefined(data.settlementAmount) || Number(data.settlementAmount) == 0) {

                                        message.error(i18n.t("结算金额不可为0"));
                                        return false;

                                    }
                                    if (Number(data.settlementAmount) > Number(data.checkNum)) {

                                        message.error(i18n.t("结算金额不可超过待结算金额"));
                                        return false;

                                    }

                                    const tipParam = {
                                        ...param,
                                        onOk:()=>{
                                            axios.post("/clothes/statistic/updateProduceOrderStatus", {
                                                uuid: data.uuid,
                                                amount: data.settlementAmount
                                            }).then((resp: any) => {
                                                if (resp.data.Status == 1) {
                                                    message.success(i18n.t("结算成功"));
                                                    hideModal()
                                                    thisGridRef.current.refresh()
                                                } else {
                                                    message.error(resp.data.Data);
                                                }
                                            })
                                        }
                                    }
                                    Tip(tipParam)
                                }}
                            >
                                <span>{i18n.t("确定")}</span>
                            </button>
                            <button type="button" className="ant-btn" onClick={hideModal}>
                                <span>{i18n.t("取消")}</span>
                            </button>
                        </>
                    </>}
                width={(width * 1) / 5}
                bodyStyle={{height: (height * 1) / 5}}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={detailForm}
                    layout="horizontal"
                    name="basicForm"
                    autoComplete="off"
                    preserve={false}
                    onFinish={onDetailFinish}
                >
                    <Form.Item name="uuid" hidden>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={i18n.t("已结算金额")}
                        name="settlementedAmount"
                    >
                        <InputNumber readOnly={true}
                                     size={"middle"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("待结算金额")}
                        name="checkNum"
                    >
                        <InputNumber readOnly={true}
                                     size={"middle"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("结算金额")}
                        name="settlementAmount"
                    >
                        <InputNumber
                            min={0}
                            max={checkNum}
                            size={"middle"}
                        />
                    </Form.Item>
                </Form>
            </Modal>


        </div>
    );
}