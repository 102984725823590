import React, { useEffect, useState, useCallback } from "react";
// import MonacoEditor from "react-monaco-editor";
import { Button, message } from "antd";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import { getQueryString } from "../../../../../util/getQueryString";

export default function ServerScript() {
  const [script, setScript] = useState("");
  const save = useCallback(
    function () {
      axios
        .post<Result<string>>("/wf/form/vsheet/script/server", {
          workflowTemplateId: getQueryString("workflowTemplateId"),
          script: script,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.success(resp.data.Data);
          }
        });
    },
    [script]
  );
  useEffect(function () {
    axios
      .get<Result<string>>(
        `/wf/form/vsheet/script/server/raw?workflowTemplateId=${getQueryString(
          "workflowTemplateId"
        )}`
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          setScript(resp.data.Data);
        }
      });
  }, []);
  return (
    <>
      <div style={{ textAlign: "right", marginBottom: 10 }}>
        <Button type={"primary"} onClick={save}>
          保存
        </Button>
      </div>
      {/*<MonacoEditor
        language="javascript"
        theme="vs-light"
        height={400}
        value={script}
        options={{
          selectOnLineNumbers: true,
        }}
        onChange={function () {
          setScript(arguments[0]);
        }}
        editorDidMount={function () {}}
      />*/}
    </>
  );
}
