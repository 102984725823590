import React, { useContext, useState, useRef } from "react";
import {
  message,
  Modal,
  Input,
  Collapse,
  Form,
  Button,
} from "antd";
import {
  PhoneTwoTone,
  OrderedListOutlined,
  ClockCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import styled from "styled-components";
import { TableGrid } from "../../../../../../components/TableGrid";
import { validateTableGridSave } from "../../Customer/Detail/util";
import { useUpdateEffect } from "react-use";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import moment from "moment";
const { TextArea } = Input;
const { Panel } = Collapse;
const FlexDiv:any = styled.div`
  display: flex;
  align-items: center;
`;
const InlineFormGroup:any = styled.div`
  margin-right: 20px;
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
export interface ContactDetailDialogProps {
  visible: boolean;
  /**
   * uuid 编辑联系记录
   */
  uuid?: string;
  /**
   * editable 编辑联系记录
   */
  editable?: boolean;
  /**
   * customerId 新建联系记录
   */
  customerId?: string;
  chanceId?: string;
  projectId?: string;
  entityName?: string | undefined;
  onCancel: () => void;
  onOk: () => void;
}
export const CustomerContactDetailDialog = ({
  visible,
  onCancel,
  onOk,
  uuid,
  customerId,
  chanceId,
  projectId,
  entityName = "crm_contact",
  editable = true,
}: ContactDetailDialogProps) => {
  const session: Session = useContext(SessionContext);
  const [data, setData] = useState<any>({});
  const tableGridRef = useRef<any>(null);
  useUpdateEffect(() => {
    axios
      .post("/crm/customer/getRecordById", {
        uuid: uuid,
        customerId: customerId,
        chanceId: chanceId,
        projectId: projectId,
        tableName: entityName,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          setData(resp.data.Data);
        }
      });
  }, [uuid]);

  function saveContactDetail(id: string) {
    const copy = { ...data, uuid: id };
    // if (!uuid) {
    //   copy.customerId = customerId;
    //   copy.projectId = projectId;
    //   copy.chanceId = chanceId;
    // }
    axios.post("/crm/crm/contact/addContact", copy).then((resp) => {
      if (resp.data.Status === "1") {
        message.success("保存成功");
        onOk?.();
      }
    });
  }

  return (
    <>
      <Modal
        title={uuid ? "查看联系记录" : "新建联系记录"}
        width={800}
        footer={
          editable ? (
            <>
              <Button
                onClick={() => {
                  onCancel?.();
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (validateTableGridSave(data) && tableGridRef.current) {
                    tableGridRef.current.save();
                  }
                }}
              >
                确定
              </Button>
            </>
          ) : null
        }
        visible={visible}
        onCancel={() => {
          onCancel?.();
        }}
      >
        <TextArea
          rows={4}
          placeholder="联系内容"
          value={data.content}
          style={{ borderBottom: "none" }}
          onChange={(e) => {
            setData({
              ...data,
              content: e.target.value,
            });
          }}
        />
        <Collapse
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <DoubleRightOutlined
              style={{ color: "#1890ff" }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            extra={
              <span
                style={{
                  color: "#1890ff",
                  marginTop: 5,
                  display: "inline-block",
                }}
              >
                更多
              </span>
            }
            header={
              <FlexDiv style={{ float: "left" }}>
                <InlineFormGroup
                  onClick={(event:any) => {
                    event.stopPropagation();
                  }}
                >
                  <ClockCircleOutlined
                    style={{ color: "#cccccc", fontSize: 18 }}
                  />
                  <DatePicker
                    showTime
                    style={{ width: 150, marginLeft: 5 }}
                    value={
                      data.contactTime
                        ? moment(data.contactTime, "YYYY-MM-DD hh:mm")
                        : null
                    }
                    onChange={(value, dateString) => {
                      setData({
                        ...data,
                        contactTime: dateString,
                      });
                    }}
                  />
                </InlineFormGroup>
                <InlineFormGroup
                  onClick={(event:any) => {
                    event.stopPropagation();
                  }}
                >
                  <PhoneTwoTone
                    twoToneColor="#cccccc"
                    style={{ color: "#cccccc", fontSize: 18 }}
                    rotate={100}
                  />
                  <DictionarySelect
                    style={{ width: 130, marginLeft: 5 }}
                    entityName="dict_contact_way"
                    value={data.contactWay}
                    onChange={(value: any) => {
                      setData({
                        ...data,
                        contactWay: value,
                      });
                    }}
                  />
                </InlineFormGroup>
                <InlineFormGroup
                  onClick={(event:any) => {
                    event.stopPropagation();
                  }}
                >
                  <OrderedListOutlined
                    style={{ color: "#cccccc", fontSize: 18 }}
                  />
                  <DictionarySelect
                    style={{ width: 130, marginLeft: 5 }}
                    entityName="dict_contact_stage"
                    value={data.stage}
                    onChange={(value: any) => {
                      setData({
                        ...data,
                        stage: value,
                      });
                    }}
                  />
                </InlineFormGroup>
              </FlexDiv>
            }
            key="1"
          >
            <TableGrid
              ref={tableGridRef}
              id={uuid}
              entityName={entityName}
              columnCount={2}
              defaultValues={{
                creatorId: session.id,
                createTime: moment().format("YYYY-MM-DD"),
              }}
              onSaved={(id) => {
                saveContactDetail(id);
              }}
            />
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
              <Form.Item label="拜访位置">
                <Input value={data.address} />
              </Form.Item>
              <Form.Item label="下次联系时间">
                <DatePicker
                  showTime
                  style={{ width: "100%" }}
                  value={
                    data.nextContactTime
                      ? moment(data.nextContactTime, "YYYY-MM-DD hh:mm")
                      : null
                  }
                  onChange={(value, dateString) => {
                    setData({
                      ...data,
                      nextContactTime: dateString,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="下次联系内容">
                <Input value={data.nextContactContent} />
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
};
