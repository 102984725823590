export const inputType = {
    TEXT: 0,
    INTEGER: 1,
    DOUBLE: 2,
    CHECKBOX: 3,
    DATE: 4,
    TIME: 5,
    DICTIONARY: 6,
    AUTOCOMPLETE: 7,
    CHECKLISTBOX: 8,
};
