import React, {useCallback, useEffect, useState} from "react";
import {Button, message, Modal, Upload} from "antd";
import { instance as axios } from "../../util/axios";
import { BaseURL } from "../../constants/url";
import i18n from "i18next";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {isEmpty} from "../../containers/App/screens/Clothes/util/Common";
import ImgCrop from "antd-img-crop";

export interface Param {
    value?: string | string[];
    onChange?: (value: any) => void;
    onPreview?: (file:any) => void;
}

const ImageUploads = ({ value, onChange,onPreview }: Param) => {
    const [imageUrl,setImageUrl] = useState("");
    const [fileList,setFileList] = useState<any>([]);
    function beforeUpload(file:any) {
        let index = file.name.lastIndexOf('.')
        // 截取最后一个文件后缀名
        let extname = file.name.substr(index + 1)
        // 定义一个数组存储正确的图片格式
        let imgArr = ['jpg','jpeg','png','gif','webp','heif','jfif']
        // 判断截取的文件后缀是否正确
        const isJpgOrPng = imgArr.includes(extname.toLowerCase())
        // console.log(file)
        // const isJpgOrPng = (file.type === 'image/png'||  file.type === 'image/jpeg'||  file.type === 'image/jpg'|| file.type === 'image/gif'
        //     || file.type === 'image/webp'|| file.type === 'image/heif'|| file.type === 'image/hevc');
        if (!isJpgOrPng) {
            message.error('图片格式不正确');
            return Upload.LIST_IGNORE
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(i18n.t("img_limit_message"));
            return Upload.LIST_IGNORE
        }
        return isJpgOrPng && isLt2M;
    }
    const uploadButton = (
        <div>
            {false ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{i18n.t("uploadImage")}</div>
        </div>
    );
    useEffect(() => {
        console.log("value:",value)
        const tempFileList:any = [];
        if(!isEmpty(value)){
            let tempValue:any = value;
            tempValue.split(',').map((item:any)=>{
                tempFileList.push({
                    uid:item,
                    status:'done',
                    url:BaseURL+"/oa/common/attachment/showImageAttachment?id="+item
                })
            })
            console.log(tempFileList)
            setFileList(tempFileList)

        }
    }, [value]);
    return (
        <div >
            <ImgCrop
                     modalTitle={i18n.t("编辑")}
                     modalOk={i18n.t("确定")}
                     modalCancel={i18n.t("取消")}
                     showGrid={true}

            >
            <Upload
                name="avatar"
                showUploadList={true}
                listType="picture-card"
                withCredentials={true}
                data={(file) => {
                    return {
                        fileData: file,
                        category: "id",
                        fileName: encodeURI(file.name),
                    };
                }}
                beforeUpload={beforeUpload}
                multiple={true}
                accept="image/*"
                action={BaseURL + "/oa/common/attachment/uploadFile2"}
                onChange={(param) => {
                    let fileList = [...param.fileList];
                    setFileList(fileList)

                    // 1. Limit the number of uploaded files
                    // Only to show two recent uploaded files, and old ones will be replaced by the new
                    fileList = fileList.slice(-10);

                    // 2. Read from response and show file link
                    fileList = fileList.map(file => {
                        if (file.response) {
                            // Component will show file.url as link
                            file.url = BaseURL+"/oa/common/attachment/showImageAttachment?id="+file.response.Data.uuid;
                            file.uid = file.response.Data.uuid;
                        }
                        return file;
                    });


                    setFileList(fileList);
                    const ids = fileList.map((item:any) => item.uid)
                        .join(",");
                    if(onChange){
                        onChange(ids)
                    }

                }}
                fileList={fileList}
                onPreview={(file:any)=>{
                    if(onPreview){
                        onPreview(file)
                    }
                }}
                onRemove={(file:any)=>{
                    console.log(file)
                }}
            >
                {fileList.length < 10 ? uploadButton:""}
            </Upload>
            </ImgCrop>
        </div>
    );
};
export default ImageUploads;
