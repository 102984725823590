import React, { ReactNode, useCallback, useRef, useState } from "react";

import { useWindowSize } from "react-use";
import { DataGrid } from "../../../../../components/DataGrid";
import "../CSS/price.css";
import { Form, Input, InputNumber, message, Modal } from "antd";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import i18n from "i18next";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
export default () => {
  const { width, height } = useWindowSize();
  const [visiable, setVisiable] = useState(false);
  const [detailForm] = Form.useForm();
  const thisGridRef = useRef<any>();
  const columnsData = [
    {
      fieldShowName: i18n.t("价格表名称",),
      fieldName: "name",
    },
    {
      fieldShowName: i18n.t("操作",),
      fieldName: "uuid",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        return (
            <a
                href="#"
                className="deleteA"
                onClick={async (e: any) => {
                  await getPriceDetailById(record.uuid);
                  await setVisiable(true);
                }}
            >
              {i18n.t("编辑")}
            </a>
        );
      },
    },
  ];
  const getPriceDetailById = useCallback(function (priceId: string) {
    axios
      .post<Result<any[]>>(`/clothes/price/getPriceDetailById`, {
        priceId: priceId,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          detailForm.setFieldsValue(resp.data.Data);
        }
      });
  }, []);
  const [columns] = useState<any[]>(columnsData);
  const detailOnOk = () => {
    detailForm.submit();
  };
  const hideModal = () => {
    setVisiable(false);
  };
  const detailElement: ReactNode = (
      <>
        <>
          <button
              type="button"
              className="ant-btn ant-btn-primary"
              onClick={detailOnOk}
          >
            <span>{i18n.t("确定")}</span>
          </button>
          <button type="button" className="ant-btn" onClick={hideModal}>
            <span>{i18n.t("取消")}</span>
          </button>
        </>
      </>
  );
  const onDetailFinish = (values: any) => {
    axios
      .post<Result<any[]>>(`/clothes/price/savePrice`, {
        data: JSON.stringify(values),
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("保存成功"));
          setVisiable(false);
          thisGridRef.current.refresh();
        }
      });
  };
  return (
      <div className="price-tabB-table">
        <DataGrid
            ref={thisGridRef}
            pagination={true}
            sorter={{field: "createTime", order: "descend"}}
            pageSize={Math.floor((height - 280) / 38)}
            descriptions={columns}
            onClick={async (record: any) => {
              await getPriceDetailById(record.uuid);
              await setVisiable(true);
            }}
            url={`/clothes/price/getCurrencyPriceList`}
        />

        <Modal
            title={i18n.t("价格表")}
            className="add-component"
            visible={visiable}
            destroyOnClose={true}
            onCancel={hideModal}
            footer={detailElement}
            width={(width * 1) / 3}
            bodyStyle={{height: (height * 1) / 2, overflowY: 'auto'}}
            maskClosable={false}
        >
          <Form
              {...layout}
              form={detailForm}
              layout="horizontal"
              name="basicForm"
              autoComplete="off"
              preserve={false}
              onFinish={onDetailFinish}
          >
            <Form.Item name="uuid" hidden>
              <Input/>
            </Form.Item>
            <Form.Item name="isCurrency" hidden>
              <Input/>
            </Form.Item>
            <Form.Item name="customerId" hidden>
              <Input/>
            </Form.Item>
            <Form.Item name="brandId" hidden>
              <Input/>
            </Form.Item>
            <Form.Item
                label={i18n.t("价格表名称")}
                name="name"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined || value.trim() == "") {
                        message.error(i18n.t("价格表名称")+i18n.t("不能为空"));
                        throw new Error(i18n.t("价格表名称")+i18n.t("不能为空"));
                      }
                    },
                  },
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item label={i18n.t("参考基价")} name="referenceBasePrice">
              <Input readOnly={true}/>
            </Form.Item>
            <Form.Item
                label={i18n.t("前期成本固定加价")}
                name="earlyPriceIncrease"
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <Input type="number"/>
            </Form.Item>
            <Form.Item
                label={i18n.t("利润")+"%"}
                name="profit"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined) {
                        message.error(i18n.t("利润")+i18n.t("不能为空"));
                        throw new Error(i18n.t("利润")+i18n.t("不能为空"));
                      }
                    },
                  },
                ]}
            >
              <InputNumber
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  size={"middle"}
                  parser={(value: any) => value.replace("%", "")}
              />
            </Form.Item>
            <Form.Item
                label={i18n.t("后期固定加价")}
                name="latePriceIncrease"
                rules={[
                  {
                    required: true,
                  },
                ]}
            >
              <Input type="number"/>
            </Form.Item>
            <Form.Item
                label={i18n.t("修正精确")}
                name="accuracyId"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined || value.trim() == "") {
                        message.error(i18n.t("修正精确")+i18n.t("不能为空"));
                        throw new Error(i18n.t("修正精确")+i18n.t("不能为空"));
                      }
                    },
                  },
                ]}
            >
              <DictionarySelect entityName={"dict_price_accuracy"} isTrans = {true}/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
};
