import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Input, DatePicker, Button, message, Form } from "antd";
import { SessionContext } from "../../../index";
import Session from "../../../../../schemas/session";
import { FlexDiv, InlineFormGroup } from "../../../../../components/style";
import {
  ClockCircleOutlined,
  PhoneTwoTone,
  OrderedListOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { DictionarySelect } from "../../../../../components/DictionarySelect";
import { validateTableGridSave } from "../Customer/Detail/util";
import { TableGrid } from "../../../../../components/TableGrid";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
const { TextArea } = Input;
export interface AddContactProps {
  id: string;
  host: "customer" | "project" | "chance";
  onOk: () => void;
  entityName?: string;
}
export const AddContact = ({
  id,
  host,
  onOk,
  entityName = "crm_sale_chance_contact",
}: AddContactProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const tableGridRef = useRef<any>(null);
  const session: Session = useContext(SessionContext);
  const [addForm, setAddForm] = useState({
    content: "",
    contactTime: "",
    contactWay: "",
    stage: "",
    nextContactTime: "",
    address: "",
    nextContactContent: "",
  });
  const defaultValues = useMemo(
    () => ({
      creatorId: session.id,
      createTime: moment().format("YYYY-MM-DD"),
    }),
    [session.id]
  );

  /**
   *  保存联系记录的固定字段，如下次联系时间等
   *  动态字段通过 TableGrid 保存
   */
  const saveFixedFields = useCallback(
    (uuid: string) => {
      axios
        .post<Result<void>>("/crm/crm/contact/addContact", {
          ...addForm,
          uuid: uuid,
          [host + "Id"]: id,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.success("保存成功");
            onOk?.();
          }
        });
    },
    [addForm, id]
  );

  return (
    <>
      <div style={{ background: "#e6f7ff", padding: 10 }}>
        <TextArea
          rows={4}
          placeholder="联系内容"
          value={addForm.content}
          onChange={(e) => {
            setAddForm({
              ...addForm,
              content: e.target.value,
            });
          }}
        />
        <FlexDiv style={{ paddingTop: 10 }}>
          <InlineFormGroup onClick={(event:any) => event.stopPropagation()}>
            <ClockCircleOutlined style={{ color: "#cccccc", fontSize: 18 }} />
            <DatePicker
              showTime
              style={{ width: 150, marginLeft: 5 }}
              value={
                addForm.contactTime
                  ? moment(addForm.contactTime, "YYYY-MM-DD hh:mm")
                  : null
              }
              onChange={(value, dateString) => {
                setAddForm({
                  ...addForm,
                  contactTime: dateString,
                });
              }}
            />
          </InlineFormGroup>
          <InlineFormGroup onClick={(event:any) => event.stopPropagation()}>
            <PhoneTwoTone
              twoToneColor="#cccccc"
              style={{ color: "#cccccc", fontSize: 18 }}
              rotate={100}
            />
            <DictionarySelect
              style={{ width: 130, marginLeft: 5 }}
              entityName="dict_contact_way"
              value={addForm.contactWay}
              onChange={(value: any) => {
                setAddForm({
                  ...addForm,
                  contactWay: value,
                });
              }}
            />
          </InlineFormGroup>
          <InlineFormGroup onClick={(event:any) => event.stopPropagation()}>
            <OrderedListOutlined style={{ color: "#cccccc", fontSize: 18 }} />
            <DictionarySelect
              style={{ width: 130, marginLeft: 5 }}
              entityName="dict_contact_stage"
              value={addForm.stage}
              onChange={(value: any) => {
                setAddForm({
                  ...addForm,
                  stage: value,
                });
              }}
            />
          </InlineFormGroup>
          <div style={{ flex: 1 }} onClick={() => setExpanded(!expanded)}>
            <Button type={"link"}>
              更多
              <DoubleRightOutlined rotate={expanded ? 90 : 0} />
            </Button>
          </div>
          <Button
            type="primary"
            onClick={() =>
              validateTableGridSave(addForm) &&
              tableGridRef.current &&
              tableGridRef.current.save()
            }
          >
            确定
          </Button>
        </FlexDiv>
      </div>

      <div
        style={expanded ? { marginTop: 10 } : { height: 0, overflow: "hidden" }}
      >
        <TableGrid
          ref={tableGridRef}
          entityName={entityName}
          columnCount={2}
          defaultValues={defaultValues}
          onSaved={(uuid) => saveFixedFields(uuid)}
        />
        <Form
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          style={{ marginTop: 15 }}
        >
          <Form.Item label="拜访位置">
            <Input value={addForm.address} />
          </Form.Item>
          <Form.Item label="下次联系时间">
            <DatePicker
              showTime
              style={{ width: "100%" }}
              value={
                addForm.nextContactTime
                  ? moment(addForm.nextContactTime, "YYYY-MM-DD hh:mm")
                  : null
              }
              onChange={(value, dateString) => {
                setAddForm({
                  ...addForm,
                  nextContactTime: dateString,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="下次联系内容">
            <Input value={addForm.nextContactContent} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
