// 开发阶段使用sessionstorage进行缓存
var storage = {
    set(key,value){
        sessionStorage.setItem(key,JSON.stringify(value))
    },
    get(key){
        return JSON.parse(sessionStorage.getItem(key));
    },
    remove(key){
        sessionStorage.removeItem(key)
    }
}

export default storage;