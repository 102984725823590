import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react"
import { Form, InputNumber} from 'antd'
import i18n from "i18next";

export interface Param {
	formData:any,
    countAmountRef:()=>void;
}
export const TabJEMX = forwardRef(({formData,countAmountRef}:Param,ref:any)=> {

	return (
		<div className="u-tab-con u-tab-jemx">
			<Form.Item label={i18n.t("订单金额")} name="amount">
				<InputNumber style={{width: '80%'}} onChange={(v: any) => {
					//计算折扣金额
					const discountRate: any = Number(formData.getFieldValue("discountRate")) || 0;
					var result = Number(v * (discountRate / 100)).toFixed(2);
					formData.setFieldsValue({discountAmount: result})
					countAmountRef();
				}} disabled={true}/>
			</Form.Item>

			<Form.Item label={i18n.t("税率")} name="taxRate">
				<InputNumber disabled={true} style={{width: '80%'}} onChange={() => {
					countAmountRef();
				}}/>
			</Form.Item>

			<Form.Item label={i18n.t("折扣%")} name="discountRate">
				<InputNumber style={{width: '80%'}} onChange={(v: any) => {
					//填写折扣带出折扣金额
					const amount = Number(formData.getFieldValue("amount"));
					var result = Number(amount * (v / 100)).toFixed(2);
					formData.setFieldsValue({discountAmount: result})
					countAmountRef();
				}}/>
			</Form.Item>

			<Form.Item label={i18n.t("税费")} name={"tax"}>
				<InputNumber disabled={true}/>
			</Form.Item>

			<Form.Item label={i18n.t("折扣金额")} name="discountAmount">
				<InputNumber style={{width: '80%'}} onChange={(v: any) => {
					//填写折扣金额带出折扣
					const amount = Number(formData.getFieldValue("amount"));
					var result = Number(v / amount * 100).toFixed(2);
					formData.setFieldsValue({discountRate: result})
					countAmountRef();

				}}/>
			</Form.Item>

			<Form.Item label={i18n.t("服务费用")} name="serviceAmount">
				<InputNumber disabled={true} style={{width: '80%'}}/>
			</Form.Item>


			<Form.Item label={i18n.t("实际金额")} name="actualAmount">
				<InputNumber disabled={true} style={{width: '80%'}} onChange={(v: any) => {
					//填写实际金额 , 带出折扣
					const amount = Number(formData.getFieldValue("amount"));
					var discountAmount: any = Number(amount - v).toFixed(2);
					var discountRate = Number(discountAmount / amount * 100).toFixed(2);
					formData.setFieldsValue({discountRate: discountRate, discountAmount: discountAmount})
					console.log(v)
				}}/>
			</Form.Item>

			<Form.Item label={i18n.t("文件总金额")} name="totalAmount">
				<InputNumber disabled={true}/>
			</Form.Item>


		</div>
	);
	
})