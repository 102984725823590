import React, {useState, useEffect, useCallback, useImperativeHandle, forwardRef} from "react";
import {Button, Col, Form, Input, message, Modal, Row, Select, TreeSelect} from "antd";

import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import "../../CSS/BasicData.css";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import "../../CSS/skuDetaile.css";
import "../../CSS/color.css";
import i18n from "i18next";
import { useHistory, useParams } from "react-router";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import '../ColorSelect.css'
import {TreeNode} from "antd/es/tree-select";
import {PlusOutlined} from "@ant-design/icons/lib";
import FormList from "antd/es/form/FormList";
import {generateUUID, isEmpty} from "../../util/Common";
import {useWatch} from "antd/es/form/Form";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
const { Option } = Select;
const { SketchPicker  } = require("react-color");

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

interface Params {
  uuid: string;
  type: string;
  isHomePage:string;
  categoryId:string;
}
interface OrderParam {
  colorType?:string;
  isFromOrder?:string;
  onOk?:()=>void;
}
export default forwardRef(({colorType,isFromOrder,onOk}:OrderParam,ref:any) => {
  const history = useHistory();
  const [selectValue, setSelectValue] = useState("undefined");
  const [dataTree, setDataTree] = useState<any>();
  const [isDoubleColor, setIsDoubleColor] = useState(false);
  const [isRgb, setIsRgb] = useState(true);
  const [rgbArea, setRgbArea] = useState(1);
  const [form] = Form.useForm();
  const [editColorForm] = Form.useForm();
  const params = useParams<Params>();
  const uuid = params.uuid;
  const type = params.type;
  const isHomePage = params.isHomePage;
  const categoryId = params.categoryId;
  const [rgb, setRgb] = useState("#FFFFFF");
  const [editRgb, setEditRgb] = useState("");
  const [secondRgb, setSecondRgb] = useState("#FFFFFF");
  const [editColor, setEditColor] = useState(false);
  const [colors,setColors] = useState([])
  const [colorDetail,setColorDetail] = useState([
  ])

  const colorList = Form.useWatch('colors', form);

  useEffect(()=>{
    console.log(1111111111)
      //计算色序
    var i = 1;
    var colorIndex = "";
    if(colorDetail && colorDetail.length > 0){
      colorDetail.map((item:any)=>{
        colorIndex += i;
        colorIndex += "-";
        i++;
      })
      colorIndex = colorIndex.substring(0, colorIndex.length-1);
    }
    form.setFieldsValue({colorIndex:colorIndex})

  },[colorDetail])

  useEffect(()=>{
    console.log(editColorForm)
    if(editColor && editColorForm){
      const item:any = colorDetail[rgbArea - 1];
      //弹出框初始化值
      editColorForm.setFieldsValue(item)
      setEditRgb(item.rgb)
    }
  },[editColor])


  const [data,setData] = useState<any>([]);
  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/colorManager/selectAllColor`, {
          colorId: uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data = resp.data.Data;
            if(data!=undefined&&data.length>0){
              setData(data);
            }
          }
        });
  },[])

  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/treeSelect/getTreeSelect`, {
          type: "color_sku_category",
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            setDataTree(resp.data.Data);
          }
        });
  },[])

  useEffect(function () {
    if(type!="add" && isFromOrder != "1"){
      axios
          .post<Result<any[]>>(`/clothes/colorManager/getColorManageById`, {
            colorId: uuid,
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              let data:any = resp.data.Data;
              setRgb(data.rgb);
              form.setFieldsValue(data);
              if(data.typeId=="2"){
                setIsDoubleColor(true);
              }
              if(data.categoryId!=undefined){
                form.setFieldsValue({
                  categoryId:data.categoryId.split(",")
                })
              }
              axios.get("/clothes/colorManager/getDetailList?colorId="+uuid).then((dresp)=>{
                if (dresp.data.Status === "1") {
                  const data:any = dresp.data.Data;
                  if(data && data.length > 0){
                    setColorDetail(data);
                  }
                }
                })
            }
          });
    }else{
      form.setFieldsValue({
        uuid:isFromOrder=="1"?generateUUID():uuid,
        // typeId:"1",
        nameColorId:"1",
        rgb:"#FFFFFF",
        status:1,
      })
      if( categoryId && categoryId!="undefined"){
        form.setFieldsValue({
          categoryId:categoryId.split(","),
        })
      }
      if(colorType){
        form.setFieldsValue({
          categoryId:colorType.split(",")
        })
      }
    }
  }, []);

  const onChange = (value: any) => {
    setSelectValue(value);
  };
  const changeRgb = ()=>{
    setIsRgb(true);
  }

  const changeSecondRgb = ()=>{
    setIsRgb(false);
  }
  const changeRgbArea= (index:any)=>{
    setRgbArea(index)
  }

  const getRgb = (rgbIndex:any)=>{
    if(!isDoubleColor){
      return rgb;
    }
    const rgbs:any = {...colors};
    return rgbs["rgb"+(rgbIndex+1)]
  }
  const getRgbName = (rgbIndex:any)=>{
    if(!isDoubleColor){
      return rgb;
    }
    const rgbs:any = {...colors};
    return rgbs["rgbName"+(rgbIndex+1)]
  }

  const sketchPickerOnChange = (color: any) => {
    if(!isDoubleColor){
      setRgb(color.hex);
      return;
    }
    const rgbs:any = {...colors};
    rgbs["rgb" + rgbArea] = color.hex;
    setColors(rgbs);
    console.log(rgbs)
  };
  const onFinish = (values: any) => {
    values.rgb = rgb;
    values.categoryId = values.categoryId.join(",");
    values.colorManageDetails = colorDetail;
    console.log("values:", values);
    axios
        .post<Result<any[]>>(`/clothes/colorManager/saveColorManager`, {
          data: JSON.stringify(values),
          type: type?type:"add",
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("saveSuccess"));
            if(onOk){
              onOk();
            }
          }
        });

  };
  const onFinishFailed = async (values: any) => {
    console.log("values:", values);
    values.errorFields.map((item:any)=>{
      message.error(item.errors[0]);
    })
  };
  function getTreeNote(dataTree: any) {
    if (dataTree != undefined) {
      return (
          <>
            {dataTree.map((item: any) => {
              return (
                  <TreeNode value={item.value} title={item.title}>
                    {item.children == undefined ? (
                        <></>
                    ) : (
                        getTreeNote(item.children)
                    )}
                  </TreeNode>
              );
            })}
          </>
      );
    } else {
      return <></>;
    }
  }

  const removeColor = (index:any)=>{
    console.log(1111111111111)
    const rgbs:any = {...colors};
    rgbs["rgb" + (index+1)] = "";

    for(var i = 1;i <=5 ;i++){
      var key = "rgb" + i;
      if(isEmpty(rgbs[key])){
        //这个元素空了  从后面取一个填进来
        var newKey = "rgb" + (i + 1);
        rgbs[key] = rgbs[newKey]
        rgbs[newKey] = "";
      }
    }
    console.log(rgbs)
    setColors(rgbs);
  }


  useImperativeHandle(ref,()=>({
    submit(){
      form.submit();
    }
  }))

  const remove = (index:any)=>{
    const tempColorDetail = [...colorDetail];
    tempColorDetail.splice(index,1);
    console.log(tempColorDetail)
    setColorDetail(tempColorDetail);
  }

  const add = (item:any)=>{
    const tempColorDetail:any = [...colorDetail];
    tempColorDetail[rgbArea-1] = item;
    setColorDetail(tempColorDetail);
  }


  return (
      <div className="u-box u-basic-page u-MaterialArchivesForm">
        {isFromOrder != "1" &&
        <div className="u-box-head u-box-head-border">
          <span className="u-box-title">{i18n.t("颜色库")}</span>
        </div>}

        <div className="u-box-con u-fixed-padding u-Table-border">
          <div className="u-box-con u-tabsA">
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="editForm"
                autoComplete="off"
                scrollToFirstError={true}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
              <div className="u-form-wrap">
                <div className="u-form-1" style={{width:"80%"}}>
                  <Form.Item name="uuid" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="status" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item label={i18n.t("app_enterprise_color_naming")} name="name" rules={[
                    {
                      required: true,
                      validator: async (rule, value) => {
                        if(isEmpty(value)){
                          // message.error(i18n.t('颜色名称不能为空'));
                          throw new Error(i18n.t('颜色名称不能为空'));
                        }
                        if (data != undefined && data.length > 0) {
                          data.map((item: any) => {
                            if (item.name == value) {
                              message.error(i18n.t('名称重复'));
                              throw new Error(i18n.t('名称重复'));
                            }
                          })
                        }
                      },
                    },
                  ]}>
                    <Input className="e-input"/>
                  </Form.Item>
                  <Form.Item name="rgb" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="secondRgb" hidden>
                    <Input/>
                  </Form.Item>
                  <Form.Item name="colorIndex" label={i18n.t("色序")}>
                    <Input disabled={true}/>
                  </Form.Item>
                  {isDoubleColor ? (
                      <>
                        <Form.Item label={"颜色"}>
                          <Row gutter={6}>
                          <Col span={23}>
                            {colorDetail.length > 0 &&
                              colorDetail.map((field:any, index) => (
                                  <>
                                    <Form.Item noStyle name={"colorName"} >
                                      <Input
                                          className={"color-form-rgb1"}
                                          value={field.colorName}
                                          readOnly={true}
                                          style={{backgroundColor: field.rgb}}
                                          onClick={() => {
                                            changeRgbArea(index + 1)
                                            setEditColor(true);
                                          }}
                                      />
                                      <a style={{float:"left",position:"relative",marginLeft:"-15px",fontSize:20}} onClick={()=>{
                                        remove(index)
                                        // autoUpdateColor()
                                      }}>x</a>
                                    </Form.Item>
                                  </>
                              ))
                            }
                          </Col>
                          <Col span={1}>
                            <Button
                                className="addColor"
                                onClick={() => {
                                  if (colorDetail.length >= 6) {
                                    message.info(i18n.t("只能选择六种颜色"));
                                    return;
                                  }
                                  const tempColorDetail:any = [...colorDetail];
                                  let temp = {
                                    "relationColorId":"",
                                    "rgb":"",
                                    "colorName":"",
                                    "colorIndex":tempColorDetail.length+1
                                  };
                                  tempColorDetail.push(temp);
                                  setColorDetail(tempColorDetail);
                                  setRgbArea(colorDetail.length + 1);
                                  setEditColor(true)

                                }}
                                icon={<PlusOutlined/>}
                            >
                            </Button>
                          </Col>
                          </Row>
                        </Form.Item>
                        {/*<Form.Item label={i18n.t("颜色")}>
                    <Row gutter={6}>
                      <Col span={23}>
                    <Input
                      className="color-form-rgb1"
                      value={rgb}
                      readOnly={true}
                      style={{ backgroundColor: rgb }}
                      onClick={changeRgb}
                    />
                    <Input
                      className="color-form-rgb1"
                      value={secondRgb}
                      readOnly={true}
                      style={{ backgroundColor: secondRgb }}
                      onClick={changeSecondRgb}
                    />
                    <Input
                      className="color-form-rgb1"
                      value={secondRgb}
                      readOnly={true}
                      style={{ backgroundColor: secondRgb }}
                      onClick={changeSecondRgb}
                    />
                    <Input
                      className="color-form-rgb1"
                      value={secondRgb}
                      readOnly={true}
                      style={{ backgroundColor: secondRgb }}
                      onClick={changeSecondRgb}
                    />
                    <Input
                      className="color-form-rgb1"
                      value={secondRgb}
                      readOnly={true}
                      style={{ backgroundColor: secondRgb }}
                      onClick={changeSecondRgb}
                    />
                      </Col>
                      <Col span={1}>
                        <Button className={"addColor"} onClick={() => add()} icon={<PlusOutlined />}/>
                      </Col>
                    </Row>
                  </Form.Item>*/}
                      </>
                  ) : (
                      <Form.Item label={i18n.t("颜色")}>
                        <Input
                            value={getRgb(0)}
                            readOnly={true}
                            style={{backgroundColor: getRgb(0), height: "150px"}}
                            onClick={changeRgb}
                        />
                      </Form.Item>
                  )}
                  <Form.Item label={i18n.t("app_enterprise_color_type")} name="typeId">
                    <DictionarySelect
                        entityName={"dict_sku_color_type"}
                        onChange={(value: any) => {
                          if (value == "1") {
                            setIsDoubleColor(false);
                          } else {
                            setIsDoubleColor(true);
                          }
                        }}
                    />
                  </Form.Item>
                  <Form.Item label={i18n.t("app_enterprise_color_classification")} name="categoryId" rules={[
                    {
                      required: true,
                      validator: async (rule, value) => {
                        if (value == undefined || value == "") {
                          throw new Error(i18n.t("app_enterprise_color_classification_message"));
                        }
                      },
                    },
                  ]}>
                    {/*<TreeSelect*/}
                    {/*  style={{ width: "100%" }}*/}
                    {/*  value={selectValue}*/}
                    {/*  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}*/}
                    {/*  treeData={dataTree}*/}
                    {/*  treeCheckable={true}*/}
                    {/*  treeDefaultExpandAll*/}
                    {/*  onChange={onChange}*/}
                    {/*/>*/}
                    <TreeSelect
                        showSearch
                        style={{width: "100%"}}
                        dropdownStyle={{maxHeight: 400, overflow: "auto"}}
                        placeholder="Please select"
                        treeNodeFilterProp={"title"}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                    >
                      {getTreeNote(dataTree)}
                    </TreeSelect>
                  </Form.Item>
                  <Form.Item label={i18n.t("app_enterprise_color_type_1")} name="nameColorId">
                    <DictionarySelect entityName={"dict_name_color"}/>
                  </Form.Item>
                  <Form.Item colon={false} label=" " name="" hidden={isDoubleColor}>
                    <SketchPicker color={getRgb(rgbArea - 1)} onChange={sketchPickerOnChange}/>
                  </Form.Item>
                  {/*<Form.Item colon={false} label=" " name="" hidden={isRgb}>
                  <SketchPicker color={secondRgb} onChange={sketchPickerOnChange} />
                </Form.Item>*/}
                </div>
              </div>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
                  hidden={isFromOrder==="1"}
              >
                <div className="u-button-box">
                  <Form.Item>
                    <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                        }}
                    >
                      {i18n.t("保存")}
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                          if (isHomePage == "true") {
                            history.push("/clothes/colorManager/list");
                          } else {
                            history.push("/clothes/colorManager/colorLibrary");
                          }
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("cancel")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Modal
            getContainer={false}
            style={{top: '20px'}}
            width={'500px'}
            visible={editColor}
            title={i18n.t("选配颜色")}
            okText={i18n.t("确定")}
            cancelText={i18n.t("取消")}
            onCancel={()=>{
              editColorForm.resetFields()
              setEditRgb("")
              setEditColor(false)
            }}
            onOk={async () => {
              const editColor = editColorForm.getFieldsValue();
              if(isEmpty(editColor.rgb) && isEmpty(editColor.colorName)){
                message.error(i18n.t("请选择颜色"))
                return ;
              }
              const item = {
                relationColorId: editColor.relationColorId,
                colorName: editColor.colorName,
                rgb: editColor.rgb,
              }
              add(item)
              setEditColor(false)
            }}
            bodyStyle={{height: "550px",overflow: "scroll"}}
            maskClosable={false}
            destroyOnClose={true}
        >
          <Form form={editColorForm} {...layout}>
            <Form.Item name={"relationColorId"} label={i18n.t("颜色选择")}>
              <DictionarySelects entityName={"dict_sku_color"} filter={" typeId = 1 "} onChange={(colorId)=>{
                axios
                    .post<Result<any[]>>(`/clothes/colorManager/getColorManageById`, {
                      colorId: colorId,
                    })
                    .then((resp:any) => {
                      const data = resp.data.Data;
                      editColorForm.setFieldsValue({
                        colorName:data.name,
                        rgb:data.rgb,
                      })
                      setEditRgb(data.rgb)
                    })
              }}/>
            </Form.Item>
            <Form.Item name={"colorName"} label={i18n.t("颜色命名")}>
              <Input onChange={()=>{
                editColorForm.setFieldsValue({
                  relationColorId:"",
                })
              }}/>
            </Form.Item>
            <Form.Item name={"rgb"} label={i18n.t("颜色")}>
              <Input className={"color-form-rgb-edit"} readOnly style={{background:editRgb}}/>
            </Form.Item>

            <Form.Item colon={false} label=" " name="">
              <SketchPicker color={editRgb} onChange={(color:any)=>{
                setEditRgb(color.hex);
                editColorForm.setFieldsValue({
                  relationColorId:"",
                })
                editColorForm.setFieldsValue({rgb:color.hex})
              }}/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
});
