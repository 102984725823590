import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
import CustomerList from "./screens/CRM/Customer/List";
import LeadList from "./screens/CRM/Lead/List";
import Gonghai from "./screens/CRM/Customer/Gonghai";
import ProjectList from "./screens/CRM/Project/List";
import ProjectDesigner from "./screens/CRM/Project/Designer";
import ChanceList from "./screens/CRM/Chance/List";
import BonusList from "./screens/CRM/Bonus/List";
import WorkflowMonitor from "./screens/Workflow/Monitor";
import FormList from "./screens/CRM/Customer/FormList";
import CustomerContactList from "./screens/CRM/Customer/Contact/List";
import Dictionary from "./screens/RAD/Dictionary";
import BarcodeScanning from "./screens/RAD/BarcodeScanning";
import BarcodeScanningChuku from "./screens/RAD/BarcodeScanning/Chuku";
import ServerScript from "./screens/Workflow/Editor/ServerScript";
import ClientScript from "./screens/Workflow/Editor/ClientScript";
import OrderManage from "../App/screens/Clothes/OrderManage/index";
import Sku from "../App/screens/Clothes/Sku/index";
import TypesOfGoods from "../App/screens/Clothes/Sku/TypesOfGoods/index";
import SkuDetail from "../App/screens/Clothes/Sku/Detail/index";
import OrderPlan from "../App/screens/Clothes/OrderPlan/index";
import Factory from "./screens/Clothes/Factory/index";
import AppEnterprise from "./screens/Clothes/AppEnterprise/index";
import AppEnterpriseMerge from "./screens/Clothes/AppEnterprise/Merge/index";
import AppEnterpriseDetail from "./screens/Clothes/AppEnterprise/Detail/index";
import AppMergeRecords from "./screens/Clothes/AppEnterprise/Merge/records";
import OrderPlanDetail from "../App/screens/Clothes/OrderPlan/Detail/index";
import ColorDetail from '../App/screens/Clothes/ColorManager/Detail/index';
import Color from '../App/screens/Clothes/ColorManager/List/index';
import ColorLibrary from '../App/screens/Clothes/ColorManager/ColorLibrary/index';
import Size from '../App/screens/Clothes/Size/index';
import CraftTemplate from '../App/screens/Clothes/CraftTemplate/index';
import Tax from '../App/screens/Clothes/Tax/index';
import OrderPur from '../App/screens/Clothes/OrderPur/index';
import OrderPurDetail from '../App/screens/Clothes/OrderPur/Detail/index';
import CRM from '../App/screens/Clothes/CRM/index';
import CRMForm from "./screens/Clothes/CRM/Form";
import CRMDetail from "./screens/Clothes/CRM/Detail";
import Price from "./screens/Clothes/Price/index";
import Order from '../App/screens/Clothes/Order/index';
import OrderDetail from '../App/screens/Clothes/Order/Detail/index';
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import "./screens/Clothes/CSS/clothes.css";
import "./screens/Clothes/CSS/antBasicReset.css";
import "./screens/Clothes/CSS/config.css";
import "./screens/Clothes/CSS/BasicData.css";
import {instance as axios} from "../../util/axios";
import storage from "./screens/Clothes/util/Stroage";
import i18n from "i18next";
import moment from "moment";
import ProduceOrder from '../App/screens/Clothes/ProduceOrder/index';
import ProduceOrderDetail from '../App/screens/Clothes/ProduceOrder/Detail/index';
import SystemConfig from "../App/screens/Clothes/PersonalConfig/index"
import WorkingProcedure from "../App/screens/Clothes/WorkingProcedure/index";

import Language from "../App/screens/Clothes/language/home";
import {Spin} from "antd";
import LanguageChange from "./screens/Clothes/language/LanguageChange";
import CompanyInfo from "./screens/Clothes/CompanyInfo";
import Home from "./screens/Clothes/Home/Home";
import Sale from "./screens/Clothes/Home/Sale";
import Product from "./screens/Clothes/Home/Product";
import All from "./screens/Clothes/Home/All";
import OrderAmount1 from "./screens/Clothes/Home/OrderAmount1";
import OrderAmount2 from "./screens/Clothes/Home/OrderAmount2";
import OrderAmount3 from "./screens/Clothes/Home/OrderAmount3";
import OrderAmount5 from "./screens/Clothes/Home/OrderAmount5";
import OrderAmount6 from "./screens/Clothes/Home/OrderAmount6";
import OrderAmount8 from "./screens/Clothes/Home/OrderAmount8";
import OrderAmount7 from "./screens/Clothes/Home/OrderAmount7";
import OrderAmount9 from "./screens/Clothes/Home/OrderAmount9";
import OrderAmount10 from "./screens/Clothes/Home/OrderAmount10";
import OrderAmount11 from "./screens/Clothes/Home/OrderAmount11";
import {isEmpty} from "./screens/Clothes/util/Common";
import {getDateTimeFormat} from "../../util/config";
import Inventory from "./screens/Clothes/Inventory";
import InventoryDetail from "./screens/Clothes/Inventory/Detail";
import Invoice from "./screens/Clothes/Invoice";
import InvoiceDetail from "./screens/Clothes/Invoice/Detail";
import Test from "./screens/Clothes/Sku/Detail/Test";


export const SessionContext = React.createContext({ id: "", permissions: [],corpId:0 });

export default function App() {
  const [session, setSession] = useState({
    id: "",
    permissions: [],
    corpId:0
  });
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    axios.get("/oa/user/").then((resp) => {
      const id = resp.data.Data.userId;
      axios.get("/oa/user/getUserPermission").then((res) => {
        setSession({
          id: id,
          permissions: res.data.Data,
          corpId:resp.data.Data.corpId
        });
        localStorage.setItem("userId", id);
        localStorage.setItem("corpId", resp.data.Data.corpId);
        localStorage.setItem("permissions", res.data.Data);
      });
    });
  }, []);
  const [value, setValue] = useState(false);

  useEffect(()=>{
    var url = window.location.href + "?t=" + moment();
    var lang = storage.get("ClientLanguage");
    var packages = storage.get("languagePackage");
    if(lang == undefined || packages == undefined) {
      var times = setInterval(()=>{
        lang = storage.get("ClientLanguage");
        packages = storage.get("languagePackage");
        if(lang == undefined || packages == undefined){
        }else{
          i18n.changeLanguage(lang);
          i18n.addResourceBundle(lang, 'translation', packages, true, true);
          i18n.reloadResources();
          clearInterval(times);
          setLoadComplete(true);
          window.location.href = url;
        }
      },1000)
    }else{
      setLoadComplete(true);
    }
  },[])

  useEffect(()=>{
    var url = storage.get("reactUrl");
    if(isEmpty(url)){
      axios.get("/oa/homepageTemplate/getReactUrl").then((resp)=>{
        if(resp.data.Status == 1){
          storage.set("reactUrl",resp.data.Data)
        }
      })

    }
  },[])

  // 获取日期格式
  useEffect(()=>{
    if(!localStorage.getItem("format")){
      getDateTimeFormat().then((res) => {
        localStorage.setItem("format", JSON.stringify(res));
      });
    }
  })
  return (
      (loadComplete?
  <SessionContext.Provider value={session}>
      <DndProvider backend={HTML5Backend}>
        <Router>
          <div className={"container"}>
            <div>
              <Switch>
                <Route path="/crm/customer/list">
                  <CustomerList />
                </Route>
                <Route path="/crm/lead/list">
                  <LeadList />
                </Route>
                <Route path="/crm/customer/gonghai/list">
                  <Gonghai />
                </Route>
                <Route path="/crm/bonus/list">
                  <BonusList />
                </Route>
                <Route path="/crm/project/designer">
                  <ProjectDesigner />
                </Route>
                <Route path="/crm/project/list">
                  <ProjectList />
                </Route>
                <Route path="/crm/chance/list">
                  <ChanceList />
                </Route>
                <Route path="/workflow/monitor">
                  <WorkflowMonitor />
                </Route>
                <Route path="/crm/customer/form/list">
                  <FormList />
                </Route>
                <Route path="/crm/customer/contact/list">
                  <CustomerContactList />
                </Route>
                <Route path="/rad/dictionary">
                  <Dictionary />
                </Route>
                <Route path="/rad/barcode/scanning" exact>
                  <BarcodeScanning />
                </Route>
                <Route path="/rad/barcode/scanning/chuku/:uuid/:orderId">
                  <BarcodeScanningChuku />
                </Route>
                <Route path="/workflow/editor/script/server">
                  <ServerScript />
                </Route>
                <Route path="/workflow/editor/script/client">
                  <ClientScript />
                </Route>
                <Route path="/clothes/orderManage">
                  <OrderManage />
                </Route>
                <Route path="/clothes/orderPlan">
                  <OrderPlan />
                </Route>
                <Route path="/clothes/orderPlanDetail/:orderId/:serialNumber/:customerId">
                  <OrderPlanDetail orderId={""} serialNumber={""} customerId={""} orderPlanId={""}/>
                </Route>
                <Route path="/sku/skuManage/:property">
                  <Sku />
                </Route>
                <Route path="/clothes/sku/typesOfGoods">
                  <TypesOfGoods />
                </Route>
                <Route path="/sku/skuManageDetail/:uuid/:type/:property/">
                  <SkuDetail />
                </Route>
                <Route path="/clothes/factory">
                  <Factory />
                </Route>
                <Route path="/clothes/appEnterprise/list">
                  <AppEnterprise />
                </Route>
                {/*<Route path="/clothes/appEnterprise/merge/:enterpriseId">*/}
                {/*  <AppEnterpriseMerge enterpriseIdd={""}/>*/}
                {/*</Route>*/}
                <Route path="/clothes/appEnterprise/mergeRecords">
                  <AppMergeRecords />
                </Route>
                {/*<Route path="/clothes/appEnterprise/detail/:enterpriseId/:type/:id">*/}
                {/*  <AppEnterpriseDetail />*/}
                {/*</Route>*/}
                <Route path="/clothes/colorManager/list">
                  <Color />
                </Route>
                <Route path="/clothes/colorManager/colorLibrary">
                  <ColorLibrary />
                </Route>
                <Route path="/clothes/colorManager/detail/:uuid/:type/:isHomePage/:categoryId">
                  <ColorDetail />
                </Route>
                <Route path="/clothes/CRM/:customerType">
                  <CRM />
                </Route>
                <Route path="/clothes/CRMForm/:uuid/:customerType">
                  <CRMForm />
                </Route>
                <Route path="/clothes/CRMDetail/:uuid/:customerType">
                  <CRMDetail />
                </Route>
                <Route path="/clothes/language">
                  <Language />
                </Route>
                <Route path="/clothes/sizeManage">
                  <Size />
                </Route>
                <Route path="/clothes/taxManage">
                  <Tax />
                </Route>
                <Route path="/clothes/price/list">
                  <Price />
                </Route>
                <Route path="/clothes/produceOrder">
                  <ProduceOrder />
                </Route>
                <Route path="/clothes/produceOrderDetail">
                  <ProduceOrderDetail />
                </Route>

                <Route path="/clothes/craftTemplate">
                  <CraftTemplate />
                </Route>

              <Route path="/clothes/order">
                <Order />
              </Route>
              <Route path="/clothes/orderDetail/:uuid">
                <OrderDetail />
              </Route>

                <Route path="/clothes/orderPur">
                  <OrderPur />
                </Route>
                <Route path="/clothes/orderPurDetail/:uuid/:type">
                  <OrderPurDetail />
                </Route>
                <Route path="/clothes/system/config">
                  <SystemConfig />
                </Route>
                <Route path="/clothes/workingprocedure">
                  <WorkingProcedure />
                </Route>

                <Route path={"/clothes/languagechange/:language"}>
                  <LanguageChange/>
                </Route>
                {/*公司配置*/}
                <Route path="/clothes/companyInfo">
                  <CompanyInfo />
                </Route>
                {/*首页*/}
                <Route path="/clothes/home">
                  <Home />
                </Route>
                {/*销售统计*/}
                <Route path="/clothes/statistic/sale">
                  <Sale />
                </Route>
                {/*生产统计*/}
                <Route path="/clothes/statistic/product">
                  <Product />
                </Route>
                {/*总览*/}
                <Route path="/clothes/statistic/all">
                  <All />
                </Route>
                <Route path="/clothes/statistic/orderAmount1">
                  <OrderAmount1 />
                </Route>
                <Route path="/clothes/statistic/orderAmount2">
                  <OrderAmount2 />
                </Route>
                <Route path="/clothes/statistic/orderAmount4">
                  <OrderAmount3 />
                </Route>
                <Route path="/clothes/statistic/orderAmount5">
                  <OrderAmount5 />
                </Route>
                <Route path="/clothes/statistic/orderAmount6">
                  <OrderAmount6 />
                </Route>
                <Route path="/clothes/statistic/orderAmount7">
                  <OrderAmount7 />
                </Route>
                <Route path="/clothes/statistic/orderAmount8">
                  <OrderAmount8/>
                </Route>
                <Route path="/clothes/statistic/orderAmount9">
                  <OrderAmount9 />
                </Route>
                <Route path="/clothes/statistic/orderAmount10">
                  <OrderAmount10/>
                </Route>
                <Route path="/clothes/statistic/orderAmount11">
                  <OrderAmount11/>
                </Route>
                <Route path="/clothes/inventory">
                  <Inventory/>
                </Route>
                <Route path="/clothes/inventoryDetail">
                  <InventoryDetail/>
                </Route>
                <Route path="/clothes/invoice">
                  <Invoice/>
                </Route>
                <Route path="/clothes/invoiceDetail/:uuid">
                  <InvoiceDetail/>
                </Route>
                <Route path="/clothes/test">
                  <Test/>
                </Route>
              </Switch>
            </div>
          </div>
        </Router>
      </DndProvider>
    </SessionContext.Provider> :
              <div style={{textAlign:"center",marginTop:"300px"}}><Spin tip="Loading...">
              </Spin></div>
      )
  )
};
