import React, { useRef } from "react";
import { Tabs, Button, message, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DataGrid } from "../../../../../components/DataGrid";
import { instance as axios } from "../../../../../util/axios";
import i18n from "i18next";

const { TabPane } = Tabs;

const Dictionary = function () {
  const ccGridRef = useRef<any>(null);
  const csGridRef = useRef<any>(null);
  const cGridRef = useRef<any>(null);
  const colorGridRef = useRef<any>(null);
  const fmGridRef = useRef<any>(null);

  function getRender(entityName: string, gridRef: React.MutableRefObject<any>) {
    return function (_: any, record: any) {
      return (
        <>
          {gridRef.current.isEditing(record) ? (
            <>
              <a
                style={{ marginRight: 16 }}
                onClick={async () => {
                  if (record.key) {
                    await gridRef.current.save(record.key);
                    const data = gridRef.current.getDataItem(record.key);
                    const url = `/erp/dictConfig/saveDictItemList?tableName=${entityName}`;
                    axios
                      .post(url, { data: JSON.stringify([data]) })
                      .then(() => {
                        message.info("保存成功");
                      });
                  } else {
                    await gridRef.current.save(record.key);
                    const data = gridRef.current.getDataItem(record.key);
                    const url = `/erp/dictConfig/saveDictItemList?tableName=${entityName}`;
                    await axios
                      .post(url, { data: JSON.stringify([data]) })
                      .then(() => {
                        message.info("保存成功");
                      });
                    gridRef.current.refresh();
                  }
                }}
              >
                {i18n.t("确认")}
              </a>
              {!record.key ? (
                <a onClick={() => gridRef.current.remove(record.key)}>撤销</a>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {gridRef.current.isOnEdit() ? (
                <>
                  <a style={{ marginRight: 16, color: "gray" }}>编辑</a>
                  <a style={{ color: "gray" }}>删除</a>
                </>
              ) : (
                <>
                  <a
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      gridRef.current.edit(record);
                    }}
                  >
                    编辑
                  </a>
                  <Popconfirm
                    title="确定要删除吗?"
                    onConfirm={() => {
                      const url = `/crm/dictConfig/deleteDictItem?uuid=${record.key}&tableName=${entityName}`;
                      axios.delete(url).then(() => {
                        message.info("删除成功");
                        gridRef.current.refresh();
                      });
                    }}
                  >
                    <a>删除</a>
                  </Popconfirm>
                </>
              )}
            </>
          )}
        </>
      );
    };
  }

  function getTabPane(
    tabName: string,
    entityName: string,
    gridRef: React.MutableRefObject<any>
  ) {
    return (
      <TabPane tab={tabName} key={entityName}>
        <DataGrid
          style={{ marginBottom: 8 }}
          pagination={false}
          dragable={true}
          ref={gridRef}
          sorter={{ field: "sort", order: "ascend" }}
          onDrop={() => {
            const uuids = gridRef.current
              .getData()
              .map((item: any) => item.uuid)
              .join(",");
            axios
              .post(`/erp/dictConfig/sort`, {
                tableName: entityName,
                uuids,
              })
              .then(() => {
                message.success("操作成功");
                gridRef.current.refresh();
              });
          }}
          descriptions={[
            {
              fieldName: "sort",
              fieldShowName: "排序",
              width: 100,
            },
            {
              fieldName: "name",
              fieldShowName: "名称",
              editable: true,
            },
            {
              fieldShowName: "操作",
              render: getRender(entityName, gridRef),
            },
          ]}
          url={`/erp/dictConfig/getDictItemList?tableName=${entityName}`}
        />
        <Button
          icon={<PlusOutlined />}
          type={"primary"}
          onClick={() => gridRef.current.add()}
        >
          新建
        </Button>
      </TabPane>
    );
  }

  return (
    <Tabs>
      {getTabPane("高度分类", "dict_rad_center_category", ccGridRef)}
      {getTabPane("高度", "dict_rad_center_spacing", csGridRef)}
      {getTabPane("接口口径", "dict_rad_caliber", cGridRef)}
      {getTabPane("颜色", "dict_rad_color", colorGridRef)}
      {getTabPane("进水方式", "dict_rad_flowin_method", fmGridRef)}
    </Tabs>
  );
};
export default Dictionary;
