import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { Result, GongHaiTabConfig } from "../../../../../../schemas/common";
import { TabContent } from "./TabContent";

const { TabPane } = Tabs;

export default function () {
  const session: Session = useContext(SessionContext);
  const [dynamicTabConfigs, setDynamicTabConfigs] = useState<
    GongHaiTabConfig[]
  >([]);

  useEffect(function () {
    axios
      .get<Result<GongHaiTabConfig[]>>("/crm/customer/gongHai/getPublicLibrary")
      .then((resp) => {
        setDynamicTabConfigs(resp.data.Data);
      });
  }, []);

  return (
    <>
      <Tabs>
        {dynamicTabConfigs.map((tab) => (
          <TabPane tab={tab.name} key={tab.uuid}>
            <TabContent
              key={tab.uuid}
              uuid={tab.uuid}
              claimable={tab.extractRule === "1"}
              assignable={session.id === tab.administrators}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
}
