import React, {useEffect, useState} from "react";
import { Tabs } from "antd";

import TabA from "./TabA";
import TabB from "./TabB";
import TabC from "./TabC";
import TabD from "./TabD";
import TabE from "./TabE";
import TabF from "./TabF";
import { useParams } from "react-router";
import i18n from "i18next";
import ChangeHistory from "./ChangeHistory";

const { TabPane } = Tabs;

export interface Params {
  uuid: string;
  customerType: string;
}
export default () => {
  const params = useParams<Params>();
  const uuid = params.uuid;
  const customerType = params.customerType;
  const callbackTabs = (key: any) => {
    console.log(key);
  };
  useEffect(function () {
    console.log("customerType:",customerType);
    if(customerType==="5"){
    }
  })

  return (
      <div className="u-box u-basic-page u-MaterialArchivesForm">
        <div className="u-box-head u-box-head-border">
          <span className="u-box-title">{i18n.t("编辑客户")}</span>
        </div>
        <div className="u-box-con u-fixed-padding">
          <div className="u-tabsA">
            <div className="u-form-lr">
              <Tabs defaultActiveKey={"1"} onChange={callbackTabs}>
                <TabPane tab={i18n.t("客户信息")} key="1">
                  <TabA customerId={uuid} customerType={customerType}/>
                </TabPane>
                {
                  //4：客户
                  customerType == "4" ? <TabPane tab={i18n.t("品牌管理")} key="2">
                    <TabB customerId={uuid} customerType={customerType}/>
                  </TabPane> : <></>
                }

                <TabPane tab={i18n.t("付款信息")} key="3">
                  <TabC customerId={uuid} customerType={customerType}/>
                </TabPane>
                <TabPane tab={i18n.t("联系信息")} key="4">
                  <TabD customerId={uuid} customerType={customerType}/>
                </TabPane>
                <TabPane tab={i18n.t("收货地址")} key="5">
                  <TabE customerId={uuid} customerType={customerType}/>
                </TabPane>
                {
                  //5：加工厂
                  customerType == "5" ? <TabPane tab={i18n.t("工序")} key="2">
                    <TabF customerId={uuid} customerType={customerType}/>
                  </TabPane> : <></>
                }
                {
                  //5：加工厂
                  customerType == "3" ? <TabPane tab={i18n.t("历史记录")} key="6">
                    <ChangeHistory customerId={uuid} changeType={"1"}/>
                  </TabPane> : <></>
                }
              </Tabs>
            </div>
          </div>
        </div>
      </div>
  );
};
