import React, { useState, useEffect } from "react";
import { getTree } from "../../../../../../util/ant/tree";
import { instance as axios } from "../../../../../../util/axios";
import { Tree, message, Modal } from "antd";
export interface TransferProps {
  visible: boolean;
  customerIds: string[];
  onOk: () => void;
  onCancel: () => void;
}
export const CustomerTransferDialog = function ({
  customerIds,
  onCancel,
  onOk,
  visible,
}: TransferProps) {
  const [treeData, setTreeData] = useState([]);
  const [selectedTreeId, setSelectedTreeId] = useState("");
  useEffect(
    function () {
      if (visible) {
        getTree("crm_customer_category").then((result) => setTreeData(result));
      }
    },
    [visible]
  );

  return (
    <>
      <Modal
        title="转移类别"
        width={400}
        visible={visible}
        onCancel={() => {
          if (onCancel) {
            onCancel();
          }
        }}
        onOk={() => {
          axios
            .post("/crm/customer/transferCustomerToGroup", {
              customerIds: customerIds.join(","),
              groupId: selectedTreeId,
            })
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("转移成功");
                if (onOk) {
                  onOk();
                }
              }
            });
        }}
      >
        {treeData.length > 0 && (
          <Tree
            treeData={treeData}
            onSelect={(keys: any) => {
              setSelectedTreeId(keys[0]);
            }}
            defaultExpandAll={true}
          />
        )}
      </Modal>
    </>
  );
};
