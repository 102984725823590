import React from 'react'
import USwiper from './uSwiper'

interface Param {
	createTime:any
}
export default ({createTime}:Param) =>  {
    return (
		<div className="invoice">
			<USwiper createTime={createTime}/>
		</div>
    )
}