import React, {useEffect, useRef, useState} from "react";
import {Button, PageHeader, Tabs} from "antd";
import i18n from "i18next";
import TabPane from "@ant-design/pro-card/es/components/TabPane";
import {DataGrid} from "../../../../../components/DataGrid";
import {createNewTab} from "../../../../../util/tyy";
import storage from "../util/Stroage";
import {useWindowSize} from "react-use";
import {inputType} from "../../../../../constants/inputType";

export default ()=>{
    const thisGridRef = useRef<any>();
    const thisGridRef1 = useRef<any>();
    const { height } = useWindowSize();

    return (
        <div>
            <PageHeader
                title={i18n.t("发货单管理")}

            />
            <Tabs defaultActiveKey="1" >
                <TabPane tab={i18n.t("发货单列表")} key="1">
                    <DataGrid
                        ref={thisGridRef}
                        pagination={true}
                        sorter={{field: " createTime", order: "descend"}}
                        pageSize={Math.floor((height - 280) / 42)}
                        rowSelectionType="checkbox"
                        descriptions={[
                            {
                                fieldShowName: i18n.t("发货单号",),
                                fieldName:"serialNumber",
                                render:(text:any,record:any)=>{
                                    return(
                                        <>
                                            <a onClick={()=>{
                                                createNewTab(storage.get("reactUrl") + "/clothes/invoiceDetail/" + record.docId,i18n.t('发货单详情',));
                                            }}>{text}</a>
                                        </>
                                    )
                                }
                            },
                            {
                                fieldShowName: i18n.t("关联订单号",),
                                fieldName:"orderNumber",
                                render:(text:any,record:any)=>{
                                    return(
                                        <>
                                            <a onClick={()=>{
                                                createNewTab(storage.get("reactUrl") + "/clothes/invoiceDetail/" + record.docId,i18n.t('发货单详情',));
                                            }}>{text}</a>
                                        </>
                                    )
                                }
                            },
                            {
                                fieldShowName: i18n.t("发货时间",),
                                fieldName:"deliveryTime"
                            },
                            {
                                fieldShowName: i18n.t("发货人",),
                                fieldName:"sender",
                                inputType:inputType.DICTIONARY,
                                dictionary:"base_staff"
                            },
                            {
                                fieldShowName: i18n.t("发货总数量",),
                                fieldName:"total"
                            },
                            {
                                fieldShowName: i18n.t("发货总金额",),
                                fieldName:"amount"
                            },
                            {
                                fieldShowName: i18n.t("客户名称",),
                                fieldName:"customerId",
                                inputType:inputType.DICTIONARY,
                                dictionary:"clothes_customer"
                            },
                        ]}
                        url={"/clothes/invoice/getInvoiceList"}
                        isShowExport={true}
                    />

                </TabPane>
                <TabPane tab={i18n.t("发货单详情")} key="2">
                    <DataGrid
                        ref={thisGridRef1}
                        pagination={true}
                        sorter={{field: "createTime", order: "descend"}}
                        pageSize={Math.floor((height - 280) / 42)}
                        rowSelectionType="checkbox"
                        descriptions={[
                            {
                                fieldShowName: i18n.t("发货单号",),
                                fieldName:"serialNumber",
                                render:(text:any,record:any)=>{
                                    return(
                                        <>
                                            <a onClick={()=>{

                                            }}>{text}</a>
                                        </>
                                    )
                                }
                            },
                            {
                                fieldShowName: i18n.t("关联订单号",),
                                fieldName:"orderNumber",
                                render:(text:any,record:any)=>{
                                    return(
                                        <>
                                            <a onClick={()=>{

                                            }}>{text}</a>
                                        </>
                                    )
                                }
                            },
                            {
                                fieldShowName: i18n.t("发货时间",),
                                fieldName:"deliveryTime"
                            },
                            {
                                fieldShowName: i18n.t("发货人",),
                                fieldName:"sender",
                                inputType:inputType.DICTIONARY,
                                dictionary:"base_staff"
                            },
                            {
                                fieldShowName: i18n.t("商品编码",),
                                fieldName:"skuId",
                                inputType:inputType.DICTIONARY,
                                dictionary:"clothes_sku"
                            },
                            {
                                fieldShowName: i18n.t("商品简介",),
                                fieldName:"skuId",
                                inputType:inputType.DICTIONARY,
                                dictionary:"dict_sku_code"
                            },
                            {
                                fieldShowName: i18n.t("数量",),
                                fieldName:"total"
                            },
                            {
                                fieldShowName: i18n.t("金额",),
                                fieldName:"amount"
                            },
                            {
                                fieldShowName: i18n.t("客户名称",),
                                fieldName:"customerId",
                                inputType:inputType.DICTIONARY,
                                dictionary:"clothes_customer"
                            },
                        ]}
                        url={"/clothes/invoice/getInvoiceDetailList"}
                        isShowExport={true}
                    />
                </TabPane>
            </Tabs>

        </div>
    );
}