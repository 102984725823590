import React, {CSSProperties, useContext, useEffect, useState} from "react";
import { Divider, Input, message, Select } from "antd";
import { Dictionary } from "../../schemas/dictionary";
import { cacheableInstance as axios } from "../../util/axios";
import { Pager } from "../DataGrid/schemas";
import { BaseURL } from "../../constants/url";
import { Result } from "../../schemas/common";
import { useUpdateEffect } from "react-use";
import i18n from "i18next";
import { PlusOutlined } from "@ant-design/icons/lib";
import { isEmpty } from "../../containers/App/screens/Clothes/util/Common";
import { DictionaryModel } from "../../schemas/dictionaryModel";
import Session from "../../schemas/session";
import {SessionContext} from "../../containers/App";

function mapper(item: any) {
  var baseItem = {
    text: item.name,
    value: item.uuid,
  };
  return Object.assign(baseItem, item);
}
export interface DictionarySelectProps {
  onChange?: (value: any, option: any) => void;
  onFocus?: (value: any) => void;
  entityName?: string;
  style?: CSSProperties;
  value?: string | string[];
  size?: "small" | "middle" | "large";
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  textIndex?: string | null;
  addAble?: boolean;
}

const initialPagination = { pageIndex: 0, pageSize: 10 };
const MaterialSelect = ({
  entityName,
  onChange,
  onFocus,
  placeholder,
  size,
  style,
  value,
  multiple,
  disabled,
  textIndex="name,skuCode",
  addAble = false,
}: DictionarySelectProps) => {
  const { Option } = Select;
  const [dataSource, setDataSource] = useState<Dictionary[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [pagination, setPagination] = useState<Pager>(initialPagination);
  const [addItem, setAddItem] = useState("");

  const session: Session = useContext(SessionContext);

  async function getData(
      entityName = "inventory_sku",
      keyword: string | null,
      selectId: string | string[] | null,
      pagination: Pager | null,
      textIndex: string | null
  ): Promise<DictionaryModel[]> {
    const url = `${BaseURL}/clothes/common/CommonApi/GetZLSelectDict`;
    const queryString = `pageIndex=${
        pagination ? pagination.pageIndex : ""
    }&pageSize=${
        pagination ? pagination.pageSize : ""
    }&tableName=${entityName}&key=${keyword || ""}&selectId=${
        typeof selectId === "string"
            ? selectId
            : selectId instanceof Array
            ? selectId.join(",")
            : ""
    }&filter=${selectId ? "setValue" : session.corpId==2000?"status <> 0":"status <> 0 and enterpriseId="+session.corpId}&colName=${textIndex || ""}`;
    return (
        await axios.get<Result<DictionaryModel[]>>(`${url}?${queryString}`)
    ).data.Data.map(mapper);
  }

  var timer: any;
  // init
  useEffect(() => {
    getData(entityName, null, null, initialPagination, textIndex || null).then(
      (resp) => {
        if (value) {
          getData(entityName, null, value, null, textIndex || null).then(
            (valueResp) => {
              const result = [...resp];
              valueResp.forEach((i) => {
                if (!resp.some((j) => j.value === i.value)) {
                  result.push(i);
                }
              });
              setDataSource(result);
            }
          );
        } else {
          setDataSource(resp);
        }
      }
    );
  }, [entityName, textIndex, value,session]);
  // on scroll
  useUpdateEffect(() => {
    (async function () {
      const resp = await getData(
        entityName,
        keyword,
        null,
        pagination,
        textIndex || null
      );
      setDataSource((prev: Dictionary[]) => {
        const result = [...prev];
        resp.forEach((i) => {
          if (!prev.some((j) => j.value === i.value)) {
            result.push(i);
          }
        });
        return result;
      });
    })();
  }, [pagination]);
  // on search
  useUpdateEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: 0,
    }));
  }, [keyword]);

  return (
    <>
      <Select
        style={style}
        disabled={disabled || false}
        placeholder={placeholder || i18n.t("请选择")}
        showSearch
        dropdownMatchSelectWidth={600}
        size={size || "middle"}
        mode={multiple ? "multiple" : undefined}
        onPopupScroll={(event: any) => {
          event.persist();
          const { target } = event;
          var currentHeight = target.scrollTop + target.offsetHeight;
          console.log(target.scrollHeight - currentHeight);
          if (target.scrollHeight - currentHeight <= 100) {
            setPagination((prev) => {
              let current = Object.assign({}, prev);
              current.pageIndex++;
              return current;
            });
          }
        }}
        onChange={onChange}
        onFocus={onFocus}
        filterOption={false}
        value={value}
        onSearch={(val) => {
          clearInterval(timer);
          timer = setTimeout(() => {
            setKeyword(val);
            setDataSource([]);
            setPagination({ pageIndex: 0, pageSize: 10 });
          }, 500);
        }}
        dropdownRender={
          addAble
            ? (menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      style={{ flex: "auto" }}
                      value={addItem}
                      onChange={(value) => {
                        setAddItem(value.target.value);
                      }}
                    />
                    <a
                      style={{
                        flex: "none",
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (isEmpty(addItem)) {
                          message.error(i18n.t("public_emptyContent"));
                          return;
                        }
                        console.log(addItem);
                        var newItemList = [];
                        var newItem: any = {};
                        newItem.isNew = true;
                        newItem.hasChanges = true;
                        newItem.name = addItem;
                        newItemList.push(newItem);
                        axios
                          .post(
                            "crm/systemConfig/fieldDescription/saveDictionaryList?tableName=" +
                              entityName,
                            { data: JSON.stringify(newItemList) }
                          )
                          .then((resp) => {
                            if (resp.data.Status == 1) {
                              message.success(i18n.t("public_addSuccess"));
                              var any: any = undefined;
                              setPagination(any);
                              setPagination({ pageIndex: 0, pageSize: 10 });
                            }
                          });
                        setAddItem("");
                      }}
                    >
                      <PlusOutlined /> Add item
                    </a>
                  </div>
                </div>
              )
            : undefined
        }
        optionLabelProp={"label"}
      >
        {dataSource.map((item: DictionaryModel) => {
          return (
            <Option value={item.value} key={item.value} label={item.text}>
              <div
                style={{ padding: "5px", borderBottom: "1px solid #f3f3f3" }}
              >
                <div
                  style={{
                    height: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "4px",
                    lineHeight: "20px",
                    fontSize: "15px",
                  }}
                >
                  <span style={{ fontFamily: "Arial",fontWeight:500 }}> {item.text}</span>
                  <span>{item.specs}</span>
                </div>
                <div
                  style={{
                    color: "#777",
                  }}
                >
                  <span
                    style={{
                      marginRight: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {i18n.t("public_skuCode")}: {item.skuCode}
                  </span>
                  <span
                    style={{
                      marginRight: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {i18n.t("price")}: {item.price}
                  </span>
                </div>
              </div>
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default MaterialSelect;
