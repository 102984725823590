import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, message, Modal, Space, Switch } from "antd";
import { get, getNameSpaceDescriptions } from "../../../../../util/description";
import { FieldDescription } from "../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../schemas/description";
import { useUpdateEffect } from "react-use";
import { addLine, editOtherLine } from "../components/EditForm";
import { instance as axios } from "../../../../../util/axios";
import { Result } from "../../../../../schemas/common";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import {DictionarySelect} from "../../../../../components/DictionarySelect";

export default () => {
  const dataGridRef = useRef<any>(null);
  const [namespace, setNamespace] = useState("clothes_factory.all");
  const [entityName, setEntityName] = useState("clothes_factory");
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  const confirm = useCallback(function confirm(uuid: string) {
    Modal.confirm({
      title: i18n.t("删除操作",),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("确定删除吗",),
      okText: i18n.t("确定",),
      cancelText: i18n.t("取消",),
      onOk: function () {
        const url =
          `/clothes/notAppFactory/deleteFactoryById?factoryId=` + uuid;
        axios.post(url).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("删除成功"));
            dataGridRef.current.refresh();
          } else {
            message.info(i18n.t("删除失败"));
          }
        });
      },
    });
  }, []);
  function getRender(gridRef: React.MutableRefObject<any>) {
    return function (_: any, record: any) {
      return (
          <>
            {gridRef.current.isEditing(record) ? (
                <>
                  <a
                      href="#"
                      className="deleteA"
                      onClick={async (e) => {
                        await e.stopPropagation();
                        await gridRef.current.save(record.key);
                        const data = gridRef.current.getDataItem(record.key);
                        let stepId = data.stepId;
                        if (data.stepId != undefined && (typeof data.stepId != "string")) {
                          data.stepId = stepId.join(",");
                        }
                        await axios
                            .post<Result<any[]>>(`/clothes/notAppFactory/saveFactory`, {
                              data: JSON.stringify(data),
                            })
                            .then((resp) => {
                              if (resp.data.Status === "1") {
                                message.info(i18n.t("保存成功"));
                                gridRef.current.refresh();
                              }
                            });
                      }}
                  >
                    {i18n.t("确认")}
                  </a>
                  {!record.uuid ? (
                      <a
                          href="#"
                          className="deleteB"
                          onClick={(e) => {
                            e.stopPropagation();
                            gridRef.current.remove(record.key);
                          }}
                      >
                        {i18n.t("撤销")}
                      </a>
                  ) : (
                      <></>
                  )}
                </>
            ) : (
                <>
                  <a
                      href="#"
                      className="deleteA"
                      onClick={(e: any) => {
                        confirm(record.uuid);
                        e.stopPropagation();
                      }}
                  >
                    {i18n.t("删除")}
                  </a>
                </>
            )}
          </>
      );
    };
  }

  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
        (nameSpaceDescriptions: any) => {
          if (nameSpaceDescriptions == null) {
            setCurrentDescriptions(descriptions);
          }
        }
      );
    });
  }, []);
  const [tableForm] = Form.useForm();
  const handlerSwitch = (checked: boolean, record: any, e: Event) => {
    let sta = checked === true ? 1 : 0;
    axios // 保存操作
      .post("/clothes/notAppFactory/updateStatusById", {
        factoryId: record.uuid,
        status: sta,
      })
      .then((resp) => {
        message.success("SUCCESS");
      })
      .catch((error) => {
        message.success("FAILD");
      });
    e.stopPropagation();
  };
  function format() {
    const formattedCurrentDescriptions = JSON.parse(
      JSON.stringify(currentDescriptions)
    );
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: getRender(dataGridRef),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      if (description.fieldName == "status") {
        description.render = (fieldName: any, record: any) => {
          return (
            <Switch
              size="default"
              checkedChildren={i18n.t("color_manage_activated")}
              unCheckedChildren={i18n.t("color_manage_not_activated")}
              style={{ width: 65 }}
              defaultChecked={record.status == 0 ? false : true}
              onChange={(checked, e:any) => handlerSwitch(checked, record, e)}
            />
          );
        };
      } else {
        description.editable = true;
      }
    });
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  return (
      <>
        <div className="u-box u-basic-page">
          <div className="u-box-head-factory">
          <span className="u-box-title" onClick={(e) => e.preventDefault()}>
            {i18n.t("工厂管理")}
          </span>
          </div>
        </div>
        <div className="u-form">
          <DataGrid
              ref={dataGridRef}
              url={"/clothes/notAppFactory/getNotAppFactoryManageList"}
              descriptions={format()}
              pagination={true}
              onClick={async (record: any) => {
                await editOtherLine(dataGridRef, record);
              }}
              loadAllDictionary={true}
              isShowSearch={true}
              form={tableForm}
          />
          <div style={{paddingBottom: 25}}>
            <Space>
              <Button
                  style={{width: 100}}
                  onClick={async () => {
                    await addLine(dataGridRef);
                  }}
              >
                {i18n.t("add")}
              </Button>
            </Space>
          </div>
        </div>
      </>
  );
};
