import React, {useEffect, useState} from 'react'
import { Table, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import {
  DoubleRightOutlined
} from '@ant-design/icons'
import {instance as axios} from "../../../../../util/axios";
import i18n from "i18next";

const columns = [
		{ title: 'user', dataIndex: 'unlockStaffAccount', key: '1' ,
		render: (unlockStaffAccount: any) => (
			<>
			<div className="u-con-l">
				<Avatar src={unlockStaffAccount} />
			</div>
			<div className="u-con-r">
				<p style={{padding: '5px 0 0 0'}}>{unlockStaffAccount} </p>
			</div>
			</>
		)
		},
		{ title: 'unlockTime', dataIndex: 'unlockTime', key: '2' },
		{ title: 'lockNo', dataIndex: 'lockNo', key: '3',
		}
	]

interface Param {
	createTime:any;
}

export default ({createTime}:Param) =>  {
	const [data,setData] = useState([]);
	useEffect(function () {
		axios.get("/inventory/openRecord/getOpenRecordList?createTimeStr="+createTime)
			.then(function (resp) {
				var res = resp.data;
				if(res.Status == 1){
					setData(resp.data.Data);
				}
			})

	},[createTime])
	// for (let i = 0; i < 5; ++i) {
	//   data.push({
	// 	key: i,
	// 	name: '2738839292',
	// 	time: '2020-03-03 12:23',
	// 	user: ['张三', 'images/logo.png']
	//   })
	// }
    return (
		<div className="u-collapseA invoice">
			<Table
				showHeader={false}
				columns={columns} 
				dataSource={data} 
				pagination={false}
			/>
		</div>
    )
}