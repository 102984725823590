import React, { useState, useEffect, Ref } from "react";
import { Dropdown, Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { get, getByCustomerCategoryId } from "../../../../../util/description";
import { FieldDescription } from "../../../../../schemas/fieldDescriptions";
import GridColumnCustomizerDialog from "../../../../../components/GridColumnCustomizerDialog";
import { instance as axios } from "../../../../../util/axios";
import useForceUpdate from "../../../../../hooks/useForceUpdate";
import i18next from "i18next";
import {UploadOutlined} from "@ant-design/icons/lib";

export interface MoreItemParam {
  descriptions: FieldDescription[];
  namespace: string;
  onOk?: (currentDescriptions: FieldDescription[]) => void;
  dataGridRef: any;
}

const DropdownList = function ({
  descriptions,
  namespace,
  onOk,
  dataGridRef,
}: MoreItemParam) {
  const [
    gridColumnCustomizerDialogVisible,
    setGridColumnCustomizerDialogVisible,
  ] = useState(false);

  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);
  const [categoryDescriptions, setCategoryDescriptions] = useState<
    FieldDescription[]
  >([]);
  // const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(function () {
    getNameSpaceDescriptions(namespace, descriptions);
  }, []);
  const forceUpdate = useForceUpdate();

  /**
   * 根据自定义列设置，更新显示列
   */
  function getCurrentDescriptions(
    previousDescriptions: FieldDescription[],
    namespaceDescriptions: any,
    onSuccess?: any
  ) {
    const copy = JSON.parse(JSON.stringify(previousDescriptions));
    let newDescriptions: FieldDescription[] = [];
    namespaceDescriptions
      .filter((item: any) => item.displayToList)
      .forEach((item: any) => {
        newDescriptions = [
          ...newDescriptions,
          ...copy.filter(
            (copyItem: any) => copyItem.fieldName === item.fieldName
          ),
        ];
      });
    // setCurrentDescriptions(newDescriptions);
    if (onSuccess != undefined) {
      onSuccess(newDescriptions);
    }
  }

  function getNameSpaceDescriptions(
    space: string,
    previousDescriptions: FieldDescription[]
  ) {
    axios
      .post("/oa/list/description/getListDescription", {
        namespace: space,
      })
      .then((resp) => {
        if (resp.data.Status === "1" && resp.data.Data) {
          getCurrentDescriptions(
            previousDescriptions,
            JSON.parse(resp.data.Data)
          );
        } else {
          setCurrentDescriptions(previousDescriptions);
        }
      });
  }

  function handleTabBarExtraMenuClick(e: any) {
    switch (e.key) {
      case "column":
        setCategoryDescriptions(descriptions);
        setGridColumnCustomizerDialogVisible(true);
        break;
      case "export":
        dataGridRef.current.export();
        break;
    }

  }

  return (
    <>
      {/*<Dropdown*/}
      {/*  overlay={*/}
      {/*    <Menu onClick={handleTabBarExtraMenuClick}>*/}
      {/*      <Menu.Item key={"column"}>*/}
      {/*        {i18next.t("public_columnDisplay")}*/}
      {/*      </Menu.Item>*/}
      {/*      <Menu.Item key={"export"}>{i18next.t("export")}</Menu.Item>*/}
      {/*    </Menu>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <UnorderedListOutlined />*/}
      {/*</Dropdown>*/}

      <Dropdown
        overlay={
          <Menu onClick={handleTabBarExtraMenuClick}>
            <Menu.Item key={"column"}>
              <UnorderedListOutlined />{i18next.t("public_columnDisplay")}
            </Menu.Item>
            <Menu.Item key={"export"}><UploadOutlined />{i18next.t("export")}</Menu.Item>
          </Menu>
        }
      >
        <span>
          <UnorderedListOutlined />
        </span>
      </Dropdown>

      <GridColumnCustomizerDialog
        onOk={(result) => {
          setGridColumnCustomizerDialogVisible(false);
          getCurrentDescriptions(categoryDescriptions, result, onOk);
        }}
        onCancel={() => setGridColumnCustomizerDialogVisible(false)}
        namespace={namespace}
        descriptions={categoryDescriptions}
        visible={gridColumnCustomizerDialogVisible}
      />
    </>
  );
};

export default DropdownList;
