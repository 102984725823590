import React, { useRef, useState } from "react";
import { DataGrid } from "../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { Space, Button, message, Modal } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../util/axios";
import { Result } from "../../../../schemas/common";
import { Description } from "../../../../schemas/description";
import { createNewTab } from "../../../../util/tyy";

export default function () {
  const gridRef = useRef<any>();
  const exportGridRef = useRef<any>();
  const { height } = useWindowSize();
  const [exportForm, setExportForm] = useState<{
    visible: boolean;
    workflowTemplateId: string;
    descriptions: Description[];
  }>({
    visible: false,
    workflowTemplateId: "",
    descriptions: [],
  });
  return (
    <>
      <Space
        style={{ width: "100%", justifyContent: "flex-end", marginBottom: 10 }}
      >
        <Button
          type="primary"
          onClick={() => {
            const gridData = gridRef.current.getData();
            const workflowTemplates = Array.from(
              new Set(
                gridData.map(
                  (item: { workflowTemplate: string }) => item.workflowTemplate
                )
              )
            );
            if (workflowTemplates.length > 1) {
              message.error("请选择一种申请类型");
              return;
            }
            axios
              .get<Result<string>>(
                `/wf/formLinkage/formManage/getFormDataColumns?workflowTemplateId=${workflowTemplates.join(
                  ","
                )}`
              )
              .then((resp) => {
                if (resp.data.Status === "1") {
                  const columns: any[] = eval("(" + resp.data.Data + ")");
                  const finalColumns: Description[] = columns.map((item) => {
                    let result: Description = {
                      fieldName: item.field,
                      fieldShowName: item.header,
                      dictionary: item.remoteDictionaryName,
                    };
                    if (item.type === "dictionary") {
                      result.inputType = 6;
                    } else if (item.type === "datetime") {
                      result.inputType = 4;
                    }
                    return result;
                  });
                  setExportForm({
                    ...exportForm,
                    descriptions: finalColumns,
                    workflowTemplateId: workflowTemplates.join(","),
                    visible: true,
                  });
                } else {
                  message.error(resp.data.Data);
                }
              });
          }}
        >
          导出
        </Button>
      </Space>
      <DataGrid
        pageSize={Math.floor((height - 260) / 42)}
        ref={gridRef}
        sorter={{ field: "createTime", order: "descend" }}
        url={`/crm/crmConfig/getWorkflowPostData`}
        descriptions={[
          {
            fieldShowName: "操作",
            render: (text: any, record: any) => {
              return (
                <>
                  <Space>
                    <Button
                      type={"link"}
                      icon={<FileTextOutlined />}
                      onClick={() => {
                        let dataUrl = `/wf/form/vsheet/form?workflowTemplateId=${record.ref.workflowTemplate}&id=${record.ref.formDataId}`;
                        createNewTab(dataUrl, record.formName);
                      }}
                    />
                  </Space>
                </>
              );
            },
            align: "center",
            fixed: true,
            width: 50,
          },
          {
            fieldName: "serialNumber",
            fieldShowName: "单号",
            width: 150,
          },
          {
            fieldName: "workflowTemplate",
            fieldShowName: "申请类型",
            width: 120,
            dictionary: "workflow_template.formName",
            inputType: 6,
          },
          {
            fieldName: "creatorId",
            fieldShowName: "申请人",
            width: 100,
            dictionary: "base_staff",
            inputType: 6,
          },
          {
            fieldName: "departmentId",
            fieldShowName: "部门",
            width: 100,
            dictionary: "base_department",
            inputType: 6,
          },
          {
            fieldName: "currentState",
            fieldShowName: "当前状态",
            width: 100,
          },
          {
            fieldName: "createTime",
            fieldShowName: "提交时间",
            width: 100,
          },
        ]}
      />
      <Modal
        title={"导出"}
        width={800}
        onCancel={() => {
          setExportForm({
            ...exportForm,
            visible: false,
          });
        }}
        onOk={() => {
          exportGridRef.current.export();
          setExportForm({
            ...exportForm,
            visible: false,
          });
        }}
        visible={exportForm.visible}
        maskClosable={false}
      >
        <DataGrid
          ref={exportGridRef}
          sorter={{ field: "createTime", order: "descend" }}
          url={`/wf/formLinkage/formManage/getFormDataList?workflowTemplateId=${exportForm.workflowTemplateId}`}
          descriptions={exportForm.descriptions}
        />
      </Modal>
    </>
  );
}
