import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Row,
    DatePicker,
    Form,
    Divider,
    Button, Space, Select, Input, PageHeader, Modal, message
} from "antd";
import i18n from "i18next";
import {DataGrid} from "../../../../../../components/DataGrid";
import Add from "../Detail/add"
import {instance as axios} from "../../../../../../util/axios";

export default ()=>{
    const [form] = Form.useForm();
    const [searchData, setSearchData] = useState({"property":"0"});
    const [showModal, setShowModal] = useState(false);
    const [totalQty, setTotalQty] = useState("");
    const gridRef = useRef<any>(null);

    useEffect(()=>{
        form.setFieldValue("property","0")
    },[])

    return (
        <div>
            <PageHeader
                title={i18n.t("出库记录")}
                footer={
                    <div className={"u-box-head"}>
                    <span className="u-box-title">
                          出库总数:{totalQty}
                    </span>
                    </div>

                }
                extra={[
                    <Button key="1" type="primary" onClick={()=>{
                        setShowModal(true)
                    }}>
                        {i18n.t("新建")}
                    </Button>,
                    <Button key="1" onClick={()=>{
                        gridRef.current.export();
                    }}>
                        {i18n.t("导出")}
                    </Button>,
                ]}
            />
            <br/>

            <DataGrid ref={gridRef} isShowSearch={true} url={"/clothes/inventory/getInventoryDetailList"} searchData={searchData}
                      descriptions={[
                          {
                              fieldName: "serialNumber",
                              fieldShowName: i18n.t("制单号",)
                          },
                          {
                              fieldName: "createTime",
                              fieldShowName: i18n.t("时间",)
                          },

                          {
                              fieldName: "name",
                              fieldShowName: i18n.t("商品编码",)
                          },
                          {
                              fieldName: "code",
                              fieldShowName: i18n.t("商品简介",)
                          },
                          {
                              fieldName: "modelCode",
                              fieldShowName: i18n.t("生产模特编码",)
                          },
                          {
                              fieldName: "quantity",
                              fieldShowName: i18n.t("数量",),
                              render:(text:any,record:any)=>{
                                  setTotalQty(record.totalQty)
                                  return (
                                      <>{text}</>
                                  )
                              }
                          },

                      ]} />
            <Modal
                getContainer={false}
                style={{top: '20px'}}
                width={'500px'}
                visible={showModal}
                title={i18n.t("新增出库记录")}
                okText={i18n.t("确定")}
                cancelText={i18n.t("取消")}
                onCancel={()=>{
                    form.resetFields()
                    setShowModal(false)
                }}
                onOk={async () => {
                    form.validateFields().then(()=>{
                        const data = form.getFieldsValue();
                        axios.post("/clothes/inventory/insertRecord",data).then((data)=>{
                            if(data.data.Status == 1){
                                message.success(i18n.t("新增成功"));
                                form.resetFields()
                                setShowModal(false);
                                gridRef.current.refresh();
                            }
                        })
                    }).catch(()=>{
                        message.error(i18n.t("请输入内容"));
                    })

                }}
                bodyStyle={{height: 200}}
                destroyOnClose={true}
            >
                <Add form={form}/>
            </Modal>

        </div>
    );
}