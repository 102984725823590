import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { Cell, CustomCell } from "./Designer/components/Cell";
import { reducer, cloneAndForEach } from "./Designer/util";
import { CellData } from "./Designer/schemas/CellData";
import { getData } from "./Designer/components/GridCell/components/Pool/util";

interface FormProps {
  data: CellData;
  customCells?: CustomCell[];
  onChange?: (data: any, setValue: (id: string, value: any) => void) => void;
}

/**
 * Instance context provides the same utilities with designer context
 * because they use the same reducer,
 * but also allows user's input
 */
export const InstanceContext = React.createContext<any>(null);
export default forwardRef(
  ({ data, customCells, onChange }: FormProps, ref: any) => {
    const [innerData, dispatch] = useReducer(reducer, data);
    const [previousInnerData, setPreviousInnerData] = useState(innerData);
    const setValue = useCallback(
      (id: string, value: any) => {
        setPreviousInnerData(innerData);
        dispatch({ type: "SET_VALUE", id, value: value });
      },
      [innerData]
    );
    useEffect(() => {
      if (JSON.stringify(innerData) !== JSON.stringify(previousInnerData)) {
        onChange?.(getData(innerData), setValue);
      }
    }, [innerData, onChange, previousInnerData, setValue]);
    useImperativeHandle(ref, () => ({
      getData: function () {
        return getData(innerData);
      },
      validate: function () {
        dispatch({
          type: "VALIDATE",
        });
        const errorData: { [key: string]: any } = {
          requiredCellDatas: [],
          hasError: false,
        };
        cloneAndForEach(innerData, function (cellData) {
          if (
            (!cellData.value ||
              (cellData.multiple && cellData.value.length === 0)) &&
            cellData.required
          ) {
            errorData.requiredCellDatas.push(cellData);
            errorData.hasError = true;
          }
        });
        return errorData;
      },
    }));
    return (
      <InstanceContext.Provider value={dispatch}>
        <Cell
          ref={ref}
          cellData={innerData}
          className={"preview"}
          customCells={customCells}
        />
      </InstanceContext.Provider>
    );
  }
);
