import React, { CSSProperties, useEffect, useState } from "react";
import {Divider, Input, message, Select} from "antd";
import { Dictionary } from "../../schemas/dictionary";
import { cacheableInstance as axios } from "../../util/axios";
import { Pager } from "../DataGrid/schemas";
import { BaseURL } from "../../constants/url";
import { Result } from "../../schemas/common";
import { useUpdateEffect } from "react-use";
import i18n from "i18next";
import {PlusOutlined} from "@ant-design/icons/lib";
import {isEmpty} from "../../containers/App/screens/Clothes/util/Common";
import moment from "moment";
import {setValue} from "../DynamicForm/Designer/components/GridCell/components/Pool/util";
import {SelectCommonPlacement} from "antd/lib/_util/motion";

function mapper(item: any) {
  return {
    text: item.name,
    value: item.uuid,
  };
}
export interface DictionarySelectProps {
  onChange?: (value: any, option: any) => void;
  onFocus?: (value: any) => void;
  entityName: string;
  style?: CSSProperties;
  value?: string | string[];
  size?: "small" | "middle" | "large";
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  textIndex?: string | null;
  splicingColName?: string | null;
  addAble?:boolean;
  isFormatDate?:boolean;
  onClick?:()=>void;
  allowClear?:boolean;
  searchUrl?:string;
    isTrans?:boolean;
    localAdd?:boolean;//本地新增不上传服务器 接口处理新增数据
    onAdd?:(value:any)=>void;//如果是受控模式 使用form 赋值
    closeOnAdd?:boolean;
}

async function getData(
  entityName: string,
  keyword: string | null,
  selectId: string | string[] | null,
  pagination: Pager | null,
  textIndex: string | null,
  splicingColName: string | null,
  searchUrl: string | null,
): Promise<Dictionary[]> {
  const url = searchUrl?searchUrl:`${BaseURL}/oa/common/CommonApi/GetZLSelectDict`;
  const queryString = `pageIndex=${
    pagination ? pagination.pageIndex : ""
  }&pageSize=${
    pagination ? pagination.pageSize : ""
  }&dictionaryName=${entityName}&key=${keyword || ""}&selectId=${
    typeof selectId === "string"
      ? selectId
      : selectId instanceof Array
      ? selectId.join(",")
      : ""
  }&filter=${selectId ? "setValue" : ""}&colName=${
    textIndex || ""
  }&splicingColName=${splicingColName || ""}`;
  return (
    await axios.get<Result<{ uuid: string; name: string }[]>>(
      `${url}?${queryString}`
    )
  ).data.Data.map(mapper);
}
const initialPagination = { pageIndex: 0, pageSize: 10 };
export const DictionarySelect = ({
  entityName,
  onChange,
  onFocus,
  placeholder,
  size,
  style,
  value,
  multiple,
  disabled,
  textIndex,
  splicingColName,
  addAble=false,isFormatDate=false,onClick,allowClear,searchUrl,isTrans=false,localAdd,
    onAdd,closeOnAdd
}: DictionarySelectProps) => {
  const { Option } = Select;
  const [dataSource, setDataSource] = useState<Dictionary[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [pagination, setPagination] = useState<Pager>(initialPagination);
  const [addItem, setAddItem] = useState("");
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [selectValue, setSelectValue] = useState<any>(null);
  const [open, setOpen] = useState(false);

  // init
  useEffect(() => {
    getData(
      entityName,
      null,
      null,
      initialPagination,
      textIndex || null,
      splicingColName || null,
        searchUrl || null
    ).then((resp) => {
      if (value) {
          getData(
              entityName,
              null,
              value,
              null,
              textIndex || null,
              splicingColName || null,
              searchUrl || null
          ).then((valueResp) => {
              if(dataSource && dataSource.length > 0) {
                  const result = [...dataSource];
                  valueResp.forEach((i) => {
                      if (!dataSource.some((j) => j.value === i.value)) {
                          result.push(i);
                      }
                  });
                  setDataSource(result);
                  setLoadSuccess(true);
              }else{
                  const result = [...resp];
                  valueResp.forEach((i) => {
                      if (!resp.some((j) => j.value === i.value)) {
                          result.push(i);
                      }
                  });
                  setDataSource(result);
                  setLoadSuccess(true);
              }

          });

      } else {
        setDataSource(resp);
      }
    });
  }, [entityName, textIndex, splicingColName,value]);

  const [timeout, setTimeoutt] = useState(0);
  useUpdateEffect(() => {
      if (timeout > 0) {
          console.log("set time out")
          clearTimeout(timeout);
          setTimeoutt(0);
      }
      let time:any = setTimeout(getCurrentData, 300);
      setTimeoutt(time);
  }, [pagination]);

  async function getCurrentData(){
      const resp = await getData(
          entityName,
          keyword,
          null,
          pagination,
          textIndex || null,
          splicingColName || null,
          searchUrl || null
      );
      setDataSource((prev: Dictionary[]) => {
          const result = [...prev];
          resp.forEach((i) => {
              if (!prev.some((j) => j.value === i.value)) {
                  result.push(i);
              }
          });
          return result;
      });
  }

  // on search
  useUpdateEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: 0,
    }));
  }, [keyword]);

  return (
    <>
      <Select
          dropdownMatchSelectWidth={false}
          style={style}
        disabled={disabled || false}
        placeholder={placeholder || i18n.t("请选择")}
        showSearch
        size={size || "middle"}
        mode={multiple ? "multiple" : undefined}
        onPopupScroll={(event:any) => {
            event.persist();
            const { target } = event;
            var currentHeight = target.scrollTop + target.offsetHeight +1;
            if (currentHeight.toString().split(".")[0] >= target.scrollHeight) {
                setPagination((prev) => {
                    let current = Object.assign({}, prev);
                    current.pageIndex++;
                    return current;
                });
            }

        }}
        onChange={onChange}
        onFocus={onFocus}
        filterOption={false}
        value={loadSuccess?selectValue || value:undefined}
        onSearch={(val) => {
          setKeyword(val);
          setDataSource([]);
          setPagination({ pageIndex: 0, pageSize: 10 });
        }}
          open={closeOnAdd?open:undefined}
          onDropdownVisibleChange={closeOnAdd?(visible) => setOpen(visible):undefined}
          dropdownRender={addAble?menu =>
          (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input style={{ flex: 'auto' }} value={addItem} onChange={value=>{
                      setAddItem(value.target.value);
                    }}/>
                    <a
                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={()=>{
                          if(isEmpty(addItem)){
                            message.error(i18n.t("public_emptyContent"));
                            return;
                          }
                          var newItemList = [];
                          var newItem:any = {};
                          if(localAdd){
                              setDataSource([...dataSource]);
                              var newItem:any = {};
                              newItem.isNew = true;
                              newItem.hasChanges = true;
                              newItem.text = addItem;
                              newItem.value = addItem;
                              newItem.key = new Date().getTime();
                              setDataSource([newItem,...dataSource]);
                              // console.log(dataSource)
                              // setSelectValue(addItem)
                              if(onAdd){
                                  onAdd(newItem);
                              }
                          }else{
                              newItem.isNew = true;
                              newItem.hasChanges = true;
                              newItem.name = addItem;
                              newItemList.push(newItem);
                              axios.post("oa/dictionary/dictionaryName/saveDictionaryList?tableName="+entityName,{data:JSON.stringify(newItemList)}).then((resp)=>{
                                  if(resp.data.Status == 1){
                                      message.success(i18n.t("public_addSuccess"))
                                      var any: any = undefined;
                                      setPagination(any);
                                      setPagination({ pageIndex: 0, pageSize: 10 });
                                  }
                              })
                          }

                          setAddItem("");
                          setOpen(false)
                        }}
                    >
                      <PlusOutlined /> Add item
                    </a>
                  </div>
                </div>
            ) :undefined}
        optionLabelProp={"title"}
        onClick={()=>{
            if(onClick){
                onClick();
            }
        }}
        allowClear={allowClear || undefined}
      >
        {dataSource.map((item: Dictionary) => {
          return (
            <Option value={item.value} key={item.value} title={item.text}>
              {isTrans?item.text:item.text} {isFormatDate?"["+moment().format(item.text)+"]":""}
            </Option>
          );
        })}
      </Select>
    </>
  );
};
