import React, {
  useState,
  useEffect, useCallback, useRef,
} from "react";
import {
  Tabs,
  Form,
  Input,
  Radio, message, Checkbox, Image, Modal, Button, Row, Col
} from "antd";

import TabA from "./TabA";
import TabB from "./TabB";
import TabC from "./TabC";
import TabD from "./TabD";
import TabF from "./TabF";
import TabE from "./TabE";
import ImageUpload from "../../../../../../components/ImageUpload/ImageUpload";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import { useParams } from "react-router";
import "../../CSS/BasicData.css";
import { DictionarySelect } from "../../../../../../components/DictionarySelect";
import "../../CSS/skuDetaile.css";
import SmallFileUpload from "../../../../../../components/FileUpload/SmallFileUpload";
import {ModalForm} from "./Component";
import {RightOutlined} from "@ant-design/icons/lib";
import {MasterMaterialComponent} from "./MasterMaterialComponent";
import {isEmpty, isNullOrUndefined} from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import i18n from "i18next";
import {BaseURL} from "../../../../../../constants/url";
import ImageUploads from "../../../../../../components/ImageUpload/ImageUploads";
import Img from "../../../../../../components/Img/Img";
import ChangeHistory from "../../CRM/Detail/ChangeHistory";

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};

export interface Params {
  uuid: string;
  type: string;
  property: string;
}

export default () => {
  const [pagTitle, setPagTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isComponent, setIsComponent] = useState(false);
  const [isShowProcessingMaterial, setIsShowProcessingMaterial] = useState(false);
  const [isZiChan, setIsZiChan] = useState(false);
  const [isShowMulticolor, setIsShowMulticolor] = useState(false);
  const [isShowUpload, setIsShowUpload] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [data,setData] = useState<any>([]);
  const [imageIds,setImageIds] = useState<any>("");
  const [imageId,setImageId] = useState<any>("");
  const [imageUrl,setImageUrl] = useState<any>("");
  const [form] = Form.useForm();
  const param = useParams<Params>();
  const uuid = param.uuid;
  const type = param.type;
  const property = param.property;
  const [huiZongForm] = Form.useForm();
  const [sku, setSku] = useState<any>();
  const refs = useRef<any>();
  const refs1 = useRef<any>();
  useEffect(function () {
    if (type == "edit") {
      axios
          .post<Result<any[]>>(`/clothes/sku/getSkuById`, {
            uuid: uuid,
          })
          .then((resp:any) => {
            if (resp.data.Status === "1") {
              setSku(resp.data.Data);
              form.setFieldsValue(resp.data.Data);
              form.setFieldsValue({
                categoryId:resp.data.Data.categoryId.split(",")
              })
            }
          });
    } else {
      form.setFieldsValue({
        uuid: uuid,
        property: property,
      });
    }
  }, []);

  useEffect(function () {
    axios
        .post<Result<any[]>>(`/clothes/sku/selectAllSku`, {
          skuId: uuid,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            let data = resp.data.Data;
            if(data!=undefined&&data.length>0){
              setData(data);
            }
          }
        });
  },[])

  useEffect(() => {
    switch (property) {
      case "0":
        setIsShowMulticolor(true);
        setPagTitle(type == "add" ? i18n.t("新增原材料") : i18n.t("编辑原材料"));
          break;
      case "2":
        setIsShowUpload(true);
        setPagTitle(type == "add" ? i18n.t("新增辅料") : i18n.t("编辑辅料"));
        break;
      case "3":
        setIsShowMulticolor(true);
        setIsShowProcessingMaterial(true);
        setIsZiChan(true);
        setPagTitle(type == "add" ? i18n.t("新增自产类") : i18n.t("编辑自产类"));
        break;
      case "4":
        setPagTitle(type == "add" ? i18n.t("新增服务") : i18n.t("编辑服务"));
        break;
      case "5":
        setPagTitle(type == "add" ? i18n.t("新增成品采购") : i18n.t("编辑成品采购"));
        break;
      default:
        setPagTitle(type == "add" ? i18n.t("新增商品") : i18n.t("编辑商品"));
        break;

    }
  }, [type]);

/*  const SkuImage = useCallback(()=>{
    let imageIds = data.imageIds;
    if(isEmpty(imageIds)){
      imageIds = "";
    }
    let returnDiv:any;
    imageIds.split(',').map((imageId:any)=>{
      returnDiv = returnDiv + <Image
          width={200}
          src= {BaseURL +"/oa/common/attachment/showImageAttachment?id=" + imageId}
      />
    })
    return(
        <div>
          {returnDiv && returnDiv}

        </div>
    )
  },[imageIds])*/
  const callbackTabs = (key: any) => {
    console.log(key);
  };

  const showComponentModal1 = () => {
    setIsComponent(true);
  };
  const showLiningModal1 = () => {
    setIsComponent(false);
  };
  const showComponentModal2 = () => {
    setIsComponent(true);
    setVisible(true);
  };
  const showLiningModal2 = () => {
    setIsComponent(false);
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
    const [messageInfo, setMessageInfo] = useState<any>();
  return (
      <div className="u-box u-basic-page u-MaterialArchivesForm">
        <div className="u-box-head u-box-head-border">
          <span className="u-box-title">{pagTitle}</span>
        </div>

        <div className="u-box-con u-fixed-padding u-Table-border">
          <div className="u-box-con u-tabsA">
            <Form.Provider
                onFormFinish={(name, {values, forms}) => {
                  if (name === "addForm") {
                    if (isComponent) {
                      form.setFieldsValue({component: values.component});
                    } else {
                      form.setFieldsValue({lining: values.lining});
                    }
                    setVisible(false);
                  }
                }}
            >
              <Form
                  {...layout}
                  form={form}
                  layout="horizontal"
                  name="editForm"
                  autoComplete="off"
                  scrollToFirstError={true}
              >
                <div className="u-form-wrap">
                  <div className="u-form-l">
                    <Form.Item name="uuid" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="property" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="rawMaterialCost" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="liningCost" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="accessoriesCost" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="craftCost" hidden>
                      <Input/>
                    </Form.Item>
                    <Form.Item name="totalCost" hidden>
                      <Input/>
                    </Form.Item>

                    {isNullOrUndefined(messageInfo) ? (
                        ""
                    ) : (
                        <OnFinishFaileds values={messageInfo} form={form}/>
                    )}

                    <Form.Item
                        label={i18n.t("商品编码")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            validator: async (rule, value) => {
                              if (value == undefined || value.trim() == "") {
                                message.error(i18n.t('商品编码不能为空'));
                                throw new Error(i18n.t('商品编码不能为空'));
                              }
                              if (data != undefined && data.length > 0) {
                                data.map((item: any) => {
                                  if (item.name == value) {
                                    message.error(i18n.t('编码重复'));
                                    throw new Error(i18n.t('编码重复'));
                                  }
                                })
                              }
                            }
                          },
                        ]}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("商品简介")}
                        name="code"
                        rules={[
                          {
                            required: true,
                            validator: async (rule, value) => {
                              if (value == undefined || value.trim() == "") {
                                message.error(i18n.t('商品简介不能为空'));
                                throw new Error(i18n.t('商品简介不能为空'));
                              }
                              // if(data!=undefined&&data.length>0){
                              //   data.map((item:any)=>{
                              //     if(item.code==value){
                              //       message.error('商品简介重复');
                              //       throw new Error('商品简介重复');
                              //     }
                              //   })
                              // }
                            }
                          },
                        ]}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item label={i18n.t("主原料成份")} style={{zIndex:1}}>
                      <Form.Item className={"sku-tankuang"} name="component">
                        <Input className="e-input" readOnly={true}/>
                      </Form.Item>
                      {/*{!isZiChan ? <div className="e-setting" onClick={showComponentModal1} onDoubleClick={showComponentModal2}>*/}
                      {/*  <RightOutlined style={{fontSize:"160%",marginTop:"23%"}}/>*/}
                      {/*</div> : <div className="e-setting" onClick={showComponentModal1} onDoubleClick={showComponentModal2}>*/}
                      {/*  <RightOutlined style={{fontSize:"160%",marginTop:"23%"}}/>*/}
                      {/*</div>}*/}

                      {property != '3' &&
                      <div className="e-setting" onClick={showComponentModal1} onDoubleClick={showComponentModal2}>
                        <RightOutlined style={{fontSize: "160%", marginTop: "23%",zIndex:1}}/>
                      </div>}
                    </Form.Item>
                    {property == "3" && <Form.Item label={i18n.t("里布.内衬")}  style={{zIndex:1}}>
                      <Form.Item className={"sku-tankuang"} name="lining">
                        <Input className="e-input" readOnly={true}/>
                      </Form.Item>
                      {property != '3' &&
                      <div className="e-setting" onClick={showLiningModal1} onDoubleClick={showLiningModal2}>
                        <RightOutlined style={{fontSize: "160%", marginTop: "23%"}}/>
                      </div>}
                    </Form.Item>}
                  </div>
                  <div className="u-form-r">
                    <Form.Item label=" " className={"u-form-img-l"} name="imageIds" colon={false}>
                      <ImageUploads onPreview={(file:any)=>{
                        // form.setFieldsValue({imageId:file.uid})
                        // console.log(file)
                        // message.success(i18n.t("设置主图成功,请保存"));
                        setPreviewVisible(true)
                        setImageUrl(file.url)
                        setImageId(file.uid);
                      }}/>
                      {/*{<ImageUploads/>}*/}
                      {/*<SkuImage/>*/}
                    </Form.Item>
                    <Form.Item className="sku-image" label=" " name="imageId" colon={false} hidden={true}>
                      <Img/>
                    </Form.Item>

                  </div>
                </div>
                <div className="u-form-wrap">
                  <div className="u-form-l">
                    <Form.Item className="u-unitId" label={i18n.t("单位")} name="unitId" {...{
                      labelCol: {span: 6},
                      wrapperCol: {span: 3}
                    }}>
                      <DictionarySelect entityName="dict_sku_unit"/>
                    </Form.Item>
                  </div>
                </div>
                <div className="u-FourCon">
                  <Form.Item className="changdu" label={i18n.t("长度")} name="length" {...{
                    labelCol: {span: 10},
                    wrapperCol: {span: 10}
                  }}>
                    <Input/>
                  </Form.Item>
                  <Form.Item className="kuandu" label={i18n.t("宽度")} name="width" {...{
                    labelCol: {span: 10},
                    wrapperCol: {span: 10}
                  }}>
                    <Input/>
                  </Form.Item>
                  <Form.Item className="kezhong" label={i18n.t("克重")} name="gramWeight" {...{
                    labelCol: {span: 10},
                    wrapperCol: {span: 10}
                  }}>
                    <Input/>
                  </Form.Item>
                </div>

                {isShowProcessingMaterial && <div className="u-form-wrap">
                  <div className="u-form-l">


                    <Form.Item className={"u-unitId"}
                        label={i18n.t("加工材料")}
                        name="isProcessingMaterial" {...{
                      labelCol: {span: 6},
                      wrapperCol: {span: 3}
                    }}
                    >
                      <Checkbox.Group>
                        <Checkbox value="1"/>
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                </div>}

                {isShowMulticolor && <div className="u-form-wrap">
                  <div className="u-form-l">


                    <Form.Item className={"u-unitId"} label={i18n.t("有色布")} name="isMulticolor" {...{
                      labelCol: {span: 6},
                      wrapperCol: {span: 3}
                    }}>
                      <Checkbox.Group>
                        <Checkbox value="1"/>
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                </div>}
                {/*<div className="SkuRadioGroup">
                  <Form.Item
                      className="isProcessingMaterial"
                      label={i18n.t("加工材料")}
                      name="isProcessingMaterial"
                      hidden={!isShowProcessingMaterial}
                      {...{labelCol: {span: 12}, wrapperCol: {span: 12}}}
                  >
                    <Checkbox.Group>
                      <Checkbox value="1"/>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item className="isMulticolor" label={i18n.t("多色")} name="isMulticolor"
                             hidden={!isShowMulticolor} {...{labelCol: {span: 12}, wrapperCol: {span: 12}}}>
                    <Checkbox.Group>
                      <Checkbox value="1"/>
                    </Checkbox.Group>
                  </Form.Item>
                </div>*/}
                <div className="u-form-wrap" hidden={isShowUpload}>
                  <div className="u-form-l">
                    <Form.Item label={i18n.t("标准价格")} name="standardCost">
                      <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item
                        hidden={isZiChan}
                        label={i18n.t("采购价格")}
                        name="buyingPrice"
                    >
                      <Input type={"number"} onChange={
                        (e) => {
                          let buyingPrice = e.target.value;
                          let property = form.getFieldValue("property");
                          if (property != "3") {
                            let craftCost = form.getFieldValue("craftCost");
                            if (!isNaN(craftCost)) {
                              form.setFieldsValue({
                                totalCost: Number(buyingPrice) + Number(craftCost),
                                standardCost: Number(buyingPrice) + Number(craftCost),
                              })
                            } else {
                              form.setFieldsValue({
                                totalCost: Number(buyingPrice),
                                standardCost: Number(buyingPrice),
                              })
                            }
                          }
                        }
                      }/>
                    </Form.Item>
                  </div>
                </div>
                <div className="u-form-wrap" hidden={!isShowUpload}>
                  <div className="u-form-l">
                    <Form.Item label={i18n.t("标准价格")} name="standardCost">
                      <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item
                        hidden={isZiChan}
                        className="sku-standard"
                        label={i18n.t("采购价格")}
                        name="buyingPrice"
                    >
                      <Input type={"number"} onChange={
                        (e) => {
                          let buyingPrice = e.target.value;
                          let property = form.getFieldValue("property");
                          if (property != "3") {
                            let craftCost = form.getFieldValue("craftCost");
                            if (!isNaN(craftCost)) {
                              form.setFieldsValue({
                                totalCost: Number(buyingPrice) + Number(craftCost),
                                standardCost: Number(buyingPrice) + Number(craftCost),
                              })
                            } else {
                              form.setFieldsValue({
                                totalCost: Number(buyingPrice),
                                standardCost: Number(buyingPrice),
                              })
                            }
                          }
                        }
                      }/>
                    </Form.Item>
                    <Form.Item className="sku-attachment" name="attachmentIds">
                      <SmallFileUpload/>
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <Tabs
                  style={{height: "500px"}}
                  defaultActiveKey="1"
                  onChange={callbackTabs}
              >
                <TabPane tab={i18n.t("基本信息")} key="1">
                  <TabA form={form} skuId={uuid} type={type} property={property} onSave={()=>{
                    if(refs1.current){
                      message.info(i18n.t("正在保存物料清单..."))
                      refs1.current.submitForm()
                    }
                  }}/>
                </TabPane>
                <TabPane tab={i18n.t("参考销售价格")} key="2">
                  <TabB skuId={uuid} property={property}/>
                </TabPane>
                <TabPane tab={i18n.t("技术资料")} key="3">
                  <TabC form={form} skuId={uuid} property={property}/>
                </TabPane>
                {property =="3" && <TabPane tab={i18n.t("物料清单")} key="4">
                  <TabD form={form} skuId={uuid} property={property} huiZongForm={huiZongForm} ref={refs1}/>
                </TabPane>}
                <TabPane tab={i18n.t("工序清单")} key="5">
                  <TabE form={form} skuId={uuid} property={property} huiZongForm={huiZongForm} ref={refs}/>
                </TabPane>
                <TabPane tab={i18n.t("供应商")} key="6">
                  <TabF form={form} skuId={uuid} property={property}/>
                </TabPane>
                  <TabPane tab={i18n.t("历史记录")} key="7">
                      <ChangeHistory customerId={uuid} changeType={"2"}/>
                  </TabPane>
              </Tabs>

              <ModalForm
                  isComponent={isComponent}
                  visible={visible}
                  onCancel={hideModal}
                  addForm={form}
              />
              <Modal visible={previewVisible} title={""} footer={null} onCancel={()=>{
                setPreviewVisible(false)
              }}>
                <Button onClick={()=>{
                  form.setFieldsValue({imageId:imageId})
                  message.success(i18n.t("设置主图成功,请保存"));
                  setPreviewVisible(false)
                }}>{i18n.t("设置主图")}</Button>
                <img src={imageUrl} style={{ width: '100%' }}/>
              </Modal>
              {/*<MasterMaterialComponent*/}
              {/*    isComponent={isComponent}*/}
              {/*    visible={visible}*/}
              {/*    onCancel={hideModal}*/}
              {/*    addForm={form}*/}
              {/*/>*/}

            </Form.Provider>
          </div>
        </div>
      </div>
  );
};
