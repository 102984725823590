import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ProcessModel } from "../../../../../../../schemas/projectStage";
import { File } from "../file";
import { Member } from "./Member";
import { instance as axios } from "../../../../../../../util/axios";
import { Result } from "../../../../../../../schemas/common";
import Session from "../../../../../../../schemas/session";
import { SessionContext } from "../../../../../index";
import { Alert } from "antd";
import { DynamicTab } from "../../../components/DynamicTab";
import { DynamicForm } from "../../../../../../../schemas/dynamicForm";
export interface StageProps {
  id: string;
  advisorId: string;
  permission: string[];
  ref: any;
}

export const Stage = forwardRef(
  ({ id, advisorId, permission }: StageProps, ref: any) => {
    const [categories, setCategories] = useState<string>("");
    const [roles, setRoles] = useState<string>("");
    const [processModel, setProcessModel] = useState<ProcessModel>();
    const session: Session = useContext(SessionContext);
    const [dynamicTabConfigs, setDynamicTabConfigs] = useState<DynamicForm[]>(
      []
    );

    const load = useCallback(
      function () {
        axios
          .get<Result<ProcessModel>>(
            `crm/project/process/getProcessByProjectId?projectId=${id}`
          )
          .then((resp) => {
            if (resp.data.Status === "1") {
              const model = resp.data.Data;
              setProcessModel(model);
              if (model && model.parameter) {
                const parameter: { [key: string]: string[] } = JSON.parse(
                  model.parameter
                );
                if (model.type === "file") {
                  setCategories(parameter.categories.join(","));
                } else if (model.type === "tab") {
                  axios
                    .post<Result<DynamicForm[]>>(
                      "/crm/dynamicTab/getSelectedList?host=project&hostPrimaryKey=" +
                        id
                    )
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        const data = resp.data.Data;
                        if (data.length === 0) {
                          return;
                        }
                        setDynamicTabConfigs(
                          data.filter(
                            (item) => parameter.tabs.indexOf(item.uuid) > -1
                          )
                        );
                      }
                    });
                } else {
                  setRoles(parameter.roles.join(","));
                }
              }
            } else {
              setProcessModel(undefined);
            }
          });
      },
      [id]
    );

    useEffect(() => load(), [id, load]);
    useImperativeHandle(ref, () => ({
      refresh() {
        load();
      },
    }));

    return (
      <>
        {processModel && (
          <>
            {processModel.description && (
              <Alert message={processModel.description} type="info" />
            )}
            {processModel.type === "file" && categories && (
              <File
                categories={categories}
                projectId={id}
                editable={advisorId === session.id}
              />
            )}
            {processModel.type === "tab" && dynamicTabConfigs && (
              <>
                {dynamicTabConfigs.map((tab) => (
                  <DynamicTab
                    editable={
                      advisorId === session.id ||
                      permission.some(
                        (item: string) => item === tab.workflowTemplateId
                      )
                    }
                    key={tab.tableName}
                    id={id}
                    host="project"
                    advisorId={advisorId}
                    data={{
                      ...tab,
                      entityName: tab.tableName,
                      uuid: tab.uuid,
                    }}
                  />
                ))}
              </>
            )}
            {processModel.type === "member" && roles && (
              <Member
                projectId={id}
                roleIds={roles}
                editable={advisorId === session.id}
              />
            )}
          </>
        )}
      </>
    );
  }
);
