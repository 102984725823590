import React, {useEffect} from 'react'
import {Form, Button, Input, Select, Row, Col, message} from 'antd'

import ImageUpload from "../../../../../components/ImageUpload/ImageUpload";
import {instance as axios} from "../../../../../util/axios";
import i18n from "i18next";
import ImageUploadCrop from '../../../../../components/ImageUpload/ImageUploadCrop';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

export default () => {
	const [form] = Form.useForm()
	const onFinish = (values:any) => {
		console.log(values)
		axios.post("/oa/enterpriseManagement/saveEnterpriseManagement",values).then((res:any)=>{
			if(res.data.Status == 1){
				message.success(i18n.t(i18n.t("保存成功")));
			}
		})
	}

	useEffect(()=>{
		axios.get("/oa/enterpriseManagement/getCurrentEnterpriseManagement").then((res:any)=>{
			if(res.data.Status == 1){
				form.setFieldsValue(res.data.Data);
			}
		})
	},[])

	return (
		<div className="u-form u-form-label u-form-horizontal u-config">
			<Form className="u-form-box CompanyInfo" {...layout} layout="horizontal" form={form} onFinish={onFinish}>
				<Form.Item className="sku-image" label={i18n.t("公司Logo")} name="enterpriseImage" colon={false}>
					<ImageUploadCrop/>
				</Form.Item>
				<Form.Item className="sku-image" label={i18n.t("公司打印Logo")} name="enterprisePrintImage" colon={false}>
					<ImageUploadCrop/>
				</Form.Item>
				<Form.Item name="name" label={i18n.t("公司名称")}>
					<Input disabled={true}/>
				</Form.Item>
				<Form.Item name="systemName" label={i18n.t("公司打印名称")}>
					<Input />
				</Form.Item>
				<Form.Item name="contactWay" label={i18n.t("联系方式")}>
					<Input/>
				</Form.Item>
				<Form.Item name="address" label={i18n.t("联系地址")}>
					<Input.TextArea/>
				</Form.Item>

				<Form.Item name="email" label={i18n.t("邮箱")}>
					<Input/>
				</Form.Item>
				<Form.Item name="website" label={i18n.t("官网网址")}>
					<Input/>
				</Form.Item>

				<div className="u-form-but-bar" style={{left: '0'}}>
					<div className="ant-col ant-col-20 ant-col-offset-9">
						<Button type="primary" className="u-form-buttonB" htmlType="submit">
							{i18n.t("保存")}
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
}