import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { get } from "../../../../../../util/description";
import { Modal, Space, Button } from "antd";
import { TableGrid } from "../../../../../../components/TableGrid";
import { EditOutlined } from "@ant-design/icons";

export interface ContactListProps {
  leadId: string;
}
export const ContactList = ({ leadId }: ContactListProps) => {
  const gridRef = useRef<any>();
  const tableGridRef = useRef<any>();
  const { height } = useWindowSize();
  const [contactForm, setContactForm] = useState<{
    visible: boolean;
    uuid: string | null;
  }>({
    visible: false,
    uuid: null,
  });
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  useEffect(function () {
    get("crm_clue_contact").then((columns: FieldDescription[]) => {
      const copy = JSON.parse(JSON.stringify(columns));
      copy.unshift({
        fieldShowName: "操作",
        render: (text: any, record: any) => {
          return (
            <Space>
              <Button
                type={"link"}
                icon={<EditOutlined />}
                onClick={() => {
                  setContactForm((prevState) => ({
                    ...prevState,
                    visible: true,
                    uuid: record.key,
                  }));
                }}
              />
            </Space>
          );
        },
        align: "center",
        fixed: true,
      });
      setDescriptions(copy);
    });
  }, []);
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          type={"link"}
          style={{ paddingRight: 0 }}
          onClick={() => {
            setContactForm((prevState) => ({
              ...prevState,
              visible: true,
              uuid: null,
            }));
          }}
        >
          +联系记录
        </Button>
      </div>
      <DataGrid
        pageSize={Math.floor((height - 220) / 42)}
        ref={gridRef}
        url={"/crm/clue/getContactByClueId?clueId=" + leadId}
        sorter={{ field: "createTime", order: "descend" }}
        descriptions={descriptions}
      />
      <Modal
        title={contactForm.uuid ? "编辑" : "新建"}
        width={500}
        visible={contactForm.visible}
        onCancel={() => {
          setContactForm((prevState) => ({
            ...prevState,
            visible: false,
          }));
        }}
        onOk={() => {
          tableGridRef.current.save();
        }}
      >
        <TableGrid
          id={contactForm.uuid || undefined}
          ref={tableGridRef}
          entityName="crm_clue_contact"
          columnCount={1}
          defaultValues={{
            clueId: leadId,
          }}
          onSaved={() => {
            setContactForm((prevState) => ({
              ...prevState,
              visible: false,
            }));
            gridRef.current.refresh();
          }}
        />
      </Modal>
    </>
  );
};
