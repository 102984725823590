import {instance as axios} from "../../../../../util/axios";
import {isNullOrUndefined} from "../util/Common";

//获取客户默认地址
 const getCustomerDefaultAddress =async (customerId:string)=>{
    const resp:any =await axios
        .get(
            "/inventory/customerAddress/getCustomerDefaultAddress?customerId=" +
            customerId
        );
    return resp.data.Data as any;
}

//获取对应地址类型的地址
const getCustomerAddressByList = (addressType:string,list:any)=>{
    var address = undefined;
    list.map((item:any)=>{
        if(item.addressType == addressType){
            address = item;
            return true;
        }
    })
    return address;
}

//获取客户地址格式 先默认格式,之后可能会根据配置来
const getCustomerAddressFormat =(item:any)=>{
    console.log(item);
     if(isNullOrUndefined(item)){
         return "";
     }else{
         var str = item.name =
             item.address +
             " " +
             item.city +
             " " +
             item.state +
             "," +
             item.postalCode +
             "," +
             item.telephone +
             " " +
             item.fax;
         return str.replace(/[,]/g, "\n")
     }

}

export {getCustomerDefaultAddress,getCustomerAddressByList,getCustomerAddressFormat};