import styled from "styled-components";
export const FlexDiv:any = styled.div`
  display: flex;
  align-items: center;
`;
export const InlineFormGroup:any = styled.div`
  margin-right: 20px;
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;