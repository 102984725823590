import React, {useCallback, useEffect, useState} from 'react'
import {Form, TreeSelect} from "antd";
import {instance as axios} from "../../util/axios";
import {Result} from "../../schemas/common";

export interface TreeProps {
    entityName: string;
    onSelected?: (keys: any) => void;
    onChange?:(v:any)=>void;
    value?:string;
    multiple?:boolean | undefined;
    disabled?:boolean | undefined;
}

const TreeSelectByType = ({entityName,onSelected,onChange,value,multiple,disabled}:TreeProps)=>{

    const [dataTree, setDataTree] = useState<any>([]);

    useEffect(()=>{
        axios
            .post<Result<any[]>>(`/clothes/treeSelect/getTreeSelect`, {
                type: entityName,
            })
            .then((resp) => {
                if (resp.data.Status === "1") {
                    setDataTree(resp.data.Data);
                }
            });
    },[entityName])
    return (
       <TreeSelect
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={dataTree}
            treeDefaultExpandAll
            onChange={(v)=>{
                if(onChange){
                    onChange(v);
                }
            }}
            value={value}
            multiple={multiple}
            disabled={disabled}
        />
    )
}

export default TreeSelectByType;