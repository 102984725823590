import React, { Dispatch, forwardRef, useCallback, useEffect } from "react";
import { Input } from "antd";
import { CellData } from "../schemas/CellData";
import { FormGroup } from "./FormGroup";
import { ReducerAction } from "../../type";

interface InputCellProps {
  data: CellData;
  layout?: "vertical" | "horizontal";
  dispatch: Dispatch<ReducerAction>;
}

export const InputCell = forwardRef(
  ({ data, layout, dispatch }: InputCellProps, ref: any) => {
    const setValue = useCallback(
      (id: string, value: any) => {
        dispatch({ type: "SET_VALUE", id, value });
      },
      [dispatch]
    );
    useEffect(() => {
      data.onChange?.(data.value, setValue);
    }, [data.onChange, data.value, setValue]);
    return (
      <>
        <FormGroup
          ref={ref}
          required={data.required!}
          warning={data.warning}
          layout={layout}
          warnable={data.warnable!}
          label={
            data.labeled ? (
              <label title={data.label}>{data.label}</label>
            ) : (
              <></>
            )
          }
          element={
            <Input
              onClick={() => data.onClick?.()}
              disabled={data.disabled}
              value={data.value}
              placeholder={data.placeholder}
              onChange={(event) => {
                dispatch?.({
                  type: "SET_VALUE",
                  id: data.id,
                  value: event.target.value,
                });
              }}
            />
          }
        />
      </>
    );
  }
);
