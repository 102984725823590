import React, { Dispatch, forwardRef } from "react";
import { CellData } from "../schemas/CellData";
import { FormGroup } from "./FormGroup";
import { DictionarySelect } from "../../../DictionarySelect";
import { ReducerAction } from "../../type";

interface SelectCellProps {
  data: CellData;
  layout?: "vertical" | "horizontal";
  dispatch: Dispatch<ReducerAction>;
}

export const SelectCell = forwardRef(
  ({ data, layout, dispatch }: SelectCellProps, ref: any) => {
    if (!data.options || data.options.length === 0) {
      data.options = [
        {
          label: "Joyce",
          value: "1",
        },
        {
          label: "Teresa",
          value: "2",
        },
      ];
    }
    return (
      <>
        <FormGroup
          ref={ref}
          required={data.required!}
          warning={data.warning}
          layout={layout}
          warnable={data.warnable!}
          label={
            data.labeled ? (
              <label title={data.label}>{data.label}</label>
            ) : (
              <></>
            )
          }
          element={
            <DictionarySelect
              style={{ width: "100%" }}
              disabled={data.disabled}
              entityName={data.entityName || ""}
              multiple={data.multiple}
              value={data.value}
              onChange={(value) => {
                dispatch({
                  type: "SET_VALUE",
                  id: data.id,
                  value: value,
                });
              }}
            />
            // <Select
            //   onChange={(value) => {
            //     dispatch({
            //       type: "SET_VALUE",
            //       target: cellData,
            //       value: value,
            //     });
            //   }}
            // >
            //   {cellData.options?.map((option) => (
            //     <Option value={option.value}>{option.label}</Option>
            //   ))}
            // </Select>
          }
        />
      </>
    );
  }
);
