import React, {useEffect, useState} from 'react'
import { Table } from 'antd'
import {instance as axios} from "../../../../../util/axios";

const columns = [
		{ title: 'serial number', dataIndex: 'serialNumber', key: 'date',render:function (text:any) {
				return <span>{text}</span>
			}},
		{ title: 'Storage time', dataIndex: 'docDate', key: 'name' },
		{ title: 'Delivery time', dataIndex: 'name', key: 'upgradeNum' },
		{ title: 'smartbox', dataIndex: 'status', key: 'upgradeNum' },
		{ title: 'state', dataIndex: 'total', key: 'upgradeNum' },
	]

interface Param {
	docNumber:string
}

export default ({docNumber}:Param) =>  {
	const [data,setData] = useState([]);
	useEffect(function () {
		axios.get("/inventory/invoice/getInvoiceList?docNumber="+docNumber)
			.then(function (resp) {
				var res = resp.data;
				if(res.Status == 1){
					setData(resp.data.Data);
				}
			})

	},[docNumber])
    return (
		<div className="invoice">
			<Table
				showHeader={false}
				columns={columns} 
				dataSource={data} 
				pagination={false} 
			/>
		</div>
    )
}