import React, { ReactNode, useCallback, useRef, useState } from "react";
import { DataGrid } from "../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import EnterpriseSubAccount from "./Children/index";
import { useHistory } from "react-router";
import ButtonGroup from "antd/es/button/button-group";
import {Button, Drawer, Form, Input, message, Modal, Radio, Space} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { instance as axios } from "../../../../../util/axios";
import "./index.css";
import { Result } from "../../../../../schemas/common";
import TextArea from "antd/es/input/TextArea";
import AppEnterpriseMerge from "./Merge";
import AppEnterpriseDetail from "./Detail";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
export default () => {
  const thisGridRef = useRef<any>();
  const history = useHistory();
  const { width, height } = useWindowSize();
  const [enterpriseId, setEnterpriseId] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [visiable, setVisiable] = useState(false);
  const [thisCorpId, setThisCorpId] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const hideModal = () => {
    setVisiable(false);
    thisGridRef.current.refresh();
  };
  /**
   * 默认服装场
   */
  const [searchData, setSearchData] = useState<any>({
    searchField_string_type: "1|1",
  });
  const columnsData = [
    {
      fieldShowName: i18n.t("enterprise_number"),
      fieldName: "id",
      width: 100,
    },
    {
      fieldShowName: i18n.t("appEnterprise_name"),
      fieldName: "name",
      width: 130,
      render: (text: any, record: any) => {
        return (
          <a
            href="#"
            className="deleteA"
            onClick={(e: any) => {
              setEnterpriseId(record.uuid);
              setVisible2(true);
              setId(record.id);
              setType(record.type)
              // history.push(
              //   "/clothes/appEnterprise/detail/" +
              //     record.uuid +
              //     "/" +
              //     record.type +
              //     "/" +
              //     record.id
              // );
            }}
          >
            {record.name}
          </a>
        );
      },
    },
    {
      fieldShowName: i18n.t("enterpriseManage_type"),
      fieldName: "type",
      dataType: 4,
      width: 130,
      render: (text: any, record: any) => {
        if (record.type == "1") {
          return <>{i18n.t("app_enterprise_garment_factory")}</>;
        } else {
          return <>{i18n.t("app_enterprise_processing_plant")}</>;
        }
      },
    },
    {
      fieldShowName: i18n.t("app_enterprise_number_sub"),
      fieldName: "accountNum",
      width: 130,
      align: "center",
      render: (text: any, record: any) => {
        if (record.accountNum == 0) {
          return <>{record.accountNum}</>;
        } else {
          return (
            <a
              href="#"
              onClick={(e: any) => {
                setEnterpriseId(record.uuid);
                setThisCorpId(record.id);
                setVisiable(true);
              }}
            >
              {record.accountNum}
            </a>
          );
        }
      },
    },
    {
      fieldShowName: i18n.t("public_contract"),
      fieldName: "contact",
      width: 100,
      showTitle: true,
    },
    {
      fieldShowName: i18n.t("enterprise_contact_information"),
      fieldName: "contactWay",
      width: 130,
    },
    {
      fieldShowName: i18n.t("remark"),
      fieldName: "remarks",
      width: 150,
    },
    {
      fieldShowName: i18n.t("appEnterprise_finalLandingTime"),
      fieldName: "finalLandingTime",
      width: 150,
    },
    {
      fieldShowName: i18n.t("appEnterprise_registeredTime"),
      fieldName: "registeredTime",
      width: 150,
    },
    {
      fieldShowName: i18n.t("app_enterprise_is_have_data"),
      fieldName: "isHaveData",
      width: 150,
    },
    {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      title: "option",
      width: 150,
      align: "center",
      isHiddenSearch: true,
      render: (text: any, record: any) => {
        return (
          <>
            <a
              href="#"
              className="deleteA"
              onClick={(e: any) => {
                setEnterpriseId(record.uuid);
                setVisible(true);
              }}
            >
              {i18n.t("app_enterprise_account_merger")}
            </a>
            <a
              href="#"
              className="deleteA"
              style={{ marginLeft: "10%" }}
              onClick={(e: any) => {
                getRemarksById(record);
              }}
            >
              {i18n.t("app_enterprise_remark")}
            </a>
          </>
        );
      },
    },
  ];
  const [columns] = useState<any[]>(columnsData);

  const logout = () => {
    var selected = thisGridRef.current.getSelectedRows();
    if (selected.length <= 0) {
      message.error(i18n.t("enterprise_select_first"));
      return;
    }
    var enterpriseIds = "";
    selected.map((item: any) => {
      if (enterpriseIds.length > 0) {
        enterpriseIds += ",";
      }
      enterpriseIds += item["id"];
    });

    Modal.confirm({
      title: i18n.t("okText"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("app_enterprise_logout_prompt"),
      okText: i18n.t("okText"),
      cancelText: i18n.t("cancelText"),
      onOk: function () {
        const url = `/oa/enterpriseManagement/logout`;
        axios.post(url, { enterpriseIds: enterpriseIds }).then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("app_enterprise_logout_success"));
            thisGridRef.current.refresh();
          } else {
            message.info(resp.data.Data);
          }
        });
      },
    });
  };

  const [remarkVisiable, setRemarkVisiable] = useState(false);
  const remarkHideModal = () => {
    setRemarkVisiable(false);
  };
  const [remarkDetailForm] = Form.useForm();
  const remarkDetailOnOk = () => {
    remarkDetailForm.submit();
  };

  const remarkDetailElement: ReactNode = (
    <>
      <>
        <button
          type="button"
          className="ant-btn ant-btn-primary"
          onClick={remarkDetailOnOk}
        >
          <span>{i18n.t("app_enterprise_sure")}</span>
        </button>
        <button type="button" className="ant-btn" onClick={remarkHideModal}>
          <span>{i18n.t("app_enterprise_cancel")}</span>
        </button>
      </>
    </>
  );
  const onDetailFinishRemark = (values: any) => {
    axios
      .post<Result<any[]>>(`/oa/appEnterpriseManagement/saveRemarkById`, {
        enterpriseId: values.uuid,
        remarks: values.remarks,
      })
      .then((resp) => {
        if (resp.data.Status === "1") {
          message.info(i18n.t("saveSuccess"));
          setRemarkVisiable(false);
          thisGridRef.current.refresh();
        }
      });
  };
  const getRemarksById = useCallback(
    function getRemarksById(record: any) {
      remarkDetailForm.setFieldsValue({
        uuid: record.uuid,
        remarks: record.remarks,
      });
      setRemarkVisiable(true);
    },
    [remarkVisiable]
  );
  return (
    <div className="u-box u-basic-page">
      <div className="u-box-head">
        <span className="u-box-title" onClick={(e) => e.preventDefault()}>
          {i18n.t("enterpriseManage_enterprise")}
        </span>
      </div>
      <Radio.Group
        onChange={(e) => {
          let tempSearchParam = { ...searchData };
          setSearchData(null);
          tempSearchParam.searchField_string_type = "1|" + e.target.value;
          setSearchData(tempSearchParam);
        }}
        defaultValue="1"
      >
        <Radio.Button value="1">
          {i18n.t("app_enterprise_garment_factory")}
        </Radio.Button>
        <Radio.Button value="2">
          {i18n.t("app_enterprise_processing_plant")}
        </Radio.Button>
      </Radio.Group>
      <EnterpriseSubAccount
        enterpriseId={enterpriseId}
        thisCorpId={thisCorpId}
        visiable={visiable}
        hideModal={hideModal}
      />
      <div className="u-box-con">
        <DataGrid
          ref={thisGridRef}
          pagination={true}
          sorter={{ field: "registeredTime", order: "descend" }}
          pageSize={Math.floor((height - 280) / 42)}
          rowSelectionType="checkbox"
          descriptions={columns}
          url={`/oa/appEnterpriseManagement/getEnterpriseList`}
          searchData={searchData}
        />
      </div>
      <ButtonGroup>
        <Button type="primary" onClick={logout}>
          {" "}
          {i18n.t("app_enterprise_logout")}
        </Button>
      </ButtonGroup>
      <Modal
        title={i18n.t("app_enterprise_edit_notes")}
        className="add-component"
        visible={remarkVisiable}
        destroyOnClose={true}
        onCancel={remarkHideModal}
        footer={remarkDetailElement}
        width={(width * 1) / 3}
        bodyStyle={{ height: (height * 1) / 5 }}
        maskClosable={false}
      >
        <Form
          {...layout}
          form={remarkDetailForm}
          layout="horizontal"
          name="basicForm"
          autoComplete="off"
          preserve={false}
          onFinish={onDetailFinishRemark}
        >
          <Form.Item name="uuid" hidden>
            <Input />
          </Form.Item>
          <Form.Item label={i18n.t("remark")} name="remarks">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <Drawer
          placement="right"
          visible={visible}
          onClose={()=>{
            setVisible(false);
          }}
          width={"100%"}
          destroyOnClose={true}
      >
        <AppEnterpriseMerge enterpriseIdd={enterpriseId} onclose={()=>{setVisible(false)}}/>
      </Drawer>
      <Drawer
          placement="right"
          visible={visible2}
          onClose={()=>{
            setVisible2(false);
          }}
          width={"100%"}
          destroyOnClose={true}
      >
        <AppEnterpriseDetail  id={id} enterpriseId={enterpriseId} type={type} onclose={()=>{setVisible2(false)}}/>
      </Drawer>
    </div>
  );
};
