import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {DataGrid} from "../../../../../components/DataGrid";
import {inputType} from "../../../../../constants/inputType";
import i18n from "i18next";
import {instance as axios} from "../../../../../util/axios";
import {Form, message} from "antd";
import {updateMomentType} from "../util/DateFormat";
import {formatNumber} from "../util/FormatMoney";
import {usePermission} from "../../../../../util/permession";

export interface Param {
    docId: string,
    photoUrl: string,
    currentStep: any
}

const ProduceList = forwardRef(({docId, photoUrl, currentStep}: Param, ref: any) => {
        console.log(currentStep)
        const processListRef = useRef<any>();
        const [form] = Form.useForm();

        useImperativeHandle(ref, () => ({
            refresh() {
                if (processListRef) {
                    return processListRef.current.refresh();
                    ;
                }
            },
            getProduceIds() {
                if (processListRef) {
                    var ids = processListRef.current.getSelectedIds();
                    var checkedIds = "";
                    if(ids && ids.length > 0){
                        ids.map((item: string) => {
                            if (checkedIds != "") {
                                checkedIds += "," + item;
                            } else {
                                checkedIds += item;
                            }
                        })
                    }
                    return checkedIds;
                }
            },
        }));

        const processQtyChange = () => {
            var pre = form.getFieldValue("preProcessingNumber");
            var act = form.getFieldValue("actualProcessingNumber");
            var dif = formatNumber(act)-formatNumber(pre);
            form.setFieldsValue({diffProcessingNumber:dif});
        }

        const factoryIdChange = () => {
            var pre = form.getFieldValue("factoryId");
            axios.post("/clothes/customerManage/getCustomerInformationById?customerId="+pre).then((res)=>{
                if(res.data.Status == 1){
                    form.setFieldsValue({subContactFactoryId:res.data.Data.customerNumber})
                }
            })

        }
        const factoryCodeChange = () => {
            var subContactFactoryId = form.getFieldValue("subContactFactoryId");
            axios.post("/clothes/customerManage/getCustomerIdByCode?customerCode="+subContactFactoryId+"&customerType=5").then((res)=>{
                if(res.data.Status == 1){
                    form.setFieldsValue({factoryId:res.data.Data})
                }
            })
        }

        const [colorColumn, setColorColumn] = useState<any>(
            [
                {
                    fieldShowName: i18n.t("工序",),
                    fieldName: "processNumber",
                },
                {
                    fieldShowName: i18n.t("工序描述",),
                    fieldName: "processId",

                    inputType: inputType.DICTIONARY,
                    dictionary: "dict_working_procedure",
                },
                {
                    fieldShowName: i18n.t("开始日期",),
                    fieldName: "startDate",
                    inputType: inputType.DATE,
                    editable: true
                },
                {
                    fieldShowName: i18n.t("预计完工日期",),
                    fieldName: "preFinishDate",
                    inputType: inputType.DATE,
                    editable: true
                },
                {
                    fieldShowName: i18n.t("实际完成时间",),
                    fieldName: "finishDate",
                    inputType: inputType.DATE,
                    editable: true
                },
                {
                    fieldShowName: i18n.t("预计加工数",),
                    fieldName: "preProcessingNumber",
                    editable: true,
                    onChange: () => {
                        //预计实际加工数-预计加工数 = 相差加工数
                        processQtyChange();
                    }
                },
                {
                    fieldShowName: i18n.t("实际加工数",),
                    fieldName: "actualProcessingNumber",
                    editable: true,
                    onChange:()=>{
                        processQtyChange();
                    }

                },
                {
                    fieldShowName: i18n.t("相差加工数",),
                    fieldName: "diffProcessingNumber",
                    editable: true

                },
                {
                    fieldShowName: i18n.t("加工总费用",),
                    fieldName: "amount",
                    editable: false,
                },
                {
                    fieldShowName: i18n.t("工厂编号",),
                    fieldName: "subContactFactoryId",
                    // inputType: inputType.DICTIONARY,
                    // dictionary: "clothes_factory",
                    editable: true,
                    onChange:()=>{
                        factoryCodeChange()

                    }

                },
                {
                    fieldShowName: i18n.t("工厂名称",),
                    fieldName: "factoryId",
                    inputType: inputType.DICTIONARY,
                    dictionary: "clothes_customer",
                    filter: "customerType = 5",
                    editable: true,
                    onChange:()=>{
                        factoryIdChange()

                    }
                },
                {
                    fieldShowName: i18n.t("单位成本",),
                    fieldName: "billByPiece",
                    editable: true

                },
                {
                    fieldShowName: i18n.t("备注",),
                    fieldName: "remark",
                    editable: true

                },
                {
                    fieldShowName: i18n.t('操作',),
                    render: function useAa (record: any) {
                        return useGetRender(record, processListRef, () => {
                            var data = processListRef.current.getDataItem(record.uuid);
                            updateMomentType(data);
                            axios.post("/clothes/produceOrder/updateProduceOrderProcess", data)
                                .then(function (resp) {
                                    var res = resp.data;
                                    if (res.Status == 1) {
                                        message.success(i18n.t("保存成功"));
                                        processListRef.current.refresh();
                                    } else {
                                        message.error(i18n.t("保存失败"));
                                    }
                                }).catch(() => {
                                message.error(i18n.t("保存失败"));
                            })
                        })
                    },
                }
            ]);

        const useGetRender = (record: any, gridRef: any, onSave: any) => {
            return (
                usePermission("新增修改生产订单") && <>

                    <a
                        style={{marginRight: 16}}
                        onClick={async (e) => {
                            if(gridRef.current.getEditingKey() == record.uuid || gridRef.current.getEditingKey() == record.key){
                                await gridRef.current.save(gridRef.current.getEditingKey());
                                await onSave();
                            }else{
                                await gridRef.current.edit(record);
                            }
                        }}
                    >
                        {i18n.t("编辑/保存")}
                    </a>

                </>
            )

        }


        return <div>

            <div className="u-table-photo">
                <div className="u-table-left">


                    <DataGrid
                        ref={processListRef}
                        pagination={false}
                        pageSize={5}
                        scrolly={300}
                        rowSelectionType="checkbox"
                        descriptions={colorColumn}
                        url={`/clothes/produceOrder/getProduceOrderProcessList?docId=` + docId}
                        onClick={(record: any) => {
                        }}
                        isShowSearch={false}
                        rowClassName={(record: any) => {
                            return currentStep == record.ref.processId ? "currentStep" : "";
                        }}
                        form={form}
                    />


                </div>
                <div className="u-photo-left">
                    {photoUrl && <img alt='' src={photoUrl}/>}
                </div>
            </div>

        </div>
    }
)

export default ProduceList;