import React, { Dispatch, forwardRef, useRef, useState } from "react";
// @ts-ignore
// import { Map, NavigationControl } from "react-bmap";
import { Input, Modal } from "antd";
import { CellData } from "./Designer/schemas/CellData";
import { FormGroup } from "./Designer/components/FormGroup";
import Autocomplete from "../BMap/Autocomplete";
import { ReducerAction } from "./type";
interface AddressCellProps {
  data: CellData;
  layout?: "vertical" | "horizontal";
  dispatch: Dispatch<ReducerAction>;
}

export const AddressCell = forwardRef(
  ({ data, layout, dispatch }: AddressCellProps, ref: any) => {
    const [locationForm, setLocationForm] = useState<{
      visible: boolean;
    }>({
      visible: false,
    });
    const bMapRef = useRef<any>();
    const [marker, setMarker] = useState<{
      province: string;
      city: string;
      address: string;
      lng: number;
      lat: number;
    }>();
    const innerData = { ...data };
    innerData.label = innerData.label || "地址";
    return (
      <>
        <FormGroup
          ref={ref}
          required={innerData.required!}
          warning={innerData.warning}
          layout={layout}
          warnable={innerData.warnable!}
          label={
            innerData.labeled ? (
              <label title={innerData.label}>{innerData.label}</label>
            ) : (
              <></>
            )
          }
          element={
            <Input
              value={innerData.value}
              placeholder={innerData.placeholder}
              disabled={innerData.disabled}
              onClick={() =>
                setLocationForm((prevState) => ({
                  ...prevState,
                  visible: true,
                }))
              }
            />
          }
        />
        <Modal
          visible={locationForm.visible}
          onOk={() => {
            setLocationForm((prevState) => ({
              ...prevState,
              visible: false,
            }));
            dispatch &&
              dispatch({
                type: "SET_VALUE",
                id: innerData.id,
                value: marker?.address,
              });
          }}
          maskClosable={false}
        >
          {/*<Map
            center={{ lng: 116.402544, lat: 39.928216 }}
            zoom="11"
            ref={bMapRef}
          >
            <NavigationControl />
          </Map>*/}
          <Autocomplete
            style={{
              width: "100%",
              margin: "5px 0",
              height: 32,
              border: "1px solid #d9d9d9",
            }}
            onConfirm={(e) => {
              // @ts-ignore
              const local = new BMap.LocalSearch(bMapRef.current.map, {
                renderOptions: { map: bMapRef.current.map },
                onMarkersSet: function (pois: any) {
                  for (const poi of pois) {
                    poi.marker.addEventListener("infowindowopen", function () {
                      setMarker({
                        province: poi.province,
                        city: poi.city,
                        address: poi.address,
                        lng: poi.lng,
                        lat: poi.lat,
                      });
                    });
                  }
                },
              });
              const value = e.item.value;
              local.search(
                value.province +
                  value.city +
                  value.district +
                  value.street +
                  value.business
              );
            }}
          />
        </Modal>{" "}
      </>
    );
  }
);
