import { Badge, Button, Collapse, List, message, Popover } from "antd";
import { instance as axios } from "../../../../../../../util/axios";
import {
  CaretRightOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProjectRole } from "../../../../../../../schemas/customerRole";
import { DictionarySelect } from "../../../../../../../components/DictionarySelect";
import { AvatarWrapper } from "../../../../../../../components/AvatarWrapper";
import { UserSelect } from "../../../../../../../components/UserSelect";
import { User } from "../../../../../../../components/UserSelect/schema";
import { Result } from "../../../../../../../schemas/common";

export interface MemberManagerProps {
  projectId: string;
  roleIds: string;
  editable: boolean;
}

const DeletableBadge = styled(Badge)`
  :hover {
    .anticon {
      display: initial !important;
    }
  }
`;
export const Member = ({
  projectId,
  roleIds,
  editable = true,
}: MemberManagerProps) => {
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const [data, setData] = useState<ProjectRole[]>([]);
  const [addMemberForm, setAddMemberForm] = useState({
    roleId: "",
  });
  useEffect(
    function () {
      axios
        .post("/crm/crm/projectRole/getRoleDataByProjectId", {
          projectId,
          roleIds,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            setData(resp.data.Data);
          }
        });
    },
    [projectId]
  );

  function delCustomerRoleUser(roleId: string, staffUserId: string) {
    axios
      .delete(
        `/crm/crm/projectRole/delRoleStaffByProjectIdAndRoleId?projectId=${projectId}&roleId=${roleId}&staffId=${staffUserId}`
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          const copy = JSON.parse(JSON.stringify(data));
          copy.forEach((role: ProjectRole) => {
            if (role.uuid === roleId) {
              role.users = role.users.filter(
                (user) => user.uuid !== staffUserId
              );
            }
          });
          setData(copy);
          message.success("操作成功");
        }
      });
  }
  const content = (
    <div>
      <div style={{ display: "inline-block" }}>
        <DictionarySelect
          style={{ width: 200 }}
          entityName={"base_staff"}
          multiple={false}
          onChange={(value, option) => {
            axios
              .post("/crm/crm/projectRole/addRoleStaffByProjectIdAndRoleId", {
                projectId: projectId,
                roleId: addMemberForm.roleId,
                staffId: value,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.success("操作成功");
                  const copy = JSON.parse(JSON.stringify(data));
                  copy.forEach((role: ProjectRole) => {
                    if (role.uuid === addMemberForm.roleId) {
                      if (!role.users.some((user) => user.uuid === value)) {
                        role.users.push({
                          uuid: option.key,
                          name: option.children,
                        });
                      }
                    }
                  });
                  setData(copy);
                }
              });
          }}
        />
      </div>
      <div style={{ display: "inline-block" }}>
        <Button
          type={"link"}
          onClick={() => {
            setUserSelectVisible(true);
          }}
        >
          更多人员
        </Button>
      </div>
    </div>
  );

  function getButtonAdd(roleId: string) {
    return (
      <Popover
        content={content}
        trigger="hover"
        placement={"bottomRight"}
        onVisibleChange={(visible) => {
          if (visible) {
            setAddMemberForm({ roleId });
          }
        }}
      >
        <PlusCircleOutlined
          style={{
            fontSize: 30,
            color: "#a3a3a3",
            margin: "24px 10px",
            cursor: "pointer",
          }}
        />
      </Popover>
    );
  }

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(role: ProjectRole) => (
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <CollapsePanel
                style={{
                  border: "none",
                  textAlign: "left",
                }}
                className="site-collapse-custom-panel"
                header={role.name}
                key={role.uuid}
              >
                {role.users.length
                  ? role.users.map((user, index) => (
                      <div style={{ display: "inline-flex" }}>
                        {editable ? (
                          <DeletableBadge
                            offset={[0, 10]}
                            count={
                              <a
                                href="#"
                                className="head-example"
                                onClick={() => {
                                  delCustomerRoleUser(role.uuid, user.uuid);
                                }}
                              >
                                <CloseCircleOutlined
                                  style={{ color: "#f5222d", display: "none" }}
                                />
                              </a>
                            }
                          >
                            <AvatarWrapper
                              size={"large"}
                              direction={"vertical"}
                              userId={user.uuid}
                              userName={user.name}
                            />
                          </DeletableBadge>
                        ) : (
                          <AvatarWrapper
                            size={"large"}
                            direction={"vertical"}
                            userId={user.uuid}
                            userName={user.name}
                          />
                        )}

                        {index === role.users.length - 1 && editable ? (
                          getButtonAdd(role.uuid)
                        ) : (
                          <></>
                        )}
                      </div>
                    ))
                  : getButtonAdd(role.uuid)}
              </CollapsePanel>
            </Collapse>
          )}
        />
      </div>
      <UserSelect
        visible={userSelectVisible}
        defaultSelectedUsers={[]}
        multiple={true}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
        onOk={(users: User[]) => {
          setUserSelectVisible(false);
          axios
            .post<Result<void>>(
              "/crm/crm/projectRole/addRoleStaffByProjectIdAndRoleId",
              {
                projectId: projectId,
                roleId: addMemberForm.roleId,
                staffId: users.map((user) => user.id).join(","),
              }
            )
            .then((resp) => {
              if (resp.data.Status === "1") {
                message.success("操作成功");
                const copy = JSON.parse(JSON.stringify(data));
                copy.forEach((role: ProjectRole) => {
                  if (role.uuid === addMemberForm.roleId) {
                    users.forEach((user) => {
                      if (
                        !role.users.some(
                          (roleUser) => roleUser.uuid === user.id
                        )
                      ) {
                        role.users.push({
                          uuid: user.id,
                          name: user.name.split("-")[1],
                        });
                      }
                    });
                  }
                });
                setData(copy);
              }
            });
        }}
      />
    </>
  );
};
