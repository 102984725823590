import { instance as axios } from "../axios";

async function getTree(tableName, url) {
  const tabledURL = `/oa/common/crmtreeControl/getTreeData?type=${tableName}&dataId=-1&selectedId=0`;
  const customURL = url + "&dataId=-1&selectedId=0";
  const resp = await axios.get(tableName ? tabledURL : customURL);
  formatTreeData(resp.data);
  return resp.data;
}

function formatTreeData(treeData) {
  let func = function (options) {
    for (let option of options) {
      option.title = option.text;
      option.key = option.id;
      if (option.children && option.children.length > 0) {
        func(option.children);
      }
    }
  };
  func(treeData);
}

export { getTree };
