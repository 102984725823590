import { instance as axios } from "../../util/axios";
import { GridData } from "../../schemas/gridData";
import { Dictionary } from "../../schemas/dictionary";
import { CountOptions, Expression, GridDataOptions } from "./schemas";
import { Description } from "../../schemas/description";
import { Result } from "../../schemas/common";
import {isEmpty, isNullOrUndefined} from "../../containers/App/screens/Clothes/util/Common";

function getPagination({ pageIndex, pageSize }: GridData, total: number) {
  return {
    current: pageIndex,
    pageSize: pageSize,
    total: total,
  };
}

function getDataSource({ data, dictionary }: GridData) {
  return data.map((data: { [key: string]: any }) => {
    let result = Object.assign({}, data);
    if(isEmpty(data.uuid)){
        result.key = data.key;
    }else{
        result.key = data.uuid;
    }
    result.ref = data;
    for (let prop in dictionary) {
      if (dictionary.hasOwnProperty(prop)) {
        let fieldName = prop.split(".")[0];
        let items = dictionary[prop];
        result[fieldName] = items
            .filter((item: Dictionary) => {
              //字典值不能为数字,这个格式化一下
              if(!isNullOrUndefined(result[fieldName])){
                result[fieldName] = result[fieldName].toString();
              }
              return (
                  result[fieldName] &&
                  result[fieldName].split(",").includes(item.value)
              );
            })
            .map((item: Dictionary) => item.text)
            .join(",");
      }
    }
    return result;
  });
}

async function getExpressions({
  searchForm,
  url,
  expressions,searchData
}: CountOptions): Promise<{ [key: string]: string }> {
  const expression = new Expression("数量", "count(1)");
  expression.format = function (value) {
    return `共${value}项`;
  };
  expressions ? expressions.push(expression) : (expressions = [expression]);
  const data = {
    expressions: JSON.stringify(expressions),
    ...searchForm,
    summary: true,
      ...searchData,
  };
  let response = await axios.post<Result<GridData>>(url, data);
  return response.data.Data.expressions!;
}

async function getGridData(options: GridDataOptions): Promise<GridData> {
  let formattedPager = options.pagination
    ? options.pager || { pageIndex: 1, pageSize: 10 }
    : {
        pageIndex: 0,
        pageSize: 0,
      };
  let dictionaryNames = "";
  for (let prop in options.dictionaryMapper) {
    if (options.dictionaryMapper.hasOwnProperty(prop)) {
      if (dictionaryNames.length > 0) {
        dictionaryNames += ",";
      }
      let dictionaryName = options.dictionaryMapper[prop];
      dictionaryNames += prop + "." + dictionaryName;
    }
  }

  let data: any = {
    pageIndex: formattedPager.pageIndex,
    pageSize: formattedPager.pageSize,
    dictionaryNames,
    ...options.searchForm,
    ...options.searchData,
      loadAllDictionary:options.loadAllDictionary
  };
  if (options.sorter?.order) {
    data.sortField = options.sorter.field;
    data.sort = options.sorter.order === "descend" ? "desc" : "asc";
  }
  let response = await axios.post(options.url, data);
  return response.data.Data;
}

function getDictionaryMapper(descriptions: Description[]) {
  let mapper: { [key: string]: string } = {};
  descriptions
    .filter((item: Description) => item.dictionary)
    .forEach((desc: Description) => {
      mapper[desc.fieldName!] = desc.dictionary!;
    });
  return mapper;
}

export {
  getPagination,
  getDataSource,
  getGridData,
  getDictionaryMapper,
  getExpressions,
};
