import React, { useState, useEffect, useCallback, ReactNode } from "react";
import {Tree, Modal, Input, message, Form, InputNumber, Button} from "antd";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { instance as axios } from "../../util/axios";
import { getTree } from "../../util/ant/tree";
import styled from "styled-components";
import { Category } from "../../schemas/category";
import { Result } from "../../schemas/common";
import { useUpdateEffect } from "react-use";
import i18n from "i18next";
const { confirm } = Modal;
const TreeNodeWrapper = styled.span`
  .icon {
    color: transparent;
  }
  &:hover .icon {
    color: #1890ff;
  }
`;
export interface TreeProps {
  editable?: boolean;
  getTreeDataUrl: string;
  updateUrl: string;
  deleteUrl: string;
  addUrl: string;
  onSelected: (keys: any) => void;
  showSeacher?: boolean;
}


export const CorrespondenceTreeWrapper = ({
  editable,
  getTreeDataUrl,
  deleteUrl,
  addUrl,
  updateUrl,
  onSelected,
  showSeacher,
}: TreeProps) => {
  const [treeData, setTreeData] = useState<Category[]>([]);
  const [formatTreeData, setFormatTreeData] = useState<Category[]>([]);
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [addOnLoad, setAddOnLoad] = useState(false);
  const [editOnLoad, setEditOnLoad] = useState(false);
  const formatTitle = useCallback(
    function (rootNode: any[]) {
      let copy = JSON.parse(JSON.stringify(rootNode));
      let func = function (nodes: any[]) {
        for (let node of nodes) {
          node.title = (
            <>
              {editable ? (
                <TreeNodeWrapper>
                  <span style={{ marginRight: 25 }}>{node.title}</span>
                  <PlusOutlined
                    hidden={!editable}
                    className="icon"
                    onClick={(e) => {
                      formAdd.setFieldsValue({
                        parentId: node.key,
                        parentName: node.text,
                        name: "",
                      });
                      setAddVisible(true);
                      e.stopPropagation();
                    }}
                  />
                  <EditOutlined
                    hidden={!editable}
                    className="icon"
                    style={{ margin: "0 6px" }}
                    onClick={(e) => {
                      formEdit.setFieldsValue({
                        id: node.key,
                        name: node.text,
                      });
                      setEditVisible(true);
                      e.stopPropagation();
                    }}
                  />
                  <DeleteOutlined
                    hidden={!editable}
                    className="icon"
                    onClick={(e) => {
                      if (copy[0].key === node.key) {
                        message.error("根节点不可删除");
                        e.stopPropagation();
                        return;
                      }
                      if (node.children != undefined) {
                        message.error("存在子节点不可删除");
                        e.stopPropagation();
                        return;
                      }
                      confirm({
                        title: i18n.t("提示",),
                        icon: <ExclamationCircleOutlined />,
                        okText: i18n.t("确定",),
                        cancelText: i18n.t("cancelText",),
                        content: i18n.t(`您确定要删除类别吗？`),
                        onOk() {
                          axios
                            .delete<Result<void>>(deleteUrl + node.key)
                            .then((resp) => {
                              if (resp.data.Status === "1") {
                                message.success(i18n.t("删除成功"));
                                getTree(null, getTreeDataUrl).then(setTreeData);
                              } else {
                                message.error(i18n.t("删除失败"));
                              }
                            });
                        },
                      });
                      e.stopPropagation();
                    }}
                  />
                </TreeNodeWrapper>
              ) : (
                <span>{node.title}</span>
              )}
            </>
          );
          if (node.children && node.children.length > 0) {
            func(node.children);
          }
        }
      };
      func(copy);
      return copy;
    },
    [deleteUrl, editable, getTreeDataUrl]
  );

  useEffect(
    function () {
      getTree(null, getTreeDataUrl).then(setTreeData);
    },
    [getTreeDataUrl]
  );
  useUpdateEffect(() => {
    setFormatTreeData(formatTitle(treeData));
  }, [editable, treeData]);
  const onAddFinish = (values: any) => {
    console.log("Finish:", values);
    axios
      .put<Result<string>>(
        `${addUrl}&name=${values.name}&parent=${values.parentId}`
      )
      .then((resp) => {
        if (resp.data.Status === "1") {
          console.log(i18n.t("保存成功"))
          message.success(i18n.t("保存成功"));
          setAddVisible(false);
          setAddOnLoad(false);
          getTree(null, getTreeDataUrl).then((result) => {
            setTreeData(result);
          });
        }else if(resp.data.Status === "2"){
          message.error(i18n.t("商品名称重复,请修改名称"));
          setAddOnLoad(false);
        }else {
          message.error(resp.data.Data);
          setAddOnLoad(false);
        }
      });
  };
  const onEditFinish = (values: any) => {
    console.log("Finish:", values);
    axios
      .post<Result<void>>(updateUrl, {
        name: values.name,
        uuid: values.id,
      })
      .then((resp:any) => {
        if(resp.data.Status === "1"){
          message.success(i18n.t("编辑成功"));
          setEditVisible(false);
          setEditOnLoad(false);
          getTree(null, getTreeDataUrl).then((result) => {
            setTreeData(result);
          });
        }else if(resp.data.Status === "2"){
          message.error(i18n.t("商品名称重复,请修改名称"));
          setEditOnLoad(false);
        }else {
          message.error(resp.data.Data);
          setEditOnLoad(false);
        }

      });
  };
  const onAddFinishFailed = (values: any) => {
    setAddOnLoad(false);
  };
  const onEditFinishFailed = (values: any) => {
    setEditOnLoad(false);
  };
  const onOkAdd = () => {
    setAddOnLoad(true);
    formAdd.submit();
  };
  const onOkEdit = () => {
    setEditOnLoad(true);
    formEdit.submit();
  };
  const onCloseAdd = () => {
    setAddVisible(false);
  };
  const onCloseEdit = () => {
    setEditVisible(false);
  };
  const addElement: ReactNode = (
    <>
      <Button
        type="primary"
        className="ant-btn ant-btn-primary"
        loading={addOnLoad}
        onClick={onOkAdd}
      >
        <span>{i18n.t("确定",)}</span>
      </Button>
      <button type="button" className="ant-btn" onClick={onCloseAdd}>
        <span>{i18n.t("cancelText",)}</span>
      </button>
    </>
  );
  const editElement: ReactNode = (
      <>
        <Button
            type="primary"
            className="ant-btn ant-btn-primary"
            loading={editOnLoad}
            onClick={onOkEdit}
        >
          <span>{i18n.t("确定",)}</span>
        </Button>
        <button type="button" className="ant-btn" onClick={onCloseEdit}>
          <span>{i18n.t("cancelText",)}</span>
        </button>
      </>
  );

  const [searchValue,setSearchValue] = useState("");

  const loop = useCallback((data:Category[])=>{
    if(searchValue!=""){
      let arr:Category[] = [];
      data.map((item:Category)=>{
        if(item.text.indexOf(searchValue)>-1){
          arr.push(item);
        }
        if(item.children!=undefined){
          var temp:any = {};
          childrenloop(arr,item.children,false,temp);
        }
      })
      /*if(arr.length>0){
        var nodeId = arr[0].id;
        // 找到nodeid的父级节点
        var result = {};
        getNodeParent(result, nodeId, data,data[0]);
        console.log("ersult",result)

      }*/
      if(arr.length > 0){
        var node = arr[0];
        var parent = {};
        getNodeParent(parent, node.id, data, {},arr);
      }
      return arr;
    }else{
      return data;
    }
  },[searchValue])

  const watch = useUpdateEffect(()=>{
    if(!searchValue){
      getTree(null, getTreeDataUrl).then(setTreeData);
    }
  },[searchValue])

  const childrenloop = useCallback((arr:Category[],data:Category[],isInChildren:boolean,currentNode:Category)=>{
    data.map((item:Category)=>{
      item.title = item.text;
      if(item.text.indexOf(searchValue)>-1){
        var tempArr = [];;
        tempArr.push(item);
        if(currentNode.text){
          currentNode.children = tempArr;
          arr.push(currentNode);
        }else{
          var tempItem = JSON.parse(JSON.stringify(item));
          arr.push(tempItem);
        }
      }
      if(item.children!=undefined){
        childrenloop(arr,item.children,true,item);
      }
    })
  },[searchValue])

  /**
   * 获取某一结点的父级节点
   */
  const getNodeParent = useCallback((result:any,nodeId:string,data:Category[],parentNode:any,arr:any)=>{
      data.map((item:Category)=>{
        item.title = item.text;
        if(item.id == nodeId){
          var tempItem = JSON.parse(JSON.stringify(parentNode));
          var tempArr = JSON.parse(JSON.stringify(arr));
          tempItem.children = tempArr;
          result = tempItem;
          arr.length = 0;
          arr.push(tempItem);
        }
        if(item.children){
          getNodeParent(result,nodeId,item.children,item,arr);
        }
      })
  },[]);

  return (
      <>
        {formatTreeData.length > 0 && (
            <>
              <Input style={{marginBottom: 8}} placeholder="Search" hidden={!showSeacher} onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}/>
              <Tree
                  treeData={loop(formatTreeData)}
                  onSelect={onSelected}
                  defaultExpandAll={true}
              />
            </>
        )}
        <Modal
            title={i18n.t("编辑类别")}
            width={500}
            visible={editVisible}
            footer={editElement}
            maskClosable={false}
            onCancel={onCloseEdit}
        >
          <Form
              form={formEdit}
              layout="horizontal"
              name="editForm"
              autoComplete="off"
              scrollToFirstError={true}
              onFinish={onEditFinish}
              onFinishFailed={onEditFinishFailed}
          >
            <Form.Item name="id" hidden>
              <Input/>
            </Form.Item>
            <Form.Item
                label={i18n.t("类别名称")}
                name="name"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined || value.trim() == "") {
                        message.error(i18n.t("类别名称不能为空"));
                        throw new Error(i18n.t("类别名称不能为空"));
                      }
                    },
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
            title={i18n.t("新增类别")}
            width={500}
            visible={addVisible}
            footer={addElement}
            maskClosable={false}
            onCancel={onCloseAdd}
        >
          <Form
              form={formAdd}
              layout="horizontal"
              name="addForm"
              autoComplete="off"
              scrollToFirstError={true}
              onFinish={onAddFinish}
              onFinishFailed={onAddFinishFailed}
          >
            <Form.Item name="parentId" hidden>
              <Input/>
            </Form.Item>
            <Form.Item
                label={i18n.t("上级类别")}
                name="parentName"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined || value.trim() == "") {
                        message.error(i18n.t("上级类别不能为空"));
                        throw new Error(i18n.t("上级类别不能为空"));
                      }
                    },
                  },
                ]}
            >
              <Input disabled/>
            </Form.Item>
            <Form.Item
                label={i18n.t("类别名称")}
                name="name"
                rules={[
                  {
                    required: true,
                    validator: async (rule, value) => {
                      if (value == undefined || value.trim() == "") {
                        message.error(i18n.t("类别名称不能为空"));
                        throw new Error(i18n.t("类别名称不能为空"));
                      }
                    },
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};
