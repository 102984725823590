import { CorrespondenceTreeWrapper } from "../../../../../../components/CorrespondenceTree";
import { Button, Layout, message, Popconfirm } from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons/lib";
import React, { useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { instance as axios } from "../../../../../../util/axios";
import { Result } from "../../../../../../schemas/common";
import UploadWrapper from "../../../../../../components/UploadWrapper";
import { BaseURL } from "../../../../../../constants/url";
const { Sider, Content } = Layout;
export interface FileProps {
  categories: string;
  projectId: string;
  editable: boolean;
}
export const File = ({ categories, projectId, editable }: FileProps) => {
  const { height } = useWindowSize();
  const gridRef = useRef<any>();
  const [treeCategory, setTreeCategory] = useState<string>();
  const [correspondenceUrl, setCorrespondenceUrl] = useState<string>(
    "/crm/crm/project/getList?projectId=" + projectId
  );
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState(
    false
  );
  return (
    <>
      <Layout>
        <Sider width={260}>
          <CorrespondenceTreeWrapper
            getTreeDataUrl={`/crm/correspondenceCategoryTree/getTreeData?type=crm_project_correspondence_category&projectId=${projectId}&dataIds=${categories}`}
            deleteUrl={"/crm/correspondenceCategoryTree/?uuid="}
            addUrl={`/crm/correspondenceCategoryTree/?projectId=${projectId}`}
            updateUrl={`/crm/correspondenceCategoryTree/updateChild?projectId=${projectId}`}
            editable={editable}
            onSelected={(keys) => {
              const category: string = keys[0] || "";
              setTreeCategory(category);
              setCorrespondenceUrl(
                `/crm/crm/project/getList?projectId=${projectId}&categoryId=${category}`
              );
            }}
          />
        </Sider>
        <Content>
          <div style={{ textAlign: "right" }}>
            {editable && (
              <Button
                type={"link"}
                onClick={() => setUploadWrapperDialogVisible(true)}
              >
                上传文件
              </Button>
            )}
          </div>
          <DataGrid
            pageSize={Math.floor((height - 300) / 42)}
            ref={gridRef}
            url={correspondenceUrl}
            descriptions={[
              {
                fieldShowName: "文件名称",
                fieldName: "filename",
              },
              {
                fieldShowName: "大小",
                render(file: { fileSize: number }) {
                  return (
                    <>
                      {file.fileSize >= 1
                        ? file.fileSize.toFixed(2) + " M"
                        : (file.fileSize * 1024).toFixed(2) + " KB"}
                    </>
                  );
                },
              },
              {
                fieldShowName: "上传人员",
                fieldName: "creatorId",
                dictionary: "base_staff",
              },
              {
                fieldShowName: "上传时间",
                fieldName: "createTime",
              },
              {
                fieldShowName: "操作",
                render(text: any, record: any) {
                  return (
                    <>
                      <Button
                        type={"link"}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          window.open(
                            `${BaseURL}/oa/common/attachment/downloadFiles?ids=${record.attachments}`
                          );
                        }}
                      />
                      <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                          const url = `/crm/crm/project/correspondence?uuid=${record.key}`;
                          axios.delete<Result<void>>(url).then((resp) => {
                            if (resp.data.Status === "1") {
                              message.info("删除成功");
                              setCorrespondenceUrl(
                                `/crm/crm/project/getList?projectId=${projectId}&categoryId=${
                                  treeCategory || ""
                                }&t=${new Date().getTime()}`
                              );
                            }
                          });
                        }}
                      >
                        <Button type={"link"} icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  );
                },
              },
            ]}
          />
        </Content>
        <UploadWrapper
          multiple={false}
          visible={uploadWrapperDialogVisible}
          onOk={(files) => {
            setUploadWrapperDialogVisible(false);
            axios
              .post<Result<void>>(`/crm/crm/project/correspondence`, {
                attachments: files.map((file) => file.uuid).join(","),
                projectId: projectId,
                categoryId: treeCategory,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  message.info("保存成功");
                  setCorrespondenceUrl(
                    `/crm/crm/project/getList?projectId=${projectId}&categoryId=${
                      treeCategory || ""
                    }&t=1`
                  );
                }
              });
          }}
          onCancel={() => setUploadWrapperDialogVisible(false)}
        />
      </Layout>
    </>
  );
};
