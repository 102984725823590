import i18n from "i18next";

var SerialNumberColumn = [
    {
        fieldShowName: i18n.t("public_serialNumber"),
        fieldName: "serialNumber",
        width: 150,
    },
    {
        fieldShowName: i18n.t("public_skuCode"),
        fieldName: "skuCode",
        width: 150,
    },
    {
        fieldShowName: i18n.t("public_skuName"),
        fieldName: "skuId",
        inputType:6,
        dictionary:"inventory_sku",
        width: 150,
    },
    // {
    //   title: i18n.t("warehouse"),
    //   fieldName: "warehouse",
    //   inputType:6,
    //   dictionary:"inventory_warehouse",
    //   defaultValue : 1,
    //   width: 150,
    // },
    {
        fieldShowName: i18n.t("public_location"),
        fieldName: "location",
        width: 150,
    },
];

export {SerialNumberColumn}
