import React, { useRef, useState } from "react";
import { Menu, Button, message } from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import { instance as axios } from "../../../../../../util/axios";
import UploadWrapper from "../../../../../../components/UploadWrapper";
import { editOtherLine } from "../../components/EditForm";
import { formatDateTime } from "../../util/DateFormat";
import i18n from "i18next";
export interface Param {
  orderId: string;
  serialNumber: string;
  customerId: string;
  orderPlanId: string;
}
export default ({ orderId, serialNumber, customerId, orderPlanId }: Param) => {
  const thisGridRef = useRef<any>();
  const [uploadWrapperDialogVisible, setUploadWrapperDialogVisible] = useState(
    false
  );
  function getRender(entityName: string, gridRef: React.MutableRefObject<any>) {
    return function (_: any, record: any) {
      return (
          <>
            {gridRef.current.isEditing(record) ? (
                <>
                  <a
                      style={{marginRight: 16}}
                      onClick={async () => {
                        await gridRef.current.save(record.key);
                        const data = gridRef.current.getDataItem(record.key);
                        data.startDate = formatDateTime(data.startDate);
                        data.expectedCompletionDate = formatDateTime(
                            data.expectedCompletionDate
                        );
                        data.completionDate = formatDateTime(data.completionDate);
                        const url = `/clothes/productionPlanManage/saveProductionPlanByOrderId?orderId=${data.orderId}&customerId=${data.customerId}&orderPlanId=${data.orderPlanId}`;
                        await axios
                            .post(url, {data: JSON.stringify([data])})
                            .then(() => {
                              message.info(i18n.t("保存成功"));
                            });
                        gridRef.current.refresh();
                      }}
                  >
                    {i18n.t("确认")}
                  </a>
                  {!record.key ? (
                      <a onClick={() => gridRef.current.remove(record.key)}>撤销</a>
                  ) : (
                      <></>
                  )}
                </>
            ) : (
                <>
                  <a
                      style={{margin: "0 10px"}}
                      onClick={() => {
                        const url = `/clothes/productionPlanManage/deleteProductionPlanById`;
                        axios.post(url, {uuid: record.uuid}).then(() => {
                          thisGridRef.current.save(record.key);
                          thisGridRef.current.refresh();
                          message.info(i18n.t("删除成功"));
                        });
                      }}
                  >
                    {i18n.t("删除")}
                  </a>
                </>
            )}
          </>
      );
    };
  }

  const { height } = useWindowSize();
  const columnsData = [
    {
      fieldShowName: i18n.t("操作",),
      fixed: "left",
      title: "option",
      width: 100,
      align: "center",
      isHiddenSearch: true,
      render: getRender("clothes_order_plan", thisGridRef),
    },
    {
      fieldShowName: i18n.t("订单号",),
      fieldName: "serialNumber",
      isHiddenSearch: true,
      width: 150,
    },
    {
      fieldShowName: i18n.t("客户",),
      fieldName: "customerId",
      isHiddenSearch: true,
      width: 100,
      dictionary: "crm_customer",
    },
    {
      fieldShowName: i18n.t("工序",),
      fieldName: "workingProcedureId",
      isHiddenSearch: true,
      width: 100,
      dictionary: "clothes_working_procedure",
      inputType: 6,
      editable: true,
    },
    {
      fieldShowName: i18n.t("顺序",),
      fieldName: "sort",
      isHiddenSearch: true,
      width: 80,
      editable: true,
    },
    {
      fieldShowName: i18n.t("派单数量",),
      fieldName: "assignQuantity",
      isHiddenSearch: true,
      width: 80,
      editable: true,
    },
    {
      fieldShowName: i18n.t("已完成数量",),
      fieldName: "completedQuantity",
      isHiddenSearch: true,
      width: 100,
    },
    {
      fieldShowName: i18n.t("加工厂",),
      fieldName: "factoryId",
      isHiddenSearch: true,
      width: 100,
      dictionary: "clothes_factory",
      inputType: 6,
      editable: true,
    },
    {
      fieldShowName: i18n.t("单价",),
      fieldName: "unitPrice",
      isHiddenSearch: true,
      width: 100,
      editable: true,
    },
    {
      fieldShowName: i18n.t("进度",),
      fieldName: "planStatus",
      isHiddenSearch: true,
      width: 100,
    },
    {
      fieldShowName: i18n.t("开始日期",),
      fieldName: "startDate",
      isHiddenSearch: true,
      width: 120,
      inputType: 4,
    },
    {
      fieldShowName: i18n.t("预计完成日期",),
      fieldName: "expectedCompletionDate",
      isHiddenSearch: true,
      width: 120,
      inputType: 4,
    },
    {
      fieldShowName: i18n.t("实际完成日期",),
      fieldName: "completionDate",
      isHiddenSearch: true,
      width: 120,
      inputType: 4,
    },
  ];
  const [columns] = useState<any[]>(columnsData);
  return (
      <div className="u-box u-basic-page">
        <div className="u-box-head">
          <div className="u-box-toolbar">
            <div className="u-icon-list u-btn-list">
              <Button
                  type="primary"
                  onClick={() => {
                    var currentData = thisGridRef.current.getData();
                    var data = {
                      serialNumber: serialNumber,
                      orderId: orderId,
                      orderPlanId: orderPlanId,
                      customerId: customerId,
                      lineNumber: currentData.length + 1,
                    };
                    thisGridRef.current.addData(data);
                  }}
                  className="u-btn-add"
              >
                {i18n.t("新增计划")}
              </Button>
            </div>
          </div>
        </div>

        <div className="u-box-con">
          <DataGrid
              ref={thisGridRef}
              pagination={true}
              sorter={{field: "sort", order: "asc"}}
              pageSize={Math.floor((height - 280) / 42)}
              descriptions={columns}
              url={
                `/clothes/productionPlanManage/getProductionPlanListByOrderId?orderId=` +
                orderPlanId
              }
              loadAllDictionary={true}
              onClick={async (record: any) => {
                await editOtherLine(thisGridRef, record);
              }}
          />
          <UploadWrapper
              multiple={false}
              visible={uploadWrapperDialogVisible}
              onOk={(file) => {
                setUploadWrapperDialogVisible(false);
                axios
                    .post("/clothes/utils/excelUtil/importExcel", {
                      tableName: "crm_customer",
                      treeField: "",
                      treeParentValue: "",
                      defaultValueFields: "type,status,taxIncentives",
                      defaultValues: "a,0,1",
                      fileId: file[0].uuid,
                      startIndex: 1,
                      endIndex: 0,
                    })
                    .then((resp) => {
                      if (resp.data.Status === "1") {
                        message.info(resp.data.Message);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }
                    });
              }}
              onCancel={() => setUploadWrapperDialogVisible(false)}
          />
        </div>
      </div>
  );
};
