import React, {useContext, useState, useEffect, useRef, useImperativeHandle, useCallback} from 'react'
import {Table, Input, Button, Form, Row, Col, Select, Tag, TreeSelect, message,Modal} from 'antd'
import { ExclamationCircleOutlined,PlusOutlined,RedoOutlined } from "@ant-design/icons/lib";
import {DictionarySelect} from "../../../../../../components/DictionarySelect";
import {instance as axios} from "../../../../../../util/axios";
import {TreeWrapper} from "../../../../../../components/TreeWrapper";
import TreeSelectByType from "../../../../../../components/TreeSelectByType";
import {Result} from "../../../../../../schemas/common";
import {DictionarySelects} from "../../../../../../components/DictionarySelectFilter";
import {isEmpty, isNullOrUndefined} from "../../util/Common";
import i18n from "i18next";
import {editOtherLine, saveDataGrid} from "../../components/EditForm";
import {DataGrid} from "../../../../../../components/DataGrid";
import AddSize from "./AddSize";
import {createNewTab} from "../../../../../../util/tyy";
import storage from "../../util/Stroage";
import AddColor from "../../ColorManager/Detail/index"
import {FaLeaf} from "react-icons/all";
import ColorDiv from "../../../../../../components/ColorDiv";
const EditableContext = React.createContext()

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	checkColorIndex,
	skuColorIndex,
	...restProps
}) => {
	const [editing, setEditing] = useState(false)
	const inputRef = useRef()
	const form = useContext(EditableContext)

	useEffect(() => {
		if (editing) {
			inputRef.current.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		console.log(record)
		if(!checkColorIndex(record)){
			if(skuColorIndex && skuColorIndex != "null"){
				message.error(i18n.t("色卡不匹配"));
			}else{
				message.error(i18n.t("该产品未设置色序"));
			}
			return;
		}
		setEditing(!editing)
		form.setFieldsValue({
			[dataIndex]: record[dataIndex]
		})
	}

	const save = async (e) => {
		try {
			const values = await form.validateFields();
			for(var key in values){
				var value = values[key];
				if(isNaN(Number(value)) || Number(value) == 0){
					values[key] = "";
				}else{
					values[key] = Number(value);
				}
				// values[key] = isNaN(Number(value))?"":Number(value);
			}
			console.log(values)
			toggleEdit()
			handleSave({ ...record, ...values })
		} catch (errInfo) {
			console.log('Save failed:', errInfo)
		}
	}

	const focus = async (e) => {
		var element = document.getElementById("numberInput");
		if(element){
			element.select()
		}
	}

	let childNode = children

	if (editable) {
		childNode = editing ? (
			<Form.Item style={{margin: 0}} name={dataIndex}>
				<Input id={"numberInput"}   style={{margin: '0'}} ref={inputRef} onPressEnter={save} onBlur={save} onFocus={focus}/>
			</Form.Item>
		) : (
			<div id={dataIndex} onClick={toggleEdit} className="editable-cell-value-wrap">
				{children}
			</div>
		)
	}

	return <td {...restProps} style={{background: `${childNode.color}`}}>{childNode}</td>
}

export default React.forwardRef((props, ref,initData) => {
	return <ChildComp {...props} myRef={ref}></ChildComp>
})

const getColorTag = (color,colorName)=>{
	if (color && color.indexOf("/") >= 0) {
		return (
			<div>
				<Tag className="u-tag" color={color.split("/")[0]}>{}</Tag>
				<Tag style={{float:"right"}} className="u-tag" color={color.split("/")[1]}>{}</Tag>
			</div>
		)
	} else {
		return <Tag className='u-tag' color={color}>{}</Tag>
	}
}

const getFontColor = (a)=>{
	if (a.substr(0, 1) == "#") a = a.substring(1);
	a = a.toLowerCase();
	var b = new Array();
	for (var x = 0; x < 3; x++) {
		b[0] = a.length == 6 ? a.substr(x * 2, 2) : a.substr(x * 1, 1) + a.substr(x * 1, 1);
		b[3] = "0123456789abcdef";
		b[1] = b[0].substr(0, 1);
		b[2] = b[0].substr(1, 1);
		b[20 + x] = b[3].indexOf(b[1]) * 16 + b[3].indexOf(b[2])
	}
	var color = 0.213 * b[20] + 0.715 * b[21] + 0.072 * b[22] > 255 / 2;
	return color? '#000000': '#ffffff'
}

const ChildComp = (props) => {


	const columnsD = [
		{
			title: i18n.t('颜色',),
			dataIndex: 'color',
			sortNum: 1,
			width:"130px",
			render: (text, row) => {
					return (
						<ColorDiv colors={row.color.split(",")} colorName={row.colorName} nameColorId={row.nameColorId}/>
					)
			},
		},
	]

	const getColumnCount = (record)=>{
		var count = 0;
		for(var key in record){
			var pat = /^size+[0-9]+$/;
			if(key.match(pat)){
				var itemCount = record[key];
				itemCount = isNaN(Number(itemCount)) ? 0 : Number(itemCount);
				count = count + itemCount;
			}
		}
		return count;
	}

	const endColumns = [
		{
			title: i18n.t('数量合计',),
			dataIndex: 'hj',
			sortNum: 101,
			width:50,
			render: (text, record, index) => {
				return (
					<div className="u-tdColor" style={{textAlign: 'center'}}>
						{
							getColumnCount(record)
						}
					</div>
				)
			},
		}]
	const { myRef,initData } = props
	console.log(initData)

	const [form] = Form.useForm()
	const [columnsSource, setColumnsSource] = useState(columnsD)
	const [dataSource, setDataSource] = useState([])
	const [rowSelectionData, setRowSelectionData] = useState([])
	const [rowData, setRowData] = useState([])
	const [rowFlag, setRowFlag] = useState(true)
	const [columnType, setColumnType] = useState()
	const [rowCell, setRowCell] = useState([])
	const [sizeFilter, setSizeFilter] = useState()
	const [selectCustomer, setSelectCustomer] = useState(initData.selectCustomer);
	const [skuId, setSkuId] = useState("");
	const [skuColorIndex, setSkuColorIndex] = useState("");
	const [selectBrand, setSelectBrand] = useState(initData.selectBrand);
	const [orderId, setOrderId] = useState(initData.orderId);
	const [visible, setVisible] = useState(false);
	const [addColor, setAddColor] = useState(false);
	const [templateId, setTemplateId] = useState("");
	const [colorType, setColorType] = useState("");
	const [sizeData, setSizeData] = useState([]);
	const [refresh, setRefresh] = useState(1);
	const dataGridRef = useRef(null);
	const colorRef = useRef()


	const handleSave = (row) => {
		const newData = [...dataSource]
		const index = newData.findIndex((item) => (row.key && row.key === item.key) || (row.uuid && row.uuid ===item.uuid))
		const item = newData[index]
		newData.splice(index, 1, { ...item, ...row })
		setDataSource(newData)
	}

	const checkColorIndex = (row)=>{
	    if("true" == skuColorIndex){
            return true;
        }
	    //没维护色序
	    if("null" == skuColorIndex && "2" === row.typeId){
            return false;
        }
	    //不维护色卡的情况
	    if("0" == skuColorIndex && "1" === row.typeId){
            return true;
        }
		if("null" != skuColorIndex){//多色
			if(skuColorIndex != row.colorIndex){
				return false;
			}else{
				return true;
			}
		}
		return true;
	}

	  
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setRowSelectionData(selectedRows)
		}
	}
	
	useEffect(()=>{
		form.setFieldsValue(initData.colorData);
		if(initData.colorData && initData.colorData.colorColumns){
			setColumnsSource(initData.colorData.colorColumns);
		}
		if(initData.colorData && initData.colorData.colorData){
			setDataSource(initData.colorData.colorData);
		}
		if(initData.colorData && initData.colorData.sizeTemplate){
			setSizeFilter(initData.colorData.sizeTemplate);
		}
		if(initData.selectCustomer){
			setSelectCustomer(initData.selectCustomer);
		}
		if(initData.selectBrand){
			setSelectBrand(initData.selectBrand)
		}
		if(initData.baseData && initData.baseData.skuId){
			setSkuId(initData.baseData.skuId)
		}
	},[])

	useEffect(()=>{
		if(initData.colorData.sizeType && initData.colorData.sizeTemplateId){
			var size = {skuSizeTypeId:initData.colorData.sizeTemplateId,skuSizeId:initData.colorData.sizeType};
			if(size){
				form.setFieldsValue({sizeTemplate:size.skuSizeTypeId,sizeType:size.skuSizeId})
				setSizeFilter(" templateId='" + size.skuSizeTypeId+"'");
				setTemplateId(size.skuSizeTypeId);
				onChangeType(size.skuSizeId);
			}
			getSizeName(size.skuSizeId)
		}else{
			//根据客户加载默认尺码分类和尺码类型
			axios.get("/clothes/order/getCustomerSkuSizeById?id="+initData.selectBrand).then((resp)=>{
				if(resp.data.Status == 1){
					var size = resp.data.Data;
					if(size && size.skuSizeTypeId && size.skuSizeId){
						form.setFieldsValue({sizeTemplate:size.skuSizeTypeId,sizeType:size.skuSizeId})
						setSizeFilter(" templateId='" + size+"'");
						onChangeType(size.skuSizeId);
						getSizeName(size.skuSizeId)

					}else{
						var size = {skuSizeTypeId:"1",skuSizeId:"964652874fab477eb7459ce078a46328"};
						if(size){
							form.setFieldsValue({sizeTemplate:size.skuSizeTypeId,sizeType:size.skuSizeId})
							setSizeFilter(" templateId='" + size.skuSizeTypeId+"'");
							setTemplateId(size.skuSizeTypeId);
							onChangeType(size.skuSizeId);
						}
						getSizeName(size.skuSizeId)

					}
				}
			})

		}
	},[])


	useEffect(()=>{
	    if(initData.colorData.sizeType){
            onChangeType(initData.colorData.sizeType);
        }

	},[])
	//加载默认颜色
	useEffect(()=>{
		if(!initData.colorData.colorData){
			colorChange(2);
		}
	},[])
	//加载色序
	useEffect(()=>{
		if(skuId && skuId.length > 0){
			axios.get("/clothes/sku/getSkuColorIndex?skuId="+skuId).then((resp)=>{
				if(resp && resp.data.Status == 1){
					setSkuColorIndex(resp.data.Data)
				}
			})
		}
	},[skuId])

	useImperativeHandle(myRef, () => {
		const data = {
			columnsSource: columnsSource,
			rowSelectionData: rowSelectionData,
			dataSource: dataSource,
			columnType: columnType
		}
		return {
			...data,
			onReset,
			getDatas
		}
	})
	
	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		}
	}
		
	const columns = columnsSource.map((col) => {
		if (!col.editable) {
			return col
		}
	
		return {
			...col,
			render: (text, record, index) => {
				return (
					<div className="u-tdColor1" style={{height:30}}>{isEmpty(text)?"":isNaN(Number(text))?"":Number(text)}</div>
				)
			},
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSave,
				checkColorIndex:checkColorIndex,
				skuColorIndex: skuColorIndex
			})
		}
	})
	
	const rowCopy = () => {
		if (!rowSelectionData.length) return
		const newData = []
		dataSource.forEach ((obj) => {
			if (rowSelectionData[0].key === obj.key) {
				newData.push(obj)
			}
		})
		setRowData(newData)
		setRowFlag(false)
	}

	const generateUUID = useCallback(function generateUUID() {
		var d = new Date().getTime();
		var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
			c
		) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
		return uuid;
	}, []);
	
	const rowPaste = () => {
		//粘贴之前校验色序
		const rowSelect = rowSelectionData[0];
		if(!checkColorIndex(rowSelect)){
			if(skuColorIndex && skuColorIndex != "null"){
				message.error(i18n.t("色卡不匹配"));
			}else{
				message.error(i18n.t("该产品未设置色序"));
			}
			return;
		}
		const rD = JSON.parse(JSON.stringify([...rowData]))
		const newData = dataSource.map((obj, index) => {
			if ((obj.key && rowSelectionData[0].key === obj.key) || (obj.uuid && rowSelectionData[0].uuid === obj.uuid)) {
				let uuid = generateUUID()
				let rObj = rD[0]
				rObj.color = obj.color
				rObj.colorName = obj.colorName
				rObj.colorId = obj.colorId
				rObj.nameColorId = obj.nameColorId
				rObj.key = uuid
				rObj.uuid = uuid
				return rObj
			}
			return obj
		})
		setDataSource(newData)
		// setRowFlag(true)
	}
	
	const onReset = () => {
		const reformattedArray = dataSource.map((obj, index) => {
			const rObj = {}
			for (let r in obj) {
				if(r.indexOf("size")>=0){
					rObj[r] = undefined;
				}
			}
			rObj.key = obj.key
			rObj.uuid = obj.uuid
			rObj.color = obj.color
			rObj.colorName = obj.colorName
			rObj.colorId = obj.colorId
			rObj.docId = obj.docId
			return rObj
		})
		setDataSource(reformattedArray)
	}
	const onCurrentReset = () => {
		if (!rowSelectionData.length) return
		console.log(rowSelectionData)
		const reformattedArray = dataSource.map((obj, index) => {
			const rObj = {}
			for (let r in obj) {
				var pat = /^size+[0-9]+$/;
				if(r.match(pat)){
					if(rowSelectionData[0].color == obj.color && rowSelectionData[0].colorName == obj.colorName){
						rObj[r] = undefined;
					}else{
						rObj[r] = obj[r];
					}
				}
			}
			rObj.key = obj.key
			rObj.uuid = obj.uuid
			rObj.color = obj.color
			rObj.colorName = obj.colorName
			rObj.colorId = obj.colorId
			rObj.docId = obj.docId
			return rObj
		})
		if(rowSelectionData[0].isCreatedProduce == 1){
			Modal.confirm({
				title: i18n.t('该产品已进入生产流程，是否确认修改',),
				icon: <ExclamationCircleOutlined />,
				content: i18n.t('该产品已进入生产流程，是否确认修改'),
				onOk() {
					setDataSource(reformattedArray)
				},
				onCancel() {},
			})
		}else{
			setDataSource(reformattedArray)
		}
	}

	const getDatas = ()=>{
		var data = form.getFieldsValue();
		data.colorColumns = columns;
		data.colorData = dataSource;
		return data;
	}
	
	const onChangeType = (v) => {
		axios.get("/clothes/size/getSizeColumnById?sizeId=" + v)
			.then(function (resp) {
				var res = resp.data;
				if (res.Status == 1) {
					var data = resp.data.Data;
					var newColumns = [];
					if(data!=undefined){
						data.map((item)=>{
							var newItem = {};
							newItem.title = item.fieldShowName;
							newItem.dataIndex = item.fieldName;
							newItem.ellipsis = true;
							newItem.editable = true;
							newItem.width = item.width;
							newColumns.push(newItem);
						})
						let arr = columnsD.concat(newColumns).concat(endColumns);
						setColumnsSource(arr)
					}
					// setDataSource([])
				}
			})
	};

	useEffect(()=>{
		if(isEmpty(templateId) || isEmpty(orderId)){
			return;
		}
		axios.post("/clothes/size/getSizeByTemplateIdAndOrderId?templateId="+templateId+"&orderId="+orderId).then((resp)=>{
			if(resp && resp.data.Status == 1){
				setSizeData(resp.data.Data)
			}
		})
	},[templateId,orderId,visible])

	const getSizeName = (sizeId)=>{
		const url = "/oa/common/CommonApi/GetZLSelectDict?dictionaryName=clothes_size&selectId="+sizeId+"&filter=setValue";
		axios.get(url).then((res)=>{
			const resp = res.data.Data;
			if(resp && resp.length > 0){
				form.setFieldsValue({sizeName:resp[0].name})
			}
		})
	}

	const colorChange = (v)=>{
		setColorType(v);
		axios.post("/clothes/colorManager/getEnableList",{categoryId:v,sortField: "createTime", sort: "desc"}).then((resp)=>{
			if(resp.data.Status == 1){
				var colorData = [];
				var addedColorData = [];
				var tempColorData = [...dataSource];
				tempColorData.map((currentItem)=>{
					var isAppend = false;
					for(var key in currentItem){
						var pat = /^size+[0-9]+$/;
						if(key.match(pat) && currentItem[key] > 0){
							isAppend = true;
						}
					}
					if(isAppend){
						colorData.push(currentItem)
						addedColorData.push(currentItem)
					}
				})
				var colors = resp.data.Data.data;
				colors.map((item)=>{
					//默认显示已经启用的颜色
					//如果当前已经有了这个颜色,就不需要再附加颜色了
					var flag = false;
					addedColorData.map((currentItem)=>{
						if(currentItem.colorName == item.name){
							flag = true;
						}
					})
					if(item.status == 0 || flag){
						return false;
					}
					var colorItem = {};
					colorItem.colorId = item.uuid;
					colorItem.colorName = item.name
					// if(item.secondRgb){
					// 	colorItem.color = item.rgb + "/" + item.secondRgb;
					// }else{
					// 	colorItem.color = item.rgb
					// }

					colorItem.color = item.color
					colorItem.nameColorId = item.nameColorId
					colorItem.colorIndex = item.colorIndex
					colorItem.typeId = item.typeId
					if(item.secondRgb){
						colorItem.secondColor = item.secondRgb;
					}
					colorItem.key = item.uuid;
					colorItem.uuid = item.uuid;
					colorData.push(colorItem);
				})
				setDataSource(colorData);
			}
		})
	}

	return (
		<div style={{position: 'relative', height: '500px'}}>
			<Form form={form}>
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item
							name="sizeTemplate"
							label={i18n.t("尺码分类")}
						>
							<DictionarySelects
								filter={" customerId='" + selectCustomer + "' and brandId='" + selectBrand + "' or ( isDefault = 1 or customerId  is null )  and isDelete = 0"}
								entityName={"clothes_size_template"} onChange={(v) => {
								setTemplateId(v);
								setSizeFilter(" templateId='" + v + "'");
								form.setFieldValue("sizeType","")
							}}/>
						</Form.Item>
					</Col>
					<Col span={8}>
						{sizeFilter && <Form.Item
							name="sizeType"
							label={i18n.t("尺码类型")}
						>
							<Select onChange={(item, option) => {
								form.setFieldsValue({sizeName: option.children})
								onChangeType(item);
							}}>
								{
									sizeData.map((item)=>{
										return(
											<Select.Option key={item.uuid}>
												{item.name}
											</Select.Option>
										)
									})
								}
							</Select>
							{/*<DictionarySelects filter={sizeFilter+" or orderId = '"+orderId+"'"} entityName={"clothes_size"}
											   onChange={(item, option) => {
												   form.setFieldsValue({sizeName: option.children})
												   onChangeType(item);
											   }}/>*/}
						</Form.Item>}
					</Col>
					<Form.Item
						hidden={true}
						name="sizeName"
						label="尺码类型名称"
					>
						<Input/>
					</Form.Item>
					<Button icon={<PlusOutlined />} onClick={()=>{
						setVisible(true)
					}}></Button>
				</Row>
				<Row gutter={8}>
					<Col span={16}>
						<Form.Item
							name="colorType"
							label={i18n.t("色卡种类")}
						>
							<TreeSelectByType entityName={"color_sku_category"} onChange={colorChange}/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Button icon={<PlusOutlined />} onClick={()=>{
							const colorType = form.getFieldValue("colorType");
							if(isEmpty(colorType)){
								message.info(i18n.t("请选择色卡种类"));
								return;
							}
							setAddColor(true)
							// createNewTab(storage.get("reactUrl")+"/clothes/colorManager/detail/"+generateUUID()+"/add/false/"+colorType,i18n.t("新增颜色"));

						}}></Button>
					</Col>
				</Row>
			</Form>

			<Table
				rowKey={"uuid"}
				className="editTable"
				components={components}
				bordered
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				rowSelection={{
					type: 'radio',
					...rowSelection,
				}}
				scroll={{y: 300}}
				style={{height: "auto", overflowY: "auto"}}
			/>
			<div className="u-btn-toolbar u-btn-bottom" style={{padding: '0'}}>
				<Button type="primary" onClick={rowCopy}>{i18n.t("复制")}</Button>
				<Button type="primary" onClick={rowPaste} disabled={rowFlag}>{i18n.t("粘贴")}</Button>


				<Button onClick={onReset}>
					{i18n.t("清除全部数据")}
				</Button>
				<Button onClick={onCurrentReset}>
					{i18n.t("清除选中数据")}
				</Button>

			</div>
			<Modal
				getContainer={false}
				style={{top: '20px'}}
				width={'800px'}
				visible={visible}
				title={i18n.t("新增尺码")}
				okText={i18n.t("确定")}
				cancelText={i18n.t("取消")}
				onCancel={()=>{
					setVisible(false)
				}}
				onOk={async () => {
					await saveDataGrid(dataGridRef);
					let sizeList = await dataGridRef.current.getData();
					axios
						.post(`/clothes/size/insertOrderSizeList`, {
						templateId: templateId,
						orderId: orderId,
						sizeList: JSON.stringify(sizeList),
					})
						.then((resp) => {
							message.success(i18n.t("保存成功"));
							setVisible(false)
						});
				}}
				bodyStyle={{height: 300}}
				maskClosable={false}
				destroyOnClose={true}
			>
				<AddSize orderId={orderId} templateId={templateId} dataGridRef={dataGridRef}/>
			</Modal>
			<Modal
				getContainer={false}
				style={{top: '20px'}}
				width={'800px'}
				visible={addColor}
				title={i18n.t("新增颜色")}
				okText={i18n.t("确定")}
				cancelText={i18n.t("取消")}
				onCancel={()=>{
					setAddColor(false)
				}}
				onOk={async () => {
					colorRef.current.submit();
				}}
				bodyStyle={{height: "500px",overflow: "scroll"}}
				maskClosable={false}
				destroyOnClose={true}
			>
				<AddColor colorType={colorType} isFromOrder={"1"} ref={colorRef} onOk={()=>{
					setAddColor(false);
					colorChange(colorType)
				}}/>
			</Modal>


		</div>
	);
}