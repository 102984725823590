import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Empty,
  Layout,
  Modal,
  Switch,
  Tabs,
  Tag,
  Tree,
  Typography,
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { instance as axios } from "../../util/axios";
import { getTree } from "../../util/ant/tree";
import "./index.css";
import { useLocalStorage, useUpdateEffect } from "react-use";
import { SessionContext } from "../../containers/App";
import { User } from "./schema";
import { BaseURL } from "../../constants/url";

const { Content, Sider, Footer } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

interface UserSelectProps {
  visible: boolean;
  multiple?: boolean;
  onCancel: Function;
  onOk: Function;
  defaultSelectedUsers: User[];
}

async function getUsersByDepartmentId(departmentId: string) {
  const resp = await axios.post(
    "/oa/common/StaffSelect/GetStaff?dptId=" + departmentId
  );
  return resp.data.Data;
}

export const UserSelect = function ({
  visible = false,
  multiple = true,
  defaultSelectedUsers = [],
  onCancel,
  onOk,
}: UserSelectProps) {
  const [departmentTreeData, setDepartmentTreeData] = useState<any>([]);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [disabledUserVisible, setDisabledUserVisible] = useState(false);
  const session = useContext(SessionContext);
  const [recentSelectedUsers, setRecentSelectedUsers] = useLocalStorage<{
    [key: string]: User[];
  }>("common.user-select.recently", {});

  function handleUserClick(user: User) {
    const index = selectedUsers.findIndex((item) => item.id === user.id);
    if (index > -1) {
      const copy = [...selectedUsers];
      copy.splice(index, 1);
      if (multiple) {
        setSelectedUsers(copy);
      } else {
        setSelectedUsers([]);
      }
    } else {
      if (multiple) {
        setSelectedUsers([...selectedUsers, user]);
      } else {
        setSelectedUsers([user]);
      }
      const myRecentSelectedUsers = recentSelectedUsers[session.id] || [];
      const cacheIndex = myRecentSelectedUsers.findIndex(
        (item) => item.id === user.id
      );
      if (cacheIndex > -1) {
        myRecentSelectedUsers.splice(cacheIndex, 1);
      }
      setRecentSelectedUsers({
        ...recentSelectedUsers,
        [session.id]: [user, ...myRecentSelectedUsers].splice(0, 10),
      });
    }
  }

  function renderUsers(users: User[]) {
    return (
      <Scrollbars className={"scrollbar"}>
        {users
          .filter((user: User) => {
            return disabledUserVisible ? true : user.disabled;
          })
          .map((user: User) => {
            const src = `${BaseURL}/oa/common/attachment/showAvatar?userId=${user.id}`;
            const split = user.name.split("-");
            const name = split.length === 2 ? split[1] : split[0];
            const department = split.length === 2 ? split[0] : "";
            return (
              <div
                className={"user"}
                key={"user-" + user.id}
                onClick={() => handleUserClick(user)}
              >
                <Avatar size={"small"} src={src} />
                <Text className={"name"}>{name}</Text>
                <Text className={"name"} type={"secondary"}>
                  {department}
                </Text>
              </div>
            );
          })}
      </Scrollbars>
    );
  }

  useEffect(
    function () {
      setSelectedUsers(defaultSelectedUsers);
    },
    [defaultSelectedUsers]
  );

  useUpdateEffect(() => {
    const rootDepartmentId = departmentTreeData[0].key;
    getUsersByDepartmentId(rootDepartmentId).then((data) =>
      setDepartmentUsers(data)
    );
  }, [departmentTreeData]);

  return (
    <>
      <Modal
        title={"选择员工"}
        visible={visible}
        width={700}
        onCancel={() => onCancel && onCancel()}
        onOk={() => onOk && onOk(selectedUsers)}
        maskClosable={false}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Layout>
          <Content>
            <Tabs
              onChange={(key) => {
                switch (key) {
                  case "department":
                    getUsersByDepartmentId("myDptId").then((users) => {
                      setDepartmentUsers(users);
                    });
                    break;
                  case "tree":
                    getTree("base_department").then((resp) => {
                      setDepartmentTreeData(resp);
                    });
                    break;
                  default:
                    break;
                }
              }}
              tabBarExtraContent={
                <>
                  <Switch
                    checked={disabledUserVisible}
                    onChange={(checked) => setDisabledUserVisible(checked)}
                    size={"small"}
                    checkedChildren={"显示离职"}
                    unCheckedChildren={"隐藏离职"}
                  />
                  {/*<DictionarySelect*/}
                  {/*  size={"small"}*/}
                  {/*  style={{ width: 100, marginLeft: 10 }}*/}
                  {/*  entityName={"base_staff"}*/}
                  {/*  onChange={(value: any, option: any) => {*/}
                  {/*    handleUserClick({*/}
                  {/*      id: option.key,*/}
                  {/*      name: option.children,*/}
                  {/*      disabled: true,*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*/>*/}
                </>
              }
            >
              <TabPane tab={"最近"} key={"recent"}>
                <Layout>
                  <Content style={{ height: 300 }}>
                    {!recentSelectedUsers[session.id] ||
                    recentSelectedUsers[session.id].length === 0 ? (
                      <Empty
                        style={{ marginTop: 100 }}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      renderUsers(recentSelectedUsers[session.id])
                    )}
                  </Content>
                </Layout>
              </TabPane>
              <TabPane tab={"本部门"} key={"department"}>
                <Layout>
                  <Content style={{ height: 300 }}>
                    {departmentUsers.length === 0 ? (
                      <Empty
                        style={{ marginTop: 100 }}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      renderUsers(departmentUsers)
                    )}
                  </Content>
                </Layout>
              </TabPane>
              <TabPane tab={"按部门"} key={"tree"}>
                <Layout>
                  <Sider style={{ height: 300 }}>
                    <Scrollbars className={"scrollbar"}>
                      <Tree
                        defaultCheckedKeys={[departmentTreeData.key]}
                        treeData={departmentTreeData}
                        onSelect={async (keys) => {
                          setDepartmentUsers(
                            await getUsersByDepartmentId(keys[0] as string)
                          );
                        }}
                        onLoad={async () => {
                          if (departmentTreeData.length > 0) {
                            setDepartmentUsers(
                              await getUsersByDepartmentId(
                                departmentTreeData[0].key
                              )
                            );
                          }
                        }}
                        defaultExpandAll={true}
                      />
                    </Scrollbars>
                  </Sider>
                  <Content
                    style={{
                      height: 300,
                    }}
                  >
                    {renderUsers(departmentUsers)}
                  </Content>
                </Layout>
              </TabPane>
            </Tabs>
          </Content>
          <Footer className={"footer"}>
            <Scrollbars className={"scrollbar"}>
              <div style={{ marginTop: -10 }}>
                {selectedUsers.map((user) => {
                  const split = user.name.split("-");
                  const name = split.length === 2 ? split[1] : split[0];
                  return (
                    <Tag
                      key={"selected-" + user.id}
                      style={{ marginTop: 10 }}
                      onClose={() => {
                        handleUserClick(user);
                      }}
                      closable={true}
                    >
                      {name}
                    </Tag>
                  );
                })}
              </div>
            </Scrollbars>
          </Footer>
        </Layout>
      </Modal>
    </>
  );
};
