import React, {useCallback, useEffect, useState} from "react";
import {Button, message, Modal, Upload} from "antd";
import { instance as axios } from "../../util/axios";
import { BaseURL } from "../../constants/url";
import i18n from "i18next";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons/lib";

export interface Param {
    value?: string | string[];
    onChange?: (value: any) => void;
}

const Img = ({ value, onChange }: Param) => {
    const [imageUrl,setImageUrl] = useState("");
    useEffect(() => {
        axios.get(`/oa/common/attachment/index?attachIds=${value}`).then((resp) => {
            if (resp.data.Status === "1") {
                    resp.data.Data.map(
                        (item: { filename: string; uuid: string; suffix: string }) => {
                            let url: string;
                            url = BaseURL+"/oa/common/attachment/showImageAttachment?id=" + item.uuid;
                            setImageUrl(url);
                            // getImageUrlById(item.uuid)
                            return {
                                name: item.filename,
                                uid: item.uuid,
                                status: "done",
                                url: imageUrl,
                                response: { Data: { uuid: item.uuid } },
                            };
                        }
                    )
            }
        });
    }, [value]);
    return (
        <div className="u-uploader-box">
            <img src={imageUrl} className="u-uploader-img" />
        </div>
    );
};
export default Img;
