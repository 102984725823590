import React, {useCallback, useState} from "react";
import {Form, Input, Button, Radio, Image, message, Modal} from "antd";
import "../../CSS/customer.css";
import { useHistory } from "react-router";
import "../../CSS/skuDetaile.css";
import TextArea from "antd/es/input/TextArea";
import {instance as axios} from "../../../../../../util/axios";
import {Result} from "../../../../../../schemas/common";
import {FormInstance} from "antd/es/form";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import i18n from "i18next";
import {usePermission} from "../../../../../../util/permession";
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
interface Params {
  form: FormInstance;
  skuId: string;
  property: string;
}
export default ({ form,skuId,property }: Params) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const callBack = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/sku/skuManage/"+property);
        },
      });
    }else{
      history.push("/sku/skuManage/"+property);
    }

  }, []);
  const onFinish = async (values: any) => {
    console.log("Finish:", values);
    let categoryId = values.categoryId;
    values.categoryId = categoryId.join(",");
    if (values.isBigPackage == undefined) {
      values.isBigPackage = "0";
    } else {
      values.isBigPackage = values.isBigPackage[0];
    }
    if (values.isProcessingMaterial === undefined) {
      values.isProcessingMaterial = "0";
    } else {
      values.isProcessingMaterial = values.isProcessingMaterial[0];
    }
    if (values.isMulticolor === undefined) {
      values.isMulticolor = "0";
    } else {
      values.isMulticolor = values.isMulticolor[0];
    }
    axios
        .post<Result<any[]>>(`/clothes/sku/saveSku`, {
          data: JSON.stringify(values),
          skuId:skuId,
        })
        .then((resp) => {
          if (resp.data.Status === "1") {
            message.info(i18n.t("保存成功"));
            setLoading(false);
            setIsChange(false);
            axios
                .post<Result<any[]>>(`/clothes/sku/getSkuById`, {
                  uuid: skuId,
                })
                .then((resp:any) => {
                  if (resp.data.Status === "1") {
                    form.setFieldsValue(resp.data.Data);
                    form.setFieldsValue({
                      categoryId:resp.data.Data.categoryId.split(",")
                    })
                  }
                });
          }
        });
  };
  const onFinishFailed = (values: any) => {
    let arr: any = [];
    values.errorFields.map((item: any) => {
      arr.push(<li>{item.errors}</li>);
    });
    setLoading(false);
    window.scrollTo(0, 0);
  };
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };

  const setFieldByNameAndValue = useCallback(function setFieldByNameAndValue(fieldName:string,value:string) {
    if(form.getFieldValue(fieldName)===value){
      switch (fieldName) {
        case "washingId":
          form.setFieldsValue({
            washingId:""
          })
          break;
        case "bleachId":
          form.setFieldsValue({
            bleachId:""
          })
          break;
        case "dryingId":
          form.setFieldsValue({
            dryingId:""
          })
          break;
        case "IroningId":
          form.setFieldsValue({
            IroningId:""
          })
          break;
        case "dryCleaningId":
          form.setFieldsValue({
            dryCleaningId:""
          })
          break;
        default:
          break;
      }
    }
  },[])

  return (
      <div className="u-box-con u-fixed-padding">
        <div className="u-tabsA">
          <Form.Provider>
            <Form
                {...layout}
                form={form}
                layout="horizontal"
                name="basicForm"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError={true}
                onValuesChange={onValuesChange}
            >
              <Form.Item label={i18n.t("商品洗涤标志")}>
                <div>{i18n.t("洗涤")}</div>
                <Form.Item label="" name="washingId">
                  <Radio.Group>
                    <Radio.Button value="1" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "1");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/donotwash.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="2" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "2");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/handwash.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="3" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "3");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/30.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="4" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "4");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/31.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="5" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "5");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/32.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="6" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "6");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/40.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="7" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "7");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/41.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="8" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "8");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/42.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="9" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "9");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/50.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="10" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "10");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/51.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="11" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "11");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/60.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="12" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "12");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/61.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="13" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "13");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/70.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="14" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "14");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/80.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="15" onClick={(e) => {
                      setFieldByNameAndValue("washingId", "15");
                    }}>
                      <Image
                          src={require("../../Images/Sku/xidi/81.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div>{i18n.t("漂白")}</div>
                <Form.Item label="" name="bleachId">
                  <Radio.Group>
                    <Radio.Button value="1" onClick={(e) => {
                      setFieldByNameAndValue("bleachId", "1");
                    }}>
                      <Image
                          src={require("../../Images/Sku/piaobai/piaobai1.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="2" onClick={(e) => {
                      setFieldByNameAndValue("bleachId", "2");
                    }}>
                      <Image
                          src={require("../../Images/Sku/piaobai/piaobai2.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="3" onClick={(e) => {
                      setFieldByNameAndValue("bleachId", "3");
                    }}>
                      <Image
                          src={require("../../Images/Sku/piaobai/piaobai0.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div>{i18n.t("烘干")}</div>
                <Form.Item label="" name="dryingId">
                  <Radio.Group>
                    <Radio.Button value="1" onClick={(e) => {
                      setFieldByNameAndValue("dryingId", "1");
                    }}>
                      <Image
                          src={require("../../Images/Sku/honggan/jinhonggan.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="2" onClick={(e) => {
                      setFieldByNameAndValue("dryingId", "2");
                    }}>
                      <Image
                          src={require("../../Images/Sku/honggan/diwenhonggan.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="3" onClick={(e) => {
                      setFieldByNameAndValue("dryingId", "3");
                    }}>
                      <Image
                          src={require("../../Images/Sku/honggan/zhongwenhonggan.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="4" onClick={(e) => {
                      setFieldByNameAndValue("dryingId", "4");
                    }}>
                      <Image
                          src={require("../../Images/Sku/honggan/gaowenhonggan.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div>{i18n.t("熨烫")}</div>
                <Form.Item label="" name="IroningId">
                  <Radio.Group>
                    <Radio.Button value="1" onClick={(e) => {
                      setFieldByNameAndValue("IroningId", "1");
                    }}>
                      <Image
                          src={require("../../Images/Sku/yuntang/bukeyuntang.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="2" onClick={(e) => {
                      setFieldByNameAndValue("IroningId", "2");
                    }}>
                      <Image
                          src={require("../../Images/Sku/yuntang/diyu100duyuntang.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="3" onClick={(e) => {
                      setFieldByNameAndValue("IroningId", "3");
                    }}>
                      <Image
                          src={require("../../Images/Sku/yuntang/diyu150duyuntang.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="4" onClick={(e) => {
                      setFieldByNameAndValue("IroningId", "4");
                    }}>
                      <Image
                          src={require("../../Images/Sku/yuntang/diyu200duyuntang.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <div>{i18n.t("干洗")}</div>
                <Form.Item label="" name="dryCleaningId">
                  <Radio.Group>
                    <Radio.Button value="1" onClick={(e) => {
                      setFieldByNameAndValue("dryCleaningId", "1");
                    }}>
                      <Image
                          src={require("../../Images/Sku/ganxi/jinganxi.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="2" onClick={(e) => {
                      setFieldByNameAndValue("dryCleaningId", "2");
                    }}>
                      <Image
                          src={require("../../Images/Sku/ganxi/changguiganxi.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                    <Radio.Button value="3" onClick={(e) => {
                      setFieldByNameAndValue("dryCleaningId", "3");
                    }}>
                      <Image
                          src={require("../../Images/Sku/ganxi/huanheganxi.png")}
                          width={"30px"}
                          preview={false}
                      />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Form.Item>
              <Form.Item label={i18n.t("加工技术资料")} name="processRemark">
                <TextArea rows={4}/>
              </Form.Item>
              <Form.Item
                  className="u-form-custom"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改商品") &&<Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </div>
  );
};
