import { instance as axios } from "./axios";
import { Description } from "../schemas/description";
import {FieldDescription} from "../schemas/fieldDescriptions";
import {useState} from "react";


async function get(entityName: string): Promise<Description[]> {
  const fieldResp = await axios.get(
    `/oa/field/description/list/byTableName?tableName=${entityName}`
  );
  return (fieldResp.data.Data as Description[]) || [];
}

async function getByCustomerCategoryId(categoryId: string) {
  const fieldResp = await axios.get(
    `/crm/common/crmtreeControl/getDescriptionByCategoryId?categoryId=${categoryId}`
  );
  return fieldResp.data.Data;
}

async function getNameSpaceDescriptions(
    space: string,
    previousDescriptions: FieldDescription[]
) {
  const resp = await axios
      .post("/oa/list/description/getListDescription", {
        namespace: space,
      });
        if (resp.data.Status === "1" && resp.data.Data) {
          return resp.data.Data;
        } else {
          return null;
        }
}

async function getCurrentDescriptions(
    previousDescriptions: FieldDescription[],
    namespaceDescriptions: any
) {
  const copy = JSON.parse(JSON.stringify(previousDescriptions));
  let newDescriptions: FieldDescription[] = [];
  JSON.parse(namespaceDescriptions)
      .filter((item: any) => item.displayToList)
      .forEach((item: any) => {
        newDescriptions = [
          ...newDescriptions,
          ...copy.filter(
              (copyItem: any) => copyItem.fieldName === item.fieldName
          ),
        ];
      });
  return newDescriptions;
}

export { get, getByCustomerCategoryId,getNameSpaceDescriptions,getCurrentDescriptions};
