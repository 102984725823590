import React, { useState } from "react";
import { Button, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { DictionarySelect } from "../../../../../../../../../components/DictionarySelect";
import DatePicker from "../../../../../../../../../components/DatePicker/DatePicker";
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
export interface CommentProps {
  onSearch: (form: {
    startTime: string;
    endTime: string;
    advisorId: string;
    departmentId: string;
    productIds: string[];
    projectId: string;
    customerId: string;
  }) => void;
}
export const SearchBar = ({ onSearch }: CommentProps) => {
  const [searchType, setSearchType] = useState("0"); //收缩类型，0为部门，1为用户
  const [form, setForm] = useState({
    startTime: "",
    endTime: "",
    advisorId: "",
    departmentId: "",
    productIds: [],
    projectId: "",
    customerId: "",
  });
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <RangePicker
          value={
            !form.startTime || !form.endTime
              ? null
              : [
                  moment(form.startTime, dateFormat),
                  moment(form.endTime, dateFormat),
                ]
          }
          onChange={(dates: any, date: any) => {
            setForm({
              ...form,
              startTime: date[0],
              endTime: date[1],
            });
          }}
        />
        <Select
          defaultValue="0"
          value={searchType}
          style={{ width: 120, marginLeft: 8 }}
          onChange={(value: any) => {
            setSearchType(value);
          }}
        >
          <Option value="0">按部门</Option>
          <Option value="1">按用户</Option>
        </Select>
        {searchType === "0" && (
          <DictionarySelect
            style={{ width: 188, marginLeft: 8 }}
            entityName="base_department"
            value={form.departmentId}
            onChange={(value: any) => {
              setForm({
                ...form,
                departmentId: value,
                advisorId: "",
              });
            }}
          />
        )}
        {searchType === "1" && (
          <DictionarySelect
            style={{ width: 188, marginLeft: 8 }}
            entityName="base_staff"
            value={form.advisorId}
            onChange={(value: any) => {
              setForm({
                ...form,
                departmentId: "",
                advisorId: value,
              });
            }}
          />
        )}
        <DictionarySelect
          style={{ minWidth: 188, margin: "0 8px" }}
          entityName="inv_sku"
          multiple={true}
          placeholder="请选择意向产品"
          value={form.productIds}
          onChange={(value: any) => {
            setForm({
              ...form,
              productIds: value,
            });
          }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => {
            if (onSearch) {
              onSearch(form);
            }
          }}
        >
          搜索
        </Button>
      </div>
    </>
  );
};
