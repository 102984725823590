import React, { useCallback, useRef, useState, useEffect } from "react";
import {
    Button, message,
} from "antd";
import { DataGrid } from "../../../../../../components/DataGrid";
import { useWindowSize } from "react-use";
import i18n from "i18next";
import { useHistory } from "react-router";
import { CorrespondenceTreeWrapper } from "../../../../../../components/CorrespondenceTree";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { ModalForm } from "../Detail/AddColorLibrary";
import {createNewTab} from "../../../../../../util/tyy";
import storage from "../../util/Stroage";
interface Param {
  current: any;
}

const ColorList = function () {
  const [parent, setParent] = useState("parent");
  const [visible, setVisible] = useState(false);
  document.addEventListener("paste", function (e) {
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
  });
  const [currentDescriptions, setCurrentDescriptions] = useState<
    FieldDescription[]
  >([]);

  const descriptionsCopy = JSON.parse(
    JSON.stringify(currentDescriptions || [])
  );
  const [urldata, setUrldata] = useState("-1");
  const [edit, setEdit] = useState(" ");
  const history = useHistory();
  const thisGridRef = useRef<any>(null);
  const { width, height } = useWindowSize();
  const hideModal = () => {
    setVisible(false);
    thisGridRef.current.refresh();
  };
  const thisRef: Param = useRef();
  thisRef.current = {
    urldata,
    edit,
    visible,
  };
  useEffect(
    function () {
      thisGridRef.current.refresh();
    },
    [urldata]
  );
  const columnsData = [
      {
          fieldName: "name",
          fieldShowName: i18n.t("颜色名称"),
          width: 200,
          align: "center",
      },
      {
          fieldName: "typeId",
          fieldShowName: i18n.t("颜色种类"),
          width: 100,
          inputType: 6,
          dictionary: "dict_sku_color_type",
          align: "center",
      },
      {
          fieldName: "name",
          fieldShowName: i18n.t("颜色"),
          width: 200,
          align: "center",
          isHiddenSearch: true,
          render: (text: any, record: any) => {
              let colorName = "black";
              //1:暗色
              if(record.nameColorId == "1"){
                  colorName = "white";
              }
              if (record.ref.typeId == "2") {
                  return (
                      <div style={{ width: "100%"}}>
                          {
                              record.colorArr.map((color:any,index:any)=>{
                                  return <div
                                      style={{
                                          backgroundColor: color,
                                          float: "left",
                                          width: 100/record.colorArr.length+"%",
                                      }}
                                  >
                                      <span style={{color:colorName}}>&nbsp;</span>
                                  </div>
                              })
                          }
                      </div>
                  )
              } else {
                  return (
                      <div style={{ width: "100%" }}>
                          <div
                              style={{
                                  backgroundColor: record.rgb,
                                  float: "left",
                                  width: "100%",
                              }}
                          >
                              <span style={{color:colorName}}>&nbsp;</span>
                          </div>
                      </div>
                  );
              }
          },
      },
  ];
    const generateUUID = useCallback(function generateUUID() {
        var d = new Date().getTime();
        var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
            c
        ) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    }, []);
  const [columns] = useState<any[]>(columnsData);
  const getSpicralPriceList = useCallback(function getSpicralPriceList(
    //tab标签名
    tab: string,
    //标签的键,标识符
    key: string,
    //表示页面列表
    gridRef: React.MutableRefObject<any>
  ) {
    return (
      <div className="u-box u-basic-page">
        <div className="u-box u-basic-page">
          <div className="u-box-head">
            <span className="u-box-title" onClick={(e) => e.preventDefault()}>
              {i18n.t("color_manage_management")}
            </span>

            <div className="u-box-toolbar">
              <div className="u-icon-list u-btn-list">
                  <Button
                      type="primary"
                      className="u-btnA"
                      onClick={() => {
                          if(thisRef.current.urldata=="-1"){
                              message.info(i18n.t("app_enterprise_choose_sub"));
                          }else{
                              // history.push("/clothes/colorManager/detail/"+generateUUID()+"/add/true/"+thisRef.current.urldata);
                              setVisible(true);
                          }
                      }}
                  >
                      {i18n.t("新增")}
                  </Button>
                <Button
                  type="primary"
                  className="u-btnA"
                  onClick={() => {
                    // setIsShow(!isShow);  添加操作
                      createNewTab(
                          storage.get("reactUrl") + "/clothes/colorManager/colorLibrary",
                          i18n.t("颜色库"
                          ));
                    // history.push("/clothes/colorManager/colorLibrary");
                  }}
                >
                    {i18n.t("颜色库")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="u-box-con">
          <div className="u-Tree-l">
            <CorrespondenceTreeWrapper
              editable={true}
              getTreeDataUrl={`/clothes/treeSelect/getTreeData?type=color_sku_category&parent=${parent}`}
              updateUrl={"/clothes/treeSelect/updateCategoryById?tableName=color_sku_category"}
              deleteUrl={"/clothes/treeSelect/deleteCategoryById?tableName=color_sku_category&uuid="}
              addUrl={"/clothes/treeSelect/addCategory?tableName=color_sku_category&uuid="}
              onSelected={(keys) => {
                setUrldata(keys[0]);
                setEdit(keys[0]);
              }}
            />
          </div>
          <div className="u-Table-r">
            <DataGrid
              ref={thisGridRef}
              url={"/clothes/colorManager/getEnableList?categoryId="+thisRef.current.urldata}
              rowSelectionType="checkbox"
              pagination={true}
              // isShowSearch={false}
              sorter={{ field: "createTime", order: "descend" }}
              pageSize={Math.floor((height - 280) / 42)}
              descriptions={columns}
            />
          </div>
            <ModalForm visible={thisRef.current.visible} onCancel={hideModal} categoryId={thisRef.current.urldata}
            />
        </div>
      </div>
    );
  }, []);
  return (
    <>
      {getSpicralPriceList(
        i18n.t("material_groupManagement"),
        "material_groupManagement",
        thisGridRef
      )}
    </>
  );
};
export default ColorList;
