import React, { useState, useContext } from "react";
import { Popover, List, Progress, Empty } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { instance as axios } from "../../../../../../util/axios";
import { SessionContext } from "../../../../index";
import Session from "../../../../../../schemas/session";
import { AvatarWrapper } from "../../../../../../components/AvatarWrapper";
import styled from "styled-components";
import { CustomerContactDetailDialog } from "../CustomerContactDetailDialog";
const ListWrapper:any = styled.div`
  width: 500px;
  height: 200px;
  overflow-y: auto;
  .ant-list-item {
    padding: 10px;
  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: start;
    .list-item-left {
      float: left;
      margin-right: 10px;
    }
    .list-item-top {
      display: flex;
      align-items: center;
      > span:last-child {
        color: #a3a3a3;
        margin-left: 10px;
      }
    }
    .list-item-bottom {
      cursor: pointer;
    }
  }
`;
export interface ButtonCustomerContactListProps {
  customerId: string;
}
export const ButtonCustomerContactList = ({
  customerId,
}: ButtonCustomerContactListProps) => {
  const session: Session = useContext(SessionContext);
  const [contactList, setContactList] = useState<any[]>([]);
  const [detailForm, setDetailForm] = useState({
    visible: false,
    id: "",
    advisorId: "",
  });

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        content={() => (
          <>
            <ListWrapper onClick={(e:any) => e.stopPropagation()}>
              {contactList.length > 0 ? (
                <List
                  itemLayout="vertical"
                  size="large"
                  dataSource={contactList}
                  renderItem={(item: any) => (
                    <List.Item key={item.uuid} actions={[]}>
                      <div className="list-item">
                        <div className="list-item-left">
                          <Progress
                            type="circle"
                            percent={item.stage}
                            width={60}
                          />
                        </div>
                        <div>
                          <div className="list-item-top">
                            <span>
                              <AvatarWrapper
                                userId={item.advisorName}
                                userName={item.creatorId}
                              />
                            </span>
                            <span>{item.contactTime}</span>
                          </div>
                          <div
                            className="list-item-bottom"
                            onClick={(e) => {
                              setDetailForm({
                                ...detailForm,
                                visible: true,
                                id: item.uuid,
                                advisorId: item.advisorId,
                              });
                              e.stopPropagation();
                            }}
                          >
                            {item.content}
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: 40 }}
                />
              )}
            </ListWrapper>
          </>
        )}
        placement="right"
        title={<span style={{ lineHeight: "32px" }}>跟进记录</span>}
        overlayStyle={{
          maxHeight: 320,
        }}
        onVisibleChange={(visible) => {
          if (visible) {
            axios
              .post("/crm/crm/contact/getContactTile", {
                customerId: customerId,
              })
              .then((resp) => {
                if (resp.data.Status === "1") {
                  setContactList(resp.data.Data.contactTileList);
                }
              });
          }
        }}
      >
        <FileTextOutlined style={{ verticalAlign: "middle" }} />
      </Popover>
      <CustomerContactDetailDialog
        visible={detailForm.visible}
        uuid={detailForm.id}
        editable={session.id === detailForm.advisorId}
        onCancel={() => {
          setDetailForm({
            ...detailForm,
            visible: false,
          });
        }}
        onOk={() => {
          setDetailForm({
            ...detailForm,
            visible: false,
          });
        }}
      />
    </div>
  );
};
