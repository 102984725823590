import React, { useState, useEffect, useRef, useCallback } from "react";
import { TableGrid } from "../../../../../../components/TableGrid";
import { Button, Tabs, Descriptions } from "antd";
import { instance as axios } from "../../../../../../util/axios";
import { ContactList } from "./ContactList";
import { Lead } from "../../../../../../schemas/lead";
import { Result } from "../../../../../../schemas/common";

const { TabPane } = Tabs;
export interface DetailProps {
  leadId: string;
}

export const Detail = ({ leadId }: DetailProps) => {
  const tableGridRef = useRef<any>();
  const [lead, setLead] = useState<Lead>({});
  const getClue = useCallback(
    function () {
      axios
        .post<Result<Lead>>(`/crm/clue/getClueById?uuid=${leadId}`)
        .then((resp) => {
          if (resp.data.Status === "1") {
            setLead(resp.data.Data);
          }
        });
    },
    [leadId]
  );
  useEffect(() => {
    getClue();
  }, [getClue, leadId]);

  return (
    <>
      <Descriptions title={lead.companyName}>
        <Descriptions.Item label="联系人">
          {lead.contactPer || "无"}
        </Descriptions.Item>
        <Descriptions.Item label="来源">
          {lead.sourceName || "无"}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
          {lead.createTime || "无"}
        </Descriptions.Item>
      </Descriptions>
      <Tabs>
        <TabPane key={"info"} tab={"基本信息"}>
          <div style={{ textAlign: "right" }}>
            <Button
              type={"link"}
              style={{ paddingRight: 0 }}
              onClick={() => {
                tableGridRef.current.save();
              }}
            >
              保存
            </Button>
          </div>
          <TableGrid
            ref={tableGridRef}
            id={leadId}
            entityName="crm_clue"
            columnCount={3}
            onSaved={getClue}
          />
        </TabPane>
        <TabPane key={"contact"} tab={"联系记录"}>
          <ContactList leadId={leadId} />
        </TabPane>
      </Tabs>
    </>
  );
};
