import React from "react"
import { Form, Input} from 'antd'
import i18n from "i18next";

export default ()=> {

	return (
		<div className="u-tab-con u-tab-bzmx">
			<Form.Item label={i18n.t("客户要求")} name="processRequirements">
				<Input.TextArea autoSize={{minRows: 3, maxRows: 5}}/>
			</Form.Item>
		</div>
	);
	
}