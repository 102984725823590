import React, {useCallback, useEffect, useState} from "react";
import {Button, message, Modal, Upload} from "antd";
import { instance as axios } from "../../util/axios";
import { BaseURL } from "../../constants/url";
import i18n from "i18next";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons/lib";
import ImgCrop from 'antd-img-crop';

export interface Param {
    value?: string | string[];
    onChange?: (value: any) => void;
}

const ImageUpload = ({ value, onChange }: Param) => {
    const [imageUrl,setImageUrl] = useState("");
    const getImageUrlById = useCallback(function getImageUrlById (id:any) {
        axios.post("/oa/common/attachment/showImageAttachment?id="+id).then((resp) => {
            if (resp.data.Status === "1") {
                setImageUrl(resp.data.Data);
                return resp.data.Data;
            } else {
                return null;
            }
        });
    },[])
    function beforeUpload(file:any) {
        let index = file.name.lastIndexOf('.')
        // 截取最后一个文件后缀名
        let extname = file.name.substr(index + 1)
        // 定义一个数组存储正确的图片格式
        let imgArr = ['jpg','jpeg','png','gif','webp','heif']
        // 判断截取的文件后缀是否正确
        const isJpgOrPng = imgArr.includes(extname.toLowerCase())
        // console.log(file)
        // const isJpgOrPng = file.type === 'image/png'|| file.type === 'image/jpeg' || file.type === 'image/jpg'|| file.type === 'image/gif' || file.type === 'image/webp' || file.type === 'image/heif'|| file.type === 'image/hevc';
        if (!isJpgOrPng) {
            message.error('图片格式不正确');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(i18n.t("img_limit_message"));
        }
        return isJpgOrPng && isLt2M;
    }
    const uploadButton = (
        <div>
            {false ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{i18n.t("uploadImage")}</div>
        </div>
    );
    useEffect(() => {
        axios.get(`/oa/common/attachment/index?attachIds=${value}`).then((resp) => {
            if (resp.data.Status === "1") {
                    resp.data.Data.map(
                        (item: { filename: string; uuid: string; suffix: string }) => {
                            let url: string;
                            url = BaseURL+"/oa/common/attachment/showImageAttachment?id=" + item.uuid;
                            setImageUrl(url);
                            // getImageUrlById(item.uuid)
                            return {
                                name: item.filename,
                                uid: item.uuid,
                                status: "done",
                                url: imageUrl,
                                response: { Data: { uuid: item.uuid } },
                            };
                        }
                    )
            }
        });
    }, [value]);
    return (
        <div className="u-uploader-box">
            <ImgCrop aspect={1.5}
                     modalTitle={i18n.t("编辑")}
                     modalOk={i18n.t("确定")}
                     modalCancel={i18n.t("取消")}
                     minZoom={0.5}
                     grid={true}
            >
                <Upload
                name="avatar"
                showUploadList={false}
                listType="picture-card"
                withCredentials={true}
                data={(file) => {
                    return {
                        fileData: file,
                        category: "id",
                        fileName: encodeURI(file.name),
                    };
                }}
                beforeUpload={beforeUpload}
                multiple={false}
                accept="image/*"
                action={BaseURL + "/oa/common/attachment/uploadFile2"}
                onChange={(param) => {
                    // const ids = param.fileList
                    //     .filter((item) => item.status === "done")
                    //     .map((item) => item.response.Data.uuid)
                    //     .join(",");
                    const ids = param.file.status === "done"?param.file.response.Data.uuid:"";
                    if(ids){
                        setImageUrl(BaseURL+"/oa/common/attachment/showImageAttachment?id="+ids);
                        // getImageUrlById(ids)
                        if(onChange){
                            onChange(ids)
                        }
                    }

                }}
            >
                {imageUrl ? (
                    <img src={imageUrl} alt="avatar" className="u-uploader-img" />
                ) : (
                    uploadButton
                )}
            </Upload>
            </ImgCrop>
        </div>
    );
};
export default ImageUpload;
