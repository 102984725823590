//redis操作方法
import {instance as axios} from "./axios";
import {Result} from "../schemas/common";

//删除key
async function deleteRedisKey(key:string): Promise<any> {
    const resp = await axios.get<Result<any>>(
        `/clothes/redis/del?key=`+key
    );
    return resp.data.Data;
}

export {deleteRedisKey};