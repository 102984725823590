import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {useHistory} from "react-router-dom";
import {
  Form,
  message,
  Button,
  Space, Avatar, Popconfirm, Modal,
} from "antd";
import {
  get,
  getNameSpaceDescriptions,
} from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { DataGrid } from "../../../../../../components/DataGrid";
import i18n from "i18next";
import { Description } from "../../../../../../schemas/description";
import {useUpdateEffect} from "react-use";
import {addLine, editOtherLine, isOnEdit, saveDataGrid} from "../../components/EditForm";
import GetRender from "../../util/Document";
import {validateMessages} from "../../util/DocumentEvent";
import {isNullOrUndefined} from "../../util/Common";
import OnFinishFaileds from "../../util/OnFinishFailed";
import {instance as axios} from "../../../../../../util/axios";
import {Result} from "../../../../../../schemas/common";
import {Comment} from "../../../CRM/Customer/Contact/List/components/Comment";
import {Like} from "../../../CRM/Customer/Contact/List/components/Like";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import {usePermission} from "../../../../../../util/permession";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Params {
  customerId: string;
  customerType: string;
}
export default ({ customerId,customerType }: Params) => {
  const [form] = Form.useForm();
  const dataGridRef = useRef<any>(null);
  const history = useHistory();
  const [namespace, setNamespace] = useState(
      "clothes_shipping_address.all"
  );
  const [entityName, setEntityName] = useState(
      "clothes_shipping_address"
  );
  const [currentDescriptions, setCurrentDescriptions] = useState<
      FieldDescription[]
      >([]);
  const [loading, setLoading] = useState(false);
  const [isChange,setIsChange] = useState(false);
  const callBack = useCallback(function confirm(flag: boolean) {
    if(flag){
      Modal.confirm({
        title: i18n.t("返回操作",),
        icon: <ExclamationCircleOutlined />,
        content: i18n.t("您还有未编辑完的信息，确定退出该页面吗？",),
        okText: i18n.t("okText"),
        cancelText: i18n.t("cancelText"),
        onOk: function () {
          history.push("/clothes/CRM/"+customerType);
        },
      });
    }else{
      history.push("/clothes/CRM/"+customerType);
    }

  }, []);

  const onFinish = async (fieldsValue: any) => {
    await saveDataGrid(dataGridRef);
    setLoading(true);
    try {
      let data = dataGridRef.current.getData();
      axios
          .post<Result<any[]>>(`/clothes/customerManage/saveShippingAddressList`, {
            data: JSON.stringify(data),
            customerId:customerId,
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              message.info(i18n.t("saveSuccess"));
              setLoading(false);
              setIsChange(false);
            }
          });
    } catch (e) {
      message.error(i18n.t("saveError"));
      setLoading(false);
    }
  };

  useEffect(function () {
    get(entityName).then((descriptions: FieldDescription[]) => {
      getNameSpaceDescriptions(namespace, descriptions).then(
          (nameSpaceDescriptions: any) => {
            if (nameSpaceDescriptions == null) {
              setCurrentDescriptions(descriptions);
            }
          }
      );
    });
  }, []);
  const [tableForm] = Form.useForm();

  function format() {
    const formattedCurrentDescriptions = JSON.parse(
        JSON.stringify(currentDescriptions)
    );
    let isDefault = {
      fieldShowName: i18n.t("是否默认",),
      fixed: "right",
      width: 130,
      render: (_: any, record: any) => (
          <Space>
            {record.isDefault? (<Space>{i18n.t("是")}</Space>):(<Space>{i18n.t("否")}</Space>)}
          </Space>
      ),
    };
    let operate = {
      fieldShowName: i18n.t("options"),
      fixed: "right",
      render: GetRender.GetRender(dataGridRef,onDetailSave,entityName,true),
      width: 130,
    };
    formattedCurrentDescriptions.forEach((description: Description) => {
      description.editable = true;
      if(description.fieldName == "brandId"){
        description.filter = "customerId = '"+customerId+"'";
      }
    });
    formattedCurrentDescriptions.push(isDefault);
    formattedCurrentDescriptions.push(operate);
    return formattedCurrentDescriptions;
  }

  const onDetailSave = async ()=>{
  }

  const [messageInfo, setMessageInfo] = useState<any>();
  const onValuesChange = (values: any) => {
    setIsChange(true);
  };
  return (
      <>
        <div className="u-form">
          <Form.Provider>
            <Form
                {...layout}
                layout="horizontal"
                form={form}
                name="editForm"
                onFinish={onFinish}
                validateMessages={validateMessages}
                onFinishFailed={(err: any) => {
                  setMessageInfo(err);
                }}
                scrollToFirstError={true}
            >
              {isNullOrUndefined(messageInfo) ? "" : <OnFinishFaileds values={messageInfo} form={form}/>}

              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <DataGrid
                    ref={dataGridRef}
                    url={
                      "/clothes/customerManage/getShippingAddressByCustomerId?customerId=" + customerId
                    }
                    descriptions={format()}
                    pagination={false}
                    onClick={async (record: any) => {
                      await editOtherLine(dataGridRef, record);
                    }}
                    loadAllDictionary={true}
                    isShowSearch={false}
                    form={tableForm}
                    onValuesChange={onValuesChange}
                />
              </Form.Item>
              <div style={{paddingBottom: 25}}>
                <Space>
                  <Button style={{width: 100}} onClick={async () => {
                    await addLine(dataGridRef);
                  }}>
                    {i18n.t("add")}
                  </Button>
                </Space>
              </div>
              <Form.Item
                  className="u-form-custom100"
                  {...{wrapperCol: {span: 24}}}
              >
                <div className="u-button-box">
                  <Form.Item>
                    {usePermission("新增修改客户") && <Button
                        type="primary"
                        className="u-form-buttonB"
                        onClick={() => {
                          form.submit();
                          setLoading(true);
                        }}
                        loading={loading}
                    >
                      {i18n.t("保存")}
                    </Button>}
                    <Button
                        htmlType="button"
                        onClick={() => {
                          callBack(isChange);
                        }}
                        className="u-form-buttonA"
                    >
                      {i18n.t("返回")}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </>
  );
};
