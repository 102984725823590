import React, { useEffect, useRef, useState } from "react";
import {Button, message, Spin} from "antd";
import moment from "moment";
import { instance as axios } from "../../../../../util/axios";
import {formatNumber} from "../util/FormatMoney";
import {getLocalTime} from "../util/DateFormat";

interface Param {
  docId: string;
  tempType: string;
  resizeHandle:(pageSize:string)=>void;
}
const FormTemplate = function ({ docId, tempType,resizeHandle }: Param) {
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState<any>();
  const [maxLine, setMaxLine] = useState(0);
  const [loading, setLoading] = useState(true);

  function td(row: any, column: any) {
    // return document.getElementById(
    //   "r" + row.toString() + "c" + column.toString()
    // );
    return document.querySelector("[r='" + row + "'] [c='" + column + "']");
  }
  function tr(row: any) {
    // return document.getElementById("r" + row.toString());
    return document.querySelector("[r='" + row + "']");
  }

  function mergeCell(cell: any, $cell: any, data: any) {
    if (Number(cell.columnSpan) > 1) {
      $cell.setAttribute("colspan", cell.columnSpan);
    }
    for (var c = 1; c < cell.columnSpan; c++) {
      $cell.nextElementSibling.remove();
    }

    // rowspan>1需要合并下一行内容
    if (Number(cell.rowSpan) > 1) {
      // $cell.setAttribute("rowspan",cell.rowSpan);
      for (var ri = 1; ri < cell.rowSpan; ri++) {
        // 行号
        var rowNumber = cell.gridRow + ri;

        // 垂直方向相邻元素
        var $vSibling = td(rowNumber, cell.gridColumn);

        // 删除相邻元素后面的元素
        for (var ci = 1; ci < cell.columnSpan; ci++) {
          if ($vSibling != null && $vSibling.nextElementSibling != null) {
            $vSibling.nextElementSibling.remove();
          }
        }
        // 删除相邻元素
        if ($vSibling != null) {
          $vSibling.remove();
        }
      }
    }

    switch (cell.cellStyle) {
      case "header":
        var $tr: any = tr(cell.gridRow);
        if ($tr != null) {
          $tr.style.background = data.detailBackgroundColor
            ? data.detailBackgroundColor
            : "";
          $tr.style.color = data.detailBackgroundColor ? "#fff" : "";
          $tr.style.height = "40px";
        }
        break;
      default:
    }
  }

  function filledData(cell: any, $cell: any,isDetailArea?:boolean,detailStyle?:string) {
    $cell.setAttribute("colspan", cell.columnSpan);
    $cell.setAttribute("rowspan", cell.rowSpan);
    cell.text = cell.text ? cell.text : "";
    switch (cell.cellStyle) {
      case "image":
        var imgs = document.createElement("IMG");
        imgs.setAttribute("src", cell.value);
        if (cell.style) {
          imgs.setAttribute("style", cell.style);
        }
        $cell.appendChild(imgs);
        break;
      case "datePicker":
        var date = moment(cell.text).format("ll");
        var span = document.createElement("DIV");
        var text = document.createTextNode(date.toString());
        span.appendChild(text);
        if (cell.style) {
          span.setAttribute("style", cell.style);
        }
        $cell.appendChild(span);
        break;
      case "date":
        var date = getLocalTime(cell.text,false);
        var span = document.createElement("DIV");
        var text = document.createTextNode(date.toString());
        span.appendChild(text);
        if (cell.style) {
          span.setAttribute("style", cell.style);
        }
        $cell.appendChild(span);
        break;
      case "datetime":
        var date = getLocalTime(cell.text,true);
        var span = document.createElement("DIV");
        var text = document.createTextNode(date.toString());
        span.appendChild(text);
        if (cell.style) {
          span.setAttribute("style", cell.style);
        }
        $cell.appendChild(span);
        break;

      default:
        var span = document.createElement("DIV");
        var text = document.createTextNode(cell.text);
        span.appendChild(text);
        if (cell.style) {
          span.setAttribute("style", cell.style);
        }
        $cell.appendChild(span);
    }
    if(isDetailArea && detailStyle){
      $cell.setAttribute("style",detailStyle);
    }
    if (cell.tdStyle) {
      $cell.setAttribute("style", cell.tdStyle);
    }

    // $cell.innerText = cell.text;
  }

  // 主区域初始化
  function initFormMasterAreas(data: any) {
    var masterAreas = data.masterArea.dataCellList;
    masterAreas.map(function (cell: any, index: number) {
      var $td = td(cell.gridRow, cell.gridColumn);
      if ($td != null) {
        var $tr = tr(cell.gridRow);
        filledData(cell, $td);
        mergeCell(cell, $td, data);
        if ($tr) {
          $tr.setAttribute("filled", "filled");
        }
        // $td.innerHTML = cell.gridColumn;
      }
      console.log(cell);
    });
  }

  /**
   * 更新node节点的id
   * @param index
   */
  function updateNodeId(rows: any) {
    for (const row of rows) {
      var oldR: number = row.getAttribute("r");
      row.setAttribute("r", Number(oldR) + 1);
    }
  }

  /**
   * 明细区域初始化
   */
  function initFormDetailsAreas(data: any) {
    //明细区域
    data.detailAreaList.map(function (item: any, index: any) {
      //遍历标题列
      var lineIndex=0;
      item.content.map((cells: any, indexs: any) => {
        lineIndex = lineIndex+1;
        //获取当前行
        var isFilled = false;
        var isWraping = false;
        cells.map((cell: any, index: any) => {
          var $tr:any = tr(cell.gridRow);
          var $td = td(cell.gridRow, cell.gridColumn);
          if ($tr && $tr.getAttribute("filled") == "filled" && !isFilled) {
            isFilled = true;
            //需要更新的节点
            var trs: any = document.querySelectorAll("tr");
            var rows = [];
            for (const trl of trs) {
              if (trl.getAttribute("r") >= cell.gridRow) {
                rows.push(trl);
              }
            }
            //创建一个空行
            var ntr = document.createElement("TR");
            ntr.setAttribute("r", cell.gridRow);
            for (var j = 0; j < data.columnCount; j++) {
              var ntd = document.createElement("TD");
              ntd.style.height = "30px";
              ntd.setAttribute("c", j + "");
              ntr.appendChild(ntd);
            }
            $tr && $tr.before(ntr);
            //从$tr开始更新id
            $td = td(cell.gridRow, cell.gridColumn);
            updateNodeId(rows);
          }
          // console.log("lineIndex", lineIndex);
          filledData(cell, $td,true,data.detailStyle);
          mergeCell(cell, $td, data);
          if ($tr) {
            // $tr.setAttribute("filled", "filled");
          }
        });
      });
    });
  }
  const changeNextPage = ()=>{
    var trs:any = document.querySelectorAll("#tableTemplate tbody tr");
    trs.forEach((item:any)=>{
      var rowIndex = item.getAttribute("r");
      //28个格子进行换页
      if((formatNumber(rowIndex)+4) % 27 == 0 && rowIndex!= 0){
        var changePageDiv = document.createElement("DIV");
        var orginDiv = document.createElement("DIV");
        // orginDiv.setAttribute("style", "page-break-after:right");
        console.log(item, changePageDiv);
        item.parentNode.insertBefore(orginDiv,item);
        item.parentNode.insertBefore(changePageDiv,item);
        //换行之后给他加一个表头
        var detailHeader:any = document.getElementById("r10");
        var newDetailHeader = detailHeader.cloneNode(true);
        newDetailHeader.setAttribute("id", "dheader" + rowIndex);
        item.parentNode.insertBefore(newDetailHeader,item);
      }
    })
  }
  useEffect(
    function () {
      if (docId && tempType) {
        axios
          .get(
            "/inventory/template/getPrintTemplate?docId=" +
              docId +
              "&tempType=" +
              tempType
          )
          .then(function (resp) {
            var data = resp.data.Data;
            if (resp.data.Status == 1) {
              resizeHandle(data.pageSize);
              setFormData(data);
              setMaxLine(data.realRowCount);
              var rowCount = data.realRowCount;
              var columnCount = data.columnCount;
              setMaxLine(columnCount);
              var node: string =
                "<table id='tableTemplate' style="+data.formStyle+" >";
              for (var i = 0; i < rowCount; i++) {
                var cont =
                  "<tr r=" +
                  i +
                  "  id='r" +
                  i.toString() +
                  "' key=" +
                  i.toString() +
                  ">";
                for (var j = 0; j < columnCount; j++) {
                  cont +=
                    "<td c=" +
                    j +
                    " style='height: 30px' id='r" +
                    i.toString() +
                    "c" +
                    j.toString() +
                    "'> </td>";
                }
                cont += "</tr>";
                //明细表开始
                node += cont;
              }
              node += "</table>";
              setContent(node);
              initFormMasterAreas(data);
              initFormDetailsAreas(data);
              //判断是否分页
              // changeNextPage();
            } else {
              message.error("获取数据失败");
            }
          })
          .catch((error) => {
            console.log(error)
            message.error("获取数据失败");
          }).finally(()=>{
              setLoading(false)
        });
      }
    },
    [docId]
  );

  return (
      <Spin spinning={loading}>
    <div style={{ margin: "0 auto" }}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
      </Spin>
  );
};

export default FormTemplate;
