import React, { useEffect, useRef, useState } from "react";
import { Modal, Switch } from "antd";
import { FieldDescription } from "../../schemas/fieldDescriptions";
import { DataGrid } from "../DataGrid";
import { instance as axios } from "../../util/axios";
import { useWindowSize } from "react-use";
import i18next from "i18next";

interface GridColumnCustomizerDialogProps {
  /**
   * 列表在系统内的唯一标识，如：grid.column.customizer.customer
   */
  namespace: string;
  descriptions: FieldDescription[];
  visible: boolean;
  onCancel: () => void;
  onOk: (
    newDescriptions: { fieldName: string; displayToList: boolean }[]
  ) => void;
}

export default function ({
  namespace,
  visible,
  onOk,
  onCancel,
  descriptions,
}: GridColumnCustomizerDialogProps) {
  const gridRef = useRef<any>(null);
  const [data, setData] = useState<FieldDescription[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
    const { width, height } = useWindowSize();

  useEffect(
    function () {
      if (!visible) {
        return;
      }
      axios
        .post("/oa/list/description/getListDescription", {
          namespace: namespace,
        })
        .then((resp) => {
          if (resp.data.Status === "1" && resp.data.Data) {
            if (descriptions.length === 0) {
              return;
            }
            setData(
              JSON.parse(resp.data.Data).map((item: FieldDescription) => {
                return Object.assign(
                  descriptions.filter(
                    (fieldDescription: FieldDescription) =>
                      fieldDescription.fieldName === item.fieldName
                  )[0],
                  item
                );
              })
            );
          } else {
            setData(descriptions);
          }
        });
    },
    [descriptions]
  );

  return (
    <Modal
      onOk={() => {
          setConfirmLoading(true);
        const newDescriptions = gridRef.current
          .getData()
          .map((item: FieldDescription) => {
            return {
              fieldName: item.fieldName,
              displayToList: item.displayToList,
            };
          });
        axios
          .post("/oa/list/description/saveListDescription", {
            namespace: namespace,
            descriptionIds: JSON.stringify(newDescriptions),
          })
          .then((resp) => {
            if (resp.data.Status === "1") {
              onOk(newDescriptions);
            }
          });
      }}
      onCancel={onCancel}
      visible={visible}
      title={i18next.t("public_columnDisplay")}
      confirmLoading={confirmLoading}
      bodyStyle={{height: height*3/5, overflowY: 'auto'}}
      maskClosable={false}
    >
      <DataGrid
        ref={gridRef}
        pagination={false}
        dragable={true}
        dataSource={data}
        descriptions={[
          {
            fieldShowName: i18next.t("fieldName"),
            render(item: any) {
              return <>{item.fieldShowName}</>;
            },
          },
          {
            fieldShowName: i18next.t("public_showOrHide"),
            render(text: any, item: any, index: number) {
              return (
                <Switch
                  checked={item.displayToList}
                  onChange={(checked) => {
                    const copy = JSON.parse(
                      JSON.stringify(gridRef.current.getData())
                    );
                    copy[index].displayToList = checked;
                    setData(copy);
                  }}
                />
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
