import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputRef,
  Spin,
  Table,
  Drawer,
  Tooltip,
  message,
} from "antd";
import {
  FilterOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import moment, { now } from "moment";
import { get } from "../../util/description";
import { Description } from "../../schemas/description";
import { Expression, GridProps, Pager, Sorter } from "./schemas";
import { DictionarySelect } from "../DictionarySelect";
import useForceUpdate from "../../hooks/useForceUpdate";
import DragableBodyRow from "./components/DragableBodyRow";
import EditableCell from "./components/EditableCell";
import {
  getDataSource,
  getDictionaryMapper,
  getExpressions,
  getGridData,
  getPagination,
} from "./util";
import "./index.css";
import { openWindowWithPostRequest } from "../../util/request";
import { BaseURL } from "../../constants/url";
import { GridData } from "../../schemas/gridData";
import { useUpdateEffect } from "react-use";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons/lib";
import i18next from "i18next";
import { getLocalTime } from "../../containers/App/screens/Clothes/util/DateFormat";
import { isEmpty } from "../../containers/App/screens/Clothes/util/Common";

export const DataGrid = forwardRef(
  (
    {
      scrolly,
      descriptions,
      dragable,
      entityName,
      onDrop,
      pagination = true,
      pageSize = 10,
      rowSelectionType,
      scroll = { x: true, y: scrolly },
      sorter,
      style,
      url,
      dataSource,
      onDraw,
      drawerWidth,
      expressions,
      onSelected,
      expandedRow,
      searchData,
      size = "small",
      expandedRowMutuallyExclusive = false,
      rowStyle,
      expandedRowClassName,
      isShowSearch = true,
      loadAllDictionary = false,
      form,
      onClick,
      onValuesChange,
      defaultExpandAllRows = false,
      tableSummary,
      rowClassName,
      clickToSelected = false,
      isShowExport = false,
      checkboxProps,
      onSearch,
      editRender,
    }: GridProps,
    ref: any
  ) => {
    const [columns, setColumns] = useState<any[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
    const [expandedRowKey, setExpandedRowKey] = useState<string>("");
    const [innerPagination, setInnerPagination] = useState<any>({});
    const [innerDescriptions, setInnerDescriptions] = useState<Description[]>(
      []
    );
    const [loading, setLoading] = useState(false);
    const [formattedData, setFormattedData] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [dictionary, setDictionary] = useState({});
    const searchFormRef = useRef<{ [key: string]: string }>({});
    const [filterCallback, setFilterCallback] = useState<Function | null>(null);
    const searchInputRef = useRef<InputRef | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [summaries, setSummaries] = useState<string>("");
    const [drawerFullScreen, setDrawerFullScreen] = useState(false);
    const [drawerContent, setDrawerContent] = useState<JSX.Element | null>(
      null
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (form == undefined) {
      [form] = Form.useForm();
    }
    const forceUpdate = useForceUpdate();
    const editingKeyRef = useRef<string | null>(null);
    const isEditing = function (item: any) {
      return item.key === editingKeyRef.current;
    };

    const mergedColumns = columns.map((col) => {
      const index = innerDescriptions.findIndex(
        (desc) => desc.fieldName === col.key
      );
      const description = innerDescriptions[index];
      if (!description) {
        return {};
      }
      col.shouldCellUpdate = description.shouldCellUpdate;
      if (description.keepRight || description.inputType == 2) {
        return {
          ...col,
          onCell: function (record: any) {
            return {
              record,
              inputType: description.inputType,
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              dictionary: description.dictionary || "",
              onChange: description.onChange,
              filter: description.filter,
              keepRight: description.keepRight,
              readOnly: description.readOnly,
              editRender: description.editRender,
              shouldCellUpdate: description.shouldCellUpdate,
            };
          },
        };
      }
      if (!description.editable && !description.showTitle) {
        return col;
      }
      if (description.showTitle) {
        return {
          ...col,
          onCell: function (record: any) {
            let dataIndexs = col.dataIndex;
            col.render = (dataIndexs: any) => (
              <Tooltip placement="topLeft" title={dataIndexs}>
                <td className="ant-table-cell ant-table-cell-ellipsis">
                  {dataIndexs}
                </td>
              </Tooltip>
            );
            return {
              record,
              inputType: description.inputType,
              dataIndex: dataIndexs,
              title: col.title,
              editing: isEditing(record),
              dictionary: description.dictionary || "",
              onChange: description.onChange,
              filter: description.filter,
              keepRight: description.keepRight,
              readOnly: description.readOnly,
              editRender: description.editRender,
              shouldCellUpdate: description.shouldCellUpdate,
            };
          },
        };
      }
      return {
        ...col,
        onCell: function (record: any) {
          return {
            record,
            inputType: description.inputType,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            dictionary: description.dictionary || "",
            onChange: description.onChange,
            filter: description.filter,
            keepRight: description.keepRight,
            readOnly: description.readOnly,
            editRender: description.editRender,
            shouldCellUpdate: description.shouldCellUpdate,
          };
        },
      };
    });

    function getColumnSearchProps({
      fieldName,
      fieldShowName,
      inputType,
      dictionary,
    }: Description) {
      let title = fieldShowName || fieldName || "";
      switch (inputType) {
        case 4:
          return getDateTimeSearchProps(fieldName!);
        case 6:
          return getDictionarySearchProps(fieldName!, title, dictionary!);
        default:
          return getTextSearchProps(fieldName!, title);
      }
    }

    function getColumnSortProps({ fieldName }: Description) {
      return { sorter: fieldName !== null && fieldName !== "" };
    }

    function getDateTimeSearchProps(dataIndex: string) {
      return {
        filterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) {
          const { RangePicker } = DatePicker;
          // noinspection NonAsciiCharacters
          return (
            <div style={{ padding: 8 }}>
              <div style={{ marginBottom: 8 }}>
                <RangePicker
                  autoFocus={true}
                  showTime
                  // placeholder={["开始时间", "结束时间"]}
                  onChange={(value) => setSelectedKeys(value)}
                  allowEmpty={[true, true]}
                  allowClear={true}
                  value={selectedKeys}
                  // ranges={{
                  //   今日: [moment(), moment()],
                  //   本周: [moment().startOf("week"), moment().endOf("week")],
                  //   本月: [moment().startOf("month"), moment().endOf("month")],
                  //   本季度: [
                  //     moment().startOf("quarter"),
                  //     moment().endOf("quarter"),
                  //   ],
                  //   本年: [moment().startOf("year"), moment().endOf("year")],
                  // }}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<FilterOutlined />}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                  onClick={() => {
                    if (selectedKeys.length > 0) {
                      handleSearchDateTime(selectedKeys, confirm, dataIndex);
                    } else {
                      handleReset(clearFilters, dataIndex, 4);
                    }
                  }}
                >
                  {i18next.t("public_search")}
                </Button>
                <Button
                  onClick={() => handleReset(clearFilters, dataIndex, 4)}
                  size="small"
                  style={{ width: 90 }}
                >
                  {i18next.t("public_clear")}
                </Button>
              </div>
            </div>
          );
        },
      };
    }

    function getDictionarySearchProps(
      dataIndex: string,
      title: string,
      dictionary: string
    ) {
      const entries = dictionary.split(".");
      const entityName = entries[0];
      const textIndex = entries.length === 2 ? entries[1] : null;
      return {
        filterDropdown: function ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) {
          return (
            <div style={{ padding: 8 }}>
              <DictionarySelect
                style={{ width: 188, marginBottom: 8, display: "block" }}
                entityName={entityName}
                value={selectedKeys[0]}
                onChange={(value: any) => setSelectedKeys([value])}
                textIndex={textIndex}
              />
              <Button
                type="primary"
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
                onClick={() => {
                  if (selectedKeys.length > 0) {
                    handleSearchDictionary(selectedKeys, confirm, dataIndex);
                  } else {
                    handleReset(clearFilters, dataIndex, 0);
                  }
                }}
              >
                {i18next.t("public_search")}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters, dataIndex, 6)}
                size="small"
                style={{ width: 90 }}
              >
                {i18next.t("public_clear")}
              </Button>
            </div>
          );
        },
      };
    }

    function getTextSearchProps(dataIndex: string, title: string) {
      console.log(1111111111111111111);
      return {
        filterDropdown({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) {
          function handleSearch() {
            if (selectedKeys.length > 0) {
              handleSearchText(selectedKeys, confirm, dataIndex);
            } else {
              handleReset(clearFilters, dataIndex, 0);
            }
          }
          return (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={i18next.t("public_search") + ` ${title}`}
                ref={searchInputRef}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
                onPressEnter={() => handleSearch()}
              />
              <Button
                type="primary"
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
                onClick={() => handleSearch()}
              >
                {i18next.t("public_search")}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters, dataIndex, 0)}
                size="small"
                style={{ width: 90 }}
              >
                {i18next.t("public_clear")}
              </Button>
            </div>
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          if (visible) {
            setTimeout(() => {
              if (searchInputRef.current) {
                searchInputRef.current.focus();
              }
            });
          }
        },
      };
    }

    // 构建搜索参数
    function handleSearchText(
      selectedKeys: any[],
      confirm: Function,
      dataIndex: string
    ) {
      let assign = Object.assign({}, searchFormRef.current);
      assign["searchField_string_" + dataIndex] = "1|" + selectedKeys[0];
      searchFormRef.current = assign;
      if (onSearch) {
        onSearch(searchFormRef.current);
      }
      setFilterCallback(() => confirm);
    }

    function handleSearchDateTime(
      selectedKeys: any[],
      confirm: Function,
      dataIndex: string
    ) {
      let current = Object.assign({}, searchFormRef.current);
      const fmt = "YYYY-MM-DD HH:mm:ss";
      const prop = "searchField_datetime_" + dataIndex;
      current[prop] = `${selectedKeys[0].format(fmt)}|${selectedKeys[1].format(
        fmt
      )}`;
      searchFormRef.current = current;
      if (onSearch) {
        onSearch(searchFormRef.current);
      }
      setFilterCallback(() => confirm);
    }

    function handleSearchDictionary(
      selectedKeys: any[],
      confirm: Function,
      dataIndex: string
    ) {
      let current = Object.assign({}, searchFormRef.current);
      current["searchField_dictionary_" + dataIndex] = selectedKeys[0];
      searchFormRef.current = current;
      if (onSearch) {
        onSearch(searchFormRef.current);
      }
      setFilterCallback(() => confirm);
    }

    function handleReset(
      clearFilters: Function,
      dataIndex: string,
      dataType: number
    ) {
      let current = Object.assign({}, searchFormRef.current);
      switch (dataType) {
        case 4:
          current["searchField_datetime_" + dataIndex] = "";
          break;
        case 6:
          current["searchField_dictionary_" + dataIndex] = "";
          break;
        default:
          current["searchField_string_" + dataIndex] = "";
          break;
      }
      searchFormRef.current = current;
      clearFilters();
      if (onSearch) {
        onSearch(searchFormRef.current);
      }
      setFilterCallback(() => clearFilters);
    }

    // 搜索
    useUpdateEffect(() => {
      if (filterCallback) {
        filterCallback({ confirm: true, closeDropdown: true });
      }
    }, [filterCallback]);

    useImperativeHandle(ref, () => ({
      isOnEdit() {
        return editingKeyRef.current !== null;
      },
      isEditing(record: any) {
        return isEditing(record);
      },
      edit(record: any) {
        if (editingKeyRef.current !== null) {
          message.warn("请先保存或撤销未提交的修改");
          return;
        }
        let copy = Object.assign({ key: record.key }, record.ref);
        innerDescriptions.forEach((columns: Description) => {
          // 如果时间没有值就不用格式化了,避免出现Invalid date
          if (
            copy[columns.fieldName!] &&
            (columns.inputType === 4 || columns.inputType === 5)
          ) {
            copy[columns.fieldName!] = moment(
              copy[columns.fieldName!],
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        });
        //这一行填写的内容保存完之后编辑其他行数据会带过去,加上这行代码
        form.resetFields();
        console.log(copy);
        form.setFieldsValue({ ...copy });
        editingKeyRef.current = copy.key;
        forceUpdate();
      },
      refreshs() {
        forceUpdate();
      },
      async save(key: React.Key) {
        const row = (await form.validateFields()) as any;
        const newData = [...data];
        const index = newData.findIndex((item) => {
          return isEmpty(item.uuid) ? key === item.key : key === item.uuid;
        });
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          setFormattedData(
            getDataSource({ data: newData, dictionary: dictionary })
          );
          setData(newData);
          editingKeyRef.current = null;
          forceUpdate();
        }
      },
      async updateItem(row: any, key: React.Key) {
        const newData = [...data];
        const index = newData.findIndex((item) => {
          return isEmpty(item.uuid) ? key === item.key : key === item.uuid;
        });
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          setFormattedData(
            getDataSource({ data: newData, dictionary: dictionary })
          );
          setData(newData);
          editingKeyRef.current = null;
          forceUpdate();
        }
      },
      remove(key: React.Key) {
        const newData = [...data];
        const index = newData.findIndex((item) => {
          return isEmpty(item.uuid) ? key === item.key : key === item.uuid;
        });
        if (index > -1) {
          newData.splice(index, 1);
          setFormattedData(
            getDataSource({ data: newData, dictionary: dictionary })
          );
          let newPagination = Object.assign({}, innerPagination);
          newPagination.pageSize -= 1;
          setInnerPagination(newPagination);
          setData(newData);
          editingKeyRef.current = null;
          forceUpdate();
        }
      },
      refresh() {
        setLoading(true);
        setSelectedRowKeys([]);
        loadData({
          pageIndex: paginationOption.current || 1,
          pageSize: paginationOption.pageSize || pageSize,
        }).then(() => setLoading(false));
      },
      clearUuid() {
        const newData = [...data];
        newData.map((item: any) => {
          item.uuid = "";
        });
        setFormattedData(
          getDataSource({ data: newData, dictionary: dictionary })
        );
        setData(newData);
        editingKeyRef.current = null;
        forceUpdate();
      },
      setDefault(record: any) {
        const newData = [...data];
        newData.map((item: any) => {
          if (item.uuid === record.key || item.key === record.key) {
            item.isDefault = true;
          } else {
            item.isDefault = false;
          }
        });
        const index = newData.findIndex((item) => {
          return isEmpty(item.uuid)
            ? record.key === item.key
            : record.key === item.uuid;
        });
        if (index > -1) {
          setFormattedData(
            getDataSource({ data: newData, dictionary: dictionary })
          );
          setData(newData);
          editingKeyRef.current = null;
          forceUpdate();
        }
      },
      getData() {
        return data;
      },
      async export() {
        const fullGridData = await getData({ pageIndex: 0, pageSize: 0 });
        let html = "<thead><tr>";
        html += columns
          .filter((column) => column.key)
          .map((column) => `<td>${column.title}</td>`)
          .join("");
        html += "</tr></thead>";
        html += "<tbody>";
        for (let item of getDataSource(fullGridData)) {
          html += "<tr>";
          for (const column of columns) {
            if (!column.key) {
              continue;
            }
            html += `<td${
              descriptions?.filter((desc) => desc.fieldName === column.key)[0]
                .inputType === 0
                ? " style='vnd.ms-excel.numberformat:@' "
                : ""
            }>${item[column.key] || ""}</td>`;
          }
          html += "</tr>";
        }
        html += "</tbody>";
        openWindowWithPostRequest(
          `${BaseURL}/oa/common/tableGrid/ZLGridExcelExport`,
          { tablehtml: JSON.stringify(html) }
        );
      },
      getSelectedRows() {
        return data.filter((item) =>
          selectedRowKeys.some((ele) => ele === item.uuid)
        );
      },
      getSelectedIds() {
        return selectedRowKeys;
      },
      getDataItem(key: string): any {
        return data.find((item) => {
          return item.uuid === key || item.key === key;
        });
      },
      add() {
        if (editingKeyRef.current !== null) {
          message.warn("请先保存或撤销未提交的修改");
          return;
        }
        const newData = [...data];
        let item = { uuid: "", key: moment(new Date()).toString() };
        newData.push(item);
        setFormattedData(
          getDataSource({ data: newData, dictionary: dictionary })
        );
        let newPagination = Object.assign({}, innerPagination);
        newPagination.pageSize += 1;
        setInnerPagination(newPagination);
        setData(newData);
        form.resetFields();
        form.setFieldsValue({ ...item });
        editingKeyRef.current = item.key;
        forceUpdate();
      },
      addData(item: any) {
        const newData = [...data];
        if (isEmpty(item.uuid)) {
          item.key = moment(new Date()).toString() + Math.random();
        }
        newData.push(item);
        setFormattedData(
          getDataSource({ data: newData, dictionary: dictionary })
        );
        let newPagination = Object.assign({}, innerPagination);
        newPagination.pageSize += 1;
        setInnerPagination(newPagination);
        setData(newData);
        forceUpdate();
      },
      addDataEdit(item: any) {
        if (editingKeyRef.current !== null) {
          message.warn("请先保存或撤销未提交的修改");
          return;
        }
        const newData = [...data];
        if (isEmpty(item.uuid)) {
          item.key = moment(new Date()).toString() + Math.random();
        }
        newData.push(item);
        setFormattedData(
          getDataSource({ data: newData, dictionary: dictionary })
        );
        let newPagination = Object.assign({}, innerPagination);
        newPagination.pageSize += 1;
        setInnerPagination(newPagination);
        setData(newData);
        form.resetFields();
        form.setFieldsValue({ ...item });
        editingKeyRef.current = item.key;
        forceUpdate();
      },
      addDatas(items: any) {
        items.map((item: any) => {
          if (isEmpty(item.uuid)) {
            item.key = moment(new Date()).toString();
          }
        });
        const newDataOld = [...data];
        const newData = newDataOld.concat(items);
        setFormattedData(
          getDataSource({ data: newData, dictionary: dictionary })
        );
        let newPagination = Object.assign({}, innerPagination);
        newPagination.pageSize += 1;
        setInnerPagination(newPagination);
        setData(newData);
        forceUpdate();
      },
      getEditingKey() {
        return editingKeyRef.current;
      },
      getIsLoading() {
        return loading;
      },
      /**
       * 取消编辑,不保存
       */
      cancelEdit() {
        editingKeyRef.current = null;
        forceUpdate();
      },
      /**
       * 为缓存字典添加临时值
       */
      addDictionary(dict: any) {
        const tempDictionary: any = { ...dictionary };
        for (var index in dict!) {
          const toUpdateDict = tempDictionary![index];
          if (toUpdateDict) {
            tempDictionary![index].push(dict[index]);
          }
        }
        setDictionary(tempDictionary);
      },
    }));

    function getColumns(descriptions: Description[]) {
      return descriptions.map((description: Description) => {
        let title = description.fieldShowName || description.fieldName || "";
        let showTitle = description.showTitle;
        let ellipsis = showTitle
          ? {
              showTitle: false,
            }
          : true;
        let column = {
          title: title,
          key: description.fieldName,
          dataIndex: description.fieldName,
          ellipsis: ellipsis,
          width: !description.width
            ? (title.length + 3) * 16
            : description.width,
          render: description.render,
          align: description.align,
          fixed: description.fixed,
          isHiddenSearch: description.isHiddenSearch,
          sortFieldName: description.sortFieldName,
        };
        //日期选择
        if (description.inputType == 4) {
          column.render = (text: any, record: any) => {
            // return "ddd";
            return <div>{text ? getLocalTime(text, false) : ""}</div>;
          };
        }
        //时间选择
        if (description.inputType == 5) {
          column.render = (text: any, record: any) => {
            // return "ddd";
            return <div>{text ? getLocalTime(text, true) : ""}</div>;
          };
        }
        return !isShowSearch
          ? column
          : column.isHiddenSearch
          ? column
          : Object.assign(column, {
              filterIcon: function (filtered: boolean) {
                return (
                  <FilterOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                  />
                );
              },
              ...getColumnSearchProps(description),
              ...getColumnSortProps(description),
            });
      });
    }

    /**
     * 仅查询数据
     * @param pager
     * @param currentSorter
     */
    async function getData(
      pager?: Pager,
      currentSorter?: Sorter
    ): Promise<GridData> {
      if (dataSource) {
        return {
          data: dataSource,
          dictionary: {},
        };
      } else {
        return await getGridData({
          url: url!,
          dictionaryMapper: getDictionaryMapper(innerDescriptions),
          pagination: pagination !== false,
          pager: pager,
          sorter: currentSorter && currentSorter.order ? currentSorter : sorter,
          searchForm: searchFormRef.current,
          searchData: searchData,
          loadAllDictionary: loadAllDictionary,
        });
      }
    }

    async function summary(): Promise<{ [key: string]: string }> {
      if (!dataSource) {
        return await getExpressions({
          url: url!,
          searchForm: searchFormRef.current,
          expressions: expressions,
          searchData: searchData,
        });
      }
      return {};
    }

    async function exportData() {
      const fullGridData = await getData({ pageIndex: 0, pageSize: 0 });
      let html = "<thead><tr>";
      html += columns
        .filter((column) => column.key)
        .map((column) => `<td>${column.title}</td>`)
        .join("");
      html += "</tr></thead>";
      html += "<tbody>";
      for (let item of getDataSource(fullGridData)) {
        html += "<tr>";
        for (const column of columns) {
          if (!column.key) {
            continue;
          }
          html += `<td${
            descriptions?.filter((desc) => desc.fieldName === column.key)[0]
              .inputType === 0
              ? " style='vnd.ms-excel.numberformat:@' "
              : ""
          }>${item[column.key] || ""}</td>`;
        }
        html += "</tr>";
      }
      html += "</tbody>";
      openWindowWithPostRequest(
        `${BaseURL}/oa/common/tableGrid/ZLGridExcelExport`,
        { tablehtml: JSON.stringify(html) }
      );
    }

    /**
     * 加载数据
     * @param pager
     * @param currentSorter
     */
    async function loadData(pager?: Pager, currentSorter?: Sorter) {
      let gridData = await getData(pager, currentSorter);
      setData(gridData.data);
      setDictionary(gridData.dictionary);
      setFormattedData(getDataSource(gridData));
      setInnerPagination(getPagination(gridData, innerPagination.total));
    }

    useUpdateEffect(
      function () {
        if (pagination) {
          summary().then((result) => {
            const total = result["数量"];
            setInnerPagination((prev: any) => ({
              ...prev,
              total,
            }));
            const summaries: string[] = [];
            expressions
              ? expressions.forEach((expression) => {
                  if (expression.format) {
                    summaries.push(
                      expression.format(result![expression.field])
                    );
                  }
                })
              : summaries.push(
                  i18next.t("public_totalCount") +
                    ` ${total} ` +
                    i18next.t("public_item")
                );
            setSummaries(summaries.join("，"));
          });
        }
      },
      [data]
    );

    // url改变，重新加载列表数据
    useUpdateEffect(() => {
      (async function () {
        setLoading(true);
        await loadData({ pageIndex: 1, pageSize: pageSize });
        setLoading(false);
      })();
    }, [url, dataSource, pageSize, searchData]);

    useUpdateEffect(() => {
      var tempRowKeys: any = [];
      data.map((item) => {
        if (defaultExpandAllRows) {
          tempRowKeys.push(item.uuid);
          setExpandedRowKeys(tempRowKeys);
        }
      });
    }, [formattedData]);

    // 加载动态列
    useEffect(() => {
      (async function () {
        if (entityName) {
          setInnerDescriptions(await get(entityName));
        } else {
          setInnerDescriptions(descriptions!);
        }
      })();
    }, [descriptions, entityName, innerDescriptions.length]);

    // 加载数据
    useUpdateEffect(() => {
      (async function () {
        if (innerDescriptions.length !== columns.length) {
          setLoading(true);
          setColumns(getColumns(innerDescriptions));
          await loadData({ pageIndex: 1, pageSize: pageSize });
          setLoading(false);
        }
      })();
    }, [innerDescriptions]);

    const components: any = {
      body: { cell: EditableCell, row: DragableBodyRow },
    };

    function moveRow(dragIndex: number, hoverIndex: number) {
      setData((prev) => {
        const assign = Object.assign([], prev);
        assign.splice(dragIndex, 1);
        assign.splice(hoverIndex, 0, data[dragIndex]);
        return assign;
      });
      setFormattedData((prev) => {
        const assign = Object.assign([], prev);
        assign.splice(dragIndex, 1);
        assign.splice(hoverIndex, 0, formattedData[dragIndex]);
        return assign;
      });
      if (onDrop) {
        onDrop();
      }
    }

    const draw = useCallback(
      function draw(record: any) {
        if (onDraw) {
          setDrawerVisible(true);
          setDrawerContent(onDraw(record));
        }
      },
      [onDraw]
    );

    function getRowClassName(record: any) {}

    const paginationOption = !pagination
      ? false
      : Object.assign(innerPagination, {
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total: string) => summaries,
          onShowSizeChange: async (pageIndex: number, pageSize?: number) => {
            setLoading(true);
            await loadData({
              pageIndex: 1,
              pageSize,
            }).then(() => setLoading(false));
          },
          position: ["bottomLeft"],
          hideOnSinglePage: true,
          // style: { position: "fixed", bottom: "0" },
        });

    return (
      <>
        <Spin spinning={loading}>
          <Form form={form} component={false} onValuesChange={onValuesChange}>
            <div className={"datagrid"}>
              <Table
                rowKey={(record) => record.uuid}
                expandedRowKeys={expandedRowKeys}
                style={style}
                dataSource={formattedData}
                scroll={scroll}
                components={components}
                onChange={(pagination, filters, sorter: any) => {
                  console.log("sorter:", sorter);
                  setLoading(true);
                  loadData(
                    {
                      pageIndex: pagination.current || 1,
                      pageSize: pagination.pageSize,
                    },
                    {
                      field: sorter.column
                        ? sorter.column.sortFieldName
                          ? sorter.column.sortFieldName
                          : sorter.field
                        : sorter.field,
                      order: sorter.order,
                    }
                  ).then(() => setLoading(false));
                }}
                pagination={paginationOption}
                onRow={(record, index): any => {
                  const data = {
                    onClick: () => {
                      if (onClick) {
                        onClick(record);
                      }

                      if (clickToSelected) {
                        setSelectedRowKeys([record.key]);
                      }

                      draw(record);
                    },
                  };
                  if (dragable) {
                    Object.assign(data, { index, moveRow });
                  }
                  return data;
                }}
                columns={mergedColumns}
                bordered
                size={size}
                tableLayout={"fixed"}
                onExpand={(expanded, record) => {
                  if (!expanded) {
                    let expandRows = JSON.parse(
                      JSON.stringify(expandedRowKeys)
                    );
                    let tempRows: any = [];
                    expandRows.map((item: any) => {
                      if (item == record.uuid) {
                      } else {
                        tempRows.push(item);
                      }
                    });
                    setExpandedRowKeys(tempRows);
                    // setExpandedRowKey("");
                  } else {
                    let expandRows = JSON.parse(
                      JSON.stringify(expandedRowKeys)
                    );
                    expandRows.push(record.uuid);
                    // setExpandedRowKey(record.uuid);
                    setExpandedRowKeys(expandRows);
                  }
                }}
                expandable={
                  expandedRow
                    ? {
                        expandedRowRender: (record) => expandedRow(record),
                        indentSize: 50,
                      }
                    : undefined
                }
                rowSelection={
                  rowSelectionType
                    ? {
                        type: rowSelectionType,
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys: any) => {
                          setSelectedRowKeys(selectedRowKeys);
                          if (onSelected) {
                            onSelected(selectedRowKeys);
                          }
                        },
                        getCheckboxProps: checkboxProps,
                      }
                    : undefined
                }
                summary={tableSummary}
                rowClassName={rowClassName}
                footer={
                  isShowExport
                    ? (data) => (
                        <a
                          onClick={async () => {
                            await exportData();
                          }}
                        >
                          {i18next.t("导出")}
                        </a>
                      )
                    : undefined
                }
              />
            </div>
          </Form>
        </Spin>
        <Drawer
          width={drawerFullScreen ? "100%" : drawerWidth}
          visible={drawerVisible}
          closeIcon={
            drawerFullScreen ? (
              <FullscreenExitOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  setDrawerFullScreen(false);
                }}
              />
            ) : (
              <FullscreenOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  setDrawerFullScreen(true);
                }}
              />
            )
          }
          onClose={() => setDrawerVisible(false)}
        >
          {drawerContent}
        </Drawer>
      </>
    );
  }
);
