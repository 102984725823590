import {useCallback} from "react";

function isNullOrUndefined(object:any) {
    if(object == null || object==undefined){
        return true;
    }else{
        return false;
    }
}

/**
 * 是否为空
 * @param object
 */
function isEmpty(object:any) {
    if(isNullOrUndefined(object) || object =="" || object==''){
        return true;
    }else{
        return false;
    }
}

/**
 * 是否为image
 * @param object
 */
function isImage(object:any) {
    if(object =="gif" || object =="jpg" || object=='png'){
        return true;
    }else{
        return false;
    }
}

// 两个浮点数求和
function accAdd(num1:any,num2:any){
    var r1,r2,m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    // return (num1*m+num2*m)/m;
    return Math.round(num1*m+num2*m)/m;
}

// 两个浮点数相减
function accSub(num1:any,num2:any){
    var r1,r2,m,n;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2=num2.toString().split(".")[1].length;
    }catch(e){
        r2=0;
    }
    m=Math.pow(10,Math.max(r1,r2));
    n=(r1>=r2)?r1:r2;
    return (Math.round(num1*m-num2*m)/m).toFixed(n);
}
// 两数相除
function accDiv(num1:any,num2:any){
    var t1,t2,r1,r2;
    try{
        t1 = num1.toString().split('.')[1].length;
    }catch(e){
        t1 = 0;
    }
    try{
        t2=num2.toString().split(".")[1].length;
    }catch(e){
        t2=0;
    }
    r1=Number(num1.toString().replace(".",""));
    r2=Number(num2.toString().replace(".",""));
    return (r1/r2)*Math.pow(10,t2-t1);
}

function accMul(num1:any,num2:any){
    var m=0,s1=num1.toString(),s2=num2.toString();
    try{m+=s1.split(".")[1].length}catch(e){};
    try{m+=s2.split(".")[1].length}catch(e){};
    return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
}


const getColor = (status:any)=>{
    var color = "";
    switch (status) {
        case "0":
            color = "#D8D8D8";
            break;
        case "1":
            color = "#fa541c"
            break;
        case "2":
            color = "#73d13d"
            break;
        case "3":
            color = "#f00";
            break;
        default:
            color = "#D8D8D8";
    }
    return color;
}

const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
        c
    ) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export {isNullOrUndefined,accAdd,accSub,accDiv,accMul,isEmpty,getColor,isImage,generateUUID}