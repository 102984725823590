import React, { useState, useEffect } from "react";
import { Tree, Modal, Input, message, Form } from "antd";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { instance as axios } from "../../util/axios";
import { getTree } from "../../util/ant/tree";
import styled from "styled-components";
import { useUpdateEffect } from "react-use";
import i18n from "i18next";
const { confirm } = Modal;
const TreeNodeWrapper = styled.span`
  .icon {
    color: transparent;
  }
  &:hover .icon {
    color: #1890ff;
  }
`;
export interface TreeProps {
  editable?: boolean;
  entityName: string;
  onSelected: (keys: any) => void;
}
export const TreeWrapper = (props: TreeProps) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [formatTreeData, setFormatTreeData] = useState<any[]>([]);
  const [addForm, setAddForm] = useState({
    parentName: "",
    parentId: "",
    visible: false,
    name: "",
  });
  const [editForm, setEditForm] = useState({
    name: "",
    id: "",
    visible: false,
  });
  function formatTitle(rootNode: any[]) {
    let copy = JSON.parse(JSON.stringify(rootNode));
    let func = function (nodes: any[]) {
      for (let node of nodes) {
        node.title = (
          <>
            {props.editable ? (
              <TreeNodeWrapper>
                <span style={{ marginRight: 25 }}>{node.title}</span>
                <PlusOutlined
                  className="icon"
                  onClick={(e) => {
                    setAddForm({
                      ...addForm,
                      parentId: node.key,
                      parentName: node.text,
                      name: "",
                      visible: true,
                    });
                    e.stopPropagation();
                  }}
                />
                <EditOutlined
                  className="icon"
                  style={{ margin: "0 6px" }}
                  onClick={(e) => {
                    setEditForm({
                      ...editForm,
                      id: node.key,
                      name: node.text,
                      visible: true,
                    });
                    e.stopPropagation();
                  }}
                />
                <DeleteOutlined
                  className="icon"
                  onClick={(e) => {
                    if (copy[0].key === node.key) {
                      message.error(i18n.t("根节点不可删除"));
                      e.stopPropagation();
                      return;
                    }
                    confirm({
                      title: i18n.t("提示",),
                      icon: <ExclamationCircleOutlined />,
                      okText: i18n.t("确定",),
                      cancelText: i18n.t("取消",),
                      content: i18n.t(`您确定要删除类别吗？`),
                      onOk() {
                        axios
                          .post("/oa/common/crmtreeControl/deleteChild", {
                            uuid: node.key,
                          })
                          .then((resp) => {
                            if (resp.data.Status === "1") {
                              message.success(i18n.t("删除成功"));
                              getTree(props.entityName).then((result) => {
                                setTreeData(result);
                              });
                            } else {
                              message.error(i18n.t("删除失败"));
                            }
                          });
                      },
                    });
                    e.stopPropagation();
                  }}
                />
              </TreeNodeWrapper>
            ) : (
              <span>{node.title}</span>
            )}
          </>
        );
        if (node.children && node.children.length > 0) {
          func(node.children);
        }
      }
    };
    func(copy);
    return copy;
  }
  useEffect(
    function () {
      console.log(1);
      getTree(props.entityName).then((result) => {
        setTreeData(result);
      });
    },
    [props.entityName]
  );
  useUpdateEffect(() => {
    setFormatTreeData(formatTitle(treeData));
  }, [props.editable, treeData]);

  return (
      <>
        {formatTreeData.length > 0 && (
            <Tree
                treeData={formatTreeData}
                onSelect={props.onSelected}
                defaultExpandAll={true}
            />
        )}
        <Modal
            title={i18n.t("编辑类别")}
            width={500}
            visible={editForm.visible}
            onCancel={() => {
              setEditForm({
                ...editForm,
                visible: false,
              });
            }}
            onOk={() => {
              axios
                  .post("/oa/common/crmtreeControl/DoAlterNode", {
                    type: props.entityName,
                    name: editForm.name,
                    id: editForm.id,
                  })
                  .then(() => {
                    message.success(i18n.t("编辑成功"));
                    setEditForm({
                      ...editForm,
                      visible: false,
                    });
                    getTree(props.entityName).then((result) => {
                      setTreeData(result);
                    });
                  });
            }}
            maskClosable={false}
        >
          <Form>
            <Form.Item label={i18n.t("类别名称")}>
              <Input
                  value={editForm.name}
                  onChange={(e) => {
                    setEditForm({
                      ...editForm,
                      name: e.target.value,
                    });
                  }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
            title={i18n.t("新增类别")}
            width={500}
            visible={addForm.visible}
            onCancel={() => {
              setAddForm({
                ...addForm,
                visible: false,
              });
            }}
            onOk={() => {
              axios
                  .post("/oa/common/crmtreeControl/addChild", {
                    name: addForm.name,
                    parent: addForm.parentId,
                  })
                  .then((resp) => {
                    if (resp.data.Status === "1") {
                      message.success(resp.data.Data);
                      setAddForm({
                        ...addForm,
                        visible: false,
                      });
                      getTree(props.entityName).then((result) => {
                        setTreeData(result);
                      });
                    }
                  });
            }}
            maskClosable={false}
        >
          <Form>
            <Form.Item label={i18n.t("上级类别")}>
              <Input value={addForm.parentName} disabled/>
            </Form.Item>
            <Form.Item label={i18n.t("类别名称")}>
              <Input
                  value={addForm.name}
                  onChange={(e) => {
                    setAddForm({
                      ...addForm,
                      name: e.target.value,
                    });
                  }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};
