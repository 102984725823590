import { SessionContext } from "../containers/App";
import Session from "../schemas/session";
import { useContext } from "react";

const usePermission = (permission: string) => {
  const session: Session = useContext(SessionContext);
  if (session.permissions.includes(permission)) {
    return true;
  } else {
    return false;
  }
};
const hasPermission = (permission: string) => {
  const permissions = localStorage.getItem("permissions")
  if (permissions && permissions.includes(permission)) {
    return true;
  } else {
    return false;
  }
};

export { usePermission,hasPermission };
