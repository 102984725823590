import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, Modal } from "antd";
import Session from "../../../../../../schemas/session";
import { DataGrid } from "../../../../../../components/DataGrid";
import { get } from "../../../../../../util/description";
import { FieldDescription } from "../../../../../../schemas/fieldDescriptions";
import { TableGrid } from "../../../../../../components/TableGrid";
import { useWindowSize } from "react-use";
import moment from "moment";
import { SessionContext } from "../../../../index";
import { Detail } from "../../Project/Detail";
export interface ProjectSProps {
  customerId: string;
  editable?: boolean;
}

/**
 * 客户联系人列表
 * @param customerId 客户 ID
 * @constructor
 */
export const Projects = ({ customerId, editable = true }: ProjectSProps) => {
  const [descriptions, setDescriptions] = useState<FieldDescription[]>([]);
  const { height } = useWindowSize();
  const projectGridRef = useRef<any>();
  const session: Session = useContext(SessionContext);
  const projectsTableGridRef = useRef<any>();
  const [addProjectForm, setAddProjectForm] = useState({
    visible: false,
  });
  useEffect(function () {
    get("crm_project").then((descriptions: FieldDescription[]) => {
      setDescriptions(descriptions);
    });
  }, []);

  return (
    <>
      {editable && (
        <div style={{ textAlign: "right" }}>
          <Button
            type={"link"}
            style={{ color: "#1890ff", paddingRight: 0 }}
            onClick={() => setAddProjectForm({ visible: true })}
          >
            +新建项目
          </Button>
        </div>
      )}
      <DataGrid
        onDraw={(record) => <Detail key={record.key} projectId={record.key} />}
        drawerWidth={1100}
        pageSize={Math.floor((height - 320) / 42)}
        ref={projectGridRef}
        url={"/crm/crm/project/getProjectBycustomerId?customerId=" + customerId}
        descriptions={descriptions}
      />
      <Modal
        title="新建项目"
        width={900}
        bodyStyle={{ height: 400, overflowY: "auto" }}
        visible={addProjectForm.visible}
        onCancel={() => {
          setAddProjectForm({
            ...addProjectForm,
            visible: false,
          });
        }}
        onOk={() => {
          projectsTableGridRef.current.save();
        }}
      >
        <>
          <div>
            <TableGrid
              onCell={(cell) => {
                if (cell.id === "address") {
                  cell.type = "address";
                }
              }}
              ref={projectsTableGridRef}
              entityName="crm_project"
              columnCount={2}
              defaultValues={{
                customerId: customerId,
                creatorId: session.id,
                advisorId: session.id,
                createTime: moment().format("YYYY-MM-DD"),
              }}
              onSaved={() => {
                projectGridRef.current.refresh();
                setAddProjectForm({
                  ...addProjectForm,
                  visible: false,
                });
              }}
            />
          </div>
        </>
      </Modal>
    </>
  );
};
